<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link to="/admin/userRolesManager">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回用户管理</el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">{{type===1?'编辑用户':'新增用户'}}</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>

    <div class="mainForm">
      <el-form :model="user" :rules="rules" ref="user" label-width="150px" class="demo-user store-info-form">

        <!--<el-form-item class="labelTitle" label="用户名称" prop="name">
          <el-input class="inputLine" v-model="user.name" placeholder="请输入用户名称" maxlength="50"></el-input>
        </el-form-item>-->
        <el-form-item class="labelTitle" label="员工姓名" prop="realName">
          <el-input class="inputLine" v-model="user.realName" placeholder="请输入用户名称" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="labelTitle" label="手机号" prop="phone" v-if="type===0">
          <el-input class="inputLineShort" v-model="user.phone" placeholder="请输入手机号"></el-input>
          <el-button id="send-code-btn" class="sendSM" type="primary" @click="sendSM('NEW')">发送验证码</el-button>
         <!-- <el-input class="inputLineCode" v-model="user.shortMessageCode" placeholder="验证码"></el-input>-->
        </el-form-item>
        <el-form-item class="labelTitle" label="验证码" prop="shortMessageCode" v-if="type===0">
          <el-input class="inputLineShort" v-model="user.shortMessageCode" placeholder="验证码"></el-input>
        </el-form-item>
        <div v-if="!showStopInfo" class="showStopInfo phoneToast">
          <div class="stopInfo">提示：新用户密码默认为手机号后6位。</div>
        </div>
        <el-form-item class="labelTitle" label="状态" prop="status" v-if="type===1">
          <el-radio-group class="checkGroup" v-model="user.status" @change="radioChange">
            <el-radio-button class="statusOpen" :label="SUPPLIER_STATUS.OPEN.key">启用</el-radio-button>
            <el-radio-button class="statusStop" :label="SUPPLIER_STATUS.STOP.key">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <div v-if="showStopInfo" class="showStopInfo">
          <div class="stopInfo">提示：用户已停用，停用的用户将没有登录权限。</div>
          <div class="stopInfo">可使用“用户管理”页面显示出已停用的用户。</div>
        </div>
        <el-form-item class="labelTitle" label="性别" prop="sex">
          <el-radio-group class="checkGroupSex" v-model="user.sex" @change="sexChange">
            <el-radio :label="SEX_STATUS.MALE.key">男</el-radio>
            <el-radio :label="SEX_STATUS.FEMALE.key">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="labelTitle" label="用户生日" prop="birthday">
          <el-date-picker class="inputLine"
                          v-model="user.birthday"
                          type="date"
                          placeholder="请选择出生日期"
                          @change="birthdayChange"
                          :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item class="labelTitle" label="邮箱" prop="email">
          <el-input class="inputLine" v-model="user.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item class="labelTitle" label="员工角色赋权" prop="roleIds">
          <el-checkbox-group v-for="(item,index) in roles" :key="index" class="roleBox" v-model="user.roleIds"
                             @change="roleChange">
            <el-checkbox :label="item.id" border :disabled="(item.level===1)  && item.tenantId==='wt_admin'">
              {{item.tenantId==='wt_admin' && item.level===1?'店主（公共模板）':item.tenantId==='wt_admin' && item.level===2?'员工（公共模板）':item.roleName}}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item class="storeInfoSub">
          <el-button class="storeInfoBtn" type="danger" @click="save('user')">保存</el-button>
          <el-button class="resetBtn" @click="resetForm('user')" v-if="this.type === 0">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserRolesManagerEdit',
    data () {
      let validate_clientName = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (!value) {
          callback(new Error('请填写用户名称'))
        } else if (value && getLength(value) > 50) {
          callback(new Error(this.type === 3 ? '用户名称最多不能超过50个字符' : '用户名称最多不能超过20个字符'))
        } else {
          callback()
        }
      }
      let validate_clientPerson = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (!value) {
          callback(new Error('请填写用户名称'))
        } else if (getLength(value) > 20) {
          callback(new Error('联系人最多不能超过20个字符'))
        } else {
          callback()
        }
      }
      let validate_clientPhone = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请填写手机号'))
        } else if (value && !Pattern.validate('mobile', value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      let emailValidator = (rule, value, callback) => {

        let regBlank = /\b[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}\b/g
        let removeReg = /^\s*|\s{0,}$/g

        let rmValue = value
        if (Tool.isEmpty(value)) {
          callback()
        } else if (!regBlank.test(rmValue)) {
          callback(new Error('必须由字母、数字@组成的正确邮箱格式'))
          regBlank.test(rmValue)
        } else {
          callback()
        }

      }
      let validate_roleIds = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        console.log('value: ', value)
        if (Tool.isEmpty(value) || value === undefined) {
          callback(new Error('请为员工选择角色'))
        } else {
          callback()
        }
      }
      return {
        message: '客户编辑',
        type: 0,
        searchInput: '',
        checkedStop: false,//默认不显示停用客户
        clientName: '',
        user: {
          name: '', //客户名称
          realName: '', //联系人
          phone: '', //手机号
          status: 0, //状态
          roleIds: [], //角色ID
          shortMessageCode:'',
          sex: 1,
        },
        roles: [],
        roleIds: [],
        loginUser: {},
        checkArea: [],
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        SEX_STATUS: SEX_STATUS,
        //禁用提示
        showStopInfo: false,
        pickerOptions: {
          shortcuts: [{
            text: '70年 出生',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 52)
              picker.$emit('pick', date)
            }
          }, {
            text: '80年 出生',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 42)
              picker.$emit('pick', date)
            }
          }, {
            text: '90年 出生',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 32)
              picker.$emit('pick', date)
            }
          }, {
            text: '00年 出生',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 365 * 22)
              picker.$emit('pick', date)
            }
          }]
        },
        rules: {
          /*name: [
            {
              required: true,
              validator: validate_clientName,
              trigger: 'blur'
            }
          ],*/
          realName: [
            {
              required: true,
              validator: validate_clientPerson,
              trigger: 'blur'
            }
          ],
          phone: [
            {
              required: true,
              validator: validate_clientPhone,
              trigger: 'blur'
            }
          ],
          roleIds: [
            {
              required: true,
              message:"请为员工赋权角色",
              trigger: 'blur'
            }
          ],
          email: [
            {
              validator: emailValidator,
              trigger: 'blur'
            }
          ],
        },

        //联想下拉列表输入框
        restaurants: [],
        state: '',
        timeout: null,
      }
    },
    mounted () {
      this.loginUser = LocalStorage.get(LOGIN_USER)
      this.user.tenantId = this.loginUser.tenantId
      this.user.createUser = this.loginUser.userId
      let userRoleInfo = {}
      this.navChange()
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch()
      }
      //编辑：type=1 新增：type=0
      if (Tool.isEmpty(this.$route.params.type) && this.$route.params.type !== 0) {
        userRoleInfo = SessionStorage.get(USER_ROLE_INFO)
        this.type = userRoleInfo.type
        this.showStopInfo = userRoleInfo.showStopInfo
        this.roleIds = userRoleInfo.roleIds
        this.user.id=''
      } else {
        this.type = this.$route.params.type
        if (this.type === 1) {
          this.user = this.$route.params.item || {}
          if (this.user.status === 1) {
            this.showStopInfo = true
          }

          for (let i = 0; i < this.user.roleIds.length; i++) {
            this.user.roleIds[i] = this.user.roleIds[i] + ''
            this.roleIds.push(this.user.roleIds[i])
          }
        }
        userRoleInfo.type = this.type
        userRoleInfo.user = this.user
        userRoleInfo.showStopInfo = this.showStopInfo
        userRoleInfo.roleIds = this.roleIds
        SessionStorage.set(USER_ROLE_INFO, userRoleInfo)
      }

    },
    methods: {

      createStateFilter (queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      handleSelect (item) {
        this.user.clientName = item.clientName
        this.user.clientPerson = item.clientPerson
        this.user.clientPhone = item.clientPhone
      },
      roleChange (item) {
        this.user.roleIds = item
        this.roleIds = item
      },
      //查看详情
      getSearch () {
        if(this.type===0){
          this.user.createUserId=this.loginUser.userId
          Loadings.show()
          this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/role/list?userId=' + this.user.createUserId+"&tenantId="+this.loginUser.tenantId).then((response) => {
            Loadings.hide()
            let resp = response.data
            let tempRoles = resp.data
            this.roles = tempRoles
          })
        }else{
          Loadings.show()
          this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/role/list?userId=' + this.user.id+"&tenantId="+this.loginUser.tenantId).then((response) => {
            Loadings.hide()
            let resp = response.data
            let tempRoles = resp.data
            this.roles = tempRoles
          })
        }

      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        console.log('保存的用户：', _this.user)
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            this.$axios.post(process.env.VUE_APP_SERVER + '/blade-user/saveUserRole', this.user)
              .then((response) => {
                Loadings.hide()
                if (response.status === 200) {
                  if (response.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '更新用户信息成功！',
                      duration: 2000,
                      offset: 110
                    })
                    setTimeout(() => {
                      this.$router.push('/admin/userRolesManager')
                    }, 1000)
                  } else {
                    this.$message({
                      type: 'warning',
                      message: response.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        })
      },
      //2.停用客户
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }

      },
      sexChange (e) {
        console.log('性别选择：', e)
      },
      birthdayChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.user.birthday = this.$moment(e).format('YYYY-MM-DD')
        } else {
          this.user.birthday = ''
        }
        console.log('用户生日：', this.user.birthday)
      },
      sendSM (type) {
        let _this = this

        //对手机号进行验证
        let shortMessage = {
          mobile: _this.user.phone,
          type: 'A'
        }
        //发送短信验证码
        _this.sendMobileSMSCode(shortMessage, 'send-code-btn')

      },
      //发送短信验证码请求
      sendMobileSMSCode (shortMessage, sendBtn) {
        let _this = this
        //调用短信发送接口
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/smsSendRecord/sendValidateCode?' + 'phone=' + shortMessage.mobile + '&smsType=' + shortMessage.type,
          method: 'POST',
          // data: {
          //   account: _this.member.mobile,
          //   password: _this.member.password
          // },
        })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.code == 200) {
                //设置倒计时，按钮不可点击
                _this.countDown = 60
                _this.setSMTime(sendBtn)
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      /**
       * 设置按钮倒计时
       */
      setSMTime (sendBtn) {
        let _this = this
        let btn = $('#' + sendBtn)
        if (_this.countDown === 0) {
          btn.removeAttr('disabled')
          btn.text('获取验证码')
          //=0停止当前时间循环
          return
        } else {
          //不为0，不可点击,显示倒计时
          btn.attr('disabled', true)
          btn.text('重新发送(' + _this.countDown + ')')
          _this.countDown--
        }
        //每1秒执行一次setTime
        setTimeout(function () {
          _this.setSMTime(sendBtn)
        }, 1000)
      },
      //3.省市区
      handleArea (value) {
        //console.log(value)
      },
      navChange () {
        let flag = 0
        console.log('this.type: ', this.type)
        if (this.type === 1) {
          flag = 1
        }
        this.$emit('navChange', flag)
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-form-item__label:before {
    margin-left: 4px;
    float: right;
  }

  .mainPages {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 150px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 25px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  .storeInfoBtn {
    margin-top: 10px;
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  .inputLineShort {
    max-width: 220px;
    float: left;
  }

  .inputLineCode {
    max-width: 104px;
    margin-left: 10px;
    margin-top: 0;
    float: left;
  }

  .inputLineCode /deep/ .el-input__inner {
    border: 1px solid #66b1ff;
  }

  .sendSM {
    float: left;
    margin-left: 10px;
    padding: 12px 12px;
  }

  .checkArea {
    float: left;
  }

  /*停用启用*/
  .checkGroup {
    float: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
  }

  .checkGroupSex {
    float: left;
    line-height: 50px;
  }

  /deep/ .el-radio {
    margin-left: 10px;
    margin-right: 10px;
  }

  /deep/ .el-radio-button__inner, .el-radio-button:first-child {
    border: 0 !important;
  }

  .showStopInfo {
    text-align: left;
    margin-left: 150px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }
  .phoneToast{
    margin-top: 10px;
  }
  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  /deep/ .el-autocomplete-suggestion li {
    line-height: 20px !important;
    padding: 7px;
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .addr {
    font-size: 12px;
    color: #b4b4b4;
  }

  .highlighted .addr {
    color: #ddd;
  }

  /deep/ .el-textarea .el-input__count {
    background: unset;
    bottom: -10px;
    right: 20px;
  }

  /deep/ .el-form-item__content {
    max-width: 500px;
  }

  .roleBox {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  /deep/ .el-radio__label {
    font-size: 16px;
  }
</style>
