<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="输入店铺名称、客户名称或手机号"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
        <div class="checkStop">
          <template>
            <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示停用客户</el-checkbox>
          </template>
        </div>

      </div>
      <div class="navRight">
        <el-button class="searchBtn" type="danger" @click="addClient" v-if="getAuthorityMenu('500201')">新增客户</el-button>
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="getSearch(1)" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="clients"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" ></el-table-column>
        <el-table-column property="clientName" label="店铺名称"  sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.clientName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.clientName===''?'-':scope.row.clientName}}
              </div>
              <span class="showTipLine">{{scope.row.clientName===''?'-':scope.row.clientName}}</span>
            </el-tooltip>
            <span class="clientIdShow">{{scope.row.supplierId}}</span>
          </template>

        </el-table-column>
        <el-table-column property="clientPerson" label="客户名称" sortable>
          <template slot-scope="scope">
            <el-tooltip placement="bottom" @mouseenter.native="remarkEnter(scope.row.clientPerson)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.clientPerson===''?'-':scope.row.clientPerson}}
              </div>
              <span class="showTipLine">{{scope.row.clientPerson===''?'-':scope.row.clientPerson}}</span>
            </el-tooltip>

          </template>

        </el-table-column>
        <el-table-column property="idCertified" label="实名认证" width="190" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.idCertified === true ? 'danger':'info'" class="showCenter"
                    id="identityStatus" @click="idCertification(scope.row)">
              <el-image class="identityImg" v-show="scope.row.idCertified" :src="imgUrls.idRed">
              </el-image>
              <el-image class="identityImg" v-show="!scope.row.idCertified" :src="imgUrls.idGrey"></el-image>
              <div class="identityText">{{ID_CERTIFIED | optionObjectKV(scope.row.idCertified)}}</div>
              <i class="statusIcon" :class="scope.row.idCertified?'el-icon-success':''"></i>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="clientPhone" label="手机号" width="120" sortable>
          <template slot-scope="scope">
            {{scope.row.clientPhone===""?"-":scope.row.clientPhone}}
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{SUPPLIER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="remark" label="备注" width="240" >
          <template slot-scope="scope" >
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.remark)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.remark===''?'-':scope.row.remark}}
              </div>
              <el-tag type="info" class="showTipLinePlugin">{{scope.row.remark===''?'-':scope.row.remark}}</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="arrears" label="赊账" width="140" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.arrears === 0 ? 'primary':'danger'" class="showCenter"
                    id="arrears">{{scope.row.arrears>0?'￥ '+parseFloat(scope.row.arrears).toFixed(2):'未赊账'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="300">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button v-if="getAuthorityMenu('500202')"
              size="mini"
              type="primary"
              @click="edit(scope.row)">编辑
            </el-button>
            <el-button v-if="getAuthorityMenu('500203')"
              size="mini"
              :type="scope.row.status===0?'danger':'primary'"
              @click="openStop(scope.row)">{{scope.row.status===0?'停用':'启用'}}
            </el-button>
            <el-button v-if="getAuthorityMenu('500204')"
              size="mini"
              type="warning"
              @click="salesDetail(scope.row)">往来明细
            </el-button>
            <el-button v-if="scope.row.arrears>0"
              size="mini"
              type="success"
              @click="repaymentBill(scope.row)">收款
            </el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-client-repayment ref="clientRepayment" @repaymentRefresh="repaymentRefresh"></dialog-client-repayment>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogClientRepayment from '../../../components/DialogClientRepayment'
  export default {
    name: 'SupplierManager',
    components:{PageHelper,DialogClientRepayment},
    data () {
      return {
        message: '客户管理',
        client: {},
        clients: [],
        searchInput: '',
        checkedStop: false,//默认不显示停用供应商
        SUPPLIER_STATUS:SUPPLIER_STATUS,
        ID_CERTIFIED:ID_CERTIFIED,
        currentRow: null,
        remarkFlag: false,
        imgUrls:{
          idRed:'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/sfzFront_red.png',
          idBlue:'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/sfzFront_blue.png',
          idGrey:'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/sfzFront_grey.png',
          phone:'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/phone.png',
        },
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }
      this.getSearch(1);
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
      this.navChange()
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput);
      },
      getSearch (page,pageChange) {
        Loadings.show()
        let current = page;
        let size = this.$refs.pageHelper.size; //引用传入组件后，在标签中refs定义的别名
        let keywords = this.searchInput;
        let showStop = 0
        if(this.checkedStop === false){
          showStop = 0
        } else {
          showStop = 1
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/client/page?current='+current+"&size="+size+"&keywords="+keywords+"&showStop="+showStop).then((response) => {
          Loadings.hide()
          let resp = response.data;
          this.clients = resp.data.records;
          this.$refs.pageHelper.render(page, resp.data.total, size);
        })
      },
      reSearch (data) {
        //console.log("data: ",data);
        //this.getSearch(1,data);
        this.getSearch(this.$refs.pageHelper._data.page);
      },
      //2.显示停用客户
      checkedStopClick () {
        //console.log('当前的checkedStop: ', this.checkedStop)
        this.getSearch(1);
      },
      //3.新增供应商
      addClient () {
        let type=0;
        this.$router.push({
          name: "/clientManagerEdit",
          params: {type}
        });
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      edit(item) {
        let type = 1;
        this.$router.push({
          name: "/clientManagerEdit",
          params: { item, type }
        });
      },
      //停用/启用
      openStop(item) {
        let _this = this;
        if(item.arrears>0 && item.status === 0){
          this.$toast.error("当前用户："+(item.clientName===''?item.clientPerson:item.clientName)+" 存在赊账，无法停用！","center")
        }else{
          if (item.status === 0) {
            _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/client/stopUse?clientId=' + item.clientId)
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.code === 200) {
                    this.$message({ type: 'success', message: '停用成功！', duration: 2000, offset: 110 });
                    _this.getSearch(_this.$refs.pageHelper._data.page);
                  } else {
                    this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                  }
                } else {
                  this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
                }
              })
          } else if (item.status === 1) {
            _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/client/enableUse?clientId=' + item.clientId)
              .then((response) => {
                if (response.status === 200) {
                  if (response.data.code === 200) {
                    this.$message({ type: 'success', message: '启用成功！', duration: 2000, offset: 110 });
                    _this.getSearch(_this.$refs.pageHelper._data.page);
                  } else {
                    this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                  }
                } else {
                  this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
                }
              })
          }
        }

      },
      //5.往来明细
      salesDetail(item) {
        this.$router.push({
          name: "/clientManagerSaleDetail",
          params: {item}
        });
      },
      //6.前往实名认证
      idCertification(item){
        if(!item.idCertified){
          console.log("前往实名认证")
          let type = 4;
          this.$router.push({
            name: "/clientManagerEdit",
            params: { item, type }
          });
        }

      },
      //7.收款
      repaymentBill(item){
        this.$refs.clientRepayment.openRepayment(item)
      },
      repaymentRefresh(){
        console.log("刷新列表")
        this.getSearch(1)
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  .mainForm {

  }
  /deep/ .el-table .el-table__cell:first-of-type{
    line-height: 30px !important;
  }
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 630px;
  }
  /*提示*/
  .clientIdShow {
    display: none;
  }
  .toolTipWidth {
    max-width: 300px !important;
    text-align: center !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
    /*text-align: center;*/
  }
  .showTipLine{
    line-height: 36px;
    height: 36px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showTipLinePlugin{
    line-height: 36px;
    height: 36px;
    width: 200px !important;
    text-align: center;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address{
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showRemarkLine{
    height: 36px !important;
    line-height: 36px !important;
    overflow: hidden;
  }
  #identityStatus{
    cursor: pointer;
  }
  .identityText{
    margin-right: 15px;
  }
  .identityImg{

    width: 50px;
    height: 30px;
  }
  .showCenter{
    /*display: block;*/
  }
  .statusIcon{
    font-size: 20px;
    color: #f0ad4e;
    position: absolute;
    right: 11px;
    bottom: 5px;
  }
</style>
