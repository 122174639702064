<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="输入供应商名称、联系人或手机号"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
        <div class="checkStop">
          <template>
            <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示停用供应商</el-checkbox>
          </template>
        </div>

      </div>
      <div class="navRight">
        <el-button class="searchBtn" type="danger" @click="addSupplier" v-if="getAuthorityMenu('500301')">新增供应商</el-button>
         <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="suppliers"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="80" ></el-table-column>
        <el-table-column property="supplierName" label="供应商名称" width="200" sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.supplierName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.supplierName===''?'-':scope.row.supplierName}}
              </div>
              <span class="showTipLine">{{scope.row.supplierName===''?'-':scope.row.supplierName}}</span>
            </el-tooltip>
            <span class="clientIdShow">{{scope.row.supplierId}}</span>
          </template>
        </el-table-column>
        <el-table-column property="supplierPerson" label="联系人">
          <template slot-scope="scope">
            <el-tooltip placement="bottom" @mouseenter.native="remarkEnter(scope.row.supplierPerson)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.supplierPerson===''?'-':scope.row.supplierPerson}}
              </div>
              <span class="showTipLine">{{scope.row.supplierPerson===''?'-':scope.row.supplierPerson}}</span>
            </el-tooltip>

          </template>
        </el-table-column>
        <el-table-column property="supplierPhone" label="手机号" width="120" sortable></el-table-column>
        <el-table-column property="regionName" label="所在地区" width="240" >
          <template slot-scope="scope" >
            <el-tag :type="scope.row.regionName === '' ? 'info':'primary'" class="showCenter">
              {{scope.row.regionName===""?"-":scope.row.regionName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="address" label="详细地址" width="240" >
          <template slot-scope="scope" >
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.address)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.address===''?'-':scope.row.address}}
              </div>
              <el-tag type="info" class="showTipLinePlugin">{{scope.row.address===''?'-':scope.row.address}}</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" width="100" >
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{SUPPLIER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="remark" label="备注" >
          <template slot-scope="scope" >
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.remark)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.remark===''?'-':scope.row.remark}}
              </div>
              <el-tag type="info" class="showTipLinePlugin">{{scope.row.remark===''?'-':scope.row.remark}}</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="240">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button v-if="getAuthorityMenu('500302')"
              size="mini"
              type="primary"
              @click="edit(scope.row)">编辑
            </el-button>
            <el-button v-if="getAuthorityMenu('500303')"
              size="mini"
              :type="scope.row.status===0?'danger':'primary'"
              @click="openStop(scope.row)">{{scope.row.status===0?'停用':'启用'}}
            </el-button>
            <el-button v-if="getAuthorityMenu('500304')"
              size="mini"
              type="warning"
              @click="salesDetail(scope.row)">往来明细
            </el-button>
            <!--<el-button
              size="mini"
              type="warning"
              @click="del">排序
            </el-button>-->
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'

  export default {
    name: 'SupplierManager',
    components:{PageHelper},
    data () {
      return {
        message: '供应商管理',
        supplier: {},
        suppliers: [],
        searchInput: '',
        checkedStop: false,//默认不显示停用供应商
        SUPPLIER_STATUS:SUPPLIER_STATUS,
        currentRow: null,
        remarkFlag: false,
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1);
      }
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
      this.navChange()
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      getSearch (page,pageChange) {
        Loadings.show()
        let current=page
        let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let keywords=this.searchInput
        let showStop=0
        if(this.checkedStop===false){
          showStop=0
        }else{
          showStop=1
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/supplier/list' +
          '?current=' + current +
          "&size=" + size +
          "&keywords=" + keywords +
          "&showStop=" + showStop)
        .then((response) => {
          Loadings.hide();
          let resp = response.data;
          this.suppliers = resp.data.records;
          this.$refs.pageHelper.render(page, resp.data.total);
        });
      },
      reSearch (data) {
        this.getSearch(this.$refs.pageHelper._data.page);
      },
      //2.显示停用供应商
      checkedStopClick () {
        this.getSearch(1);
      },
      //3.新增供应商
      addSupplier () {
        let type=0;
        this.$router.push({
          name: "/supplierManagerEdit",
          params: {type}
        });
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      edit(item){
        let type = 1;
        this.$router.push({
          name: "/supplierManagerEdit",
          params: { item, type }
        });
      },
      //停用/启用
      openStop(item) {
        let _this = this;
        if (item.status === 0) {
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/supplier/stopUse?supplierId=' + item.supplierId)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$message({ type: 'success', message: '停用成功！', duration: 2000, offset: 110 });
                _this.getSearch(_this.$refs.pageHelper._data.page);
              } else {
                this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          })
        } else if (item.status === 1) {
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/supplier/enableUse?supplierId=' + item.supplierId)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                this.$message({ type: 'success', message: '启用成功！', duration: 2000, offset: 110 });
                _this.getSearch(_this.$refs.pageHelper._data.page);
              } else {
                this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          })
        }
      },
      //5.往来明细
      salesDetail(item){
        this.$router.push({
          name: "/supplierManagerSaleDetail",
          params: {item}
        });
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  .mainForm {

  }
  /deep/ .el-table .el-table__cell:first-of-type{
    line-height: 30px !important;
  }
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 630px;
  }
  /*提示*/
  .clientIdShow {
    display: none;
  }
  .toolTipWidth {
    max-width: 300px !important;
    text-align: center !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
    /*text-align: center;*/
  }
  .showTipLine{
    line-height: 36px;
    height: 36px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showTipLinePlugin{
    line-height: 36px;
    height: 36px;
    width: 200px !important;
    text-align: center;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address{
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showRemarkLine{
    height: 36px !important;
    line-height: 36px !important;
    overflow: hidden;
  }
</style>
