<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">更新权限
          <i class="el-icon-user-solid"></i>
          <span>{{user.name}}</span>
        </div>
      </div>

      <div class="addStoreForm">
        <div class="mainForm">
          <el-form :model="user" :rules="rules" ref="user" label-width="120px"
                   class="demo-user user-info-form">
            <!--<el-form-item class="labelTitle" label="开通时间" prop="openTime">
              <el-date-picker class="dateTime"
                v-model="openTime"
                type="date"
                placeholder="选择开通时间"
                @change="openTimeChange"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>-->
            <el-form-item class="labelTitle" label="到期时间" prop="deadline">
              <el-date-picker class="dateTime"
                v-model="user.deadline"
                type="date"
                placeholder="选择到期时间"
                @change="deadlineChange"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item class="labelTitle" label="缴费金额" prop="payAmount">
              <div class="unitLine">
                <el-input-number @change="payChange" :precision="2" :step="0.1" :max="999999999.99" :min="0"
                                 class="packageUnitLine" v-model="user.payAmount"
                                 placeholder="请输入金额"></el-input-number>
                <div class="money">元</div>
              </div>
            </el-form-item>
            <!--<el-form-item class="labelTitle" label="使用期限" prop="useTerm">
              <el-select class="statusLine" v-model="user.useTerm" placeholder="请选择">
                <el-option
                  v-for="item in USE_STATUS"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>-->
            <!--<el-form-item class="labelTitle" label="授权状态" prop="openStatus">
              <el-radio-group class="checkGroup" v-model="user.openStatus" @change="radioChange">
                <el-radio-button class="statusOpen" :label="USER_STATUS.CLOSE.key">{{USER_STATUS.CLOSE.value}}
                </el-radio-button>
                <el-radio-button class="statusStop" :label="USER_STATUS.OPEN.key">{{USER_STATUS.OPEN.value}}
                </el-radio-button>
                <el-radio-button class="statusExpire" :label="USER_STATUS.EXPIRE.key">{{USER_STATUS.EXPIRE.value}}
                </el-radio-button>
              </el-radio-group>

            </el-form-item>
            <div class="showStopInfo">
              <div class="stopInfo">
                {{user.openStatus===1?'用户已注册，未开通授权！':user.openStatus===2?'用户已缴费，开通授权！':'用户授权已到期，需续费！'}}
              </div>
            </div>-->
            <el-form-item class="storeInfoSub">
              <el-button class="userInfoBtn" type="danger" @click="save('user')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('user')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'UserPrivilege',
    props: {},
    data () {
      let priceValidate = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('请输入回收单价'))
        } else {
          callback()
        }
      }
      return {
        message: '用户密码修改',
        user: {
          deadline: '',
        },
        centerDialogVisible: false,
        pickerOptions: {

          shortcuts: [{
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          }, {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          }, {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
        },
        openTime: '',
        rules: {
          payAmount: [
            {
              required: true,
              message: '缴费金额不能为空！',
              trigger: 'blur',
            }
          ],
          deadline:[
            {
              required: true,
              message: '到期时间不能为空！',
              trigger: 'blur',
            }
          ],
          /*useTerm:[
            {
              required:true,
              message:'使用期限不能为空！',
              trigger: 'blur',
            }
          ],*/
          /*openStatus:[
            {
              required:true,
              message:'授权状态必须哦！',
              trigger: 'blur',
            }
          ],*/
        },

        USER_STATUS: USER_STATUS,
        USE_STATUS:USE_STATUS,
      }
    },
    mounted () {

    },
    methods: {
      dialogClose () {

      },
      payChange (e) {
        //console.log('支付金额变动：', e)
      },
      openUserPrivilege (user) {
        let _this = this
        this.centerDialogVisible = true
        _this.user = user
        _this.user = $.extend({}, _this.user)
        //_this.user.payAmount = 100.00
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
           Loadings.show()
            this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/psi/paylog/save',
              method: 'POST',
              data:this.user
            })
              .then((res) => {
                Loadings.hide()
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '缴费成功，更新权限！',
                      duration: 2000,
                      offset: 110
                    })
                    this.$emit('getSearch',1)
                    this.centerDialogVisible = false
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        })
      },
      openTimeChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.openTime = this.$moment(e).format('YYYY-MM-DD')
        } else {
          this.openTime = ''
        }
      },
      deadlineChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.user.deadline = this.$moment(e).format('YYYY-MM-DD')
        } else {
          this.user.deadline = ''
        }
        console.log("当前用户关闭时间：")
      },
      radioChange (e) {
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 620px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .title i {
    margin-left: 5px;
    margin-right: 5px;
    color: #f6e483;
  }

  .header-title .title span {
    display: block;
    float: right;
    margin-top: 19px;
    color: #f6e483;
    cursor: pointer;
    max-width: 400px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .remark div {
    text-align: left;
  }

  /*店铺管理*/
  .addStoreForm {
  }

  .userInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  .showStopInfo {
    text-align: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    line-height: 20px;
    padding: 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }

  /deep/ .el-date-editor.el-input, /deep/ .el-date-editor.el-input__inner {
    min-width: 400px;
  }

  .dateTime {
    float: left;
  }

  /*停用启用*/
  .checkGroup {
    float: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
  }

  /deep/ .el-radio-button__inner, .el-radio-button:first-child {
    border: 0 !important;
  }

  .showStopInfo {
    text-align: left;
    margin-left: 120px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 400px;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }

  .packageUnitLine {
    float: left;
  }
  .statusLine{
    float: left;
    width: 180px;
  }
  .money {
    float: left;
    margin-left: 10px;
  }
  /*.storeInfoSub{
    margin-top: 120px;
  }*/
</style>
