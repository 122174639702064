<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link to="/admin/importData">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回数据上传</el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">数据上传</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>

    <div class="mainForm">
      <el-form ref="upload" label-width="150px" class="demo-user store-info-form">
        
        <el-form-item class="labelTitle" label="类型">
          <el-radio-group class="checkGroupSex" v-model="upload.type">
            <el-radio :label="1">进货数据</el-radio>
            <el-radio :label="2">销货数据</el-radio>
            <el-radio :label="3">库存数据</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="文件" class="file">
          <el-upload
            class="upload-demo"
            :action=url
            :headers="headers"
            :on-success="successFile"
            :before-remove="removeFile"
            name="files"
            multiple
            :limit="9"
            :file-list="fileList">
            <el-button size="small" type="primary" class="uploadFile">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item class="storeInfoSub">
          <el-button class="storeInfoBtn" type="danger" @click="save('user')">保存</el-button>
          <!-- <el-button class="resetBtn" @click="resetForm('user')" v-if="this.type === 0">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'UserRolesManagerEdit',
    data () {
      return {
        message: '数据上传',
        upload: {
          type: 1, //类型
        
        },
        url:process.env.VUE_APP_SERVER + "/psi/orderfile/upload",
        fileList:[],
        filepath:[],
        headers:{
          'Blade-Auth':'bearer ' + LocalStorage.get(TOKEN)
        }
      }
    },
    mounted () {
    },
    methods: {
      //文件上传成功
      successFile(response,file,fileList) {
        this.filepath.push(file.response.data)
      },
      //删除文件
      removeFile(file,fileList) {
        this.filepath = this.filepath.filter(item=>{
          return item != file.response.data
        })
      },
      createStateFilter (queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      handleSelect (item) {
        this.user.clientName = item.clientName
        this.user.clientPerson = item.clientPerson
        this.user.clientPhone = item.clientPhone
      },
      roleChange (item) {
        this.user.roleIds = item
        this.roleIds = item
      },
      save () {
        let _this = this
        if(!this.filepath.length) {
          this.$message({
            type: 'warning',
            message: '请上传文件',
            duration: 2000,
            offset: 110
          })
          return
        }
        //console.log('保存的用户：', _this.user)
        //this.$refs[formName].validate((valid) => {
         // if (valid) {
           // Loadings.show()
            this.$axios.post(process.env.VUE_APP_SERVER + '/psi/orderfile/save', {filePaths:this.filepath.join(","),fileType:this.upload.type})
              .then((response) => {
                Loadings.hide()
                if (response.status === 200) {
                  if (response.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '上传成功！',
                      duration: 2000,
                      offset: 110
                    })
                    setTimeout(() => {
                      this.$router.push('/admin/importData')
                    }, 1000)
                  } else {
                    this.$message({
                      type: 'warning',
                      message: response.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
         // }
        //})
       }
      
    }
  }
</script>

<style scoped>
  .mainPages {
    min-height: 850px;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 150px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 25px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  .storeInfoBtn {
    margin-top: 10px;
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  .inputLineShort {
    max-width: 220px;
    float: left;
  }

  .inputLineCode {
    max-width: 104px;
    margin-left: 10px;
    margin-top: 0;
    float: left;
  }

  .inputLineCode /deep/ .el-input__inner {
    border: 1px solid #66b1ff;
  }

  .sendSM {
    float: left;
    margin-left: 10px;
    padding: 12px 12px;
  }

  .checkArea {
    float: left;
  }

  /*停用启用*/
  .checkGroup {
    float: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
  }

  .checkGroupSex {
    float: left;
    line-height: 50px;
  }

  /deep/ .el-radio {
    margin-left: 10px;
    margin-right: 10px;
  }

  /deep/ .el-radio-button__inner, .el-radio-button:first-child {
    border: 0 !important;
  }

  .showStopInfo {
    text-align: left;
    margin-left: 150px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }
  .phoneToast{
    margin-top: 10px;
  }
  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }


  .name {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .addr {
    font-size: 12px;
    color: #b4b4b4;
  }

  .highlighted .addr {
    color: #ddd;
  }


  /deep/ .el-form-item__content {
    max-width: 500px;
  }
  /deep/ .file .el-form-item__content {
    margin-left: 50px !important;
  }
  /deep/ .file .el-upload__input {
    margin-left: -98px;
  }
  /deep/ .file .el-upload-list__item {
    width: 100%;
    margin-left: 100px;
    text-align: left;
  }
  .roleBox {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  /deep/ .el-radio__label {
    font-size: 16px;
  }
  .uploadFile {
    position: absolute;
    left: 112px;
    top: 5px;
  }
</style>
