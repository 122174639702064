<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="输入角色名称"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>

      </div>
      <div class="navRight">
        <el-button class="searchBtn" type="danger" @click="addRole" v-if="getAuthorityMenu('600401')">新增角色</el-button>
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="roles"
        border
        highlight-current-row
        class="previous-row"
        height="775px"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" ></el-table-column>
        <el-table-column property="roleName" label="角色名称"  :show-overflow-tooltip="true"></el-table-column>
        <el-table-column property="roleDesc" label="权限描述" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column property="level" label="角色等级" width="200px" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag :type="scope.row.level===0?'danger':scope.row.level===1?'warning':scope.row.level===2?'warning':'primary'" class="showCenter"
                    id="level">{{ ROLE_STATUS | optionObjectKV(scope.row.level)}}{{scope.row.tenantId==='wt_admin'?'（公共模板）':''}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="220">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <!--<div v-if="loginUser.level<1">
              <el-button v-if="getAuthorityMenu('600402')"
                         size="mini"
                         type="primary"
                         @click="edit(scope.row)">编辑
              </el-button>
              <el-button v-if="getAuthorityMenu('600403')"
                         size="mini"
                         type="warning"
                         @click="delRole(scope.row)">删除
              </el-button>
            </div>-->
            <div>
              <el-button v-if="getAuthorityMenu('600402') && scope.row.tenantId!=='wt_admin' && scope.row.createUser!=='0' && scope.row.createUser===loginUser.userId"
                         size="mini"
                         type="primary"
                         @click="edit(scope.row)">编辑
              </el-button>
              <el-button v-if="getAuthorityMenu('600403') && scope.row.tenantId!=='wt_admin' && scope.row.createUser!=='0' && scope.row.createUser===loginUser.userId"
                         size="mini"
                         type="warning"
                         @click="delRole(scope.row)">删除
              </el-button>
            </div>

          </template>

        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'RoleManager',
    data () {
      return {
        message: '角色管理',
        role: {},
        roles: [],
        userIds:[],
        userList:[],
        searchInput: '',
        //权限模态框
        dialogFormUserVisible: false,
        dialogUserTitle:'员工角色配置',
        SUPPLIER_STATUS:SUPPLIER_STATUS,
        currentRow: null,
        remarkFlag: false,
        resourcess:[],
        ROLE_STATUS:ROLE_STATUS,
        loginUser:{},
      }
    },
    mounted () {
      this.loginUser = LocalStorage.get(LOGIN_USER)
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1);
        this.menuTreeList()
      }
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
      this.navChange()
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput);
      },
      getSearch () {
        Loadings.show()
        //console.log("当前的loginUser：",this.loginUser.tenantId)
        this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/role/list?userId=' + this.loginUser.userId+"&tenantId="+this.loginUser.tenantId+"&roleName="+this.searchInput).then((response) => {
          Loadings.hide()
          let resp = response.data;
          this.roles = resp.data;
        })
      },
      reSearch (data) {
        //console.log("data: ",data);
        //this.getSearch(1,data);
        this.getSearch(this.$refs.pageHelper._data.page);
      },
      //2.显示停用客户
      checkedStopClick () {
        //console.log('当前的checkedStop: ', this.checkedStop)
        this.getSearch(1);
      },
      //3.新增角色
      addRole () {
        let type=0;
        this.$router.push({
          name: "/roleManagerEdit",
          params: {resourcess:this.resourcess,type}
        });
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      edit(item) {
        let type = 1;
        let menuIds=[];
        Loadings.show();
        this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/role/detail/'+item.id).then((response) => {
          Loadings.hide()
          let resp = response.data;
          //console.log("resp: ",resp.data.menuIds)
          for(let i=0;i<resp.data.menuIds.length;i++){
            menuIds.push(resp.data.menuIds[i])
          }
          item.menuIds=menuIds;
          //console.log("roleItem: ",item)
          this.$router.push({
            name: "/roleManagerEdit",
            params: { resourcess:this.resourcess,item:item, type:type }
          });
        })

      },
      menuTreeList () {
        let _this = this
        Loadings.show()
        this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/menu/tree?userId='+_this.loginUser.userId+"&tenantId="+this.loginUser.tenantId).then((response) => {
          Loadings.hide()
          let resp = response.data
          this.resourcess = resp.data
          //console.log('当前的role：', this.resourcess)
        })
      },
      //停用/启用
      privilegeSet(item) {
        let _this = this;
        _this.dialogFormUserVisible=true;
        setTimeout(function () {
          let transformBtn=document.getElementsByClassName('el-transfer__button');
          for(let i=0;i<transformBtn.length;i++){
            transformBtn[i].style.backgroundColor='#fab6b6'
            transformBtn[i].style.borderColor='#fab6b6'
            //console.log("角色按钮：",transformBtn[i])
          }
        })

        //console.log("附权角色：",item)
      },
      //5.往来明细
      delRole(item) {
        let _this=this
        //console.log("删除角色：",item)
        this.$toast.showConfirm(item.roleName, function () {
          Loadings.show()
          _this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/role/remove/'+item.id).then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code != 200) {
                _this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              } else {
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    _this.$message({
                      type: 'success',
                      message: '删除角色成功！',
                      duration: 2000,
                      offset: 110
                    })
                    //跳转到客户列表页面
                    _this.getSearch()
                  }
                }
              }
            } else {
              _this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000,
                offset: 110
              })
            }
          })
        })

      },
      handleChange(value, direction, movedKeys) {
        if (direction === "right") {
          this.empIds = value;
        } else {
          this.empIds = [];
        }
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  .mainForm {

  }
  /deep/ .el-table .el-table__cell:first-of-type{
    line-height: 30px !important;
  }
  /deep/ .el-table .el-table__cell {
    text-align: center;
    padding: 15px 0;
  }
  /deep/ .el-table__body-wrapper{
    /*min-height: 720px;*/
  }

  /*模态框*/
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px 30px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }
  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }
  /deep/ .el-form-item__label{
    font-size: 15px;
  }

  /deep/ .el-dialog__footer{
    text-align: center;
  }
  .dialog-footer button{
    width: 150px;
  }
  .cancelBtn:hover,.cancelBtn:focus{
    background: #fef0f0;
    border-color: #fbc4c4;
    color: #f56c6c;
  }
  .sureBtn{
    background:#e52a33;
  }

</style>
