<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">收款单</div>
      </div>

      <div>
        <div class="mainForm">
          <el-form :model="client" :rules="rules" ref="client" label-width="120px"
                   class="demo-store store-info-form">
            <el-form-item class="repaymentLabelTitle" label="客户" prop="clientName">
              <div class="textDesc">{{client.clientName===''?client.clientPerson:client.clientName}}<i class="el-icon-user-solid clientNameIcon"></i></div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle" label="欠款" prop="arrears">
              <div class="textDescRed">￥ {{client.arrears}}</div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle" label="实收金额" prop="paymentAmount">
              <el-input-number class="inputGroup" @change="paymentAmountChange" v-model="paymentAmount"
                               :precision="2" :step="1" :min="0.00" :max="debtMax"
                               placeholder="请输入实收金额"></el-input-number>
              <div class="textDesc">元</div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle" label="优惠金额" prop="discountMoney">
              <el-input-number class="inputGroup" @change="discountMoneyChange" v-model="discountMoney"
                               :precision="2" :step="1" :min="0.00" :max="debtComputeMax"
                               placeholder="请输入优惠金额"></el-input-number>
              <div class="textDesc">元</div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle lastTitle" label="总收款金额" prop="sumPaymentAmount">
              <div class="textDescRed">{{client.sumPaymentAmount}} 元</div>
            </el-form-item>
            <div class="showStopInfo">
              <div class="stopInfo">注：总收款金额 = 实收金额 + 优惠金额</div>
            </div>
            <el-form-item class="repaymentLabelTitle" label="备注" prop="remark">
              <el-input class="remarkInput"
                type="textarea"
                :rows="2"
                maxlength="500"
                placeholder="请输入备注信息，最多500个字符"
                v-model="client.remark">
              </el-input>
            </el-form-item>
            <el-form-item class="storeInfoSub">
              <el-button class="storeInfoBtn" type="danger" @click="save('client')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('client')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <!--<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>-->
            <h4 class="modal-title" id="myModalLabel">选择位置</h4>
          </div>
          <div id="mapLocation" class="modal-body mapBody" style="width: 1000px; height: 600px;"></div>
          <!--
          <div class="modal-footer">
            <button type="button" class="locationBtn btn btn-primary" @click="saveLocation">保存</button>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DialogClientRepayment',
    props: {},
    data () {
      let validate_paymentAmount = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入实收金额'))
        } else if (value > parseFloat(this.client.arrears)) {
          callback(new Error('实收金额不能大于欠款金额'))
        } else if (value > parseFloat(this.client.arrears) - this.discountMoney) {
          callback(new Error('实收金额不能大于欠款金额-优惠金额'))
        } else {
          callback()
        }
      }
      let validate_discountMoney = (rule, value, callback) => {
        //console.log("value: ",value,"dis: ",this.discountMoney)
        if (this.discountMoney > parseFloat(this.client.arrears)) {
          callback(new Error('优惠金额不能大于欠款金额'))
        } /*else if (this.discountMoney > parseFloat(this.client.arrears) - this.paymentAmount) {
          this.discountMoney=0
          console.log("this.client.arrears: ",this.client.arrears,"this.sumPaymentAmount: ",this.sumPaymentAmount)
          this.client.sumPaymentAmount=this.client.arrears
          callback()
        }*/ else {
          callback()
        }
      }

      return {
        message: '店铺信息管理',
        centerDialogVisible: false,
        client: {
          clientId: '',
          clientName: '',
          clientPerson: '',
          clientPhone: '',
          status: '',
          paymentAmount: 0,
          disposeMoney: 0,
          sumPaymentAmount: 0,
          remark: ''
        },
        canChange: true,
        debtMax: 0,
        debtComputeMax: 0,
        paymentAmount: 0,
        discountMoney: 0,
        rules: {
          paymentAmount: [
            {
              required: true,
              message: '实收金额不能为空',
              trigger: 'blur'
            }
          ],
          discountMoney: [
            {
              validator: validate_discountMoney,
              trigger: 'blur'
            }
          ],
        },

      }
    },
    mounted () {

    },
    methods: {

      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openRepayment (item) {
        this.client = item
        this.debtMax = parseFloat(this.client.arrears)
        this.debtComputeMax = parseFloat(this.client.arrears)
        this.client.arrears = parseFloat(this.client.arrears).toFixed(2)
        this.client.discountMoney = 0
        this.discountMoney = 0
        this.client.paymentAmount = parseFloat(this.client.arrears)
        this.paymentAmount = this.client.paymentAmount
        this.client.sumPaymentAmount = this.client.arrears
        if (Tool.isEmpty(this.client.clientName)) {
          this.client.clientName = this.client.clientPerson
        }
        //console.log('client: ', this.client, 'debtMax: ', this.debtMax, 'debtComputeMax: ', this.debtComputeMax)
        this.centerDialogVisible = true
      },
      //重置表单
      resetForm (formName) {
        this.debtMax = parseFloat(this.client.arrears)
        this.debtComputeMax = parseFloat(this.client.arrears)
        this.client.arrears = parseFloat(this.client.arrears).toFixed(2)
        this.client.discountMoney = 0
        this.discountMoney = 0
        this.client.paymentAmount = parseFloat(this.client.arrears)
        this.paymentAmount = this.client.paymentAmount
        this.client.sumPaymentAmount = this.client.arrears
      },
      save (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/psi/repayment/save',
              data:{
                "clientId": this.client.clientId,
                "paymentAmount": this.client.paymentAmount,
                "discountMoney": this.discountMoney,
                "remark": this.client.remark,
                "sumPaymentAmount": this.client.sumPaymentAmount
              },
              method: 'POST'
            })
              .then((res) => {
                Loadings.hide()
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '收款成功！',
                      duration: 2000,
                      offset: 110
                    })
                    this.$emit('repaymentRefresh')
                    //关闭店铺信息管理弹窗
                    this.centerDialogVisible = false
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        })
      },
      paymentAmountChange () {
        if (Tool.isEmpty(this.paymentAmount) || this.discountMoney===parseFloat(this.client.arrears)) {
          this.paymentAmount = 0
          this.debtMax =0
        }
        //console.log('client.paymentAmount: ', this.paymentAmount)
        this.debtMax = parseFloat(this.client.arrears) - this.discountMoney
        this.debtComputeMax = parseFloat(this.client.arrears) - this.paymentAmount
        this.updateClient()
      },
      discountMoneyChange () {
        if (Tool.isEmpty(this.discountMoney)) {
          this.discountMoney = 0
        }
        if(this.discountMoney<=parseFloat(this.client.arrears)){
          this.paymentAmount =parseFloat(this.client.arrears)-this.discountMoney
          if(this.discountMoney===parseFloat(this.client.arrears)){
            this.debtMax = 0
          }else{
            this.debtMax = parseFloat(this.client.arrears)
          }
        }


        //console.log('client.discountMoney: ', this.discountMoney,"--this.paymentAmount: ",this.paymentAmount," this.discountMoney:", this.discountMoney)
        this.updateClient()
      },
      updateClient () {
        this.client.sumPaymentAmount = this.paymentAmount + this.discountMoney
        //console.log('this.client.sumPaymentAmount: ', this.client.sumPaymentAmount, 'this.paymentAmount: ', this.paymentAmount, 'this.discountMoney: ', this.discountMoney)
        this.client.sumPaymentAmount = parseFloat(this.client.sumPaymentAmount).toFixed(2)
        this.client.paymentAmount = this.paymentAmount
        this.client.disposeMoney = this.discountMoney
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 540px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .remark div {
    text-align: left;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  .locationIcon {
    font-size: 20px;
    margin-top: 10px;
    color: #2a92ee;
    cursor: pointer;
  }

  .locationIcon:hover {
    color: #58adf8;
  }

  #myModal {
    z-index: 3000;
    margin-top: 100px;
  }

  .greyTheme {
    color: #999999 !important;
  }

  .inputGroup {
    float: left;
    margin-right: 5px;
  }

  .textDesc {
    float: left;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .textDescRed {
    float: left;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #e92322;
  }

  .repaymentLabelTitle /deep/ .el-form-item__label {
    font-size: 16px;
  }

  .repaymentLabelTitle {
    margin-bottom: 15px;
  }
  .lastTitle{
    margin-bottom: 0 !important;
  }
  .showStopInfo {
    text-align: center;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 327px;
    line-height: 20px;
    padding: 10px;
    margin-left: 120px;
    margin-bottom: 20px;
    margin-top: 0;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }
  .clientNameIcon{
    margin-left: 5px;
    color: #f0ad4e;
  }
  .remarkInput{
    max-width: 327px;
    float: left;
  }
</style>
