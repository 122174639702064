<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">修改密码</div>
      </div>

      <div class="addStoreForm" >
        <div class="mainForm">
          <el-form :model="my" :rules="rules" ref="my" label-width="120px"
                   class="demo-my my-info-form">
            <el-form-item class="labelTitle" label="新密码" prop="newPwd">
              <el-input class="inputLine" placeholder="请输入新密码" v-model="my.newPwd" maxlength="16" show-password></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="确认密码" prop="newPwdAgin">
              <el-input class="inputLine" placeholder="请输入确认密码" v-model="my.newPwdAgin" maxlength="16" show-password></el-input>
            </el-form-item>
            <el-form-item class="labelTitle">
              <div class="showStopInfo">
                <div class="stopInfo">注意：密码由6-16位数字、字母任意组合而成。</div>
              </div>
            </el-form-item>
            <el-form-item class="storeInfoSub">
              <el-button class="myInfoBtn" type="danger" @click="save('my')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('my')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MyPwd',
    props: {},
    data () {
      return {
        message: '店铺信息管理',
        my: {
          newPwd: '',
          newPwdAgin: ''
        },
        centerDialogVisible: false,
        rules: {
          newPwd: [
            {
              required: true,
              message: '请输入新密码',
              trigger: 'blur'
            }
          ],
          newPwdAgin: [
            {
              required: true,
              message: '请输入确认密码',
              trigger: 'blur'
            }
          ]
        },
      }
    },
    mounted () {
      
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openMyPwd () {
        this.centerDialogVisible=true;
      },
      resetForm (formName) {
        this.$refs[formName].resetFields();
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //正则表达式校验密码
            if (!Pattern.validate('password', this.my.newPwd)) {
              this.$message({ type: 'warning', message: '密码必须是6-16位，数字、大小写字母任意组合', duration: 2000, offset: 110 });
              return;
            }
            if (this.my.newPwd != this.my.newPwdAgin) {
              this.$message({ type: 'warning', message: '两次输入密码不一致', duration: 2000, offset: 110 });
              return;
            }
            Loadings.show();
            this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-user/editPassword' +
                '?password=' + this.my.newPwd +
                '&rpassword=' + this.my.newPwdAgin,
              method: 'POST'
            })
            .then((res) => {
              Loadings.hide();
              if (res.status === 200) {
                if (res.data.code === 200) {
                  this.$message({ type: 'success', message: '保存密码成功！', duration: 2000, offset: 110 });
                  this.centerDialogVisible = false;
                } else {
                  this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }
            })
          }
        })
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 620px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }
  .remark div {
    text-align: left;
  }

  /*店铺管理*/
  .addStoreForm {
  }

  .myInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }
  .showStopInfo{
    text-align: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    line-height: 20px;
    padding: 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }
</style>
