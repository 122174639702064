<template>
  <div class="resourceBox">
    <el-row>
      <el-col :span="10">

        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <el-tag class="boxCardTitle" type="danger" effect="plain">菜单资源管理</el-tag>
            <div class="boxCardTitleDesc">JSON文本传输助手</div>
          </div>
          <el-input
            type="textarea"
            :rows="28"
            placeholder="请输入内容"
            v-model="resourcesJson">
          </el-input>
          <el-button class="resourceBtn" type="danger" @click="saveJSON" v-if="getAuthorityMenu('600502')">一键生成菜单</el-button>
        </el-card>

      </el-col>
      <el-col :span="14" style="height: 800px;overflow-y: scroll">
        <div class="resourceRow">
          <div class="switchDrag">
            <!--绑定switchDraggable参数，默认false，点击拖拽，显示批量保存-->
            <el-switch class="switchDragChoose" v-if="getAuthorityMenu('600503')"
                       v-model="switchDraggable"
                       active-text="开启批量删除"
                       inactive-text="关闭批量删除">
            </el-switch>
            <el-button type="primary" class="switchDragBtn" @click="addResource" v-if="getAuthorityMenu('600501')">新增主菜单</el-button>
            <el-button v-if="switchDraggable && getAuthorityMenu('600503')" type="danger" class="switchDragBtn" @click="batchDel">批量删除</el-button>
          </div>
          <div class="categoryTree">
            <el-tree
              :data="resourcess"
              :props="defaultProps"
              :expand-on-click-node="false"
              node-key="id"
              show-checkbox
              :default-expanded-keys="expendedKeys"
              empty-text=""
              ref="resourcesTree">
                <span class="tree-node" slot-scope="{node,data}">
                    <span class="nodeIcon glyphicon glyphicon-folder-open"></span>
                    <span class="labelName">{{node.label}}</span>
                    <span class="option-btn">
                    <el-button class="opt-btn-add" icon="el-icon-circle-plus" v-if="node.level<=3 && getAuthorityMenu('600501')" type="primary"
                               size="mini" @click="() => append(node,data)" plain>新增</el-button>
                    <el-button class="opt-btn-edit" icon="el-icon-question"
                               type="primary" v-if="getAuthorityMenu('600501')"
                               size="mini" @click="() => edit(node,data)" plain>修改</el-button>
                    <el-button class="opt-btn-del" icon="el-icon-remove" v-if="node.childNodes.length===0 && getAuthorityMenu('600501')"
                               type="primary" size="mini" @click="() => del(node,data)" plain>刪除</el-button>
                    </span>
                </span>

            </el-tree>
          </div>
        </div>

      </el-col>
      <div class="dialogModal">
        <el-dialog
          :visible.sync="dialogVisible"
          width="30%"
          :close-on-click-modal="false"
        >
          <div slot="title" class="header-title">
            <div class="title">{{dialogTitle}}</div>
          </div>
          <el-form :model="resources" ref="resources" :rules="rules">
            <el-form-item class="descTitle" label="资源名称">
              <el-input v-model="resources.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="descTitle" label="排序">
              <el-input v-model="resources.sort" autocomplete="off"
                        :disabled="resources.sort!=null?false:true"></el-input>
            </el-form-item>
            <el-form-item class="descTitle" label="资源页">
              <el-input v-model="resources.page" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="descTitle" label="请求路径">
              <el-input type="textarea" v-model="resources.path" :rows="4" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
                    <el-button class="cancelBtn" @click="dialogVisible = false">取 消</el-button>
                    <el-button class="sureBtn" type="danger" @click="save('resources')">确 定</el-button>
                </span>
        </el-dialog>

      </div>
    </el-row>

  </div>
</template>

<script>
  export default {
    name: 'ResourceManager',
    data () {
      let nameValidate = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value === '') {
          callback(new Error('资源名称不能为空'))
        } else if (getLength(value) < 2) {
          callback(new Error('资源名称长度至少3位'))
        } else {
          callback()
        }
      }
      return {
        message: '资源管理',
        resourcess: [],
        resources: {},
        resourcesJson: '',
        //tree
        rules: {
          name: [
            {
              validator: nameValidate,
              trigger: 'blur'
            }
          ],
        },
        expendedKeys: [], //tree默认展开的数组
        dialogVisible: false, //对话框显示，默认为false
        defaultProps: {
          children: 'children', //子菜单的属性名
          label: 'name' //显示的名称
        },
        dialogType: '', //对话框类型：添加add/更新edit
        dialogTitle: '',
        maxCategory: 0, //拖拽节点的最大深度
        updateNodes: [], //存放所有拖拽后的节点位置/排序/id等
        switchDraggable: false, //绑定是否开启拖拽
        pId: [], //全局pId，批量保存为数组,
        uniIds: [], //批量删除
        currentNode: '',
        loginUser:{},
      }
    },
    mounted () {
      this.loginUser=LocalStorage.get(LOGIN_USER)
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.menuTreeList()
      }

    },
    methods: {
      //多选的方法
      toggleSelection (rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
      },
      //1.菜单查询
      menuTreeList () {
        Loadings.show()
        this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/menu/tree?userId='+this.loginUser.userId+"&tenantId="+this.loginUser.tenantId).then((response) => {
          Loadings.hide()
          let resp = response.data
          this.resourcess = resp.data
          if(this.currentNode===''){
            for(let i=0;i<this.resourcess.length;i++){
              this.expendedKeys.push(this.resourcess[i].id)
            }
          }else{
            this.expendedKeys=[this.currentNode.data.id];
          }
          console.log('this.expendedKeys: ', this.expendedKeys)
        })
      },

      //新增主菜单
      addResource () {
        this.dialogType = '新增主菜单'
        this.dialogTitle = '新增主菜单资源'
        this.resources = {}
        this.dialogVisible = true
        this.resources.sort = 0
        this.resources.parentId = '10000000'
        this.resources.category = 1
        this.resources.isOpen = 1
      },
      //批量删除
      batchDel () {
        //let uniIds = [];
        let leafName = []
        let checkedLeaf = this.$refs.resourcesTree.getCheckedNodes()
        for (let i = 0; i < checkedLeaf.length; i++) {
          /*注意axios只接收json和dataForm数据，如果是其他如string、[]list数据，将在前端被拦截
          这里需要this.定义好的属性，axios自动转化为json
          * */
          this.uniIds.push(checkedLeaf[i].id)
          leafName.push(checkedLeaf[i].name)
        }
        //let ids=JSON.stringify(uniIds);
        this.$confirm(`是否批量删除当前【${leafName}】分类？`, '批量删除操作提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //axios默认不允许传输数组
          this.$axios.post(process.env.VUE_APP_SERVER + '/blade-system/menu/delBatch', {ids:this.uniIds})
            .then((response) => {
              let resp = response.data
              if (resp.success) {
                this.menuTreeList()
                //删除成功，展开之前的父节点
                this.expendedKeys = this.pId
              }
            })

        })
      },
      //新增子菜单
      append (node, data) {
        this.dialogType = '新增菜单'
        this.dialogTitle = '新增菜单资源'
        this.resources = {}
        this.dialogVisible = true
        this.resources.parentId = data.id
        //这里获取到的category是data中，而这里因为点击了父节点新增子节点，如果父节点为2，那么子节点+1=3
        this.resources.sort = 0
        this.resources.category = data.category * 1 + 1
        this.resources.isOpen = 1
        this.currentNode = node
        console.log('当前的节点1：', this.currentNode)
      },
      //4.修改菜单
      edit (node, resources) {
        this.dialogType = '更新菜单'
        this.dialogTitle = '更新菜单资源'
        this.dialogVisible = true
        this.resources = resources
        this.currentNode = node
        console.log('当前的节点2：', this.currentNode)
      },
      del (node, resources) {
        let _this = this
        let resourcesParam = resources
        this.$toast.showConfirm(resources.name, function () {
            _this.$axios.post(process.env.VUE_APP_SERVER + '/blade-system/menu/del/' + resourcesParam.id)
              .then((response) => {
                let resp = response.data
                if (resp.success) {
                  _this.menuTreeList()
                  //删除成功，展开之前的父节点
                  _this.expendedKeys = [node.parent.data.id]
                }else{
                  _this.$message({
                    type: 'error',
                    message: '删除菜单失败！',
                    duration: 2000,
                    offset: 110
                  })
                }

              })

          }
        )
      },
      save (formName) {
        let responseMsg = ''
        //前端校验
        this.$refs[formName].validate((valid) => {
          if (valid) {

            this.$axios.post(process.env.VUE_APP_SERVER + '/blade-system/menu/save', this.resources)
              .then((response) => {
                let resp = response.data
                responseMsg = response

                if (resp.success) {
                  //关闭对话框
                  this.dialogVisible = false
                  this.menuTreeList()
                  //展开父级菜单
                  //this.expendedKeys = [this.currentNode.data.parentId];
                  this.$message({
                    type: 'success',
                    message: '更新菜单成功！',
                    duration: 2000,
                    offset: 110
                  })
                } else {
                  this.$notify({
                    title: '填写内容错误',
                    message: resp.responseMsg,
                    position: 'top-right',
                    type: 'warning'
                  })
                }
              })
          } else {
            this.$notify({
              title: '填写内容错误',
              message: '请按照提示内容填写正确信息',
              position: 'top-right',
              type: 'warning'
            })
            return false
          }
        })
      },
      //批量保存：一键生成菜单
      saveJSON () {
        let responseMsg = ''
        if (Tool.isEmpty(this.resourcesJson)) {
          this.$toast.error('菜单内容不能为空，且必须是JSON格式！', 'center')
          return
        }
        let json = JSON.parse(this.resourcesJson)
        console.log('发送的json：', json)
        Loadings.show()
        //前端校验
        this.$axios.post(process.env.VUE_APP_SERVER + '/blade-system/menu/saveMenus', json)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            responseMsg = response
            if (resp.success) {
              //this.pageList(1);
              this.menuTreeList()
              //引入全局变量toast提示
              this.$toast.success('保存成功', 'center')
            } else {
              this.$notify({
                title: '更新失败，请联系管理员!',
                message: resp.responseMsg,
                position: 'top-right',
                type: 'warning'
              })
            }
          })
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  .resourceBox {
    padding: 20px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 100%;

  }

  /*资源*/
  .resourceRow {
    height: 700px;
  }

  .resourceBtn {
    float: right;
    margin-top: 15px;
    margin-bottom: 30px;
    margin-right: 10px;
  }

  .boxCardTitle {
    font-size: 18px;
    float: left;
    padding: 10px 20px;
    height: 100%;
  }

  .boxCardTitleDesc {
    float: left;
    margin-left: 10px;
    margin-top: 15px;
    color: #F56C6C;
  }

  /*树型控件*/
  /deep/ .el-tree-node__content {
    height: 30px;
  }

  .categoryTree {
    margin-top: 80px;
    float: left;
    margin-left: 20px;
    width: 90%;
  }

  .nodeIcon {
    margin-right: 15px;
    color: #2a92ee;
    font-size: 15px;
  }

  .labelName {
    margin-right: 30px;
    font-size: 15px;
  }

  .opt-btn-add {
    font-size: 13px;
    padding: 3px 6px;
    margin-right: 4px;
  }

  .opt-btn-edit {
    font-size: 13px;
    padding: 3px 6px;
    margin-right: 4px;
  }

  .opt-btn-del {
    font-size: 13px;
    padding: 3px 6px;

  }

  /*拖拽开关*/
  .switchDrag {
    margin-top: 20px;
    margin-bottom: 30px;
    position: absolute;
  }

  .switchDragChoose {
    float: left;
    font-size: 25px;
    margin-top: 8px;
    margin-left: 30px;
  }

  .switchDragBtn {
    float: left;
    margin-left: 20px;
  }

  /*模态框*/
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px 30px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-dialog__footer {
    text-align: center;
  }

  .dialog-footer button {
    width: 150px;
  }

  .cancelBtn:hover, .cancelBtn:focus {
    background: #fef0f0;
    border-color: #fbc4c4;
    color: #f56c6c;
  }

  .sureBtn {
    background: #e52a33;
  }
</style>
