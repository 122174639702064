<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link :to="type===2?'/admin/stockOrders':'/admin/supplierManager'">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">{{type===2?'返回进货开单':'返回供应商管理'}}
          </el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">{{this.type===1?'编辑供应商':'新增供应商'}}</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>

    <div class="mainForm">
      <el-form :model="supplier" :rules="rules" ref="supplier" label-width="120px"
               class="demo-supplier store-info-form">

        <el-form-item class="labelTitle" label="供应商名称" prop="supplierName" v-if="this.type === 0 || this.type===2">
          <el-autocomplete
            class="inputLine"
            style="width: 371px;"
            v-model="supplier.supplierName"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入供应商名称"
            @select="handleSelect2"
            @blur="checkSupplierNameIsRepeat">
            <template slot-scope="{ item }">
              <div class="name">{{ item.supplierName }} ( {{ item.supplierPerson }} : {{ item.supplierPhone }} )</div>
            </template>
          </el-autocomplete>
        </el-form-item>

        <el-form-item class="labelTitle" label="供应商名称" prop="clientName" v-if="this.type === 1">
          <el-input class="inputLine"
                    v-model="supplier.supplierName"
                    placeholder="请输入供应商名称"
                    maxlength="50"
                    @blur="checkSupplierNameIsRepeat">
          </el-input>
        </el-form-item>

        <el-form-item class="labelTitle" label="联系人" prop="supplierPerson">
          <el-input class="inputLine" v-model="supplier.supplierPerson" placeholder="请输入联系人" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item class="labelTitle" label="手机号" prop="supplierPhone">
          <el-input class="inputLine" v-model="supplier.supplierPhone" placeholder="请输入手机号" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item class="labelTitle" label="所在地区" prop="regionName">
          <!--
          <el-cascader class="checkArea"
            size="medium"
            :options="options"
            v-model="checkArea"
            @change="handleArea">
          </el-cascader>
          -->

          <el-cascader
            ref="regionCascader"
            class="checkArea"
            style="width: 480px;"
            size="large"
            :clearable="true"
            v-model="supplier.parentRegionCode"
            :props="props"
            @change="handlerChange">
          </el-cascader>
        </el-form-item>
        <el-form-item class="labelTitle" label="详细地址" prop="address">
          <el-input class="inputLine" v-model="supplier.address" placeholder="请输入您的详细地址" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item class="labelTitle" label="状态" prop="status" v-if="this.type === 1">
          <el-radio-group class="checkGroup" v-model="supplier.status" @change="radioChange">
            <el-radio-button class="statusOpen" :label="SUPPLIER_STATUS.OPEN.key">启用</el-radio-button>
            <el-radio-button class="statusStop" :label="SUPPLIER_STATUS.STOP.key">停用</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <div v-if="showStopInfo" class="showStopInfo">
          <div class="stopInfo">提示：供应商已停用，停用的供应商将不会出现在开单时的“选择供应商”页面。</div>
          <div class="stopInfo">可使用“供应商管理”页面显示出已停用的供应商。</div>
        </div>
        <el-form-item class="labelTitle" label="备注" prop="remark">
          <el-input id="stockDescription" class="inputCoreDesc" :rows="6"
                    type="textarea" maxlength="500" show-word-limit v-model="supplier.remark"
                    placeholder="请输入备注信息，最多500个字符"></el-input>
        </el-form-item>

        <el-form-item class="storeInfoSub">
          <el-button class="storeInfoBtn" type="danger" @click="save('supplier')">保存</el-button>
          <el-button class="resetBtn" @click="resetForm('supplier')" v-if="this.type === 0">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { regionData } from 'element-china-area-data'

  export default {
    name: 'SupplierManager',
    data () {
      let validate_supplierName = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (!value) {
          callback(new Error('请填写供应商名称'))
        } else if (getLength(value) > 50) {
          callback(new Error('供应商名称最多不能超过50个字符'))
        } else {
          callback()
        }
      }
      let validate_supplierPerson = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (!value) {
          callback(new Error('请填写联系人'))
        } else if (getLength(value) > 20) {
          callback(new Error('联系人最多不能超过50个字符'))
        } else {
          callback()
        }
      }
      let validate_supplierPhone = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请填写手机号'))
        } else if (!Pattern.validate('mobile', value)) {
          callback(new Error('请填写正确的手机号'))
        } else {
          callback()
        }
      }
      let validate_remark = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value && getLength(value) > 500) {
          callback(new Error('备注最多不能超过500个字符'))
        } else {
          callback()
        }
      }
      return {
        message: '供应商管理',
        type: 0,
        searchInput: '',
        checkedStop: false,//默认不显示停用供应商

        supplier: {
          supplierName: '',
          supplierPerson: '',
          supplierPhone: '',
          parentRegionCode: [],
          regionCode: '',
          regionName: '',
          address: '',
          status: 0,
          remark: ''
        },

        //联想下拉列表输入框
        restaurants: [],
        state: '',
        timeout: null,

        // //省市区三级联动
        // options:regionData,
        // checkArea: [],
        SUPPLIER_STATUS: SUPPLIER_STATUS,

        props: {
          lazy: true,
          lazyLoad: this.lazyLoad,
          value: 'code',
          label: 'name',
          checkStrictly: true,
          expandTrigger: 'hover', //鼠标移上触发加载下一级
        },

        //禁用提示
        showStopInfo: false,
        rules: {
          supplierName: [
            {
              required: true,
              validator: validate_supplierName,
              trgger: 'blur'
            }
          ],
          supplierPerson: [
            {
              required: true,
              validator: validate_supplierPerson,
              trgger: 'blur'
            }
          ],
          supplierPhone: [
            {
              required: true,
              validator: validate_supplierPhone,
              trgger: 'blur'
            }
          ],
          remark: [
            {
              validator: validate_remark,
              trgger: 'blur'
            }
          ]
        },
      }
    },
    created () {

    },
    mounted () {
      let supplierInfo = {}
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }
      this.type = this.$route.params.type
      supplierInfo.type = this.type
      //编辑：type=1 新增：type=0
      if (this.type === 1) {
        this.supplier = this.$route.params.item || {}
        this.regionHandler(this.supplier.parentRegionCode)
        //编辑时，自动设置好初始状态下的regionCode
        this.supplier.regionCode = this.supplier.parentRegionCode[this.supplier.parentRegionCode.length - 1]
        if (this.supplier.status === 1) {
          this.showStopInfo = true
        }
        supplierInfo.supplier = this.supplier
        SessionStorage.set(SUPPLIER_INFO, supplierInfo)
      } else if (this.type === 0) {
        //新增默认是启用状态
        this.supplier.status = 0
        supplierInfo.supplier = this.supplier
        SessionStorage.set(SUPPLIER_INFO, supplierInfo)
      } else if (this.type === 2) {
        //console.log('')
        supplierInfo.supplier = this.supplier
        SessionStorage.set(SUPPLIER_INFO, supplierInfo)
      }

      this.supplier = SessionStorage.get(SUPPLIER_INFO).supplier
      this.type=SessionStorage.get(SUPPLIER_INFO).type
      this.navChange()
    },
    methods: {
      querySearchAsync (queryString, cb) {
        /*
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 3000 * Math.random());
        */

        if (!this.supplier.supplierName || this.supplier.supplierName == '') {
          clearTimeout(this.timeout)
          cb([])
          return
        }

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/supplier/selectSupplierByName?supplierName=' + this.supplier.supplierName,
            method: 'GET',
          })
            .then((res) => {
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.restaurants = res.data.data
                  cb(this.restaurants)
                } else {
                  cb([])
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                cb([])
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }, 1000 * Math.random())
      },
      createStateFilter (queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        }
      },
      //供应商名称联想下拉列表选中回调
      handleSelect2 (item) {
        this.supplier.supplierName = item.supplierName
        this.supplier.supplierPerson = item.supplierPerson
        this.supplier.supplierPhone = item.supplierPhone
      },
      //检查供应商名称是否重复
      checkSupplierNameIsRepeat () {
        if (!this.supplier.supplierName) {
          return
        }
        if (this.type === 0 || this.type === 2) {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/supplier/checkGoodsName?supplierName=' + this.supplier.supplierName,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        } else if (this.type === 1) {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/supplier/checkGoodsName' +
              '?supplierName=' + this.supplier.supplierName +
              '&supplierId=' + this.supplier.supplierId,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
      },
      //原联想下拉列表的选中回填处理逻辑
      handleSelect (item) {
        this.supplier.supplierName = item.supplierName
        this.supplier.supplierPerson = item.supplierPerson
        this.supplier.supplierPhone = item.supplierPhone
        this.supplier.parentRegionCode = item.parentRegionCode
        this.supplier.address = item.address
        this.supplier.remark = item.remark

        let provCode, cityCode, distCode, townCode, villageCode
        for (let i = 0; i < this.supplier.parentRegionCode.length; i++) {
          if (i === 0) {
            provCode = this.supplier.parentRegionCode[i]
          } else if (i === 1) {
            cityCode = this.supplier.parentRegionCode[i]
          } else if (i === 2) {
            distCode = this.supplier.parentRegionCode[i]
          } else if (i === 3) {
            townCode = this.supplier.parentRegionCode[i]
          } else if (i === 4) {
            villageCode = this.supplier.parentRegionCode[i]
          }
        }
        if (provCode) {
          let store = this.$refs.regionCascader.$refs.panel.store
          //根据provCode获取指定列表项对象
          let provNode = store.nodes.find(item => item.value === provCode)
          //provNode.children = undefined;
          //为指定provNode节点添加子节点集合
          //store.appendNodes(cityList, provNode);
          //provNode.loaded = true;
          if (cityCode) {
            //调用panel的方法，模拟点击省级列表项的操作
            this.$refs.regionCascader.$refs.panel.handleExpand(provNode)
            let cityNode = provNode.children.find(item => item.value === cityCode)
            //cityNode.loaded = true;

            if (distCode) {
              //调用panel的方法，模拟点击市级列表项的操作
              this.$refs.regionCascader.$refs.panel.handleExpand(cityNode)

              let distNode = cityNode.children.find(item => item.value === distCode)
              //console.log("distNode：", distNode);
              //distNode.loaded = true;

              if (townCode) {
                //调用panel的方法，模拟点击区县级列表项的操作
                this.$refs.regionCascader.$refs.panel.handleExpand(distNode)

                let townNode = distNode.children.find(item => item.value === townCode)
                //console.log("townNode：", townNode);
                //townNode.loaded = true;

                if (villageCode) {
                  //调用panel的方法，模拟点击街道级列表项的操作
                  this.$refs.regionCascader.$refs.panel.handleExpand(townNode)

                  //let villageNode = townNode.children.find(item => item.value === villageCode);
                  //villageNode.loaded = true;
                  //调用panel的方法，模拟点击社区级列表项的操作
                  //this.$refs.regionCascader.$refs.panel.handleExpand(villageNode);
                }
              }
            }
          }

          //调用组件的方法，将文字回显出来（省/市/区）
          this.$refs.regionCascader.computePresentText()
        }
      },
      //区域下拉列表数据回显处理
      regionHandler (paramRegionCode) {
        let provCode, cityCode, distCode, townCode, villageCode
        for (let i = 0; i < paramRegionCode.length; i++) {
          if (i === 0) {
            provCode = paramRegionCode[i]
          } else if (i === 1) {
            cityCode = paramRegionCode[i]
          } else if (i === 2) {
            distCode = paramRegionCode[i]
          } else if (i === 3) {
            townCode = paramRegionCode[i]
          } else if (i === 4) {
            villageCode = paramRegionCode[i]
          }
        }

        setTimeout(() => {
          if (provCode) {
            let store = this.$refs.regionCascader.$refs.panel.store
            //根据provCode获取指定列表项对象
            let provNode = store.nodes.find(item => item.value === provCode)
            //为指定provNode节点添加子节点集合
            //store.appendNodes(cityList, provNode);
            provNode.loaded = true

            this.$refs.regionCascader.$refs.panel.handleExpand(provNode)

            if (cityCode) {
              //调用panel的方法，模拟点击省级列表项的操作
              this.$refs.regionCascader.$refs.panel.handleExpand(provNode)

              let cityNode = provNode.children.find(item => item.value === cityCode)
              cityNode.loaded = true

              if (distCode) {
                //调用panel的方法，模拟点击市级列表项的操作
                this.$refs.regionCascader.$refs.panel.handleExpand(cityNode)

                let distNode = cityNode.children.find(item => item.value === distCode)
                distNode.loaded = true

                if (townCode) {
                  //调用panel的方法，模拟点击区县级列表项的操作
                  this.$refs.regionCascader.$refs.panel.handleExpand(distNode)

                  let townNode = distNode.children.find(item => item.value === townCode)
                  townNode.loaded = true

                  if (villageCode) {
                    //调用panel的方法，模拟点击街道级列表项的操作
                    this.$refs.regionCascader.$refs.panel.handleExpand(townNode)

                    //let villageNode = townNode.children.find(item => item.value === villageCode);
                    //villageNode.loaded = true;
                    //调用panel的方法，模拟点击社区级列表项的操作
                    //this.$refs.regionCascader.$refs.panel.handleExpand(villageNode);
                  }
                }
              }
            }

            //调用组件的方法，将文字回显出来（省/市/区）
            this.$refs.regionCascader.computePresentText()
          }
        }, 1000)
      },
      handlerChange (value) {
        let provCode, cityCode, distCode, townCode, villageCode
        for (let i = 0; i < value.length; i++) {
          if (i === 0) {
            provCode = value[i]
          } else if (i === 1) {
            cityCode = value[i]
          } else if (i === 2) {
            distCode = value[i]
          } else if (i === 3) {
            townCode = value[i]
          } else if (i === 4) {
            villageCode = value[i]
          }
        }
        if (provCode) {
          if (cityCode) {
            let store = this.$refs.regionCascader.$refs.panel.store
            //console.log("store：", store);
            //根据provCode获取指定列表项对象
            let provNode = store.nodes.find(item => item.value === provCode)

            //调用panel的方法，模拟点击省级列表项的操作
            this.$refs.regionCascader.$refs.panel.handleExpand(provNode)

            let cityNode = provNode.children.find(item => item.value === cityCode)
            //console.log("cityNode：", cityNode);
            //cityNode.loaded = true;

            if (distCode) {
              //调用panel的方法，模拟点击市级列表项的操作
              this.$refs.regionCascader.$refs.panel.handleExpand(cityNode)

              let distNode = cityNode.children.find(item => item.value === distCode)
              //console.log("distNode：", distNode);
              //distNode.loaded = true;

              if (townCode) {
                //调用panel的方法，模拟点击区县级列表项的操作
                this.$refs.regionCascader.$refs.panel.handleExpand(distNode)

                let townNode = distNode.children.find(item => item.value === townCode)
                //console.log("townNode：", townNode);
                //townNode.loaded = true;

                if (villageCode) {
                  //调用panel的方法，模拟点击街道级列表项的操作
                  this.$refs.regionCascader.$refs.panel.handleExpand(townNode)

                  //let villageNode = townNode.children.find(item => item.value === villageCode);
                  //villageNode.loaded = true;
                  //调用panel的方法，模拟点击社区级列表项的操作
                  //this.$refs.regionCascader.$refs.panel.handleExpand(villageNode);
                }
              }
            }
          }

          //调用组件的方法，将文字回显出来（省/市/区）
          this.$refs.regionCascader.computePresentText()
        }

        //选中后隐藏下拉面板
        this.$refs.regionCascader.dropDownVisible = false

        if (value.length > 0) {
          this.supplier.regionCode = value[value.length - 1]
        } else {
          this.supplier.regionCode = ''
        }
      },
      lazyLoad (node, resolve) {
        let _this = this
        let parent_code = '00'
        if (node && node.data) {
          parent_code = node.data.code
        }
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/blade-system/region/select?code=' + parent_code,
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                if (res.data.data && res.data.data.length > 0) {
                  const nodes = res.data.data.map(item => ({
                    name: item.name,
                    code: item.code,
                    leaf: item.level === 5 ? true : false //是否是最后一级
                  }))
                  resolve(nodes)
                } else {
                  resolve([])
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (_this.type === 0 || _this.type === 2) {

              //校验供应商名称是否重复
              this.$axios.request({
                url: process.env.VUE_APP_SERVER + '/psi/supplier/checkGoodsName?supplierName=' + this.supplier.supplierName,
                method: 'GET'
              })
                .then((res) => {
                  if (res.status === 200) {
                    if (res.data.code != 200) {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    } else {
                      let checkedNode = _this.$refs.regionCascader.panel.getCheckedNodes()
                      if (checkedNode && checkedNode.length > 0) {
                        this.supplier.regionName = checkedNode[0].pathLabels.join(',')
                      }

                      Loadings.show()
                      this.$axios.post(process.env.VUE_APP_SERVER + '/psi/supplier/save', this.supplier)
                        .then((res) => {
                          Loadings.hide()
                          if (res.status === 200) {
                            if (res.data.code === 200) {
                              this.$message({
                                type: 'success',
                                message: '新增供应商成功！',
                                duration: 2000,
                                offset: 110
                              })
                              setTimeout(() => {
                                if (this.type === 2) {
                                  this.supplier = res.data.data
                                  if (Tool.isEmpty(this.supplier.supplierName)) {
                                    this.supplier.supplierName = this.supplier.supplierPerson
                                  }
                                  let type = 7
                                  this.$router.push({
                                    name: '/stockOrders',
                                    params: {
                                      type,
                                      item: this.supplier,
                                    }
                                  })
                                } else {
                                  //跳转到客户列表页面
                                  _this.$router.push('/admin/supplierManager')
                                }
                              }, 2000)
                            } else {
                              this.$message({
                                type: 'warning',
                                message: res.data.msg,
                                duration: 2000,
                                offset: 110
                              })
                            }
                          } else {
                            this.$message({
                              type: 'warning',
                              message: '网络异常',
                              duration: 2000,
                              offset: 110
                            })
                          }
                        })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                })
            } else if (_this.type === 1) {
              //校验供应商名称是否重复
              this.$axios.request({
                url: process.env.VUE_APP_SERVER + '/psi/supplier/checkGoodsName' +
                  '?supplierName=' + this.supplier.supplierName +
                  '&supplierId=' + this.supplier.supplierId,
                method: 'GET'
              })
                .then((res) => {
                  if (res.status === 200) {
                    if (res.data.code != 200) {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    } else {
                      let checkedNode = _this.$refs.regionCascader.panel.getCheckedNodes()
                      if (checkedNode && checkedNode.length > 0) {
                        this.supplier.regionName = checkedNode[0].pathLabels.join(',')
                      }

                      Loadings.show()
                      this.$axios.post(process.env.VUE_APP_SERVER + '/psi/supplier/update', this.supplier)
                        .then((res) => {
                          Loadings.hide()
                          if (res.status === 200) {
                            if (res.data.code === 200) {
                              this.$message({
                                type: 'success',
                                message: '编辑供应商成功！',
                                duration: 2000,
                                offset: 110
                              })
                              setTimeout(() => {
                                //跳转到客户列表页面
                                _this.$router.push('/admin/supplierManager')
                              }, 2000)
                            } else {
                              this.$message({
                                type: 'warning',
                                message: res.data.msg,
                                duration: 2000,
                                offset: 110
                              })
                            }
                          } else {
                            this.$message({
                              type: 'warning',
                              message: '网络异常',
                              duration: 2000,
                              offset: 110
                            })
                          }
                        })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                })
            }
          } else {
            return false
          }
        })
      },
      //2.停用供应商
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.supplier.status ? this.supplier.status = key:this.supplier.status=0;
      },
      //3.省市区
      handleArea (value) {
        //console.log('')
      },
      navChange () {
        let flag = 0
        if (this.type === 1) {
          flag = 1
        }
        //console.log("子组件发射flag：",flag)
        this.$emit('navChange', flag)
        SessionStorage.set(EDIT_FLAG,flag)
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-form-item__label:before {
    margin-left: 4px;
    float: right;
  }

  .mainPages {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  .checkArea {
    float: left;
  }

  /*停用启用*/
  .checkGroup {
    float: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
  }

  /deep/ .el-radio-button__inner, .el-radio-button:first-child {
    border: 0 !important;
  }

  .showStopInfo {
    text-align: left;
    margin-left: 120px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 450px;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  /deep/ .el-textarea .el-input__count {
    background: unset;
    bottom: -10px;
    right: 20px;
  }
</style>
