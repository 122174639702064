<template>
  <div class="mainPages">

    <div class="mainForm">
      <el-form :model="generateData" :rules="rules" ref="generateData" label-width="120px"
               class="demo-generateData store-info-form">

        <el-form-item class="labelTitle" label="订单时间" prop="dates">
          <el-date-picker
            v-model="generateData.dates"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            @change="dateChange">
          </el-date-picker>
        </el-form-item>


        <el-form-item class="labelTitle" label="生成订单数" prop="num">
          <el-input class="inputLine" v-model="generateData.num" placeholder="请输入生成订单数" maxlength="11"></el-input>
        </el-form-item>

        <el-button v-if="getAuthorityMenu('700401')" class="storeInfoBtn" type="primary" @click="save('generateData')">生成</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AutoGenerate',
    data () {

      let validate_dates = (rule, value, callback) => {
        // console.log("val: ",value)
        let start=this.dateFormat(value[0])
        let end=this.dateFormat(value[1])
        // console.log("start: ",start)
        // console.log("end: ",end)
        let days=this.getDays(start,end)
        // console.log("间隔：",days)
        if (!value) {
          callback(new Error('请选择订单时间'))
        }else if(days>365){
          callback(new Error('订单时间范围不能超过一年'))
        } else {
          callback()
        }
      }

      let validate_num = (rule, value, callback) => {
        let reg = /^\d+$/; // 非负整数
        // var regNeg = /^\-[1-9][0-9]*$/; // 负整数
        if (!value) {
          callback(new Error('请输入生成订单数'))
        }else if(!reg.test(value)){
          callback(new Error('订单数必须为正整数，请重新输入'))
        }else if(reg.test(value) && value>100){
          callback(new Error('每次最多生成100个订单，请重新输入'))
        } else {
          callback()
        }
      }

      return {
        message: '自动生成订单',
        value2:'',
        startDate:'',
        endDate:'',

        generateData: {
          dates: '',
          num:'',
        },

        rules: {

          dates: [
            {
              required: true,
              validator: validate_dates,
              trigger: 'change'
            }
          ],
          num: [
            {
              required: true,
              validator: validate_num,
              trigger: 'blur'
            }
          ],
        },
      }
    },
    created () {

    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }

    },
    methods: {
      dateChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.startDate = this.$moment(e[0]).format('YYYY-MM-DD')
          this.endDate = this.$moment(e[1]).format('YYYY-MM-DD')
        } else {
          this.startDate = ''
          this.endDate = ''
        }
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            let reqData={
              startTime:this.startDate,
              endTime:this.endDate,
              orderNumber:this.generateData.num
            }
            this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/generateOrder?startTime='+reqData.startTime+'&endTime='+reqData.endTime+'&orderNumber='+reqData.orderNumber)
              .then((res) => {
                Loadings.hide()
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '自动生成订单成功！',
                      duration: 2000,
                      offset: 110
                    })

                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        })
      },

      dateFormat(dateData) {
        var date = new Date(dateData)
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        var d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        const time = y + '-' + m + '-' + d
        return time
      },
      getDays(start, end) {
        let strSeparator = "-";
        let iDays=0
        let oDate1 = start.split(strSeparator);
        let oDate2 = end.split(strSeparator);
        //计算毫秒
        let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
        let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
        //把相差的毫秒数转换为天数
        iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24)+1
        return iDays;
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    },
  }
</script>

<style scoped>
  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-form-item__label:before {
    margin-left: 4px;
    float: right;
  }
  /deep/ .el-form-item__content{
    float: left;
    margin-left: 0 !important;
  }
  .mainPages {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  /*时间搜索*/
  .searchTime {
    float: left;
    margin-right: 10px;
  }

  /deep/ .el-date-editor .el-range-input {
    margin-left: 20px;
  }

  .storeInfoBtn {
    width: 150px;
    float: left;
    margin-left: 120px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }


  /deep/ .el-textarea .el-input__count {
    background: unset;
    bottom: -10px;
    right: 20px;
  }
</style>
