<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-radio-group v-model="type" class="cftype dataType">
					<el-radio :label="0">全部</el-radio>
					<el-radio :label="1">进货数据</el-radio>
					<el-radio :label="2">销货数据</el-radio>
					<el-radio :label="3">库存数据</el-radio>
				</el-radio-group>
				<el-date-picker class="cftype "
					v-model="value2"
					type="daterange"
					align="left"
					unlink-panels
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="dateChange"
					:picker-options="pickerOptions">
				</el-date-picker>
        <el-input class="searchInput"
                  placeholder="文件名称"
                  v-model="fileName"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
      </div>
      <div class="navRight">
        <el-button v-if="getAuthorityMenu('500401')" class="searchBtn" type="danger" @click="uploadFile">上传</el-button>
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="list"
        height="700px"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="80" fixed></el-table-column>
        <el-table-column property="fileName" label="文件名称" fixed></el-table-column>
        <el-table-column property="fileType" label="文件类型" width="200" :formatter="typeFormat"></el-table-column>
        <!-- <el-table-column property="happenLevel" label="时间" width="160" :formatter="cdFormat"></el-table-column> -->
        <el-table-column property="createTime" label="时间" width="240"></el-table-column>
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="200">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button 
              size="mini"
              type="primary"
              @click="down(scope.row)">下载
            </el-button>
            <el-button 
              size="mini"
              type="danger"
              @click="del(scope.row)">删除
            </el-button>
           
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper uploadFile">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
  </div>
</template>

<script>
  import { Upload } from 'element-ui'
import PageHelper from '../../../components/PageHelper'

  export default {
    name: 'RecipelrManager',
    components:{PageHelper},
    data () {
      return {
				type:0,
				fileName:'',
				value2:'',
				pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
				startDate:'',
				endDate:'',
        list: [],
        base_url:'',
        searchInput: '',
        currentRow: null,
        category: {},
        categoryId:'',
        value:''
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1);
        //this.getCategorys();
      }
      this.base_url=this.BASE_URL;
      this.navChange();
    },
    methods: {
       //时间选择
			 dateChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.startDate = this.$moment(e[0]).format('YYYY-MM-DD')
          this.endDate = this.$moment(e[1]).format('YYYY-MM-DD')
        } else {
          this.startDate = ''
          this.endDate = ''
        }
      },
      //格式化文件类型
			typeFormat(row) {
				if(row.fileType==1){
          return '进货数据'
        }else if(row.fileType==2){
          return '销货数据'
        }else if(row.fileType==3){
          return '库存数据'
        }else{
          return ''
        }
			},
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      getSearch (page,pageChange) {
        let current=page
        let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        if(this.categoryId===0){
          this.categoryId='';
        }
        Loadings.show();
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/orderfile/list?current='
          +current+"&size="+size+"&fileName="+this.fileName+"&fileType="+ (this.type== 0?'':this.type) +"&beginTime="+this.startDate+"&endTime="+this.endDate)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.list = resp.data.records;
            this.$refs.pageHelper.render(page, resp.data.total)
          })
      },
      reSearch (data) {
        this.getSearch(1,data);
      },
      //上传数据
			uploadFile() {
				this.$router.push({
          name: "/importEdit"
        });
			},
      handleCurrentChange (val) {
        this.currentRow = val
      },
			//下载
			down(item) {
				axios({
          method: 'get',
          url: process.env.VUE_APP_SERVER + '/psi/orderfile/download?id=' +item.id,
          responseType: 'blob',
          headers: {
            'blade-auth': 'bearer ' + LocalStorage.get(TOKEN)
          }
        })
          .then((res) => {
            const link = document.createElement('a')
            let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
            let temp = res.headers["content-disposition"].split(";")[2].split("filename*=")[1];
            let fileTemp=temp.substr(7,temp.length)
            //let temp = res.data;
            let fileName = decodeURIComponent(fileTemp);
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }).catch(error => {
          console.log(error)
        })
        // this.$axios.get(process.env.VUE_APP_SERVER + '/psi/orderfile/download?id='+ item.id)
        //   .then((response) => {
        //     Loadings.hide()
        //     let resp = response.data
        //     this.list = resp.data.records;
        //     this.$refs.pageHelper.render(page, resp.data.total)
        //   })
				
			},
      //删除
      del(item){
				let that = this
				this.$toast.showOrderConfirm('提示','', '是否确定删除', '确定', function () {
					that.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/orderfile/delete?ids=' + item.id ,
            method: 'post',
          })
            .then((res) => {
              if (res.status == 200) {
                if (res.data.code == 200) {
                  that.$message({
                    type: 'success',
                    message: '删除成功',
                    duration: 2000,
                    offset: 110
                  })
                  that.reSearch()
                } else {
                  that.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                that.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
				})
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }
  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 630px;
  }
	/deep/ .el-date-editor .el-range-separator {
		width: 6%;
	}
  .categoryInput{
    float: left;
    margin-right: 10px;
  }
  /* 上传样式 */
  .cftype{
      float: left;
      margin-right: 10px;
  }
	.dataType  {
		margin-top: 12px;
	}
	.uploadFile .pageNav {
		margin-top: 18px !important;
	}
</style>
