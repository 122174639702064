<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">库存盘点</div>
        <!-- <el-link class="supplierName" type="info" :underline="false" icon="el-icon-d-arrow-right">商品名称（{{good.goodsName}}）</el-link> -->
      </div>
      <div class="orderNav">
        <el-form :model="good" :rules="rules" ref="good" label-width="120px" class="demo-good store-info-form">
          <el-form-item class="labelTitle" label="商品名称" prop="inventory" style="text-align: left;">
            <span>{{ good.goodsName }}</span>
          </el-form-item>
          <el-form-item class="labelTitle" label="商品原库存" prop="inventory" >
            <el-input class="inputLine" v-model="good.inventory"  maxlength="50" disabled></el-input>
          </el-form-item>
          <el-form-item class="labelTitle" label="当前盘点库存" prop="newInventory">
            <el-input-number
              class="inputLine"
              style="width: 220px;"
              step-strictly
              placeholder="当前盘点库存"
              v-model="good.newInventory"
              :min="0"
              :max="9999999">
            </el-input-number>
          </el-form-item>
          <div class="toastText">注：减少的库存数量将成为报损数量</div>
          <div class="bottomBtn">
            <el-button class="storeInfoBtn" type="danger" @click="saveStock('good')">保存</el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {
    },
    data () {
      let validate_newInventory = (rule, value, callback) => {
        if (!value && value != 0) {
          callback(new Error('请输入库存数量'));
        } else if (value >= this.good.inventory) {
          callback(new Error('当前盘点库存数量不能大于等于现有库存数量'));
        } else {
          callback();
        }
      }
      return {
        message: '商品库存盘点',
        supplierName: {
          default: '-'
        },
        good: {},
        centerDialogVisible: false,
        rules: {
          newInventory: {
            validator: validate_newInventory,
            trgger: 'blur'
          }
        },
      }
    },
    mounted () {

    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openStockCheck (good) {
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true;
        this.good = good;
      },

      saveStock (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show();
            this.$axios.get(process.env.VUE_APP_SERVER + '/psi/warehousegoodsrel/upShelves?goodsId=' + this.good.goodsId +
              '&inventory=' + this.good.newInventory)
            .then((res) => {
              Loadings.hide()
              this.$message({ type: 'success', message: '盘点库存成功！', duration: 2000, offset: 110 });
              this.stockChange();
            });
          }
        });
      },

      stockChange(){
        this.$emit("stockChange",this.good)
        this.centerDialogVisible=false;
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    width: 450px !important;
    margin-top: 300px !important;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }
  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }
  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    font-size: 18px;
    margin-left: 10px;
    color:  #ffffff;
    height: 40px;
    margin-top: 15px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }
  /deep/ .el-form-item{
    margin-bottom: 10px;
  }
  /deep/ .el-form-item__content {

  }

  .showCheck span{
    color: rgba(255, 102, 0, 0.9);
  }

  .orderNav {
    position: relative;
    margin-bottom: 20px;
  }
  .inputLine{
    max-width: 210px;
    float: left;
  }

  .bottomBtn{
    display: inline-block;
    margin-top: 30px;
    /*position: absolute;
    bottom: 80px;
    left: 45%;
    margin: 0 auto;*/
  }
  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }
  .toastText{
    color: #e52a33;
    font-size: 12px;
    text-align: center;
  }
</style>
