<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link to="/admin/supplierManager">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回供应商管理</el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">{{'供应商往来明细'}}</el-link>
        <el-link class="supplierName" type="warning" :underline="false" icon="el-icon-s-claim">{{supplier.supplierName}}</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>

    <!--
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="输入商品名称"
                  value-format="yyyy-MM-dd"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  :clearable="true">
        </el-input>

        <div class="block searchTime">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <el-button class="searchBtn" type="danger" @click="queryList(1)">搜索</el-button>

        <div class="checkStop">
          <template>
            <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示已撤销订单</el-checkbox>
          </template>
        </div>

      </div>
      <div class="navRight">
        <el-button class="searchBtn" type="danger" @click="addSupplier">新增供应商</el-button>
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
      </div>
    </div>
    -->

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="supplierOrders"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" fixed></el-table-column>
        <el-table-column property="orderName" label="订单类型" width="150" sortable></el-table-column>
        <el-table-column property="createTime" label="开单时间" width="160" fixed></el-table-column>
        <!-- <el-table-column property="goodsName" label="购买商品" sortable></el-table-column> -->
        <el-table-column property="goodsName" label="购买商品" sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthGoods" placement="bottom" @mouseenter.native="remarkEnter(scope.row.goodsName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.goodsName===''?'-':scope.row.goodsName}}
              </div>
              <span class="showTipLine">{{scope.row.goodsName===''?'-':scope.row.goodsName}}</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- <el-table-column property="amount" label="实收金额" width="150" sortable></el-table-column> -->
        <el-table-column property="paymentAmount" label="单据金额" width="250" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.orderName==='进货开单'?'danger':'warning'" >{{ scope.row.orderName === '进货开单' ? '实付' : '实退' }} ￥ {{scope.row.amount}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column property="status" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{SUPPLIER_ORDER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <!--<el-table-column property="remark" label="备注"  :show-overflow-tooltip="true">
          <template slot-scope="scope" >
            <el-tag type="info" class="showCenter">
              {{scope.row.remark===""?"-":scope.row.remark}}
            </el-tag>
          </template>
        </el-table-column>-->
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="180">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="detail(scope.row)">查看详情
            </el-button>
            <el-button size="mini" v-if="(getAuthorityMenu('200201') || ((getAuthorityMenu('200401') || getAuthorityMenu('50030401'))))"
                      style="width: 68px;"
                      :type="scope.row.status == 0 ? 'danger' : 'warning'"
                      @click="cancelOrder(scope.row)">{{ scope.row.status == 0 ? '撤单' : '已撤单' }}
            </el-button>
            <!--
            <el-button v-if="scope.row.status===0"
              size="mini"
              :type="scope.row.status===0?'warning':'danger'"
              @click="cancelOrder(scope.row)">{{scope.row.status===0?'撤单':'已撤销'}}
            </el-button>
            <el-button
              size="mini"
              type="success"
              @click="reOrder(scope.row)">再来一单
            </el-button>
            -->
            <!--<el-button
              size="mini"
              type="warning"
              @click="del">排序
            </el-button>-->
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="queryList" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-supplier-order-detail ref="dialogSupplierOrderDetail" @cancelOrder="cancelOrder2"></dialog-supplier-order-detail>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogSupplierOrderDetail from '../../../components/DialogSupplierOrderDetail'
  export default {
    name: 'SupplierManagerSaleDetail',
    components:{PageHelper,DialogSupplierOrderDetail},
    data () {
      return {
        message: '供应商往来明细',
        searchInput: '',
        supplierName: '',
        supplier: {
          supplierName: '',
          supplierPerson: '',
          supplierPhone: '',
          status: 0
        },
        supplierOrder:{},
        supplierOrders:[],
        checkedStop: false,//默认不显示停用供应商
        currentRow: null,
        SUPPLIER_ORDER_STATUS:SUPPLIER_ORDER_STATUS,
        //弹框
        centerDialogVisible: false,
        //时间选择器
        startDate: '',  //开始时间
        endDate: '', //结束时间
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value1: '',
        value2: '',

        remarkFlag: false,
      }
    },
    mounted () {
      if(this.$route.params.item){
        this.supplier =this.$route.params.item
        SessionStorage.set(WANGLAIMINGXI_SUPPLIER, this.supplier)
      }else{
        this.supplier = SessionStorage.get(WANGLAIMINGXI_SUPPLIER)
      }
      this.navChange()
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.queryList(1);
      }
    },
    methods: {
      navChange () {
        let flag = 0
        if (this.type === 1) {
          flag = 1
        }
        this.$emit('navChange', flag)
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      queryList(page) {
        let current = page;
        let size = this.$refs.pageHelper.size; //引用传入组件后，在标签中refs定义的别名

        //接口入参
        //Long supplierId, String goodsName, String startDate, String endDate, String orderType, Integer orderStatus, Query query

        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/supplier/transactionDetailForPc' +
            '?current=' + current +
            '&size=' + size +
            '&supplierId=' + this.supplier.supplierId +
            '&goodsName=' + this.searchInput +
            (this.startDate ? '&startDate=' + this.startDate : '') +
            (this.endDate ? '&endDate=' + this.endDate : '') +
            '&orderType=0' +
            '&orderStatus=1',
          method: 'GET'
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.supplierOrders = res.data.data.records;
              for(let i=0;i<this.supplierOrders.length;i++){
                this.supplierOrders[i].amount=parseFloat(this.supplierOrders[i].amount).toFixed(2)
              }
              this.$refs.pageHelper.render(page, res.data.data.total);
            } else {
              this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
            }
          } else {
            this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
          }
        });
      },
      getSearch (page,pageChange) {
        Loadings.show()
        let current=page
        let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let keywords=this.searchInput
        let showStop=0
        if(this.checkedStop===false){
          showStop=0
        }else{
          showStop=1
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/supplier/transactionDetail?current='
          +current+"&size="+size+"&keywords="+keywords+"&showStop="+showStop+"&supplierId="+this.supplier.supplierId)
        .then((response) => {
          Loadings.hide()
          let resp = response.data
          let orders = resp.data.records;
          for(let i=0;i<orders.length;i++){
            if(orders[i].orderList.length>0){
              for(let j=0;j<orders[i].orderList.length;j++){
                orders[i].orderList[j].amount=parseFloat(orders[i].orderList[j].amount).toFixed(2)
                this.supplierOrders.push(orders[i].orderList[j]);
              }
            }
          }
          this.$refs.pageHelper.render(page, resp.data.total)
        })
      },
      reSearch (data) {
        this.queryList(this.$refs.pageHelper.page);
      },
      //2.已撤销订单
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.supplier.status ? this.supplier.status = key:this.supplier.status=0;
      },
      checkedStopClick () {
        //console.log('当前的checkedStop: ', this.checkedStop)
      },
      //订单详情
      detail(item) {
        this.$nextTick(() => {
          let orderId = item.orderId;
          let supplierName = this.supplier.supplierName;
          let type = item.orderName === '进货开单' ? 1 : 2;
          this.$refs.dialogSupplierOrderDetail.getOrderId(orderId, supplierName, type);
        })
      },
      //撤销订单
      cancelOrder(row) {
        if (row.status === 1) {
          this.$message({ type: 'info', message: '该条记录已撤单！', duration: 2000, offset: 110 });
          return;
        }

        let orderId = row.orderId;
        let type = row.orderName === '进货开单' ? 1 : 2;
        if (type == 1) {
          Loadings.show();
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/buyorder/cancel?buyOrderId=' + orderId,
            method: 'GET'
          })
          .then((res) => {
            Loadings.hide();
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$message({ type: 'success', message: '撤单成功！', duration: 2000, offset: 110 });
                this.queryList(this.$refs.pageHelper.page);
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          })
        } else if (type == 2) {
          Loadings.show();
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/buyreturnorder/cancel?buyReturnOrderId=' + orderId,
            method: 'GET'
          })
          .then((res) => {
            Loadings.hide();
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$message({ type: 'success', message: '撤单成功！', duration: 2000, offset: 110 });
                this.queryList(this.$refs.pageHelper.page);
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          })
        }
      },
      cancelOrder2(orderId, type) {
        if (type == 1) {
          Loadings.show();
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/buyorder/cancel?buyOrderId=' + orderId,
            method: 'GET'
          })
          .then((res) => {
            Loadings.hide();
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$message({ type: 'success', message: '撤单成功！', duration: 2000, offset: 110 });
                this.queryList(this.$refs.pageHelper.page);
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          })
        } else if (type == 2) {
          Loadings.show();
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/buyreturnorder/cancel?buyReturnOrderId=' + orderId,
            method: 'GET'
          })
          .then((res) => {
            Loadings.hide();
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$message({ type: 'success', message: '撤单成功！', duration: 2000, offset: 110 });
                this.queryList(this.$refs.pageHelper.page);
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          })
        }
      },
      //再来一单
      reOrder(item){
        //console.log("跳转进货开单页面：",item)
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //时间选择
      dateChange(e){
        this.startDate = this.$moment(e[0]).format("YYYY-MM-DD");
        this.endDate = this.$moment(e[1]).format("YYYY-MM-DD");
        //console.log("startDate：",startDate," endDate: ",endDate);
        this.queryList();
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .mainPages {
    min-height: 850px;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    margin-right: 10px;
  }
  .supplierName{
    margin-top: -5px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }
  /*搜索导航*/
  .topNav {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 180px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*时间搜索*/
  .searchTime{
    float: left;
    margin-right: 10px;
  }
  /deep/ .el-date-editor .el-range-input{
    margin-left: 20px;
  }
  /*表格*/
  .mainForm{
    margin-top: 10px;
  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 560px;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }
  .toolTipWidth {
    max-width: 200px !important;
    text-align: center !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
    text-align: center;
  }
  .showTipLine{
    line-height: 30px;
    height: 30px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address{
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showRemarkLine{
    height: 30px !important;
    line-height: 30px !important;
    overflow: hidden;
  }
</style>
