import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import {Message} from 'element-ui'
import enumsFilter from '../public/static/js/utils/enumsFilter'

// 引入element样式
import 'element-ui/lib/theme-chalk/index.css'
import Swal from 'sweetalert2'
//引入日期格式化
import Moment from 'moment'
//条形码插件
import VueBarcode from "vue-barcode";

Vue.component('barcode', VueBarcode);

Vue.use(ElementUI)

//Vue.use(toast)
Vue.prototype.$moment=Moment
Vue.prototype.$Swal=Swal
// 阻止显示生产模式的消息
Vue.config.productionTip = false
// 解决前后端分离，每次请求sessionId不同（验证码+登录问题，需要相同验证码）
axios.defaults.withCredentials=true
//vue全局组件监听事件总线，所有组件发送请求都必须经过这个总线$event
Vue.prototype.$event=new Vue();
Vue.prototype.$axios=axios;
Vue.prototype.BASE_URL=process.env.VUE_APP_SERVER

/**
 * axios请求日志拦截
 */
axios.interceptors.request.use(function (request) {
  if (LocalStorage.get(TOKEN)) {
    Object.assign(request.headers, { 'blade-auth': 'bearer ' + LocalStorage.get(TOKEN) });
  }
  //console.log("发出请求：",request);
  return request;
}, error => {})
let routeIndex=0
axios.interceptors.response.use(function (response) {
  //console.log("响应数据：",response)
  let returnFlag=true

  if (response.status === 200) {
    if (response.data.code === 401) {

      returnFlag=false

      SessionStorage.clearAll();

      LocalStorage.remove(TOKEN)
      LocalStorage.remove(TENANT_INFO)
      LocalStorage.remove(LOGIN_USER)
      LocalStorage.remove(IS_LOG)

      LocalStorage.remove(IS_EXISTS_SHOPINFO)
      Loadings.hide()
      routeIndex+=1
      if(document.title=='牡丹江市慧台账电脑版'){
        router.push({
          // path:'/mudanjianglogin?resetLogin='+true,
          path:'/mudanjianglogin',
          query:{resetLogin:true,routeIndex:routeIndex}
        });
      }else{
        router.push({
          path:'/login',
          // path:'/mudanjianglogin',
          query:{resetLogin:true,routeIndex:routeIndex}
        });
      }
      console.log("response.data.msg: ",response.data.msg)
      Message({
        type: 'warning',
        message: response.data.msg,
        duration: 2000,
        offset: 130
      })

    }else if(response.data.code===333){

      if(window.location.pathname.indexOf('mudanjianglogin')==-1 && window.location.pathname.indexOf('login')==-1 && window.location.pathname!='/'){
        returnFlag=false
        SessionStorage.clearAll();

        LocalStorage.remove(TOKEN)
        LocalStorage.remove(TENANT_INFO)
        LocalStorage.remove(LOGIN_USER)
        LocalStorage.remove(IS_LOG)

        LocalStorage.remove(IS_EXISTS_SHOPINFO)
        if(document.title=='牡丹江市慧台账电脑版'){
          router.push({
            path:'/mudanjianglogin',
            query:{
              showModel:true,
            }
          });
        }else{
          router.push({
            path:'/login',
            query:{
              showModel:true,
            }
          });
        }

      }else{
        returnFlag=true

      }
    }
  }
  if(returnFlag){
    return response;
  }

}, error => {})

//增加enumsFilter全局过滤器(js)：用于下拉框的显示效果
Object.keys(enumsFilter).forEach(key=>{
  Vue.filter(key,enumsFilter[key])
})

//全局toast
const toast = {
  showOK: function (message, position) {
    Swal.fire({
      icon: 'success',
      position: position,
      title: message,
      timer:1500,
      showConfirmButton:false
    })
  },
  showAuth: function (message, position) {
    Swal.fire({
      icon: 'error',
      position: position,
      title: message,
      timer:2000,
      showConfirmButton:false
    })
  },
  success: function (message, position) {
    Swal.fire({
      icon: 'success',
      position: position,
      title: message,
      confirmButtonText: `知道了`,
      confirmButtonColor: '#67C23A',
      timer:1500
    })
  },
  error: function (message, position) {
    Swal.fire({
      icon: 'error',
      position: position,
      title: message,
      confirmButtonText: `知道了`,
      confirmButtonColor: '#e92322',
    })
  },
  warning: function (icon, position, message, timer) {
    Swal.fire({
      icon: icon,
      position: position,
      title: message,
      timer: timer,
      confirmButtonText: `知道了`,
      confirmButtonColor: '#e92322',
    })
  },
  info: function (message, position) {
    Swal.fire({
      icon: 'info',
      position: position,
      title: message,
    })
  },
  //删除弹出提示：回调
  showConfirm: function (confirmParams, callback) {
    Swal.fire({
      title: '确定要删除 &nbsp;<span style="color: #e92322;display: inline-block;margin-top: 10px">' + confirmParams + '</span>&nbsp; ? ',
      text: '删除后不可恢复，请确认删除内容！',
      showDenyButton: true,
      confirmButtonText: `确定`,
      confirmButtonColor: '#e92322',
      denyButtonText: `取消`,
      denyButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (callback) {
          callback()
        }
        Swal.fire({
          icon: 'success',
          position: 'center',
          title:confirmParams,
          confirmButtonText: `操作完成`,
          confirmButtonColor: '#67C23A',
          timer: 1500
        })
      }
    })
  },

  showCodeConfirm: function (confirmParams, goodName, text, doIt, callback) {

    Swal.fire({
      title: confirmParams + '&nbsp;<span style="color: #e92322;display: inline-block;margin-top: 10px">' + goodName + '</span>&nbsp; ',
      text: text,
      showDenyButton: true,
      confirmButtonText: doIt,
      confirmButtonColor: '#e92322',
      denyButtonText: `取消`,
      denyButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (callback) {
          callback()
          //console.log("callback: ",callback)
        }
        Swal.fire({
          icon: 'success',
          position: 'center',
          confirmButtonText: `操作完成`,
          confirmButtonColor: '#e92322',
          timer: 1500
        })
        //Swal.fire(confirmParams+' 成功!', '', 'success');
      }
    })
  },

  showOrderConfirm: function (confirmParams, goodName, text, doIt, callback) {

    Swal.fire({
      title: confirmParams + '&nbsp;<span style="color: #e92322;display: inline-block;margin-top: 10px">' + goodName + '</span>&nbsp; ',
      text: text,
      showDenyButton: true,
      confirmButtonText: doIt,
      confirmButtonColor: '#e92322',
      denyButtonText: `取消`,
      denyButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (callback) {
          callback()
        }

      }
    })
  },
}

Vue.prototype.$toast=toast

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//console.log("全局dev/prod环境配置: ", process.env.NODE_ENV);
//console.log("全局dev/prod环境前缀: ", process.env.VUE_APP_SERVER);
