<template>
  <el-container class="container">
    <el-header>
      <div class="leftLogo">
        <el-image class="logoImg" :src="imgUrls.logo"></el-image>
      </div>
      <div class="rightTitle">
        <div class="titleText">慧台账 实名购药认证</div>
      </div>
    </el-header>
    <!--<el-scrollbar>-->
    <el-main>

      <div class="authenticationBox">
        <el-alert
          :title="'恭喜您在 '+client.tenantName+' 实名认证成功'"
          type="success" :closable="false" center
          effect="dark">
        </el-alert>
        <div class="authenticationInfo">

          <div class="imgBox">
            <div class="infoBox">
              <span class="infoTitle">姓名：</span>
              <span class="infoDesc">{{client.realName}}</span>
            </div>
            <div class="infoBoxsfz">
              <span class="infoTitle">身份证号：</span>
              <span class="infoDesc">{{client.decryptCode}}</span>
            </div>
            <div class="infoBoxadd">
              <span class="infoTitle">详细地址：</span>
              <span class="infoDescAdd">{{client.addressDesc}}</span>
            </div>
          </div>
          <img class="identityCard img-responsive" :src="imgUrls.identityCard_empty_height">
        </div>
      </div>
      <el-button class="storeInfoBtn " type="primary"><i class="glyphicon glyphicon-saved"></i></el-button>
    </el-main>
  </el-container>
</template>

<script>

  export default {
    name: 'IdentityScan',
    data () {
      return {
        message: '实名认证完成页面',
        tenantInfo: {},
        client: {
          realName: '无',
          identityCode: '无',
          clientPhone: '',
          addressDesc: '无',
          tenantName: '无',
          decryptCode:'',
        },
        imgUrls: {
          logo: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/main/login_logo_single.png',
          del_grey: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/del_grey.png',
          identityCard_empty: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/identityCard_empty-1.png',
          identityCard_empty_height: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/identityCard_empty-height-1.png',
        },
        cropTree: [],
        cropNoTree: [],
        checkCrops: [],//选中的id
        checkCropsDetail: [],//选中的作物对象
        inputValue: '',
        keywords: '',
        num: 1,
        drawer: false,//抽屉
        direction: 'btt',
        cameraPick: false,

        //联想下拉列表输入框
        restaurants: [],
        state: '',
        timeout: null,
        //作物选择
        isIndeterminate: true,
        indexNum: 0, //必填字段，为键盘索引，每个键盘必须具有唯一值
        //oldValue: '', //从父组件传递给子组件的值
        nullError: false,
        formatError: false,
        secretPsd: '',//加密证件号
        decryptPsd: '',//解密证件号
        decryptCode: '',//解密就加密证件号
      }
    },
    mounted () {

      //document.title="实名认证"
      //console.log('传入采纳数: ', this.$route.params)
      if(this.$route.params){
        this.client=this.$route.params
        if(this.$route.params.idCode){
          this.client.identityCode=BaseDecrypt.decode(this.$route.params.idCode)
          let subID=this.client.identityCode.substring(1,17)
          let subSecret=[]
          for(let i=0;i<subID.length;i++){
            subSecret.push("*")
          }
          this.client.decryptCode=this.client.identityCode.substr(0,1)+subSecret.toString().replace(/,/g,'')+this.client.identityCode.substr(this.client.identityCode.length-1)
        }
        if(Tool.isEmpty(this.client.decryptCode)){
          this.client.decryptCode="******************"
        }
        this.$forceUpdate()
      }else{
        this.$router.push("/identityScan")
      }

    },
    methods: {},
  }
</script>

<style scoped>
  .container {
    background: #E9EEF3;
    padding: 0;
    /*overflow-x: hidden;
    overflow-y: hidden;*/
    width: 100%;
  }

  .el-header {
    background-color: #1684F0;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 9;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 40%);
  }

  .el-footer {
    background-color: #1684F0;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .storeInfoBtn {
    background: #1684F0;
    border-color: #1684F0;
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    line-height: 60px;
    padding: 0;
    position: fixed;
    bottom: 0;
    border-radius: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 22px 0 rgb(0 0 0 / 60%);
    z-index: 1;
  }

  .storeInfoBtn:focus, .storeInfoBtn:hover {
    background: #1684F0 !important;
    border-color: #1684F0 !important;
    color: #ffffff;
  }

  .el-main {
    margin-top: 65px;
    background-color: #ffffff;
    color: #333;
    text-align: center;
    padding: 10px;
  }

  .leftLogo {
    background: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 5px;
    float: left;
  }

  .logoImg {
    width: 35px;
    height: 35px;
    float: left;
    margin-left: 7px;
    margin-top: 7px;
  }

  .titleText {
    color: #ffffff;
    font-size: 18px;
  }

  .authenticationInfo {
    margin-top: 20px;

  }

  .imgBox {
    padding: 10px;
    height: 300px;
    background: #f6fcfc;
    border-radius: 10px;
    position: relative;
  }

  .identityCard {
    position: absolute;
    top: 140px;
    padding-left: 10px;
    padding-right: 30px;
    opacity: 10%;
  }

  .infoBox {
    margin-top: 25px;
    height: 50px;
    color: black;
  }

  .infoBoxsfz {
    height: 50px;
    color: black;
  }
  .infoBoxadd {
    color: black;
  }

  .infoTitle {
    font-size: 18px;
    color: black;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    width: 90px;
    float: left;
    margin-left: 15px;
    margin-right: 10px;
  }

  .infoDesc {
    font-size: 16px;
    float: left;
    line-height: 50px;
  }

  .infoDescAdd {
    font-size: 16px;
    float: left;
    line-height: 30px;
    margin-left: 20px;
    text-align: left;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap !important;
    padding-right: 15px;
  }
</style>
