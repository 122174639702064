<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">更新购物车商品</div>
        <el-link class="supplierName" type="info" :underline="false" icon="el-icon-d-arrow-right">客户（<span>{{clientName}}</span>）
        </el-link>
      </div>
      <div class="orderNav">
        <el-form :model="good" :rules="rules" ref="good" label-width="120px" class="demo-good store-info-form">
          <el-row :gutter="20" class="rowForm">
            <el-col :span="12">
              <el-form-item class="labelTitle" label="商品名称" prop="goodName">
                <el-input disabled class="inputLine" v-model="good.goodsName" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="包装规格" prop="packingSpecification">
                <el-input disabled class="inputLine" v-model="good.packageUnit" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="包装单位" prop="packageLevel">
                <div v-if="good.packageLevel===2">
                  <el-radio-group class="checkGroup" v-model="good.buyPackageLevel" @change="radioChange"
                                  :disabled="isHLJ && !isDN && hljMode===1">
                    <el-radio-button class="statusOpen" :label="PACKAGE_LEVEL.SMALL.key">{{PACKAGE_LEVEL.SMALL.value}}
                    </el-radio-button>
                    <el-radio-button class="statusStop" :label="PACKAGE_LEVEL.BIG.key">{{PACKAGE_LEVEL.BIG.value}}
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <div v-if="good.packageLevel===1">
                  <el-radio-group class="checkGroup" v-model="good.buyPackageLevel" @change="radioChange"
                                  :disabled="isHLJ && !isDN && hljMode===1">
                    <el-radio-button class="statusOpen" :label="PACKAGE_LEVEL.SMALL.key">{{PACKAGE_LEVEL.SMALL.value}}
                    </el-radio-button>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item class="labelTitle" :label="returnFlag===true?'退货单价':'销货单价'" prop="goodsPrice">
                <el-input-number class="inputGroup" @change="priceChange" v-model="good.goodsPrice" :precision="2"
                                 :step="0.1" :min="0.00" :max="999999.99" placeholder="请输入单价"></el-input-number>
                <div v-if="good.buyPackageLevel===1" class="priceUnit">
                  <!--<div class="unitName">{{good.smallUnitName}}</div>-->
                  <div class="unitName">元</div>
                  <div class="slash">/</div>
                  <div class="unitName">{{good.middleUnitName}}</div>
                </div>
                <div v-if="good.buyPackageLevel===2" class="priceUnit">
                  <!--<div class="unitName">{{good.middleUnitName}}</div>-->
                  <div class="unitName">元</div>
                  <div class="slash">/</div>
                  <div class="unitName">{{good.bigUnitName}}</div>
                </div>
              </el-form-item>
              <el-form-item class="inputGroup" :label="returnFlag===true?'退货数量':'销货数量'" prop="inventory">
                <el-input-number :disabled="isHLJ && !isDN && hljMode===1" @change="countChange" @input="countInput" :min="1"
                                 :max="99999" class="inputGroup numInputCount" v-model="good.goodsNumber"
                                 placeholder="请输入数量"></el-input-number>
                <div class="priceUnit">
                  <div class="unitOneName">{{good.buyPackageLevel===1?good.middleUnitName:good.bigUnitName}}</div>
                </div>
              </el-form-item>

              <el-form-item class="inputGroup" label="每亩用量" prop="usageAmount" v-if="saleType">
                <el-input-number @blur="amountChange(good.usageAmount)" :precision="2" :step="1" :min="0.01"
                                 :max="9999.99" class="inputGroup" v-model="good.usageAmount"
                                 placeholder="请输入数量"></el-input-number>
                <div class="priceUnit">
                  <div class="unitOneName">{{good.smallUnitName}}/亩</div>
                </div>
              </el-form-item>
              <el-form-item class="inputGroup" label="用药方法" prop="usageId" v-if="good.usageAmount>0 && saleType">
                <el-select v-model="good.usageId" placeholder="请选择">
                  <el-option
                    v-for="item in usageList"
                    :key="item.usageId"
                    :label="item.usageName"
                    :value="item.usageId">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item class="totalMoney" label="小计" prop="saleBigPrice">
                <span class="totalPrice">{{good.totalPrice}} 元</span>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-card class="imageBlock">
                <!--<el-image class="uploadImage imageFull" v-if="good.goodsImg" fit="scale-down"
                          :src="base_url+'/goods'+good.goodsImg"
                ></el-image>-->
                <div class="imgBox" v-if="good.goodsImg">
                  <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                  <div class="innerImg" v-if="good.isGreen==1">
                    <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                  </div>

                </div>

                <span class="imageDesc">商品图片</span>
              </el-card>

            </el-col>
            <el-col :span="24" v-if="!returnFlag && (good.categoryId.toString()[0]==='2' || good.categoryId.toString()[0]==='7')">
              <el-form-item class="rangeBlock" label="施用范围" prop="useRange">
                <div class="rangeText">{{good.useRange}}</div>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="bottomBtn">
            <el-button v-if="!editGoods" class="storeInfoBtn" icon="el-icon-s-goods" type="danger" @click="saveSale"
                       :disabled="canSave">添加购物车
            </el-button>
            <el-button v-if="editGoods" class="storeInfoBtn" icon="el-icon-s-goods" type="danger" @click="saveSale"
                       :disabled="canSave">保存
            </el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {
      usageList: [],
      isHLJ: {
        type: Boolean,
        default: false
      },
      isDN: {
        type: Boolean,
        default: false
      },
      hljMode: {
        type: Number,
        default: 2
      },//1农药2其他商品
    },
    data () {
      return {
        message: '进货开单修改购物车商品',
        clientName: '',
        good: {
          categoryId: ''
        },
        centerDialogVisible: false,
        rules: {},
        PACKAGE_LEVEL: PACKAGE_LEVEL,
        base_url: '',
        editGoods: false,//添加/编辑购物车
        canSave: false,//库存限制
        returnFlag: false,//销货退货传入
        saleType: false,//为true=处方
        isInput: false,//正在输入
        canEmit: true,//是否向父组件传递
      }
    },
    mounted () {
      this.base_url = this.BASE_URL
      //this.base_url="D:\\DEV_CODE\\Intelligy_idead_code\\upload-file\\temp";
      //this.base_url="https://jxccs.weituodata.com:81"
    },
    methods: {
      dialogClose () {
        this.centerDialogVisible = false
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openSaleGood (good, clientName, flag, returnFlag, saleType) {
        // console.log("是否是黑龙江：",this.isHLJ,"是否为农药：",this.hljMode)
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        this.good = good
        // console.log("good: ",this.good,"saleType: ",saleType)
        //console.log("this.good: ",this.good)
        if (this.good.packageLevel === 1) {
          this.good.buyPackageLevel = 1
        }
        this.good = $.extend({}, this.good)
        this.clientName = clientName.toString()
        this.editGoods = false
        this.canSave = false
        if (flag) {
          //编辑
          this.editGoods = true
        }

        if (returnFlag) {
          this.returnFlag = true
        }else{
          this.returnFlag = false
        }

        if (saleType) {
          this.saleType = true
        } else if (!saleType) {
          this.saleType = false
        }
        this.$forceUpdate()
      },

      saveSale () {
        if (this.editGoods) {
          this.$emit('editSaleCart', this.good)
        } else {
          this.canSave = true
          this.$emit('addSaleCart', this.good)
        }

        this.centerDialogVisible = false
      },
      radioChange (e) {
        let _this = this
        _this.good = $.extend({}, _this.good)
        _this.good.buyPackageLevel = e
        if (e === 1) {
          _this.good.goodsPrice = parseFloat(_this.good.lastSmallPrice).toFixed(2)
        }
        if (e === 2) {
          _this.good.goodsPrice = parseFloat(_this.good.lastBigPrice).toFixed(2)
        }
        _this.good.buyPackageLevel = e
        if (!_this.returnFlag) {
          _this.inventoryLimit()
        }

        _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
        /*this.$forceUpdate()*/
      },
      priceChange (e) {

        let _this = this
        _this.good = $.extend({}, _this.good)
        if (e === undefined) {
          e = '0.00'
        }
        _this.good.goodsPrice = e
        _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
        _this.good.totalPrice = parseFloat(_this.good.totalPrice).toFixed(2)
        /*_this.$forceUpdate()*/
      },
      cartChange () {
        this.$emit('cartChange', this.good)
        this.centerDialogVisible = false
      },
      countInput (value) {
        //console.log('输入的value：', value)
        let _this = this
        _this.good = $.extend({}, _this.good)
        _this.good.goodsNumber = value
        if (!_this.returnFlag) {
          _this.inventoryLimit()
        }
        _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
        _this.good.totalPrice = parseFloat(_this.good.totalPrice).toFixed(2)
        _this.isInput = false
      },
      countChange (value) {
        //console.log('value: ', value)

        let _this = this
        //console.log('_this.isInput: ', _this.isInput)
        if (_this.isInput) {
          _this.good = $.extend({}, _this.good)

          $('.numInputCount span').off('mouseup').on('mouseup', function () {
            //console.log('-->value: ', value)
            _this.good.goodsNumber = value
            if (!_this.returnFlag) {
              _this.inventoryLimit()
            }
            _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
            _this.good.totalPrice = parseFloat(_this.good.totalPrice).toFixed(2)
            _this.isInput = false
          })

        }

        /*_this.$forceUpdate()*/
      },
      amountChange (value) {
        //console.log("value: ",value)
        let _this = this
        _this.good = $.extend({}, _this.good)
        if (Tool.isEmpty(value)) {
          _this.good.usageAmount = 0.01
        } else {
          _this.good.usageAmount = value
        }

      },
      inventoryLimit () {
        let _this = this
        if (!_this.returnFlag) {
          _this.canSave = false
          if (_this.good.buyPackageLevel === 1) {
            if (_this.good.goodsNumber > _this.good.inventory) {

              _this.canSave = true
              if (_this.canEmit) {
                _this.$emit('toStockOrder', _this.good, true)
                _this.canEmit = false
                setTimeout(() => {
                  _this.canEmit = true
                }, 500)
              }

            }
          }
          if (_this.good.buyPackageLevel === 2) {
            if (_this.good.goodsNumber * _this.good.middleCount > _this.good.inventory) {
              _this.canSave = true
              if (_this.canEmit) {
                _this.$emit('toStockOrder', _this.good, true)
                _this.canEmit = false
                setTimeout(() => {
                  _this.canEmit = true
                }, 500)
              }

            }
          }
        }
      }
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 825px !important;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    font-size: 18px;
    margin-left: 10px;
    color: #ffffff;
    height: 40px;
    margin-top: 20px;
    text-decoration: none;
    cursor: pointer;
    max-width: 400px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__content {

  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .orderNav {
    position: relative;
    margin-bottom: 50px;
  }

  .inputLine {
    max-width: 230px;
    float: left;
  }

  .bottomBtn {
    display: inline-block;
    /*position: absolute;
    bottom: 80px;
    left: 45%;
    margin: 0 auto;*/
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }

  /*包装*/
  .checkGroup {
    float: left;
  }

  .inputGroup {
    float: left;
    margin-right: 5px;
  }

  .packageUnit {
  }

  .unitLine {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 5px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  .unitName {
    float: left;
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;
  }

  .unitOneName {
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;

  }

  .priceUnit {
    float: left;
    display: inline-block;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #DCDFE6;
  }

  .inventoryLine {
    float: left;
    max-width: 180px;
    margin-right: 10px;
  }

  /*小计*/
  .totalMoney {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
  }

  .totalPrice {
    float: left;
    font-size: 20px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }

  .rangeBlock {
    display: inline-block;
    width: 100%;
  }

  .rangeBlock /deep/ .el-form-item__content {
    padding-top: 10px;
  }

  .rangeText {
    line-height: 20px;
    text-align: left;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 0;
  }

  .imageBlock {
    max-width: 270px;
    margin-left: 30px;
    padding: 20px;
  }

  .uploadImage {
    max-height: 210px;
  }

  .imageDesc {
    display: block;
    margin-top: 15px;
  }

  .imageFull {
    height: 230px;
  }
  .imgBox{
    position: relative;
  }
  .innerImg{
    position: absolute;
    left: 9px;
    bottom: 0;
    width: 91.8%;
  }
</style>
