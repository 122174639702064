<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <div class="reportBox">
          <div class="reportText">已选：{{reportNum}}个</div>
        </div>
        <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                     placeholder="选择商品分类"
                     v-model="category"
                     :options="categorys"
                     :props="{value:'categoryId',label:'categoryName',children:'children'}"
                     separator=" / "
                     @change="categoryChange">
        </el-cascader>
        <el-input class="searchInput"
                  placeholder="输入商品名称"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
        <!--<div class="checkStop">
          <template>
            <el-checkbox v-model="reportedOrder" @change="reportedOrderClick">显示已上报订单</el-checkbox>
          </template>
        </div>-->

      </div>
      <div class="navRight">
        <div class="searchBtn">
          <el-button v-if="getAuthorityMenu('700302')" class="searchBtn" type="primary" icon="el-icon-tickets" @click="reportAll(1)">上报</el-button>
        </div>
        <el-button v-if="getAuthorityMenu('700301')" class="searchBtn" type="primary" icon="el-icon-document-copy" @click="reportAll(2)">全部上报</el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="goods"
        height="700px"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        @selection-change="handleSelectionChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column
          type="selection"
          :selectable="selectableHandle"
          width="55">
        </el-table-column>
        <el-table-column type="index" label="序号" class="indexCenter" width="80" fixed></el-table-column>
        <el-table-column property="goodsName" label="商品名称" fixed></el-table-column>
        <el-table-column class="imgColumn" property="goodsImg" label="商品图片" width="82" fixed>
          <template slot-scope="scope" class="imgColumnScope">
            <el-image
              style="width: 60px; height: 60px"
              :src="base_url+'/goods'+scope.row.goodsImg"
            ></el-image>
            <el-image v-if="scope.row.isGreen==1" :style="{position:'absolute',bottom:0,left:'10px',width:'60px'}" :src="'/static/imgs/green_tag.png'"></el-image>
          </template>
        </el-table-column>
        <el-table-column property="companyName" label="生产厂家" sortable></el-table-column>
        <el-table-column property="packingSpecification" label="包装规格" sortable>
        </el-table-column>
        <el-table-column property="packageLevel" label="销售价格" width="120" sortable>
          <template slot-scope="scope" >
            <el-tag type="danger" class="showCenter">
              ￥ {{scope.row.packageLevel===1?scope.row.saleSmallPrice:scope.row.saleBigPrice}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{GOODS_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="inventory" label="库存" width="120" :show-overflow-tooltip="true">
          <template slot-scope="scope" >
            <el-tag :type="scope.row.inventory <= scope.row.inventoryWarning ? 'danger' : 'primary'" class="showCenter">
              {{ scope.row.inventory }}
              {{ scope.row.middleUnitName }}
            </el-tag>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-goods-stock-check ref="dialogGoodsStockCheck" @stockChange="stockChange"></dialog-goods-stock-check>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogGoodsStockCheck from '../../../components/DialogGoodsStockCheck'
  export default {
    name: 'InventoryReport',
    components:{PageHelper,DialogGoodsStockCheck},
    data () {
      return {
        pageName:'库存台账上报',
        reportNum:0,
        good: {},
        goods: [],
        base_url:'',
        searchInput: '',
        reportedOrder: false,//默认不显示停用商品
        GOODS_STATUS:GOODS_STATUS,
        currentRow: null,
        //库存盘点
        centerDialogVisible: false,
        //级联选择
        categorys: [
          {
            "categoryId":0,
            "categoryName":"全部",
            "categotyOrder":0,
            "children":[
              {
                "categoryId":0,
                "categoryName":"全部分类",
                "categotyOrder":0,
                "children":[],
                "parentCategoryId":0
              }
            ],
            "parentCategoryId":0
          }
        ],
        category: {},
        categoryId:'',
        multipleSelection:'',//选中项
        total:0,
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1);
        this.getCategorys();
      }
      this.base_url=this.BASE_URL;
      //this.base_url="https://jxccs.weituodata.com:81"
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
      this.navChange();
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //获取所有分类
      getCategorys(){
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/category/selectAll')
          .then((response) => {
            Loadings.hide()
            let resp = response.data;
            resp.data.map(item=>{
              if(item.categoryName!=='其他'){
                item.children.unshift({categoryId:item.categoryId,categoryName:'全部'+item.categoryName})
              }
            })
            for (let i = 0; i < resp.data.length; i++) {
              this.categorys.push(resp.data[i]);
            }

            for (let i = 0; i < this.categorys.length; i++) {
              for (let j = 0; j < this.categorys[i].children.length; j++) {
                if (this.categorys[i].children[j].children) {
                  delete this.categorys[i].children[j].children;
                }
              }
            }
          })
      },
      //选中分类搜索
      categoryChange(value) {
        // let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0];
        // this.categoryId = checkedNodes.value;

        this.categoryId = value[1];
        this.getSearch(1);
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      getSearch (page,pageChange) {
        let current=page
        let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let keywords=this.searchInput
        let showStop=0
        let barCode=""
        let isReport = 0
        if (this.reportedOrder === false) {
          isReport = 0
        } else {
          isReport = 1
        }
        if(this.categoryId===0){
          this.categoryId='';
        }
        Loadings.show();
        //'&isReport='+isReport+
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectGoodsPage?current='
          +current+"&size="+size+"&status="+showStop +"&categoryId="+this.categoryId+"&goodsName="+this.searchInput+"&barCode="+barCode)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.goods = resp.data.records;
            this.total=resp.data.total
            this.$refs.pageHelper.render(page, resp.data.total)
          })
      },
      reSearch (data) {
        this.getSearch(1,data);
      },
      //2.停用商品
      reportedOrderClick () {
        this.getSearch(1);
      },
      //3.新增商品
      addGoods () {
        let type = 0;
        //SessionStorage.set(GOODS_EDIT_TYPE, type);
        this.$router.push({
          name: "/goodsManagerEdit",
          params: {type}
        });
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      edit(item){
        let type=1;
        let goodsId=item.goodsId;
        // SessionStorage.set(GOODS_EDIT_TYPE, type);
        // SessionStorage.set(GOODS_ID, goodsId);
        this.$router.push({
          name: "/goodsManagerEdit",
          params: {goodsId,type}
        });
      },
      //上架/下架
      openStop(item) {
        let _this = this;
        if (item.status === 0) {
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/offShelves?goodsId=' + item.goodsId)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '商品下架成功！', duration: 2000, offset: 110 });
                  _this.getSearch(_this.$refs.pageHelper._data.page);
                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }
            })
        } else if (item.status === 1) {
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/upShelves?goodsIds=' + item.goodsId)
            .then((response) => {
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '商品上架成功！', duration: 2000, offset: 110 });
                  _this.getSearch(_this.$refs.pageHelper._data.page);
                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }
            })
        }
      },
      //5.库存盘点
      stockCheck(item){
        this.$nextTick(() => {
          this.$refs.dialogGoodsStockCheck.openStockCheck(item);
        })
      },
      stockChange(good) {
        this.getSearch(this.$refs.pageHelper.page);
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
      exportExcel () {
        axios({
          method: 'get',
          url: process.env.VUE_APP_SERVER + '/psi/goods/exportGoods?goodsName='
            + this.searchInput + '&categoryId=' + this.categoryId + '&barCode=' + "" + '&status=' + (this.reportedOrder ? 1 : 0),
          responseType: 'blob',
          headers: {
            'blade-auth': 'bearer ' + LocalStorage.get(TOKEN)
          }
        })
          .then((res) => {
            const link = document.createElement('a')
            let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
            let temp = res.headers["content-disposition"].split(";")[2].split("filename*=")[1];
            let fileTemp=temp.substr(7,temp.length)
            //let temp = res.data;
            let fileName = decodeURIComponent(fileTemp);
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }).catch(error => {
          // console.log(error)
        })

      },

      toggleSelection(rows,allTag) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });

        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        this.reportNum=this.multipleSelection.length
        // console.log("this.multipleSelection: ",this.multipleSelection)
      },
      reportAll(type){
        let _this=this
        if(type==1){
          // console.log("上报：",type)
          let ids=[]
          if(this.multipleSelection.length<1){
            this.$message({
              type: 'error',
              message: '请选择需要上报的订单',
              duration: 2000,
              offset: 110
            })
          }else{
            this.multipleSelection.map(item=>{
              ids.push(item.goodsId)
            })
            // console.log("上报iD：",ids)
            Loadings.show()
            _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/reportGoodsAndInventory?categoryId=' +'&goodsIds='+ ids+'&goodsName=')
              .then((response) => {
                  Loadings.hide()
                  if (response.status === 200) {

                    if (response.data.code === 200) {
                      _this.$message({
                        type: 'success',
                        message: '上报成功！',
                        duration: 2000,
                        offset: 110
                      })

                      _this.getSearch(1)

                    } else {
                      _this.$message({
                        type: 'warning',
                        message: response.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    _this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                }
              )
          }
        }else{
          // console.log("全部上报：",type)
          // this.toggleSelection(this.stockOrders)
          // this.reportNum=this.total
          Loadings.show()
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectGoodsPage?current='
            +1+"&size="+10+"&status="+0 +"&categoryId="+this.categoryId+"&goodsName="+this.searchInput+"&barCode=")
            .then((res) => {
              Loadings.hide()
              let resp = res.data
              this.goods = resp.data.records;
              this.total=resp.data.total
              this.$refs.pageHelper.render(1, resp.data.total)
              if(this.total>0){
                this.$toast.showOrderConfirm('库存台账上报总计 '+this.total+" 个商品", '是否上报', '','上报', function () {
                  Loadings.show()
                  console.log("1111111111111:")
                  _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/reportGoodsAndInventory?categoryId='+_this.categoryId +'&goodsIds='+'&goodsName='+_this.searchInput)
                    .then((response) => {
                        Loadings.hide()
                        if (response.status === 200) {

                          if (response.data.code === 200) {
                            _this.$message({
                              type: 'success',
                              message: '上报成功！',
                              duration: 2000,
                              offset: 110
                            })

                            _this.getSearch(1)

                          } else {
                            _this.$message({
                              type: 'warning',
                              message: response.data.msg,
                              duration: 2000,
                              offset: 110
                            })
                          }
                        } else {
                          _this.$message({
                            type: 'warning',
                            message: '网络异常',
                            duration: 2000,
                            offset: 110
                          })
                        }
                      }
                    )
                })
              }else{
                _this.$message({
                  type: 'error',
                  message: '暂未查到可上报的商品',
                  duration: 2000,
                  offset: 110
                })
              }

            })

        }
      },
      selectableHandle(row,index){
        return true
      },
    }
  }
</script>

<style scoped>
  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  .mainForm {

  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 630px;
  }
  .categoryInput{
    float: left;
    margin-right: 10px;
  }

  .reportBox{
    display: inline-block;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
  }
  .reportText{
    font-size: 16px;
    font-family: SimHei;
    font-weight: 400;
    color: #409EFF;
  }
</style>
