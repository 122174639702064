<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">选择所属供应商</div>
      </div>
      <div class="orderNav">

        <div class="navLeft">
          <el-tag class="showCheck">当前供应商 <span>{{checkSupplierIds.length>0?checkSupplierIds.length:0}}</span> 个
            <span v-show="checkSupplierIds.length>0">{{strSuppliers}}</span></el-tag>
          <el-button class="searchBtn" type="primary" @click="addSuppliers">确认选中</el-button>
        </div>

        <div class="navRight">
          <el-input class="searchInput"
                    placeholder="输入供应商名称、联系人或手机号"
                    v-model="searchInput"
                    :blur="searchInputChange(searchInput)"
                    :clearable="true">
          </el-input>
          <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
          <el-button v-show="getAuthorityMenu('200101') || (getAuthorityMenu('200301') || getAuthorityMenu('500301'))" class="searchBtn" type="danger" @click="toNewSupplier">新增供应商</el-button>
        </div>

      </div>

      <div class="dialogBody">

        <div class="mainForm">
          <el-table
            ref="multipleTable"
            tooltip-effect="dark"
            :data="suppliers"
            border
            height="540px"
            highlight-current-row
            class="previous-row"
            @current-change="handleCurrentChange"
            @selection-change="handleSelectionChange"
            :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
          >
            <el-table-column type="selection" class="indexCenter" width="35" property="clientId">
            </el-table-column>
            <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
            <el-table-column property="supplierName" label="供应商名称" width="200">
              <template slot-scope="scope">
                <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.supplierName)" :disabled="!remarkFlag">
                  <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                    {{scope.row.supplierName===''?'-':scope.row.supplierName}}
                  </div>
                  <span class="showTipLine">{{scope.row.supplierName===''?'-':scope.row.supplierName}}</span>
                </el-tooltip>
                <span class="clientIdShow">{{scope.row.supplierId}}</span>
              </template>
            </el-table-column>
            <el-table-column property="supplierPerson" label="联系人">
              <template slot-scope="scope">
                <el-tooltip placement="bottom" @mouseenter.native="remarkEnter(scope.row.supplierPerson)" :disabled="!remarkFlag">
                  <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                    {{scope.row.supplierPerson===''?'-':scope.row.supplierPerson}}
                  </div>
                  <span class="showTipLine">{{scope.row.supplierPerson===''?'-':scope.row.supplierPerson}}</span>
                </el-tooltip>

              </template>
            </el-table-column>
            <el-table-column property="supplierPhone" label="手机号" width="120"></el-table-column>
            <el-table-column property="regionName" label="地址">
              <template slot-scope="scope">
                <el-tag :type="scope.row.regionName === '' ? 'info':'primary'" class="showCenter">
                  {{ scope.row.regionName === '' ? '-' : scope.row.regionName }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column property="status" label="状态" width="100">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                        id="status">{{SUPPLIER_STATUS | optionObjectKV(scope.row.status)}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column property="remark" label="备注" >
              <template slot-scope="scope">
                <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.remark)" :disabled="!remarkFlag">
                  <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                    {{scope.row.remark===''?'-':scope.row.remark}}
                  </div>
                  <span class="showRemarkLine">{{scope.row.remark===''?'-':scope.row.remark}}</span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pageNav" aria-label="分页">

        <div class="pageChoose">
          <el-button type="danger" plain :disabled="page===1" @click="selectPage(1)">首页</el-button>
          <el-button type="danger" icon="el-icon-caret-left" circle
                     :disabled="page===1" @click="selectPage(page-1)"></el-button>
          <el-button class="pageNumber" :class="p===0?'pageNumbers':''" type="danger" plain v-for="p in pages" :key="p"
                     @click="selectPage(p+1)">{{p+1}}
          </el-button>
          <el-button type="danger" icon="el-icon-caret-right" circle
                     :disabled="page===pageTotal" @click="selectPage(page+1)"></el-button>
          <el-button type="danger" plain :disabled="page===pageTotal" @click="selectPage(pageTotal)">尾页</el-button>
          <el-button class="totalCount" type="danger" plain>共{{totalSize}}条</el-button>
          <!--<span>前往</span>
          <el-input class="goPageNum" v-model="input" placeholder="1" @change="inputValue"></el-input>
          <el-button v-model="input" id="goBtn" type="danger" plain @click="selectPage(input)">GO</el-button>-->
        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'SupplierChooseMore',
    props: {
      /*checkSupplierIds: {
        type:Array,
        default:null
      },
      checkSupplierNames:{
        type:Array,
        default:null
      },*/
    },
    data () {
      return {
        message: '选择供应商',
        supplierName: {
          default: '-'
        },
        supplier: {},
        suppliers: [],
        supplierIds: [],
        checkSupplierIds: 0,
        centerDialogVisible: false,
        searchInput: '',
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        currentRow: null,
        pageShow: true,
        //分页
        list: {
          type: Function,
          default: null
        },
        //显示的页码数，共50页，显示5页，其他用...表示
        itemCount: Number,
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        input: null,
        //选中
        checkSuppliers: 0,
        strSuppliers: '',
        type: 1,//type=1商品管理，=2进货
        previousCheck: {},//上一次选中
        remarkFlag: false,
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        /*this.supplierNames = Array.from(new Set(this.supplierNames))
        console.log('关闭2？', this.supplierNames, 'ids: ', this.checkSupplierIds, 'supIds：', this.supplierIds)
        this.addSuppliers()*/
        this.centerDialogVisible = false
      },
      getSupplierIds (supplierIds, type, supplierNames) {
        let _this=this
        _this.type = type
        _this.supplierIds = supplierIds
        _this.checkSupplierIds=supplierIds
        _this.supplierIds=$.extend([],this.supplierIds)
        _this.checkSupplierIds=$.extend([],this.checkSupplierIds)
        if (this.supplierIds.length < 1) {
          _this.checkSupplierIds = []
          _this.checkSupplierNames = []
          _this.strSuppliers = ''
        } else {
          _this.supplierNames = supplierNames
          _this.supplierNames=$.extend([],_this.supplierNames)
          _this.strSuppliers = supplierNames.toString()
          _this.strSuppliers=$.extend('',_this.strSuppliers)
          _this.checkSupplierIds = supplierIds
          _this.checkSupplierNames = supplierNames
          _this.checkSupplierNames =$.extend([],_this.checkSupplierNames)
        }
        //console.log(supplierIds)
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        //多选框默认选中回显处理
        this.getSearch(1)
        //console.log('传入的供应ids：', supplierIds)

      },

      getSearch (page, pageChange) {
        let _this = this
        Loadings.show()
        let current = page
        let size = 10 //引用传入组件后，在标签中refs定义的别名

        let keywords = _this.searchInput
        let showStop = 0
        _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/supplier/list?current='
          + current + '&size=' + size + '&keywords=' + keywords + '&showStop=' + showStop)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            _this.suppliers = resp.data.records
            _this.renderPage(page, resp.data.total)
            _this.stockOrdering(page)

            let closeDialog = $('.el-dialog__close')
            if (_this.supplierIds === undefined) {
              closeDialog[0].style.display = 'none'
              //console.log('当前的dialog--------：', closeDialog)
            } else {
              closeDialog[0].style.display = 'block'
            }
            _this.$forceUpdate()
          })
      },
      reSearch (data) {
        this.getSearch(1, data)
      },
      //进货
      stockOrdering (page) {
        let _this = this
        let pseudoArr = document.getElementsByClassName('el-checkbox')[0]
        pseudoArr.style.display = 'none'
        pseudoArr.firstChild.style.display = 'none'
        if (_this.supplierIds && _this.supplierIds.length > 0) {
          setTimeout(() => {
            _this.toggleSelection(page)
          }, 200)
        }
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      handleChange (value) {
        //console.log('改变的opts：', value[0])
        this.size = value[0]
        this.$emit('pageSizeChange', this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
        //console.log('输入的内容：', this.input)
        this.getSearch(this.input)
      },
      /**
       * 渲染分页组件
       */
      renderPage (page, totalSize) {
        this.page = parseInt(page) //当前页
        this.totalSize = totalSize //总条数 / 每页显示条数size
        this.pageTotal = Math.ceil(totalSize / this.size)
        //默认分页后显示的页码数：10
        this.pages = this.getPageItems(this.pageTotal, page, 10)
        let pageNums = $('.pageNumber')
        //console.log('---->pageNums: ', pageNums)
        if (pageNums.length > 0) {
          if (page === 1) {
            Tool.addClass(pageNums[0], 'pageNumbers')
          }
          let newPageIndex = 0
          if (page !== 1) {
            for (let i = 0; i < pageNums.length; i++) {
              Tool.removeClass(pageNums[i], 'pageNumbers')
              if (pageNums[i].innerText === page) {
                Tool.addClass(pageNums[i], 'pageNumbers')
              }
            }
          }
        }
      },
      //查询每一页
      selectPage (page) {
        let _this = this
        if (page < 1) {
          page = 1
        }
        //如果传入的当前页>总页码
        if (page > _this.pageTotal) {
          page = _this.pageTotal
        }
        if (_this.page !== page) {
          _this.page = page
          /*if (_this.list) {
            _this.list(page)
          }*/
        }
        //console.log('当前page', $('.pageNumber'))
        let pageNums = $('.pageNumber')
        for (let i = 0; i < pageNums.length; i++) {
          Tool.removeClass(pageNums[i], 'pageNumbers')
          if (pageNums[i].innerText === page + '') {
            //console.log(pageNums[i])
            Tool.addClass(pageNums[i], 'pageNumbers')
          }
        }
        _this.getSearch(page)
      },
      //当前显示的页码
      getPageItems (totalSize, current, length) {
        let items = []
        if (length >= totalSize) {
          for (let i = 0; i < totalSize; i++) {
            //总条数放入items，遍历页码
            items.push(i)
          }
        } else {
          let base = 0
          //前移:向下取整
          if (current - 0 > Math.floor((length - 1) / 2)) {
            //后移：向上取整
            base = Math.min(totalSize, current - 0 + Math.ceil((length - 1) / 2)) - length
          }
          //条数集合
          for (let i = 1; i <= length; i++) {
            items.push(base + i)
          }
        }
        return items
      },
      //3.添加class
      addClass (node, className) {
        let reg = new RegExp('\\b' + className + '\\b')
        if (!reg.test(node.className)) {
          node.className += (' ' + className)
        }
      },

      //4.移除class
      removeClass (node, className) {
        if (node.className) {
          let reg = new RegExp('\\b' + className + '\\b')
          let classes = node.className
          node.className = classes.replace(reg, '')
          if (/^\s*$/g.test(node.className)) {
            node.removeAttribute('class')
          }
        } else {
          node.removeAttribute('class')
        }
      },

      //多选
      handleCurrentChange (val) {
        this.currentRow = val
      },
      toggleSelection (page) {
        let _this = this
        //console.log('1111新增选中 this.checkSupplierIds：', this.checkSupplierIds, ' this.checkSupplierNames: ', this.checkSupplierNames, ' this.strSuppliers: ', this.strSuppliers)
        let rows = []
        _this.suppliers.map((item, index) => {
          _this.supplierIds.map(id => {
            if (item.supplierId === id) {
              rows.push(_this.suppliers[index])
            }
          })
        })

        let pageNums = $('.pageNumber')
        for (let i = 0; i < pageNums.length; i++) {
          Tool.removeClass(pageNums[i], 'pageNumbers')
          if (pageNums[i].innerText === page + '') {
            //console.log(pageNums[i])
            Tool.addClass(pageNums[i], 'pageNumbers')
          }
        }
        setTimeout(function () {
          rows.forEach(row => {
            _this.$refs.multipleTable.toggleRowSelection(row)
          })
        }, 50)

      },
      handleSelectionChange (val) {
        let _this = this
        _this.multipleSelection = val
        for (let i = 0; i < _this.checkSupplierIds.length; i++) {
          if (typeof (_this.checkSupplierIds[i]) === 'object') {
            _this.checkSupplierIds[i] = _this.checkSupplierIds[i].supplierId
          }
        }

        let checkBoxInput = document.getElementsByClassName('el-checkbox__input')
        setTimeout(function () {
          for (let i = 0; i < checkBoxInput.length; i++) {
            if (checkBoxInput[i].className.indexOf('is-focus') !== -1 && checkBoxInput[i].className.indexOf('is-checked') === -1) {
              let clientNew = checkBoxInput[i].parentNode.parentNode.parentNode.parentNode.childNodes[2].childNodes[0].childNodes[1].innerHTML
              clientNew = parseInt(clientNew)
              for(let i=0;i<_this.checkSupplierIds.length;i++){
                if(_this.checkSupplierIds[i]===clientNew){
                  _this.checkSupplierIds.splice(i,1)
                  _this.checkSupplierNames.splice(i,1)
                  _this.supplierIds = _this.checkSupplierIds
                  _this.$forceUpdate()
                }
              }
            }
          }
          _this.supplierIds = _this.checkSupplierIds

          _this.checkSuppliers = _this.multipleSelection.length
          let ids = []
          let names = []
          if (_this.multipleSelection.length > 0 || _this.supplierIds.length > 0) {
            if (_this.multipleSelection.length < 1) {
              for (let i = 0; i < _this.supplierIds.length; i++) {
                for (let j = 0; j < _this.suppliers.length; j++) {
                  if (_this.suppliers[j].supplierId === _this.supplierIds[i]) {
                    _this.multipleSelection.push(_this.suppliers[j])
                  }
                }
              }
            }
          }
          for (let i = 0; i < _this.multipleSelection.length; i++) {
            ids.push(_this.multipleSelection[i].supplierId)
            names.push(_this.multipleSelection[i].supplierName)
          }
          if (_this.checkSupplierIds.length > 0) {
            let idsTemp = []
            for (let i = 0; i < ids.length; i++) {
              for (let j = 0; j < _this.checkSupplierIds.length; j++) {
                if (ids[i] !== _this.checkSupplierIds[j]) {
                  idsTemp.push(ids[i])
                }
              }
            }
            let namesTemp = []
            for (let i = 0; i < names.length; i++) {
              for (let j = 0; j < _this.checkSupplierNames.length; j++) {
                if (names[i] !== _this.checkSupplierNames[j]) {
                  namesTemp.push(names[i])
                }
              }
            }
            idsTemp.forEach(item => {
              _this.checkSupplierIds.push(item)
            })
            namesTemp.forEach(item => {
              _this.checkSupplierNames.push(item)
            })
            _this.checkSupplierIds = Array.from(new Set(_this.checkSupplierIds))
            _this.checkSupplierNames = Array.from(new Set(_this.checkSupplierNames))
          } else {
            _this.checkSupplierIds = ids
            _this.checkSupplierNames = names
          }

          _this.supplierIds = _this.checkSupplierIds
          _this.strSuppliers = _this.checkSupplierNames.toString()
          //console.log('新增选中 _this.checkSupplierIds：', _this.checkSupplierIds, ' _this.checkSupplierNames: ', _this.checkSupplierNames, '111 _this.strSuppliers: ', _this.strSuppliers)
        }, )

      },
      addSuppliers () {
        this.$emit('getSupplierMore', this.checkSupplierIds, this.checkSupplierNames)
        this.supplierIds = []

        this.centerDialogVisible = false
      },
      toNewSupplier () {
        let type = 2
        this.$router.push({
          name: '/supplierManagerEdit',
          params: {
            type,
          }
        })
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      }
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .navRight {
    float: right;
  }

  /*表格*/
  .orderNav {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }


  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 480px !important;
  }

  /deep/ .el-table .el-table__cell {
    padding: 6px 0;
  }

  .remark div {
    text-align: left;
  }

  /*分页*/
  .pageNav {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .pageChoose {
    margin-top: 10px;
  }

  .totalCount {
    margin-right: 10px;
  }

  .pageSize {

    margin-top: 5px;
  }

  .sizeNum {
    margin: 0 5px;
    max-width: 150px;
  }

  .goPage {
    margin: 5px 0 5px 5px;
    color: #F56C6C;
  }

  .goPageNum {
    width: 60px;
    margin: 0 10px;

  }

  /*跳转页*/
  .pageNumbers {
    background: #F56C6C !important;
    border-color: #F56C6C !important;
    color: #FFF !important;
  }

  .clientIdShow {
    display: none;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }
  .toolTipWidth {
    max-width: 300px !important;
    text-align: center !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
    text-align: center;
  }
  .showTipLine{
    line-height: 36px;
    height: 36px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address{
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showRemarkLine{
    height: 36px !important;
    line-height: 36px !important;
    overflow: hidden;
  }
</style>
