<template>
  <div class="mainPage">
    <div class="topNav">
      <el-row :gutter="12">
        <el-col :span="6" v-for="(item,index) in cardList" :key="index" class="cardBox">
          <div class="lintTo" @click="linkTo(item.toLink)">
            <el-card class="box-card" :class="getAuthorityRouter(item.toLink.substr(1))===true?'':'unAuthTheme'">
              <div class="cardTitle">
                <div class="titleTop">
                  <div class="cardLine"></div>
                  <span>{{item.title }}</span>
                </div>
              </div>
              <div class="titleBody">
                <el-col :span="18">
                  <div class="bodyLeft">
                    <span>{{item.desc}}</span>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="bodyRight">
                    <img :src="getAuthorityRouter(item.toLink.substr(1))===true?item.url:item.url_back">
                  </div>
                </el-col>
              </div>

            </el-card>
          </div>

        </el-col>

      </el-row>

    </div>
    <div class="bottomCarousel">
      <el-carousel id="pro_carousels" :interval="4000">
        <el-carousel-item v-for="(item,index) in carouselList" :key="index">
        </el-carousel-item>
      </el-carousel>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'Index',
    data () {
      return {
        message: '首页',
        barCode: '',
        carouselList: ['/static/imgs/my/index_carousel-1-1.jpg', '/static/imgs/my/index_carousel-2-6.jpg'],
        cardList: [
          {
            title: '商品管理',
            desc: '快速录入商品信息，支持扫码自动填充',
            url: '/static/imgs/my/商品管理.png',
            url_back:'/static/imgs/my/商品管理_back.png',
            toLink: '/admin/goodsManager',
          },
          {
            title: '客户管理',
            desc: '快速创建客户档案资料，支持联想搜索，方便销货维护客户群',
            url: '/static/imgs/my/客户管理.png',
            url_back:'/static/imgs/my/客户管理_back.png',
            toLink: '/admin/clientManager',
          },
          {
            title: '供应商管理',
            desc: '快速创建产品供应商档案资料，支持联想搜索，方便进货维护产品供应链',
            url: '/static/imgs/my/供应商管理.png',
            url_back:'/static/imgs/my/供应商管理_back.png',
            toLink: '/admin/supplierManager',
          },
          {
            title: '进货开单',
            desc: '快速记录产品采购信息，支持扫码快捷进货，10s急速开单',
            url: '/static/imgs/my/进货开单.png',
            url_back:'/static/imgs/my/进货开单_back.png',
            toLink: '/admin/stockOrders',
          },
          {
            title: '销货开单',
            desc: '快速记录客户订单数据，支持扫码快捷销货，10s急速开单',
            url: '/static/imgs/my/销货开单.png',
            url_back:'/static/imgs/my/xiao_back.png',
            toLink: '/admin/saleOrders',
          },
          {
            title: '销货单据',
            desc: '实时监控销货订单数据，订单详情一目了然，支持再来一单、撤单等功能',
            url: '/static/imgs/my/销货单据.png',
            url_back:'/static/imgs/my/销货单据_back.png',
            toLink: '/admin/saleOrderList',
          },
          {
            title: '回收开单',
            desc: '快速记录回收产品信息，包含农药、肥料、农膜等其他产品回收',
            url: '/static/imgs/my/回收开单.png',
            url_back:'/static/imgs/my/回收开单_back.png',
            toLink: '/admin/recycleOrders',
          },
          {
            title: '处置开单',
            desc: '快速处理包装回收往来产品数据',
            url: '/static/imgs/my/处置开单.png',
            url_back:'/static/imgs/my/处置开单_back.png',
            toLink: '/admin/recycleOrderReturns',
          },
        ],
        isHLJ:LocalStorage.get(IS_HLJ)
      }
    },
    mounted () {
      //console.log("首页快捷：",this.isHLJ)
      let _this = this

      //当用户访问登录页面时，发现缓存中存在登录状态，则会直接跳转回首页，这时需要处理modal蒙层问题
      let modalEle = document.getElementsByClassName('modal-backdrop in')[0]
      if (modalEle) {
        modalEle.style.display = 'none'
      }

      // .modal-backdrop.in {
      //     filter: alpha(opacity=50);
      //     opacity: 5;
      //     display: none;
      // }

      //如果用户未登录，则跳转到登录页面
      if (!LocalStorage.get(TOKEN)) {
        setTimeout(() => {
          _this.$router.push('/login')
        }, 200)
      }

      let carousel = document.querySelectorAll('#pro_carousels .el-carousel__item')
      for (let i = 0; i < carousel.length; i++) {
        carousel[i].style = 'background-image: url(' + this.carouselList[i] + ');'
      }
      setTimeout(function () {
        if (document.getElementsByClassName('swal2-container')[0]) {
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display = 'none'
        }
      }, 50)
      let flag = Tool.getAuthorityRouter(this.$route.path.substr(1))
      if (!flag) {
        this.$emit('noAuth')
      }
    },
    methods: {
      handleFocus () {
        this.$refs.barCode.select()
      },
      linkTo (link) {
        if (this.getAuthorityRouter(link.substr(1))) {
          this.$emit('linkTo',link)
        }else{
          this.$toast.showAuth("当前登录用户没有访问权限，请重新登录！","center")
        }
      },
      getAuthorityRouter (router) {

        //获取登录用户的权限对象列表
        let resources = Tool.getUserPrivilege().menuList
        if (Tool.isEmpty(resources)) {
          return false
        } else {
          for (let i = 0; i < resources.length; i++) {
            if (router === resources[i].page) {
              return true
            }
          }
        }
      },
    }
  }
</script>

<style scoped>
  .mainPage {
    padding: 10px;
  }

  .lintTo {
    cursor: pointer;
  }

  .cardBox {
    margin-bottom: 20px;
    min-width: 270px;
  }

  .box-card {
    border-radius: 10px;
  }

  .cardTitle .titleTop {
    position: relative;
  }

  .cardTitle .titleTop .cardLine {
     width: 10px;
     height: 40px;
     background-color: #e92322;
     float: left;
   }

  .cardTitle .titleTop span {
    font-size: 24px;
    color: #e92322;
    margin-left: 10px;
    line-height: 40px;
    display: inline-block;
    float: left;
  }

  .titleBody {
    margin-top: 50px;
    font-size: 16px;
    margin-bottom: 140px;
  }

  .titleBody span {
    float: left;
    margin-left: 15px;
    color: #999999;
    text-align: left;
  }

  .titleBody img {
    float: right;
    width: 80px;
    margin-right: -15px;
  }

  /*轮播*/
  .bottomCarousel {
    margin-bottom: 30px;
    box-shadow: 0 2px 8px 0 rgba(7, 17, 27, .5);
    background-color: unset;
    border-radius: 15px;
  }

  /deep/ .el-carousel__container {
    height: 400px;
    background-color: unset;
  }

  /deep/ .el-carousel__item {
    border-radius: 15px;
    background-color: unset;
    background-position: center;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 400px;
    margin: 0;
  }

  .unAuthTheme .cardTitle .titleTop .cardLine {
    width: 10px;
    height: 40px;
    background-color: #999999;
    float: left;
  }

  .unAuthTheme .cardTitle .titleTop span {
    font-size: 24px;
    color: #999999;
    margin-left: 10px;
    line-height: 40px;
    display: inline-block;
    float: left;
  }
</style>
