<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">店铺备注
          <i class="el-icon-store-solid"></i>
          <!--<span>{{store.tenantName}</span>-->
        </div>
      </div>

      <div class="addStoreForm">
        <div class="mainForm">
          <el-form :model="store" :rules="rules" ref="store" label-width="120px"
                   class="demo-store store-info-form">
            <el-form-item class="labelTitle" label="店铺名称" prop="payAmount">
              <div class="unitLine">
                <el-input class="packageUnitLine" v-model="store.tenantName" disabled></el-input>
              </div>
            </el-form-item>
            <el-form-item class="labelTitle" label="备注信息" prop="payAmount">
              <div class="unitLine">
                <el-input class="packageUnitLine"
                  type="textarea"
                  :rows="4"
                  placeholder="请输入内容"
                  v-model="store.remark">
                </el-input>

              </div>
            </el-form-item>

            <el-form-item class="storeInfoSub">
              <el-button class="storeInfoBtn" type="danger" @click="save('store')">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'storePrivilege',
    props: {},
    data () {
      let priceValidate = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('请输入回收单价'))
        } else {
          callback()
        }
      }
      return {
        message: '用户密码修改',
        store: {
          deadline: '',
        },
        centerDialogVisible: false,
        rules: {
          /*useTerm:[
            {
              required:true,
              message:'使用期限不能为空！',
              trigger: 'blur',
            }
          ],*/
          /*openStatus:[
            {
              required:true,
              message:'授权状态必须哦！',
              trigger: 'blur',
            }
          ],*/
        },
      }
    },
    mounted () {

    },
    methods: {
      dialogClose () {

      },
      payChange (e) {
        //console.log('支付金额变动：', e)
      },
      openStorePrivilege (store) {
        let _this = this
        this.centerDialogVisible = true
        _this.store = store
        _this.store = $.extend({}, _this.store)
        //_this.store.payAmount = 100.00
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        console.log("store: ",this.store)
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-system/tenant/saveRemark',
              method: 'POST',
              data:this.store
            })
              .then((res) => {
                Loadings.hide()
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '更新店铺备注成功！',
                      duration: 2000,
                      offset: 110
                    })
                    this.$emit('getSearch',1)
                    this.centerDialogVisible = false
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        })
      },
      openTimeChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.openTime = this.$moment(e).format('YYYY-MM-DD')
        } else {
          this.openTime = ''
        }
      },
      deadlineChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.store.deadline = this.$moment(e).format('YYYY-MM-DD')
        } else {
          this.store.deadline = ''
        }
        console.log("当前用户关闭时间：")
      },
      radioChange (e) {
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 620px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .title i {
    margin-left: 5px;
    margin-right: 5px;
    color: #f6e483;
  }

  .header-title .title span {
    display: block;
    float: right;
    margin-top: 19px;
    color: #f6e483;
    cursor: pointer;
    max-width: 400px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .remark div {
    text-align: left;
  }

  /*店铺管理*/
  .addStoreForm {
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  .showStopInfo {
    text-align: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    line-height: 20px;
    padding: 10px;
    margin-top: -15px;
    margin-bottom: 10px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }

  /deep/ .el-date-editor.el-input, /deep/ .el-date-editor.el-input__inner {
    min-width: 400px;
  }

  .dateTime {
    float: left;
  }

  /*停用启用*/
  .checkGroup {
    float: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
  }

  /deep/ .el-radio-button__inner, .el-radio-button:first-child {
    border: 0 !important;
  }

  .showStopInfo {
    text-align: left;
    margin-left: 120px;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 400px;
    padding: 10px;
    margin-top: -10px;
    margin-bottom: 20px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }

  .packageUnitLine {
    float: left;
  }
  .statusLine{
    float: left;
    width: 180px;
  }
  .money {
    float: left;
    margin-left: 10px;
  }
  /*.storeInfoSub{
    margin-top: 120px;
  }*/
</style>
