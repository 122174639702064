import { render, staticRenderFns } from "./PrivilegeManager.vue?vue&type=template&id=f0b20820&scoped=true&"
import script from "./PrivilegeManager.vue?vue&type=script&lang=js&"
export * from "./PrivilegeManager.vue?vue&type=script&lang=js&"
import style0 from "./PrivilegeManager.vue?vue&type=style&index=0&id=f0b20820&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b20820",
  null
  
)

export default component.exports