<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title" v-if="!saleStock">更新购物车商品</div>
        <div class="title " v-if="saleStock">
          <div class="stockSale">
            库存不足
            <li class="el-icon-warning"></li>
          </div>
          进货开单
        </div>
        <el-link class="supplierName" type="info" :underline="false" icon="el-icon-d-arrow-right">供应商（{{supplierName}}）
        </el-link>
      </div>
      <div class="orderNav">
        <el-form :model="good" :rules="rules" ref="good" label-width="120px" class="demo-good store-info-form">
          <el-row :gutter="20" class="rowForm">
            <el-col :span="12">
              <el-form-item class="labelTitle" label="商品名称" prop="goodName">
                <el-input disabled class="inputLine" v-model="good.goodsName" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="包装规格" prop="packingSpecification">
                <el-input disabled class="inputLine" v-model="good.packingSpecification" maxlength="50"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="包装单位" prop="packageLevel">
                <div v-if="good.packageLevel===2">
                  <el-radio-group class="checkGroup" v-model="good.buyPackageLevel" @change="radioChange">
                    <el-radio-button class="statusOpen" :label="PACKAGE_LEVEL.SMALL.key">{{PACKAGE_LEVEL.SMALL.value}}
                    </el-radio-button>
                    <el-radio-button class="statusStop" :label="PACKAGE_LEVEL.BIG.key">{{PACKAGE_LEVEL.BIG.value}}
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <div v-if="good.packageLevel===1">
                  <el-radio-group class="checkGroup" v-model="good.buyPackageLevel" @change="radioChange">
                    <el-radio-button class="statusOpen" :label="PACKAGE_LEVEL.SMALL.key">{{PACKAGE_LEVEL.SMALL.value}}
                    </el-radio-button>
                  </el-radio-group>
                </div>
                <!--<el-radio-group v-if="good.packageLevel===1" class="checkGroup" v-model="good.packageLevel" @change="radioChange">
                  <el-radio-button class="statusOpen" :label="PACKAGE_LEVEL.SMALL.key">{{PACKAGE_LEVEL.SMALL.value}}
                  </el-radio-button>
                </el-radio-group>-->
              </el-form-item>
              <el-form-item class="labelTitle" :label="returnFlag===true?'退货单价':'进货单价'" prop="goodsPrice">
                <el-input-number class="inputGroup" @change="priceChange" v-model="good.goodsPrice" :precision="2"
                                 :step="0.1" :min="0.00" :max="999999.99" placeholder="请输入销售单价"></el-input-number>
                <div v-if="good.buyPackageLevel===1" class="priceUnit">
                  <!--<div class="unitName">{{good.smallUnitName}}</div>-->
                  <div class="unitName">元</div>
                  <div class="slash">/</div>
                  <div class="unitName">{{good.middleUnitName}}</div>
                </div>
                <div v-if="good.buyPackageLevel===2" class="priceUnit">
                  <!--<div class="unitName">{{good.middleUnitName}}</div>-->
                  <div class="unitName">元</div>
                  <div class="slash">/</div>
                  <div class="unitName">{{good.bigUnitName}}</div>
                </div>
              </el-form-item>
              <el-form-item class="inputGroup" :label="returnFlag===true?'退货数量':'进货数量'" prop="inventory">
                <el-input-number @change="countChange" :min="1" :max="99999" class="inputGroup"
                                 v-model="good.goodsNumber" placeholder="请输入进货数量"></el-input-number>
                <div class="priceUnit">
                  <div class="unitOneName">{{good.buyPackageLevel===1?good.middleUnitName:good.bigUnitName}}</div>
                </div>
              </el-form-item>

              <el-form-item class="totalMoney" label="小计" prop="saleBigPrice">
                <span class="totalPrice">{{good.totalPrice}} 元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-card class="imageBlock">
                <!--<el-image class="uploadImage imageFull" v-if="good.goodsImg" fit="scale-down"
                          :src="base_url+'/goods'+good.goodsImg"
                ></el-image>-->

                <div class="imgBox" v-if="good.goodsImg">
                  <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                  <div class="innerImg" v-if="good.isGreen==1">
                    <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                  </div>

                </div>

                <span class="imageDesc">商品图片</span>
              </el-card>

            </el-col>
          </el-row>

          <div class="bottomBtn">
            <el-button v-if="!editGoods & !saleStock" class="storeInfoBtn" icon="el-icon-s-goods" type="danger" :disabled="canSave"
                       @click="saveStockCart">添加购物车
            </el-button>
            <el-button v-if="editGoods & !saleStock" class="storeInfoBtn" icon="el-icon-s-goods" type="danger"
                       @click="saveStockCart" :disabled="canSave">保存
            </el-button>
            <el-button v-if="saleStock" class="storeInfoBtn" icon="el-icon-document-copy" type="danger"
                       @click="saveStockCart">进货
            </el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {},
    data () {
      return {
        message: '进货开单修改购物车商品',
        supplierName: '',
        good: {},
        centerDialogVisible: false,
        rules: {},
        PACKAGE_LEVEL: PACKAGE_LEVEL,
        base_url: '',
        editGoods: false,//添加/编辑购物车
        saleStock: false,
        editGood:false,//编辑状态下的购物车
        returnFlag:false,//销货退货传入
        canSave:false,//库存限制
      }
    },
    mounted () {
      this.base_url=this.BASE_URL;
      //this.base_url = 'https://jxccs.weituodata.com:81'
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          this.centerDialogVisible = false;
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openStockGood (good, supplierName, flag, saleStock,editGood,returnFlag) {
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        this.good = good
        if(this.good.packageLevel===1){
          this.good.buyPackageLevel=1
        }
        this.good = $.extend({}, this.good)
        this.editGoods = false
        this.canSave=false;
        if (flag) {
          //编辑
          this.editGoods = true
        }
        if (saleStock) {
          this.saleStock = true
        }
        if(editGood){
          //销货
          this.editGood=true
        }
        if(returnFlag){
          //进货退货
          this.returnFlag=true;
        }
        this.supplierName = supplierName.toString()
        //this.getSearch(1)
      },

      saveStockCart () {
        if (this.editGoods) {
          this.$emit('editStockCart', this.good)
        }
        else if (this.saleStock) {
          let goodsCart={
            details:[],
          };
          goodsCart.supplierId = this.good.supplierList[0].supplierId
          goodsCart.supplierName = this.good.supplierList[0].supplierName
          this.good.packageLevel = this.good.buyPackageLevel
          if(this.good.buyPackageLevel===1){
            this.good.inventory+=this.good.goodsNumber
          }
          if(this.good.buyPackageLevel===2){
            this.good.inventory+=this.good.goodsNumber*this.good.middleCount
          }
          this.good.saleSmallPrice=this.good.smallGoodsPrice;
          this.good.saleBigPrice=this.good.bigGoodsPrice;
          this.good.added=true;
          this.good.totalPrice=this.good.goodsPrice*this.good.goodsNumber;
          goodsCart.details.push(this.good);
          goodsCart.accountsPayable = 0
          for (let i = 0; i < goodsCart.details.length; i++) {
            goodsCart.accountsPayable += goodsCart.details[i].totalPrice
          }
          goodsCart.num = goodsCart.details.length
          goodsCart.paymentAmount = goodsCart.accountsPayable
          console.log("goodsCart：",goodsCart)
          Loadings.show()
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/buyorder/save', goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '进货开单成功！', duration: 2000, offset: 110 });
                  //跳转到客户列表页面
                  this.sureDialogVisible = false
                  SessionStorage.remove(STOCK_CART)
                  console.log("this.editGood: ",this.editGood)
                  if(this.editGood){
                    this.$emit('saveStockSaleCart',this.good);
                  }else{
                    this.$emit('saveStockCart',this.good);
                  }

                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }
            })
        }
        else {
          this.canSave=true;
          this.$emit('addStockCart', this.good)
        }

        this.centerDialogVisible = false
      },
      radioChange (e) {
        let _this = this
        _this.good = $.extend({}, _this.good)
        _this.good.buyPackageLevel = e
        if (e === 1) {
          _this.good.goodsPrice = parseFloat(_this.good.smallGoodsPrice).toFixed(2)
        }
        if (e === 2) {
          _this.good.goodsPrice = parseFloat(_this.good.bigGoodsPrice).toFixed(2)
        }
        _this.good.buyPackageLevel = e
        _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
        if(_this.returnFlag){
          _this.inventoryLimit();
        }
      },
      priceChange (e) {
        if(e===undefined){
          e="0.00"
        }
        let _this = this
        _this.good = $.extend({}, _this.good)
        _this.good.goodsPrice = e
        _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
        _this.good.totalPrice = parseFloat(_this.good.totalPrice).toFixed(2)
        /*_this.$forceUpdate()*/
      },
      cartChange () {
        this.$emit('cartChange', this.good)
        this.centerDialogVisible = false
      },
      countChange (value) {
        let _this = this
        _this.good = $.extend({}, _this.good)
        _this.good.goodsNumber = value
        _this.good.totalPrice = _this.good.goodsNumber * _this.good.goodsPrice
        _this.good.totalPrice = parseFloat(_this.good.totalPrice).toFixed(2)
        _this.canSave=false;
        if(_this.returnFlag){
          _this.inventoryLimit();
        }
      },
      inventoryLimit(){
        let _this=this;
        if(_this.returnFlag){
          if(_this.good.buyPackageLevel===1){
            if(_this.good.goodsNumber>_this.good.inventory){
              _this.canSave=true;
              this.$toast.error("商品库存不足，无法退货！")
              //_this.$emit('toStockOrder',_this.good,true);
            }
          }
          if(_this.good.buyPackageLevel===2){
            if(_this.good.goodsNumber*_this.good.middleCount>_this.good.inventory){
              _this.canSave=true;
              this.$toast.error("商品库存不足，无法退货！")
              //_this.$emit('toStockOrder',_this.good,true);
            }
          }
          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
        }
      }
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 825px !important;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    font-size: 18px;
    margin-left: 10px;
    color:  #ffffff;
    height: 40px;
    margin-top: 20px;
    text-decoration: none;
    cursor: pointer;
    max-width: 400px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__content {

  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .orderNav {
    position: relative;
    margin-bottom: 50px;
  }

  .inputLine {
    max-width: 230px;
    float: left;
  }

  .bottomBtn {
    display: inline-block;
    /*position: absolute;
    bottom: 80px;
    left: 45%;
    margin: 0 auto;*/
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }

  /*包装*/
  .checkGroup {
    float: left;
  }

  .inputGroup {
    float: left;
    margin-right: 5px;
  }

  .packageUnit {
  }

  .unitLine {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 5px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  .unitName {
    float: left;
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;
  }

  .unitOneName {
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;

  }

  .priceUnit {
    float: left;
    display: inline-block;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #DCDFE6;
  }

  .inventoryLine {
    float: left;
    max-width: 180px;
    margin-right: 10px;
  }

  /*小计*/
  .totalMoney {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
  }

  .totalPrice {
    float: left;
    font-size: 20px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 0;
  }

  .imageBlock {
    max-width: 270px;
    margin-left: 30px;
    padding: 20px;
  }

  .uploadImage {
    max-height: 210px;
  }

  .imageDesc {
    display: block;
    margin-top: 15px;
  }

  .stockSale {
    color: #f6e483;
    float: left;
  }

  .stockSale li {
    margin-right: 5px;
  }
  .imageFull{
    height: 230px;
  }
  .imgBox{
    position: relative;
  }
  .innerImg{
    position: absolute;
    left: 9px;
    bottom: 0;
    width: 91.8%;
  }
</style>
