<template>
  <div class="mainPages">
    <el-row @click="handleClick">
      <div class="pageNav">
        <router-link to="/admin/goodsManager" v-if="type!==4 && type!==5 && type!==6">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回商品管理</el-button>
        </router-link>
        <router-link to="/admin/stockOrders" v-if="type===4">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回进货开单</el-button>
        </router-link>
        <router-link to="/admin/saleOrders" v-if="type===5">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回销货开单</el-button>
        </router-link>
        <router-link to="/admin/recipelManagerEdit" v-if="type===6">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回处方管理</el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">{{ this.type === 1 ? '编辑商品' : '新增商品' }}</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>

    <input type="text" id="scanCode" class="scanCode" v-model="qrcode" @input="qrCodeInput"/>

    <div class="mainForm" @click="handleClick">
      <div class="toastMessage" v-if="this.type === 0 || type===4 || type===5">
        <el-alert title="提示：使用扫码枪录入商品时，请勿将鼠标光标放入任何输入框中" type="warning" :closable="false" center show-icon></el-alert>
      </div>
      <el-form :model="good" :rules="rules" ref="good" label-width="110px" class="goodForm">
        <el-row :gutter="20" class="rowForm">
          <el-col :span="12">
            <div class="leftForm">
              <el-form-item class="labelTitle" label="商品名称" prop="goodsName">
                <el-input class="inputLine"
                          v-model="good.goodsName"
                          placeholder="请输入商品名称"
                          @blur="goodsNameOnBlur"
                          maxlength="30"
                          @click.stop.native="">
                </el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="商品分类" prop="categoryId">
                <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                             placeholder="选择商品分类"
                             v-model="good.categoryId"
                             :options="categorys"
                             :props="{value:'categoryId',label:'categoryName',children:'children'}"
                             separator=" / "
                             @change="categoryChange"
                             @click.stop.native="">
                </el-cascader>
              </el-form-item>
              <el-form-item class="labelTitle" label="登记证号" prop="pdno" v-if="isShowpdno">
                <el-input class="inputLine" v-model="good.pdno" placeholder="请输入登记证号"
                          @click.stop.native="" @blur="pdnoBlur(good)"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="批准文号" prop="approvalNumber" v-if="isApprovalNumber">
                <el-input class="inputLine" v-model="good.approvalNumber" placeholder="请输入批准文号"
                          @click.stop.native="" @blur="approvalNumberBlur(good)"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="生产厂家" prop="companyName">
                <el-input class="inputLine" v-model="good.companyName" placeholder="请输入生产厂家" maxlength="50"
                          @click.stop.native=""></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="施用范围" prop="useRange" v-if="isShowpdno || isApprovalNumber">
                <el-input class="inputLine" :rows="2" type="textarea" maxlength="2000"
                          v-model="good.useRange" placeholder="请输入施用范围"
                          @click.stop.native=""></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="所属供应商" prop="supplierNames">
                <el-tag class="keyTags" id="supplierIds"
                        :key="index"
                        v-for="(tag,index) in supplierNames"
                        :disable-transitions="false"
                        @click.stop.native="">
                  {{tag}}
                </el-tag>
                <el-button class="keyTagsInput" icon="el-icon-circle-plus-outline" type="primary"
                           @click="showChooseDialog" v-if="type!==4 && type!==0 && type!==1 && type!==5">选择供应商
                </el-button>
                <el-button class="keyTagsInput" icon="el-icon-circle-plus-outline" type="primary"
                           @click="showChooseMore" v-if="type===0 || type===1 || type===5">选择供应商
                </el-button>
              </el-form-item>

              <el-form-item class="labelTitle" label="包装规格" prop="packageLevel">
                <el-radio-group class="checkGroup" v-model="good.packageLevel" @change="radioChange">
                  <el-radio-button class="statusOpen" :label="PACKAGE_LEVEL.SMALL.key">{{ PACKAGE_LEVEL.SMALL.value }}
                  </el-radio-button>
                  <el-radio-button class="statusStop" :label="PACKAGE_LEVEL.BIG.key">{{ PACKAGE_LEVEL.BIG.value }}
                  </el-radio-button>
                </el-radio-group>
                <div class="packageUnit" v-if="good.packageLevel===1">
                  <div class="unitLine">
                    <el-input-number
                      class="packageUnitLine"
                      placeholder="小包装数量"
                      v-model="good.smallCount"
                      :min="1"
                      :max="9999"
                      @change="smallNumberChange"
                      @click.stop.native="">
                    </el-input-number>
                    <!-- 小包装单位选择 -->
                    <el-select class="unitSelect" v-model="good.smallUnitId" @change="handleChangeSmallUnit"
                               placeholder="请选择">
                      <el-option
                        v-for="item in smallUnits"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                        @click.stop.native="">
                      </el-option>
                    </el-select>
                    <div class="slash">/</div>
                    <!-- 中包装单位选择 -->
                    <el-select class="unitSelectRight" v-model="good.middleUnitId" @change="handleChangeMiddleUnit"
                               placeholder="请选择">
                      <el-option
                        v-for="item in middleUnits"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                        @click.stop.native="">
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <div class="packageUnit" v-if="good.packageLevel===2">
                  <div class="unitLine">
                    <el-input-number
                      class="packageUnitLine"
                      placeholder="请输入数量"
                      v-model="good.smallCount"
                      :min="1"
                      :max="9999"
                      @change="smallNumberChange"
                      @click.stop.native="">
                    </el-input-number>
                    <!-- 小包装单位选择 -->
                    <el-select class="unitSelect" v-model="good.smallUnitId" @change="handleChangeSmallUnit"
                               placeholder="请选择" @click.stop.native="">
                      <el-option
                        v-for="item in smallUnits"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId">
                      </el-option>
                    </el-select>
                    <div class="slash">/</div>
                    <!-- 中包装单位选择 -->
                    <el-select class="unitSelect" v-model="good.middleUnitId" @change="handleChangeMiddleUnit"
                               placeholder="请选择" @click.stop.native="">
                      <el-option
                        v-for="item in middleUnits"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="unitLine">
                    <el-input-number
                      step-strictly
                      @change="middleNumberChange"
                      :min="1"
                      :max="9999"
                      class="packageUnitLine"
                      v-model="good.middleCount"
                      placeholder="请输入数量"
                      @click.stop.native="">
                    </el-input-number>
                    <!-- 中包装单位选择 -->
                    <el-select class="unitSelect" v-model="good.middleUnitId" @change="handleChangeMiddleUnit"
                               placeholder="请选择">
                      <el-option
                        v-for="item in middleUnits"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                        @click.stop.native="">
                      </el-option>
                    </el-select>
                    <div class="slash">/</div>
                    <!-- 大包装单位选择 -->
                    <el-select class="unitSelectRight" v-model="good.bigUnitId" @change="handleChangeBigUnit"
                               placeholder="请选择">
                      <el-option
                        v-for="item in bigUnits"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                        @click.stop.native="">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </el-form-item>
              <!-- 销售单价 -->
              <el-form-item class="labelTitle" label="销售单价" prop="saleSmallPrice">
                <el-input-number
                  class="priceInput"
                  v-model="good.saleSmallPrice"
                  :precision="2"
                  :step="0.1"
                  :max="999999.99"
                  placeholder="请输入"
                  @blur="saleSmallPriceBlur"
                  @click.stop.native="">
                </el-input-number>
                <div class="priceUnit">
                  <div class="unitName">元</div>
                  <div class="slash">/</div>
                  <div class="unitName">{{ this.middleUnits[this.middleUnitIndex] ?
                    this.middleUnits[this.middleUnitIndex].unitName : '无' }}
                  </div>
                </div>
              </el-form-item>

              <!-- 整箱销售价 -->
              <el-form-item v-if="good.packageLevel===2" class="labelTitle"
                            :label="getBigUnitPriceTitle()" prop="saleBigPrice">
                <el-input-number
                  class="priceInput"
                  v-model="good.saleBigPrice"
                  :precision="2"
                  :step="0.1"
                  :max="999999.99"
                  placeholder="请输入"
                  @click.stop.native="">
                </el-input-number>
                <div class="priceUnit">
                  <div class="unitName">元</div>
                  <div class="slash">/</div>
                  <div class="unitName">{{ this.bigUnits[this.bigUnitIndex] ? this.bigUnits[this.bigUnitIndex].unitName
                    : '无' }}
                  </div>
                </div>
              </el-form-item>
              <!-- 现有库存 -->
              <el-form-item class="labelTitle" label="现有库存" prop="inventory" v-if="type === 1 ">
                <el-input-number
                  class="priceInput"
                  step-strictly
                  placeholder="现有库存"
                  v-model="good.inventory"
                  :min="0"
                  :max="9999999"
                  @change="inventoryChange"
                  @click.stop.native=""
                  :disabled="type===1">
                </el-input-number>
                <div class="priceUnit">
                  <div class="unitName">{{ this.middleUnits[this.middleUnitIndex] ?
                    this.middleUnits[this.middleUnitIndex].unitName : '无' }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="报损" prop="loss" v-if="this.type===1">
                <el-input-number
                  class="inventoryLine"
                  step-strictly
                  placeholder="报损数量"
                  v-model="good.loss"
                  :min="0"
                  :max="9999999"
                  @click.stop.native=""
                  disabled>
                </el-input-number>
                <div class="priceUnit">
                  <div class="unitName">{{ this.middleUnits[this.middleUnitIndex] ?
                    this.middleUnits[this.middleUnitIndex].unitName : '无' }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="库存预警" prop="inventoryWarning" v-if="type===1">
                <el-input-number
                  placeholder="预警数量"
                  class="inventoryLine"
                  step-strictly
                  v-model="good.inventoryWarning"
                  :min="1"
                  :max="999"
                  @change="inventoryWarningChange"
                  @click.stop.native="">
                </el-input-number>
                <div class="priceUnit">
                  <div class="unitName">{{ this.middleUnits[this.middleUnitIndex] ?
                    this.middleUnits[this.middleUnitIndex].unitName : '无' }}
                  </div>
                </div>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="rightForm">
              <el-form-item class="labelTitle" label="库存预警" prop="inventoryWarning" v-if="type!==1">
                <el-input-number
                  placeholder="预警数量"
                  class="inventoryLine"
                  step-strictly
                  v-model="good.inventoryWarning"
                  :min="1"
                  :max="999"
                  @change="inventoryWarningChange"
                  @click.stop.native="">
                </el-input-number>
                <div class="priceUnit">
                  <div class="unitName">{{ this.middleUnits[this.middleUnitIndex] ?
                    this.middleUnits[this.middleUnitIndex].unitName : '无' }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item class="labelTitl picContent" label="商品图片" prop="goodsImg">
                <big-file :upload-tile="'上传图片'" :input-id="'upload-article-icon'"
                          :suffix-type="['jpg','jpeg','png','gif']"
                          :uniId="'fileUpload'" :use-type="'user'"
                          :after-upload="afterUpload">
                </big-file>
                <div class="imgBox">
                  <el-image class="uploadImage"
                            v-if="good.goodsImg"
                            :src="base_url+'/goods'+good.goodsImg">
                  </el-image>
                  <el-image v-if="good.isGreen==1" :style="{position:'absolute',bottom:'20px',left:'91px',width:'88px'}" :src="'/static/imgs/green_tag.png'"></el-image>
                </div>

                <span class="pic_prompt">建议上传500*500像素图片更佳</span>
              </el-form-item>
              <el-form-item class="labelTitle remarkContent" label="备注" prop="remark">
                <el-input id="stockDescription" class="inputCoreDesc" :rows="2"
                          type="textarea" maxlength="500" show-word-limit v-model="good.remark"
                          placeholder="请输入备注信息，最多500个字符"
                          @click.stop.native=""></el-input>
              </el-form-item>
              <!-- 条形码 -->
              <el-form-item label="条形码" v-if="this.type===1">
                <div class="barCodeItem">
                  <el-row>
                    <div class="itemTitle">
                      <el-col :span="12">
                        <div class="titleLeft">
                          {{ good.smallCount }}
                          {{ this.smallUnits[this.smallUnitIndex] ? this.smallUnits[this.smallUnitIndex].unitName : '无'
                          }}
                          /
                          {{ this.middleUnits[this.middleUnitIndex] ? this.middleUnits[this.middleUnitIndex].unitName :
                          '无' }}
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="titleRight">
                          <el-button type="warning" @click="print(1)" plain>打印</el-button>
                        </div>
                      </el-col>
                    </div>
                    <barcode class="itemImg" :value="good.smallBarCode">条形码显示失败</barcode>
                  </el-row>
                </div>
                <div class="barCodeItem">
                  <el-row>
                    <div class="itemTitle">
                      <el-col :span="12">
                        <div class="titleLeft">
                          {{ good.smallCount }}
                          {{ this.smallUnits[this.smallUnitIndex] ? this.smallUnits[this.smallUnitIndex].unitName : '无'
                          }}
                          /
                          {{ this.middleUnits[this.middleUnitIndex] ? this.middleUnits[this.middleUnitIndex].unitName :
                          '无' }}
                          X
                          {{ good.middleCount }}
                          {{ this.middleUnits[this.middleUnitIndex] ? this.middleUnits[this.middleUnitIndex].unitName :
                          '无' }}
                          /
                          {{ this.bigUnits[this.bigUnitIndex] ? this.bigUnits[this.bigUnitIndex].unitName : '无' }}
                        </div>
                      </el-col>
                      <el-col :span="12">
                        <div class="titleRight">
                          <el-button type="warning" @click="print(2)" plain>打印</el-button>
                        </div>
                      </el-col>
                    </div>
                    <barcode class="itemImg" :value="good.middleBarCode">条形码显示失败</barcode>
                  </el-row>
                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="经营许可证" prop="remark">
                <big-files :upload-tile="'上传证书照片'" :input-id="'upload-license-icon'"
                          :suffix-type="['jpg','jpeg','png','gif']"
                          :uniId="'filesLicenseUpload'" :use-type="'user'"
                          :after-upload="afterUploadLicense">
                </big-files>
                <div class="imageBox" v-if="good.businessFilenameArr" >
                  <div class="imageItem" v-for="(item,index) in good.businessFilenameArr" :key="index" @click="showMax">
                    <el-image class="uploadImages"
                              :src="item"
                              :preview-src-list="good.businessFilenameArr">
                    </el-image>
                    <div class="delImgBox" @click="delLicenseImg(item)"><i class="el-icon-delete delText"></i></div>
                  </div>

                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="生产许可证" prop="remark">
                <big-files :upload-tile="'上传证书照片'" :input-id="'upload-product-icon'"
                           :suffix-type="['jpg','jpeg','png','gif']"
                           :uniId="'filesProductUpload'" :use-type="'user'"
                           :after-upload="afterUploadProduct">
                </big-files>
                <div class="imageBox" v-if="good.productionFilenameArr" >
                  <div class="imageItem" v-for="(item,index) in good.productionFilenameArr" :key="index" @click="showMax">
                    <el-image class="uploadImages"
                              :src="item"
                              :preview-src-list="good.productionFilenameArr">
                    </el-image>
                    <div class="delImgBox" @click="delProductImg(item)"><i class="el-icon-delete delText"></i></div>
                  </div>

                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="质量标准证" prop="remark">
                <big-files :upload-tile="'上传证书照片'" :input-id="'upload-quality-icon'"
                           :suffix-type="['jpg','jpeg','png','gif']"
                           :uniId="'filesQualityUpload'" :use-type="'user'"
                           :after-upload="afterUploadQuality">
                </big-files>
                <div class="imageBox" v-if="good.qualityFilenameArr" >
                  <div class="imageItem" v-for="(item,index) in good.qualityFilenameArr" :key="index" @click="showMax">
                    <el-image class="uploadImages"
                              :src="item"
                              :preview-src-list="good.qualityFilenameArr">
                    </el-image>
                    <div class="delImgBox" @click="delQualityImg(item)"><i class="el-icon-delete delText"></i></div>
                  </div>

                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="合格证" prop="remark">
                <big-files :upload-tile="'上传证书照片'" :input-id="'upload-qualified-icon'"
                           :suffix-type="['jpg','jpeg','png','gif']"
                           :uniId="'filesQualifiedUpload'" :use-type="'user'"
                           :after-upload="afterUploadQualified">
                </big-files>
                <div class="imageBox" v-if="good.qualifiedFilenameArr" >
                  <div class="imageItem" v-for="(item,index) in good.qualifiedFilenameArr" :key="index" @click="showMax">
                    <el-image class="uploadImages"
                              :src="item"
                              :preview-src-list="good.qualifiedFilenameArr">
                    </el-image>
                    <div class="delImgBox" @click="delQualifiedImg(item)"><i class="el-icon-delete delText"></i></div>
                  </div>

                </div>
              </el-form-item>
              <el-form-item class="labelTitle" label="其他" prop="remark">
                <big-files :upload-tile="'上传其他照片'" :input-id="'upload-other-icon'"
                           :suffix-type="['jpg','jpeg','png','gif']"
                           :uniId="'filesOtherUpload'" :use-type="'user'"
                           :after-upload="afterUploadOther">
                </big-files>
                <div class="imageBox" v-if="good.otherFilenameArr" >
                  <div class="imageItem" v-for="(item,index) in good.otherFilenameArr" :key="index" @click="showMax">
                    <el-image class="uploadImages"
                              :src="item"
                              :preview-src-list="good.otherFilenameArr">
                    </el-image>
                    <div class="delImgBox" @click="delOtherImg(item)"><i class="el-icon-delete delText"></i></div>
                  </div>

                </div>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <div class="bottomBtn" :class="type==1?'paddingBottom':''">
          <el-button class="storeInfoBtn" type="danger" @click="save('good')">保存</el-button>
          <el-button class="resetBtn" v-if="this.type === 0 || type===4 || type===5" @click="resetForm('good')">重置
          </el-button>
        </div>
      </el-form>

    </div>
    <dialog-supplier-choose ref="dialogSupplierChoose" @getSuppliers="getSuppliers"></dialog-supplier-choose>
    <supplier-choose-more ref="supplierChooseMore" @getSupplierMore="getSupplierMore"></supplier-choose-more>
  </div>
</template>

<script>

  import DialogSupplierChoose from '../../../components/DialogSupplierChoose'
  import BigFile from '../../../components/BigFile'
  import SupplierChooseMore from '../../../components/SupplierChooseMore'
  import { getLodop } from '/public/static/js/utils/LodopFuncs.js'
  import BigFiles from '../../../components/BigFiles'
  export default {
    name: 'GoodsManagerEdit',
    components: {
      DialogSupplierChoose,
      BigFile,
      SupplierChooseMore,
      BigFiles
    },
    data () {
      //登记证号验证器
      let validate_pdno = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        // if (!value) {
        //   callback(new Error('请输入登记证号'))
        // } else if (getLength(value) != 10) {
        //   callback(new Error('请输入正确的登记证号'))
        // } else {
        //   callback()
        // }

        //登记证号校验规则修改
        if (!value) {
          callback(new Error('请输入登记证号'))
        } else {
          callback()
        }
      }

      //批准文号
      let validate_approvalNumber = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }
        if (!value) {
          callback(new Error('请输入批准文号'))
        } else {
          callback()
        }
      }

      //施用范围
      let validate_useRange = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }
        if (!value) {
          callback(new Error('请输入施用范围'))
        } else {
          callback()
        }
      }

      //所属供应商验证器
      let validate_supplierNames = (rule, value, callback) => {
        if (!this.supplierNames || this.supplierNames.length <= 0) {
          callback(new Error('请选择所属供应商'))
        } else {
          callback()
        }
      }
      //包装规格验证器
      let validate_packageLevel = (rule, value, callback) => {
        if (this.good.packageLevel != 1 && this.good.packageLevel != 2) {
          callback(new Error('请选择包装规格'))
        } else if (!this.good.smallCount) {
          callback(new Error('请输入小包装数量'))
        } else if (this.good.packageLevel == 2 && !this.good.middleCount) {
          callback(new Error('请选择中包装数量'))
        } else {
          callback()
        }
      }
      return {
        message: '供应商管理',
        type: 0,
        searchInput: '',
        checkedStop: false, //默认不显示停用供应商
        supplierName: '',
        isShowpdno: false, //是否显示登记证号，只有农药需要填写登记证号
        isApprovalNumber: false, //是否显示批准文号（兽药）
        qrcode: '', //扫码数据
        good: {
          goodsId: 0, //商品id
          goodsName: '', //商品名称
          categoryId: '', //商品分类
          pdno: '', //登记证号
          approvalNumber:'',//批准文号
          companyName: '', //生产厂家
          useRange:'',//施用范围
          packageLevel: 1, //包装规格
          smallCount: 0, //小包装数值
          smallUnitId: 0, //小包装单位id
          middleCount: 0, //中包装数值
          middleUnitId: 0, //中包装单位id
          bigUnitId: 0, //大包装单位id
          saleSmallPrice: 0.00, //中包装销售单价
          saleBigPrice: 0.00, //大包装单位销售单价
          inventory: 0, //现有库存
          inventoryWarning: 5, //库存预警
          goodsImg: '', //商品图片
          remark: '', //备注
          businessFilename:"",//经营许可证
          businessFilenameArr:[],
          productionFilename:"",//生产许可证
          productionFilenameArr:[],
          qualityFilename:"",//质量标准证
          qualityFilenameArr:[],
          qualifiedFilename:"",//合格证
          qualifiedFilenameArr:[],
          otherFilename:"",//其他图片
          otherFilenameArr:[],
          isGreen:'',//是否为绿色产品，1=是 0=否
        },
        PACKAGE_LEVEL: PACKAGE_LEVEL,
        //级联选择
        categorys: [],
        category: [],
        categoryId: '',
        //供应商
        //supplierIds:[615, 617],
        supplierIds: [],
        //supplierNames:['供应商A', '供应商B'],
        supplierNames: [],
        strSuppliers: '',
        supplierId: '',
        inputVisible: false,
        //弹框
        centerDialogVisible: false,

        smallUnits: [], //小包装单位集合
        middleUnits: [], //中包装单位集合
        bigUnits: [], //大包装单位集合

        smallUnitIndex: 0, //小包装单位下拉列表选中下标
        middleUnitIndex: 0, //中包装单位下拉列表选中下标
        bigUnitIndex: 0, //大包装单位下拉列表选中下标

        timer: -1, //setTimeout定时任务
        //文件上传
        files: '',
        base_url: '',
        //销货传入商品类型
        isPesticide:'',
        rules: {
          //商品名称
          goodsName: [{
            required: true,
            message: '请输入商品名称',
            trigger: 'blur'
          }],
          //商品分类
          categoryId: [{
            required: true,
            message: '请选择商品分类',
            trigger: 'blur'
          }],
          //登记证号
          pdno: [{
            required: true,
            validator: validate_pdno,
            trigger: 'blur'
          }],
          //批准文号
          approvalNumber:[
            {
              required: true,
              validator: validate_approvalNumber,
              trigger: 'blur'
            }
          ],
          //施用范围
          useRange:[
            {
              required: true,
              validator: validate_useRange,
              trigger: 'blur'
            }
          ],
          //所属供应商
          supplierNames: [{
            required: true,
            validator: validate_supplierNames,
            trgger: 'blur'
          }],
          //包装规格
          packageLevel: [{
            required: true,
            validator: validate_packageLevel,
            trigger: 'blur'
          }],
        },

        tenantInfo: {},
        saleNoGood:{},//销货传入没有的商品
      }
    },
    created () {

    },
    mounted () {

      this.tenantInfo = LocalStorage.get(TENANT_INFO);

      if (document.getElementById('scanCode')) {
        document.getElementById('scanCode').focus()
      }
      // document.onkeyup = (e) => {
      //   console.log('按键弹起');
      // }
      // document.onkeydown = (e) => {
      //   console.log('按键按下');
      // }

      this.base_url = this.BASE_URL

      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        //获取分类数据
        this.getCategorys()
        //获取包装单位数据集合
        this.getSmallUnits()
        this.getMiddleUnits()
        this.getBigUnits()
        let current = $(window).width()
        this.changeWidth(current)
      }
      //编辑：type=1 新增：type=0
      if (this.$route.params.type || this.$route.params.type === 0 || this.$route.params.type===6) {
        this.type = this.$route.params.type
        this.supplierIds = this.$route.params.supplierIds
        this.supplierNames = this.$route.params.supplierNames
        let sup = {
          supplierIds: this.supplierIds,
          supplierNames: this.supplierNames
        }
        if(this.type===6){
          this.supplierIds=[]
          this.supplierNames=[]
        }
        SessionStorage.set(GOODS_EDIT_TYPE, this.type)
        SessionStorage.set(GOODS_SUPPLIER, sup)
        SessionStorage.remove(GOODS_ID)
        if (this.type == 1) {
          SessionStorage.set(GOODS_ID, this.$route.params.goodsId)
        }
      }

      if (this.type === 0 || this.type === 3 || this.type===6) {
        setTimeout(() => {
          //新增页面默认显示
          this.good.packageLevel = 2
          this.good.smallCount = 1
          //this.good.smallUnitId = this.smallUnits[0].unitId;
          this.good.middleCount = 10
          //this.good.middleUnitId = this.middleUnits[0].unitId;
          //this.good.bigUnitId = this.bigUnits[0].unitId;
          //包装单位默认选中
          // this.smallUnitIndex = 0;
          // this.middleUnitIndex = 0;
          // this.bigUnitIndex = 0;
          //库存预警
          this.good.inventoryWarning = 5
          if(Tool.isNotEmpty(SessionStorage.get(GOODS_ID))){
            this.type=1
            this.good.goodsId = SessionStorage.get(GOODS_ID)
            this.getGoodById();
          }
        }, 200)
      } else if (this.type === 1) {
        this.good.goodsId = this.$route.params.goodsId
        if (!this.good.goodsId) {
          this.good.goodsId = SessionStorage.get(GOODS_ID)
        }
        this.getGoodById();
      } else if (this.type === 4) { //进货开单跳转
        setTimeout(() => {
          if (this.$route.params.item) {
            this.good.goodsName = this.$route.params.item.goodsName
            this.good.companyName = this.$route.params.item.companyName
            this.good.pdno = this.$route.params.item.pdno
            this.good.seedCode = this.$route.params.item.seedCode
          }
          //this.isShowpdno = true
          this.supplierIds = this.$route.params.supplierIds
          this.supplierNames = this.$route.params.supplierNames
          //新增页面默认显示
          this.good.packageLevel = 2
          this.good.smallCount = 1
          this.good.middleCount = 10

          // this.good.smallUnitId = this.smallUnits[0].unitId
          // this.good.middleUnitId = this.middleUnits[0].unitId
          // this.good.bigUnitId = this.bigUnits[0].unitId
          //包装单位默认选中
          // this.smallUnitIndex = 0
          // this.middleUnitIndex = 0
          // this.bigUnitIndex = 0
          //库存预警
          this.good.inventoryWarning = 5
        }, 200)

      } else if (this.type === 5) { //销货开单跳转
        if(this.$route.params.isPesticide){
          this.isPesticide=this.$route.params.isPesticide
        }
        //this.isShowpdno = true
        //console.log("this.$route.params.item: ",this.$route.params.item)
        this.saleNoGood=this.$route.params.item
        //console.log("saleNoGood: ",this.saleNoGood)
        if (this.$route.params.item) {
          this.good.goodsName = this.$route.params.item.goodsName
          this.good.companyName = this.$route.params.item.companyName
          if(this.$route.params.item.pdno){
            this.good.pdno = this.$route.params.item.pdno
            this.good.seedCode = this.$route.params.item.seedCode
            this.isShowpdno=true
            this.categoryId=this.$route.params.item.farmChemicalTypeCode
            this.good.categoryId=[2,this.$route.params.item.farmChemicalTypeCode]
          }else if(this.$route.params.item.approvalNumber){
            this.good.approvalNumber=this.$route.params.item.approvalNumber
            this.isApprovalNumber=true
            this.categoryId=this.$route.params.item.farmChemicalTypeCode
            this.good.categoryId=[7,this.$route.params.item.farmChemicalTypeCode]
          }

        }

        //新增页面默认显示
        this.good.packageLevel = 2
        this.good.smallCount = 1
        this.good.middleCount = 10
        /*
        setTimeout(() => {
          this.good.smallUnitId = this.smallUnits[0].unitId
          this.good.middleUnitId = this.middleUnits[0].unitId
          this.good.bigUnitId = this.bigUnits[0].unitId
          //包装单位默认选中
          this.smallUnitIndex = 0
          this.middleUnitIndex = 0
          this.bigUnitIndex = 0
        }, 100)
        */

        //库存预警
        this.good.inventoryWarning = 5
      }
      /*setTimeout(()=>{
        let canClose = document.getElementsByClassName('keyTags')
        for(let i=0;i<canClose.length;i++){
          canClose[i].childNodes[1].style.display='none'
        }
      },250)*/

      this.type = SessionStorage.get(GOODS_EDIT_TYPE)
      this.supplierIds = SessionStorage.get(GOODS_SUPPLIER).supplierIds
      this.supplierNames = SessionStorage.get(GOODS_SUPPLIER).supplierNames
      console.log("type: ",this.type)
      this.navChange()
    },
    methods: {
      /**
       * type 区分小包装条码和大包装条码
       */
      print (type) {

        //判断用户是否有打印权限
        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
          return;
        }

        /*
        let LODOP = getLodop();

        LODOP.PRINT_INIT('商品条形码打印'); //首先一个初始化语句

        LODOP.SET_PRINT_PAGESIZE(3, 580, 0, ''); //设定纸张大小

        LODOP.SET_PRINT_STYLE('FontName', '楷书');
        //LODOP.SET_PRINT_STYLE('FontSize', '10');
        //台头
        //LODOP.ADD_PRINT_RECTA(0,0,180,1,0);
        LODOP.ADD_PRINT_TEXT(5, 20, 580, 40, '*慧台账进销存*');
        LODOP.SET_PRINT_STYLEA(1,"FontSize",15);
        LODOP.SET_PRINT_STYLEA(1,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(35,0,580,40,'供应商：');
        LODOP.SET_PRINT_STYLEA(2,"FontSize",11);
        LODOP.SET_PRINT_STYLEA(2,"FontName","微软雅黑");
        for(let i=0;i<this.supplierNames.length;i++){
          LODOP.ADD_PRINT_TEXT(35,60,580,40,this.supplierNames[i]);
        }
        LODOP.ADD_PRINT_TEXT(50, 0, 360, 54, '---------------------------------------------');
        LODOP.ADD_PRINT_TEXT(65,0,580,40,'商品名称：');
        LODOP.SET_PRINT_STYLEA(5,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(5,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(67,70,580,40,this.good.goodsName);
        LODOP.ADD_PRINT_TEXT(85,0,580,40,'商品分类：');
        LODOP.SET_PRINT_STYLEA(7,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(7,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(87,70,580,40,this.good.categoryName);
        LODOP.ADD_PRINT_TEXT(105,0,580,40,'生产厂家：');
        LODOP.SET_PRINT_STYLEA(9,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(9,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(107,70,580,40,this.good.companyName);
        LODOP.ADD_PRINT_TEXT(125,0,580,40,'包装规格：');
        LODOP.SET_PRINT_STYLEA(11,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(11,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(128,70,580,40,this.good.packingSpecification);
        LODOP.SET_PRINT_STYLEA(12,"FontSize",7);
        LODOP.SET_PRINT_STYLEA(12,"FontName","微软雅黑");

        LODOP.ADD_PRINT_TEXT(140, 0, 360, 54, '---------------------------------------------');
        LODOP.ADD_PRINT_TEXT(155,0,580,40,'销售单价：');
        LODOP.SET_PRINT_STYLEA(14,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(14,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(157,80,580,40,this.good.saleSmallPrice);
        LODOP.ADD_PRINT_TEXT(175,0,580,40,'整箱销售价：');
        LODOP.SET_PRINT_STYLEA(16,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(16,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(177,90,580,40,this.good.saleBigPrice);
        LODOP.ADD_PRINT_TEXT(195,0,580,40,'现有库存：');
        LODOP.SET_PRINT_STYLEA(18,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(18,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(197,80,580,40,this.good.inventory);
        LODOP.ADD_PRINT_TEXT(215,0,580,40,'报损：');
        LODOP.SET_PRINT_STYLEA(20,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(20,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(217,40,580,40,this.good.loss);
        LODOP.ADD_PRINT_TEXT(235,0,580,40,'库存预警：');
        LODOP.SET_PRINT_STYLEA(22,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(22,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(237,80,580,40,this.good.inventoryWarning);

        LODOP.ADD_PRINT_TEXT(250, 0, 360, 54, '---------------------------------------------');
        LODOP.SET_PRINT_STYLEA(24,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(24,"FontName","微软雅黑");
        LODOP.ADD_PRINT_TEXT(265, 0, 580, 40, '条形码');
        //打印数字条形码
        LODOP.ADD_PRINT_BARCODE(280,'0mm','58mm','20mm','128C','2112201043510002');
        LODOP.ADD_PRINT_TEXT(370,0,580,40,'备注：');
        LODOP.ADD_PRINT_TEXT(390,0,580,20,this.good.remark);
        LODOP.PREVIEW(); //打印预览
        //LODOP.PRINT(); //直接打印
        */

        /*
        let lodop = getLodop();
        lodop.SET_PRINT_PAGESIZE(3, 540, 0, "");
        //lodop.ADD_PRINT_HTM(10, 0, "100%", "", document.getElementById("box").innerHTML);
        lodop.ADD_PRINT_HTML(10, 0, "100%", "", document.getElementById("box").innerHTML);
        lodop.PREVIEW();
        */

        /*
        let LODOP = getLodop();
        LODOP.NEWPAGEA();
        LODOP.ADD_PRINT_TEXT(30,10,258,54,"****#1 尤友订单****");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);

        LODOP.ADD_PRINT_TEXT(80,50,258,54,"中交和美新城(后门)");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",10);

        LODOP.ADD_PRINT_TEXT(120,40,258,54,"--已在线支付--");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);

        LODOP.ADD_PRINT_TEXT(150,15,258,54,"................................");
        LODOP.ADD_PRINT_TEXT(180,15,258,54,"【下单时间】2018-12-01 04:02:00");
        LODOP.ADD_PRINT_TEXT(210,15,258,54,"..............商品..............");
        //商品列表
        LODOP.ADD_PRINT_TEXT(230,15,100,54,"蒙牛奶特香草味牛奶243ml*12盒");
        LODOP.ADD_PRINT_TEXT(230,130,40,54,"*1");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",14);
        LODOP.ADD_PRINT_TEXT(230,170,40,54,"22");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",14);

        LODOP.ADD_PRINT_TEXT(270,15,100,54,"蒙牛未来星妙妙儿童成长牛奶原味125ml*20盒");
        LODOP.ADD_PRINT_TEXT(270,130,40,54,"*1");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",14);
        LODOP.ADD_PRINT_TEXT(270,170,40,54,"22");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",14);
        //其他
        LODOP.ADD_PRINT_TEXT(320,15,258,54,"..............其他..............");
        LODOP.ADD_PRINT_TEXT(340,15,258,54,"配送费：0.00元");
        LODOP.ADD_PRINT_TEXT(360,15,258,54,"优惠券：0.00元");
        LODOP.ADD_PRINT_TEXT(380,15,258,54,"商品总金额：0.00元");
        LODOP.ADD_PRINT_TEXT(400,15,258,54,"................................");
        //总计
        LODOP.ADD_PRINT_TEXT(420,15,258,54,"总计：");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);
        LODOP.ADD_PRINT_TEXT(420,100,100,54,"￥136.06");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);
        //地址
        LODOP.ADD_PRINT_TEXT(460,15,200,54,"海上五月花5期天马路海上五月花5期7幢1001");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);
        //收货人
        LODOP.ADD_PRINT_TEXT(540,15,200,54,"依恋");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",36);
        LODOP.ADD_PRINT_TEXT(570,15,200,54,"18859669092");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);
        LODOP.ADD_PRINT_TEXT(600,10,258,54,"*******#1 完******");
        LODOP.SET_PRINT_STYLEA(0,"fontsize",16);
        //设定打印页面大小
        LODOP.SET_PRINT_PAGESIZE(3,580,45,"订单页面");
        LODOP.PREVIEW();
        */

        let topDistance = 5

        let LODOP = getLodop()

        if (LODOP === 'Error:DriveNotInstalled') {
          //console.log('打印插件未安装！');
          this.$message({
            type: 'error',
            message: '打印插件未安装！请点击右下角悬浮图标，进行下载安装。',
            duration: 2000,
            offset: 110
          })
          return
        } else if (LODOP === 'Error:DriverNeedsToBeUpgraded') {
          //console.log('打印插件需升级！');
          this.$message({
            type: 'error',
            message: '打印插件需升级！请点击右下角悬浮图标，进行下载安装。',
            duration: 2000,
            offset: 110
          })
          return
        }

        LODOP.NEWPAGEA()
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')
        topDistance += 20
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '商品名称：')
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')
        //this.good.goodsName = '硝~烟~莠去津3 六神生产厂生产药品，商品标识商品名称，通用名称均为硝烟莠去津';
        //topDistance += 3
        //高度+17加一行
        let height_goodsName = 17
        if (this.good.goodsName.length > 9) {
          height_goodsName += Math.ceil(this.good.goodsName.length / 9) * 17
        }

        LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_goodsName, this.good.goodsName)
        if (this.good.goodsName.length > 9) {
          topDistance += Math.ceil(this.good.goodsName.length / 9) * 20
        } else {
          topDistance += 20
        }

        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '包装规格：')
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

        //topDistance += 3

        let packingSpecification = ''
        if (type == 1) {
          packingSpecification = this.good.smallCount + this.good.smallUnitName + '/' + this.good.middleUnitName
        } else if (type == 2) {
          packingSpecification = this.good.smallCount + this.good.smallUnitName + '/' + this.good.middleUnitName + ' ' +
            this.good.middleCount + this.good.middleUnitName + '/' + this.good.bigUnitName
        }

        let height_packageSpecification = 10
        if (packingSpecification.length > 12) {
          height_packageSpecification += Math.ceil(packingSpecification.length / 12) * 17
        }

        //LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,packingSpecification);
        LODOP.ADD_PRINT_TEXT(topDistance, 65, 120, height_packageSpecification, packingSpecification)

        if (packingSpecification.length > 12) {
          topDistance += Math.ceil(packingSpecification.length / 12) * 20
        } else {
          topDistance += 20
        }

        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '备注：')
        LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
        LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')
        //topDistance += 3

        let height_remark = 0
        if (this.good.remark.length > 11) {
          height_remark += Math.floor(this.good.remark.length / 11) * 17
        }

        LODOP.ADD_PRINT_TEXT(topDistance, 43, 150, height_remark, this.good.remark)

        if (this.good.remark.length > 11) {
          topDistance += (Math.floor(this.good.remark.length / 11) * 20)
        } else {
          topDistance += 20
        }

        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------')

        topDistance += 20
        //LODOP.ADD_PRINT_BARCODE(topDistance,'0mm','58mm','20mm','128C','2112201043510002');
        if (type == 1) {
          LODOP.ADD_PRINT_BARCODE(topDistance, '1mm', '54mm', '20mm', '128C', this.good.smallBarCode)
        } else if (type == 2) {
          LODOP.ADD_PRINT_BARCODE(topDistance, '1mm', '54mm', '20mm', '128C', this.good.middleBarCode)
        }

        //设定打印页面大小
        LODOP.SET_PRINT_PAGESIZE(3, 580, 24, '商品条码')
        LODOP.PREVIEW()
      },
      //商品扫码回调函数
      qrCodeInput () {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.qrcode.indexOf('&') !== -1) {
            this.qrcode = this.qrcode.replace(new RegExp('&', 'g'), '%26')
          }
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/goods/decodeQrCode?qrcode=' + this.qrcode,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                console.log("res: ",res)
                if (res.data.code === 200) {
                  if (this.$route && this.$route.params && this.$route.params.isPesticide) {
                    //isPesticide = 1时为处方开单，当为处方开单时，不允许扫描非农药商品
                    if (this.$route.params.isPesticide == 1) {
                      if (!res.data.data.pdno) {
                        this.$message({
                          type: 'warning',
                          message: '该产品不支持处方开单，请重新扫码',
                          duration: 2000,
                          offset: 110
                        });
                        return;
                      }
                    }
                    //isPesticide = 2时为销货开单，当为销货开单时，不允许扫描农药
                    // else if (this.$route.params.isPesticide == 2) {
                    //   if (res.data.data.pdno) {
                    //     this.$message({
                    //       type: 'warning',
                    //       message: '如需销售农药，请选择处方开单',
                    //       duration: 2000,
                    //       offset: 110
                    //     });
                    //     return;
                    //   }
                    // }
                  }
                  this.good.goodsName = res.data.data.goodsName
                  this.good.companyName = res.data.data.companyName
                  if(res.data.data.useRange){
                    this.good.useRange=res.data.data.useRange
                  }
                  if (res.data.data.pdno) {
                    this.good.pdno = res.data.data.pdno
                    this.isShowpdno = true
                    this.good.seedCode = res.data.data.seedCode?res.data.data.seedCode:''
                    this.categoryId=res.data.data.farmChemicalTypeCode
                    this.good.categoryId=[2,res.data.data.farmChemicalTypeCode]

                  }
                  if(res.data.data.approvalNumber){
                    this.good.approvalNumber=res.data.data.approvalNumber
                    this.isApprovalNumber=true
                    this.categoryId=res.data.data.farmChemicalTypeCode
                    this.good.categoryId=[7,res.data.data.farmChemicalTypeCode]
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }, 100)
      },
      navChange () {
        let flag = 0
        if (this.type === 1) {
          flag = 1
        }
        this.$emit('navChange', flag)
      },
      handleClick () {
        if (document.getElementById('scanCode')) {
          document.getElementById('scanCode').focus()
        }
      },
      //整箱销售价，根据大包装单位进行动态获取
      getBigUnitPriceTitle () {
        return this.bigUnits[this.bigUnitIndex] ? '整' + this.bigUnits[this.bigUnitIndex].unitName + '销售价' : '整箱销售价'
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      //商品名称失去焦点校验
      goodsNameOnBlur () {
        if (!this.good.goodsName) {
          return
        }
        if (this.type === 0) {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/goods/checkGoodsName?goodsName=' + this.good.goodsName,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        } else if (this.type === 1) {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/goods/checkGoodsName?goodsName=' + this.good.goodsName +
              '&goodsId=' + this.good.goodsId,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
      },
      //包装单位发生改变时回调
      handleChangeSmallUnit (value) {
        for (let i = 0; i < this.smallUnits.length; i++) {
          if (this.smallUnits[i].unitId === value) {
            this.smallUnitIndex = i
            break
          }
        }
      },
      handleChangeMiddleUnit (value) {
        for (let i = 0; i < this.middleUnits.length; i++) {
          if (this.middleUnits[i].unitId === value) {
            this.middleUnitIndex = i
            break
          }
        }
      },
      handleChangeBigUnit (value) {
        for (let i = 0; i < this.bigUnits.length; i++) {
          if (this.bigUnits[i].unitId === value) {
            this.bigUnitIndex = i
            break
          }
        }
      },
      //重置表单
      resetForm (formName) {
        this.$refs[formName].resetFields()
        this.good.pdno = ''

        //新增页面默认显示
        this.good.packageLevel = 2
        this.good.smallCount = 1
        this.good.smallUnitId = this.smallUnits[0].unitId
        this.good.middleCount = 10
        this.good.middleUnitId = this.middleUnits[0].unitId
        this.good.bigUnitId = this.bigUnits[0].unitId
        //包装单位默认选中
        this.smallUnitIndex = 0
        this.middleUnitIndex = 0
        this.bigUnitIndex = 0
        //库存预警
        this.good.inventoryWarning = 5
      },
      save (formName) {
        //经营许可证
        let businessFilenameStr=[]
        for(let i=0;i<this.good.businessFilenameArr.length;i++){
          let itemArr=this.good.businessFilenameArr[i].split("/");
          //console.log("itemArr: ",itemArr)
          let currentImgUrl=''
          for(let i=0;i<itemArr.length;i++){
            currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
          }
          businessFilenameStr.push(currentImgUrl)
        }
        this.good.businessFilename=businessFilenameStr.toString()
        //生产许可证
        let productionFilenameStr=[]
        for(let i=0;i<this.good.productionFilenameArr.length;i++){
          let itemArr=this.good.productionFilenameArr[i].split("/");
          //console.log("itemArr: ",itemArr)
          let currentImgUrl=''
          for(let i=0;i<itemArr.length;i++){
            currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
          }
          productionFilenameStr.push(currentImgUrl)
        }
        this.good.productionFilename=productionFilenameStr.toString()
        //质量标准证
        let qualityFilenameStr=[]
        for(let i=0;i<this.good.qualityFilenameArr.length;i++){
          let itemArr=this.good.qualityFilenameArr[i].split("/");
          //console.log("itemArr: ",itemArr)
          let currentImgUrl=''
          for(let i=0;i<itemArr.length;i++){
            currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
          }
          qualityFilenameStr.push(currentImgUrl)
        }
        this.good.qualityFilename=qualityFilenameStr.toString()
        //合格证
        let qualifiedFilenameStr=[]
        for(let i=0;i<this.good.qualifiedFilenameArr.length;i++){
          let itemArr=this.good.qualifiedFilenameArr[i].split("/");
          //console.log("itemArr: ",itemArr)
          let currentImgUrl=''
          for(let i=0;i<itemArr.length;i++){
            currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
          }
          qualifiedFilenameStr.push(currentImgUrl)
        }
        this.good.qualifiedFilename=qualifiedFilenameStr.toString()
        //其他文件
        let otherFilenameStr=[]
        for(let i=0;i<this.good.otherFilenameArr.length;i++){
          let itemArr=this.good.otherFilenameArr[i].split("/");
          //console.log("itemArr: ",itemArr)
          let currentImgUrl=''
          for(let i=0;i<itemArr.length;i++){
            currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
          }
          otherFilenameStr.push(currentImgUrl)
        }
        this.good.otherFilename=otherFilenameStr.toString()

        this.$refs[formName].validate((valid) => {
          if (valid) {
            //当选择的商品类别是农药的时候，要显示登记证号表单项，如果商品类型不是农药时，则不显示登记证号表单项
            if (this.$refs.categoryInput.getCheckedNodes()[0].parent.label === '农药') {
              this.isShowpdno = true
            } else {
              this.isShowpdno = false
              this.good.pdno = ''
            }

            if (this.$refs.categoryInput.getCheckedNodes()[0].parent.label === '兽药') {
              this.isApprovalNumber = true
            } else {
              this.isApprovalNumber = false
              this.good.approvalNumber = ''
            }

            if (this.type === 0 || this.type === 4 || this.type === 5 || this.type===6) {
              Loadings.show()
              this.$axios.request({
                url: process.env.VUE_APP_SERVER + '/psi/goods/save',
                method: 'POST',
                data: {
                  bigUnitId: this.good.bigUnitId,
                  categoryId: this.categoryId,
                  companyName: this.good.companyName,
                  goodsImg: this.good.goodsImg,
                  goodsName: this.good.goodsName,
                  inventory: this.good.inventory,
                  inventoryWarning: this.good.inventoryWarning,
                  middleCount: this.good.middleCount,
                  middleUnitId: this.good.middleUnitId,
                  packageLevel: this.good.packageLevel,
                  pdno: this.good.pdno,
                  approvalNumber: this.good.approvalNumber,
                  useRange:this.good.useRange,
                  remark: this.good.remark,
                  saleBigPrice: this.good.saleBigPrice,
                  saleSmallPrice: this.good.saleSmallPrice,
                  seedCode: this.good.seedCode,
                  smallCount: this.good.smallCount,
                  smallUnitId: this.good.smallUnitId,
                  supplierIds: this.supplierIds,
                  //文件
                  businessFilename:this.good.businessFilename,
                  productionFilename:this.good.productionFilename,
                  qualityFilename:this.good.qualityFilename,
                  qualifiedFilename:this.good.qualifiedFilename,
                  otherFilename:this.good.otherFilename
                  //qrCode: this.qrCode
                }
              })
                .then((res) => {
                  Loadings.hide()
                  if (res.status === 200) {
                    if (res.data.code === 200) {
                      this.$message({
                        type: 'success',
                        message: '新增商品成功！',
                        duration: 2000,
                        offset: 110
                      })
                      if (this.type === 0) {
                        setTimeout(() => {
                          //跳转到商品列表页面
                          this.$router.push('/admin/goodsManager')
                        }, 2000)
                      }
                      if (this.type === 4) {
                        setTimeout(() => {
                          let type = 5
                          this.good = res.data.data
                          this.good.added = true
                          this.good.goodsNumber = 1
                          this.good.buyPackageLevel = this.good.packageLevel
                          this.good.goodsPrice = 0.00
                          this.good.totalPrice = this.good.goodsPrice

                          //跳转到商品列表页面
                          this.$router.push({
                            name: '/stockOrders',
                            params: {
                              type,
                              item: this.good
                            }
                          })
                        }, 500)
                      }
                      if (this.type === 5) {
                        setTimeout(() => {
                          let type = 5
                          this.good = res.data.data
                          this.good.added = true
                          this.good.goodsNumber = 1
                          this.good.buyPackageLevel = this.good.packageLevel
                          if (this.good.packageLevel === 1) {
                            this.good.goodsPrice = this.good.saleSmallPrice
                          }
                          if (this.good.packageLevel === 2) {
                            this.good.goodsPrice = this.good.saleBigPrice * this.good.goodsNumber
                          }
                          if(this.saleNoGood && this.saleNoGood.barcode){
                            this.good.codeUrl=this.saleNoGood.barcode
                          }
                          this.good.packageUnit = this.good.packingSpecification
                          this.good.totalPrice = this.good.goodsPrice
                          // console.log("SessionStorage.get(SALE_CART)111: ",SessionStorage.get(SALE_CART))
                          // console.log("新good:",this.good)
                          //跳转到商品列表页面
                          this.$router.push({
                            name: '/saleOrders',
                            params: {
                              type,
                              item: this.good
                            }
                          })
                        }, 500)
                      }
                      if (this.type === 6) {
                        setTimeout(() => {
                          this.good = res.data.data

                          //跳转到商品列表页面
                          this.$router.push({
                            name: '/recipelManagerEdit',
                            params: {
                              good: this.good
                            }
                          })
                        }, 500)
                      }
                    } else {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                })
            } else if (this.type === 1) {
              Loadings.show()
              this.$axios.request({
                url: process.env.VUE_APP_SERVER + '/psi/goods/update',
                method: 'POST',
                data: {
                  goodsId: this.good.goodsId,
                  bigUnitId: this.good.bigUnitId,
                  categoryId: this.categoryId,
                  companyName: this.good.companyName,
                  goodsImg: this.good.goodsImg,
                  goodsName: this.good.goodsName,
                  inventory: this.good.inventory,
                  inventoryWarning: this.good.inventoryWarning,
                  middleCount: this.good.middleCount,
                  middleUnitId: this.good.middleUnitId,
                  packageLevel: this.good.packageLevel,
                  pdno: this.good.pdno,
                  approvalNumber: this.good.approvalNumber,
                  useRange:this.good.useRange,
                  remark: this.good.remark,
                  saleBigPrice: this.good.saleBigPrice,
                  saleSmallPrice: this.good.saleSmallPrice,
                  //seedCode: this.seedCode,
                  smallCount: this.good.smallCount,
                  smallUnitId: this.good.smallUnitId,
                  supplierIds: this.supplierIds,
                  //qrCode: this.qrCode
                  //文件
                  businessFilename:this.good.businessFilename,
                  productionFilename:this.good.productionFilename,
                  qualityFilename:this.good.qualityFilename,
                  qualifiedFilename:this.good.qualifiedFilename,
                  otherFilename:this.good.otherFilename
                }
              })
                .then((res) => {
                  Loadings.hide()
                  if (res.status === 200) {
                    if (res.data.code === 200) {
                      this.$message({
                        type: 'warning',
                        message: '编辑商品成功！',
                        duration: 2000,
                        offset: 110
                      })
                      setTimeout(() => {
                        //跳转到商品列表页面
                        this.$router.push('/admin/goodsManager')
                      }, 2000)
                    } else {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                })
            }
          } else {
            return false
          }
        })
      },
      //包装规格切换
      radioChange (e) {
        //console.log('----radio: ', e)
      },
      //3.根据id获取商品信息
      getGoodById () {
        let that=this
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/detail?goodsId=' + this.good.goodsId)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.good = res.data.data
                setTimeout(()=>{
                  if(that.smallUnits.length>0){
                    that.smallUnits.map((item,index)=>{
                      if(item.unitId==that.good.smallUnitId){
                        that.smallUnitIndex = index
                      }

                    })
                  }
                  if(that.middleUnits.length>0){
                    that.middleUnits.map((item,index)=>{
                      if(item.unitId==that.good.middleUnitId){
                        that.middleUnitIndex = index
                      }

                    })
                  }
                  if(that.bigUnits.length>0){
                    that.bigUnits.map((item,index)=>{
                      if(item.unitId==that.good.bigUnitId){
                        that.bigUnitIndex = index
                      }

                    })
                  }
                })


                this.good.businessFilename=""//经营许可证
                this.good.businessFilenameArr=[]
                this.good.productionFilename=""//生产许可证
                this.good.productionFilenameArr=[]
                this.good.qualityFilename=""//质量标准证
                this.good.qualityFilenameArr=[]
                this.good.qualifiedFilename=""//合格证
                this.good.qualifiedFilenameArr=[]
                this.good.otherFilename=""//其他图片
                this.good.otherFilenameArr=[]

                this.supplierNames = this.good.supplierName.split(',')
                this.categoryId = this.good.categoryId
                this.good.categoryId=[parseInt(res.data.data.categoryId.toString()[0]),res.data.data.categoryId]
                for(let i=0;i<this.good.certificateList.length;i++){
                  if(this.good.certificateList[i].certificateCategory===1){
                    this.good.businessFilename=this.good.certificateList[i].certificateImg
                    if(this.good.businessFilename.indexOf(",")!==-1){
                      let arr=this.good.businessFilename.split(",")
                      for(let j=0;j<arr.length;j++){
                        this.good.businessFilenameArr.push(this.base_url+'/goods'+arr[i])
                      }
                    }else{
                      this.good.businessFilenameArr.push(this.base_url+'/goods'+this.good.businessFilename)
                    }
                  }
                  if(this.good.certificateList[i].certificateCategory===2){
                    this.good.productionFilename=this.good.certificateList[i].certificateImg
                    if(this.good.productionFilename.indexOf(",")!==-1){
                      let arr=this.good.productionFilename.split(",")
                      for(let j=0;j<arr.length;j++){
                        this.good.productionFilenameArr.push(this.base_url+'/goods'+arr[i])
                      }
                    }else{
                      this.good.productionFilenameArr.push(this.base_url+'/goods'+this.good.productionFilename)
                    }
                  }
                  if(this.good.certificateList[i].certificateCategory===3){
                    this.good.qualityFilename=this.good.certificateList[i].certificateImg
                    if(this.good.qualityFilename.indexOf(",")!==-1){
                      let arr=this.good.qualityFilename.split(",")
                      for(let j=0;j<arr.length;j++){
                        this.good.qualityFilenameArr.push(this.base_url+'/goods'+arr[i])
                      }
                    }else{
                      this.good.qualityFilenameArr.push(this.base_url+'/goods'+this.good.qualityFilename)
                    }
                  }
                  if(this.good.certificateList[i].certificateCategory===4){
                    this.good.qualifiedFilename=this.good.certificateList[i].certificateImg
                    if(this.good.qualifiedFilename.indexOf(",")!==-1){
                      let arr=this.good.qualifiedFilename.split(",")
                      for(let j=0;j<arr.length;j++){
                        this.good.qualifiedFilenameArr.push(this.base_url+'/goods'+arr[i])
                      }
                    }else{
                      this.good.qualifiedFilenameArr.push(this.base_url+'/goods'+this.good.qualifiedFilename)
                    }
                  }
                  if(this.good.certificateList[i].certificateCategory===5){
                    this.good.otherFilename=this.good.certificateList[i].certificateImg
                    if(this.good.otherFilename.indexOf(",")!==-1){
                      let arr=this.good.otherFilename.split(",")
                      for(let j=0;j<arr.length;j++){
                        this.good.otherFilenameArr.push(this.base_url+'/goods'+arr[i])
                      }
                    }else{
                      this.good.otherFilenameArr.push(this.base_url+'/goods'+this.good.otherFilename)
                    }
                  }
                }
                if (this.good.categoryName.indexOf('农药') != -1) {
                  this.isShowpdno = true
                }
                if (this.good.categoryName.indexOf('兽药') != -1) {
                  this.isApprovalNumber = true
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //获取所有分类
      getCategorys () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/category/selectAll')
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                if ((this.$route && this.$route.params && this.$route.params.type === 6) || this.isPesticide===1) {
                  this.categorys = [];
                  let respData = res.data.data;
                  for (let i = 0; i < respData.length; i++) {
                    if (respData[i].categoryName === '农药') {
                      this.categorys.push(respData[i]);
                    }
                  }
                  for (let i = 0; i < this.categorys.length; i++) {
                    for (let j = 0; j < this.categorys[i].children.length; j++) {
                      if (this.categorys[i].children[j].children.length < 1) {
                        delete this.categorys[i].children[j].children
                      }
                    }
                  }
                }else if (this.isPesticide===2) {
                  this.categorys = [];
                  let respData = res.data.data;
                  for (let i = 0; i < respData.length; i++) {
                    // if (respData[i].categoryName !== '农药') {
                    //   this.categorys.push(respData[i]);
                    // }
                    this.categorys.push(respData[i]);
                  }
                  for (let i = 0; i < this.categorys.length; i++) {
                    for (let j = 0; j < this.categorys[i].children.length; j++) {
                      if (this.categorys[i].children[j].children.length < 1) {
                        delete this.categorys[i].children[j].children
                      }
                    }
                  }
                } else {
                  this.categorys = res.data.data
                  if (this.categorys && this.categorys.length > 0) {
                    for (let i = 0; i < this.categorys.length; i++) {
                      for (let j = 0; j < this.categorys[i].children.length; j++) {
                        if (this.categorys[i].children[j].children.length < 1) {
                          delete this.categorys[i].children[j].children
                        }
                      }
                    }
                    this.getCurrentWidth()
                  }
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //获取包装单位
      getSmallUnits () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/unit/queryUnitByLevel?unitLevel=1')
        .then((res) => {
          this.smallUnits = res.data.data;
          this.smallUnitIndex = 0;
          this.good.smallUnitId = this.smallUnits[this.smallUnitIndex].unitId;
        })
      },
      getMiddleUnits () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/unit/queryUnitByLevel?unitLevel=2')
        .then((res) => {
          this.middleUnits = res.data.data;
          this.middleUnitIndex = 0;
          this.good.middleUnitId = this.middleUnits[this.middleUnitIndex].unitId;
        })
      },
      getBigUnits () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/unit/queryUnitByLevel?unitLevel=3')
        .then((res) => {
          Loadings.hide()
          this.bigUnits = res.data.data;
          this.bigUnitIndex = 0;
          this.good.bigUnitId = this.bigUnits[this.bigUnitIndex].unitId;
        })
      },
      //小包装单位数量输入变换回调
      smallNumberChange (value) {
        /*
        let reg = /^\d+\.\d+$/;
        if (!reg.test(value)) {
          this.good.smallCount = String(this.good.smallCount).replace(/[^\d.]/g,"");
          // this.$nextTick(() => {
          //   this.good.smallCount = this.good.smallCount.replace(/[^\d.]/g,"");
          // });
        }

        if (value == '0') {
          // this.$nextTick(() => {
          //   this.good.smallCount = 1;
          // });
          this.good.smallCount = 1;
        }
        */
      },
      //打包装中包含的中包装数量改变回调
      middleNumberChange (value) {
        this.calcBigUnitPrice()
      },
      //小包装单价失去焦点
      saleSmallPriceBlur () {
        this.calcBigUnitPrice()
      },
      //计算大包装销售价
      calcBigUnitPrice () {
        if (this.good.middleCount && this.good.saleSmallPrice) {
          this.good.saleBigPrice = (parseFloat(this.good.middleCount) * parseFloat(this.good.saleSmallPrice)).toFixed(2)
        }
      },
      //现有库存改变回调
      inventoryChange (value) {

      },
      inventoryWarningChange (value) {
        //console.log('库存预警变换：', value)
      },
      getCurrentWidth (current) {
        $(window).on('resize', function () {
          let currentWidth = $(window).width()
          let leftBox = $('.box-card')
          let rightBox = $('.box-card')[1]
          let inputLine = $('.inputLine')
          let inputCoreDesc = $('.inputCoreDesc')[0]
          if (currentWidth > 1800) {
            for (let i = 0; i < leftBox.length; i++) {
              leftBox[i].style.width = '700px'
            }
          }
          if (currentWidth < 1800) {
            for (let i = 0; i < leftBox.length; i++) {
              leftBox[i].style.width = '600px'
            }
          }
          if (currentWidth < 1543) {
            for (let i = 0; i < leftBox.length; i++) {
              leftBox[i].style.marginLeft = '0px'
            }
            for (let i = 0; i < inputLine.length; i++) {
              inputLine[i].style.width = '400px'
            }
            if(inputCoreDesc){
              inputCoreDesc.style.maxWidth = '400px'
            }
          }
          if (currentWidth < 1440) {
            for (let i = 0; i < leftBox.length; i++) {
              leftBox[i].style.width = '530px'
            }
            for (let i = 0; i < inputLine.length; i++) {
              inputLine[i].style.width = '300px'
            }
            if(inputCoreDesc){
              inputCoreDesc.style.maxWidth = '300px'
            }
          }
        })
      },
      changeWidth (currentWidth) {
        let leftBox = $('.box-card')
        let rightBox = $('.box-card')[1]
        let inputLine = $('.inputLine')
        let inputCoreDesc = $('.inputCoreDesc')[0]
        if (currentWidth > 1800) {
          for (let i = 0; i < leftBox.length; i++) {
            leftBox[i].style.width = '700px'
          }
        }
        if (currentWidth < 1800) {
          for (let i = 0; i < leftBox.length; i++) {
            leftBox[i].style.width = '600px'
          }
          for (let i = 0; i < inputLine.length; i++) {
            inputLine[i].style.width = '400px'
          }
          inputCoreDesc.style.maxWidth = '400px'
        }
        if (currentWidth < 1543) {
          for (let i = 0; i < leftBox.length; i++) {
            leftBox[i].style.marginLeft = '0px'
          }
          for (let i = 0; i < inputLine.length; i++) {
            inputLine[i].style.width = '300px'
          }
          inputCoreDesc.style.maxWidth = '300px'
        }
        if (currentWidth < 1440) {
          for (let i = 0; i < leftBox.length; i++) {
            leftBox[i].style.width = '530px'
          }
          for (let i = 0; i < inputLine.length; i++) {
            inputLine[i].style.width = '300px'
          }
          inputCoreDesc.style.maxWidth = '300px'
        }
      },
      //选中分类搜索
      categoryChange (value) {
        // let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0];
        // this.categoryId=checkedNodes.value;
        //console.log("value: ",value)
        this.categoryId = value[1]
        //当选择的商品类别是农药的时候，要显示登记证号表单项，如果商品类型不是农药时，则不显示登记证号表单项
        if (this.$refs.categoryInput.getCheckedNodes()[0].parent.label === '农药') {
          this.isShowpdno = true
        } else {
          this.isShowpdno = false
          //this.good.pdno = '';
        }

        if (this.$refs.categoryInput.getCheckedNodes()[0].parent.label === '兽药') {
          this.isApprovalNumber = true
        } else {
          this.isApprovalNumber = false
          //this.good.pdno = '';
        }
      },
      //选择供应商
      handleClose (tag) {
        this.supplierIds.splice(this.supplierIds.indexOf(tag), 1)
        this.supplierNames.splice(this.supplierNames.indexOf(tag), 1)
      },
      showChooseDialog () {
        //this.inputVisible = true;
        if(Tool.isNotEmpty(this.supplierIds)){
          if (this.supplierIds.length < 1) {
            this.supplierIds = this.good.supplierIds
          }
        }


        //console.log('当前供应商ID：', this.supplierIds, '当前good：', this.good, 'supplierNames: ', this.supplierNames)
        this.$nextTick(() => {
          let supplierIds = this.supplierIds
          let supplierNames = this.supplierNames
          let type = this.type
          this.$refs.dialogSupplierChoose.getSupplierIds(supplierIds, type, supplierNames)

        })
      },
      showChooseMore () {
        if (this.type === 1) {
          this.supplierIds = this.good.supplierIds
        }
        this.$nextTick(() => {
          let supplierIds = this.supplierIds
          let supplierNames = this.supplierNames
          let type = this.type
          this.$refs.supplierChooseMore.getSupplierIds(supplierIds, type, supplierNames)

        })
      },
      getSuppliers (supplierIds, supplierNames) {
        this.supplierNames = supplierNames
        this.supplierIds = supplierIds
        setTimeout(() => {
          let canClose = document.getElementsByClassName('keyTags')

          for (let i = 0; i < canClose.length; i++) {
            if(canClose[i].childNodes[1]){
              canClose[i].childNodes[1].style.display = 'none'
            }
          }
        }, 50)
      },
      getSupplierMore (supplierIds, supplierNames) {
        this.supplierNames = $.extend([], this.supplierNames)
        this.supplierIds = $.extend([], this.supplierIds)
        this.supplierNames = supplierNames
        this.supplierIds = supplierIds
        this.good.supplierIds = supplierIds
        setTimeout(() => {
          let canClose = document.getElementsByClassName('keyTags')
          for (let i = 0; i < canClose.length; i++) {
            canClose[i].childNodes[1].style.display = 'none'
          }
        }, 50)
      },
      //图片上传
      afterUpload (resp) {
        if (resp.success) {
          //this.module.picList=resp.responseData.path;
          let picPath = resp.data
          if (!Tool.isEmpty(picPath)) {
            this.files = picPath
            this.good.goodsImg = picPath
            //console.log("本地文件路径：",this.good.goodsImg);
          }
        }
      },
      afterUploadLicense(resp){
        let picsPath=resp.data
        if(!Tool.isEmpty(picsPath)){
          //console.log("picsPath:",picsPath)
          let picsArr=picsPath.split(",")
          for(let i=0;i< picsArr.length;i++){
            this.good.businessFilenameArr.push(this.base_url+'/goods'+picsArr[i])
          }
          this.$forceUpdate()
          //console.log("当前营业执照数组：",this.good.businessFilenameArr)
        }
      },
      showMax(){
        setTimeout(function () {
          let prev=document.getElementsByClassName('el-image-viewer__prev');
          //console.log("rev: ",prev)
          for(let i=0;i<prev.length;i++){
            //Tool.addClass(prev[i],"leftImp")
            prev[i].style.marginLeft="200px"
            //console.log("prev-i: ",prev[i])
          }
          let next=document.getElementsByClassName('el-image-viewer__next');
          for(let i=0;i<next.length;i++){
            next[i].style.float="right";
          }
          let close=document.getElementsByClassName('el-image-viewer__close');
          for(let i=0;i<close.length;i++){
            close[i].style.top="140px !important"
          }
        },100)
      },
      delLicenseImg(item){
        //console.log("删除的图片：",item)
        let _this=this
        let itemArr=item.split("/");
        //console.log("itemArr: ",itemArr)
        let currentImgUrl=''
        for(let i=0;i<itemArr.length;i++){
          currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
        }
        //console.log("去除http请求链接的imgUrl：",currentImgUrl)
        let index=item.lastIndexOf("/")
        let currentImg=item.substring(index+1,item.length)
        //console.log("currentImg: ",currentImg)
        this.$toast.showConfirm("当前图片", function () {
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/goods/delete/certificate?filename='+currentImgUrl)
            .then((res) => {
              for (let i=0;i<_this.good.businessFilenameArr.length;i++){
                if(item===_this.good.businessFilenameArr[i]){
                  _this.good.businessFilenameArr.splice(i,1)
                }
              }
              _this.$forceUpdate()
              //console.log("删除响应：",res)
            })
        })

      },
      afterUploadProduct(resp){
        let picsPath=resp.data
        if(!Tool.isEmpty(picsPath)){
          //console.log("picsPath:",picsPath)
          let picsArr=picsPath.split(",")
          for(let i=0;i< picsArr.length;i++){
            this.good.productionFilenameArr.push(this.base_url+'/goods'+picsArr[i])
          }
          this.$forceUpdate()
          //console.log("当前生产许可证数组：",this.good.productionFilenameArr)
        }
      },
      delProductImg(item){
        //console.log("删除的图片：",item)
        let _this=this
        let itemArr=item.split("/");
        //console.log("itemArr: ",itemArr)
        let currentImgUrl=''
        for(let i=0;i<itemArr.length;i++){
          currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
        }
        //console.log("去除http请求链接的imgUrl：",currentImgUrl)
        let index=item.lastIndexOf("/")
        let currentImg=item.substring(index+1,item.length)
        //console.log("currentImg: ",currentImg)
        this.$toast.showConfirm("当前图片", function () {
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/goods/delete/certificate?filename='+currentImgUrl)
            .then((res) => {
              for (let i=0;i<_this.good.productionFilenameArr.length;i++){
                if(item===_this.good.productionFilenameArr[i]){
                  _this.good.productionFilenameArr.splice(i,1)
                }
              }
              _this.$forceUpdate()
              //console.log("删除生产许可证响应：",res)
            })
        })
      },
      afterUploadQuality(resp){
        let picsPath=resp.data
        if(!Tool.isEmpty(picsPath)){
          //console.log("picsPath:",picsPath)
          let picsArr=picsPath.split(",")
          for(let i=0;i< picsArr.length;i++){
            this.good.qualityFilenameArr.push(this.base_url+'/goods'+picsArr[i])
          }
          this.$forceUpdate()
          //console.log("当前质量标准证数组：",this.good.qualityFilenameArr)
        }
      },
      delQualityImg(item){
        //console.log("删除的图片：",item)
        let _this=this
        let itemArr=item.split("/");
        //console.log("itemArr: ",itemArr)
        let currentImgUrl=''
        for(let i=0;i<itemArr.length;i++){
          currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
        }
        //console.log("去除http请求链接的imgUrl：",currentImgUrl)
        let index=item.lastIndexOf("/")
        let currentImg=item.substring(index+1,item.length)
        //console.log("currentImg: ",currentImg)
        this.$toast.showConfirm("当前图片", function () {
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/goods/delete/certificate?filename='+currentImgUrl)
            .then((res) => {
              for (let i=0;i<_this.good.qualityFilenameArr.length;i++){
                if(item===_this.good.qualityFilenameArr[i]){
                  _this.good.qualityFilenameArr.splice(i,1)
                }
              }
              _this.$forceUpdate()
              //console.log("删除质量标准证响应：",res)
            })
        })
      },
      afterUploadQualified(resp){
        let picsPath=resp.data
        if(!Tool.isEmpty(picsPath)){
          //console.log("picsPath:",picsPath)
          let picsArr=picsPath.split(",")
          for(let i=0;i< picsArr.length;i++){
            this.good.qualifiedFilenameArr.push(this.base_url+'/goods'+picsArr[i])
          }
          this.$forceUpdate()
          //console.log("当前合格证数组：",this.good.qualifiedFilenameArr)
        }
      },
      delQualifiedImg(item){
        //console.log("删除的图片：",item)
        let _this=this
        let itemArr=item.split("/");
        //console.log("itemArr: ",itemArr)
        let currentImgUrl=''
        for(let i=0;i<itemArr.length;i++){
          currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
        }
        //console.log("去除http请求链接的imgUrl：",currentImgUrl)
        let index=item.lastIndexOf("/")
        let currentImg=item.substring(index+1,item.length)
        //console.log("currentImg: ",currentImg)
        this.$toast.showConfirm("当前图片", function () {
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/goods/delete/certificate?filename='+currentImgUrl)
            .then((res) => {
              for (let i=0;i<_this.good.qualifiedFilenameArr.length;i++){
                if(item===_this.good.qualifiedFilenameArr[i]){
                  _this.good.qualifiedFilenameArr.splice(i,1)
                }
              }
              _this.$forceUpdate()
              //console.log("删除合格证响应：",res)
            })
        })
      },
      afterUploadOther(resp){
        let picsPath=resp.data
        if(!Tool.isEmpty(picsPath)){
          //console.log("picsPath:",picsPath)
          let picsArr=picsPath.split(",")
          for(let i=0;i< picsArr.length;i++){
            this.good.otherFilenameArr.push(this.base_url+'/goods'+picsArr[i])
          }
          this.$forceUpdate()
          //console.log("当前其他数组：",this.good.otherFilenameArr)
        }
      },
      delOtherImg(item){
        //console.log("删除的图片：",item)
        let _this=this
        let itemArr=item.split("/");
        //console.log("itemArr: ",itemArr)
        let currentImgUrl=''
        for(let i=0;i<itemArr.length;i++){
          currentImgUrl="/"+itemArr[4]+"/"+itemArr[5]+"/"+itemArr[6]
        }
        //console.log("去除http请求链接的imgUrl：",currentImgUrl)
        let index=item.lastIndexOf("/")
        let currentImg=item.substring(index+1,item.length)
        //console.log("currentImg: ",currentImg)
        this.$toast.showConfirm("当前图片", function () {
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/goods/delete/certificate?filename='+currentImgUrl)
            .then((res) => {
              for (let i=0;i<_this.good.otherFilenameArr.length;i++){
                if(item===_this.good.otherFilenameArr[i]){
                  _this.good.otherFilenameArr.splice(i,1)
                }
              }
              _this.$forceUpdate()
              //console.log("删除其他响应：",res)
            })
        })
      },
      pdnoBlur(good){
        let _this=this
        if(good.categoryId.toString()[0]==='2' && good.pdno.length>5){
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/queryUseRange?pdno='+good.pdno)
            .then((res) => {
              //console.log("响应：",res)
              if(res.data.data.length>0){
                this.good.useRange=res.data.data
              }

              _this.$forceUpdate()
            })
        }
      },
      approvalNumberBlur(good){
        let _this=this
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-form-item__label:before {
    margin-left: 4px;
    float: right;
  }

  .mainPages {
    /*min-height: 850px;*/
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 150px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 25px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  .goodForm {
    margin-top: 20px;
  }

  .labelTitle {
    margin-bottom: 20px !important;
  }

  .inputLine {
    width: 400px;
    float: left;
  }

  .priceInput {
    float: left;
    margin-right: 10px;
  }

  .rowForm {
    margin-bottom: 20px;
  }

  .leftForm {
    background: #fafafa;
    padding-top: 20px;
    /*padding-bottom: 20px;*/
    border-radius: 20px;
    border: dashed 1px #faecd8;
    min-height: 646px;
  }

  .rightForm {
    background: #fafafa;
    padding-top: 20px;
    border-radius: 20px;
    border: dashed 1px #faecd8;
    min-height: 646px;
  }

  /*供应商*/
  .keyTags {
    float: left;
    margin-right: 8px;
    margin-bottom: 10px;
    line-height: 40px;
    height: 40px;
  }

  .keyTagsInput {
    float: left;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  /*包装*/
  .packageUnit {
  }

  .unitLine {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 5px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  .unitName {
    float: left;
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;
  }

  .priceUnit {
    float: left;
    display: inline-block;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #DCDFE6;
  }

  .inventoryLine {
    float: left;
    max-width: 180px;
    margin-right: 10px;
  }

  /*图片上传*/
  .picContent {
    margin-bottom: 15px;
  }

  .pic_prompt {
    float: left;
    color: #999999;
    margin-top: -10px;
  }
  .imgBox{
    position: relative;
  }
  .uploadImage {
    float: left;
    width: 90px;
    height: 60px;
    border: 1px dashed #DCDFE6;
    margin-top: -80px;
    margin-left: 90px;
  }
  /*停用启用*/
  .checkGroup {
    float: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
    width: 180px;
    margin-right: 130px;
  }

  /deep/ .el-radio-button__inner, .el-radio-button:first-child {
    border: 0 !important;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 550px;
    float: left;
  }

  /*分类*/
  .categoryInput {
    float: left;
    margin-right: 10px;
  }

  .paddingBottom{
    padding-bottom: 80px;
  }

  .bottomBtn {
    display: block;

    /*position: absolute;
    bottom: 80px;
    left: 45%;
    margin: 0 auto;*/
  }

  .toastMessage {
    margin-top: -10px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }

  .resetBtn {
    width: 150px;
  }

  /*条形码*/
  .scanCode {
    color: #fff;
    border: 0px solid #fff;
    position: absolute;
    outline: none;
    width: 300px;
  }

  .barCodeItem {
    margin-top: 5px;
    display: block;
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 8px 20px;
    margin-bottom: 10px;
    max-width: 310px;
    float: left;
    margin-right: 30px;
  }

  .barCodeItem .itemTitle {
    line-height: 30px;
    float: left;

  }

  .barCodeItem .itemTitle .titleLeft {
    min-width: 190px;
    text-align: left;
    font-weight: 700;
    margin-left: 10px;
  }

  .barCodeItem .itemTitle .titleRight {
    float: right;
  }

  .barCodeItem .itemTitle .titleRight .el-button {
    padding: 5px 15px;
  }

  .barCodeItem .itemImg {
    float: left;
    display: block;
  }
  .imageBox{
    margin-top: -50px;
    justify-content: left !important;
    text-align: left !important;
    width: 85%;
    margin-left: 110px;
    position: relative;
  }
  .imageItem{
    position: relative;
    display: inline-block;
    height: 50px;
    margin-bottom: 20px;
  }
  .uploadImages {
    width: 60px;
    height: 60px;
    border: 1px dashed #DCDFE6;
    margin-top: -70px;
    margin-right: 20px;
    text-align: left;
    padding: 2px;
    overflow: unset !important;
    display: inline-block;
  }
  .delImgBox{
    position: relative;
    top: -70px;
    right: -45px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: #e52a33;
    cursor: pointer;
  }
  .delImgBox .delText{
    color: #ffffff;
    width: 25px;
    height: 24px;
    position: absolute;
    left: 5px;
    top: 4px;
    font-size: 16px;

  }
  .leftImp{
    left: 240px !important;
  }
</style>
