import { render, staticRenderFns } from "./RoleManagerEdit.vue?vue&type=template&id=63dce9f0&scoped=true&"
import script from "./RoleManagerEdit.vue?vue&type=script&lang=js&"
export * from "./RoleManagerEdit.vue?vue&type=script&lang=js&"
import style0 from "./RoleManagerEdit.vue?vue&type=style&index=0&id=63dce9f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63dce9f0",
  null
  
)

export default component.exports