<template>
  <div class="mainBody">
    <el-row :gutter="10" class="rowForm">
      <el-col :span="colSpanLeft">
        <div class="leftBody" >
          <div class="topNav" @click="handleClick">
            <el-tag class="keyTags" v-if="goodsCart.clientName===undefined" type="warning">请选择客户</el-tag>
            <el-tag class="keyTags" id="clientIds" v-show="goodsCart.clientName.length>0"
                    :key="index"
                    v-for="(tag,index) in goodsCart.clientName"
                    :disable-transitions="false"
                    @close="handleClose(tag)"
            >
              <template >
                <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(tag)" :disabled="!remarkFlag">
                  <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                    {{tag}}
                  </div>
                  <span class="showRemarkLine">{{tag}}</span>
                </el-tooltip>
              </template>
            </el-tag>
            <el-button class="keyTagsInput" icon="el-icon-circle-plus-outline" type="primary" @click="showChooseDialog">
              选择客户
            </el-button>
            <div class="tipTag">
              <el-button v-if="showClearBtn" class="clearCart" icon="el-icon-delete" type="danger"
                         @click="showChooseDialog">清空购物车
              </el-button>
            </div>
          </div>
          <div class="toastMessage" @click="handleClick">
            <el-alert title="提示：点击这里，使用扫码枪添加商品" type="success" :closable="false" center show-icon>
            </el-alert>
            <input type="text" id="scanCode" class="scanCode" v-model="qrcode" @input="qrCodeInput"/>
          </div>
          <div class="mainForm" @click.self="handleClick">
            <el-table
              ref="multipleTable"
              tooltip-effect="dark"
              :data="goodsCart.details"
              height="440px"
              border
              highlight-current-row
              :empty-text="isHLJ && !isDN && hljMode===1?'未选择任何商品，请扫码添加商品':'未选择任何商品，请在右侧栏选择添加商品'"
              class="previous-row"
              :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
            >
              <el-table-column type="index" label="序号" class="indexCenter" :width="49"></el-table-column>
              <el-table-column property="goodsName" label="已选商品" :width="isHLJ && !isDN && hljMode===1?250: 120"
                               :show-overflow-tooltip="true"></el-table-column>
              <el-table-column property="packageUnit" label="包装规格" :width="isHLJ && !isDN && hljMode===1?200:160"
                               :show-overflow-tooltip="true"></el-table-column>
              <el-table-column property="lastPackageLevel" label="单位" :width="isHLJ && !isDN && hljMode===1?90:49">
                <template slot-scope="scope">
                  {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                </template>
              </el-table-column>
              <el-table-column property="goodsPrice" label="销售单价" :width="isHLJ && !isDN && hljMode===1?'':105" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag type="danger" class="showCenter">
                    ￥ {{scope.row.goodsPrice}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="goodsNumber" label="销售数量" :width="isHLJ && !isDN && hljMode===1?'':105" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag :type="scope.row.buyPackageLevel===1?'primary':'danger'" class="showCenter">
                    {{scope.row.goodsNumber}}
                    {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="packageLevel" label="小计" :width="isHLJ && !isDN && hljMode===1?'':135" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag type="danger" class="showCenter">
                    ￥ {{scope.row.totalPrice}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                class="optionList"
                fixed="right"
                label="操作"
                width="145">
                <!--使用slot-scope传递当前行参数-->
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="editCart(scope.row)">修改
                  </el-button>
                  <el-button
                    size="mini"
                    type="warning"
                    @click="delCart(scope.row)">删除
                  </el-button>
                  <!--<el-button
                    size="mini"
                    type="warning"
                    @click="del">排序
                  </el-button>-->
                </template>

              </el-table-column>
            </el-table>
          </div>
          <div class="formBottom" >
            <el-row>
              <div class="pay">
                <div class="payLabel">应退金额：</div>
                <el-input-number class="priceInput" v-model="goodsCart.accountsPayable" :precision="2" :step="1"
                                 disabled
                                 :max="99999999999.99" placeholder="请输入应收金额"></el-input-number>
              </div>
            </el-row>
            <el-row>
              <div class="pay">
                <div class="payLabel">实退金额：</div>
                <el-input-number class="priceInput" v-model="goodsCart.paymentAmount" :precision="2" :step="1" :min="0"
                                 :max="returnPaymentAmountMax" @blur="paymentAmountChange(goodsCart.paymentAmount)" placeholder="请输入整箱销售价"></el-input-number>
              </div>
            </el-row>
            <el-row>
              <div class="payDebt">
                <div class="payLabel">欠款金额：</div>
                <div class="debtMoneyText" :class="goodsCart.arrears>0?'debtMoneyWarning':''">{{goodsCart.arrears}} ￥</div>
                <div class="debtMoneyDesc">注：实退金额 = 应退金额 - 欠款金额</div>
              </div>
            </el-row>
            <el-row>
              <div class="pay">
                <div class="remarkLabel">备注：</div>
                <el-input id="stockDescription" class="inputCoreDesc" :rows="2"
                          type="textarea" maxlength="500" show-word-limit v-model="goodsCart.remark"
                          placeholder="请输入备注信息，最多500个字符"></el-input>
              </div>
            </el-row>
            <div class="bottomBtn">
              <el-button class="storeInfoBtn" type="danger" icon="el-icon-document-copy" @click="saveCart">去结算
              </el-button>
              <el-badge :value="goodsCart.num" class="item">
                <el-button :disabled="goodsCart.num>0?false:true" class="resetBtn" type="danger" plain
                           icon="el-icon-delete" @click="clearCart()">清空购物车
                </el-button>
              </el-badge>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="colSpanRight">
        <div class="rightBody">
          <el-row>
            <div class="topNav">
              <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                           placeholder="选择商品分类"
                           v-model="category"
                           :options="categorys"
                           :props="{value:'categoryId',label:'categoryName',children:'children'}"
                           separator=" / "
                           @change="categoryChange">
              </el-cascader>
              <el-input class="searchInput"
                        placeholder="请输入商品名称"
                        v-model="searchInput"
                        :blur="searchInputChange(searchInput)"
                        :clearable="true">
              </el-input>
              <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
            </div>
            <div class="navRight">
              <!-- <el-button class="searchBtn" type="danger" @click="toNewGood">新增商品</el-button>-->
              <el-button class="refreshBtn" icon="el-icon-refresh" circle></el-button>
            </div>
          </el-row>

          <div class="row mainCard" @click.self="handleClick">
            <el-col :span="4" v-for="(good, index) in goods" :key="index">
              <el-card class="goodCart" :class="good.added?'addGood':''">
                <!--<el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>-->
                <div class="imgBox">
                  <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                  <div class="innerImg" v-if="good.isGreen==1">
                    <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                  </div>

                </div>

                <div v-if="good.added" class="checkedbox">已选</div>
                <div class="goodDesc">
                  <div class="desc">
                    <div class="descTitle">规格</div>
                    <div class="descContent">{{ good.packageUnit }}</div>
                  </div>
                  <div class="descTag">
                    <div class="descTitle">售价</div>
                    <div class="descContentTag">
                      <el-tag type="warning" class="inventoryTag">
                        ￥&nbsp;{{good.goodsPrice}}
                      </el-tag>
                    </div>

                  </div>
                  <div class="descTag">
                    <div class="descTitle">库存</div>
                    <div class="descContentTag">
                      <el-tag :type="good.inventory <= 5 ? 'danger':'primary'" class="inventoryTag"
                              id="status">{{good.inventory}}&nbsp;{{good.middleUnitName}}
                      </el-tag>
                    </div>
                  </div>
                  <div class="desc">
                    <div class="descTitle">商品名</div>
                    <div class="descContent">{{ good.goodsName }}</div>
                  </div>

                  <div class="bottom clearfix">
                    <el-button v-if="!good.added" class="addCart" type="danger"
                               icon="el-icon-shopping-bag-2"
                               @click="addGood(good)">
                      添加购物车
                    </el-button>
                    <el-button v-if="good.added" disabled class="addCart" type="danger" icon="el-icon-s-goods">
                      商品已添加
                    </el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
          </div>

          <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch" @rePage="rePage"></page-helper>
        </div>
      </el-col>
    </el-row>

    <div class="dialogs">
      <dialog-client-choose ref="dialogClientChoose" @getClients="getClients"></dialog-client-choose>
      <dialog-sale-order ref="dialogSaleOrder" :isHLJ="isHLJ" :isDN="isDN" :hljMode="hljMode" @addSaleCart="addSaleCart" @editSaleCart="editSaleCart"
                         @cartChange="cartChange"></dialog-sale-order>
      <dialog-sale-order-sure ref="dialogSaleOrderSure" @saveFinish="saveFinish"></dialog-sale-order-sure>
    </div>
  </div>
</template>

<script>
  import DialogClientChoose from '../../../components/DialogClientChoose'
  import PageHelper from '../../../components/PageHelper'
  import DialogSaleOrder from '../../../components/DialogSaleOrder'
  import DialogSaleOrderSure from '../../../components/DialogSaleOrderSure'

  export default {
    name: 'SaleOrder',
    components: {
      DialogClientChoose,
      DialogSaleOrder,
      DialogSaleOrderSure,
      PageHelper
    },
    data () {
      return {
        message: '销货退货开单页面',
        //type=1,销货，=2新增商品
        type: 0,
        //客户
        clientId: '',
        clientIds:'',
        inputVisible: false,
        currentIds:'',
        //弹框
        centerDialogVisible: false,
        sureDialogVisible: false,
        //购物车
        goodCart: {},
        goodsCart: {
          accountsPayable: 0,
          paymentAmount: 0,
          num: 0,
          details: [],
          clientId: [],
          clientName: [],
        },
        good: {},
        goods: [],
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        showClearBtn: false,
        base_url: '',
        //分类
        //级联选择
        categorys: [
          {
            'categoryId': 0,
            'categoryName': '全部',
            'categotyOrder': 0,
            'children': [
              {
                'categoryId': 0,
                'categoryName': '全部分类',
                'categotyOrder': 0,
                'children': [],
                'parentCategoryId': 0
              }
            ],
            'parentCategoryId': 0
          }
        ],
        category: {},
        categoryId: '',
        //搜索
        searchInput: '',
        //扫码传参
        qrcode: '',
        timer: -1,
        barCode: '',
        //分页切换
        currentPage: 1,
        //库存不足
        stockFlag: false,
        remarkFlag: false,
        returnPaymentAmountMax:99999999999.99,
        isHLJ:LocalStorage.get(IS_HLJ),
        isDN:LocalStorage.get(IS_DN),
        hljMode:1,//1农药2其他商品
        colSpanLeft:9,
        colSpanRight:15,
        codeUrlList:[],//扫码后的数组
        isPesticide:false,
      }
    },
    mounted () {
      if(Tool.isNotEmpty(SessionStorage.get(HLJ_RETURN_MODE))){
        this.hljMode=SessionStorage.get(HLJ_RETURN_MODE)
      }
      if(!Tool.isEmpty(SessionStorage.get(CODE_URL_LIST))){
        this.codeUrlList=SessionStorage.get(CODE_URL_LIST)
      }
      if(this.isHLJ && !this.isDN && this.hljMode===1){
        this.colSpanLeft=24
        this.colSpanRight=0
      }
      if(this.isHLJ && this.hljMode===2){
        this.colSpanLeft=9
        this.colSpanRight=15
      }
      document.getElementById('scanCode').focus()
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        if(!this.isHLJ || (this.isHLJ && (this.hljMode!==1 || this.isDN))){
          this.getSearch(1)
        }
      }
      //应付金额
      this.goodsCart.accountsPayable = 0
      //实付金额
      this.goodsCart.paymentAmount = 0
      if (Tool.isNotEmpty(SessionStorage.get(SALE_RETURN_CART))) {
        this.goodsCart = SessionStorage.get(SALE_RETURN_CART)
        this.clientIds = this.goodsCart.clientId
        this.clientNames = this.goodsCart.clientName
      }

      if (Tool.isNotEmpty(this.$route.params)) {
        this.type = this.$route.params.type
        if (this.type === 4) {
          this.navChange()
          this.goodsCart = this.$route.params.item
          this.goodsCart = $.extend({}, this.goodsCart)
          this.clientIds = this.goodsCart.clientId
          this.clientNames = this.goodsCart.clientName
          this.goodsCart.num = this.goodsCart.details.length
        }
      }
      // console.log("this.clientIds: ", this.clientIds);
      if(this.clientIds.length>0){
        this.currentIds=this.clientIds
      }
      if (this.goodsCart.clientName === undefined || this.goodsCart.clientName.length < 1) {
        this.showChooseDialog()
      } else {
        if(!this.isHLJ || (this.isHLJ && (this.hljMode!==1 || this.isDN))){
          this.getSearch(1)
        }
      }

      let currentWidth = $(window).width()
      this.changeWidth(currentWidth)
      //this.getSearch(1);
      this.getCategorys()
      this.base_url=this.BASE_URL;
      //获取该客户的欠款金额
      this.getCustomerArrears();
      //this.base_url = 'https://jxccs.weituodata.com:81'
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
    },
    methods: {

      handleClick (event) {
        document.getElementById('scanCode').focus()
      },
      qrCodeInput (e) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.qrcode.indexOf('&') !== -1) {
            this.qrcode = this.qrcode.replace(new RegExp('&', 'g'), '%26')
          }
          //console.log("this.qrcode: ",this.qrcode)
          Loadings.show()
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/decodeQrCode?qrcode=' + this.qrcode)
            .then((response) => {
              Loadings.hide()
              let resp = response.data.data
              let barCodeGood = resp
              barCodeGood.barcode = this.qrcode
              this.getCodeGood(barCodeGood)
            })

        }, 50)
      },
      paymentAmountChange(paymentAmount){
        if(Tool.isEmpty(paymentAmount)){
          this.goodsCart.paymentAmount=0
        }
      },
      getCodeGood (barCodeGood) {
        //console.log("barCodeGood--> ",barCodeGood)
        let _this = this
        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        let reqUrl=process.env.VUE_APP_SERVER + '/psi/saleorder/selectByScan?qrCode='
          + barCodeGood.barcode
        if(this.isHLJ && !this.isDN){
          if(this.hljMode===1){
            reqUrl=process.env.VUE_APP_SERVER + '/psi/salereturnorder/queryByScan?qrCode='
              + barCodeGood.barcode + '&isPesticide=' + this.hljMode+'&clientId='+this.clientIds
          }else{
            reqUrl=process.env.VUE_APP_SERVER + '/psi/salereturnorder/queryByScanOther?qrCode='
              + barCodeGood.barcode + '&isPesticide=' + this.hljMode+'&clientId='+this.clientIds
          }

        }
        Loadings.show()
        this.$axios.get(reqUrl)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            let _this = this
            //console.log("respL ",resp)
            if (resp.code === 200) {
              if (resp.data.records.length < 1) {
                this.$toast.error('该商品非当前客户商品', 'center')
              } else if (resp.data.records.length > 0) {
                if(!Tool.isEmpty(barCodeGood.barcode)){
                  resp.data.records[0].codeUrl=barCodeGood.barcode
                }
                if(this.isHLJ && !this.isDN && this.hljMode===1){
                  let codeUrls=[]
                  for(let i=0;i<this.codeUrlList.length;i++){
                    codeUrls.push(this.codeUrlList[i].codeUrl)
                  }
                  let goodsIds=[]
                  for (let i = 0; i < this.goodsCart.details.length; i++) {
                    goodsIds.push(this.goodsCart.details[i].goodsId)
                  }
                  let goodItem={}
                  if(this.goodsCart.details.length>0){
                    for (let i = 0; i < this.goodsCart.details.length; i++) {
                      if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                        goodItem=resp.data.records[0]
                      }
                    }
                    if(Tool.isEmpty(goodItem)){
                      goodItem=resp.data.records[0]
                    }
                    if(codeUrls.indexOf(barCodeGood.barcode)===-1 && goodsIds.indexOf(goodItem.goodsId)!==-1){
                      for (let i = 0; i < this.goodsCart.details.length; i++) {
                        if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                          this.goodsCart.details[i].goodsNumber+=1
                          this.goodsCart.details[i].totalPrice=this.goodsCart.details[i].goodsNumber*this.goodsCart.details[i].goodsPrice
                          this.codeUrlList.push({
                            goodsId:this.goodsCart.details[i].goodsId,
                            codeUrl:barCodeGood.barcode
                          })
                          SessionStorage.set(CODE_URL_RETURN_LIST,this.codeUrlList)
                          break
                        }

                      }
                      this.updateCat()
                      this.$message({
                        type: 'success',
                        message: '商品添加成功！',
                        duration: 2000,
                        offset: 0
                      })
                    }else if(codeUrls.indexOf(barCodeGood.barcode)===-1 && goodsIds.indexOf(goodItem.goodsId)===-1){
                      //console.log("商品不存在进行添加：",resp.data.records[0])
                      resp.data.records[0].buyPackageLevel=1
                      if(!resp.data.records[0].goodsPrice){
                        resp.data.records[0].goodsPrice=resp.data.records[0].lastSmallPrice
                      }
                      goodItem=resp.data.records[0]
                      this.addGood(goodItem)
                    }else{
                      //console.log("barCodeGood.barcode: ",barCodeGood.barcode,"goodsItem: ",goodItem)
                      this.$message({
                        type: 'error',
                        message: '商品已添加购物车！',
                        duration: 2000,
                        offset: 110
                      })
                    }
                    this.updateCat()
                  }else{
                    let goodsCartFlag = false
                    for (let i = 0; i < this.goodsCart.details.length; i++) {
                      if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                        goodsCartFlag = true
                      }
                    }
                    if (!goodsCartFlag) {
                      let good = resp.data.records[0]
                      good.codeUrl=barCodeGood.barcode
                      good.buyPackageLevel = good.lastPackageLevel
                      if (good.lastPackageLevel === 1) {
                        good.goodsPrice = good.lastSmallPrice
                      }
                      if (good.lastPackageLevel === 2) {
                        good.goodsPrice = good.lastBigPrice
                        if (good.inventory < good.middleCount) {
                          good.buyPackageLevel = 1
                        }
                      }
                      good.saleBigPrice = good.lastBigPrice
                      good.saleSmallPrice = good.lastSmallPrice
                      this.addGood(good)
                    }
                  }
                }else{
                  let goodsCartFlag = false
                  for (let i = 0; i < this.goodsCart.details.length; i++) {
                    if (this.goodsCart.details[i].goodsId === resp.data.records[0].goodsId) {
                      goodsCartFlag = true
                    }
                  }
                  if (!goodsCartFlag) {
                    let good = resp.data.records[0]
                    good.buyPackageLevel = good.lastPackageLevel
                    if (good.lastPackageLevel === 1) {
                      good.goodsPrice = good.lastSmallPrice
                    }
                    if (good.lastPackageLevel === 2) {
                      good.goodsPrice = good.lastBigPrice
                      /*if (good.inventory < good.middleCount) {
                        good.buyPackageLevel = 1
                      }*/
                    }
                    good.saleBigPrice = good.lastBigPrice
                    good.saleSmallPrice = good.lastSmallPrice
                    this.addGood(resp.data.records[0])
                  } else {
                    this.$toast.success('商品已添加购物车', 'center')
                  }
                }

              }
            }else{
              this.$toast.error(resp.msg, 'center')
            }


            this.qrcode = ''
          })
      },
      //上架
      goOpenStock (goodsId) {

      },
      //更换客户
      modifySupplier (good) {
        this.$toast.error('该商品非当前客户商品', 'center')
      },
      //更新购物车更新缓存
      updateCat () {
        this.goodsCart.accountsPayable = 0
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
        }
        this.goodsCart.num = this.goodsCart.details.length
        this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        // console.log("this.goodsCart: ",this.goodsCart)
        if(this.goodsCart.arrears>this.goodsCart.accountsPayable || this.goodsCart.arrears===this.goodsCart.accountsPayable){
          //欠款>=应退
          this.returnPaymentAmountMax=0
          this.goodsCart.paymentAmount=0
        }else if(this.goodsCart.arrears<this.goodsCart.accountsPayable){
          this.returnPaymentAmountMax=this.goodsCart.accountsPayable-this.goodsCart.arrears
          this.goodsCart.paymentAmount=this.goodsCart.accountsPayable-this.goodsCart.arrears
        }
        // console.log("returnPaymentAmountMax: ",this.returnPaymentAmountMax)
        SessionStorage.set(SALE_RETURN_CART, this.goodsCart)
      },

      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //获取所有分类
      getCategorys () {
        let reqUrl=process.env.VUE_APP_SERVER + '/psi/category/selectAll'
        if(this.isHLJ && !this.isDN){
          reqUrl=process.env.VUE_APP_SERVER + '/psi/category/selectCategoryTree?type=' + (this.hljMode === 1 ? 1 : 1)
        }
        this.$axios.get(reqUrl)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            resp.data.map(item=>{
              if(item.categoryName!=='其他'){
                item.children.unshift({categoryId:item.categoryId,categoryName:'全部'+item.categoryName})
              }
            })
            for (let i = 0; i < resp.data.length; i++) {
              this.categorys.push(resp.data[i])
            }
            for (let i = 0; i < this.categorys.length; i++) {
              for (let j = 0; j < this.categorys[i].children.length; j++) {
                if (this.categorys[i].children[j].children) {
                  delete this.categorys[i].children[j].children
                }
              }
            }
          })
      },
      //选中分类搜索
      categoryChange () {
        let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0]
        this.categoryId = checkedNodes.value
        this.getSearch(1)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      rePage (e) {
        this.currentPage = e
      },
      getSearch (page, pageChange) {
        Loadings.show()
        let that=this
        let current = page
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let resize = 12
        let size = resize
        let keywords = this.searchInput
        let clientId = ''
        let showStop = 0
        if (this.checkedStop === false) {
          showStop = 0
        } else {
          showStop = 1
        }
        // console.log("that.currentIds.: ",that.currentIds)
        if (Tool.isNotEmpty(that.currentIds) || that.currentIds.length>0) {
          clientId = that.currentIds.toString()
        }

        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        let reqUrl=process.env.VUE_APP_SERVER + '/psi/salereturnorder/selectBySale?current='
          + current + '&size=' + size + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&clientId=' + clientId
        if(this.isHLJ && !this.isDN){
          reqUrl=process.env.VUE_APP_SERVER + '/psi/goods/queryBySale?current='
            + current + '&size=' + size + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&clientId=' + clientId
        }
        this.$axios.get(reqUrl)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.goods = resp.data.records
            for (let i = 0; i < this.goods.length; i++) {
              this.goods[i].buyPackageLevel = this.goods[i].lastPackageLevel
              this.goods[i].goodsPrice = parseFloat(this.goods[i].saleSmallPrice).toFixed(2)
            }
            for (let i = 0; i < this.goodsCart.details.length; i++) {
              for (let j = 0; j < this.goods.length; j++) {
                if (this.goodsCart.details[i].goodsId === this.goods[j].goodsId) {
                  this.goods[j].added = true
                }
              }
            }
            this.$refs.pageHelper.render(page, resp.data.total, resize)
          })
      },
      reSearch (data) {
        this.getSearch(1, data)
      },

      //添加购物车
      addGood (good) {
        let flag = false
        good.added = false
        good.goodsNumber = 1
        if (good.buyPackageLevel === 2) {
          good.goodsPrice = parseFloat(good.lastBigPrice)
        }
        if (good.buyPackageLevel === 1) {
          good.goodsPrice = parseFloat(good.lastSmallPrice)
        }
        if(this.isHLJ && !this.isDN && this.hljMode===1){
          good.buyPackageLevel = 1
          good.goodsPrice = parseFloat(good.lastSmallPrice)
        }
        good.totalPrice = parseFloat(good.goodsNumber * good.goodsPrice).toFixed(2)
        if (this.goodsCart.clientName === undefined || this.goodsCart.clientName.length < 1) {
          this.showChooseDialog()
        } else {
          let returnFlag = true
          this.$nextTick(() => {
            this.$refs.dialogSaleOrder.openSaleGood(good, this.clientNames, flag, returnFlag)
          })
        }
      },
      editCart (item) {
        let flag = true
        let returnFlag = true
        this.$nextTick(() => {
          this.$refs.dialogSaleOrder.openSaleGood(item, this.goodsCart.clientName, flag,returnFlag)
        })
      },
      //选择客户
      handleClose (tag) {
        this.clientIds.splice(this.clientIds.indexOf(tag), 1)
      },
      showChooseDialog () {
        this.$nextTick(() => {
          let clientIds = this.clientIds
          //销货开单，type=2-4 3回收 5为销货退货
          let type = 5
          this.$refs.dialogClientChoose.getClientIds(clientIds, type)
        })
      },
      getClients (clientIds, clientNames, isPesticide,hljMode) {
        this.isPesticide = isPesticide
        if(!Tool.isEmpty(SessionStorage.get(CODE_URL_RETURN_LIST))){
          this.codeUrlList=SessionStorage.get(CODE_URL_RETURN_LIST)
        }
        if(hljMode){
          this.hljMode=hljMode
          SessionStorage.set(HLJ_RETURN_MODE,this.hljMode)

        }
        if(this.isHLJ && !this.isDN && this.hljMode===1){
          this.colSpanLeft=24
          this.colSpanRight=0
        }
        if(this.isHLJ && (this.hljMode===2 || this.isDN)){
          this.colSpanLeft=9
          this.colSpanRight=15
          this.currentIds=clientIds
          this.goodsCart.details = []
          this.goodsCart.num = this.goodsCart.details.length
          this.goodsCart.paymentAmount = 0.00
          this.goodsCart.accountsPayable = 0.00
          SessionStorage.remove(SALE_RETURN_CART)
          SessionStorage.set(SALE_RETURN_CART, this.goodsCart)
          this.codeUrlList=[]
          SessionStorage.set(CODE_URL_RETURN_LIST,this.codeUrlList)
          this.getSearch(1)
        }
        // console.log("clientIds:",clientIds)

        if(clientIds[0]!==this.currentIds[0]){

          this.currentIds=clientIds
          // console.log("this.currentIds: ",this.currentIds)
          this.goodsCart.details = []
          this.goodsCart.num = this.goodsCart.details.length
          this.goodsCart.paymentAmount = 0.00
          this.goodsCart.accountsPayable = 0.00
          SessionStorage.remove(SALE_RETURN_CART)
          SessionStorage.set(SALE_RETURN_CART, this.goodsCart)
          this.getSearch(1)
        }else{
          if(!this.isHLJ || (this.isHLJ && this.isDN)){
            this.getSearch(1)
          }
        }
        this.clientNames = clientNames
        this.clientIds = clientIds
        this.goodsCart.clientId = this.clientIds
        this.goodsCart.clientName = this.clientNames
        this.$forceUpdate()

        //获取该客户的欠款金额
        this.getCustomerArrears();
        SessionStorage.set(SALE_RETURN_CART, this.goodsCart)
      },
      //购物车
      addSaleCart (good) {
        //console.log("add",good)
        good.added = true
        let _this = this
        _this.goodsCart.details.push(good)
        _this.goodsCart.num = _this.goodsCart.details.length
        _this.goodsCart.accountsPayable = 0
        for (let i = 0; i < _this.goodsCart.details.length; i++) {
          _this.goodsCart.details[i].totalPrice = parseFloat(_this.goodsCart.details[i].totalPrice)
          _this.goodsCart.accountsPayable += _this.goodsCart.details[i].totalPrice
        }
        _this.goodsCart.paymentAmount = _this.goodsCart.accountsPayable
        if(good.codeUrl){
          this.codeUrlList.push({
            goodsId:good.goodsId,
            codeUrl:good.codeUrl
          })
          SessionStorage.set(CODE_URL_RETURN_LIST,this.codeUrlList)
        }
        _this.updateCat()
        SessionStorage.set(SALE_RETURN_CART, _this.goodsCart)
        this.getSearch(this.currentPage)
      },
      editSaleCart (good) {
        //console.log("edit")
        this.goodsCart.accountsPayable = 0
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          if (good.goodsId === this.goodsCart.details[i].goodsId) {
            this.goodsCart.details.splice(i, 1, good)
          }
          this.goodsCart.details[i].totalPrice = parseFloat(this.goodsCart.details[i].totalPrice)
          this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
        }
        this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        this.updateCat()
        SessionStorage.set(SALE_RETURN_CART, this.goodsCart)
      },
      cartChange (item) {
        //console.log('购物车商品更新的good：', item)
      },
      saveFinish (flag) {
        this.goodsCart.details = []
        this.goodsCart.num = 0
        this.goodsCart.clientName = []
        this.goodsCart.clientId = []
        this.goodsCart.paymentAmount = 0.00
        this.goodsCart.accountsPayable = 0.00
        this.getSearch(1)
        this.showChooseDialog()
        this.getCustomerArrears()
      },

      delCart (item) {
        //console.log("item--",item)
        let _this = this
        this.$toast.showConfirm(item.goodsName, function () {
            for (let j = 0; j < _this.goods.length; j++) {
              if (_this.goods[j].goodsId === item.goodsId) {
                _this.goods[j].added = false
              }
            }
            for (let i = 0; i < _this.goodsCart.details.length; i++) {
              if (_this.goodsCart.details[i].goodsId === item.goodsId) {
                _this.goodsCart.details.splice(i, 1)
                _this.goodsCart.num = _this.goodsCart.details.length
                _this.updateCat();
                SessionStorage.set(SALE_RETURN_CART, _this.goodsCart)
              }
            }
          for(let i=0;i<_this.codeUrlList.length;i++){
            if(_this.codeUrlList[i].goodsId===item.goodsId){
              _this.codeUrlList.splice(i)
            }
          }
          //console.log("this.codesL ",this.codeUrlList)
          if(_this.codeUrlList){
            SessionStorage.set(CODE_URL_RETURN_LIST,_this.codeUrlList)
          }

          this.updateCat()
          }
        )

      },
      clearCart () {
        let _this = this
        this.$toast.showConfirm('清空购物车商品', function () {
            _this.goodsCart.details = []
            _this.goodsCart.num = _this.goodsCart.details.length
            //_this.goodsCart.clientName = []
            //_this.goodsCart.clientId = []
            _this.goodsCart.paymentAmount = 0.00
            _this.goodsCart.accountsPayable = 0.00
            SessionStorage.remove(SALE_RETURN_CART)
            SessionStorage.set(SALE_RETURN_CART, _this.goodsCart)
            _this.getSearch(1)

          }
        )
      },
      saveCart () {
        if (this.goodsCart.clientName === undefined || this.goodsCart.clientName.length < 1) {
          this.showChooseDialog()
        } else if (this.goodsCart.num > 0) {
          for (let i = 0; i < this.goodsCart.details.length; i++) {
            if (this.goodsCart.details[i].inventory < 1) {
              let type = 3
              this.$nextTick(() => {
                this.$refs.dialogSaleOrderSure.sureOrders(this.goodsCart, type)
              })
              //this.goodsCart.details[i].stockFlag = true
              //this.toStockOrder(this.goodsCart.details[i])
            } else {
              let type = 3
              this.$nextTick(() => {
                this.$refs.dialogSaleOrderSure.sureOrders(this.goodsCart, type)
              })
            }
          }

        } else if (this.goodsCart.num < 1) {
          this.$message({
            message: '您还没有选择任何商品哦~',
            center: true,
            type: 'warning',
            offset: 150
          })
        }
      },
      //去进货
      toStockOrder (item) {
        this.$confirm('商品【' + item.goodsName + '】库存不足, 是否去进货?', '提示', {
          confirmButtonText: '去进货',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      toNewGood () {
        let type = 3
        this.$router.push({
          name: '/goodsManagerEdit',
          params: { type }
        })
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
      //获取
      getCustomerArrears(){
        //console.log("clientId: ",this.clientIds)
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/salereturnorder/show/debtMoney?clientId=' + this.clientIds)
          .then(response => {
            Loadings.hide()
            // console.log("response.data.data: ",response.data.data)
            let resp = response
            // console.log("resp: ",resp)
            this.goodsCart.arrears=resp.data.data
            if(this.goodsCart.arrears>this.goodsCart.accountsPayable || this.goodsCart.arrears===this.goodsCart.accountsPayable){
              //欠款>=应退
              this.returnPaymentAmountMax=0
              this.goodsCart.paymentAmount=0
            }else if(this.goodsCart.arrears<this.goodsCart.accountsPayable){
              this.returnPaymentAmountMax=this.goodsCart.accountsPayable-this.goodsCart.arrears
              this.goodsCart.paymentAmount=this.goodsCart.accountsPayable-this.goodsCart.arrears
            }
          })
      },
      //视口
      changeWidth (width) {
        let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
        let clearCart = document.getElementsByClassName('clearCart')[0]
        if (width >= 1619) {
          inputCoreDesc.style.width = '400px'
        }
        if (this.showClearBtn) {
          if (width > 1669) {
            clearCart.style.marginRight = '-100px'
          }
          if (width <= 1669) {
            clearCart.style.marginRight = '0px'
          }
          if (width < 1640) {
            clearCart.style.marginRight = '50px'
          }
          if (width < 1523) {
            clearCart.style.marginRight = '100px'
          }
          if (width < 1370) {
            clearCart.style.marginRight = '160px'
          }

        }

        if (width < 1619) {
          inputCoreDesc.style.width = '280px'
        }
        if (width < 1310) {
          inputCoreDesc.style.width = '250px'
        }
        if (width < 1240) {
          inputCoreDesc.style.width = '200px'
        }
        this.triggerResize()
      },
      triggerResize () {
        $(window).on('resize', function () {
          let width = $(window).width()
          let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
          let clearCart = document.getElementsByClassName('clearCart')[0]
          if (width >= 1619) {
            inputCoreDesc.style.width = '400px'
          }

          if (this.showClearBtn) {
            if (width > 1669) {
              clearCart.style.marginRight = '-100px'
            }
            if (width <= 1669) {
              clearCart.style.marginRight = '0px'
            }
            if (width < 1640) {
              clearCart.style.marginRight = '50px'
            }
            if (width < 1523) {
              clearCart.style.marginRight = '100px'
            }
            if (width < 1370) {
              clearCart.style.marginRight = '160px'
            }
          }

          if (width < 1619) {
            inputCoreDesc.style.width = '280px'
          }
          if (width < 1310) {
            inputCoreDesc.style.width = '250px'
          }
          if (width < 1240) {
            inputCoreDesc.style.width = '200px'
          }
        }).trigger('resize')
      },
    }
  }
</script>

<style scoped>
  .mainBody {
    padding: 10px;
  }

  .rowForm {
    margin-bottom: 0px;
  }

  .leftBody, .rightBody {
    background: #fafafa;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: dashed 1px #faecd8;
    min-height: 840px;
  }

  .topNav {
    float: left;
    margin-left: 10px;
    max-width: 500px;
  }

  /*客户*/
  .keyTags {
    float: left;
    margin-right: 8px;
    margin-bottom: 10px;
    line-height: 40px;
    height: 40px;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .keyTagsInput {
    float: left;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  .clearCart {
    float: right;
    right: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  /*购物车*/
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  .pay {
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
  }

  .pay .payLabel {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .priceInput {
    float: left;
  }

  /*备注*/
  .pay .remarkLabel {
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;

  }

  .inputCoreDesc {
    float: left;
    width: 400px;
    margin-left: 28px;
  }

  .bottomBtn {
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 180px;
    margin-right: 10px;
  }

  .resetBtn {
    width: 180px;
  }

  .tipTag {
    position: relative;
  }

  /*右侧页面*/
  .categoryInput {
    float: left;
    width: 180px;
    margin-right: 10px;
  }

  .searchInput {
    width: 200px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 0;
    float: left;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*商品列表*/
  .el-col-4 {
    width: 16.4%;
  }

  .mainCard {
    margin-left: 5px;
    margin-top: 10px;
    min-height: 670px;
  }

  /deep/ .el-card__body {
    padding: 5px;
  }

  .goodCart {
    max-width: 230px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .cardImage {
    /*width: 150px;
    height: 150px;*/
    /*max-height: 145px;*/
    height: 145px;
    border-radius: 10px;
  }

  .desc .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .desc .descContent {
    line-height: 30px;
    font-size: 12px;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .descTag .descContentTag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descContentTag {
    padding-bottom: 8px;
  }

  .descTag .descContentTag:nth-of-type(2) {
    padding-top: 0;
  }

  .descTag .descContentTag /deep/ .el-tag {
    line-height: 25px;
    height: 25px;

  }

  .addCart {
    padding: 6px 12px;
    margin-bottom: 5px;
  }

  .inventoryTag {
    padding: 0 20px;
  }

  /deep/ .el-message {
  }

  /*已选*/
  .addGood {
    border: 1px solid #F56C6C;
    position: relative;
  }

  .checkedbox {
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: #F56C6C;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }

  .checkedboxPlugin {
    width: 80px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #e92322;
    color: #ffffff;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .inventoryWarning {
    color: #f8c375;
    margin-right: 3px;
    margin-left: 3px;
  }

  .scanCode {
    color: #fff;
    border: 0px solid #fff;
    position: absolute;
    outline: none;
    width: 300px;
    opacity: 0;
  }
  .payDebt{
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 5px;
  }
  .payRemark{
    display: inline-block;
    float: left;
    margin-left: 20px;
  }
  .payDebt .payLabel {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .debtMoneyText{
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-left: 10px;
  }
  .debtMoneyDesc{
    display: inline-block;
    background: #f4f4f5;
    border: 1px dashed #e9e9eb;
    border-radius: 10px;
    padding: 3px 8px 3px 8px;
    margin-top: 6px;
    margin-left: 10px;
    color: #878787;
  }
  .payTop{
    margin-top: 5px;
  }
  .debtMoneyWarning{
    /*font-size: 20px;*/
    color: #e92322;
  }
  /deep/ .el-table__fixed-body-wrapper{
    top: 59px !important;
  }
  .imgBox{
    position: relative;
  }
  .innerImg{
    position: absolute;
    left: 2px;
    bottom: 0;
    width: 97.5%;
  }
</style>
