<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link to="/admin/clientManager">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回客户管理</el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">{{'客户往来明细'}}</el-link>
        <el-link class="clientName" type="warning" :underline="false" icon="el-icon-s-claim">{{client.clientName===''?client.clientPerson:client.clientName}}
        </el-link>
        <div class="searchBtnLeft" >
          <span class="titleDesc" >￥ {{debtMoney}}</span>
          <span class="titleName" ><!--<li class="el-icon-money"></li>-->赊账 </span>
        </div>
      </div>
      <div class="topNav">
        <div class="navLeft">

          <el-select v-model="billType" placeholder="请选择" class="cftype" @change="recipeCategoryChange">
            <el-option
              v-for="item in billTypeOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>

          <div class="block searchTime">
            <el-date-picker
              v-model="value2"
              type="daterange"
              align="left"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="dateChange"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>

          <el-button class="searchBtn" type="danger" @click="queryList(1)">搜索</el-button>

          <!--<div class="checkStop">
            <template>
              <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示已撤销订单</el-checkbox>
            </template>
          </div>-->

        </div>
        <div class="navRight">
          <!--<el-button class="searchBtn" type="danger" @click="addSupplier">新增客户</el-button>-->

        </div>

      </div>

      <el-button class="refreshBtn" icon="el-icon-refresh" @click="queryList(1)" circle></el-button>
      <div class="horizontalLine"></div>
    </el-row>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="clientOrders"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="100"></el-table-column>
        <el-table-column :property="!repayment ?'billingType':'repaymentName'" label="订单类型" width="150" :class="!repayment?'':'centerControl'"></el-table-column>
        <el-table-column property="createTime" label="开单时间" width="200" :class="!repayment?'':'centerControl'"></el-table-column>
        <el-table-column property="clientName" label="还款人" width="150"  v-if="repayment" class="centerControl"></el-table-column>

        <el-table-column property="goodsName" label="商品" sortable v-if="!repayment">
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthGoods" placement="bottom"
                        @mouseenter.native="remarkEnter(scope.row.goodsName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.goodsName===''?'-':scope.row.goodsName}}
              </div>
              <span class="showTipLine">{{scope.row.goodsName===''?'赊账收款':scope.row.goodsName}}</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column property="paymentAmount" label="单据金额" width="250" sortable v-if="!repayment">
          <template slot-scope="scope">
            <el-tag :type="scope.row.dataTable==='1'?'danger':'warning'">{{ scope.row.dataTable === '1' ? '应收' : scope.row.dataTable === '2'?'应退':'收款' }}
              ￥ {{scope.row.dataTable === '1'?scope.row.accountsPayable:scope.row.dataTable === '2'?scope.row.refundAmount:scope.row.repaymentMoney}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column property="status" label="状态" width="100" sortable v-if="!repayment">
          <template slot-scope="scope">
            <el-tag :type="parseInt(scope.row.status) === 0 || scope.row.status===3 ? 'success' : 'danger'" class="showCenter" id="status">
              {{ SUPPLIER_ORDER_STATUS | optionObjectKV(parseInt(scope.row.status)) }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column property="status" label="结算状态" width="250" sortable v-if="!repayment">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isDebt === 1 ? 'danger' : scope.row.isDebt === 2?'success':scope.row.isDebt === 0? 'primary':'info'" class="showCenter" id="isDebt">
              {{ IS_DEBT | optionObjectKV(scope.row.isDebt) }}{{scope.row.isDebt===1?'：剩余赊账 ￥' +scope.row.orderDebtMoney:''}}
              {{scope.row.dataTable==='3'?'-':''}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column property="realRepaymentMoney" label="应收金额" width="200" sortable v-if="repayment" class="centerControl">
          <template slot-scope="scope" >
            <el-tag :type="scope.row.realRepaymentMoney<1?'warning':'danger'" class="showCenter">应收
              ￥ {{scope.row.realRepaymentMoney}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="discount" label="优惠金额" width="150" sortable v-if="repayment" class="centerControl">
          <template slot-scope="scope">
            <el-tag :type="'warning'" class="showCenter">优惠
              ￥ {{scope.row.discount}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column property="userName" label="收款人" width="130"  v-if="repayment" class="centerControl"></el-table-column>
        <el-table-column property="remark" label="备注"  :show-overflow-tooltip="true" v-if="repayment">
          <template slot-scope="scope" >
            <el-tag type="info" class="showCenter">
              {{scope.row.remark===""?"-":scope.row.remark}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="repaymentMoney" label="总收款金额" width="200" sortable v-if="repayment" class="centerControl">
          <template slot-scope="scope">
            <el-tag :type="'danger'" class="showCenter">销账
              ￥ {{scope.row.repaymentMoney}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="180"
          >
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="detail(scope.row)">查看详情
            </el-button>
            <el-button size="mini" v-if="(getAuthorityMenu('300201') || ((getAuthorityMenu('300401') || getAuthorityMenu('50020401')))) && !repayment && scope.row.dataTable!=='3'"
                       style="width: 68px;" :disabled="scope.row.status == 1"
                       :type="scope.row.status == 0 ? 'danger' : 'warning'"
                       @click="cancelOrder(scope.row)">{{ scope.row.status == 0 ? '撤单' : '已撤单' }}
            </el-button>
            <!--
            <el-button
              size="mini"
              type="success"
              @click="reOrder(scope.row)">再来一单
            </el-button>
            -->
            <!--<el-button
              size="mini"
              type="warning"
              @click="del">排序
            </el-button>-->
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="queryList" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-client-repayment-detail ref="clientRepaymentDetail"></dialog-client-repayment-detail>
    <dialog-client-order-detail ref="dialogClientOrderDetail" @cancelOrder="cancelOrder2"></dialog-client-order-detail>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogClientOrderDetail from '../../../components/DialogClientOrderDetail.vue'
  import DialogClientRepaymentDetail from '../../../components/DialogClientRepaymentDetail'
  export default {
    name: 'SupplierManagerSaleDetail',
    components: {
      PageHelper,
      DialogClientRepaymentDetail,
      DialogClientOrderDetail
    },
    data () {
      return {
        message: '客户往来明细',
        searchInput: '',
        clientName: '',
        client: {
          clientName: '',
          clientPerson: '',
          clientPhone: '',
          status: 0
        },
        clientOrder: {},
        clientOrders: [],
        checkedStop: false,//默认不显示停用客户
        currentRow: null,
        SUPPLIER_ORDER_STATUS: SUPPLIER_ORDER_STATUS,
        IS_DEBT:IS_DEBT,
        debtMoney:0.00,//赊账金额
        //弹框
        centerDialogVisible: false,
        //时间选择器
        startDate: '', //开始时间
        endDate: '', //结束时间
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        value1: '',
        value2: '',

        remarkFlag: false,
        //订单分类
        billTypeOption: [
          {
            value: 0,
            label: '全部'
          }, {
            value: 4,
            label: '赊账单'
          }, {
            value: 1,
            label: '已结清（赊账单）'
          }, {
            value: 2,
            label: '未结清（赊账单）'
          },{
            value: 5,
            label: '收款单'
          },
        ],
        billType: 0,//处方分类绑定
        repayment: false,
      }
    },
    mounted () {

      if(this.$route.params.item){
        this.client =this.$route.params.item
        SessionStorage.set(WANGLAIMINGXI_CLIENT, this.client)
      }else{
        this.client = SessionStorage.get(WANGLAIMINGXI_CLIENT)
      }
      this.navChange()
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }/*else if(!this.getAuthorityMenu('50020403')){
        this.$emit('noAuth')
      }*/else{
        this.queryList(1)
      }
    },
    methods: {
      navChange () {
        let flag = 0
        if (this.type === 1) {
          flag = 1
        }
        this.$emit('navChange', flag)
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      //订单分类
      recipeCategoryChange () {
        if(this.billType>4){
          this.repayment=true
        }else{
          this.repayment=false
        }
        this.queryList(1)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      //查询往来明细列表
      queryList (page) {
        let current = page
        let size = this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/client/contactDetailsForPc' +
            '?current=' + current +
            '&size=' + size +
            '&clientId=' + this.client.clientId+
            '&billType='+this.billType+
            '&repayment='+this.repayment+
            '&startDate='+this.startDate+
            "&endDate="+this.endDate,
          method: 'GET'
        })
          .then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.clientOrders = res.data.data.records
                this.clientOrders.map(item => {
                  if(Tool.isNotEmpty(item.status)){
                    item.status = parseInt(item.status)
                  }else{
                    item.status=3
                  }
                })
                this.debtMoney=parseFloat(this.clientOrders[0].debtMoney).toFixed(2)
                this.$refs.pageHelper.render(page, res.data.data.total)
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //列表查询
      getSearch (page, pageChange) {
        let _this = this
        let current = page
        let size = _this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let keywords = _this.searchInput
        let showStop = 0
        if (_this.checkedStop === false) {
          showStop = 0 //不显示已撤单数据
        } else {
          showStop = 1 //显示已撤单数据
        }

        Loadings.show()
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/client/contactDetailsForPc' +
            '?current=' + current +
            '&size=' + size +
            '&clientId=' + _this.client.clientId+
            '&billType='+_this.billType+
            '&repayment='+_this.repayment,
          method: 'GET'
        })
          .then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code === 200) {
                _this.clientOrders = res.data.data.records
                //_this.getSearch(_this.$refs.pageHelper._data.page);
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }

          })
      },
      reSearch (data) {
        //this.getSearch(1,data);
        this.queryList(this.$refs.pageHelper.page)
      },
      //2.已撤销订单
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      },
      checkedStopClick () {
        this.getSearch(1)
      },
      //订单详情
      detail(item) {
        this.$nextTick(() => {
          let orderId = item.orderId
          let clientName = this.client.clientName===''?this.client.clientPerson:this.client.clientName
          let type = item.dataTable
          if(item.dataTable!=='3'){
            //将当前选中的客户添加到缓存中
            SessionStorage.set(WANGLAIMINGXI_CLIENT, this.client)
            //this.$refs.dialogSupplierOrderDetail.getOrderId(orderId,clientName);
            this.$refs.dialogClientOrderDetail.getOrderId(orderId, clientName, type)
          }else{
            this.$refs.clientRepaymentDetail.openRepaymentDetail(item)
          }

        })
      },
      //撤销订单
      cancelOrder (row) {
        if (row.status === '1') {
          this.$message({
            type: 'info',
            message: '该条记录已撤单！',
            duration: 2000,
            offset: 110
          })
          return
        }

        let orderId = row.orderId
        let type = row.dataTable
        if (type == 1) {
          Loadings.show()
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/saleorder/revoke?saleOrderId=' + orderId,
            method: 'POST'
          })
            .then((res) => {
              Loadings.hide()
              if (res.status === 200) {
                if (res.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '撤销订单成功！',
                    duration: 2000,
                    offset: 110
                  })
                  this.queryList(this.$refs.pageHelper.page)
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        } else if (type == 2) {
          Loadings.show()
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/salereturnorder/revoke?saleReturnOrderId=' + orderId,
            method: 'POST'
          })
            .then((res) => {
              Loadings.hide()
              if (res.status === 200) {
                if (res.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '撤销订单成功！',
                    duration: 2000,
                    offset: 110
                  })
                  this.queryList(this.$refs.pageHelper.page)
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
      },
      cancelOrder2 (orderId, type) {
        if (type == 1) {
          Loadings.show()
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/saleorder/revoke?saleOrderId=' + orderId,
            method: 'POST'
          })
            .then((res) => {
              Loadings.hide()
              if (res.status === 200) {
                if (res.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '撤销订单成功！',
                    duration: 2000,
                    offset: 110
                  })
                  this.queryList(this.$refs.pageHelper.page)
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        } else if (type == 2) {
          Loadings.show()
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/salereturnorder/revoke?saleReturnOrderId=' + orderId,
            method: 'POST'
          })
            .then((res) => {
              Loadings.hide()
              if (res.status === 200) {
                if (res.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '撤销订单成功！',
                    duration: 2000,
                    offset: 110
                  })
                  this.queryList(this.$refs.pageHelper.page)
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //时间选择
      dateChange (e) {
        this.startDate = this.$moment(e[0]).format('YYYY-MM-DD')
        this.endDate = this.$moment(e[1]).format('YYYY-MM-DD')
        this.queryList(1);
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .mainPages {
    min-height: 850px;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    margin-right: 10px;
    cursor: unset;
  }

  .clientName {
    margin-top: -5px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    cursor: unset;
  }

  /*搜索导航*/
  .topNav {
    float: left;
    display: inline-block;
    margin-top: 10px;
    margin-left: 30px;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 180px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: right;
  }
  .searchBtnLeft{
    margin-left: 20px;
    float: right;
    cursor: unset !important;
  }
  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 36px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 10px;
    float: right;
    margin-top: 10px;
  }

  /*时间搜索*/
  .searchTime {
    float: left;
    margin-right: 10px;
  }

  /deep/ .el-date-editor .el-range-input {
    margin-left: 20px;
  }

  /*表格*/
  .mainForm {
    margin-top: 10px;
  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 560px;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }

  .toolTipWidth {
    max-width: 200px !important;
    text-align: center !important;
  }

  .toolTipWidthPlugin {
    padding: 0 0 20px;
    float: left;
    text-align: center;
  }

  .showTipLine {
    line-height: 30px;
    height: 30px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .address {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showRemarkLine {
    height: 30px !important;
    line-height: 30px !important;
    overflow: hidden;
  }

  .payType {
    margin-right: 10px;

  }
  /*新增筛选*/
  .cftype {
    float: left;
    margin-right: 15px;
    width: 180px;
  }
  .centerControl{
    text-align: center !important;
  }
  .titleName {
    display: inline-block;
    background-color: #e92322;
    color: #ffffff;
    padding: 0 15px;
    line-height: 40px;
    cursor: unset;
    font-size: 16px;
    float: right;
    border-top-left-radius: 10px;
  }

  .titleDesc {
    display: inline-block;
    background-color: #ffffff;
    color: #e92322;
    border: 1px solid #e92322;
    padding: 0 15px;
    line-height: 38px;
    cursor: unset;
    margin-right: 10px;
    font-size: 20px;
    float: right;
    border-bottom-right-radius: 10px;
  }
  .greyTheme{
    background: #999999;
    color: #999999;
  }
  .dataTable3{
    text-align: center !important;
  }
</style>
