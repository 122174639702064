<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail">
      <div slot="title" class="header-title">
        <div style="padding:15px 20px;">
          <div class="title">{{ type === 1 ? '进货单据详情' : '进货退货单据详情' }}</div>
          <div class="verticalLine" v-if="type==1"></div>
          <div class="verticalLineLarge" v-if="type!=1"></div>
          <div class="titleDesc">供应商</div>
          <el-link class="supplierName" type="info" :underline="false" icon="el-icon-s-custom">{{supplierName}}
          </el-link>
        </div>
      </div>
      <SupplierOrderDetail :player-id="'dialogDetail'" ref="supplierOrderDetail" @orderCancel="orderCancel"></SupplierOrderDetail>
    </el-dialog>
  </div>
</template>

<script>
  import SupplierOrderDetail from './SupplierOrderDetail'

  export default {
    name: 'DialogPlayer',
    components: { SupplierOrderDetail },
    props: {},
    data () {
      return {
        //播放器实例
        dialogPlayer: {},
        supplierName: {
          default: '-'
        },
        orderId:'',
        centerDialogVisible: false,
        type:1,//type=2，进货退货
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      getOrderId (orderId, supplierName, type,hiddenTopBtn) {
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true;
        this.supplierName = supplierName;
        this.orderId = orderId;
        this.type = type;
        this.$nextTick(() => {
          this.$refs.supplierOrderDetail.getDetailById(orderId, supplierName, type,hiddenTopBtn)
        })
      },
      playerVOD (vod, title) {
        this.centerDialogVisible = true
        this.playerTitle = title
        this.$nextTick(() => {
          this.$refs.ossPlayer.playVOD(vod)
        })
      },
      orderCancel(flag, orderid, type) {
        if (flag) {
          this.centerDialogVisible = false;
          this.$emit('cancelOrder', orderid, type);
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog{
    border-radius: 10px;
  }
  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }
  /deep/ .el-dialog__body{
    padding: 10px;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }
  /deep/ .el-dialog__close{
    color: #ffffff !important;
  }
  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .header-title .title{
    float: left;
    cursor: pointer;
  }
  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }
  .verticalLineLarge {
    position: absolute;
    top: 20px;
    float: left;
    left: 192px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }
  .header-title .titleDesc{
    float: left;
    margin-left:30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }
  .header-title .supplierName{
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }
  .header-title .supplierName:hover,.header-title .supplierName:focus{
    color: #f6e483;
  }
</style>
