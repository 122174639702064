<template>
  <div>
    <dialog-login ref="dialogLogin" :showDialog="showDialog"></dialog-login>
  </div>

</template>

<script>
  import DialogLogin from '../../components/DialogMdjLogin'
  export default {
    name: 'mdjLogin',
    components:{DialogLogin},
    data() {
      return {
        message: '这里是登录页面',
        input: '',
        picCover:'/static/imgs/login/mdjlogin-nav-8.jpg',
        //登录，默认选中存储localStorage
        remember: true,
        /**绑定注册模块框显示的内容：showDialog=
         登录：STATUS_LOGIN   toLoginAcount()
         注册：STATUS_REGISTER  toRegisterMEM
         忘记密码：STATUS_FORGET  toForgetPWD **/
        showDialog: "STATUS_LOGIN",
      }
    },

    beforeRouteUpdate(to,from,next){
      if(to.query.resetLogin){
        // console.log("re login",to)
        LocalStorage.remove(MEMBER_LOGIN_LOCAL)
        this.$refs.dialogLogin.toLoginAccount();
        this.getHeaderImg();
      }
    },
    mounted () {
      LocalStorage.remove(MEMBER_LOGIN_LOCAL)
      // if(Tool.isNotEmpty(LocalStorage.get(MEMBER_LOGIN_LOCAL))){
      //   this.memberLogin = LocalStorage.get(MEMBER_LOGIN_LOCAL) || {};
      // }else{
      //   this.memberLogin=Tool.getLoginMember();
      // }
      //先进入完善店铺信息
      //this.showDialog="STATUS_INFO"

      this.$refs.dialogLogin.openLoginModal(this.showDialog);
      this.getHeaderImg();
      //先进入完善店铺信息-改变模态框大小
      //$('.modal-content').addClass('bigModal')
    },
    methods:{
      loginIn(){
        this.$router.push("/admin/index");
      },
      getHeaderImg() {
        let headerImg = document.getElementById("header_icon_img");
        /*背景图片：直接/base目录，如果在news下，则加.*/
        //headerImg.style.backgroundImage = "url('/base/imgs/news/news-header.e51a9e61.png')";
        headerImg.style.backgroundImage = "url("+this.picCover+")";
      },
    }
  }
</script>

<style scoped>

  .news .header_icon #header_icon_img span {
    width: 100%;
    display: inline-block;
    line-height: 150px;
    font-size: 40px;
    text-align: center;
  }
</style>
