<template>
  <div>
    <el-dialog @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">农药大数据证书下载</div>
      </div>

      <div id="wrap" class="contactBody">
        <canvas id="wrap-canvas" width="800px" height="569px"></canvas>
        <el-button class="storeInfoBtn" type="danger" @click="down()">下载证书</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MyCertificate',
    props: {},
    data () {
      return {
        message: '证书下载',
        centerDialogVisible: false,
        canvasPen: '',
        img: '',
        tenant:{},
      }
    },
    mounted () {
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openMyCertificate () {
        this.centerDialogVisible = true
        this.getCanvas();
        this.getStoreInfo();
      },
      down () {
        let canvas=document.getElementById("wrap-canvas")
        let save_url=canvas.toDataURL("image/png");
        let a=document.createElement('a')
        document.body.appendChild(a)
        a.href=save_url
        a.download=this.tenant.tenantName+"《农药大数据进销存电子台账》证明书"
        a.click()
      },
      getCanvas(){
        let _this=this
        setTimeout(()=>{
          let canvasNode = document.querySelector('#wrap-canvas')
          if (canvasNode.getContext) {
            _this.canvasPen = canvasNode.getContext('2d')

            _this.img = new Image()
            _this.img.src = '/static/imgs/my/wtjxc-certificate.jpg'
            _this.img.onload = function () {
              //1.图片填充
              _this.drawImg()
              //3.线性渐变
              _this.gradientImg()
            }
          }
        },500)

      },
      drawImg () {
        this.canvasPen.drawImage(this.img,0,0, this.img.width,this.img.height)
      },
      gradientImg () {

        // this.tenant.creditCode="92131127MA0EHBU036"
        // this.tenant.tenantName="景县小牛农资门市部"
        // this.tenant.address="河北省,衡水市,景县"
        // this.tenant.payTime="2020年07月05日"
        // this.tenant.deadline="2025年07月05日"

        this.canvasPen.save();
        this.canvasPen.fillStyle="black";
        this.canvasPen.font="18px sans-serif";
        this.canvasPen.textAlign="left";
        this.canvasPen.beginPath();
        this.canvasPen.fillText(this.tenant.creditCode,312,193);
        this.canvasPen.restore();

        this.canvasPen.save();
        this.canvasPen.fillStyle="black";
        this.canvasPen.font="17px sans-serif";
        this.canvasPen.textAlign="left";
        this.canvasPen.beginPath();
        this.canvasPen.fillText(this.tenant.tenantName,240,239);
        this.canvasPen.restore();

        this.canvasPen.save();
        this.canvasPen.fillStyle="black";
        this.canvasPen.font="18px sans-serif";
        this.canvasPen.textAlign="left";
        this.canvasPen.beginPath();
        this.canvasPen.fillText(this.tenant.creditCode,300,272);
        this.canvasPen.restore();
        //this.tenant.address='在计算机科学中，内存中每个用于数据存取的基本单位，都被赋予一个唯一的序号，称为地址，也叫做内存地址。内存地址指系统 RAM 中的特定位置，通常以十六进制的数字表示。'
        this.canvasPen.save();
        this.canvasPen.fillStyle="black";
        this.canvasPen.font="17px sans-serif";
        this.canvasPen.textAlign="left";
        this.canvasPen.beginPath();
        // 每行所需字数 = 画布总宽度 / 单个字体大小
        let rowFontNum = Math.floor(500 / 17)
        //console.log("rowFontNum: ",rowFontNum)
        // 字符串总长度
        let strLength = this.tenant.address.length
        //console.log("strLength: ",strLength)
        // 所需行数 = 字符总长度 / 每行所需字数
        let rows = Math.ceil(strLength / rowFontNum)
        //console.log("rows: ",rows)

        for (let i = 0; i <= rows; i++) {
          this.canvasPen.fillText(this.tenant.address.slice(rowFontNum*(i-1),rowFontNum*i),220,284+i*20)
        }

        // this.canvasPen.fillText(this.tenant.address,220,304);
        this.canvasPen.restore();

        this.canvasPen.save();
        this.canvasPen.fillStyle="black";
        this.canvasPen.font="18px sans-serif";
        this.canvasPen.textAlign="left";
        this.canvasPen.beginPath();
        this.canvasPen.fillText(this.tenant.payTime,294,370);
        this.canvasPen.restore();

        this.canvasPen.save();
        this.canvasPen.fillStyle="black";
        this.canvasPen.font="18px sans-serif";
        this.canvasPen.textAlign="left";
        this.canvasPen.beginPath();
        this.canvasPen.fillText(this.tenant.deadline,258,403);
        this.canvasPen.restore();
      },
      getStoreInfo(){
        Loadings.show()
        //查找当前用户的原有店铺信息
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/blade-system/tenant/tenantDetail',
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                let data = res.data.data;
                this.tenant.tenantName = data.tenantName;
                this.tenant.creditCode = data.creditCode;
                this.tenant.linkman = data.linkman;
                this.tenant.address = data.address;
                this.tenant.tenantId=data.tenantId;
                this.tenant.account=LocalStorage.get(LOGIN_USER).account;
                this.tenant.createTime=data.createTime;
                this.getTenantPrivilege()
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          });
      },
      returnDate(time){
        let times = time.replace(" ", ":").replace(/\:/g, "-").split("-");
        let timestr = times[0]+"年" + times[1] + "月" + times[2]+"日"
        return timestr
      },
      getTenantPrivilege(){
        let _this = this
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/paylog/list' +
            '?tenantId=' + this.tenant.tenantId,
          method: 'GET'
        })
          .then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.tenant.payTime=res.data.data[0].payTime;
                this.tenant.deadline=res.data.data[0].deadline;
                if(Tool.isEmpty(this.tenant.payTime)){
                  this.tenant.payTime=this.returnDate(this.tenant.createTime)
                }else{
                  this.tenant.payTime=this.returnDate(this.tenant.payTime)
                }
                this.tenant.deadline=this.returnDate(this.tenant.deadline)
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      }
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*body*/
  .contactBody {
    overflow: hidden;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 200px;

  }


</style>
