<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">用法用量</div>
      </div>
      <div class="orderNav">
        <el-form  ref="good" label-width="120px" class="demo-good store-info-form">
          <el-row :gutter="20" class="rowForm">
            <el-col :span="15">
              <el-form-item class="labelTitle" label="商品名称" prop="goodName">
                <el-input disabled class="inputLine" v-model="good.goodsName"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="包装规格" prop="packingSpecification">
                <el-input disabled class="inputLine" v-model="good.packageUnit"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="生成厂家" prop="packingSpecification">
                <el-input disabled class="inputLine" v-model="good.companyName"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="用药时间" prop="saleBigPrice">
                <div class="yytime">
                         <el-time-select
                          class="mytimeclass"
                        v-model="yytime"
                        :picker-options="{
                          start: '08:30',
                          step: '00:01',
                          end: '18:30'
                        }">
                      </el-time-select><span class="yytimejoin">至</span>
                      <el-time-select
                      class="mytimeclass"
                        v-model="yytimeend"
                        :picker-options="{
                          start: '08:30',
                          step: '00:01',
                          end: '18:30'
                        }">
                      </el-time-select>
                </div>
           
              </el-form-item>
              <el-form-item class="labelTitle" label="每亩用量" prop="packingSpecification">
                <el-input  class="inputLine" type="number" @blur="mmylblur($event)" v-model="yl" maxlength="50"></el-input><span class="mmclass">{{good.smallUnitName}}/亩</span>
              </el-form-item>
              <el-form-item class="labelTitle" label="用药方法" prop="packingSpecification">
                <el-select v-model="valueyyfa"  class="cftype">
                    <el-option
                    :props="{value:'usageId',label:'usageName'}"
                    v-for="item in yyfalist"
                    :key="item.usageId"
                    :label="item.usageName"
                    :value="item.usageId">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-card class="imageBlock">
                <el-image class="uploadImage" v-if="good.goodsImg" fit="scale-down"
                          :src="base_url+'/goods'+good.goodsImg"
                ></el-image>
                <span class="imageDesc">商品图片</span>
              </el-card>

            </el-col>
          </el-row>

          <div class="bottomBtn">
            <el-button  class="storeInfoBtn" icon="el-icon-s-goods" type="danger" @click="save" >保存 </el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {
        good:{
            type:Object,
            default:() => ({})
        },
        editgood:{
          type:Object,
          default:() => ({})
        }
    },
    data () {
      return {
        message: '处方管理加入商品填写用法用量',
        yytime: '09:00',//用药时间
        yytimeend:'11:00',
        valueyyfa:1,//用药方法
        valueyyfadesc:'喷雾法',
        yyfalist:[],//用药方法数组
        yl:'',//用量


        centerDialogVisible: false,
        rules: {},
        PACKAGE_LEVEL: PACKAGE_LEVEL,
        base_url: '',
        editGoods: false,//添加/编辑购物车
        saleStock: false,
       // editGood:false,//编辑状态下的购物车
        returnFlag:false,//销货退货传入
      }
    },
    mounted () {
      this.base_url=this.BASE_URL;
      this.getffData()
    },
    methods: {
      //用法用量
      mmylblur(e){
        var patrn = /^\d+(\.\d{1,2})?$/;
        if (!patrn.exec(e.target.value)){
            this.$message({ type: 'warning', message: '请您输入数字并且小数位2位', duration: 2000, offset: 110 });
        }
        if(e.target.value==0){
          this.$message({ type: 'warning', message: '请您输入有效数字', duration: 2000, offset: 110 });
        }
      },
      //清除上次数据
      clearyl(){
        this.yl='';
        this.valueyyfa=1;
        this.yytime="09:00"
        this.yytimeend="11:00"
        if(JSON.stringify(this.editgood)!='{}'){
          console.log(this.editgood);
          this.yl=this.editgood.yl
          this.valueyyfa=this.editgood.syfaid
          this.yytime=this.editgood.yytime.split("~")[0]
          this.yytimeend=this.editgood.yytime.split("~")[1]
        }
      },
      //获取用药方法
      getffData(){
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/usage/list')
          .then((response) => {
            Loadings.hide()
            this.yyfalist=response.data.data;
          })
      },
      dialogClose () {
        this.$nextTick(() => {
          this.centerDialogVisible = false;
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      open () {
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        
      },
      save() {
        if(!this.yl){
          this.$message({ type: 'warning', message: '请填写每亩用量', duration: 2000, offset: 110 });
          return false;
        }
        var patrn = /^\d+(\.\d{1,2})?$/;
        if (!patrn.exec(this.yl)){
            this.$message({ type: 'warning', message: '请您输入数字并且小数位2位', duration: 2000, offset: 110 });
            return 
        }
         if(this.yl==0){
          this.$message({ type: 'warning', message: '请您输入有效数字', duration: 2000, offset: 110 });
          return
        }
        let good={}
        good.good=this.good;
        good.yytime=this.yytime+"~"+this.yytimeend;
       // good.yytimeyl=this.yytime;
        good.yl=this.yl;
        good.syfaid=this.valueyyfa;
        let arr=this.yyfalist.filter(it=>{
          return it.usageId==this.valueyyfa
        })
        good.syfadesc=arr[0].usageName
        if(JSON.stringify(this.editgood)=="{}"){
          this.$emit("addPro",good,1)//添加
        }else{
          this.$emit("addPro",good,2)//编辑
        }
        
        this.centerDialogVisible = false
      }
   
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 825px !important;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__content {

  }

  .orderNav {
    position: relative;
    margin-bottom: 50px;
  }

  .inputLine {
    max-width: 250px;
    float: left;
  }

  .bottomBtn {
    display: inline-block;
    /*position: absolute;
    bottom: 80px;
    left: 45%;
    margin: 0 auto;*/
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }
  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }
  .yytime{
    display: flex;
  }
  .imageBlock {
    max-width: 270px;
    padding: 20px;
  }

  .uploadImage {
    max-height: 210px;
  }

  .imageDesc {
    display: block;
    margin-top: 15px;
  }

  .yytimejoin{
    margin: 19px;
  }
  .mmclass{
      margin-top: 20px;
      display: inline-block;
      margin-left: -75px;
  }
  .cftype{
      margin-left: -125px;
      width: 250px;
  }
  .mytimeclass{
    width: 100px !important;
  }
</style>
