<template>
  <div class="mainBody">
    <el-row :gutter="10" class="rowForm">
      <el-col :span="9">
        <div class="leftBody">
          <div class="topNav">
            <el-tag class="keyTags" v-if="goodsCart.clientName===undefined" type="warning">请选择交回者</el-tag>
            <el-tag class="keyTags" id="clientIds"
                    :key="index"
                    v-for="(tag,index) in goodsCart.clientName"
                    :disable-transitions="false"
                    @close="handleClose(tag)">
              <template >
                <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(tag)" :disabled="!remarkFlag">
                  <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                    {{tag}}
                  </div>
                  <span class="showRemarkLine">{{tag}}</span>
                </el-tooltip>
              </template>
            </el-tag>
            <el-button class="keyTagsInput" icon="el-icon-circle-plus-outline" type="primary" @click="showChooseDialog">
              选择交回者
            </el-button>
            <div class="tipTag">
              <el-button v-if="showClearBtn" class="clearCart" icon="el-icon-delete" type="danger"
                         @click="showChooseDialog">清空购物车
              </el-button>
            </div>
          </div>
          <!--<div class="toastMessage">
            <el-alert title="提示：请使用扫码枪 或 在右侧选择需要进货的商品" type="success" :closable="false" center show-icon>
            </el-alert>
          </div>-->
          <div class="mainForm">
            <el-table
              ref="multipleTable"
              tooltip-effect="dark"
              :data="goodsCart.wasteRecoveryDetailList"
              height="500px"
              border
              highlight-current-row
              empty-text="未选择任何商品，请在右侧栏选择添加商品"
              class="previous-row"
              :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
            >
              <el-table-column type="index" label="序号" class="indexCenter" width="49"></el-table-column>
              <el-table-column property="bigPackageId" label="包装分类" width="105" sortable
                               :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.bigPackageId===1?'primary':scope.row.bigPackageId===2?'success':scope.row.bigPackageId===3?'warning':'info'">
                    {{RECYCLE_CATEGORY | optionObjectKV(scope.row.bigPackageId)}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="bigPackageId" label="包装规格" width="150"
                               :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-tag type="info" class="packageUnitDesc"
                          v-if="scope.row.bigPackageId===1 || scope.row.bigPackageId===2">
                    {{scope.row.bigPackageId===1?'农药：':scope.row.bigPackageId===2?'肥料：':scope.row.bigPackageId===3?'农膜：':'其他：'}}
                    {{VESSEL_TYPE | optionObjectKV(scope.row.vesselPackageId)}}+
                    {{VESSEL_TYPE | optionObjectKV(scope.row.texturePackageId)}}+
                    {{VESSEL_TYPE | optionObjectKV(scope.row.sizePackageId)}}
                  </el-tag>
                  <el-tag type="info" class="packageUnitDesc" v-if="scope.row.bigPackageId===3">
                    {{scope.row.bigPackageId===3?'农膜：':''}}
                    {{VESSEL_TYPE | optionObjectKV(scope.row.vesselPackageId)}}
                  </el-tag>
                  <el-tag type="info" class="packageUnitDesc" v-if="scope.row.bigPackageId===4">
                    {{scope.row.bigPackageId===4?'其他：':''}}
                    无
                  </el-tag>

                </template>
              </el-table-column>
              <el-table-column property="recoveryPrice" label="单价" width="90" sortable>
                <template slot-scope="scope">
                  <el-tag type="warning">
                    {{scope.row.recoveryPrice}}元 /{{UNIT_TYPE | optionObjectKV(scope.row.pricePackageUnitId)}}
                  </el-tag>
                </template>

              </el-table-column>
              <el-table-column property="recoveryNumber" label="数量" width="80" sortable>
                <template slot-scope="scope">
                  <el-tag type="warning">
                    {{scope.row.recoveryNumber}} {{UNIT_TYPE | optionObjectKV(scope.row.numberPackageUnitId)}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="recoveryWeight" label="重量" width="80" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag type="info">
                    {{scope.row.recoveryWeight}} {{UNIT_TYPE | optionObjectKV(scope.row.weightPackageUnitId)}}
                  </el-tag>
                </template>

              </el-table-column>
              <el-table-column property="packageLevel" label="支付金额" width="110" :show-overflow-tooltip="true" sortable>
                <template slot-scope="scope">
                  <el-tag type="danger" class="showCenter">
                    ￥ {{scope.row.recoveryMoney}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                class="optionList"
                fixed="right"
                label="操作"
                width="145">
                <!--使用slot-scope传递当前行参数-->
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="editCart(scope.row)">修改
                  </el-button>
                  <el-button
                    size="mini"
                    type="warning"
                    @click="delCart(scope.row)">删除
                  </el-button>
                  <!--<el-button
                    size="mini"
                    type="warning"
                    @click="del">排序
                  </el-button>-->
                </template>

              </el-table-column>
            </el-table>
          </div>
          <div class="formBottom">
            <el-row>
              <div class="pay">
                <div class="payLabel">回收支付总金额：</div>
                <span v-if="goodsCart.recoveryMoney>0" class="totalPrice">{{goodsCart.recoveryMoney}} 元</span>
                <span v-if="!goodsCart.recoveryMoney" class="defaultPrice">0.00 元</span>
              </div>
            </el-row>
            <el-row>
              <div class="pay">
                <div class="remarkLabel">备注：</div>
                <el-input id="stockDescription" class="inputCoreDesc" :rows="4"
                          type="textarea" maxlength="500" show-word-limit v-model="goodsCart.remark"
                          placeholder="请输入备注信息，最多500个字符"></el-input>
              </div>
            </el-row>
            <div class="bottomBtn">
              <el-button :disabled="goodsCart.num>0?false:true" class="storeInfoBtn" type="danger"
                         icon="el-icon-document-copy" @click="saveCart">去结算
              </el-button>
              <el-badge :value="goodsCart.num" class="item">
                <el-button :disabled="goodsCart.num>0?false:true" class="resetBtn" type="danger" plain
                           icon="el-icon-delete" @click="clearCart()">清空购物车
                </el-button>
              </el-badge>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="15">
        <div class="rightBody">

          <div class="row mainCard">
            <el-col :span="6" v-for="(cat, index) in goodsCategory" :key="index">
              <el-card class="goodCart" shadow="hover" @click.native="checkCat(cat)">
                <div class="imgBox">
                  <el-image :src="cat.imgPath" class="cardImage" fit="scale-down"></el-image>
                  <div class="checkNum" v-if="goodsCart.pesticideNum>0 && cat.cat===1">{{goodsCart.pesticideNum}} 件
                  </div>
                  <div class="checkNum" v-if="goodsCart.manureNum>0 && cat.cat===2">{{goodsCart.manureNum}} 件</div>
                  <div class="checkNum" v-if="goodsCart.filmNum>0 && cat.cat===3">{{goodsCart.filmNum}} 件</div>
                  <div class="checkNum" v-if="goodsCart.otherNum>0 && cat.cat===4">{{goodsCart.otherNum}} 件</div>
                </div>
                <div class="goodDesc">
                  <div class="bottom clearfix">
                    <el-button class="addCart" type="danger" icon="el-icon-shopping-bag-2">
                      {{cat.title}}
                    </el-button>
                  </div>
                </div>
              </el-card>
            </el-col>
          </div>

          <el-divider class="dividerLine"><i class="middleIcon el-icon-shopping-bag-1"></i><span>添加商品</span>
          </el-divider>

          <el-form :model="good" :rules="rules" ref="good" label-width="120px" class="demo-good store-info-form">

            <el-form-item class="labelTitle" label="回收单价" prop="recoveryPrice">
              <div class="unitLine">
                <el-input-number @change="priceChange" :precision="2" :step="0.1" :min="0.00" :max="999999.99"
                                 class="packageUnitLine" v-model="good.recoveryPrice"
                                 placeholder="请输入单价"></el-input-number>
                <div class="money">元</div>
                <div class="slash">/</div>
                <el-select class="unitSelectRight" v-model="good.pricePackageUnitId" placeholder="请选择"
                           @change="priceUnitChange">
                  <el-option
                    v-for="item in moneyUnits"
                    :key="item.packageUnitId"
                    :label="item.packageUnitName"
                    :value="item.packageUnitId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="labelTitle numberError" label="回收数量" prop="recoveryNumber">
              <div class="unitLine">
                <el-input-number @change="amountChange" :min="1" :max="9999" class="packageUnitLine"
                                 v-model="good.recoveryNumber" placeholder="请输入数量"></el-input-number>
                <el-select class="unitSelectRight" v-model="good.numberPackageUnitId" placeholder="请选择"
                           @change="numberUnitChange">
                  <el-option
                    v-for="item in amountUnits"
                    :key="item.packageUnitId"
                    :label="item.packageUnitName"
                    :value="item.packageUnitId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="labelTitle weightError" label="回收重量" prop="recoveryWeight">
              <div class="unitLine">
                <el-input-number @change="weightChange" :precision="1" :step="0.1" :min="0.1" :max="9999.99"
                                 class="packageUnitLine"
                                 v-model="good.recoveryWeight" placeholder="请输入重量"></el-input-number>
                <el-select class="unitSelectRight" v-model="good.weightPackageUnitId" placeholder="请选择"
                           @change="weightUnitChange">
                  <el-option
                    v-for="item in weightUnits"
                    :key="item.packageUnitId"
                    :label="item.packageUnitName"
                    :value="item.packageUnitId">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item class="labelTitle" label="回收支付金额" prop="recoveryMoney">
              <div class="unitLine">
                <el-input-number @change="payChange" :precision="2" :step="0.1" :max="999999999.99" :min="0.00"
                                 class="packageUnitLine" v-model="good.recoveryMoney"
                                 placeholder="请输入金额"></el-input-number>
                <div class="money">元</div>
              </div>
            </el-form-item>

            <el-form-item  class="labelTitle" label="包装分类" prop="goodPhone" v-if="!this.showOther">
              <el-collapse v-model="activeNames" >
                <el-collapse-item class="collapseItem" name="1" >
                  <template slot="title">
                    <i class="header-icon"></i>容器
                  </template>
                  <div v-for="(item,index) in vessels" :key="index">
                    <el-button class="unitBtn" @click="selectVessel(item)">{{item.packageName}}
                      <li class="el-icon-check"></li>
                    </el-button>
                  </div>

                </el-collapse-item>
                <el-collapse-item class="collapseItem " name="2" v-if="!this.showFilm">
                  <template slot="title">
                    <i class="header-icon"></i>材质
                  </template>
                  <div v-for="(item,index) in textures" :key="index">
                    <el-button class="textureBtn" @click="selectTexture(item)">{{item.packageName}}
                      <li class="el-icon-check"></li>
                    </el-button>
                  </div>
                </el-collapse-item>
                <el-collapse-item class="collapseItem" name="3" v-if="!this.showFilm">
                  <template slot="title">
                    <i class="header-icon"></i>大小
                  </template>
                  <div v-for="(item,index) in sizes" :key="index">
                    <el-button class="sizeBtn" @click="selectSize(item)">{{item.packageName}}
                      <li class="el-icon-check"></li>
                    </el-button>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-form-item>

            <el-form-item class="storeInfoSub">
              <el-button class="storeInfoBtn" type="danger" @click="save('good')">添加购物车</el-button>
              <el-button class="resetBtn" @click="resetForm('good')" v-if="this.type === 0">重置</el-button>
            </el-form-item>
          </el-form>

        </div>
      </el-col>
    </el-row>

    <div class="dialogs">
      <dialog-client-choose ref="dialogClientChoose" @getClients="getClients"></dialog-client-choose>
      <dialog-recycle-order ref="dialogRecycleOrder" @cartChange="cartChange"></dialog-recycle-order>
      <dialog-recycle-order-sure ref="dialogRecycleOrderSure" @saveFinish="saveFinish"></dialog-recycle-order-sure>
    </div>
  </div>
</template>

<script>
  import DialogClientChoose from '../../../components/DialogClientChoose'
  import DialogRecycleOrder from '../../../components/DialogRecycleOrder'
  import DialogRecycleOrderSure from '../../../components/DialogRecycleOrderSure'

  export default {
    name: 'RecycleOrder',
    components: {
      DialogClientChoose,
      DialogRecycleOrder,
      DialogRecycleOrderSure,
    },
    data () {
      //校验
      let priceValidate = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('请输入回收单价'))
        } else {
          callback()
        }
      }
      let numberValidate = (rule, value, callback) => {
        if (Tool.isEmpty(value) && Tool.isEmpty(this.good.recoveryWeight)) {
          callback(new Error('回收数量和回收重量至少填写一个！'))
        } else {
          callback()
        }
      }
      let weightValidate = (rule, value, callback) => {
        if (Tool.isEmpty(value) && Tool.isEmpty(this.good.recoveryNumber)) {
          callback(new Error('回收数量和回收重量至少填写一个！'))
        } else {
          callback()
        }
      }
      return {
        message: '回收开单页面',
        //type=1,进货，=2新增商品
        type: 0,
        //交回者
        clientId: '',
        inputVisible: false,
        //弹框
        centerDialogVisible: false,
        sureDialogVisible: false,
        //购物车
        pesticide: {
          bigPackageId: 1, //包装类型
          recoveryPrice: 0.00,  //单价
          pricePackageUnitId: 1, //单价单位
          recoveryNumber: 1, //数量
          numberPackageUnitId: 9, //数量单位
          recoveryWeight: 1, //重量
          weightPackageUnitId: 15, //重量单位
          recoveryMoney: 0.00, //支付金额
          vesselPackageId: 1,//容器id
          texturePackageId: 1,//材质id
          sizePackageId: 1,//大小id
        },//农药
        manure: {
          bigPackageId: 2, //包装类型
          recoveryPrice: 0.00,  //单价
          pricePackageUnitId: 1, //单价单位
          recoveryNumber: 1, //数量
          numberPackageUnitId: 9, //数量单位
          recoveryWeight: 1, //重量
          weightPackageUnitId: 15, //重量单位
          recoveryMoney: 0.00, //支付金额
          vesselPackageId: 1,//容器id
          texturePackageId: 1,//材质id
          sizePackageId: 1,//大小id
        },//肥料
        film: {
          bigPackageId: 3, //包装类型
          recoveryPrice: 0.00,  //单价
          pricePackageUnitId: 1, //单价单位
          recoveryNumber: 1, //数量
          numberPackageUnitId: 9, //数量单位
          recoveryWeight: 1, //重量
          weightPackageUnitId: 15, //重量单位
          recoveryMoney: 0.00, //支付金额
          vesselPackageId: 1,//容器id
        },//农膜
        other: {
          bigPackageId: 4, //包装类型
          recoveryPrice: 0.00,  //单价
          pricePackageUnitId: 1, //单价单位
          recoveryNumber: 1, //数量
          numberPackageUnitId: 9, //数量单位
          recoveryWeight: 1, //重量
          weightPackageUnitId: 15, //重量单位
          recoveryMoney: 0.00, //支付金额
        },//其他
        goodsCart: {
          recoveryMoney: 0.00,
          clientId: 0,
          remark: '',
          wasteRecoveryDetailList: [],
          //顶部包装分类计数
          pesticideNum: 0,
          manureNum: 0,
          filmNum: 0,
          otherNum: 0,
        },
        good: {},
        goodsCategory: [
          {
            cat: 1,
            title: '农药包装',
            imgPath: '/static/imgs/recycle/pesticide.png',

          },
          {
            cat: 2,
            title: '肥料包装',
            imgPath: '/static/imgs/recycle/manure.png',
          },
          {
            cat: 3,
            title: '农膜',
            imgPath: '/static/imgs/recycle/film.png',
          },
          {
            cat: 4,
            title: '其他包装',
            imgPath: '/static/imgs/recycle/other.png',
          }
        ],
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        showClearBtn: false,
        base_url: '',
        category: {},
        categoryId: '',
        //搜索
        searchInput: '',
        //动态下拉
        activeNames: ['1'],
        //包装单位
        moneyUnits: [
        ],
        amountUnits: [
        ],
        weightUnits: [
        ],
        //包装分类:1=农药，2=肥料，3=农膜
        parentId: 1,
        vessels: [],
        textures: [],
        sizes: [],
        //是否显示texture和size
        showFilm: false,
        //是否包装分类
        showOther: false,
        rules: {
          recoveryPrice: [
            {
              validator: priceValidate,
              trigger: 'blur'
            }
          ],
          recoveryNumber: [
            {
              validator: numberValidate,
              trigger: 'blur'
            }
          ],
          recoveryWeight: [
            {
              validator: weightValidate,
              trigger: 'blur'
            }
          ]
        },
        RECYCLE_CATEGORY: RECYCLE_CATEGORY,
        VESSEL_TYPE: VESSEL_TYPE,
        UNIT_TYPE: UNIT_TYPE,
        remarkFlag: false,
      }

    },
    mounted () {
      //应付金额
      this.goodsCart.recoveryMoney = 0.00
      this.goodsCart.remark = ''
      if (Tool.isNotEmpty(SessionStorage.get(RECYCLE_CART))) {
        this.goodsCart = SessionStorage.get(RECYCLE_CART)
      }
      let currentWidth = $(window).width()
      this.changeWidth(currentWidth)
      //this.base_url=this.BASE_URL;
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getPackageUnits()
      }

      //获取缓存中上次选中的包装类型
      if (Tool.isNotEmpty(SessionStorage.get(RECYCLE_CAT))) {
        this.category = SessionStorage.get(RECYCLE_CAT)
        this.checkCat(this.category)
      } else {
        this.checkCat(this.goodsCategory[0])
      }
      if (Tool.isNotEmpty(this.$route.params)) {
        this.type = this.$route.params.type
        if (this.type === 3) {
          let item = this.$route.params.item

          this.goodsCart = $.extend({}, this.goodsCart)
          this.goodsCart.clientId = []
          this.goodsCart.clientId.push(item.clientId)
          this.goodsCart.clientName = []
          this.goodsCart.clientName.push(item.clientName)
          this.clientIds = this.goodsCart.clientId
          this.clientNames = this.goodsCart.clientName
        }
      }
      if (this.goodsCart.clientName === undefined) {
        this.showChooseDialog()
      }
      this.navChange()
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let resize = 12
        let size = resize
        let keywords = this.searchInput
        let clientId = ''
        if (Tool.isNotEmpty(this.clientIds)) {
          clientId = this.clientIds.toString()
        }

        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectBySale?current='
          + current + '&size=' + size + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&clientId=' + clientId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.goods = resp.data.records

          })
      },
      reSearch (data) {
        this.getSearch(1, data)
      },

      //选择包装类型
      checkCat (good) {
        /*good.totalPrice=999.99;
        this.goodsCart.details.push(good)
        this.goodsCart.num = this.goodsCart.details.length*/

        let catBtn = $('.addCart')
        this.removeCats()
        this.textures = []
        this.sizes = []
        if (good.cat === 1) {
          this.removeBtn()
          Tool.addClass(catBtn[0], 'checkCat')
          this.good = this.pesticide
          this.parentId = 1
          this.showFilm = false
          this.showOther = false
          this.activeNames = ['1']
          SessionStorage.set(RECYCLE_CAT, this.goodsCategory[0])
          this.getVesselCategory()
        }
        if (good.cat === 2) {
          this.removeBtn()
          Tool.addClass(catBtn[1], 'checkCat')
          this.good = this.manure
          this.parentId = 2
          this.showFilm = false
          this.showOther = false
          this.activeNames = ['1']
          SessionStorage.set(RECYCLE_CAT, this.goodsCategory[1])
          this.getVesselCategory()
        }
        if (good.cat === 3) {
          this.removeBtn()
          Tool.addClass(catBtn[2], 'checkCat')
          this.good = this.film
          this.parentId = 3
          this.showFilm = true
          this.showOther = false
          this.activeNames = ['1']
          SessionStorage.set(RECYCLE_CAT, this.goodsCategory[2])
          this.getVesselCategory()
        }
        if (good.cat === 4) {
          this.removeBtn()
          Tool.addClass(catBtn[3], 'checkCat')
          this.good = this.other
          this.showOther = true
          SessionStorage.set(RECYCLE_CAT, this.goodsCategory[3])
        }
        this.good = $.extend({}, this.good)
      },
      //选择包装分类
      selectVessel (item) {
        this.parentId = item.packageId
        this.good.vesselPackageId = item.packageId
        this.removeCats()
        let unitBtn = $('.unitBtn')
        for (let i = 0; i < unitBtn.length; i++) {
          Tool.removeClass(unitBtn[i], 'activeBtn')
          unitBtn[i].childNodes[2].childNodes[1].style.display = 'none'
          if (unitBtn[i].innerText.trim() === item.packageName.trim()) {
            this.good.vesselPackageName = item.packageName.trim()
            Tool.addClass(unitBtn[i], 'activeBtn')
            unitBtn[i].childNodes[2].childNodes[1].style.display = 'block'
          }
        }
        if (this.good.bigPackageId !== 3) {
          this.activeNames.push('2')
          this.getTextureCategory()
        }

      },
      selectTexture (item) {
        this.parentId = item.packageId
        this.good.texturePackageId = item.packageId
        let textureBtn = $('.textureBtn')
        for (let i = 0; i < textureBtn.length; i++) {
          Tool.removeClass(textureBtn[i], 'activeBtn')
          textureBtn[i].childNodes[2].childNodes[1].style.display = 'none'
          if (textureBtn[i].innerText.trim() === item.packageName.trim()) {
            Tool.addClass(textureBtn[i], 'activeBtn')
            textureBtn[i].childNodes[2].childNodes[1].style.display = 'block'
          }
        }
        this.activeNames.push('3')
        this.getSizeCategory()
      },
      selectSize (item) {
        this.parentId = item.packageId
        this.good.sizePackageId = item.packageId
        let sizeBtn = $('.sizeBtn')
        for (let i = 0; i < sizeBtn.length; i++) {
          Tool.removeClass(sizeBtn[i], 'activeBtn')
          sizeBtn[i].childNodes[2].childNodes[1].style.display = 'none'
          if (sizeBtn[i].innerText.trim() === item.packageName.trim()) {
            Tool.addClass(sizeBtn[i], 'activeBtn')
            sizeBtn[i].childNodes[2].childNodes[1].style.display = 'block'
          }
        }
      },
      removeBtn () {
        let catBtn = $('.addCart')
        for (let i = 0; i < catBtn.length; i++) {
          Tool.removeClass(catBtn[i], 'checkCat')
        }
      },
      removeCats () {
        let unitBtn = $('.unitBtn')
        let textureBtn = $('.textureBtn')
        let sizeBtn = $('.sizeBtn')
        let elIconCheck = $('.el-icon-check')
        for (let i = 0; i < unitBtn.length; i++) {
          Tool.removeClass(unitBtn[i], 'activeBtn')
        }
        for (let i = 0; i < textureBtn.length; i++) {
          Tool.removeClass(textureBtn[i], 'activeBtn')
        }
        for (let i = 0; i < sizeBtn.length; i++) {
          Tool.removeClass(sizeBtn[i], 'activeBtn')
        }
        for (let i = 0; i < elIconCheck.length; i++) {
          elIconCheck[i].style.display = 'none'
        }
      },
      //选择交回者
      handleClose (tag) {
        this.clientIds.splice(this.clientIds.indexOf(tag), 1)
      },
      showChooseDialog () {
        //this.inputVisible = true;
        this.$nextTick(() => {
          let clientIds = this.goodsCart.clientId
          let type = 3
          let flag = true
          this.$refs.dialogClientChoose.getClientIds(clientIds, type, flag)
        })
      },
      getClients (clientIds, clientNames) {
        this.clientNames = clientNames
        this.clientIds = clientIds
        this.goodsCart.clientId = this.clientIds
        this.goodsCart.clientName = this.clientNames
        this.$forceUpdate()
        SessionStorage.set(RECYCLE_CART, this.goodsCart)
      },
      //购物车
      editCart (item) {
        this.activeNames = ['1']
        this.good.vesselPackageId = 1
        this.good.texturePackageId = 1
        this.good.sizePackageId = 1
        this.getVesselCategory()
        this.$nextTick(() => {
          this.$refs.dialogRecycleOrder.openSaleGood(item, this.goodsCart.clientName)
        })
      },

      cartChange (item) {
        let _this=this
        this.goodsCart.recoveryMoney = 0.00
        for (let i = 0; i < this.goodsCart.wasteRecoveryDetailList.length; i++) {
          if (this.goodsCart.wasteRecoveryDetailList[i].uuid === item.uuid) {
            this.goodsCart.wasteRecoveryDetailList.splice(i,1,item)
          }
          this.goodsCart.recoveryMoney += this.goodsCart.wasteRecoveryDetailList[i].recoveryMoney
        }
        SessionStorage.set(RECYCLE_CART, this.goodsCart)
        const loading = this.$loading({
          lock: true,
          text: '',
          spinner: 'el-icon-loading',
          background: 'rgba(255,255, 255, .8)'
        });
        setTimeout(()=>{
          _this.refreshPage();
        },0)
        setTimeout(() => {
          loading.close();
        }, 5);
      },
      refreshPage(){
        this.$router.go(0)
      },
      saveFinish (flag) {
        this.goodsCart = {
          recoveryMoney: 0.00,
          clientId: 0,
          remark: '',
          wasteRecoveryDetailList: [],
          //顶部包装分类计数
          pesticideNum: 0,
          manureNum: 0,
          filmNum: 0,
          otherNum: 0,
        }
      },
      delCart (item) {
        let _this = this
        if (item.bigPackageId === 1) {
          item.packageName = '农药包装'
        }
        if (item.bigPackageId === 2) {
          item.packageName = '肥料包装'
        }
        if (item.bigPackageId === 3) {
          item.packageName = '农膜'
        }
        if (item.bigPackageId === 4) {
          item.packageName = '其他包装'
        }
        this.$toast.showConfirm(item.packageName, function () {
            _this.goodsCart.recoveryMoney = 0.00
            for (let i = 0; i < _this.goodsCart.wasteRecoveryDetailList.length; i++) {
              if (_this.goodsCart.wasteRecoveryDetailList[i].uuid === item.uuid) {
                _this.goodsCart.wasteRecoveryDetailList.splice(i, 1)
                _this.goodsCart.num = _this.goodsCart.wasteRecoveryDetailList.length
                if (item.bigPackageId === 1) {
                  _this.goodsCart.pesticideNum -= 1
                  if (_this.goodsCart.pesticideNum <= 0) {
                    _this.goodsCart.pesticideNum = 0
                  }
                }
                if (item.bigPackageId === 2) {
                  _this.goodsCart.manureNum -= 1
                  if (_this.goodsCart.manureNum <= 0) {
                    _this.goodsCart.manureNum = 0
                  }
                }
                if (item.bigPackageId === 3) {
                  _this.goodsCart.filmNum -= 1
                  if (_this.goodsCart.filmNum <= 0) {
                    _this.goodsCart.filmNum = 0
                  }
                }
                if (item.bigPackageId === 4) {
                  _this.goodsCart.otherNum -= 1
                  if (_this.goodsCart.otherNum <= 0) {
                    _this.goodsCart.otherNum = 0
                  }
                }

              }
              _this.goodsCart.recoveryMoney += _this.goodsCart.wasteRecoveryDetailList[i].recoveryMoney
              SessionStorage.set(RECYCLE_CART, _this.goodsCart)
            }
          }
        )

      },
      clearCart () {
        let _this = this
        this.$toast.showConfirm('清空购物车商品', function () {
            _this.goodsCart.wasteRecoveryDetailList = []
            _this.goodsCart.num = _this.goodsCart.wasteRecoveryDetailList.length
            _this.goodsCart.pesticideNum = 0
            _this.goodsCart.manureNum = 0
            _this.goodsCart.filmNum = 0
            _this.goodsCart.otherNum = 0
            SessionStorage.remove(RECYCLE_CART)
            _this.goodsCart.recoveryMoney = 0.00
            SessionStorage.set(RECYCLE_CART, _this.goodsCart)
            _this.$forceUpdate()
          }
        )
      },
      saveCart () {
        this.goodsCart.clientId = this.goodsCart.clientId.toString()
        let type = 2
        this.$nextTick(() => {
          this.$refs.dialogRecycleOrderSure.sureOrders(this.goodsCart, type)
        })
      },
      toNewGood () {
        let type = 3
        this.$router.push({
          name: '/goodsManagerEdit',
          params: { type }
        })
      },
      resetForm (good) {
        if (!this.goodsCart.clientName) {
          this.showChooseDialog()
        } else {
          this.good.recoveryPrice = 0.00
          this.good.recoveryNumber = 1
          this.good.recoveryWeight = 1
          this.good.recoveryMoney = 0.00

          this.good.vesselPackageId = 1
          this.good.texturePackageId = 1
          this.good.sizePackageId = 1
          this.removeCats()
        }

      },
      save (good) {
        let _this = this
        _this.good = $.extend({}, _this.good)
        if (!_this.goodsCart.clientName) {
          _this.showChooseDialog()
        } else if (_this.good.vesselPackageId === 1 && _this.good.bigPackageId !== 4) {
          _this.$notify({
            title: '请选择包装分类',
            message: '容器不能为空',
            position: 'bottom-right',
            type: 'warning'
          })

        } else if (_this.good.texturePackageId === 1 && _this.good.bigPackageId !== 4 && _this.good.bigPackageId !== 3) {
          _this.$notify({
            title: '请选择包装分类',
            message: '材质不能为空',
            position: 'bottom-right',
            type: 'warning'
          })

        } else if (_this.good.sizePackageId === 1 && _this.good.bigPackageId !== 4 && _this.good.bigPackageId !== 3) {
          _this.$notify({
            title: '请选择包装分类',
            message: '大小不能为空',
            position: 'middle-right',
            type: 'warning'
          })

        } else {
          _this.$refs[good].validate((valid) => {
              if (valid) {
                _this.good.uuid = Tool.get8UUID(8)
                if(_this.good.recoveryMoney===undefined){
                  _this.good.recoveryMoney=0.00
                }
                _this.goodsCart.wasteRecoveryDetailList.push(_this.good)
                _this.goodsCart.num = _this.goodsCart.wasteRecoveryDetailList.length
                _this.goodsCart.recoveryMoney = 0.00
                for (let i = 0; i < _this.goodsCart.wasteRecoveryDetailList.length; i++) {
                  _this.goodsCart.recoveryMoney += _this.goodsCart.wasteRecoveryDetailList[i].recoveryMoney
                }

                if (_this.good.bigPackageId === 1) {
                  _this.goodsCart.pesticideNum += 1
                  _this.good = _this.pesticide
                } else if (_this.good.bigPackageId === 2) {
                  _this.goodsCart.manureNum += 1
                  _this.good = _this.manure
                } else if (_this.good.bigPackageId === 3) {
                  _this.goodsCart.filmNum += 1
                  _this.good = _this.film
                } else if (_this.good.bigPackageId === 4) {
                  _this.goodsCart.otherNum += 1
                  _this.good = _this.other
                }

                SessionStorage.set(RECYCLE_CART, _this.goodsCart)
                _this.good.recoveryPrice= 0.00  //单价
                _this.good.pricePackageUnitId= 1 //单价单位
                _this.good.recoveryNumber= 1 //数量
                _this.good.numberPackageUnitId= 9 //数量单位
                _this.good.recoveryWeight= 1 //重量
                _this.good.weightPackageUnitId= 15 //重量单位
                _this.good.recoveryMoney= 0.00 //支付金额
                this.removeCats()
                _this.activeNames=[];
                _this.activeNames.push('1');
                _this.textures=[];
                _this.sizes=[];
              }
            }
          )
        }

      },

      unitChange (val) {
        //console.log('改变的unit：', val)
      },
      //单位监听
      priceUnitChange (e) {
        //console.log('价格单位变化：', e)
        this.recoveryMoneyHandler()
      },
      numberUnitChange (e) {
        //console.log('数量单位变化：', e)
        this.recoveryMoneyHandler()
      },
      weightUnitChange (e) {
        //console.log('重量单位变化：', e)
        this.recoveryMoneyHandler()
      },
      //获取回收单位
      getPackageUnits () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/packageunit/selectAll')
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                resp.data.map((item) => {
                  if (item.packageUnitType === 1) {
                    this.moneyUnits.push(item)
                  }
                  if (item.packageUnitType === 2) {
                    this.amountUnits.push(item)
                  }
                  if (item.packageUnitType === 3) {
                    this.weightUnits.push(item)
                  }
                })
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //获取包装分类
      getVesselCategory () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + this.parentId)
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                this.vessels = resp.data
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      getTextureCategory () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + this.parentId)
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                this.textures = resp.data
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      getSizeCategory () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + this.parentId)
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                this.sizes = resp.data
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //输入变动
      priceChange (e) {
        this.recoveryMoneyHandler();
      },
      amountChange (e) {
        if (Tool.isEmpty(e)) {
          let numberError = document.getElementsByClassName('numberError')[0]
          let weightError = document.getElementsByClassName('weightError')[0]
          setTimeout(function () {
            let item = document.getElementsByClassName('el-form-item')
            for (let i = 0; i < item.length; i++) {
              if (item[i].className.indexOf('is-error') !== -1) {
                if (weightError.className.indexOf('is-success') !== -1) {
                  Tool.removeClass(weightError, 'is-success')
                  Tool.addClass(weightError, 'is-error')
                }
              }
            }
          })
        } else {
          setTimeout(function () {
            let numberError = document.getElementsByClassName('numberError')[0]
            let weightError = document.getElementsByClassName('weightError')[0]
            if (weightError.className.indexOf('is-error') !== -1) {
              Tool.removeClass(weightError, 'is-error')
              Tool.addClass(weightError, 'is-success')
              Tool.removeClass(numberError, 'is-error')
              Tool.addClass(numberError, 'is-success')
              if(weightError.childNodes[1].childNodes[1]!==undefined){
                //weightError.childNodes[1].removeChild(weightError.childNodes[1].childNodes[1])
                weightError.childNodes[1].childNodes[1].style.display="none"
              }
            }
          })

        }

        this.recoveryMoneyHandler();
      },
      weightChange (e) {
        if (Tool.isEmpty(e)) {
          let numberError = document.getElementsByClassName('numberError')[0]
          let weightError = document.getElementsByClassName('weightError')[0]
          setTimeout(function () {
            let item = document.getElementsByClassName('el-form-item')
            for (let i = 0; i < item.length; i++) {
              if (item[i].className.indexOf('is-error') !== -1) {
                if (numberError.className.indexOf('is-success') !== -1) {
                  Tool.removeClass(numberError, 'is-success')
                  Tool.addClass(numberError, 'is-error')
                }
              }
            }
          })
        } else {
          setTimeout(function () {
            let numberError = document.getElementsByClassName('numberError')[0]
            let weightError = document.getElementsByClassName('weightError')[0]
            if (weightError.className.indexOf('is-error') !== -1 || numberError.className.indexOf('is-error') !== -1) {
              Tool.removeClass(weightError, 'is-error')
              Tool.addClass(weightError, 'is-success')
              Tool.removeClass(numberError, 'is-error')
              Tool.addClass(numberError, 'is-success')
              if(numberError.childNodes[1].childNodes[1]!==undefined){
                //numberError.childNodes[1].removeChild(numberError.childNodes[1].childNodes[1])
                numberError.childNodes[1].childNodes[1].style.display="none"
              }
            }
          })
        }

        this.recoveryMoneyHandler();

      },
      payChange (e) {
        //console.log('支付金额变动：', e)
      },

      recoveryMoneyHandler(){
        let priceUnit=JSON.parse(JSON.stringify(this.moneyUnits));
        let numberUnit=JSON.parse(JSON.stringify(this.amountUnits));
        let weightUnit=JSON.parse(JSON.stringify(this.weightUnits));
        let priceName='';
        let numberName='';
        let weightName='';
        for(let i=0;i<priceUnit.length;i++){
          let unit= JSON.parse(JSON.stringify(priceUnit[i]))
          if(this.good.pricePackageUnitId===unit.packageUnitId){
            priceName=unit.packageUnitName;
          }
        }
        //数量单位判断
        for(let i=0;i<numberUnit.length;i++){
          let unit= JSON.parse(JSON.stringify(numberUnit[i]))
          if(this.good.numberPackageUnitId===unit.packageUnitId){
            numberName=unit.packageUnitName;
          }
        }
        //重量单位判断
        for(let i=0;i<weightUnit.length;i++){
          let unit= JSON.parse(JSON.stringify(weightUnit[i]))
          if(this.good.weightPackageUnitId===unit.packageUnitId){
            weightName=unit.packageUnitName;
          }
        }
        if(priceName===numberName){
          this.good.recoveryMoney = this.good.recoveryPrice * this.good.recoveryNumber
        }
        if(priceName===weightName){
          this.good.recoveryMoney = this.good.recoveryPrice * this.good.recoveryWeight
        }
      },

      vesselClick(){
        //console.log("容器点击了：")
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      //视口
      changeWidth (width) {
        let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
        let clearCart = document.getElementsByClassName('clearCart')[0]
        if (width >= 1619) {
          inputCoreDesc.style.width = '400px'
        }
        if (this.showClearBtn) {
          if (width > 1669) {
            clearCart.style.marginRight = '-100px'
          }
          if (width <= 1669) {
            clearCart.style.marginRight = '0px'
          }
          if (width < 1640) {
            clearCart.style.marginRight = '50px'
          }
          if (width < 1523) {
            clearCart.style.marginRight = '100px'
          }
          if (width < 1370) {
            clearCart.style.marginRight = '160px'
          }

        }

        if (width < 1619) {
          inputCoreDesc.style.width = '280px'
        }
        if (width < 1310) {
          inputCoreDesc.style.width = '250px'
        }
        if (width < 1240) {
          inputCoreDesc.style.width = '200px'
        }
        this.triggerResize()
      },
      triggerResize () {
        $(window).on('resize', function () {
          let width = $(window).width()
          let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
          let clearCart = document.getElementsByClassName('clearCart')[0]
          if (width >= 1619) {
            inputCoreDesc.style.width = '400px'
          }

          if (this.showClearBtn) {
            if (width > 1669) {
              clearCart.style.marginRight = '-100px'
            }
            if (width <= 1669) {
              clearCart.style.marginRight = '0px'
            }
            if (width < 1640) {
              clearCart.style.marginRight = '50px'
            }
            if (width < 1523) {
              clearCart.style.marginRight = '100px'
            }
            if (width < 1370) {
              clearCart.style.marginRight = '160px'
            }
          }

          if (width < 1619) {
            inputCoreDesc.style.width = '280px'
          }
          if (width < 1310) {
            inputCoreDesc.style.width = '250px'
          }
          if (width < 1240) {
            inputCoreDesc.style.width = '200px'
          }
        }).trigger('resize')
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  .mainBody {
    padding: 10px;
  }

  .rowForm {
    margin-bottom: 0px;
  }

  .leftBody, .rightBody {
    background: #fafafa;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: dashed 1px #faecd8;
    min-height: 840px;
  }

  .topNav {
    float: left;
    margin-left: 10px;
    max-width: 500px;
  }

  /*交回者*/
  .keyTags {
    float: left;
    margin-right: 8px;
    margin-bottom: 10px;
    line-height: 40px;
    height: 40px;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .keyTagsInput {
    float: left;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  .clearCart {
    float: right;
    right: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  /*购物车*/
  /deep/ .el-table {
    margin-bottom: 15px;
  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  .pay {
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
  }

  .pay .payLabel {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    font-size: 16px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .priceInput {
    float: left;
  }

  .totalPrice {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }

  .defaultPrice {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #939495;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
    margin-left: -15px;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 3px;
  }

  /*备注*/
  .pay .remarkLabel {
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;

  }

  .inputCoreDesc {
    float: left;
    width: 400px;
    margin-left: 28px;
  }

  .bottomBtn {
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 180px;
    margin-right: 10px;
  }

  .resetBtn {
    width: 180px;
    color:#F56C6C !important;
    background:#fef0f0 !important;
    border-color:#fbc4c4 !important;
  }
  .resetBtn:hover {
    width: 180px;
    color:#FFF !important;
    background:#F56C6C !important;
    border-color:#F56C6C !important;
  }

  .tipTag {
    position: relative;
  }

  /*商品列表*/
  .el-col-6 {
    width: 24.7%;
  }

  .mainCard {
    margin-left: 5px;
    margin-bottom: 10px;
  }

  /deep/ .el-card__body {
    padding: 5px;
  }

  .goodCart {
    max-width: 240px;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .cardImage {
    /*width: 150px;
    height: 150px;*/
    /*height: 145px;*/
    width: 100%;
    border-radius: 10px;
    padding: 5px;
    position: relative;
  }

  .imgBox {
    position: relative;
  }

  .checkNum {
    position: absolute;
    width: 40px;
    height: 20px;
    border-top-left-radius: 5px;
    background-color: #e92322;
    left: 5px;
    top: 5px;
    color: #ffffff;
  }

  .addCart {
    padding: 6px 12px;
    margin-bottom: 5px;
    width: 100%;
  }

  .addCart:hover, .addCart:focus {
    background-color: #409EFF;
    border-color: #409EFF;
  }

  /*选中分类按钮*/
  .checkCat {
    background-color: #409EFF;
    border-color: #409EFF;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  .dividerLine {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .middleIcon {
    margin-right: 10px;
  }

  /deep/ .el-divider__text {
    background-color: #67C23A;
    color: #ffffff;
    border-radius: 5px;
    padding: 5px 20px;
  }

  .labelTitle {
    margin-bottom: 15px !important;
  }

  /deep/ .label {
    margin-bottom: 0;
  }

  /*单位*/
  .unitLine {
    display: inline-block;
    width: 100%;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 10px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .money {
    float: left;
    font-size: 18px;
    line-height: 40px;
    margin-right: 5px;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  /*包装容器*/
  /deep/ .el-collapse {
    max-width: 800px;
    border-radius: 10px;
    border-bottom: 10px;
    border-top: unset;
    margin-right: 20px;
  }

  /deep/ .el-collapse-item__wrap {
    background-color: unset;
    border-bottom: unset;
  }

  /deep/ .el-collapse-item__header {
    border: 1px solid #EBEEF5;
    border-radius: 10px;
    font-size: 16px;
    height: 40px;
    margin-bottom: -3px;
  }

  /deep/ .el-collapse-item__header.is-active {
    border-bottom: 1px solid #EBEEF5;
  }

  /deep/ .el-collapse-item__content {
    /*padding-bottom: 5px;*/
  }

  /deep/ .el-collapse-item {
  }

  /deep/ .el-collapse-item__arrow {
    font-weight: 700;
    float: left;
    margin: 0 10px;
  }

  .header-icon {
    background: #409EFF;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 20px;
    margin-right: 20px;
  }

  .collapseItem {
    margin-bottom: 5px;
  }

  .unitBtn {
    margin-top: 10px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 80px;
    padding: 10px;
  }

  .textureBtn {
    margin-top: 10px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 80px;
    padding: 10px;
  }

  .sizeBtn {
    margin-top: 10px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 80px;
    padding: 10px;
  }

  .el-icon-check {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #409EFF;
    color: #409EFF;
    border-radius: 50%;
    padding: 3px;
    left: 65px;
    top: -5px;
    display: none;
  }

  .activeBtn {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }

  .packageUnitDesc {
    border: unset !important;
    background-color: unset !important;
    padding: unset !important;
  }

  /*错误提示*/
  /deep/ .el-form-item__error {
    margin-top: -5px;
    float: left;
  }
</style>
