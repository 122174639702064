<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title" v-if="!recycleType">选择所属客户</div>
        <div class="title" v-if="recycleType">请选择交回者</div>
      </div>
      <div class="orderNav">
        <div class="navLeft">
          <el-tag class="showCheck" v-if="showPage">{{recycleType?'当前交回者':'当前客户'}} <span>{{checkClientIds.length>0?checkClientIds.length:0}}</span>
            个
            <span v-if="checkClientIds.length>0">{{strClients}}</span></el-tag>

          <el-radio-group v-model="isPesticide" class="searchBtnReturn" @change="checkMode"
                          v-show="(!isHLJ || (isHLJ && isDN)) && isHEBEI && !recycleType && (type===4 || type===2)">
            <el-radio :label="2" border class="checkMode">销货开单</el-radio>
            <el-radio :label="1" border class="checkMode">处方开单</el-radio>
          </el-radio-group>
          <el-radio-group v-model="hljMode" class="searchBtnReturn" @change="checkMode"
                          v-show="isHLJ && !isDN && !recycleType">
            <el-radio :label="1" border class="checkMode">{{type===5?'农药退货':'农药开单'}}</el-radio>
            <el-radio :label="2" border class="checkMode">{{type===5?'其他商品退货':'其他商品开单'}}</el-radio>
          </el-radio-group>
          <el-button v-if="checkClientIds.length>0 && showPage" class="confirmCheck" type="primary"
                     :disabled="(canCheck || canCheckPro) && isPesticide===1"
                     @click="addClients">确认选中
          </el-button>
          <el-button class="searchBtnReturn" type="warning" @click="returnClients" icon="el-icon-d-arrow-left"
                     v-if="showAdd">返回交回者列表
          </el-button>

        </div>
        <div class="navRight">
          <el-input class="searchInput"
                    placeholder="客户名称/联系人/手机号"
                    v-model="searchInput"
                    :blur="searchInputChange(searchInput)"
                    suffix-icon="el-icon-search"
                    :clearable="true">
          </el-input>
          <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
          <el-button v-if="getAuthorityMenu('300101') || getAuthorityMenu('400101')" class="newBtnReturn" type="danger"
                     @click="toNewClient">{{recycleType?'新增交回者':'新增客户'}}
          </el-button>
        </div>

      </div>
      <div class="pageList" v-if="!showAdd">
        <div class="dialogBody">

          <div class="mainForm">
            <el-table
              ref="multipleTable"
              tooltip-effect="dark"
              :data="clients"
              border
              height="540px"
              width="100%"
              highlight-current-row
              class="previous-row mainTable"
              @current-change="handleCurrentChange"
              @selection-change="handleSelectionChange"
              :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
            >
              <el-table-column type="selection" class="indexCenter" width="35" property="clientId">
              </el-table-column>
              <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
              <el-table-column property="clientName" :label="recycleType?'交回者名称':'店铺名称'" sortable>
                <template slot-scope="scope">
                  <el-tooltip class="toolTipWidthPlugin" placement="bottom"
                              @mouseenter.native="remarkEnter(scope.row.clientName)" :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{scope.row.clientName===''?'-':scope.row.clientName}}
                    </div>
                    <span class="showTipLine">{{scope.row.clientName===''?'-':scope.row.clientName}}</span>
                  </el-tooltip>
                  <span class="clientIdShow">{{scope.row.clientId}}</span>
                </template>

              </el-table-column>
              <el-table-column property="clientPerson" label="客户名称" sortable>
                <template slot-scope="scope">
                  <el-tooltip placement="bottom" @mouseenter.native="remarkEnter(scope.row.clientPerson)"
                              :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{scope.row.clientPerson===''?'-':scope.row.clientPerson}}
                    </div>
                    <span class="showTipLine">{{scope.row.clientPerson===''?'-':scope.row.clientPerson}}</span>
                  </el-tooltip>

                </template>
              </el-table-column>
              <el-table-column property="idCertified" label="实名认证" width="190">
                <template slot="header">
                  <span>实名认证</span>
                  <el-tooltip class="showTip" effect="light" content="限制级店铺进行处方开单时，所选客户必须实名认证。" placement="right">
                    <i class="showTipIcon el-icon-question"></i>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <el-tag :type="scope.row.idCertified === true ? 'danger':'info'" class="showCenter"
                          id="identityStatus" @click="idCertification(scope.row)">
                    <el-image class="identityImg" v-show="scope.row.idCertified" :src="imgUrls.idRed">
                    </el-image>
                    <el-image class="identityImg" v-show="!scope.row.idCertified" :src="imgUrls.idGrey"></el-image>
                    <div class="identityText">{{ID_CERTIFIED | optionObjectKV(scope.row.idCertified)}}</div>
                    <i class="statusIcon" :class="scope.row.idCertified?'el-icon-success':''"></i>
                  </el-tag>
                  <!--<div class="showTip" v-show="canCheck && !scope.row.idCertified && scope.row.isChecked">
                    <div class="tipContent">
                      <div class="tipTitle">当前客户尚未完成实名认证，无法进行农药处方开单，请点击这里进行实名认证</div>
                    </div>
                  </div>-->
                </template>
              </el-table-column>
              <el-table-column property="clientPhone" label="手机号" sortable>
                <template slot-scope="scope">
                  <span class="showCenter">{{scope.row.clientPhone===''?'-':scope.row.clientPhone}}</span>
                </template>
              </el-table-column>
              <el-table-column property="status" label="状态" width="100" sortable>
                <template slot-scope="scope">
                  <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                          id="status">{{SUPPLIER_STATUS | optionObjectKV(scope.row.status)}}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column property="remark" label="备注" width="120px">
                <template slot-scope="scope">
                  <el-tooltip class="toolTipWidthPlugin" placement="bottom"
                              @mouseenter.native="remarkEnter(scope.row.remark)" :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{scope.row.remark===''?'-':scope.row.remark}}
                    </div>
                    <span class="showRemarkLine">{{scope.row.remark===''?'-':scope.row.remark}}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="pageNav" aria-label="分页">

          <div class="pageChoose">
            <el-button type="danger" plain :disabled="page===1" @click="selectPage(1)">首页</el-button>
            <el-button type="danger" icon="el-icon-caret-left" circle class="firstPage"
                       :disabled="page===1" @click="selectPage(page-1)"></el-button>
            <el-button class="pageNumber" :class="p===0?'pageNumbers':''" type="danger" plain v-for="p in pages"
                       :key="p"
                       @click="selectPage(p+1)">{{p+1}}
            </el-button>
            <el-button type="danger" icon="el-icon-caret-right" circle class="lastPage"
                       :disabled="page===pageTotal" @click="selectPage(page+1)"></el-button>
            <el-button type="danger" plain :disabled="page===pageTotal" @click="selectPage(pageTotal)">尾页</el-button>
            <el-button class="totalCount" type="danger" plain>共{{totalSize}}条</el-button>
            <span>前往</span>
            <el-input class="goPageNum" v-model="input" placeholder="1" @change="inputValue"></el-input>
            <el-button v-model="input" id="goBtn" type="danger" plain @click="selectPage(input)">GO</el-button>
          </div>

        </div>
      </div>

      <div class="addDisposeForm" v-if="showAdd">
        <div class="mainForm">
          <el-form :model="client" :rules="rules" ref="client" label-width="120px" class="demo-client store-info-form">
            <el-form-item class="labelTitle" label="交回者名称" prop="clientName"
                          v-if="type === 0 || type===2 || type===3 || type===4">
              <!-- 联想下拉列表输入框 -->
              <el-autocomplete
                v-model="client.clientName"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入交回者名称"
                @select="handleSelect"
                @input="loadAll"
                class="inputLine"
                style="width: 371px;"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item class="labelTitle" :label="type===3?'交回者名称':'客户名称'" prop="clientName" v-if="this.type === 1">
              <el-input class="inputLine" v-model="client.clientName" placeholder="请输入交回者名称" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="联系人" prop="clientPerson">
              <el-input class="inputLine" v-model="client.clientPerson" placeholder="请输入联系人" maxlength="20"
                        @blur="checkClientPersonIsRepeat"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="手机号" prop="clientPhone">
              <el-input class="inputLine" v-model="client.clientPhone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="状态" prop="status" v-if="this.type === 1">
              <el-radio-group class="checkGroup" v-model="client.status" @change="radioChange">
                <el-radio-button class="statusOpen" :label="SUPPLIER_STATUS.OPEN.key">启用</el-radio-button>
                <el-radio-button class="statusStop" :label="SUPPLIER_STATUS.STOP.key">禁用</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <div v-if="showStopInfo" class="showStopInfo">
              <div class="stopInfo">提示：客户已停用，停用的客户将不会出现在开单时的“选择客户”页面。</div>
              <div class="stopInfo">可使用“客户管理”页面显示出已停用的客户。</div>
            </div>
            <el-form-item class="labelTitle" label="备注" prop="remark">
              <el-input id="stockDescription" class="inputCoreDesc" :rows="6"
                        type="textarea" maxlength="500" show-word-limit v-model="client.remark"
                        placeholder="请输入备注信息，最多500个字符"></el-input>
            </el-form-item>

            <el-form-item class="storeInfoSub">
              <el-button class="storeInfoBtn" type="danger" @click="save('client')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('client')" v-if="this.type === 0">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>
  import { hasClass } from 'element-ui/src/utils/dom'

  export default {
    name: 'DialogPlayer',
    props: {
      canCheckPro: {
        type: Boolean,
        default: false
      },
    },
    data () {
      let validate_clientName = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value && getLength(value) > 50) {
          callback(new Error(this.type === 3 ? '交回者名称最多不能超过50个字符' : '客户名称最多不能超过50个字符'))
        } else {
          callback()
        }
      }
      let validate_clientPerson = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (!value) {
          callback(new Error('请填写联系人'))
        } else if (getLength(value) > 20) {
          callback(new Error('联系人最多不能超过20个字符'))
        } else {
          callback()
        }
      }
      let validate_clientPhone = (rule, value, callback) => {
        if (value && !Pattern.validate('mobile', value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      let validate_remark = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value && getLength(value) > 500) {
          callback(new Error('备注最多不能超过500个字符'))
        } else {
          callback()
        }
      }
      return {
        message: '选择客户',
        clientName: {
          default: '-'
        },
        client: {
          clientName: '', //客户名称
          clientPerson: '', //联系人
          clientPhone: '', //手机号
          status: 0, //状态
          remark: '', //备注
          isChecked: false,
        },
        clients: [],
        clientIds: [],
        checkClientIds: 0,
        centerDialogVisible: false,
        searchInput: '',
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        currentRow: null,
        pageShow: true,
        //分页
        list: {
          type: Function,
          default: null
        },
        //显示的页码数，共50页，显示5页，其他用...表示
        itemCount: Number,
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        input: null,
        //选中
        checkClients: 0,
        strClients: '',
        type: 1,//type=1商品管理，//销货开单，type=2， 交回者type=3 处方开单，type=4
        recycleType: false,
        showPage: true,
        showAdd: false,
        imgUrls: {
          idRed: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/sfzFront_red.png',
          idBlue: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/sfzFront_blue.png',
          idGrey: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/sfzFront_grey.png',
          phone: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/tab/phone.png',
        },
        ID_CERTIFIED: ID_CERTIFIED,
        saleMode: false, //true非农药 false农药
        isPesticide: 2, // 2：非农药  1:农药
        isConfine: 0, //0：限制级 1：非限制级
        isHEBEI: false,//true：河北地区 false：其他地区
        canCheck: false, //处方可被选中，true处方不可选中
        rules: {
          clientName: [
            {
              validator: validate_clientName,
              trigger: 'blur'
            }
          ],
          clientPerson: [
            {
              required: true,
              message: '请填写联系人名称',
              trigger: 'blur'
            },
            {
              validator: validate_clientPerson,
              trigger: 'blur'
            }
          ],
          clientPhone: [
            {
              validator: validate_clientPhone,
              trigger: 'blur'
            }
          ],
          remark: [
            {
              validator: validate_remark,
              trigger: 'blur'
            }
          ],
        },

        //联想下拉列表输入框
        restaurants: [],
        state: '',
        //禁用提示
        showStopInfo: false,
        previousCheck: {},//上一次选中
        remarkFlag: false,
        openSocket:true,
        isHLJ:LocalStorage.get(IS_HLJ),
        isDN:LocalStorage.get(IS_DN),
        hljMode:1,//1=农药2=其他商品
      }
    },
    mounted () {
      this.isConfine = LocalStorage.get('tenant_info').isConfine
      this.isHEBEI = LocalStorage.get('tenant_info').hebei
      this.openSocket=true
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      idCertification (item) {
        if (!item.idCertified) {
          let type = 4
          this.$router.push({
            name: '/clientManagerEdit',
            params: {
              item,
              type
            }
          })
        }
      },
      checkMode (e) {
        //console.log('选中模式：', e)
        if(this.clientIds){
          if (e === 1 && this.clientIds.length > 0) {
            this.queryCustomDetail(this.clientIds.toString())
          }
        }

      },
      getClientIds (clientIds, type, recycleType) {
        this.clientIds = []
        this.type = type
        //console.log("当前type: ",this.type)
        if (this.type === 4) {
          this.isPesticide = 1
        }
        if (recycleType) {
          this.recycleType = recycleType
        }
        this.clientIds = clientIds
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        this.getSearch(1)
      },

      getSearch (page, pageChange) {
        let _this = this
        Loadings.show()
        let current = page
        let size = 10 //引用传入组件后，在标签中refs定义的别名

        let keywords = this.searchInput
        let showStop = 0
        if (!this.recycleType) {
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/client/selectSaleList?current=' + current + '&size=' + size + '&clientPerson=' + keywords)
            .then((response) => {
              Loadings.hide()
              let resp = response.data
              //console.log("resp: ",resp)
              this.clients = resp.data.records
              this.renderPage(page, resp.data.total)
              this.stockOrdering()
              let closeDialog = $('.el-dialog__close')
              if (this.clientIds === undefined) {
                closeDialog[0].style.display = 'none'
              } else {
                closeDialog[0].style.display = 'block'
              }
              for (let i = 0; i < this.clients.length; i++) {
                if (Tool.isNotEmpty(this.clientIds)) {
                  if (this.clients[i].clientId === parseInt(this.clientIds.toString())) {
                    if (!this.clients[i].idCertified) {
                      this.canCheck = true
                    }
                  }
                }
              }
            })
        } else {
          //交回者接口
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/client/selectReturnee?current=' + current + '&size=' + size + '&searchText=' + keywords)
            .then((response) => {
              Loadings.hide()
              this.clients = response.data.data.records
              this.renderPage(page, response.data.data.total)
              this.stockOrdering()
              let closeDialog = $('.el-dialog__close')
              if (this.clientIds === undefined) {
                closeDialog[0].style.display = 'none'
              } else {
                closeDialog[0].style.display = 'block'
              }
            })
        }
        if(!this.recycleType){
          //console.log("??")
          this.socketIOIdScan()
        }

      },
      reSearch (data) {
        this.getSearch(1, data)
      },
      //进货
      stockOrdering () {
        let _this = this
        let pseudoArr = document.getElementsByClassName('el-checkbox')[0]
        pseudoArr.style.display = 'none'
        pseudoArr.firstChild.style.display = 'none'
        setTimeout(function () {
          _this.toggleSelection()
        }, 10)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      handleChange (value) {
        this.size = value[0]
        this.$emit('pageSizeChange', this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
        this.getSearch(this.input)
      },
      /**
       * 渲染分页组件
       */
      renderPage (page, totalSize) {
        let _this = this
        _this.page = parseInt(page) //当前页
        _this.totalSize = totalSize //总条数 / 每页显示条数size
        _this.pageTotal = Math.ceil(totalSize / this.size)
        //默认分页后显示的页码数：10
        _this.pages = _this.getPageItems(this.pageTotal, page, 10)
        let pageNums = $('.pageNumber')
        if (pageNums.length > 0) {
          if (page === 1) {
            Tool.addClass(pageNums[0], 'pageNumbers')
          }
          let newPageIndex = 0
          if (page !== 1) {
            for (let i = 0; i < pageNums.length; i++) {
              Tool.removeClass(pageNums[i], 'pageNumbers')
              if (pageNums[i].innerText === page) {
                //console.log('')
              }
            }
          }

          for (let j = 0; j < pageNums.length; j++) {
            if (pageNums[j].innerText === page + '') {
              Tool.addClass(pageNums[j], 'pageNumbers')
            }
          }

        }
        //这里可忽略，input需要转int
        let firstPage = document.getElementsByClassName('firstPage')[0]
        let lastPage = document.getElementsByClassName('lastPage')[0]
        if (_this.page === 1 && firstPage) {
          Tool.addClass(firstPage, 'is-disabled')
        }
      },
      //查询每一页
      selectPage (page) {
        let _this = this
        if (page < 1) {
          page = 1
        }
        //如果传入的当前页>总页码
        if (page > _this.pageTotal) {
          page = _this.pageTotal
        }
        if (_this.page !== page) {
          _this.page = page
          /*if (_this.list) {
            _this.list(page)
          }*/
        }
        let pageNums = $('.pageNumber')
        for (let i = 0; i < pageNums.length; i++) {
          Tool.removeClass(pageNums[i], 'pageNumbers')
        }

        _this.getSearch(page)
      },
      //当前显示的页码
      getPageItems (totalSize, current, length) {
        let items = []
        if (length >= totalSize) {
          for (let i = 0; i < totalSize; i++) {
            //总条数放入items，遍历页码
            items.push(i)
          }
        } else {
          let base = 0
          //前移:向下取整
          if (current - 0 > Math.floor((length - 1) / 2)) {
            //后移：向上取整
            base = Math.min(totalSize, current - 0 + Math.ceil((length - 1) / 2)) - length
          }
          //条数集合
          for (let i = 1; i <= length; i++) {
            items.push(base + i)
          }
        }
        return items
      },
      //3.添加class
      addClass (node, className) {
        let reg = new RegExp('\\b' + className + '\\b')
        if (!reg.test(node.className)) {
          node.className += (' ' + className)
        }
      },

      //4.移除class
      removeClass (node, className) {
        if (node.className) {
          let reg = new RegExp('\\b' + className + '\\b')
          let classes = node.className
          node.className = classes.replace(reg, '')
          if (/^\s*$/g.test(node.className)) {
            node.removeAttribute('class')
          }
        } else {
          node.removeAttribute('class')
        }
      },

      //多选
      handleCurrentChange (val) {
        this.currentRow = val
      },
      toggleSelection () {
        if (this.clientIds && this.clientIds.length > 0) {
          let rows = []
          this.clients.map((item, index) => {
            this.clientIds.map(id => {
              if (item.clientId === id) {
                rows.push(this.clients[index])
              }
            })
          })
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          if(this.$refs.multipleTable){
            this.$refs.multipleTable.clearSelection()
          }

        }
      },
      handleSelectionChange (val) {
        let _this = this
        this.multipleSelection = val
        if (this.multipleSelection.length < 1) {
          this.multipleSelection.push(this.previousCheck)
        }
        this.checkClients = this.multipleSelection.length
        let ids = []
        let names = []
        if (this.type === 1) {
          this.checkClientIds = []
          this.checkClientNames = []
          if (this.multipleSelection.length > 0 || this.clientIds.length > 0) {
            if (this.multipleSelection.length < 1) {
              for (let i = 0; i < this.clientIds.length; i++) {
                for (let j = 0; j < this.clients.length; j++) {
                  if (this.clients[j].clientId === this.clientIds[i]) {
                    this.multipleSelection.push(this.clients[j])
                  }
                }
              }
            }
          }
          for (let i = 0; i < this.multipleSelection.length; i++) {
            ids.push(this.multipleSelection[i].clientId)
            names.push(this.multipleSelection[i].clientName)
          }
        }

        if (this.type === 2 || this.type === 3 || this.type === 4 || this.type===5) {
          this.checkClientIds = []
          this.checkClientNames = []
          if (this.multipleSelection.length > 0 || this.clientIds.length > 0) {
            if (this.multipleSelection.length < 1) {
              for (let i = 0; i < this.clientIds.length; i++) {
                for (let j = 0; j < this.clients.length; j++) {
                  if (this.clients[j].clientId === this.clientIds[i]) {
                    this.multipleSelection.push(this.clients[j])
                  }
                }
              }
            }

            this.previousCheck = this.multipleSelection[this.multipleSelection.length - 1]
            ids.push(this.multipleSelection[this.multipleSelection.length - 1].clientId)
            if (Tool.isNotEmpty(this.multipleSelection[this.multipleSelection.length - 1].clientName)) {
              names.push(this.multipleSelection[this.multipleSelection.length - 1].clientName)
            } else {
              if (Tool.isNotEmpty(this.multipleSelection[this.multipleSelection.length - 1].clientPerson)) {
                names.push(this.multipleSelection[this.multipleSelection.length - 1].clientPerson)
              }
            }

            let checkBox = document.getElementsByClassName('el-checkbox__input')
            for (let i = 0; i < checkBox.length; i++) {
              Tool.removeClass(checkBox[i], 'is-checked')
            }

            this.multipleSelection = this.multipleSelection[this.multipleSelection.length - 1]
          }
        }

        this.checkClientIds = ids
        this.checkClientNames = names
        this.strClients = this.checkClientNames.toString()

        let checkBoxInput = document.getElementsByClassName('el-checkbox__input')
        let checkBox = document.getElementsByClassName('el-checkbox')
        setTimeout(function () {

          for (let i = 0; i < checkBoxInput.length; i++) {
            if (checkBoxInput[i].className.indexOf('is-focus') !== -1) {
              //Tool.removeClass(checkBoxInput[i],'is-focus')
              Tool.addClass(checkBoxInput[i], 'is-checked')
              Tool.addClass(checkBoxInput[i], 'is-focus')
              Tool.addClass(checkBox[i], 'is-checked')
              let clientNew = checkBoxInput[i].parentNode.parentNode.parentNode.parentNode.childNodes[2].childNodes[0].childNodes[1].innerHTML
              clientNew = parseInt(clientNew)
              for (let i = 0; i < _this.clients.length; i++) {
                if (_this.clients[i].clientId === clientNew) {
                  _this.checkClientIds = []
                  _this.checkClientIds.push(_this.clients[i].clientId)
                  _this.clientIds = _this.checkClientIds
                  if (!_this.clients[i].idCertified && _this.isConfine === 0) {
                    _this.canCheck = true
                    //_this.isPesticide = 1
                    _this.clients[i].isChecked = true
                    _this.multipleSelection = _this.clients[i]
                  } else if (_this.clients[i].idCertified && _this.isConfine === 0) {
                    _this.canCheck = false
                  } else {//(_this.isConfine === 1)
                    _this.canCheck = false
                  }
                  _this.checkClientNames = []
                  if (Tool.isNotEmpty(_this.clients[i].clientName)) {
                    _this.checkClientNames.push(_this.clients[i].clientName)
                  } else {
                    _this.checkClientNames.push(_this.clients[i].clientPerson)
                  }
                  _this.strClients = _this.checkClientNames.toString()
                  _this.queryCustomDetail(_this.clientIds.toString())
                }
              }
            }
          }
        }, 50)
        //console.log('选中行：', this.multipleSelection, '当前选中? : ', this.checkClients, '选中id：', this.checkClientIds, '选中name：', this.checkClientNames)
      },
      selectionClick (rows) {
        //console.log('')
      },
      addClients () {
        /*if (this.canCheck) {
          toast.error('当前选中客户尚未完成实名认证，无法进行处方开单！', 'center')
          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
        } else {*/
        this.openSocket=false
        this.clients.map(item => {
          if (item.clientId === this.checkClientIds[0]) {
            //将当前选中的客户添加到缓存中
            SessionStorage.set(WANGLAIMINGXI_CLIENT, item)
          }
        })
        //console.log("this.isPesticide: ",this.isPesticide,"this.checkClientIds: ",this.checkClientIds,"this.checkClientNames: ",this.checkClientNames)
        if(this.isHLJ){
          this.$emit('getClients', this.checkClientIds, this.checkClientNames, this.isPesticide,this.hljMode)
        }else{
          this.$emit('getClients', this.checkClientIds, this.checkClientNames, this.isPesticide,this.hljMode)
        }

        this.centerDialogVisible = false

      },
      returnClients () {
        this.showPage = true
        this.showAdd = false
        this.client = {}
      },
      toNewClient () {
        if (this.recycleType) {
          this.showAdd = true
          this.showPage = false
        } else {
          let type = 2
          this.$router.push({
            name: '/clientManagerEdit',
            params: {
              type,
            }
          })
        }

      },

      //查看详情
      queryCustomDetail (clientId) {
        let _this = this
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/client/detail?clientId=' + clientId,
          method: 'GET',
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                _this.client = res.data.data
                if (_this.client.idCertified === true) {
                  _this.canCheck = false
                  _this.canCheckPro=false
                } else {
                  _this.canCheck = true
                }
              } else {
                _this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              _this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      loadAll () {
        let _this = this
        if (!this.client.clientName) {
          return
        }
        clearTimeout(_this.timeout2)
        this.timeout2 = setTimeout(() => {
          _this.restaurants = []
          if (_this.client.clientName) {
            _this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/psi/client/selectClientByName?clientName=' + this.client.clientName,
              method: 'GET',
              // data: {
              //   tenantName: this.storeInfo.tenantName
              // },
            })
              .then((res) => {
                if (res.status == 200) {
                  if (res.data.code == 200) {
                    res.data.data.map((item) => {
                      _this.restaurants.push({
                        value: item.clientName,
                        clientName: item.clientName,
                        clientPerson: item.clientPerson,
                        clientPhone: item.clientPhone,
                        remark: item.remark
                      })
                    })
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        }, 100)
      },
      querySearchAsync (queryString, cb) {
        setTimeout(() => {
          /*
          var restaurants = this.restaurants;
          var results = queryString ? restaurants.filter(this.createStateFilter(queryString, 0)) : restaurants;

          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            cb(results);
          }, 1000 * Math.random());
          */
          cb(this.restaurants)
        }, 500)
      },
      createStateFilter (queryString, count) {
        return (state) => {
          //return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);

          //定义显示下拉列表项数量最大为5
          if (count < 5) {
            if (state && state.value && state.value.indexOf(queryString) != -1) {
              count++
              return true
            }
          }
          return false
        }
      },
      handleSelect (item) {
        this.client.clientName = item.clientName
        this.client.clientPerson = item.clientPerson
        this.client.clientPhone = item.clientPhone
        this.client.remark = item.remark
      },
      //检查是否重复
      checkClientPersonIsRepeat () {
        if (!this.client.clientPerson) {
          return
        }
        if (this.type === 0) {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/client/repetitionClient?clientPerson=' + this.client.clientPerson,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        } else if (this.type === 1) {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/client/repetitionClient' +
              '?clientPerson=' + this.client.clientPerson +
              '&clientId=' + this.client.clientId,
            method: 'GET'
          })
            .then((res) => {
              if (res.status === 200) {
                if (res.data.code != 200) {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            if (this.type === 0 || this.type === 2 || this.type === 3 || this.type === 4 || this.type === 5) {
              this.$axios.post(process.env.VUE_APP_SERVER + '/psi/client/save', this.client)
                .then((response) => {
                  Loadings.hide()
                  if (response.status === 200) {
                    if (response.data.code === 200) {
                      this.$toast.success('新增交回者成功！', 'center')
                      setTimeout(function () {
                        let sweet = document.getElementsByClassName('swal2-container')[0]
                        sweet.style.zIndex = '3000'
                      }, 100)
                      this.showAdd = false
                      this.showPage = true
                      this.client = {}
                      this.getSearch(1)
                    } else {
                      this.$message({
                        type: 'warning',
                        message: response.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                  /*
                  this.clients = resp.data.data.records;
                  this.$refs.pageHelper.render(page, resp.data.totalSize)
                  */
                })
            } else if (this.type === 1) {
              this.$axios.request({
                url: process.env.VUE_APP_SERVER + '/psi/client/update',
                method: 'POST',
                data: {
                  // '?clientId=' + _this.client.clientId +
                  // '&clientName=' + _this.client.clientName +
                  // '&clientPerson=' + _this.client.clientPerson +
                  // '&clientPhone=' + _this.client.clientPhone +
                  // '&remark=' + _this.client.remark +
                  // '&status=' + _this.client.status,

                  clientId: _this.client.clientId,
                  clientName: _this.client.clientName,
                  clientPerson: _this.client.clientPerson,
                  clientPhone: _this.client.clientPhone,
                  remark: _this.client.remark,
                  status: _this.client.status
                }
              })
                .then((response) => {
                  Loadings.hide()
                  if (response.status === 200) {
                    if (response.data.code === 200) {
                      this.$message({
                        type: 'success',
                        message: '修改客户信息成功！',
                        duration: 2000,
                        offset: 110
                      })
                      setTimeout(() => {
                        //跳转到客户列表页面
                        _this.$router.push('/admin/clientManager')
                      }, 2000)
                    } else {
                      this.$message({
                        type: 'warning',
                        message: response.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                  /*
                  this.clients = resp.data.data.records;
                  this.$refs.pageHelper.render(page, resp.data.totalSize)
                  */
                })
            }
          } else {
            return false
          }
        })
      },
      //2.停用客户
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu (id) {
        return Tool.authorityUserResources(id)
      },
      socketIOIdScan(){
        //IE10以下的socket.io仍使用轮询方式和服务器通信，轮询过程中会导致重连，暂时不清楚原因。
        //如果服务端向客户端发送数据时发生重连，大概需要3秒多客户端才能收到数据
        //这里打开io的时候设置重连等待时间尽可能短，以减少接收数据的延时。
        let _this=this
        let socket = io.connect('http://localhost:5000',{'timeout': 30000,'reconnectionDelayMax':1000,'reconnectionDelay':500});

        socket.on('connect', function () {
          socket.emit('startRead');
        });
        socket.on('disconnect', function () {
          /*socket.ondisconnect()
          _this.$message({
            type: 'error',
            message: '设备已断开连接',
            duration: 2000,
            offset: 110
          })*/
        });

        socket.on('card message', function(msg){
          let result1 = Base64.decode(msg);
          let data = eval("("+result1+")");
          _this.client.realName=data.name;
          _this.client.identityCode=data.cardno;
          _this.client.addressDesc=data.address;
          if (Tool.isNotEmpty(_this.client.identityCode) && (_this.client.identityCode.indexOf('*') === -1 || Pattern.validate('idCode', _this.client.identityCode)) && Tool.isNotEmpty(LocalStorage.get(LOGIN_USER))) {
            if(_this.openSocket){
              _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/client/selectSaleOne?current=1&size=10&identityCode=' + BaseDecrypt.encode(_this.client.identityCode))
                .then((response) => {
                  Loadings.hide()
                  let resp = response.data
                  let clientID = resp.data.records
                  if(clientID.length>0){
                    _this.clients=clientID
                    _this.renderPage(1, resp.data.total)
                    _this.stockOrdering()
                    let closeDialog = $('.el-dialog__close')
                    if (_this.clientIds === undefined) {
                      closeDialog[0].style.display = 'none'
                    } else {
                      closeDialog[0].style.display = 'block'
                    }
                    for (let i = 0; i < _this.clients.length; i++) {
                      if (Tool.isNotEmpty(_this.clientIds)) {
                        if (_this.clients[i].clientId === parseInt(_this.clientIds.toString())) {
                          if (!_this.clients[i].idCertified) {
                            _this.canCheck = true
                          }
                        }
                      }
                    }
                  }
                })
            }
          }
        });
      },
      getClientById(identityCode){

      },
    }
  }
</script>

<style scoped>
  .popupHidden {
    cursor: unset !important;
  }

  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .navRight {
    float: right;
  }

  /*表格*/
  .orderNav {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
  }

  .searchInput {
    width: 200px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    /*margin-right: 10px;*/
  }

  .searchBtnReturn {
    float: left;
    transition: all .5s;
    margin-right: 10px;
  }

  .confirmCheck {
    float: left;
    transition: all .5s;
  }

  .newBtnReturn {
    float: right;
    transition: all .5s;
  }

  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 480px !important;
  }

  /deep/ .el-table .el-table__cell {
    padding: 6px 0;
  }

  .remark div {
    text-align: left;
  }

  /*分页*/
  .pageNav {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .pageChoose {
    margin-top: 10px;
  }

  .totalCount {
    margin-right: 10px;
  }

  .pageSize {

    margin-top: 5px;
  }

  .sizeNum {
    margin: 0 5px;
    max-width: 150px;
  }

  .goPage {
    margin: 5px 0 5px 5px;
    color: #F56C6C;
  }

  .goPageNum {
    width: 60px;
    margin: 0 10px;

  }

  .showCenter {
    text-align: center;
  }

  /*跳转页*/
  .pageNumbers {
    background: #F56C6C !important;
    border-color: #F56C6C !important;
    color: #FFF !important;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  .checkArea {
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  .noDisplay {
    display: none;
  }

  .clientIdShow {
    display: none;
  }

  /deep/ .cell {
    text-align: center;
    justify-content: center;
  }

  /deep/ .el-table .el-table__cell:first-of-type {
    line-height: 30px !important;
  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }

  .toolTipWidth {
    max-width: 270px !important;
    text-align: center !important;
  }

  .toolTipWidthPlugin {
    padding: 0 0 20px;
    float: left;
    text-align: center;
  }

  .showTip {
    font-size: 30px;
  }

  .showTipIcon {
    font-size: 20px;
    margin-left: 5px;
    color: #409EFF;
    position: absolute;

  }

  .showTipLine {
    line-height: 38px;
    height: 38px;
    width: 270px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .address {
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showRemarkLine {
    height: 38px !important;
    line-height: 38px !important;
    overflow: hidden;
  }

  /*认证信息*/
  #identityStatus {
    cursor: pointer;
  }

  .identityText {
    margin-right: 15px;
  }

  .identityImg {

    width: 50px;
    height: 30px;
  }

  .showCenter {
    /*display: block;*/
  }

  .statusIcon {
    font-size: 20px;
    color: #f0ad4e;
    position: absolute;
    right: 11px;
    bottom: 5px;
  }

  /*选择模式*/
  .checkMode {
    margin-right: 0;
  }
</style>
