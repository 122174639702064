<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">收款单详情</div>
      </div>

      <div>
        <div class="mainForm">
          <el-form :model="repaymentBill"  ref="repaymentBill" label-width="120px"
                   class="demo-store store-info-form">
            <el-form-item class="repaymentLabelTitle" label="客户" prop="clientName">
              <div class="textDesc">{{repaymentBill.clientName}}<i class="el-icon-user-solid clientNameIcon"></i></div>
            </el-form-item>
            <!--<el-form-item class="repaymentLabelTitle" label="欠款" prop="arrears">
              <div class="textDescRed">￥ {{repaymentBill.debtMoney}}</div>
            </el-form-item>-->
            <el-form-item class="repaymentLabelTitle" label="实收金额" prop="paymentAmount">
              <div class="realDecimal">{{repaymentBill.realRepaymentMoney}}</div>
              <div class="textDesc">元</div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle" label="优惠金额" prop="discountMoney">
              <div class="realDecimal">{{repaymentBill.discount}}</div>
              <div class="textDesc">元</div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle lastTitle" label="总收款金额" prop="sumPaymentAmount">
              <div class="textDescRed">{{repaymentBill.repaymentMoney}} 元</div>
            </el-form-item>
            <!--<div class="showStopInfo">
              <div class="stopInfo">注：总收款金额 = 实收金额 + 优惠金额。</div>
            </div>-->
            <el-form-item class="repaymentLabelTitle lastTitle" label="收款人" prop="userName">
              <div class="textDescRed">{{repaymentBill.userName}}</div>
            </el-form-item>
            <el-form-item class="repaymentLabelTitle lastTitle" label="开单时间" prop="createTime">
              <el-tag type="info" class="leftItem">
                {{repaymentBill.createTime}}
              </el-tag>
              <!--<div class="realDecimal">{{repaymentBill.createTime}}</div>-->
            </el-form-item>
            <el-form-item class="repaymentLabelTitle" label="备注" prop="remark">
              <div class="realDecimal">{{repaymentBill.remark}}</div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogClientRepaymentDetail',
    props: {},
    data () {
      return {
        message: '收款单详情',
        centerDialogVisible: false,
        repaymentBill: {
          dataTable:'',
          debtMoney:'',//总欠款
          repaymentId:'',//收款单id
          repaymentName:'',//订单分类名称
          repaymentMoney:'',//总收款金额
          discount:'',//优惠金额
          realRepaymentMoney:'',//实收金额
          clientId: '',
          clientName: '',
          userId:'',//收款人id
          userName:'',//收款人
          createTime:'',
          remark:'',
        },
      }
    },
    mounted () {

    },
    methods: {

      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openRepaymentDetail (item) {
        this.repaymentBill = item
        this.debtComputeMax = parseFloat(this.repaymentBill.arrears)
        this.repaymentBill.arrears = parseFloat(this.repaymentBill.arrears).toFixed(2)
        this.repaymentBill.discountMoney = 0
        this.discountMoney = 0
        this.repaymentBill.paymentAmount = parseFloat(this.repaymentBill.arrears)
        this.paymentAmount = this.repaymentBill.paymentAmount
        this.repaymentBill.sumPaymentAmount = this.repaymentBill.arrears
        if (Tool.isEmpty(this.repaymentBill.clientName)) {
          this.repaymentBill.clientName = this.repaymentBill.clientPerson
        }
        this.centerDialogVisible = true
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 540px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .remark div {
    text-align: left;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  .locationIcon {
    font-size: 20px;
    margin-top: 10px;
    color: #2a92ee;
    cursor: pointer;
  }

  .locationIcon:hover {
    color: #58adf8;
  }

  #myModal {
    z-index: 3000;
    margin-top: 100px;
  }

  .greyTheme {
    color: #999999 !important;
  }

  .inputGroup {
    float: left;
    margin-right: 5px;
  }

  .textDesc {
    float: left;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .textDescRed {
    float: left;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #e92322;
  }

  .repaymentLabelTitle /deep/ .el-form-item__label {
    font-size: 16px;
  }

  .repaymentLabelTitle {
    margin-bottom: 15px;
  }
  .lastTitle{
    margin-bottom: 0 !important;
  }
  .showStopInfo {
    text-align: center;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    line-height: 20px;
    padding: 10px;
    margin: 20px auto;
    margin-top: 0;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }
  .clientNameIcon{
    margin-left: 5px;
    color: #f0ad4e;
  }
  .realDecimal{
    float: left;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
  }
  .leftItem{
    float: left;
    font-size: 16px;
    margin-top: 3px;
    margin-left: 7px;
  }
</style>
