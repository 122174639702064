/**
 * 枚举类过滤器：数组
 * @param list：对应页面设置常量：视频类型:[{key:"P",value: "公共资源"},{key:"I",value: "私有资源"}],
 * @param key：P/I
 * @return {string} 公共资源/私有资源
 * (list,key)在更新enums对象类型后弃用
 * 前端传值 list=options过滤数组 scope.row.param=对象属性 {{options | optionKV(scope.row.videoType)}}
 */
let optionKV = (list, key) => {
    //传入非list，非key,undefined=空
    if (!list || !key) {
        return "";
    } else {
        let KV = "";
        for (let i = 0; i < list.length; i++) {
            if (key === list[i]["key"]) {
                KV = list[i]["value"];
            }
        }
        return KV;
    }
};
let optionObjectKV = (object, key) => {
    //传入非list，非key,undefined=空,这里不能使用!key，非key会连同0一起判定，这里我们有type=0，使用key=null
    if (!object || key == null) {
        return "";
    } else {
        let KV = "";
        for (let enums in object) {
            //console.log("enumsFilter传入的object：",object[enums]["key"]);
            if (key === object[enums]["key"]) {
                KV = object[enums]["value"];
            }
        }
        return KV;
    }
};

/**
 * 增加时长过滤器
 * @param value 如：3600
 * @return {string} 10:00:00
 */
let formatSecond = (value) => {
    value = value || 0;
    //将时间全部转化为int秒，radix基数为10
    let second = parseInt(value, 10);
    let minute = 0;
    let hour = 0;
    if (second >= 60) {
        //向下取整
        minute = Math.floor(second / 60);
        second = Math.floor(second % 60);
        if (minute >= 60) {
            hour = Math.floor(minute / 60);
            minute = Math.floor(minute % 60);
        }
    } else {
        //second<60，不处理
    }
    //将获取结果拼接
    let result = "" + PrefixInteger(second, 2) + "";
    //配置统一时间格式00:00:30
    result = "" + PrefixInteger(minute, 2) + ":" + result;
    result = "" + PrefixInteger(hour, 2) + ":" + result;
    return result;
}

/**
 * second时间转化：格式化指定长度，格式化2位，前面补0
 */
function PrefixInteger(num, length) {
    return (Array(length).join('0') + num).slice(-length);
}

/** 文件大小过滤器
 *  Math.round()向上取整，四舍五入
 */
let formatFileSize = (value) => {
    value = value || 0;
    let result;
    //大于100MB现实GB
    if (value > 100000 * 1024) {
        result = Math.round((value / 1024 / 1024 / 1024) * 100) / 100 + "GB";
        //大于100KB显示MB
    } else if (value > 100 * 1024) {
        result = Math.round((value / 1024 / 1024) * 100) / 100 + "MB";
        //小于100KB，显示kb
    } else {
        result = Math.round((value / 1024 )* 100) / 100 + "KB";
    }
    return result;
}

export default {
    optionKV,
    optionObjectKV,
    formatSecond,
    formatFileSize,
}