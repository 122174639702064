<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <div class="reportBox">
          <div class="reportText">已选：{{reportNum}}个</div>
        </div>
        <el-input class="searchInput"
                  placeholder="请输入客户名称、商品名称"
                  value-format="yyyy-MM-dd"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  prefix-icon="el-icon-search"
                  :clearable="true">
        </el-input>

        <div class="block searchTime">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>

        <div class="checkStop">
          <template>
            <el-checkbox v-model="reportedOrder" @change="reportedOrderClick">显示已上报订单</el-checkbox>
          </template>
        </div>

      </div>

      <div class="navRight">
        <div class="searchBtn">
          <el-button v-if="getAuthorityMenu('700202')" class="searchBtn" type="primary" icon="el-icon-tickets" @click="reportAll(1)">上报</el-button>
        </div>
        <el-button v-if="getAuthorityMenu('700201')" class="searchBtn" type="primary" icon="el-icon-document-copy" @click="reportAll(2)">全部上报</el-button>
      </div>

    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="saleOrders"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        @selection-change="handleSelectionChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column
          type="selection"
          :selectable="selectableHandle"
          width="55">
        </el-table-column>
        <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
        <el-table-column property="clientName" label="客户名称" width="200" sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.clientName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.clientName===''?scope.row.clientPerson:scope.row.clientName}}
              </div>
              <span class="showTipLine">{{scope.row.clientName===''?scope.row.clientPerson:scope.row.clientName}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="createTime" label="开单时间" width="160"></el-table-column>
        <el-table-column property="goodsName" label="购买商品" sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthGoods" placement="bottom" @mouseenter.native="remarkEnter(scope.row.goodsName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.goodsName===''?'-':scope.row.goodsName}}
              </div>
              <span class="showTipLine">{{scope.row.goodsName===''?'-':scope.row.goodsName}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="paymentAmount" label="实收金额" width="150" sortable>
          <template slot-scope="scope">
            <el-tag type="danger" class="showCenter">￥ {{scope.row.paymentAmount}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{SUPPLIER_ORDER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="status" label="赊账" width="140" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.isDebt === 1 ? 'danger' : scope.row.isDebt === 2?'success': 'primary'" class="showCenter" id="isDebt">
              {{ IS_DEBT | optionObjectKV(scope.row.isDebt) }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="105">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="detail(scope.row)">查看详情
            </el-button>
            <!--<el-button v-if="getAuthorityMenu('300201')"
                       size="mini" :disabled="scope.row.status===1"
                       :type="'warning'"
                       @click="cancelOrder(scope.row)">{{scope.row.status===0?'撤单':'已撤销'}}
            </el-button>-->

          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-client-order-detail ref="dialogClientOrderDetail" @cancelOrder="cancelOrder"></dialog-client-order-detail>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogClientOrderDetail from '../../../components/DialogClientOrderDetail'

  export default {
    name: 'SaleReport',
    components: {
      PageHelper,
      DialogClientOrderDetail
    },
    data () {
      return {
        message: '销货台账上报',
        reportNum:0,
        searchInput: '',
        clientName: '',
        client: {
          clientName: '',
          clientPerson: '',
          clientPhone: '',
          status: 0
        },
        saleOrder: {},
        saleOrders: [],
        reportedOrder: false,//默认不显示停用供应商
        currentRow: null,
        SUPPLIER_ORDER_STATUS: SUPPLIER_ORDER_STATUS,

        //时间选择器
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },

        value2: '',
        startDate: '',
        endDate: '',
        sumAmount:'0.00',
        remarkFlag: false,
        IS_DEBT:IS_DEBT,
        isHLJ:LocalStorage.get(IS_HLJ),
        isDN:LocalStorage.get(IS_DN),
        multipleSelection:'',//选中项
        total:0,
      }
    },
    mounted () {
      this.client = this.$route.params.item || {}
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1)
      }

      this.navChange();
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        let size = this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let isReport = 0
        let keywords = this.searchInput
        if (this.reportedOrder === false) {
          isReport = 0
        } else {
          isReport = 1
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/pageForPc?current='
          + current + '&size=' + size + '&clientName=' + keywords + '&status=' + 0 + '&isReport='+isReport + '&beginTime=' + this.startDate+'&endTime='+this.endDate)
          .then((response) => {
              Loadings.hide()
              let resp = response.data

              this.saleOrders=resp.data.orderList;
              if(resp.data.money){
                this.sumAmount=resp.data.money;
              }else{
                this.sumAmount='0.00'
              }
              this.total=resp.data.total
              this.$refs.pageHelper.render(page, resp.data.total)
            }
          )
      },
      reSearch (data) {
        this.getSearch(1, data)
      },
      //2.已撤销订单
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      },
      reportedOrderClick () {
        this.getSearch(1)
      },
      //订单详情
      detail(item) {
        //将当前选中的客户添加到缓存中
        let client = {
          clientName: item.clientName,
          clientPerson: item.clientPerson,
          clientPhone: item.clientPhone
        };
        SessionStorage.set(WANGLAIMINGXI_CLIENT, client);

        this.$nextTick(() => {
          let orderId = item.saleOrderId
          let clientName = item.clientName
          let type=1
          this.$refs.dialogClientOrderDetail.getOrderId(orderId, clientName,type,true)
        })
      },
      //撤销订单
      cancelOrder (item) {
        let reqUrl='/psi/saleorder/revoke'
        if(this.isHLJ){
          reqUrl='/psi/saleorder/hlj/revoke'
        }
        let _this = this
        if (typeof (item) === 'number') {
          Loadings.show()
          _this.$axios.post(process.env.VUE_APP_SERVER + reqUrl + '?saleOrderId=' + item)
            .then((response) => {
                Loadings.hide()
                let resp = response.data
                _this.getSearch(1)
              }
            )
        } else {
          this.$toast.showCodeConfirm('撤销当前订单', item.goodsName, '是否撤单', '撤单', function () {
            Loadings.show()
            _this.$axios.post(process.env.VUE_APP_SERVER + reqUrl + '?saleOrderId=' + item.saleOrderId.toString())
              .then((response) => {
                  Loadings.hide()
                  let resp = response.data
                  let orders = resp.data
                  _this.getSearch(1)
                }
              )
          })
        }
      }
      ,
      //再来一单

      handleCurrentChange (val) {
        this.currentRow = val
      }
      ,
      //时间选择
      dateChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.startDate = this.$moment(e[0]).format('YYYY-MM-DD')
          this.endDate = this.$moment(e[1]).format('YYYY-MM-DD')
        } else {
          this.startDate = ''
          this.endDate = ''
        }
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },


      toggleSelection(rows,allTag) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });

        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        this.reportNum=this.multipleSelection.length
        // console.log("this.multipleSelection: ",this.multipleSelection)
      },
      reportAll(type){
        let _this=this
        if(type==1){
          // console.log("上报：",type)
          let ids=[]
          if(this.multipleSelection.length<1){
            this.$message({
              type: 'error',
              message: '请选择需要上报的订单',
              duration: 2000,
              offset: 110
            })
          }else{
            this.multipleSelection.map(item=>{
              ids.push(item.saleOrderId)
            })
            // console.log("上报iD：",ids)
            Loadings.show()
            _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/reportSaleOrder?saleOrderId=' + ids+'&keyword='+'&startTime='+'&endTime=')
              .then((response) => {
                  Loadings.hide()
                  if (response.status === 200) {

                    if (response.data.code === 200) {
                      _this.$message({
                        type: 'success',
                        message: '上报成功！',
                        duration: 2000,
                        offset: 110
                      })

                      _this.getSearch(1)

                    } else {
                      _this.$message({
                        type: 'warning',
                        message: response.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    _this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                }
              )
          }
        }else{
          // console.log("全部上报：",type)
          // this.toggleSelection(this.stockOrders)
          // this.reportNum=this.total
          this.getSearch(1)
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/queryOrderNumberByDate?clientName='
            + this.searchInput+'&beginTime='+this.startDate+'&endTime='+this.endDate).then((res)=>{
            if (res.status === 200) {
              console.log("res.data: ",res.data)
              if (res.data.code === 200) {
                if(res.data.data>0){
                  this.$toast.showOrderConfirm('销货台账上报总计 '+res.data.data+" 个订单", '是否上报', '','上报', function () {
                    Loadings.show()

                    _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/reportSaleOrder?saleOrderId='+'&keyword='+_this.searchInput+'&startTime='+_this.startDate+'&endTime='+_this.endDate)
                      .then((response) => {
                          Loadings.hide()
                          _this.toggleSelection()
                          if (response.status === 200) {

                            if (response.data.code === 200) {
                              _this.$message({
                                type: 'success',
                                message: '上报成功！',
                                duration: 2000,
                                offset: 110
                              })

                              _this.getSearch(1)

                            } else {
                              _this.$message({
                                type: 'warning',
                                message: response.data.msg,
                                duration: 2000,
                                offset: 110
                              })
                            }
                          } else {
                            _this.$message({
                              type: 'warning',
                              message: '网络异常',
                              duration: 2000,
                              offset: 110
                            })
                          }
                        }
                      )
                  })
                }else{
                  _this.$message({
                    type: 'error',
                    message: '暂未查到可上报的订单',
                    duration: 2000,
                    offset: 110
                  })
                }

              }
            }
          })

        }
      },
      selectableHandle(row,index){
        if(row.isReport!==0){
          return false
        }else{
          return true
        }
      },
    }
  }
</script>

<style scoped>
  .mainPages {
    height: calc(100vh - 120px);
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    margin-right: 10px;
  }

  .supplierName {
    margin-top: -5px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*搜索导航*/
  .topNav {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 250px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*时间搜索*/
  .searchTime {
    float: left;
    margin-right: 10px;
  }

  /deep/ .el-date-editor .el-range-input {
    margin-left: 20px;
  }

  /*表格*/
  .mainForm {

  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 600px;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }
  .toolTipWidth {
    max-width: 200px !important;
    text-align: center !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
    text-align: center;
  }
  .showTipLine{
    line-height: 36px;
    height: 36px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address{
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showRemarkLine{
    height: 36px !important;
    line-height: 36px !important;
    overflow: hidden;
  }

  .titleName {
    display: inline-block;
    background-color: #e92322;
    color: #ffffff;
    padding: 0 15px;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;
    float: right;
    border-top-left-radius: 10px;
  }

  .titleDesc {
    display: inline-block;
    background-color: #ffffff;
    color: #e92322;
    border: 1px solid #e92322;
    padding: 0 15px;
    line-height: 38px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    float: right;
    border-bottom-right-radius: 10px;
  }
  .reportBox{
    display: inline-block;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
  }
  .reportText{
    font-size: 16px;
    font-family: SimHei;
    font-weight: 400;
    color: #409EFF;
  }
</style>
