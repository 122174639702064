<template>
  <div class="order" :id="orderId" >
    <div class="orderNav">
      <div class="navLeft" :class="hiddenTopBtn?'paddingBottom':''">
        <div class="orderTime">开单时间：{{order.createTime}}</div>
        <div class="orderNum">订单号：{{order.buyOrderNo}}</div>
        <!-- <el-button class="cancelOrder" type="danger" @click="showCeshi" >ceshi</el-button> -->
      </div>
      <div class="navRight" v-if="!hiddenTopBtn">
        <el-button class="cancelOrder" type="danger" @click="cancelOrder" v-if="order.status == 0 && (getAuthorityMenu('200201') || (getAuthorityMenu('200401') || getAuthorityMenu('50030401')))">撤销订单</el-button>
        <el-button v-if="order.canAgain && type!==2 && order.status == 0 && (getAuthorityMenu('200202') || getAuthorityMenu('50030402'))" class="printOrder" type="success" icon="el-icon-document-copy" @click="reOrder">再来一单</el-button>
        <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="print" v-if="order.status === 0 ">打印小票</el-button>
        <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="printBig" v-if="order.status === 0 ">打印大票</el-button>
      </div>
    </div>
    <div class="orderTable">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="orderDetail"
        border
        highlight-current-row
        class="previous-row orderTables"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" fixed></el-table-column>
        <el-table-column property="goodsName" label="商品名称" width="150" fixed></el-table-column>
        <el-table-column property="packingSpecification" label="包装规格" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag type="info" class="packageUnitDesc showCenter">
              {{scope.row.packingSpecification}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="buyPackageLevel" :label="type!==2?'进货包装':'退货包装'" width="100">
          <template slot-scope="scope" >
            <el-tag :type="scope.row.packageLevel===1?'primary':'danger'" class="packageUnitDesc showCenter">
              {{scope.row.packageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column v-if="type!==2" property="packageLevel" label="单价" width="150">
          <template slot-scope="scope" >
            <el-tag type="warning" class="showCenter">￥ {{scope.row.packageLevel===1?scope.row.smallGoodsPrice:scope.row.bigGoodsPrice}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type===2" property="packageLevel" label="单价" width="150">
          <template slot-scope="scope" >
            <el-tag type="warning" class="showCenter">￥ {{scope.row.goodsPrice}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column property="goodsNumber" label="数量" width="150">
          <template slot-scope="scope">
            <el-tag :type="scope.row.packageLevel===1?'primary':'danger'" class="showCenter">{{scope.row.goodsNumber}} {{scope.row.packageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column property="goodsPrice" label="小计" width="150">
          <template slot-scope="scope">
            <el-tag type="danger" class="showCenter">
              ￥ {{scope.row.totalPrice}}
            </el-tag>
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="sum">
      <div v-if="type!==2">
        <div >
          <div class="sumTitle">应付金额：</div>
          <span class="sumMoney">{{order.accountsPayable}} 元</span>
        </div>
        <div >
          <div class="sumTitle">实付金额：</div>
          <span class="sumMoney">{{order.paymentAmount}} 元</span>
        </div>
      </div>
      <div v-if="type===2">
        <div >
          <div class="sumTitle">应退金额：</div>
          <span class="sumMoney">{{order.refundAmount}} 元</span>
        </div>
        <div >
          <div class="sumTitle">实退金额：</div>
          <span class="sumMoney">{{order.realRefundAmount}} 元</span>
        </div>
      </div>
    </div>
    <div class="horizontalLine"></div>
    <div class="remark">
      <div>
        <p>备注：{{order.remark}}</p>
      </div>
    </div>

    <!-- 打印大票 -->
    <iframe style="display: none;" :srcdoc="tmpHtml" id="ifr"></iframe>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">

      <div id="box2"></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import { getLodop } from '/public/static/js/utils/LodopFuncs.js'
  export default {
    //name: "订单详情",
    //向外暴露属性
    props:{
      orderId:{
        default:"order-Id"
      },
      supplierName:{
        default:'supplierName'
      }
    },
    data:function () {
      return{
        //播放器实例
        order:{},
        orderDetail:[],
        PACKAGE_LEVEL:PACKAGE_LEVEL,
        type:1,
        orderid:'',
        supplier: {}, //供应商
        tenantInfo: {}, //店铺信息
        tmpHtml: '',
        PRINT_MSG: PRINT_MSG,
        dialogVisible: false,
        hiddenTopBtn:false,
      }
    },
    mounted() {
      this.supplier = SessionStorage.get(WANGLAIMINGXI_SUPPLIER);
      this.tenantInfo = LocalStorage.get(TENANT_INFO);
    },
    methods:{
      showCeshi() {
        document.getElementById('box2').innerHTML = this.tmpHtml;
        this.dialogVisible = true;
      },
      handleClose(){},
      getDetailById(orderId, supplierName, type,hiddenTopBtn) {
        // console.log("查看订单详情：",type)
        if(hiddenTopBtn){
          this.hiddenTopBtn=true
        }

        this.orderid = orderId;
        this.type = type;
        // console.log("------------------type = ", this.type);
        console.log("子组件orderId：",orderId,"supplierName: ",supplierName);

        let sessionStorage = SessionStorage.get(WANGLAIMINGXI_SUPPLIER);
        this.supplier = sessionStorage;

        //进货退货
        if (type === 2) {
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/buyreturnorder/detail?buyReturnOrderId=' + orderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.buyReturnOrderId=resp.data.buyReturnOrderId;
            this.order.buyOrderNo=resp.data.buyReturnOrderNo;
            this.order.refundAmount=resp.data.refundAmount;
            this.order.realRefundAmount=resp.data.realRefundAmount;
            this.order.createTime=resp.data.createTime;
            this.order.status=resp.data.status;
            this.order.canAgain=resp.data.canAgain;
            this.orderDetail=resp.data.details;
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
            }
            this.order.remark=resp.data.remark;
            this.printHtml()
          })
        }
        //进货
        else if (type === 1) {
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/buyorder/detail?buyOrderId=' + orderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.accountsPayable=resp.data.accountsPayable;
            this.order.paymentAmount=resp.data.paymentAmount;
            this.order.buyOrderNo=resp.data.buyOrderNo;
            this.order.createTime=resp.data.createTime;
            this.order.status=resp.data.status;
            this.orderDetail=resp.data.details;
            this.order.canAgain=resp.data.canAgain;
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
            }
            this.order.remark=resp.data.remark;

            this.printHtml()
          })
        }
      },
      printHtml(){
        this.tmpHtml = `<div style="text-align: center;">` +
          `<span style="font-size: 18px;">${this.tenantInfo.tenantName ? this.tenantInfo.tenantName + (this.type==1?'进货单':'进货退货单') : ''}</span>` +
          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:10px; margin-bottom:10px;">` +
          `<div style= "text-align: left;">` +
          `<div>供应商：${this.supplier.supplierName}</div>` +
          `<div>联系人：${this.supplier.supplierPerson}</div>` +
          `<div>电话：${this.supplier.supplierPhone}</div>` +
          `</div>` +
          `<div style="text-align: left;">` +
          `<div>No：${this.order.buyOrderNo}</div>` +
          `<div>日期：${this.order.createTime}</div>` +
          `</div>` +
          `</div>` +
          `<table width="100%" cellspacing="0" border="1" style="margin: auto; text-align: center;">` +
          `<tr>` +
          `<td>产品名称</td>` +
          `<td>包装规格</td>` +
          `<td>数量</td>` +
          `<td>${this.type==1?'进货单价':'进货退货单价'}</td>` +
          `<td>${this.type==1?'进货金额':'进货退货金额'}</td>` +
          `</tr>`;

        let sumTotalPrice = 0;
        for (let i = 0; i < this.orderDetail.length; i++) {
          let item = this.orderDetail[i];
          if (item.buyPackageLevel == 1) {
            item.unit = item.middleUnitName;
          } else if (item.buyPackageLevel == 2) {
            item.unit = item.bigUnitName;
          }

          this.tmpHtml +=
            `<tr style="font-size: 12px;">` +
            `<td>${item.goodsName}</td>` +
            `<td>${item.packingSpecification}</td>` +
            `<td>${item.goodsNumber + item.unit}</td>` +
            `<td>${item.goodsPrice + '元/' + item.unit}</td>` +
            `<td>￥${item.totalPrice}</td>` +
            `</tr>`;

          sumTotalPrice += item.totalPrice;

          if (i == (this.orderDetail.length - 1)) {
            this.tmpHtml +=
              `<tr style="font-size: 12px;">` +
              `<td>合计：</td>` +
              `<td></td>` +
              `<td></td>` +
              `<td></td>` +
              `<td>￥${sumTotalPrice}</td>` +
              `</tr>`;
          }
        }

        this.tmpHtml += `</table>` +
          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:100px; margin-bottom:10px;">` +
          `<div style= "text-align: left; width:200px;">` +
          `<div>${this.type===1?'应收金额':'应退金额'}：￥${this.type==1?this.order.accountsPayable:this.order.refundAmount}</div>` +
          `<div>制单人：${this.tenantInfo.userName}</div>` +
          `</div>` +
          `<div style= "text-align: left;">` +
          `<div>${this.type===1?'实收金额':'实退金额'}：￥${this.type==1?this.order.paymentAmount:this.order.realRefundAmount}</div>` +
          `<div>售货单位：${this.tenantInfo.tenantName}</div>` +
          `</div>` +
          `<div style="text-align: left;">` +
          `<div>电话：${this.tenantInfo.account}</div>` +
          `<div>备注：${this.order.remark}</div>` +
          `</div>` +
          `</div>` +
          `<div>注意事项：所有农药产品，请按产品标签说明使用</div>`+
          `</div>`;
        // console.log("this.tmpHtml: ",this.tmpHtml)
      },
      //再来一单
      reOrder () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/buyorder/detail?buyOrderId=' + this.orderid)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.accountsPayable = resp.data.accountsPayable
            this.order.paymentAmount = resp.data.paymentAmount
            this.order.buyOrderNo = resp.data.buyOrderNo
            this.order.supplierId = []
            this.order.supplierId.push(resp.data.supplierId)
            this.order.supplierName = []
            this.order.supplierName.push(resp.data.supplierName)
            this.order.createTime = resp.data.createTime
            this.order.status = resp.data.status
            this.order.remark = resp.data.remark
            this.order.canAgain = resp.data.canAgain
            this.order.details = resp.data.details
            for (let i = 0; i < this.order.details.length; i++) {
              this.order.details[i].uuid = Tool.get8UUID(8)
              this.order.details[i].buyPackageLevel = this.order.details[i].packageLevel
              this.order.details[i].totalPrice = this.order.details[i].goodsPrice * this.order.details[i].goodsNumber
              this.order.details[i].packageLevel = this.order.details[i].goodsPackageLevel
            }
            if (this.order.canAgain) {
              let type = 4
              this.$router.push({
                name: '/stockOrders',
                params: {
                  type,
                  item: this.order
                }
              })
              //this.$parent.updateRouter();
            }
          })
      },
      cancelOrder(){
        let flag=true;
        this.$emit('orderCancel',flag,this.orderid, this.type);
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
      //打印票据
      print() {

        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
          return;
        }

        let LODOP = getLodop();

        if (LODOP === 'Error:DriveNotInstalled') {
          //console.log('打印插件未安装！');
          this.$message({ type: 'error', message: '打印插件未安装！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        } else if (LODOP === 'Error:DriverNeedsToBeUpgraded') {
          //console.log('打印插件需升级！');
          this.$message({ type: 'error', message: '打印插件需升级！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        }

        let topDistance = 5; //距离顶部距离
        LODOP.NEWPAGEA();
        LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*慧台账进销存*');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",15);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 50;
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 1;
        LODOP.ADD_PRINT_TEXT(topDistance,55,130,40,this.order.createTime);

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 1;
        LODOP.ADD_PRINT_TEXT(topDistance,55,130,60,this.order.buyOrderNo);

        topDistance += 40;
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【供应商信息】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let height_supplierName = 0;
        if (this.supplier.supplierName.length > 9) {
          height_supplierName += (Math.ceil(this.supplier.supplierName.length / 9) - 1) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_supplierName,this.supplier.supplierName);

        if (this.supplier.supplierName.length > 9) {
          topDistance += Math.ceil(this.supplier.supplierName.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系人：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let height_supplierPerson = 0;
        if (this.supplier.supplierPerson.length > 9) {
          height_supplierPerson += Math.ceil(this.supplier.supplierPerson.length / 9) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_supplierPerson,this.supplier.supplierPerson);

        if (this.supplier.supplierPerson.length > 9) {
          topDistance += Math.ceil(clinet.supplierPerson.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,this.supplier.supplierPhone);

        topDistance += 30;

        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【订单详情】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;

        this.orderDetail.map((item, index) => {
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_goodsName = 0;
          if (item.goodsName.length > 9) {
            height_goodsName += Math.ceil(item.goodsName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_goodsName,item.goodsName);

          if (item.goodsName.length > 9) {
            topDistance += Math.ceil(item.goodsName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'包装规格：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_packingSpecification = 0;
          if (item.packingSpecification.length > 9) {
            height_packingSpecification += Math.ceil(item.packingSpecification.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_packingSpecification,item.packingSpecification);

          if (item.packingSpecification.length > 9) {
            topDistance += Math.ceil(item.packingSpecification.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品单价'+'：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.goodsPrice);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品数量：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let unit = '';
          if (item.buyPackageLevel == 1) {
            unit = item.middleUnitName;
          } else if (item.buyPackageLevel == 2) {
            unit = item.bigUnitName;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,item.goodsNumber + unit);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'小计：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.totalPrice);

          if (index != this.orderDetail.length - 1) {
            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************');
            topDistance += 20;
          }

        });

        topDistance += 25;
        LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,this.type==1?'应付':'应退'+'：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + (this.type==1?this.order.accountsPayable:this.order.refundAmount));

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,this.type==1?'实付':'实退'+'：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + (this.type==1?this.order.paymentAmount:this.order.realRefundAmount));

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let tenantInfo = this.tenantInfo;

        let height_tenantName = 0;
        if (tenantInfo.tenantName.length > 9) {
          height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_tenantName,tenantInfo.tenantName);

        if (tenantInfo.tenantName.length > 9) {
          topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,tenantInfo.account);

        //设定打印页面大小
        LODOP.SET_PRINT_PAGESIZE(3,580,24,"客户往来明细单据打印");
        LODOP.PREVIEW();
      },
      //打印大票
      printBig() {
        //this.$refs.printBarcode.showDialog();
        /*
        //根据div标签ID拿到div中的局部内容
        let backHtml = window.document.body.innerHTML;
        //把获取的 局部div内容赋给body标签, 相当于重置了 body里的内容
        window.document.body.innerHTML= document.getElementById("printcontent").innerHTML;
        //调用打印功能
        window.print();
        window.document.body.innerHTML = backHtml;
        */

        /*
        let bdhtml=window.document.body.innerHTML;
        let sprnstr="<!--startprint-->"; //开始打印标识字符串有17个字符
        let eprnstr="<!--endprint-->"; //结束打印标识字符串
        let prnhtml=bdhtml.substr(bdhtml.indexOf(sprnstr)+17); //从开始打印标识之后的内容
        prnhtml=prnhtml.substring(0,prnhtml.indexOf(eprnstr)); //截取开始标识和结束标识之间的内容
        window.document.body.innerHTML=prnhtml; //把需要打印的指定内容赋给body.innerHTML
        window.print(); //调用浏览器的打印功能打印指定区域
        window.document.body.innerHTML=bdhtml;//重新给页面内容赋值
        */
        //this.pagesetup_null();

        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
          return;
        }

        //打印
        document.getElementById('ifr').contentWindow.print();
      },
    }
  }
</script>

<style scoped>

  .orderNav{
    position: relative;
    width: 100%;
  }
  .navLeft{
  }
  .orderTime{
    float: left;
    margin-right: 10px;
  }
  .orderNum{
    float: left;

  }
  .navRight{

  }
  .printOrder{
    float: right;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .cancelOrder{
    float: right;
    margin-left: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  /*订单表格*/
  .orderTable{
    margin-bottom: 10px;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 10px !important;
  }
  /*总计*/
  .sum{
    margin-bottom: 55px;
  }
  .sumMoney{
    float: left;
    margin-right: 10px;
  }
  .realMoney{
    float: left;
    margin-right: 10px;
  }
  .debtMoney{
    float: left;
  }
  .horizontalLine{
    width: 100%;
    height: 3px;
    border: 3px solid #e9eef3;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .remark{
    margin-bottom: 50px;
  }
  .remark div{
    text-align: left;
  }
  .sumTitle{
    float: left;

  }
  .sumMoney{
    float: left;
    margin-right: 10px;
  }
  .sumTitle{
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    font-size: 16px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .sumMoney{
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }
  .sumMoney:after{
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 6px;
  }
  .paddingBottom{
    padding-bottom: 30px;
  }
</style>
