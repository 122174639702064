<template>
  <el-container class="container">
    <el-header>
      <div class="leftLogo">
        <el-image class="logoImg" :src="imgUrls.logo"></el-image>
      </div>
      <div class="rightTitle">
        <div class="titleText">慧台账 实名购药认证</div>
      </div>
    </el-header>
    <!--<el-scrollbar>-->
    <el-main>

      <div class="topBody">

        <div class="boxItem" @click="uploadIdentityCard">
          <div class="sfzBox">
            <div class="topContent">
              <i class="topIcon glyphicon glyphicon-user"></i>
            </div>
            <div class="mainContent">
              <!--<i class="mainBG glyphicon glyphicon-list-alt"></i>-->
              <span class="mainText">身份证识别</span>
            </div>
            <div class="bottomContent">
              <i class="bottomIcon glyphicon glyphicon-camera"></i>
            </div>
            <!--<div class="bottomLeft">
              &lt;!&ndash;<div class="leftTitle">当前店铺：</div>&ndash;&gt;
              <div class="leftDesc">店铺：{{tenantInfo.tenantName}}</div>
            </div>-->
          </div>

        </div>

        <!--<div class="boxItem">
          <div class="sfzBox">
            <div class="topContent">
              <i class="topIcon glyphicon glyphicon-user"></i>
            </div>
            <div class="mainContent">
              &lt;!&ndash;<i class="mainBG glyphicon glyphicon-list-alt"></i>&ndash;&gt;
              <span class="mainText">人脸认证</span>
            </div>
            <div class="bottomContent">
              <i class="bottomIcon glyphicon glyphicon-camera"></i>
            </div>
          </div>
        </div>-->
      </div>

      <div class="mainBody">
        <el-divider><i class="">{{tenantInfo.tenantName}}</i></el-divider>
        <el-form :model="client" :rules="rules" ref="client" label-width="80px" class="demo-client store-info-form">
          <el-form-item class="labelTitle" label="手机号" prop="clientPhone">
            <el-input class="inputLine" v-model="client.clientPhone" placeholder="请输入手机号" @blur="clientPhoneCheck"
                      @click.native="focusInput"></el-input>
            <div class="clearBox" v-show="client.clientPhone.length>0" @click="removeClientPhone"><img
              :src="imgUrls.del_grey"></div>
          </el-form-item>
          <el-form-item class="labelTitle" label="姓名" prop="realName">
            <div>
              <el-input class="inputLine" v-model="client.realName" placeholder="请输入真实姓名" maxlength="50"
                        @click.native="focusInput">
              </el-input>
              <div class="clearBox" v-show="client.realName.length>0" @click="removeRealName"><img
                :src="imgUrls.del_grey"></div>
            </div>

          </el-form-item>

          <el-form-item class="labelTitle" label="身份证号" prop="identityCode">
            <!--<el-input class="inputLine" v-model="client.identityCode" placeholder="请输入客户名称" maxlength="20"
                      @blur="checkidentityCodeIsRepeat"></el-input>-->
            <IDKeyboard class="idKeyboard" :index-num="indexNum"
                        @keyboard-input="handleKeyBoard" :old-value="client.identityCode"
                        @keyboard-blur="keyboardBlur"></IDKeyboard>
            <div class="nullError" v-show="nullError">
              <span>身份证号不能为空</span>
            </div>
            <div class="nullError" v-show="formatError">
              <span>请填写您本人的正确身份证号</span>
            </div>
            <div class="clearBox" v-show="client.identityCode.length>0" @click="removeIdentityCode"><img
              :src="imgUrls.del_grey"></div>
          </el-form-item>

          <el-form-item class="labelTitle" label="详细地址" prop="addressDesc">
            <el-input id="stockDescription" class="inputCoreDesc" :rows="2"
                      type="textarea" maxlength="100" show-word-limit v-model="client.addressDesc"
                      placeholder="请输入详细地址" @click.native="focusInput"></el-input>
            <div class="clearBoxArea" v-show="client.addressDesc.length>0" @click="removeAddressDesc"><img
              :src="imgUrls.del_grey"></div>
          </el-form-item>
          <div class="cropBox">
            <el-divider class="cropLine"><i class="">种植作物</i></el-divider>
            <!--<div class="searchCrop">
              <el-input placeholder="搜索作物名称" v-model="keywords" class="input-with-select">
                <el-button slot="append" class="searchIcon" icon="el-icon-search"></el-button>
              </el-input>
            </div>-->
            <el-tabs type="border-card">
              <el-tab-pane v-for="item in cropTree" :label="item.categoryName" :key="item.categoryId">
                <el-checkbox-group v-model="checkCrops" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="itemChildren in item.childrenNode" :key="itemChildren.categoryId"
                               :label="itemChildren.categoryId" @change="cropCheck(itemChildren)">
                    {{itemChildren.categoryName}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-tab-pane>
            </el-tabs>
            <div class="cropCheckBox">
              <el-tag class="cropTag"
                      :key="tag.categoryId"
                      v-for="tag in checkCropsDetail"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag)">
                <template>
                  <div class="inputBox">
                    <div class="inputTitle">{{tag.categoryName}}：</div>
                    <el-input-number class="cropInput" v-model="tag.num" :step="1"
                                     @change="categoryNumberChange(tag)" :min="1" :max="10000"
                                     @blur="categoryReCheck(tag)"
                                     label="请输入亩数"></el-input-number>
                    <span class="numUnit">亩</span>
                  </div>
                </template>
              </el-tag>
            </div>

          </div>
          <el-drawer class="drawerBox"
                     title="选择相机"
                     :visible.sync="drawer"
                     :direction="direction"
          >
            <div @click="drawerCamera" class="checkCamera">选择相机拍摄
              <div>
                <input type="file" id="image" accept="image/*" capture="camera" multiple=""
                       style=" position: absolute; width: 100%;height: 50%;left: 0;right: 0;bottom: 0;top: 0; opacity: 0;">
                <img src="" id="identityImg" style="display: none"/>
              </div>
            </div>
            <div @click="drawerPic" class="checkCamera">从相册中选择
              <div class="fileInput">
                <input id="selectPicFile" ref="file" type="file" v-on:change="uploadSource"
                />
              </div>
            </div>
          </el-drawer>
          <el-button class="storeInfoBtn" type="primary" @click="save('client')">开始认证</el-button>
        </el-form>
      </div>
    </el-main>
    <!--</el-scrollbar>-->
    <!--<el-footer @click="save('client')">开始认证</el-footer>-->

  </el-container>
</template>

<script>
  import IDKeyboard from '../../../components/IDKeyboard'

  export default {
    name: 'IdentityScan',
    components: { IDKeyboard },
    data () {
      let validate_clientName = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value && getLength(value) > 50) {
          callback(new Error(this.type === 3 ? '姓名最多不能超过50个字符' : '姓名最多不能超过50个字符'))
        } else if (!value) {
          callback(new Error('请填写真实姓名'))
        } else {
          callback()
        }
      }
      let validate_identityCode = (rule, value, callback) => {

        if (!value) {
          callback(new Error('请填写身份证号'))
        } else if (value.indexOf("*")===-1 && !Pattern.validate('idCode', value)) {
          callback(new Error('请填写您本人的正确身份证号'))
        } else {
          callback()
        }
      }
      let validate_clientPhone = (rule, value, callback) => {
        if (value && !Pattern.validate('mobile', value)) {
          callback(new Error('请输入正确的手机号'))
        } else if (!value) {
          callback(new Error('请填写手机号'))
        } else {
          callback()
        }
      }
      let validate_addressDesc = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value && getLength(value) > 500) {
          callback(new Error('备注最多不能超过500个字符'))
        } else if (!value) {
          callback(new Error('请填写详细地址'))
        } else {
          callback()
        }
      }

      return {
        message: '实名认证页面',
        tenantInfo: {},
        client: {
          realName: '',
          identityCode: '',
          clientPhone: '',
          addressDesc: ''
        },
        imgUrls: {
          logo: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/main/login_logo_single.png',
          del_grey: 'https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/recipe/del_grey.png',
        },
        cropTree: [],
        cropNoTree: [],
        checkCrops: [],//选中的id
        checkCropsDetail: [],//选中的作物对象
        inputValue: '',
        keywords: '',
        num: 1,
        drawer: false,//抽屉
        direction: 'btt',
        cameraPick: false,

        rules: {
          realName: [
            {
              required: true,
              validator: validate_clientName,
              trigger: 'blur',
            }
          ],
          /*identityCode: [
            {
              required: true,
              validator: validate_identityCode,
              trigger: 'blur'
            }
          ],*/
          clientPhone: [
            {
              required: true,
              validator: validate_clientPhone,
              trigger: 'blur'
            }
          ],
          addressDesc: [
            {
              required: true,
              validator: validate_addressDesc,
              trigger: 'blur'
            }
          ],

        },
        //联想下拉列表输入框
        restaurants: [],
        state: '',
        timeout: null,
        //作物选择
        isIndeterminate: true,
        indexNum: 0, //必填字段，为键盘索引，每个键盘必须具有唯一值
        //oldValue: '', //从父组件传递给子组件的值
        nullError: false,
        formatError: false,
        secretPsd:'',//加密证件号
        decryptPsd:'',//解密证件号
        decryptCode:'',//解密就*加密证件号
      }
    },
    mounted () {

      //document.title="实名认证"
      //console.log('当前路径: ', this.$route.query)
      if (this.$route.query) {
        this.tenantInfo.tenantId = this.$route.query.tenantId
        this.tenantInfo.tenantName = this.$route.query.tenantName
        this.tenantInfo.createUser = this.$route.query.createUser
      }
      this.getCropTree()
      this.getCropNoTree()
    },
    methods: {
      focusInput () {
        var winHeight = $(window).height()  //获取当前页面高度
        let storeInfoBtn = $('.storeInfoBtn')[0]
        $(window).resize(function () {
          var thisHeight = $(this).height()
          if (winHeight - thisHeight > 140) {
            //键盘弹出
            storeInfoBtn.style.position = 'static'
          } else {
            //键盘收起
            storeInfoBtn.style.position = 'fixed'
          }
        })
      },
      handleClose (tag) {
        this.checkCropsDetail.splice(this.checkCropsDetail.indexOf(tag), 1)
        this.checkCrops.splice(this.checkCrops.indexOf(tag.categoryId), 1)
      },
      handleDrawerClose (done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {
          })
      },
      handleKeyBoard (value) {
        //value为在输入框里面输入的值
        //console.log('键盘输入：', value, '客户身份证号：', this.client.identityCode)
        this.client.identityCode = value
      },
      getIdentityClass () {
        let idClass = document.getElementsByClassName('el-form-item__label')
        setTimeout(function () {
          for (let i = 0; i < idClass.length; i++) {
            if (idClass[i].innerHTML === '身份证号') {
              //console.log('idClass: ', idClass[i])
              var span = document.createElement('span')
              span.style.color = 'red'
              span.style.float = 'left'
              span.innerHTML = '*'
              idClass[i].appendChild(span)
            }
          }
        })

      },
      clientPhoneCheck () {
        if (Pattern.validate('mobile', this.client.clientPhone)) {
          this.clientPhoneAgain()
        }
      },
      clientPhoneAgain () {
        //console.log('11: ', this.client.clientPhone)
        this.$axios.post(process.env.VUE_APP_SERVER + '/psi/client/selectByclientPhone?clientPhone='+this.client.clientPhone)
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200 && Tool.isNotEmpty(response.data.data)) {
                //console.log('获取的客户信息：response：', response)
                this.client.tenantId=this.tenantInfo.tenantId
                this.client.realName=response.data.data.realName
                this.client.addressDesc=response.data.data.addressDesc

                if(!Tool.isEmpty(response.data.data.identityCode)){
                  this.secretPsd=response.data.data.identityCode;
                  this.decryptPsd=BaseDecrypt.decode(this.secretPsd)
                  //console.log("当前身份证：",this.secretPsd,"解密：",this.decryptPsd)
                  let subID=this.decryptPsd.substring(1,17)
                  let subSecret=[]
                  for(let i=0;i<subID.length;i++){
                    subSecret.push("*")
                  }
                  this.decryptCode=this.decryptPsd.substr(0,1)+subSecret.toString().replace(/,/g,'')+this.decryptPsd.substr(this.decryptPsd.length-1)
                  this.client.identityCode=this.decryptCode;
                  //console.log("identityCode: ",this.identityCode)
                }
                this.checkCropsDetail=[];
                this.checkCrops=[];
                let crops=response.data.data.clientCropAreaList;
                for(let i=0;i<crops.length;i++){
                  crops[i].num=1;
                  this.checkCrops.push(crops[i].categoryId)
                  this.checkCropsDetail.push(crops[i])
                }
              }
            }
          })
      },
      keyboardBlur () {
        //console.log('失去焦点：', this.client.identityCode)
        let myKeyboard = document.getElementsByClassName('myKeyboard')[0]
        if (Tool.isEmpty(this.client.identityCode)) {
          this.nullError = true
          this.formatError = false
          myKeyboard.firstChild.style.borderColor = '#F56C6C'
        } else if (this.client.identityCode.indexOf("*")===-1 && !Pattern.validate('idCode', this.client.identityCode)) {
          this.nullError = false
          this.formatError = true
          myKeyboard.firstChild.style.borderColor = '#F56C6C'
        } else {
          this.nullError = false
          this.formatError = false
          myKeyboard.firstChild.style.borderColor = '#DCDFE6'
          this.checkidentityCodeIsRepeat()
        }
        this.$forceUpdate()
      },
      querySearchAsync (queryString, cb) {

        if (!this.client.clientName || this.client.clientName == '') {
          clearTimeout(this.timeout)
          cb([])
          return
        }

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/client/selectClientByName?clientName=' + this.client.clientName,
            method: 'GET',
          })
            .then((res) => {
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.restaurants = res.data.data
                  cb(this.restaurants)
                } else {
                  cb([])
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                cb([])
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
        }, 1000 * Math.random())
      },
      handleSelect (item) {
        this.client.clientName = item.clientName
        this.client.identityCode = item.identityCode
        this.client.clientPhone = item.clientPhone
      },
      //检查身份证号是否重复
      checkidentityCodeIsRepeat () {
        //console.log("失去焦点：",this.client.identityCode)

        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/client/selectByIdentityCode?identityCode=' + BaseDecrypt.encode(this.client.identityCode),
          method: 'POST'
        })
          .then((res) => {
            //console.log("res: ",res)
            if (!Tool.isEmpty(res.data.data)) {
              //console.log("data: ",res.data.data)
              this.client.realName=res.data.data.realName
              this.client.addressDesc=res.data.data.addressDesc

              if(!Tool.isEmpty(res.data.data.identityCode)){
                this.secretPsd=res.data.data.identityCode;
                this.decryptPsd=BaseDecrypt.decode(this.secretPsd)
                let subID=this.decryptPsd.substring(1,17)
                let subSecret=[]
                for(let i=0;i<subID.length;i++){
                  subSecret.push("*")
                }
                this.decryptCode=this.decryptPsd.substr(0,1)+subSecret.toString().replace(/,/g,'')+this.decryptPsd.substr(this.decryptPsd.length-1)
                this.client.identityCode=this.decryptCode;
                //console.log("identityCode: ",this.identityCode)
              }
              this.checkCropsDetail=[];
              this.checkCrops=[];
              let crops=res.data.data.clientCropAreaList;
              for(let i=0;i<crops.length;i++){
                crops[i].num=1;
                this.checkCrops.push(crops[i].categoryId)
                this.checkCropsDetail.push(crops[i])
              }
            }
          })
      },
      getCropTree () {
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/cropCategory/queryCropCategoryTree',
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code != 200) {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              } else {
                let crops = res.data.data
                this.cropTree = crops
                this.getIdentityClass()
              }
            }
          })
      },
      getCropNoTree () {
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/cropCategory/queryAll',
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code != 200) {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              } else {
                let crops = res.data.data
                //console.log(crops)
                for (let i = 0; i < crops.length; i++) {
                  crops[i].num = 1
                }
                this.cropNoTree = crops
              }
            }
          })
      },
      categoryNumberChange (tag) {
        if (tag.num < 1 || Tool.isEmpty(tag.num)) {
          tag.num = 1
          for (let i = 0; i < this.checkCropsDetail.length; i++) {
            if (this.checkCropsDetail[i].categoryId === tag.categoryId) {
              this.checkCropsDetail.splice(i, 1, tag)
              this.$forceUpdate()
            }
          }
        }
      },
      categoryReCheck (tag) {
        let _this = this
        setTimeout(function () {
          let inputs = document.getElementsByClassName('cropInput')
          for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].childNodes[2].childNodes[1].getAttribute('aria-valuenow') === 'undefined') {
              inputs[i].childNodes[2].childNodes[1].value = 1
            }
          }

          //console.log('inputs: ', inputs[0].childNodes[2].childNodes[1].getAttribute('aria-valuenow'))
          if (tag.num < 1 || Tool.isEmpty(tag.num)) {
            tag.num = 1
            for (let i = 0; i < _this.checkCropsDetail.length; i++) {
              if (_this.checkCropsDetail[i].categoryId === tag.categoryId) {
                _this.checkCropsDetail.splice(i, 1, tag)
                //console.log('改变e2：', _this.checkCropsDetail)
                _this.$forceUpdate()
              }
            }
          }
        }, 10)

      },
      handleCheckedCitiesChange (value) {
        let cropCheckBox = document.getElementsByClassName('cropCheckBox')[0]
        this.checkCropsDetail = []
        for (let i = 0; i < value.length; i++) {
          for (let j = 0; j < this.cropNoTree.length; j++) {
            if (value[i] === this.cropNoTree[j].categoryId) {
              //console.log(value[i], 'this.cropNoTree[j]', this.cropNoTree[j])
              this.checkCropsDetail.push(this.cropNoTree[j])
            }
          }
        }

      },
      cropCheck (value) {
        //console.log('checkValue: ', value)

      },
      removeRealName () {
        this.client.realName = ''
      },
      removeIdentityCode () {
        this.client.identityCode = ''
      },
      removeClientPhone () {
        this.client.clientPhone = ''
      },
      removeAddressDesc () {
        this.client.addressDesc = ''
      },
      save (formName) {
        let _this = this
        //console.log("客户ID：",_this.client.identityCode)
        let resMsg = ''
        let myKeyboard = document.getElementsByClassName('myKeyboard')[0]
        if (Tool.isEmpty(_this.client.identityCode)) {
          _this.nullError = true
          _this.formatError = false
          myKeyboard.firstChild.style.borderColor = '#F56C6C'
        } else if (_this.client.identityCode.indexOf("*")===-1 && !Pattern.validate('idCode', _this.client.identityCode)) {
          _this.nullError = false
          _this.formatError = true
          myKeyboard.firstChild.style.borderColor = '#F56C6C'
        } else if(_this.client.identityCode.indexOf("*")!==-1){
          _this.client.identityCode=_this.secretPsd
          _this.nullError = false
          _this.formatError = false
          myKeyboard.firstChild.style.borderColor = '#DCDFE6'
        } else {
          _this.nullError = false
          _this.formatError = false
          myKeyboard.firstChild.style.borderColor = '#DCDFE6'
        }
        _this.client.cropList = _this.checkCropsDetail
        _this.$refs[formName].validate((valid) => {
          if (valid) {
            if (_this.client.cropList.length > 0 && !_this.nullError && !_this.formatError) {
              //console.log("当前的identityCode：",_this.client.identityCode,_this.client.identityCode.indexOf("*"))
              _this.client.identityCode=_this.client.identityCode.toString();
              if(_this.client.identityCode.indexOf("*")!==-1){
                _this.client.identityCode=_this.secretPsd
                //console.log('加密的证件号1：', _this.secretPsd)
                //console.log('解密的证件号1：', _this.decryptPsd)
              }else if(Pattern.validate('idCode', _this.client.identityCode)){
                _this.secretPsd = BaseDecrypt.encode(_this.client.identityCode)
                _this.decryptPsd = BaseDecrypt.decode(_this.secretPsd)
                _this.client.identityCode = _this.secretPsd
                _this.client.idCode=_this.secretPsd
              }else{
                _this.secretPsd = BaseDecrypt.encode(_this.client.identityCode)
                _this.decryptPsd = BaseDecrypt.decode(_this.secretPsd)
                _this.client.identityCode = _this.decryptPsd
                _this.client.idCode=_this.decryptPsd
                //console.log('加密的证件号2：', _this.secretPsd)
                //console.log('解密的证件号2：', _this.decryptPsd)
              }
              //console.log('当前证件号：', _this.client.identityCode,"当前secret：", _this.secretPsd)
              //MTUyNjI5MTk5MDA2MTkwMDEx
              _this.client.createUser = _this.tenantInfo.createUser
              _this.client.tenantId=_this.tenantInfo.tenantId
              _this.client.tenantName=_this.tenantInfo.tenantName


              //console.log('当前客户：', _this.client)
              Loadings.show()
              /*this.client.tenantName='七小店'
              this.$router.push({
                name:'identityAuthentication',
                params:this.client
              })*/
              _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/client/authentication', _this.client)
                .then((response) => {
                  Loadings.hide()
                  if (response.status === 200) {
                    if (response.data.code === 200) {
                      /*this.$message({
                        type: 'success',
                        message: '实名购药认证成功！',
                        duration: 2000,
                        offset: 110
                      })*/

                      _this.$router.push({
                        name:'identityAuthentication',
                        params:_this.client,
                      })
                    } else {
                      _this.$message({
                        type: 'warning',
                        message: response.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    _this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                })
            } else if (_this.client.cropList.length < 1) {
              _this.$message({
                type: 'error',
                message: '请选择种植作物',
                duration: 2000,
                offset: 60
              })
            }
          } else {
            let error = ''
            //console.log('client: ', _this.client)
            _this.$message({
              type: 'error',
              message: '身份信息错误，请如实填写 !',
              duration: 2000,
              offset: 110
            })
            return false
          }
        })
        let psd = _this.client.identityCode.split('')
        for (let i = 0; i < psd.length; i++) {
          psd[i] = '*'
        }
        this.client.identityCode = psd.toString().replace(/,/g, '')
      },
      uploadIdentityCard () {
        let _this = this
        _this.drawer = true
      },
      drawerCamera () {
        let _this = this
        this.drawer = false
        this.cameraPick = true
        //this.drawer=false
        document.getElementById('image').addEventListener('change', function () {
          var reader = new FileReader()
          reader.onload = function (e) {
            getImg(this.result)
          }
          reader.readAsDataURL(this.files[0])
          //表单方式提交数据
          let formData = new window.FormData()
          let file = this.files[0]
          //console.log('file : --', file)
          formData.append('file', file)
          upload(formData)
        }, true)
        var getImg = function (res) {
          document.getElementById('identityImg').src = res
        }
        var upload = function (formData) {
          //Loadings.show();
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/client/uploadImg', formData)
            .then((response) => {
              let resp = response.data
              //将获取的resp放入回调，传到页面
              _this.afterUpload(resp)
            })
        }
      },
      drawerPic () {
        this.drawer = false
        this.cameraPick = false
        $('#selectPicFile').trigger('click')
      },

      uploadSource () {

        let _this = this
        let formData = new window.FormData()
        //通过别名获取file，不需要id
        let file = _this.$refs.file.files[0]
        //console.log('file: ', file)
        let shardKey16 = hex_md5(file.name + file.size + file.type) //md5:16进制处理file
        let shardKey10 = parseInt(shardKey16, 16)//转换为10进制
        let shardKey62 = Tool.md_10to64(shardKey10)

        //file类型判断["jpg","jpeg","png","gif"]
        let suffixType = ['jpg', 'jpeg', 'png', 'gif']
        let fileName = file.name
        let suffix = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase()
        //定义后缀校验变量
        let validateSuffix = false
        //循环后缀类型
        for (let i = 0; i < suffixType.length; i++) {
          if (suffixType[i].toLowerCase() === suffix) {
            validateSuffix = true
            break
          }
        }
        if (!validateSuffix) {
          this.$toast.error('身份证图片格式不正确，请提交【jpg/jpeg/png/gif】格式图片', 'center')
          //在拦截后，避免onchange事件不触发，需要清空id标签
          $('#selectPicFile').val('')
          return
        }

        formData.append('file', file)
        //上传之前，先检查分片
        //_this.checkShardKey(param);
        _this.upload(formData)

      },
      upload (formData) {
        let _this = this
        let fileReader = new FileReader()
        /*let file = _this.$refs.file.files[0];
        formData.append('file', file)*/
        //先加载fileReader，读取到e，再进行数据shardFile转换
        _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/client/uploadImg', formData)
          .then((response) => {
            let resp = response.data
            //将获取的resp放入回调，传到页面
            _this.afterUpload(resp)
            //避免on-change事件在第二次相同类型点击后，不触发，将id对应标签设置为空.
            $('#selectPicFile').val('')

          })
      },

      afterUpload (resp) {
        if (resp.code === 200) {
          let res = resp.data
          if (res.code === 'success') {
            this.client.realName = res.realName
            this.client.identityCode = res.identityCode
            this.client.addressDesc = res.addressDesc
            if (Pattern.validate('idCode', this.client.identityCode)) {
              this.nullError = false
              this.formatError = false
            }
            let formItem = document.getElementsByClassName('el-form-item')
            let formItemError = document.getElementsByClassName('el-form-item__error')
            for (let i = 0; i < formItem.length; i++) {
              //console.log('calss: ', formItem[i].className)
              if (formItem[i].className.indexOf('is-error')) {
                Tool.removeClass(formItem[i], 'is-error')
              }
            }
            for (let j = 0; j < formItemError.length; j++) {
              formItemError[j].parentNode.removeChild(formItemError[j])
            }
            this.$forceUpdate()
          } else if (res.code === 'fail') {
            this.$toast.showAuth('请上传正确的身份证图片！', 'center')
          }
        }
      },
    },
  }
</script>

<style scoped>
  .container {
    background: #E9EEF3;
    padding: 0;
    /*overflow-x: hidden;
    overflow-y: hidden;*/
    width: 100%;
  }

  .el-header {
    background-color: #1684F0;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 9;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 40%);
  }

  .el-footer {
    background-color: #1684F0;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .storeInfoBtn {
    background: #1684F0;
    border-color: #1684F0;
    color: #ffffff;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    padding: 0;
    position: fixed;
    bottom: 0;
    border-radius: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 22px 0 rgb(0 0 0 / 60%);
    z-index: 1;
  }

  .el-main {
    margin-top: 65px;
    background-color: #ffffff;
    color: #333;
    text-align: center;
    padding: 10px;
  }

  .leftLogo {
    background: #ffffff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 10px;
    margin-top: 5px;
    float: left;
  }

  .logoImg {
    width: 35px;
    height: 35px;
    float: left;
    margin-left: 7px;
    margin-top: 7px;
  }

  .titleText {
    color: #ffffff;
    font-size: 18px;
  }

  .topBody {
    width: 100%;
    margin-bottom: 110px;
  }

  .topBody .boxItem {
    width: 100%;
  }

  .topBody .sfzBox {
    width: 100%;
    height: 80px;
    float: left;
    background-image: url("https://wt-resource-public.oss-cn-beijing.aliyuncs.com/jxc_fourth/login/identity_card-3.png");
    background-position: center;
    position: relative;
    border-radius: 5px;
    /*overflow: hidden;*/
  }

  .topBody .sfzBox {
    margin-right: 10px;
  }

  .topBody .sfzBox .topContent {
    position: relative;
    width: 100%;
  }

  .topBody .sfzBox .topContent .topIcon {
    color: #ffffff;
    float: left;
    margin-top: 5px;
    margin-left: 5px;
  }

  .topBody .sfzBox .mainContent {
    position: relative;
  }

  .topBody .sfzBox .mainContent .mainBG {
    position: absolute;
    transform: rotate(-60deg);
    font-size: 100px;
    left: 50px;
    margin-top: -20px;
    color: #ccdbec;
    z-index: 1;
  }

  .topBody .sfzBox .mainContent .mainText {
    margin-top: 50px;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    line-height: 80px;
    margin-left: -15px;
  }

  .topBody .boxItem .sfzBox .bottomContent {
    position: relative;
    margin-top: -20px;
  }

  .topBody .boxItem .sfzBox .bottomContent .bottomIcon {
    background: #00a2fc;
    padding: 10px;
    border-radius: 50%;
    font-size: 24px;
    color: white;
  }

  /*表单*/
  .mainBody {
    margin-top: 120px;
  }

  .el-divider--horizontal {
    margin: 20px 0;
  }

  /deep/ .el-checkbox__inner {
    z-index: unset;
  }

  /deep/ .el-input-number__decrease, /deep/ .el-input-number__increase {
    z-index: unset;
  }

  /*种植box*/
  .cropBox {
    margin-bottom: 70px;
  }

  .cropLine {
    margin-top: 40px;
  }

  .searchCrop {
    margin: 10px auto;
  }

  .searchIcon {
    font-size: 20px !important;
  }

  /deep/ .el-tabs__nav {
    background: #1684F0;
    z-index: unset;
  }

  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    color: #ffffff;
    padding: 0 10px;
  }

  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:nth-child(2) {
    padding-left: 10px !important;
  }

  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #1684F0 !important;
  }

  /deep/ .el-tabs--border-card {
    border-radius: 6px;
  }

  /deep/ .el-tabs__nav-wrap.is-scrollable {
    padding: 0 30px;
  }

  /deep/ .el-tabs__nav-next, /deep/ .el-tabs__nav-prev {
    color: #1684F0;
    background: #e2e7ec;
    font-size: 30px;
    line-height: 40px;
  }

  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {

  }

  /*选中标签*/
  .cropCheckBox {
    margin-top: 15px;
  }

  /deep/ .el-tag {
    width: 100%;
  }

  .cropTag {
    background-color: unset;
    border-color: unset;
    /*height: 30px;*/
    /*max-width: 60px;*/
    /*line-height: 30px;*/
    /*float: left;*/
    padding: 0;
    font-size: 14px;
    color: #999999;
    white-space: nowrap;
    border-width: unset;
    border-style: unset;
    border-radius: unset;
    box-sizing: unset;

  }

  .inputBox {
    /*float: left;*/
    margin-left: -40px;
    height: 20px;
  }

  .inputTitle {
    font-size: 15px;
    display: inline-flex;
    width: 100px;
  }

  .inputLine {
    line-height: 30px;
    width: 100%;
  }

  /deep/ .el-input-number.is-controls-right .el-input__inner {
    /*padding-left: 10px;
    padding-right: 10px;
    height: 30px;
    line-height: 30px;*/

  }

  /deep/ .el-input-number {
    width: 40%;
  }

  /deep/ .el-tag .el-icon-close {
    right: -140px;
    line-height: 20px;
    font-size: 14px;
    width: 20px;
    height: 20px;
    background: #409eff;
    color: white;
    border-radius: 50%;
    text-align: center;
    position: relative;
    cursor: pointer;
    top: -18px;
    vertical-align: middle;
  }

  /*选择相机*/
  /deep/ .el-drawer {
    height: 170px !important;
  }

  /deep/ .el-drawer__header {
    padding: 10px 10px 0;
    margin-bottom: 10px;
  }

  .drawerBox div {
    width: 50%;
    margin: 10px auto;
    line-height: 35px;
    /*border-bottom: 1px solid #e3e3e3;*/
    color: #409eff;
    cursor: pointer;
  }

  .fileInput {
    position: absolute;
    right: -1000px;
  }

  .nullError {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    transition: 1s;
  }

  /deep/ .el-textarea .el-input__count {
    background: unset;
    bottom: -10px;
  }

  .redStar {
    /*content: "*";*/
    color: #F56C6C !important;
  }

  .checkCamera {
    font-size: 20px;
  }

  .bottomLeft {
    position: absolute;
    top: 86px;
    float: left;
    width: 100%;
  }

  .leftTitle {
    float: left;
    font-size: 14px;
  }

  .leftDesc {
    float: left;
    border: 1px solid #2a92ee;
    border-radius: 5px;
    padding: 2px 5px;
    background: #ecf5ff;
    max-width: 122px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .clearBox {
    position: absolute;
    right: 10px;
    bottom: 5px;
    line-height: 20px;
  }

  .clearBoxArea {
    position: absolute;
    right: 10px;
    bottom: 15px;
    line-height: 20px;
  }

  .clearBoxArea img {
    width: 20px;
    margin-bottom: 10px;
  }

  .clearBox img {
    width: 20px;
    margin-bottom: 10px;
  }

  .cropInput {
    /*line-height: 30px;*/
  }

  /deep/ .el-input-number__decrease {
    z-index: 1;
  }

  /deep/ .el-input-number__increase {
    z-index: 1;
  }

  .numUnit {
    font-size: 15px;
    margin-left: 5px;
  }
</style>
