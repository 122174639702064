<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail">
      <div slot="title" class="header-title">
        <div style="padding:15px 20px;">
          <div class="title">{{this.type!==3?'回收':'处置'}}单据详情</div>
          <div class="verticalLine"></div>
          <div class="titleDesc">客户</div>
          <div class="clientDesc">
            （<span class="supplierName" :underline="false">{{clientName}}</span>）
          </div>

        </div>
      </div>
      <recycle-order-detail :player-id="'dialogDetail'" ref="recycleOrderDetail" @orderCancel="orderCancel"></recycle-order-detail>
    </el-dialog>
  </div>
</template>

<script>
  import RecycleOrderDetail from './RecycleOrderDetail'

  export default {
    name: 'DialogRecycleOrderDetail',
    components: { RecycleOrderDetail },
    props: {},
    data () {
      return {
        //播放器实例
        dialogPlayer: {},
        clientName: {
          default: '-'
        },
        centerDialogVisible: false,
        orderId:'',
        type:1,
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      getOrderId (orderId, clientName,type) {
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        this.clientName = clientName
        this.orderId=orderId
        if(type){
          this.type=type
        }
        this.$nextTick(() => {
          this.$refs.recycleOrderDetail.getDetailById(orderId, clientName,type)
        })
      },

      playerVOD (vod, title) {
        this.centerDialogVisible = true
        this.playerTitle = title
        this.$nextTick(() => {
          this.$refs.ossPlayer.playVOD(vod)

        })
      },
      orderCancel(flag){
        if(flag){
          this.centerDialogVisible=false;
          this.$emit('cancelOrder',this.orderId);
        }

      }
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog{
    border-radius: 10px;
  }
  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }
  /deep/ .el-dialog__body{
    padding: 10px;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .header-title .title{
    float: left;
    cursor: pointer;
  }
  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }
  .header-title .titleDesc{
    float: left;
    margin-left:30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }
  .header-title .clientDesc{
    float: left;
  }
  .header-title .supplierName{
    display: inline-block;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }
  .header-title .supplierName:hover,.header-title .supplierName:focus{
    color: #f6e483;
  }
  /deep/ .el-dialog__close{
    color: #ffffff !important;
  }
</style>
