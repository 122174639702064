<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    name:'App',
    data(){
      return{
        message:"威拓进销存PC端入口"
      }
    },
    mounted() {
      if (!LocalStorage.getCookie(TOKEN)) {
        LocalStorage.clearAll();
      }

      //关闭浏览器或刷新页面时，清除LocalStorage数据
      window.onbeforeunload = function(e) {
        if (!LocalStorage.get(IS_EXISTS_SHOPINFO)) {
          LocalStorage.remove(TOKEN);
          LocalStorage.remove(TENANT_INFO);
          LocalStorage.remove(LOGIN_USER);
          LocalStorage.remove(IS_LOG);
        }
      }
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*overflow: hidden;*/
}
.el-tooltip__popper{
  max-width: 600px;
}
body{
  overflow: hidden;
}
</style>
