<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="sureDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">{{type===3?'处置开单':'回收开单'}} - 确认订单</div>
      </div>
      <div class="orderNav">
        <div class="navLeft">
          <el-tag class="showCheck">当前{{type===3?'处置单位':'交回者'}}
            <span v-if="checkClientIds.length>0">{{strClients}}</span></el-tag>
          <el-tag class="showCheck" type="info">购物车商品数量：
            <span class="cartNum">{{goodsCart.num}}</span></el-tag>
        </div>
        <div class="navRight">
          <el-button v-if="getAuthorityMenu('400102') || getAuthorityMenu('400302')" class="searchBtn" type="danger" icon="el-icon-s-order" @click="save">确认开单</el-button>
          <!--<el-button class="printOrder" type="warning" icon="el-icon-printer" @click="printOrder()">打印单据</el-button>-->
        </div>

      </div>

      <div class="dialogBody">

        <div class="mainForm">
          <el-table
            ref="multipleTable"
            tooltip-effect="dark"
            :data="goodsCart.wasteRecoveryDetailList===undefined?goodsCart.wasteDisposeDetailList:goodsCart.wasteRecoveryDetailList"
            border
            height="400px"
            highlight-current-row
            class="previous-row"
            @current-change="handleCurrentChange"
            :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
          >
            <el-table-column type="index" label="序号" class="indexCenter" width="50" fixed></el-table-column>
            <el-table-column property="bigPackageId" label="包装分类" width="100" fixed>
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.bigPackageId===1?'primary':scope.row.bigPackageId===2?'success':scope.row.bigPackageId===3?'warning':'info'">
                  {{RECYCLE_CATEGORY | optionObjectKV(scope.row.bigPackageId)}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column property="bigPackageId" label="包装规格">
              <template slot-scope="scope">
                <el-tag type="info" class="packageUnitDesc"
                        v-if="scope.row.bigPackageId===1 || scope.row.bigPackageId===2">
                  {{scope.row.bigPackageId===1?'农药：':scope.row.bigPackageId===2?'肥料：':scope.row.bigPackageId===3?'农膜：':'其他：'}}
                  {{VESSEL_TYPE | optionObjectKV(scope.row.vesselPackageId)}}+
                  {{VESSEL_TYPE | optionObjectKV(scope.row.texturePackageId)}}+
                  {{VESSEL_TYPE | optionObjectKV(scope.row.sizePackageId)}}
                </el-tag>
                <el-tag type="info" class="packageUnitDesc" v-if="scope.row.bigPackageId===3">
                  {{scope.row.bigPackageId===3?'农膜：':''}}
                  {{VESSEL_TYPE | optionObjectKV(scope.row.vesselPackageId)}}
                </el-tag>
                <el-tag type="info" class="packageUnitDesc" v-if="scope.row.bigPackageId===4">
                  {{scope.row.bigPackageId===4?'其他：':''}}
                  无
                </el-tag>

              </template>
            </el-table-column>
            <el-table-column v-if="type!==3" property="recoveryNumber" label="数量" width="80" sortable>
              <template slot-scope="scope">
                <el-tag type="warning">
                  {{scope.row.recoveryNumber}} {{UNIT_TYPE | optionObjectKV(scope.row.numberPackageUnitId)}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type===3" property="disposeNumber" label="数量" width="80" sortable>
              <template slot-scope="scope">
                <el-tag type="warning">
                  {{scope.row.disposeNumber}} {{UNIT_TYPE | optionObjectKV(scope.row.numberPackageUnitId)}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type!==3" property="recoveryWeight" label="重量" width="80" :show-overflow-tooltip="true" sortable>
              <template slot-scope="scope">
                <el-tag type="info">
                  {{scope.row.recoveryWeight}} {{UNIT_TYPE | optionObjectKV(scope.row.weightPackageUnitId)}}
                </el-tag>
              </template>

            </el-table-column>
            <el-table-column v-if="type===3" property="disposeWeight" label="重量" width="80" :show-overflow-tooltip="true" sortable>
              <template slot-scope="scope">
                <el-tag type="info">
                  {{scope.row.disposeWeight}} {{UNIT_TYPE | optionObjectKV(scope.row.weightPackageUnitId)}}
                </el-tag>
              </template>

            </el-table-column>
            <el-table-column v-if="type!==3" property="packageLevel" label="支付金额" width="110" :show-overflow-tooltip="true" sortable>
              <template slot-scope="scope">
                <el-tag type="danger" class="showCenter">
                  ￥ {{scope.row.recoveryMoney}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column v-if="type===3" property="packageLevel" label="支付金额" width="110" :show-overflow-tooltip="true" sortable>
              <template slot-scope="scope">
                <el-tag type="danger" class="showCenter">
                  ￥ {{scope.row.disposeMoney}}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <el-row :gutter="10" class="rowForm">
          <el-col :span="7">
            <div class="totalMoney">
              <div class="label">开单时间：</div>
              <div class="timeCheck">
                <el-date-picker class="timePickers"
                                v-model="createTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                align="right"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </div>
            <div class="bottomMoney">
              <div class="totalMoney">
                <div class="label">回收支付总金额：</div>
                <div class="totalPrice">{{type===3?goodsCart.disposeMoney:goodsCart.recoveryMoney}} 元</div>
              </div>
            </div>
          </el-col>
          <el-col :span="17">
            <div class="bottomBox">
              <span class="title">备注信息：</span>
              <span>{{goodsCart.remark}}</span>
            </div>
          </el-col>
        </el-row>


      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {
      /*checkClientIds: {
        type:Array,
        default:null
      },
      checkClientNames:{
        type:Array,
        default:null
      },*/
    },
    data () {
      return {
        message: '选择供应商',
        clientName: {
          default: '-'
        },
        client: {},
        goodsCart: {
          clientId: 0,
          accountsPayable: 99.99,//应付金额
          paymentAmount: 99.99,//实付金额
          remark: '',
          details: []
        },
        createTime:'',
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          }, {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          }, {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
        },
        clientIds: [],
        checkClientIds: 0,
        sureDialogVisible: false,
        searchInput: '',
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        currentRow: null,
        pageShow: true,
        //分页
        list: {
          type: Function,
          default: null
        },
        //显示的页码数，共50页，显示5页，其他用...表示
        itemCount: Number,
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        input: null,
        //选中
        checkClients: 0,
        strClients: '',
        type: 1,//type=1商品管理，=2进货
        RECYCLE_CATEGORY: RECYCLE_CATEGORY,
        VESSEL_TYPE: VESSEL_TYPE,
        UNIT_TYPE: UNIT_TYPE,
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      sureOrders (goodsCart, type) {
        if (type === 2) {
          this.type = 2
          this.checkClientIds = goodsCart.clientId
          this.clientIds = goodsCart.clientId
        }
        if (type === 1) {
          this.type = 1
          this.checkClientIds = goodsCart.clientId
          this.clientIds = goodsCart.clientId
        }
        if (type === 3) {
          this.type = 3
          this.checkClientIds = goodsCart.disposeUnitId
          this.clientIds = goodsCart.disposeUnitId
        }

        this.clientNames = goodsCart.clientName
        this.strClients = goodsCart.clientName.toString()
        //注意，必须先打开对话框才能获取对话框的ref
        this.sureDialogVisible = true
        let now=new Date();
        this.createTime=this.$moment(now).format('yyyy-MM-DD HH:mm:ss')
        this.goodsCart = goodsCart
      },

      reSearch (data) {
        this.getSearch(1, data)
      },

      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      handleChange (value) {
        this.size = value[0]
        this.$emit('pageSizeChange', this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
        this.getSearch(this.input)
      },

      //多选
      handleCurrentChange (val) {
        this.currentRow = val
      },
      save () {
        this.goodsCart.createTime=this.createTime
        this.goodsCart.clientName=this.goodsCart.clientName.toString()
        if(this.type===1 || this.type===2){
          Loadings.show()
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/save', this.goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '回收开单成功！', duration: 2000, offset: 110 });
                  setTimeout(() => {
                    //跳转到客户列表页面
                    this.sureDialogVisible = false
                    SessionStorage.remove(RECYCLE_CART)
                    this.goodsCart={};
                    this.$emit('saveFinish',true)
                  }, 2000)
                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }

            })
        }
        if(this.type===3){
          Loadings.show()
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/wastedisposeorder/save', this.goodsCart)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  this.$message({ type: 'success', message: '处置开单成功！', duration: 2000, offset: 110 });
                  setTimeout(() => {
                    //跳转到客户列表页面
                    this.sureDialogVisible = false
                    SessionStorage.remove(RECYCLE_RETURN_CART)
                    this.goodsCart={};
                    this.$emit('saveFinish',true)
                  }, 2000)
                } else {
                  this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }

            })
        }

      },
      printOrder () {
        //console.log('打印单据：', this.goodsCart)
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .navRight {
    float: right;
  }

  /*表格*/
  .orderNav {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
    padding: 12px 30px;
  }


  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table .el-table__cell {
    padding: 6px 0;
  }

  .remark div {
    text-align: left;
  }

  .cartNum {
    color: #e52a33 !important;
  }

  /*小计*/
  .bottomMoney {
    margin-top: 10px;
    margin-right: 10px;
  }

  .bottomBox {
    min-height: 70px;
  }
  .bottomBox .title {
    font-weight: 700;
    color: #000000;
  }

  .bottomBox span {
    float: left;
    margin-left: 10px;
    line-height: 30px;
  }
  .totalMoney {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
  }

  .totalMoney .label {
    float: left;
    color: black;
    line-height: 40px;
    font-size: 15px;
  }

  /*.verticalRemarkLine{
    position: absolute;
    width: 2px;
    background: #dddddd;
    height: 100%;
    top: 0;
    left: 300px;
  }*/
  .bottomBox {
    border: 1px solid #dddddd;
    background: rgb(249, 249, 249);
    float: left;
    border-radius: 10px;
    width: 100%;
  }

  .bottomBox .remarkTitle {
    color: black;
    font-size: 15px;
    line-height: 14px;
    padding: 0 10px;
    margin-top: 15px;
    width: 100%;
  }

  .bottomBox .remarkDesc {
    float: left;
    color: black;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    padding: 10px 10px 0 10px;

  }

  .totalPrice {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    position: relative;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 5px;
  }
</style>
