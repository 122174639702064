<template>
  <div class="order" :id="orderId">
    <div class="orderNav">
      <div class="navLeft" :class="hiddenBtn?'paddingBottom':''">
        <div class="orderTime">开单时间：{{order.createTime}}</div>
        <div class="orderNum">订单号：{{order.buyOrderNo}}</div>
      </div>
      <div class="navRight" v-if="!hiddenBtn">
        <el-button class="cancelOrder" type="danger" @click="cancelOrder" v-if="order.status=== 0 && (getAuthorityMenu('300201') || (getAuthorityMenu('300401') || getAuthorityMenu('50020401')))">撤销订单</el-button>
        <el-button v-if="order.show && order.status === 0 && type!==2 && (getAuthorityMenu('300202')
        || getAuthorityMenu('50020402')) && isNongYao!=='1'" class="printOrder" type="success" icon="el-icon-document-copy" @click="reOrder">再来一单</el-button>
        <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="print" v-if="order.status === 0 && (getAuthorityMenu('300201') || getAuthorityMenu('300401'))">打印小票</el-button>
        <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="printBig" v-if="order.status === 0 && (getAuthorityMenu('300201') || getAuthorityMenu('300401'))">打印大票</el-button>
      </div>
    </div>
    <div class="orderTable">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="orderDetail"
        border
        highlight-current-row
        class="previous-row orderTables"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" ></el-table-column>
        <el-table-column property="goodsName" label="商品名称" width="150" ></el-table-column>
        <el-table-column property="packingSpecification" label="包装规格" :show-overflow-tooltip="true" >
          <template slot-scope="scope" >
            <el-tag type="info" class="packagUenitDesc showCenter" >
              {{scope.row.packingSpecification}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="showRange" property="useRange" label="施用范围">
          <template slot-scope="scope">
            {{scope.row.useRange}}
          </template>
        </el-table-column>
        <el-table-column property="buyPackageLevel" :label="type===2?'退货包装':'销货包装'" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.buyPackageLevel===1?'primary':'danger'" class="packageUnitDesc showCenter">
              {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column v-if="type!==2" property="packageLevel" label="单价" width="150">
          <template slot-scope="scope">
            <el-tag type="warning" class="showCenter">￥ {{scope.row.goodsPrice}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type===2" property="packageLevel" label="单价" width="150">
          <template slot-scope="scope">
            <el-tag type="warning" class="showCenter">￥ {{scope.row.goodsPrice}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column property="goodsNumber" label="数量" width="150">
          <template slot-scope="scope">
            <el-tag :type="scope.row.packageLevel===1?'primary':'danger'" class="showCenter">{{scope.row.goodsNumber}}
              {{scope.row.packageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column property="goodsPrice" label="小计" width="150">
          <template slot-scope="scope">
            <el-tag type="danger" class="showCenter">
              ￥ {{scope.row.totalPrice}}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="sum">
      <div v-if="type!=2">
        <div>
          <div class="sumTitle">应付金额：</div>
          <span class="sumMoney">{{order.accountsPayable}} 元</span>
        </div>
        <div>
          <div class="sumTitle">优惠金额：</div>
          <span class="sumMoney blackMoney">{{order.discountMoney}} 元</span>
        </div>
        <div>
          <div class="sumTitle">实付金额：</div>
          <span class="sumMoney blackMoney">{{order.paymentAmount}} 元</span>
        </div>
        <div>
          <div class="sumTitle">赊账金额：</div>
          <span class="sumMoney" :class="order.arrears>0?'':'blackTheme'">{{order.arrears}} 元</span>
        </div>
      </div>
      <div v-if="type===2">
        <div>
          <div class="sumTitle">应退金额：</div>
          <span class="sumMoney">{{order.refundAmount}} 元</span>
        </div>
        <div>
          <div class="sumTitle">实退金额：</div>
          <span class="sumMoney">{{order.realRefundAmount}} 元</span>
        </div>
      </div>
    </div>
    <div class="horizontalLine"></div>
    <div class="remark">
      <div>
        <p>备注：{{order.remark}}</p>
      </div>
    </div>

    <!-- 打印大票 -->
    <iframe style="display: none;" :srcdoc="tmpHtml" id="ifr"></iframe>

    <!-- 大票展示内容 -->
    <!--  style="display: none;" -->
    <!--
    <div>
      <span style="font-size: 18px;">{{ tenantInfo.tenantName ? tenantInfo.tenantName + '销售单' : '' }}</span>
      <table width="100%" border="1" style="margin: auto; text-align: center;">
        <tr><td colspan="4">店铺信息</td></tr>
        <tr style="font-size: 12px;">
          <td>店铺名称</td>
          <td>{{ tenantInfo.tenantName }}</td>
          <td>联系方式</td>
          <td>{{ tenantInfo.account }}</td>
        </tr>
        <tr><td colspan="4">客户信息</td></tr>
        <tr>
          <td>店铺名称</td>
          <td>{{ client.clientName }}</td>
          <td>客户名称</td>
          <td>{{ client.clientPerson }}</td>
        </tr>
        <tr>
          <td>联系方式</td>
          <td>{{ client.clientPhone }}</td>
          <td colspan="2"></td>
        </tr>
        <tr><td colspan="4">订单信息</td></tr>
        <tr>
          <td>订单编号</td>
          <td>{{ order.buyOrderNo }}</td>
          <td>开单时间</td>
          <td>{{ order.createTime }}</td>
        </tr>
        <tr><td colspan="4">订单明细</td></tr>
        <tr>
          <td>商品名称(包装规格)</td>
          <td>商品单价</td>
          <td>商品数量</td>
          <td>小计(元)</td>
        </tr>
        <tr v-for="(item, index) in orderDetail" :key="index">
          <td>{{ item.goodsName + '（' + item.packingSpecification + '）' }}</td>
          <td>{{ item.goodsPrice + '元/' + (item.buyPackageLevel == 1 ? item.middleUnitName : item.bigUnitName) }}</td>
          <td>{{ item.goodsNumber ? item.goodsNumber + (item.buyPackageLevel == 1 ? item.middleUnitName : item.bigUnitName) : '' }}</td>
          <td>{{ item.totalPrice }}</td>
        </tr>
        <tr>
          <td>备注</td>
          <td colspan="3">{{ order.remark }}</td>
        </tr>
        <tr>
          <td colspan="4" style="text-align: left;">上述商品清单所涉及的商品及数量，本人已提货签收。客户签名：</td>
        </tr>
        <tr>
          <td colspan="4" style="text-align: left;">注意事项：所有农药产品，请按产品标签说明使用</td>
        </tr>
      </table>
    </div>
    -->
  </div>
</template>

<script>
  import { getLodop } from '/public/static/js/utils/LodopFuncs.js'
  export default {
    //name: "订单详情",
    //向外暴露属性
    props: {
      orderId: {
        default: 'order-Id'
      },
      supplierName: {
        default: 'supplierName'
      }
    },
    data: function () {
      return {
        //播放器实例
        order: {},
        orderDetail: [],
        PACKAGE_LEVEL: PACKAGE_LEVEL,
        type: 1,
        orderid: '',

        client: {},
        tenantInfo: {},
        tmpHtml: '',

        recipe: {}, //处方数据

        PRINT_MSG: PRINT_MSG,
        isHLJ:LocalStorage.get(IS_HLJ),
        isNongYao:"",
        showRange:false,
        hiddenBtn:false,
      }
    },
    components: {},
    mounted() {
      this.client = SessionStorage.get(WANGLAIMINGXI_CLIENT);
      this.tenantInfo = LocalStorage.get(TENANT_INFO);
    },
    methods: {
      getDetailById (orderId, supplierName, type,hiddenBtn) {
        let sessionStorage = SessionStorage.get(WANGLAIMINGXI_CLIENT);
        this.client = sessionStorage;

        this.isNongYao=""
        this.orderid = orderId;
        this.type = type;

        console.log("----------type: ",type," this.type: ",this.type)

        if(hiddenBtn){
          this.hiddenBtn=true
        }
        if (type == 2) {
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/salereturnorder/detail?saleReturnOrderId=' + orderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            if(resp.data.isNongYao){
              this.isNongYao=resp.data.isNongYao
            }
            //console.log("this.isNongYao: ",this.isNongYao)
            this.order.saleReturnOrderId = resp.data.saleReturnOrderId
            this.order.saleReturnOrderNo = resp.data.saleReturnOrderNo
            this.order.buyOrderNo=resp.data.saleReturnOrderNo
            this.order.refundAmount = resp.data.refundAmount
            this.order.realRefundAmount = resp.data.realRefundAmount
            this.order.createTime = resp.data.createTime
            this.order.status = resp.data.status
            this.order.arrears=resp.data.arrears
            this.order.discountMoney=resp.data.discountMoney
            this.orderDetail = resp.data.saleReturnDetailList
            // if(this.orderDetail.some(item=>item.categoryId.toString()[0]==2)){
            //   this.showRange=true
            // }else{
            //   this.showRange=false
            // }
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              if (this.orderDetail[i].goodsPackageLevel === 2) {
                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2)+ this.orderDetail[i].smallUnitName + '/'
                //   + this.orderDetail[i].middleCount+' x '
                //   + this.orderDetail[i].middleUnitName + '/'
                //   + this.orderDetail[i].bigUnitName

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/'
                  + this.orderDetail[i].middleUnitName + ' x '
                  + this.orderDetail[i].middleCount
                  + this.orderDetail[i].middleUnitName + '/'
                  + this.orderDetail[i].bigUnitName;
              }
              if (this.orderDetail[i].goodsPackageLevel === 1) {
                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/'
                  + this.orderDetail[i].middleUnitName;
              }
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
            }
            this.order.remark = resp.data.remark;
            this.printHtml()
          })
        } else {
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/detail?saleOrderId=' + orderId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            if(resp.data.isNongYao){
              this.isNongYao=resp.data.isNongYao
            }
            this.$forceUpdate()
            //console.log("this.isNongYao---: ",this.isNongYao)
            this.order.accountsPayable = resp.data.accountsPayable
            this.order.paymentAmount = resp.data.paymentAmount
            this.order.buyOrderNo = resp.data.saleOrderNo
            this.order.createTime = resp.data.createTime
            this.order.status = resp.data.status
            this.order.show = resp.data.show
            this.order.arrears=resp.data.arrears
            this.order.discountMoney=resp.data.discountMoney
            //订单类型
            this.order.orderType = resp.data.orderType;
            //如果订单类型为农药处方开单，则查询处方详情
            if (this.order.orderType == 1) {
              this.recipe.recipeType = resp.data.recipeType;
              this.recipe.recipeId = resp.data.recipeId;
              this.$axios.get(process.env.VUE_APP_SERVER + '/psi/recipe/detail?recipeId=' + this.recipe.recipeId + '&recipeType=' + this.recipe.recipeType)
              .then((res) => {
                //处方名称
                this.recipe.recipeName = res.data.data.name;
                //防治对象名称
                this.recipe.preventionName = res.data.data.preventionName;
                //作物
                this.recipe.cropCategoryName = res.data.data.cropCategoryName;
                //注意事项
                this.recipe.matters = res.data.data.matters;
              });
            }

            this.orderDetail = resp.data.saleDetailList
            //console.log("this.orderDetail: ",this.orderDetail)
            if(this.orderDetail.some(item=>(item.categoryId.toString()[0]==2 || item.categoryId.toString()[0]==7))){
              this.showRange=true
            }else{
              this.showRange=false
            }
            for (let i = 0; i < this.orderDetail.length; i++) {
              this.orderDetail[i].uuid = Tool.get8UUID(8)
              this.orderDetail[i].buyPackageLevel = this.orderDetail[i].packageLevel
              if (this.orderDetail[i].goodsPackageLevel === 2) {

                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName + ' x '
                //   + this.orderDetail[i].middleCount
                //   + this.orderDetail[i].middleUnitName
                //   + this.orderDetail[i].bigUnitName;

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/'
                  + this.orderDetail[i].middleUnitName + ' x '
                  + this.orderDetail[i].middleCount
                  + this.orderDetail[i].middleUnitName + '/'
                  + this.orderDetail[i].bigUnitName;
              }
              if (this.orderDetail[i].goodsPackageLevel === 1) {
                // this.orderDetail[i].packingSpecification =
                //   parseFloat(this.orderDetail[i].smallCount).toFixed(2) + '/'
                //   + this.orderDetail[i].smallUnitName

                this.orderDetail[i].packingSpecification =
                  parseFloat(this.orderDetail[i].smallCount).toFixed(2) + this.orderDetail[i].smallUnitName + '/' + this.orderDetail[i].middleUnitName;
              }
              this.orderDetail[i].totalPrice = this.orderDetail[i].goodsPrice * this.orderDetail[i].goodsNumber
              /*this.orderDetail[i].packageLevel = this.orderDetail[i].goodsPackageLevel*/
              //用法用量
              this.orderDetail[i].usageAndDosage = this.orderDetail[i].usageName + '，' + this.orderDetail[i].usageAmount + this.orderDetail[i].smallUnitName + '/亩';
            }
            this.order.remark = resp.data.remark;

            this.printHtml()

            //构建大票内容
            //this.tmpHtml = `<p>${this.order.buyOrderNo}</p>`;
            //let div = document.getElementById('ifr').nextSibling;
            //this.tmpHtml = div.innerHTML;

            /*
            //原来的
            this.tmpHtml = `<div style="text-align: center;">` +
                          `<span style="font-size: 18px;">${this.tenantInfo.tenantName ? this.tenantInfo.tenantName + '销售单' : ''}</span>` +
                          `<table width="100%" cellspacing="0" border="1" style="margin: auto; text-align: center;">` +
                          `<tr><td colspan="4">店铺信息</td></tr>` +
                          `<tr style="font-size: 12px;"><td>店铺名称</td><td>${this.tenantInfo.tenantName}</td><td>联系方式</td><td>${this.tenantInfo.account}</td></tr>` +
                          `<tr><td colspan="4">客户信息</td></tr>` +
                          `<tr style="font-size: 12px;"><td>店铺名称</td><td>${this.client.clientName}</td><td>客户名称</td><td>${this.client.clientPerson}</td></tr>` +
                          `<tr style="font-size: 12px;"><td>联系方式</td><td>${this.client.clientPhone}</td><td colspan="2"></td></tr>` +
                          `<tr><td colspan="4">订单信息</td></tr>` +
                          `<tr style="font-size: 12px;"><td>订单编号</td><td>${this.order.buyOrderNo}</td><td>开单时间</td><td>${this.order.createTime}</td></tr>` +
                          `<tr><td colspan="4">订单明细</td></tr>` +
                          `<tr style="font-size: 12px;"><td>商品名称(包装规格)</td><td>商品单价</td><td>商品数量</td><td>小计(元)</td></tr>`;
            */

          });
        }
      },

      printHtml(){
        let isRange=false
        for (let i = 0; i < this.orderDetail.length; i++) {
          let item = this.orderDetail[i]
          if(item.categoryId){
            if(item.categoryId.toString()[0]==='2' || item.categoryId.toString()[0]==='7'){
              isRange=true
            }
          }

        }
        this.tmpHtml = `<div style="text-align: center;">` +
          `<span style="font-size: 18px;">${this.tenantInfo.tenantName ? this.tenantInfo.tenantName + (this.type==1?'销货单':'销货退货单') : ''}</span>` +
          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:10px; margin-bottom:10px;">` +
          `<div style= "text-align: left;">` +
          `<div>购货单位（人）：${this.client.clientName ? this.client.clientName : this.client.clientPerson}</div>` +
          `<div>电话：${this.client.clientPhone}</div>` +
          //`<div>地址：河北省衡水市桃城区中华街道丽景社区155号</div>` +
          `</div>` +
          `<div style="text-align: left;">` +
          `<div>No：${this.order.buyOrderNo}</div>` +
          `<div>日期：${this.order.createTime}</div>` +
          `</div>` +
          `</div>` +
          `<table width="100%" cellspacing="0" border="1" style="margin: auto; text-align: center;">` +
          `<tr>` +
          `<td>产品名称</td>` +
          `<td>包装规格</td>` +
          `<td style="display: ${isRange?'':'none'}">施用范围</td>`+
          `<td>数量</td>` +
          `<td>${this.type==1?'销货单价':'单价'}</td>` +
          `<td>${this.type==1?'销货金额':'金额'}</td>` +
          `</tr>`;

        // `<tr style="font-size: 12px;"><td>店铺名称</td><td>${this.tenantInfo.tenantName}</td><td>联系方式</td><td>${this.tenantInfo.account}</td></tr>` +
        // `<tr><td colspan="4">客户信息</td></tr>` +
        // `<tr style="font-size: 12px;"><td>店铺名称</td><td>${this.client.clientName}</td><td>客户名称</td><td>${this.client.clientPerson}</td></tr>` +
        // `<tr style="font-size: 12px;"><td>联系方式</td><td>${this.client.clientPhone}</td><td colspan="2"></td></tr>` +
        // `<tr><td colspan="4">订单信息</td></tr>` +
        // `<tr style="font-size: 12px;"><td>订单编号</td><td>${this.order.buyOrderNo}</td><td>开单时间</td><td>${this.order.createTime}</td></tr>` +
        // `<tr><td colspan="4">订单明细</td></tr>` +
        // `<tr style="font-size: 12px;"><td>商品名称(包装规格)</td><td>商品单价</td><td>商品数量</td><td>小计(元)</td></tr>`;

        let sumTotalPrice = 0;
        for (let i = 0; i < this.orderDetail.length; i++) {
          let item = this.orderDetail[i];

          if (item.buyPackageLevel == 1) {
            item.unit = item.middleUnitName;
          } else if (item.buyPackageLevel == 2) {
            item.unit = item.bigUnitName;
          }

          /*
          //原来的
          this.tmpHtml += `<tr style="font-size: 12px;"><td>${item.goodsName + '（' + item.packingSpecification + '）'}</td>` +
                      `<td>${item.goodsPrice + '元/' + item.unit}</td>` +
                      `<td>${item.goodsNumber + item.unit}</td>` +
                      `<td>${item.totalPrice}</td></tr>`;
          */

          // console.log('==============================');
          // console.log(item);
          // console.log(this.order);
          // console.log(this.client);
          // console.log(this.tenantInfo);

          this.tmpHtml +=
            `<tr style="font-size: 12px;">` +
            `<td>${item.goodsName}</td>` +
            `<td>${item.packingSpecification}</td>` +
            `<td style="display: ${isRange?'':'none'}">${item.useRange}</td>` +
            `<td>${item.goodsNumber + item.unit}</td>` +
            `<td>${item.goodsPrice + '元/' + item.unit}</td>` +
            `<td>￥${item.totalPrice}</td>` +
            `</tr>`;

          sumTotalPrice += item.totalPrice;

          if (i == (this.orderDetail.length - 1)) {
            this.tmpHtml +=
              `<tr style="font-size: 12px;">` +
              `<td>合计：</td>` +
              `<td></td>` +
              `<td></td>` +
              `<td></td>` +
              `<td>￥${sumTotalPrice}</td>` +
              `</tr>`;
          }
        }

        /*
        //原来的
        this.tmpHtml += `<tr style="font-size: 12px;"><td>备注</td><td colspan="3">${this.order.remark}</td></tr>` +
                      `<tr style="font-size: 12px;"><td colspan="4" style="text-align: left;">上述商品清单所涉及的商品及数量，本人已提货签收。客户签名：</td></tr>` +
                      `<tr style="font-size: 12px;"><td colspan="4" style="text-align: left;">注意事项：所有农药产品，请按产品标签说明使用</td></tr>` +
                      `</table>` +
                      `</div>`;
        */

        this.tmpHtml += `</table>` +
          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:30px; margin-bottom:10px;">` +
          `<div style= "text-align: left; width:200px;">` +
          `<div>${this.type==1?'应收金额':'应退金额'}：￥${this.type==1?this.order.accountsPayable:this.order.refundAmount}</div>` +
          `<div>制单人：${this.tenantInfo.userName}</div>` +
          `</div>` +
          `<div style= "text-align: left;">` +
          `<div>${this.type==1?'实收金额':'实退金额'}：￥${this.type==1?this.order.paymentAmount:this.order.realRefundAmount}</div>` +
          `<div>售货单位：${this.tenantInfo.tenantName}</div>` +
          `</div>` +
          `<div style="text-align: left;">` +
          `<div>电话：${this.tenantInfo.account}</div>` +
          `<div>备注：${this.order.remark}</div>` +
          `</div>` +
          `</div>` +
          `<div>注意事项：所有农药产品，请按产品标签说明使用</div>`+
          `</div>`;
      },

      //再来一单
      reOrder () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/saleorder/recurOrder?saleOrderId=' + this.orderid)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.order.accountsPayable = resp.data.accountsPayable
            this.order.paymentAmount = resp.data.paymentAmount
            this.order.buyOrderNo = resp.data.saleOrderNo
            this.order.clientId = []
            this.order.clientId.push(resp.data.clientId)
            this.order.clientName = []
            this.order.clientName.push(resp.data.clientName)
            this.order.createTime = resp.data.createTime
            this.order.status = resp.data.status
            this.order.remark = resp.data.remark
            this.order.show = resp.data.show
            this.order.details = resp.data.saleDetailList
            for (let i = 0; i < this.order.details.length; i++) {
              this.order.details[i].uuid = Tool.get8UUID(8)
              this.order.details[i].buyPackageLevel = this.order.details[i].packageLevel
              this.order.details[i].saleSmallPrice=this.order.details[i].lastSmallPrice
              this.order.details[i].saleBigPrice=this.order.details[i].lastBigPrice
              if(this.order.details[i].buyPackageLevel===1){
                this.order.details[i].goodsPrice=this.order.details[i].lastSmallPrice
              }
              if(this.order.details[i].buyPackageLevel===2){
                this.order.details[i].goodsPrice=this.order.details[i].lastBigPrice
              }
              this.order.details[i].totalPrice = this.order.details[i].goodsPrice * this.order.details[i].goodsNumber
            }
            let type = 4
            this.$router.push({
              name: '/saleOrders',
              params: {
                type,
                item: this.order
              }
            })
          })
      },
      cancelOrder () {
        let flag = true;
        this.$emit('orderCancel', flag, this.orderid, this.type);
      },
      //打印票据
      print() {

        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)

          return;
        }

        let client = this.client;

        let LODOP = getLodop();

        if (LODOP === 'Error:DriveNotInstalled') {
          //console.log('打印插件未安装！');
          this.$message({ type: 'error', message: '打印插件未安装！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        } else if (LODOP === 'Error:DriverNeedsToBeUpgraded') {
          //console.log('打印插件需升级！');
          this.$message({ type: 'error', message: '打印插件需升级！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        }

        //处方开单，打印作物用药处方
        if (this.order.orderType == 1) {
          let topDistance = 5; //距离顶部距离
          LODOP.NEWPAGEA();
          LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*作物用药处方*');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",15);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 50;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 1;
          LODOP.ADD_PRINT_TEXT(topDistance,55,130,40,this.order.createTime);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 1;
          LODOP.ADD_PRINT_TEXT(topDistance,55,130,60,this.order.buyOrderNo);

          topDistance += 40;
          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【客户信息】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
          topDistance += 30;

          // LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
          // LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          // LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          // let height_clientName = 0;
          // if (client.clientName.length > 9) {
          //   height_clientName += (Math.ceil(client.clientName.length / 9) - 1) * 17;
          // }

          // topDistance += 3;
          // LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_clientName,client.clientName);

          // if (client.clientName.length > 9) {
          //   topDistance += Math.ceil(client.clientName.length / 9) * 17;
          // } else {
          //   topDistance += 20;
          // }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'客户名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_clientPerson = 0;
          if (client.clientPerson.length > 9) {
            height_clientPerson += Math.ceil(client.clientPerson.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_clientPerson,client.clientPerson);

          if (client.clientPerson.length > 9) {
            topDistance += Math.ceil(clinet.clientPerson.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,client.clientPhone);

          topDistance += 30;

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【处方信息】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");
          topDistance += 30;

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'处方名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let recipeName = '[' + (this.recipe.recipeType == 0 ? '常规处方' : this.recipe.recipeType == 1 ? '专家处方' : this.recipe.recipeType == 2 ? '门店处方' : '无') + '] ' + this.recipe.recipeName;

          let height_recipeName = 0;
          if (recipeName.length > 9) {
            height_recipeName += Math.ceil(recipeName.length / 9) * 17;
          }

          topDistance += 3;

          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_recipeName, recipeName);

          if (recipeName.length > 9) {
            topDistance += Math.ceil(recipeName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'作物：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_cropCategoryName = 0;
          if (this.recipe.cropCategoryName.length > 9) {
            height_cropCategoryName += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,this.recipe.cropCategoryName);

          if (this.recipe.cropCategoryName.length > 9) {
            topDistance += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'防治对象：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_preventionName = 0;
          if (this.recipe.preventionName.length > 9) {
            height_preventionName += Math.ceil(this.recipe.preventionName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,this.recipe.preventionName);

          if (this.recipe.preventionName.length > 9) {
            topDistance += Math.ceil(this.recipe.preventionName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【处方详情】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 30;

          this.orderDetail.map((item, index) => {
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品名称：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_goodsName = 0;
            if (item.goodsName.length > 9) {
              height_goodsName += Math.ceil(item.goodsName.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_goodsName,item.goodsName);

            if (item.goodsName.length > 9) {
              topDistance += Math.ceil(item.goodsName.length / 9) * 17;
            } else {
              topDistance += 20;
            }

            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'包装规格：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_packingSpecification = 0;
            if (item.packingSpecification.length > 9) {
              height_packingSpecification += Math.ceil(item.packingSpecification.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_packingSpecification,item.packingSpecification);

            if (item.packingSpecification.length > 9) {
              topDistance += Math.ceil(item.packingSpecification.length / 9) * 17;
            } else {
              topDistance += 20;
            }

            LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '施用范围：')
            LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
            LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

            let height_rangeSpecification = 0
            if (item.useRange.length > 9) {
              height_rangeSpecification += Math.ceil(item.useRange.length / 9) * 17
            }

            topDistance += 3
            LODOP.ADD_PRINT_TEXT(topDistance, 65, 120,height_rangeSpecification, item.useRange)

            if (item.useRange.length > 9) {
              topDistance += Math.ceil(item.useRange.length / 9) * 17
            } else {
              topDistance += 20
            }

            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品单价：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.goodsPrice);

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品数量：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let unit = '';
            if (item.buyPackageLevel == 1) {
              unit = item.middleUnitName;
            } else if (item.buyPackageLevel == 2) {
              unit = item.bigUnitName;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,item.goodsNumber + unit);

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'用法用量：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,item.usageAndDosage);

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————');

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'小计：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            //topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,40,120,45,'￥' + item.totalPrice);

            if (index != this.orderDetail.length - 1) {
              topDistance += 20;
              LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************');
              topDistance += 20;
            }

          });

          topDistance += 25;
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,'应付：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + this.order.accountsPayable);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,'实付：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + this.order.paymentAmount);

          topDistance += 20;

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【注意事项】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          // let height_tenantName = 0;
          // if (tenantInfo.tenantName.length > 9) {
          //   height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
          // }

          //this.recipe.matters = '1. 使用本品应采取相应的安全防护措施，穿戴防护服和防护手套、口罩等，避免皮肤接触和口鼻吸入。使用中不可吸烟、饮水及吃东西，使用后及时清洗手、脸等暴露部位皮肤并更换衣物，2. 严格按照推荐剂量量取药剂，不得任意增减用量，严禁用手搅拌药液。3. 避免孕妇及哺乳期妇女接触。';

          topDistance += 30;
          LODOP.ADD_PRINT_TEXT(topDistance,5,180,100,this.recipe.matters);

          if (this.recipe.matters.length > 9) {
            topDistance += Math.ceil(this.recipe.matters.length / 9) * 7;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 30;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let tenantInfo = this.tenantInfo;

          let height_tenantName = 0;
          if (tenantInfo.tenantName.length > 9) {
            height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_tenantName,tenantInfo.tenantName);

          if (tenantInfo.tenantName.length > 9) {
            topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,tenantInfo.account);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'开方人签字：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          LODOP.ADD_PRINT_TEXT(topDistance,80,120,40,tenantInfo.userName);

          //设定打印页面大小
          LODOP.SET_PRINT_PAGESIZE(3,580,24,"客户往来明细单据打印");
          LODOP.PREVIEW();
        }
        //非处方开单
        else {
          let topDistance = 5; //距离顶部距离
          LODOP.NEWPAGEA();
          LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*慧台账进销存*');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",15);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 50;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 1;
          LODOP.ADD_PRINT_TEXT(topDistance,55,130,40,this.order.createTime);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 1;
          LODOP.ADD_PRINT_TEXT(topDistance,55,130,60,this.order.buyOrderNo);

          topDistance += 40;
          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【客户信息】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 30;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_clientName = 0;
          if (client.clientName.length > 9) {
            height_clientName += (Math.ceil(client.clientName.length / 9) - 1) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_clientName,client.clientName);

          if (client.clientName.length > 9) {
            topDistance += Math.ceil(client.clientName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'客户名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_clientPerson = 0;
          if (client.clientPerson.length > 9) {
            height_clientPerson += Math.ceil(client.clientPerson.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_clientPerson,client.clientPerson);

          if (client.clientPerson.length > 9) {
            topDistance += Math.ceil(clinet.clientPerson.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,client.clientPhone);

          topDistance += 30;

          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【订单详情】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 30;

          this.orderDetail.map((item, index) => {
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品名称：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_goodsName = 0;
            if (item.goodsName.length > 9) {
              height_goodsName += Math.ceil(item.goodsName.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_goodsName,item.goodsName);

            if (item.goodsName.length > 9) {
              topDistance += Math.ceil(item.goodsName.length / 9) * 17;
            } else {
              topDistance += 20;
            }

            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'包装规格：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_packingSpecification = 0;
            if (item.packingSpecification.length > 9) {
              height_packingSpecification += Math.ceil(item.packingSpecification.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_packingSpecification,item.packingSpecification);

            if (item.packingSpecification.length > 9) {
              topDistance += Math.ceil(item.packingSpecification.length / 9) * 17;
            } else {
              topDistance += 20;
            }
            if(item.categoryId){
              if(item.categoryId.toString()[0] === '2' || item.categoryId.toString()[0] === '7') {
                LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '施用范围：')
                LODOP.SET_PRINT_STYLEA(0, 'FontSize', 10)
                LODOP.SET_PRINT_STYLEA(0, 'FontName', '微软雅黑')

                let height_rangeSpecification = 0
                if (item.useRange.length > 9) {
                  height_rangeSpecification += Math.ceil(item.useRange.length / 9) * 17
                }

                topDistance += 3
                LODOP.ADD_PRINT_TEXT(topDistance, 65, 120,height_rangeSpecification, item.useRange)

                if (item.useRange.length > 9) {
                  topDistance += Math.ceil(item.useRange.length / 9) * 17
                } else {
                  topDistance += 20
                }
              }
            }


            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品单价：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.goodsPrice);

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品数量：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let unit = '';
            if (item.buyPackageLevel == 1) {
              unit = item.middleUnitName;
            } else if (item.buyPackageLevel == 2) {
              unit = item.bigUnitName;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,item.goodsNumber + unit);

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————');

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'小计：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + item.totalPrice);

            if (index != this.orderDetail.length - 1) {
              topDistance += 20;
              LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************');
              topDistance += 20;
            }

          });

          topDistance += 25;
          LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,this.type==1?'应付':'应退'+'：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + (this.type==1?this.order.accountsPayable:this.order.refundAmount));

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,this.type==1?'实付':'实退'+'：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + (this.type==1?this.order.paymentAmount:this.order.realRefundAmount));

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 30;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let tenantInfo = this.tenantInfo;

          let height_tenantName = 0;
          if (tenantInfo.tenantName.length > 9) {
            height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_tenantName,tenantInfo.tenantName);

          if (tenantInfo.tenantName.length > 9) {
            topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,tenantInfo.account);

          if (this.order.orderType == 1) {
            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【处方信息】');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            topDistance += 30;
            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'处方名称：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let recipeName = '[' + (this.recipe.recipeType == 0 ? '常规处方' : this.recipe.recipeType == 1 ? '专家处方' : this.recipe.recipeType == 2 ? '门店处方' : '无') + '] ' + this.recipe.recipeName;

            let height_recipeName = 0;
            if (recipeName.length > 9) {
              height_recipeName += Math.ceil(recipeName.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_recipeName,recipeName);

            if (recipeName.length > 9) {
              topDistance += Math.ceil(recipeName.length / 9) * 17;
            } else {
              topDistance += 20;
            }

            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'作物：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_cropCategoryName = 0;
            if (this.recipe.cropCategoryName.length > 9) {
              height_cropCategoryName += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_cropCategoryName,this.recipe.cropCategoryName);

            if (this.recipe.cropCategoryName.length > 9) {
              topDistance += Math.ceil(this.recipe.cropCategoryName.length / 9) * 17;
            } else {
              topDistance += 20;
            }

            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'防治对象：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_preventionName = 0;
            if (this.recipe.preventionName.length > 9) {
              height_preventionName += Math.ceil(this.recipe.preventionName.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_preventionName,this.recipe.preventionName);

            if (this.recipe.preventionName.length > 9) {
              topDistance += Math.ceil(this.recipe.preventionName.length / 9) * 17;
            } else {
              topDistance += 20;
            }

            LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'注意事项：');
            LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
            LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

            let height_matters = 0;
            if (this.recipe.matters.length > 9) {
              height_matterse += Math.ceil(this.recipe.matters.length / 9) * 17;
            }

            topDistance += 3;
            LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_matters,this.recipe.matters);

            if (this.recipe.matters.length > 9) {
              topDistance += Math.ceil(this.recipe.matters.length / 9) * 17;
            } else {
              topDistance += 20;
            }
          }

          //设定打印页面大小
          LODOP.SET_PRINT_PAGESIZE(3,580,24,"客户往来明细单据打印");
          LODOP.PREVIEW();
        }
      },
      //打印大票
      printBig() {
        //this.$refs.printBarcode.showDialog();
        /*
        //根据div标签ID拿到div中的局部内容
        let backHtml = window.document.body.innerHTML;
        //把获取的 局部div内容赋给body标签, 相当于重置了 body里的内容
        window.document.body.innerHTML= document.getElementById("printcontent").innerHTML;
        //调用打印功能
        window.print();
        window.document.body.innerHTML = backHtml;
        */

        /*
        let bdhtml=window.document.body.innerHTML;
        let sprnstr="<!--startprint-->"; //开始打印标识字符串有17个字符
        let eprnstr="<!--endprint-->"; //结束打印标识字符串
        let prnhtml=bdhtml.substr(bdhtml.indexOf(sprnstr)+17); //从开始打印标识之后的内容
        prnhtml=prnhtml.substring(0,prnhtml.indexOf(eprnstr)); //截取开始标识和结束标识之间的内容
        window.document.body.innerHTML=prnhtml; //把需要打印的指定内容赋给body.innerHTML
        window.print(); //调用浏览器的打印功能打印指定区域
        window.document.body.innerHTML=bdhtml;//重新给页面内容赋值
        */
        //this.pagesetup_null();

        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {

          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)

          return;
        }

        //打印
        document.getElementById('ifr').contentWindow.print();
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .orderNav {
    position: relative;
    width: 100%;
  }

  .navLeft {
  }

  .orderTime {
    float: left;
    margin-right: 10px;
  }

  .orderNum {
    float: left;

  }

  .navRight {

  }

  .printOrder {
    float: right;
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  .cancelOrder {
    float: right;
    margin-left: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 10px !important;
  }

  /*总计*/
  .sum {
    margin-bottom: 55px;
  }

  .sumMoney {
    float: left;
    margin-right: 10px;
  }

  .realMoney {
    float: left;
    margin-right: 10px;
  }

  .debtMoney {
    float: left;
  }

  .horizontalLine {
    width: 100%;
    height: 3px;
    border: 3px solid #e9eef3;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .remark {
    margin-bottom: 50px;
  }

  .remark div {
    text-align: left;
  }

  .sumTitle {
    float: left;

  }

  .sumMoney {
    float: left;
    margin-right: 10px;
  }

  .sumTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    font-size: 16px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .sumMoney {
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }
  .blackMoney{
    color: #606266 !important;
  }
  .blackMoney:after{
    background: #606266 !important;
  }
  .sumMoney:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 6px;
  }
  .blackTheme{
    color: #606266;
  }
  .blackTheme:after{
    background: #606266;
  }
  .paddingBottom{
    padding-bottom: 30px;
  }
</style>
