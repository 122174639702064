<template>
  <div id="login-modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="header_icon">
      <a id="header_icon_img">
        <img id="icon_img" src="" alt="">
      </a>
      <img id="icon_tag1" src="/static/imgs/login/login-nav-tag-2.png" alt="">
      <img id="icon_tag2" src="/static/imgs/login/login-nav-tag-1.png" alt="">
    </div>
    <!--
    <div class="tipMessage">
      <el-alert class=""
                title="新版 '人人都会用的电子台账' 说明"
                type="warning">
        <span class="messageBox">为让用户更好的体验到进销存管理系统，现开发了一版新的“人人都会用的电子台账”电子台账。之前“农药大数据进销存管理系 统”的数据都已导入现在新的版本，之前的“农药大数据进销存管理系统”也可通过点击登陆页的“新版”、“旧版”按钮，进系 统进行查看之前数据。之前的“农药大数据进销存管理系统”截止到2022年2月28日将全面关闭。</span>
      </el-alert>
    </div>
    -->
    <div class="modal-dialog modal-login" role="document">
      <div class="modal-content">
        <div class="modal-body" :showStatus="showDialog">
          <!--登录-->
          <div class="login-div" v-show="MODAL_STATUS===STATUS_LOGIN">
            <div class="loginHeader">
              <div class="shotMsgLogin" @click="miniLogin()">扫 码 登 录</div>
              <div class="psdLogin redActive" @click="psdLogin()">账 号 登&nbsp;录</div>

            </div>
            <div class="form-content">
              <div class="form-group">
                <input v-model="member.mobile" class="form-control" placeholder="手机号" v-on:blur="onLoginMobileBlur()">
                <span v-show="loginMobileValidator===false" class="text-danger">请输入正确的手机号码</span>
              </div>
              <div class="form-group pwdForm">
                <input v-model="member.password" class="form-control" type="password" placeholder="密码"
                       v-on:blur="onLoginPasswordBlur()" v-if="isShow">
                <input v-model="member.password" class="form-control" type="text" placeholder="密码"
                       v-on:blur="onLoginPasswordBlur()" v-else>

                <span class="el-input__suffix styletemp">
                  <span class="el-input__suffix-inner">
                    <i class="el-input__icon el-icon-view el-input__clear pwdEye"
                       v-on:click="testClick"></i>
                  </span>
                </span>

                <span v-show="loginPasswordValidator===false" class="text-danger">密码必须是6-16位，且不包含除大小写字母及数字外的任意字符</span>
              </div>
              <!--<div class="form-group">
                  <div class="input-group">
                      <input id="image-code-input" class="loginInput " type="text" placeholder="验证码"
                             v-model="member.imgCode">
                      <div class="input-group-addon" id="image-code-addon">
                          <img id="image-code" alt="验证码" v-on:click="loadImageCode()" />
                      </div>
                  </div>
              </div>-->

              <div class="form-group">
                <button v-on:click="clientLogin()" class="clickButtom btn btn-primary btn-block submit-button">
                  登&nbsp;&nbsp;录
                </button>
              </div>

              <div class="form-group bottomLabel">
                <div class="checkbox">
                  <!--<label>
                    <input type="checkbox" class="checkBoxRemember rememberme" v-model="rememberme">记住密码
                  </label>-->
                  <div class="pull-right">
                    <!--跳转忘记密码页面-->
                    <a href="javascript:;" class="aLink" v-on:click="toForgetPWD()">忘记密码</a>&nbsp;
                    <!--跳转注册页面-->
                    <a href="javascript:;" class="aLink" v-on:click="toRegisterMEM()">我要注册</a>
                  </div>
                </div>
              </div>
              <!--
              <div class="webLink">
                <span class="linkSpan">农药大数据进销存管理系统</span>
                <el-button class="linkBtn" type="danger" @click="newJXC">新版</el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button class="linkBtn" type="danger" @click="oldJXC">旧版</el-button>
              </div>
              -->
            </div>

            <div class="form-group to-login-toast">为了您的账号安全，请不要在公共设施上登录！</div>
          </div>

          <!--小程序扫码登录-->
          <div class="login-div" v-show="MODAL_STATUS===STATUS_MINI_LOGIN">
            <div class="loginHeader">
              <div class="shotMsgLogin redActive" @click="miniLogin()">扫 码 登 录</div>
              <div class="psdLogin " @click="psdLogin()">账 号 登&nbsp;录</div>

            </div>
            <div class="form-content">
              <div class="form-group">
                <img id="ceventQRCode" :src="codeImg">
              </div>


              <div class="form-group">
                <button v-on:click="refreshCodeImg()" class="refreshButton btn">
                  <i class="glyphicon glyphicon-refresh refreshIcon"></i>
                  刷新二维码
                </button>
              </div>

              <!--<div class="form-group bottomLabel">
                <div class="checkbox">
                  <div class="pull-right">
                    &lt;!&ndash;跳转忘记密码页面&ndash;&gt;
                    <a href="javascript:;" class="aLink" v-on:click="toForgetPWD()">忘记密码</a>&nbsp;
                    &lt;!&ndash;跳转注册页面&ndash;&gt;
                    <a href="javascript:;" class="aLink" v-on:click="toRegisterMEM()">我要注册</a>
                  </div>
                </div>
              </div>-->
            </div>

            <div class="form-group to-login-toast">为了您的账号安全，请不要在公共设施上登录！</div>
          </div>

          <!--登录end-->

          <!--注册-->
          <div class="register-MEM" v-show="MODAL_STATUS===STATUS_REGISTER">
            <div class="loginHeader">
              <div class="headerBottom">
                <div class="shotMsgLogin redActive">免 费 注 册</div>
              </div>
            </div>
            <!--手机号及验证码-->
            <div class="form-content">
              <div class="form-group">
                <input v-on:blur="onRegisterMobileBlur()"
                       v-bind:class="registerMobileValidatorClass"
                       id="register-mobile"
                       v-model="memberRegister.mobile"
                       class="form-control" placeholder="手机号">
                <span v-show="registerMobileValidator===false" class="text-danger">请输入正确的手机号码</span>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="validateSendCode">
                    <input v-on:blur="onRegisterMobileCodeBlur()"
                           v-bind:class="registerMobileCodeValidatorClass"
                           id="register-mobile-code"
                           v-model="memberRegister.shortMessageCode"
                           class="validateCode form-control" placeholder="手机验证码">
                    <button class="btn btn-outline-secondary"
                            id="register-send-code-btn"
                            v-on:click="sendSmsCode('A')">
                      发送验证码
                    </button>
                  </div>
                </div>
                <span v-show="registerMobileCodeValidator===false" class="text-danger">短信验证码必须是6位数字</span>
              </div>
              <!--密码-->
              <div class="form-group">
                <input v-on:blur="onRegisterPasswordBlur()"
                       v-bind:class="registerPasswordValidatorClass"
                       id="register-password"
                       v-model="memberRegister.passwordOriginal"
                       class="form-control" placeholder="密码" type="password">
                <span v-show="registerPasswordValidator===false"
                      class="text-danger">密码必须是6-16位，且不包含除大小写字母及数字外的任意字符</span>
              </div>
              <div class="form-group">
                <input v-on:blur="onRegisterPasswordConfirmBlur()"
                       v-bind:class="registerPasswordConfirmValidatorClass"
                       id="register-confirm-password"
                       v-model="memberRegister.passwordConfirm"
                       class="form-control" placeholder="确认密码" type="password"
                       name="memberRegisterPasswordConfirm">
                <span v-show="registerPasswordConfirmValidator===false" class="text-danger">两次所输密码必须一致</span>
              </div>
              <div style="margin-bottom: 20px; color: #999;">
                <span>
                  <el-checkbox v-model="flag"></el-checkbox>
                  我已阅读并同意遵守
                  <a href="javascript:;" style="text-decoration: none;" v-on:click="yonghuxieyi()">《用户协议与隐私条款》</a>
                </span>
              </div>
              <!--注册-->
              <div class="form-group">
                <button class="clickButtom btn btn-primary btn-block submit-button" v-on:click="register()"
                        :disabled="!flag">
                  注&nbsp;&nbsp;册
                </button>
              </div>
              <!--登录modal_status跳转-->
              <div class="form-group to-login-acount">
                <a href="javascript:;" class="aLink" v-on:click="toLoginAcount()">我要登录</a>
              </div>
            </div>

          </div>
          <!--注册end-->

          <!--忘记密码-->
          <div class="forget-PWD" v-show="MODAL_STATUS===STATUS_FORGET">
            <div class="loginHeader ">
              <div class="headerBottom">
                <div class="shotMsgLogin redActive">重 置 密 码</div>
              </div>
            </div>
            <div class="form-content">
              <div class="form-group">
                <input v-on:blur="onForgetMobileBlur()"
                       v-bind:class="forgetMobileValidatorClass"
                       id="forget-mobile"
                       v-model="memberForget.mobile"
                       class="form-control" placeholder="手机号">
                <span v-show="forgetMobileValidator===false" class="text-danger">手机号11位数字，且必须已注册</span>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-append">
                    <input v-on:blur="onForgetMobileCodeBlur()"
                           v-bind:class="forgetMobileCodeValidatorClass"
                           id="forget-mobile-code"
                           v-model="memberForget.shortMessageCode"
                           class="validateCode form-control" placeholder="手机验证码">
                    <button class="btn btn-outline-secondary"
                            id="forget-send-code-btn"
                            v-on:click="sendSmsCode('B')">
                      发送验证码
                    </button>
                  </div>
                </div>
                <span v-show="forgetMobileCodeValidator===false" class="text-danger">请输入短信6位验证码</span>
              </div>
              <!--忘记密码-->
              <div class="form-group">
                <input v-on:blur="onForgetPasswordBlur()"
                       v-bind:class="forgetPasswordValidatorClass"
                       id="forget-password"
                       v-model="memberForget.passwordOriginal"
                       class="form-control" placeholder="密码" type="password">
                <span v-show="forgetPasswordValidator===false"
                      class="text-danger">密码必须是6-16位，且不包含除大小写字母及数字外的任意字符</span>
              </div>
              <div class="form-group">
                <input v-on:blur="onForgetPasswordConfirmBlur()"
                       v-bind:class="forgetPasswordConfirmValidatorClass"
                       id="forget-confirm-password"
                       v-model="memberForget.passwordConfirm"
                       class="form-control" placeholder="确认密码" type="password"
                       name="memberRegisterPasswordConfirm">
                <span v-show="forgetPasswordConfirmValidator===false" class="text-danger">确认密码和密码一致</span>
              </div>
              <div class="form-group">
                <button v-on:click="resetPWD()" class="clickButtom btn btn-primary btn-block submit-button">
                  重&nbsp;&nbsp;置
                </button>
              </div>
              <!--登录modal_status跳转-->
              <div class="form-group to-login-acount">
                <a href="javascript:;" class="aLink" v-on:click="toLoginAcount()">我要登录</a>
              </div>
            </div>

          </div>
          <!--忘记密码-end-->

          <!--完善信息-->
          <div id="storeInfo" class="store-info-modal" v-show="MODAL_STATUS===STATUS_INFO">
            <div class="loginHeader">
              <!--<div class="shotMsgLogin redActive" @click="shotMsgLogin()">短信验证码登录</div>
              <div class="psdLogin " @click="psdLogin()">密 码&nbsp;登&nbsp;录</div>-->
              <div class="headerBottom">
                <div class="psdLogin redActive">完&nbsp;善&nbsp;信&nbsp;息</div>
              </div>
            </div>
            <el-form :model="storeInfo" :rules="rules" ref="storeInfo" label-width="120px"
                     class="demo-storeInfo store-info-form">
              <el-form-item class="labelTitle" label="店铺名称" prop="tenantName">

                <!-- <el-input v-model="storeInfo.tenantName" placeholder="请输入店铺名称"></el-input> -->

                <!-- 联想下拉列表输入框 -->
                <el-autocomplete
                  v-model="storeInfo.tenantName"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入店铺名称"
                  @select="handleSelect"
                  @input="loadAll"
                  style="width: 323px;"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item class="labelTitle" label="社会信用代码" prop="creditCode">
                <el-input v-model="storeInfo.creditCode" placeholder="请输入社会信用代码"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="姓名" prop="linkman">
                <el-input v-model="storeInfo.linkman" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="选择区域" prop="addressDetail" @click="mapClick">
                <el-input v-model="storeInfo.addressDetail" placeholder="点击地图选择您的地址" readonly @click.native="mapClick">
                  <i slot="suffix" class="locationIcon el-icon-location" @click="mapClick"></i>
                </el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="详细地址" prop="addressdesc">
                <el-input v-model="storeInfo.addressdesc" placeholder="请填写街道门牌信息">
                </el-input>
              </el-form-item>

              <el-form-item class="storeInfoSub">
                <el-button class="storeInfoBtn" type="danger" @click="submitForm('storeInfo')">提交</el-button>
                <el-button class="resetBtn" @click="resetForm('storeInfo')">重置</el-button>
              </el-form-item>
            </el-form>
            <div class="form-group to-login-acount returnLogin">
              <a href="javascript:;" class="aLink" v-on:click="toLoginAccount()">返回登录</a>
            </div>
          </div>

        </div>
        <!--modal-body-end-->
      </div>
      <!--modal-content-end-->
    </div>
    <div class="miniCode">
      <img src="/static/imgs/login/mini-code-1.jpg">
      <span>慧台账微信小程序</span>
    </div>
    <div class="loginFooter">
      <div class="textLine">版权所有：河北威拓信息技术有限公司</div>
      <!--<div class="textLine">咨询热线：0318-2111122</div>-->
      <div class="linkLine">慧台账 | 冀ICP备17014114号-1</div>
    </div>

    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <!--<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>-->
            <h4 class="modal-title" id="myModalLabel">选择位置</h4>
          </div>
          <div id="mapLocation" class="modal-body mapBody" style="width: 1000px; height: 600px;"></div>
          <!--
          <div class="modal-footer">
            <button type="button" class="locationBtn btn btn-primary" @click="saveLocation">保存</button>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'main-login',
    props: {
      showDialog: {
        default: 'STATUS_REGISTER'
      }
    },
    data: function () {
      //自定义表单项验证器
      let validate_tenantName = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value === '') {
          callback(new Error('请填写店铺名称'))
        } else if (getLength(value) > 50) {
          callback(new Error('店铺名称最多不能超过50个字符'))
        } else {
          callback()
        }
      }
      let validate_creditCode = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请填写统一社会信用代码'))
        } else {
          callback()
        }
      }
      let validate_linkman = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value === '') {
          callback(new Error('请填写姓名'))
        } else if (getLength(value) > 20) {
          callback(new Error('姓名最多不能超过20个字符'))
        } else {
          callback()
        }
      }
      let validate_addressdesc = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请填写详细地址'))
        } else {
          callback()
        }
      }
      let validate_addressdetail = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请选择店铺区域'))
        } else {
          callback()
        }
      }
      return {
        // 模态框内容切换：登录、注册、忘记密码
        STATUS_LOGIN: 'STATUS_LOGIN',
        STATUS_MINI_LOGIN: 'STATUS_MINI_LOGIN',
        STATUS_REGISTER: 'STATUS_REGISTER',
        STATUS_FORGET: 'STATUS_FORGET',
        MODAL_STATUS: '',//跳转页面的v-show
        STATUS_INFO: 'STATUS_INFO',
        //本地memberLogin登录变量：返回后端member
        member: {
          mobile: '', //手机号
          password: '', //密码
          //imgCode: ''
        },
        //本地注册变量，返回后端member
        memberRegister: {},
        //本地忘记密码变量，返回后端member
        memberForget: {},
        rememberme: true, //记住密码
        imageCodeToken: '',

        //登录
        loginMobileValidator: null,
        loginPasswordValidator: null,

        // 注册框显示错误信息
        registerMobileValidator: null,
        registerMobileCodeValidator: null,
        registerNameValidator: null,
        registerPasswordValidator: null,
        registerPasswordConfirmValidator: null,

        // 忘记密码框显示错误信息
        forgetMobileValidator: null,
        forgetMobileCodeValidator: null,
        forgetPasswordValidator: null,
        forgetPasswordConfirmValidator: null,

        //联想下拉列表输入框
        restaurants: [],
        state: '',
        timeout: null,

        //店铺信息
        storeInfo: {
          tenantName: '',
          creditCode: '',
          linkman: '',
          addressdesc: '',
          addressDetail:'',
        },

        flag: false,

        isShow: true,

        province: '', //省
        city: '', //市
        district: '', //地区
        township: '', //街道

        rules: {
          tenantName: [
            {
              validator: validate_tenantName,
              trigger: 'blur'
            }
          ],
          creditCode: [
            {
              validator: validate_creditCode,
              trigger: 'blur'
            }
          ],
          linkman: [
            {
              validator: validate_linkman,
              trigger: 'blur'
            }
          ],
          addressdesc: [
            {
              validator: validate_addressdesc,
              trigger: 'blur'
            }
          ],
          addressDetail: [
            {
              validator: validate_addressdetail,
              trigger: 'blur'
            }
          ],
        },
        //二维码
        codeImg: '',

        //地图定位
        isShowMapLocation: false,
      }
    },
    computed: {
      registerMobileValidatorClass: function () {
        return {
          'border-success': this.registerMobileValidator === true,
          'border-danger': this.registerMobileValidator === false,
        }
      },
      registerMobileCodeValidatorClass: function () {
        return {
          'border-success': this.registerMobileCodeValidator === true,
          'border-danger': this.registerMobileCodeValidator === false,
        }
      },
      registerNameValidatorClass: function () {
        return {
          'border-success': this.registerNameValidator === true,
          'border-danger': this.registerNameValidator === false,
        }
      },
      registerPasswordValidatorClass: function () {
        return {
          'border-success': this.registerPasswordValidator === true,
          'border-danger': this.registerPasswordValidator === false,
        }
      },
      registerPasswordConfirmValidatorClass: function () {
        return {
          'border-success': this.registerPasswordConfirmValidator === true,
          'border-danger': this.registerPasswordConfirmValidator === false,
        }
      },
      forgetMobileValidatorClass: function () {
        return {
          'border-success': this.forgetMobileValidator === true,
          'border-danger': this.forgetMobileValidator === false,
        }
      },
      forgetMobileCodeValidatorClass: function () {
        return {
          'border-success': this.forgetMobileCodeValidator === true,
          'border-danger': this.forgetMobileCodeValidator === false,
        }
      },
      forgetPasswordValidatorClass: function () {
        return {
          'border-success': this.forgetPasswordValidator === true,
          'border-danger': this.forgetPasswordValidator === false,
        }
      },
      forgetPasswordConfirmValidatorClass: function () {
        return {
          'border-success': this.forgetPasswordConfirmValidator === true,
          'border-danger': this.forgetPasswordConfirmValidator === false,
        }
      },
    },
    created () {
    },
    mounted () {
      let _this = this
      //**页面初始化设置的登录模态框
      _this.toLoginAcount()
      //监听全局总线，必须声明回调
      _this.$event.$on('openLoginModal', function () {
        _this.openLoginModal('STATUS_LOGIN')
        //_this.openLoginModal('STATUS_INFO')
      })

      //加载联想下拉列表数据
      //this.restaurants = this.loadAll();
      if(this.$route.query.showModel){
        this.showExpireModel()
        return
      }

      //如果用户已登录，则跳转到首页
      if (LocalStorage.get(IS_EXISTS_SHOPINFO) && LocalStorage.get(TOKEN)) {
        $('#login-modal').modal('hide')
        setTimeout(() => {
          _this.$router.push('/admin/index')
        }, 200)
      }
    },
    methods: {
      mapLocationDialogClose () {
        this.isShowMapLocation = false
      },
      //定位图标点击触发
      mapClick () {
        $('#myModal').modal('show')
        //console.log("myModal: ",$('#myModal')[0].childNodes[0].firstChild)
        Tool.addClass($('#myModal')[0].childNodes[0].firstChild, 'mapModal')
        this.getMapLocation()
      },
      getMapLocation: function () {
        let _this = this
        let currentAdd=_this.storeInfo.addressdesc
        var map = new BMap.Map('mapLocation')    // 创建Map实例
        map.enableScrollWheelZoom(true)     //开启鼠标滚轮缩放
        // 初始化地图,设置中心点坐标和地图级别
        //map.centerAndZoom(new BMap.Point(116.404, 39.915), 9);
        map.centerAndZoom(new BMap.Point(115.55281540191652, 37.810134993180114), 13)
        var geocoder = new BMap.Geocoder()

        map.addEventListener('click', function (e) { //给地图添加点击事件
          geocoder.getLocation(e.point, function (rs) {
            // console.log(rs.address); //地址描述(string)
            // console.log(rs.addressComponents);//结构化的地址描述(object)
            // console.log(rs.addressComponents.province); //省
            // console.log(rs.addressComponents.city); //城市
            // console.log(rs.addressComponents.district); //区县
            // console.log(rs.addressComponents.street); //街道
            // console.log(rs.addressComponents.streetNumber); //门牌号
            // console.log(rs.surroundingPois); //附近的POI点(array)
            // console.log(rs.business); //商圈字段，代表此点所属的商圈(string)

            _this.storeInfo.addressdesc = rs.addressComponents.province + rs.addressComponents.city + rs.addressComponents.district +
              rs.addressComponents.street + rs.addressComponents.streetNumber+currentAdd

            _this.province = rs.addressComponents.province
            _this.city = rs.addressComponents.city
            _this.district = rs.addressComponents.district
            _this.township = rs.addressComponents.street
            _this.storeInfo.addressDetail=rs.addressComponents.province + rs.addressComponents.city + rs.addressComponents.district +
              rs.addressComponents.street + rs.addressComponents.streetNumber
            $('#myModal').modal('hide')
          })
        })
      },
      saveLocation () {
        console.log('baocun')
      },
      //点击按钮进行定位
      searchbut () {
        MapUtil.searchbut()
      },
      testClick () {
        this.isShow = !this.isShow
      },
      yonghuxieyi () {
        window.open('/register/yonghuxieyi')
      },
      loadAll () {
        let _this = this
        clearTimeout(_this.timeout)
        this.timeout = setTimeout(() => {
          _this.restaurants = []
          if (_this.storeInfo.tenantName) {
            _this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-system/tenant/selectListByTenantName?tenantName=' + _this.storeInfo.tenantName,
              method: 'GET',
              // data: {
              //   tenantName: this.storeInfo.tenantName
              // },
            })
              .then((res) => {
                if (res.status == 200) {
                  if (res.data.code == 200) {
                    res.data.data.map((item) => {
                      _this.restaurants.push({
                        value: item.tenantName,
                        tenantName: item.tenantName,
                        linkman: item.linkman,
                        creditCode: item.creditCode,
                        address: item.address
                      })
                    })
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        }, 100)
      },
      querySearchAsync (queryString, cb) {
        setTimeout(() => {
          /*
          var restaurants = this.restaurants;
          var results = queryString ? restaurants.filter(this.createStateFilter(queryString, 0)) : restaurants;

          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            cb(results);
          }, 1000 * Math.random());
          */
          cb(this.restaurants)
        }, 500)
      },
      createStateFilter (queryString, count) {
        return (state) => {
          //return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);

          //定义显示下拉列表项数量最大为5
          if (count < 5) {
            if (state && state.value && state.value.indexOf(queryString) != -1) {
              count++
              return true
            }
          }
          return false
        }
      },
      handleSelect (item) {
        this.storeInfo.tenantName = item.tenantName
        this.storeInfo.creditCode = item.creditCode
        this.storeInfo.linkman = item.linkman
        this.storeInfo.addressdesc = item.address

        if(Tool.isEmpty(this.storeInfo.addressDetail)){
          this.storeInfo.addressDetail=item.address
        }

      },

      //店铺信息提交表单
      submitForm (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            //调用接口完善店铺信息
            _this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-user/shopInfo2?' +
                'tenantName=' + _this.storeInfo.tenantName +
                '&creditCode=' + _this.storeInfo.creditCode +
                '&linkman=' + _this.storeInfo.linkman +
                '&address=' + _this.storeInfo.addressdesc +
                '&pro=' + _this.province +
                '&city=' + _this.city +
                '&district=' + _this.district +
                '&township=' + _this.township,
              method: 'POST',
              // data: {
              //   account: _this.member.mobile,
              //   password: _this.member.password
              // },
            })
              .then((res) => {
                Loadings.hide()
                if (res.status == 200) {
                  if (res.data.code == 200) {
                    //跳转到首页
                    //用户登录判断用户等级
                    if (res.data.data) {
                      let loginUser = LocalStorage.get(LOGIN_USER)
                      let respData = res.data.data

                      loginUser.level = 1
                      let levels = []
                      for (let i = 0; i < respData.roleList.length; i++) {
                        levels.push(respData.roleList[i].level)
                      }
                      if (levels.indexOf(0) != -1 || levels.indexOf(1) != -1) {
                        loginUser.level = 1
                      } else {
                        loginUser.level = 3
                      }

                      loginUser.roleList = respData.roleList

                      if (respData.menuList && respData.menuList.length > 0) {
                        loginUser.menuList = respData.menuList
                      }

                      LocalStorage.set(LOGIN_USER, loginUser)
                    }

                    $('#login-modal').modal('hide')
                    setTimeout(function () {
                      _this.$router.push('/admin/index')
                    }, 200)
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: '网络异常',
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          } else {
            //console.log('error submit!!')
            return false
          }
        })
      },
      //重置表单
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      shotMsgLogin () {
        this.openLoginModal('STATUS_INFO')
      },
      psdLogin () {
        this.openLoginModal('STATUS_LOGIN')
      },
      openLoginModal (showDialog) {
        let _this = this
        //放入toLoginAcount,这里实现，3个模态框切换，验证码不会刷新
        //_this.loadImageCode();
        if (showDialog === 'STATUS_LOGIN') {
          //登录之前刷新验证码
          //this.loadImageCode()
          this.MODAL_STATUS = 'STATUS_LOGIN'
        } else if (showDialog === 'STATUS_REGISTER') {
          this.MODAL_STATUS = 'STATUS_REGISTER'
        } else if (showDialog === 'STATUS_FORGET') {
          this.MODAL_STATUS = 'STATUS_FORGET'
        } else if (showDialog === 'STATUS_INFO') {
          this.MODAL_STATUS = 'STATUS_INFO'
        }
        //关闭模态框点击空白处自动关闭
        $('#login-modal').modal({
          show: true,
          backdrop: 'static',
          keyboard: false
        })
      },

      /*------------登录框/注册框/忘记密码框切换--------------*/

      toLoginAcount () {
        let _this = this
        $('.modal-content').removeClass('bigModal')
        //从缓存中获取记住的手机号和密码，没有获取到=没有勾选记住我
        /*let rememberMember = LocalStorage.get(MEMBER_LOGIN_LOCAL)
        if (rememberMember) {
          _this.member = rememberMember
        }*/

        //从缓存中删除token、店铺用户等登录信息
        // LocalStorage.remove(TOKEN)
        // LocalStorage.remove(TENANT_INFO)
        // LocalStorage.remove(LOGIN_USER)
        // LocalStorage.remove(IS_LOG)

        //显示登录框，刷新验证码
        //_this.loadImageCode();
        _this.MODAL_STATUS = _this.STATUS_LOGIN
      },

      //返回登录时，清空所有缓存
      toLoginAccount () {
        let _this = this
        $('.modal-content').removeClass('bigModal')
        //从缓存中获取记住的手机号和密码，没有获取到=没有勾选记住我
        /*let rememberMember = LocalStorage.get(MEMBER_LOGIN_LOCAL)
        if (rememberMember) {
          _this.member = rememberMember
        }*/
        //从缓存中删除token、店铺用户等登录信息
        LocalStorage.remove(TOKEN)
        LocalStorage.remove(TENANT_INFO)
        LocalStorage.remove(LOGIN_USER)
        LocalStorage.remove(IS_LOG)
        //显示登录框，刷新验证码
        //_this.loadImageCode();
        _this.MODAL_STATUS = _this.STATUS_LOGIN
      },

      /**
       * 加载验证码
       */
      loadImageCode () {
        let _this = this
        _this.imageCodeToken = Tool.get8UUID(8)
        $('#image-code').attr('src', process.env.VUE_APP_SERVER + '/business/client/kaptcha/image-code/' + _this.imageCodeToken)
      },

      toRegisterMEM () {
        let _this = this
        _this.MODAL_STATUS = _this.STATUS_REGISTER
      },
      toForgetPWD () {
        let _this = this
        _this.MODAL_STATUS = _this.STATUS_FORGET
      },
      toMobileLogin () {
        let _this = this
        _this.MODAL_STATUS = _this.STATUS_INFO
      },

      //-------------------------------- 登录输入框校验 ----------------------------//

      onLoginMobileBlur () {
        let _this = this
        _this.loginMobileValidator = Pattern.validate('mobile', _this.member.mobile)
        return _this.loginMobileValidator
      },

      onLoginPasswordBlur () {
        let _this = this
        _this.loginPasswordValidator = Pattern.validate('password', _this.member.password)
        return _this.loginPasswordValidator
      },

      /**
       * 登录
       */
      clientLogin () {
        let _this = this

        //如果用户存在登录状态，则直接提示他已登录，请不要重复登录
        if (LocalStorage.get(TOKEN)) {
          this.$message({
            type: 'info',
            message: '您已登录，请不要重复登录',
            duration: 2000,
            offset: 110
          })

          setTimeout(() => {
            $('#login-modal').modal('hide')
            _this.$router.push('/admin/index')
          }, 200)
        }

        //表单校验
        let validatorRules1 = _this.onLoginMobileBlur()
        let validatorRules2 = _this.onLoginPasswordBlur()
        let validatorRules = validatorRules1 && validatorRules2
        if (!validatorRules) {
          return
        }

        /*
        //如果密码是缓存中取出，则不需要二次加密
        let c_md5 = hex_md5(_this.member.password)
        //先判断是否与本地缓存相同
        let rememberMe = LocalStorage.get(MEMBER_LOGIN_LOCAL) || {}
        if (c_md5 !== rememberMe.new_md5) {
          _this.member.password = hex_md5(_this.member.password + KEY)
        }
        _this.member.imgCodeToken = _this.imageCodeToken
        */

        // axios({
        //   method: 'post',
        //   url: process.env.VUE_APP_SERVER + "/blade-auth/token",
        //   //responseType: 'blob',
        //   headers: {
        //     'authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        //     'Content-type':'application/x-www-form-urlencoded'
        //   },
        //   data: {
        //     account: _this.member.mobile,
        //     password: _this.member.password
        //   }
        // })
        // .then((res) => {
        //   console.log("-----------+++++++++++");
        //   console.log(res);
        // });
        Loadings.show()
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/blade-auth/token?' + 'account=' + _this.member.mobile + '&password=' + _this.member.password,
          method: 'POST',
          headers: {
            'authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
            //'Content-type':'application/x-www-form-urlencoded'
          },
          // data: {
          //   account: _this.member.mobile,
          //   password: _this.member.password
          // },
        })
          .then((res) => {
            Loadings.hide()
            if (res.status == 200) {
              if (res.data.code == 200) {
                console.log("res.data.data: ",res.data.data)
                //判断是否勾选了是否记住密码
                if (_this.rememberme) {
                  //将手机号密码添加到本地缓存
                  //let new_md5 = hex_md5(_this.member.password);

                  // LocalStorage.set(MEMBER_LOGIN_LOCAL, _this.member)
                } else {
                  //清除记住密码缓存
                  LocalStorage.remove(MEMBER_LOGIN_LOCAL)
                }

                //登录后，将登录信息添加到本地缓存中
                LocalStorage.set(TOKEN, res.data.data.accessToken)
                LocalStorage.set(TENANT_INFO, res.data.data)
                LocalStorage.set(LOGIN_USER, res.data.data)

                //存储localStorage的同时，也存储一个cookie来监听
                LocalStorage.setCookie(TOKEN, true)

                //存储黑龙江
                LocalStorage.set(IS_HLJ,res.data.data.isHeiLongJiang)
                //存储牡丹江-东宁市
                LocalStorage.set(IS_DN,res.data.data.isDongNing)

                //用户登录判断用户等级
                let respData = res.data.data
                if (respData && respData.roleList && respData.roleList.length > 0) {
                  let loginUser = LocalStorage.get(LOGIN_USER)
                  loginUser.level = 1
                  let levels = []
                  for (let i = 0; i < respData.roleList.length; i++) {
                    levels.push(respData.roleList[i].level)
                  }
                  if (levels.indexOf(0) != -1 || levels.indexOf(1) != -1) {
                    loginUser.level = 1
                  } else {
                    loginUser.level = 3
                  }
                  LocalStorage.set(LOGIN_USER, loginUser)
                }

                if (res.data.data.tenantId) {
                  //如果已经完善店铺信息，则存入缓存中
                  LocalStorage.set(IS_EXISTS_SHOPINFO, true)

                  //跳转到首页
                  $('#login-modal').modal('hide')
                  setTimeout(function () {
                    _this.$router.push('/admin/index')
                  }, 200)
                } else {
                  //加载下拉列表数据
                  _this.loadAll()
                  //跳转到完善店铺信息界面
                  _this.MODAL_STATUS = _this.STATUS_INFO
                  $('.modal-content').addClass('bigModal')
                }

              }else if(res.data.code==333){
                this.showExpireModel()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      showExpireModel(){
        this.$toast.warning('warning' , 'center' , '您的账号已到期，为了不影响您正常使用，请联系客服续费，电话：0318-2111122' , 999999)
      },
      //-------------------------------- 注册框校验 ----------------------------//

      onRegisterMobileBlur () {
        let _this = this
        _this.registerMobileValidator = Pattern.validate('mobile', _this.memberRegister.mobile)
        return _this.registerMobileValidator
      },

      onRegisterMobileCodeBlur () {
        let _this = this
        _this.registerMobileCodeValidator = Pattern.validate('mobileCode', _this.memberRegister.shortMessageCode)
        return _this.registerMobileCodeValidator
      },

      onRegisterPasswordBlur () {
        let _this = this
        _this.registerPasswordValidator = Pattern.validate('password', _this.memberRegister.passwordOriginal)
        return _this.registerPasswordValidator
      },

      onRegisterPasswordConfirmBlur () {
        let _this = this
        let confirmPassword = $('#register-confirm-password').val()
        if (Tool.isEmpty(confirmPassword)) {
          return _this.registerPasswordConfirmValidator = false
        }
        return _this.registerPasswordConfirmValidator = (confirmPassword === _this.memberRegister.passwordOriginal)
      },

      //********************************自定义方法******************************//

      /**
       * 1.注册
       */
      register () {
        let _this = this
        let rpassword = $('#register-confirm-password').val()

        //文本框全部校验
        let validatorRules1 = _this.onRegisterMobileBlur()
        let validatorRules2 = _this.onRegisterMobileCodeBlur()
        let validatorRules3 = _this.onRegisterPasswordBlur()
        let validatorRules4 = _this.onRegisterPasswordConfirmBlur()
        let validatorRules = validatorRules1 && validatorRules2 && validatorRules3 && validatorRules4
        if (!validatorRules) {
          return
        }

        Loadings.show()
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/smsSendRecord/checkValidateCode?' + 'phone=' + _this.memberRegister.mobile + '&code=' + _this.memberRegister.shortMessageCode + '&smsType=' + SHORT_MESSAGE_USE.REGISTER.key,
          method: 'POST',
          // data: {
          //   account: _this.member.mobile,
          //   password: _this.member.password
          // },
        })
          .then((res) => {
            Loadings.hide()
            if (res.status == 200) {
              if (res.data.code == 200) {
                Loadings.show()
                //调用注册接口
                _this.$axios.request({
                  url: process.env.VUE_APP_SERVER + '/blade-user/register?' + 'phone=' + _this.memberRegister.mobile + '&password=' + _this.memberRegister.passwordOriginal + '&rpassword=' + rpassword,
                  method: 'POST',
                  // data: {
                  //   account: _this.member.mobile,
                  //   password: _this.member.password
                  // },
                })
                  .then((resp) => {
                    Loadings.hide()
                    if (resp.status == 200) {
                      if (resp.data.code == 200) {
                        //注册成功，跳转登录页
                        _this.toLoginAcount()
                      } else {
                        this.$message({
                          type: 'warning',
                          message: resp.data.msg,
                          duration: 2000,
                          offset: 110
                        })
                      }
                    } else {
                      this.$message({
                        type: 'warning',
                        message: '网络异常',
                        duration: 2000,
                        offset: 110
                      })
                    }
                  })
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },

      //-------------------------------- 忘记密码框校验 ----------------------------//

      onForgetMobileBlur () {
        let _this = this
        return _this.forgetMobileValidator = Pattern.validate('mobile', _this.memberForget.mobile)
      },

      onForgetMobileCodeBlur () {
        let _this = this
        return _this.forgetMobileCodeValidator = Pattern.validate('mobileCode', _this.memberForget.shortMessageCode)
      },

      onForgetPasswordBlur () {
        let _this = this
        return _this.forgetPasswordValidator = Pattern.validate('password', _this.memberForget.passwordOriginal)
      },

      onForgetPasswordConfirmBlur () {
        let _this = this
        let forgetPassword = $('#forget-confirm-password').val()
        if (Tool.isEmpty(forgetPassword)) {
          return _this.forgetPasswordConfirmValidator = false
        }
        return _this.forgetPasswordConfirmValidator = (forgetPassword === _this.memberForget.passwordOriginal)
      },

      /**
       * 忘记密码:/reset-password
       */
      resetPWD () {
        let _this = this
        let rpassword = $('#forget-confirm-password').val()

        //[单注册校验]注册之前增加校验，一个文本框为false，都不提交return
        let validatorRules = _this.onForgetMobileBlur() && _this.onForgetMobileCodeBlur() && _this.onForgetPasswordBlur() && _this.onForgetPasswordConfirmBlur()
        if (!validatorRules) {
          return
        }
        //验证短信验证码是否正确
        Loadings.show()
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/smsSendRecord/checkValidateCode?' + 'phone=' + _this.memberForget.mobile + '&code=' + _this.memberForget.shortMessageCode + '&smsType=' + SHORT_MESSAGE_USE.FORGET.key,
          method: 'POST',
          // data: {
          //   account: _this.member.mobile,
          //   password: _this.member.password
          // },
        })
          .then((res) => {
            Loadings.hide()
            if (res.status == 200) {
              if (res.data.code == 200) {
                //调用重置密码接口
                _this.$axios.request({
                  url: process.env.VUE_APP_SERVER + '/blade-user/setPassword?' + 'phone=' + _this.memberForget.mobile + '&password=' + _this.memberForget.passwordOriginal + '&rpassword=' + rpassword,
                  method: 'POST',
                  // data: {
                  //   account: _this.member.mobile,
                  //   password: _this.member.password
                  // },
                })
                  .then((resp) => {
                    if (resp.status == 200) {
                      if (resp.data.code == 200) {
                        //注册成功，跳转登录页
                        _this.toLoginAcount()
                      } else {
                        this.$message({
                          type: 'warning',
                          message: resp.data.msg,
                          duration: 2000,
                          offset: 110
                        })
                      }
                    } else {
                      this.$message({
                        type: 'warning',
                        message: '网络异常',
                        duration: 2000,
                        offset: 110
                      })
                    }
                  })
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },

      /**
       * 发送短信验证码
       * type：A-用户注册 B-密码重置
       */
      sendSmsCode (type) {
        let _this = this

        //对手机号进行验证
        if (type === SHORT_MESSAGE_USE.REGISTER.key) {
          if (!_this.onRegisterMobileBlur()) {
            return
          }
          let shortMessage = {
            mobile: _this.memberRegister.mobile,
            type: type
          }
          //发送短信验证码
          _this.sendMobileSMSCode(shortMessage, 'register-send-code-btn')
        } else if (type === SHORT_MESSAGE_USE.FORGET.key) {
          if (!_this.onForgetMobileBlur()) {
            return
          }
          let shortMessage = {
            mobile: _this.memberForget.mobile,
            type: type
          }
          //发送短信验证码
          _this.sendMobileSMSCode(shortMessage, 'forget-send-code-btn')
        }
      },

      //发送短信验证码请求
      sendMobileSMSCode (shortMessage, sendBtn) {
        let _this = this
        //调用短信发送接口
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/smsSendRecord/sendValidateCode?' + 'phone=' + shortMessage.mobile + '&smsType=' + shortMessage.type,
          method: 'POST',
          // data: {
          //   account: _this.member.mobile,
          //   password: _this.member.password
          // },
        })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.code == 200) {
                //设置倒计时，按钮不可点击
                _this.countDown = 60
                _this.setSMTime(sendBtn)
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },

      /*小程序扫码登录*/
      miniLogin () {
        let _this = this
        _this.MODAL_STATUS = _this.STATUS_MINI_LOGIN
        /*jQuery('#ceventQRCode').qrcode({
          text : "xxx",
        });*/
        /*let ws=new WebSocket('ws://192.168.1.191:8002/phoneCodeLoginWebSocket')
        console.log(ws)*/
        if ('WebSocket' in window) {
          /*
          let urls=process.env.VUE_APP_SERVER
          if(urls.indexOf('http://')!==-1){
            urls=urls.slice(7)
          }
          if(urls.indexOf('https://')!==-1){
            urls=urls.slice(8)
          }
          */

          let url = ''
          if (process.env.NODE_ENV === 'development') {
            //开发环境 do something
            url = 'localhost:8002'
          } else if (process.env.NODE_ENV === 'test') {
            //测试环境 do something
            url = '111.225.206.120:8002'
          } else if (process.env.NODE_ENV === 'production') {
            //生产环境 do something
            url = '47.105.125.230:8002'
          }
          nyb.initWs('ws://' + url + '/phoneCodeLoginWebSocket')
          listenCallback.qrcodeAction = function (r) {
            if (+r.type === 1) {
              // 获取二维码成功
              $('#ceventQRCode').attr('src', r.imageDataBase64)
              //toast.showOK('登录成功！', 'center')
            } else if (+r.type === 2) {
              // 扫码成功 等待手机确认
              $('#J_qrCodeImg, .msg-err').hide()
              $('.msg-login').show()
            } else if (+r.type === 3) {
              // 小程序允许登录成功
              Loadings.show()
              _this.$axios.request({
                url: process.env.VUE_APP_SERVER + '/blade-auth/token?grantType=qrcode' + '&userId=' + r.userId + '&code=' + r.code,
                method: 'POST',
                headers: {
                  'authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
                },
              })
                .then((res) => {
                  Loadings.hide()
                  if (res.status == 200) {
                    if (res.data.code == 200) {
                      //登录后，将登录信息添加到本地缓存中
                      LocalStorage.set(TOKEN, res.data.data.accessToken)
                      LocalStorage.set(TENANT_INFO, res.data.data)
                      //用户登录判断用户等级
                      let loginUser = res.data.data
                      loginUser.level = 1
                      let levels = []
                      for (let i = 0; i < loginUser.roleList.length; i++) {
                        levels.push(loginUser.roleList[i].level)
                      }
                      if (levels.indexOf(0) != -1 || levels.indexOf(1) != -1) {
                        loginUser.level = 1
                      } else {
                        loginUser.level = 3
                      }
                      LocalStorage.set(LOGIN_USER, loginUser)
                      if (res.data.data.tenantId) {
                        //跳转到首页
                        $('#login-modal').modal('hide')
                        setTimeout(function () {
                          _this.$router.push('/admin/index')
                        }, 200)
                      } else {
                        //加载下拉列表数据
                        _this.loadAll()
                        //跳转到完善店铺信息界面
                        _this.MODAL_STATUS = _this.STATUS_INFO
                        $('.modal-content').addClass('bigModal')
                      }
                    } else {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '网络异常',
                      duration: 2000,
                      offset: 110
                    })
                  }
                })
            } else if (+r.type === 4) {
              // 请求超时
              _this.miniLogin()
            }
          }
        }
      },
      refreshCodeImg () {
        this.miniLogin()
      },
      /**
       * 设置按钮倒计时
       */
      setSMTime (sendBtn) {
        let _this = this
        let btn = $('#' + sendBtn)
        if (_this.countDown === 0) {
          btn.removeAttr('disabled')
          btn.text('获取验证码')
          //=0停止当前时间循环
          return
        } else {
          //不为0，不可点击,显示倒计时
          btn.attr('disabled', true)
          btn.text('重新发送(' + _this.countDown + ')')
          _this.countDown--
        }
        //每1秒执行一次setTime
        setTimeout(function () {
          _this.setSMTime(sendBtn)
        }, 1000)
      },
      newJXC () {
        window.open('http://jxc2.zftdata.com/', '_blank')
      },
      oldJXC () {
        window.open('http://jxc.zftdata.com/', '_blank')
      },
    }
  }
</script>

<style scoped>
  .modal-body {
    padding: 0
  }

  .form-content {
    padding: 30px;
  }

  /*控制模态框大小*/
  .modal-dialog {
    width: 390px;
    padding-left: 0 !important;
  }

  /* 登录框 */
  .login-div .input-group-addon {
    padding: 0.5rem;
    border: 0;
  }

  /*header*/
  .loginHeader {
    background: #ffffff;
    color: #000000;
    text-align: center;
    padding: 10px 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 16px;
    line-height: 40px;
  }

  .loginHeader .shotMsgLogin {
    display: inline-block;
    width: 50%;
    color: #000000;
    border-bottom: 2px solid #dddada;
    cursor: pointer;
  }

  .loginHeader .psdLogin {
    cursor: pointer;
    display: inline-block;
    width: 50%;
    color: #000000;
    border-bottom: 2px solid #dddada;
  }

  .headerBottom {
    border-bottom: 2px solid #dddada;
  }

  /*模态框关闭*/
  /*
  .loginHeader h3{
    font-size: 18px;
    font-weight: 400;
    color: #e52a33;
    border-bottom-color:#e52a33;
  }
  .loginHeader .dialogClose{
      color: white;
      opacity: 1;
      font-size: 26px;
      margin-top: -60px;
      margin-right: 10px;
      text-shadow:0 0px;
      font-weight:unset;
  }*/
  #login-modal h3 {
    padding: 0;
  }

  #login-modal .modal-login {
    max-width: 800px;
    margin: 130px auto 70px auto;
    height: 500px;
  }

  /*取消样式，否则会隔行
  :not，如果id=idName则不响应当前样式
  */
  #login-modal input:not(.rememberme) {
    height: 45px;
    font-size: 16px;
  }

  #login-modal .submit-button {
    height: 50px;
    font-size: 20px;
  }

  #login-modal .to-login-acount {
    text-align: center;
  }

  /*输入验证码*/
  .loginInput {
    display: block;
    width: 100%;
    padding: 6px 12px;

    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    height: 60px !important;
  }

  /*验证码*/
  .validateCode {
    float: left;
    width: 60%;
    margin-right: 10px;
  }

  .validateSendCode {

  }

  .clickButtom {
    background: #e92322;
    color: white;
    border: 0;
  }

  .clickButtom:hover, .clickButtom:focus {
    background: #f53433;
  }

  .refreshButton {
    background: #67C23A;
    color: white;
    border: 0;
    line-height: 20px;
  }

  .refreshButton:hover, .refreshButton:focus {
    background: #58b12d;
  }

  .refreshIcon {
    line-height: 20px;
  }

  /*记住密码*/

  .checkbox input[type=checkbox] {
    position: relative;
    margin-right: 5px;
  }

  .checkbox label, .radio label {
    padding-left: 20px;
    float: left;
  }

  .checkBoxRemember {
    display: inline-block;

  }

  .bottomLabel {
    padding-bottom: 20px;
  }

  .to-login-toast {
    color: #e92322;
  }

  /*顶部样式*/
  .redActive {
    border-bottom: 2px solid #e92322 !important;
    color: #e92322 !important;
  }

  /*发送短信验证码样式*/
  #forget-send-code-btn {
    background-color: #e92322;
    color: white;
    line-height: 30px;
    width: 120px;
  }

  .btn-outline-secondary {
    background-color: #e92322;
    color: white;
    line-height: 30px;
    width: 120px;
  }

  /*页面跳转样式*/
  .aLink {
    color: #999;
  }

  .header_icon {
    z-index: 1051;
  }

  .header_icon #header_icon_img {
    text-decoration: none;
    color: white;
    width: 100%;
    height: 130px;
    display: block;
    background-position: center;
    /*避免图片尺寸不一样，进行填充屏幕*/
    background-size: cover;
    position: relative;
  }

  #icon_tag1 {
    position: absolute;
    top: 0;
    left: 0;
    height: 130px;
  }

  #icon_tag2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 130px;
  }

  #login-modal {
    background-position: unset;
    /*避免图片尺寸不一样，进行填充屏幕*/
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background-image: url("/static/imgs/login/login-bg-mdj-4.png");
  }

  .el-footer {
    /*color: #ffffff;*/
    color: #999;
    text-align: center;
    line-height: 60px;
  }

  /*完善信息*/
  .store-info-modal {

  }

  .store-info-form {
    padding: 20px 35px 20px 20px;
  }

  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-form-item__label:before {
    margin-left: 4px;
    float: right;
  }

  .storeInfoBtn {
    background: #e92322;
    float: left;
    margin-left: -50px;
    width: 150px;
  }

  .resetBtn {
    float: right;
    width: 150px;
    margin-right: 50px;
  }

  .storeInfoSub /deep/ {
  }

  .bigModal {
    width: 500px;
    right: 70px;
  }

  .mapModal {
    width: 1000px;
    height: 600px;
    left: -300px;
  }

  /*返回登录*/
  .returnLogin {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .styletemp {
    height: 66px;
    width: 88px;
    margin-top: 153px;
  }

  .linkSpan {
    color: #6699cc;
    margin-right: 10px;
  }

  .linkBtn {
    background: #e92322;
    padding: 5px;
  }

  /*系统更新提示*/
  /deep/ .el-alert__title.is-bold {
    color: #e92322;
  }

  .tipMessage {
    position: absolute;
    left: 34.7%;
    top: 140px;
    width: 600px;
  }

  /deep/ .el-alert--warning.is-light .el-alert__description {
    text-align: left;
    font-size: 12px;
  }

  .miniCode {
    position: absolute;
    right: 100px;
    top: 400px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
  }

  .miniCode img {
    width: 200px;
    float: right;
    margin-bottom: 20px;
  }

  .miniCode span {
    display: block;
    background-color: #ffffff;
    font-weight: 500;
  }

  .locationIcon {
    font-size: 20px;
    margin-top: 10px;
    color: #2a92ee;
    cursor: pointer;
  }

  .locationIcon:hover {
    color: #58adf8;
  }

  .modal-footer {
    position: relative;
    text-align: center;
  }

  .locationBtn {
    background: #e92322;
    width: 200px;
    padding: 10px;
    float: bottom;
    margin: 0 auto;
    border-color: #f56363
  }

  #myModal {
    margin-top: 110px;
  }

  .modal-title {
    color: #e92322;
    font-weight: 700;
  }

  .pwdForm {
    position: relative;
  }

  .pwdEye {
    line-height: 45px;
    position: absolute;
    right: 0px;
    bottom: 174px;
  }

  /deep/ .el-input__icon {
    height: unset;
  }
  .loginFooter{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .loginFooter .textLine{
    color:#ffffff;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .loginFooter .linkLine{
    color: #e9eef3;
    font-size: 14px;
    cursor: pointer;
  }
  .loginFooter .linkLine:hover,.loginFooter .linkLine:focus{
    border-bottom: 1px solid #d9eaf8;
  }
</style>
