<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title" v-if="type!==3">选择所属客户</div>
        <div class="title" v-if="type===3">请选择处置单位</div>
      </div>
      <div class="orderNav">
        <div class="navLeft">
          <el-tag class="showCheck" v-if="!showAddDispose">{{type===3?'当前处置单位':'当前客户'}} <span>{{checkClientIds.length>0?checkClientIds.length:0}}</span>
            个
            <span v-if="checkClientIds.length>0">{{strClients}}</span></el-tag>
          <el-button class="searchBtn" type="primary" @click="addClients"
                     v-if="disposeUnits.length>0 && !showAddDispose">确认选中
          </el-button>
          <el-button class="searchBtn" type="warning" @click="returnClients" icon="el-icon-d-arrow-left"
                     v-if="showAddDispose ">返回处置单位列表
          </el-button>
          <el-button class="searchBtn" type="primary" @click="addClient" v-if="getAuthorityMenu('400301')">新增处置单位</el-button>
        </div>
        <div class="navRight" v-if="!showAddDispose">
          <el-input class="searchInput"
                    placeholder="输入处置单位名称、联系人或手机号"
                    v-model="searchInput"
                    :blur="searchInputChange(searchInput)"
                    :clearable="true">
          </el-input>
          <el-button class="searchBtnSearch" type="danger" @click="getSearch(1)">搜索</el-button>
        </div>

      </div>

      <div class="pageList" v-if="showPageList">
        <div class="dialogBody">

          <div class="mainForm">
            <el-table
              ref="multipleTable"
              tooltip-effect="dark"
              :data="disposeUnits"
              border
              height="540px"
              highlight-current-row
              empty-text="暂无处置单位，请 新增处置单位"
              class="previous-row"
              @current-change="handleCurrentChange"
              @selection-change="handleSelectionChange"
              :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
            >
              <el-table-column type="selection" class="indexCenter" width="35"></el-table-column>
              <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
              <el-table-column property="unitName" label="处置单位名称" width="300px" >
                <template slot-scope="scope">
                  <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.unitName)" :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{scope.row.unitName===''?'-':scope.row.unitName}}
                    </div>
                    <span class="showTipLine">{{scope.row.unitName===''?'-':scope.row.unitName}}</span>
                  </el-tooltip>
                  <span class="clientIdShow">{{scope.row.disposeUnitId}}</span>
                </template>
              </el-table-column>
              <el-table-column property="unitPerson" label="联系人" width="300px" sortable>
                <template slot-scope="scope">
                  <el-tooltip placement="bottom" @mouseenter.native="remarkEnter(scope.row.unitPerson)" :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{scope.row.unitPerson===''?'-':scope.row.unitPerson}}
                    </div>
                    <span class="showTipLine">{{scope.row.unitPerson===''?'-':scope.row.unitPerson}}</span>
                  </el-tooltip>

                </template>
              </el-table-column>
              <el-table-column property="unitPhone" label="手机号" sortable></el-table-column>
              <!--<el-table-column property="regionName" label="地址" width="220">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.regionName === '' ? 'info':'primary'" class="showCenter">
                    {{scope.row.regionName===''?'-':scope.row.regionName}}
                  </el-tag>
                </template>
              </el-table-column>-->
              <!--<el-table-column property="status" label="状态" width="100" sortable>
                <template slot-scope="scope">
                  <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                          id="status">{{SUPPLIER_STATUS | optionObjectKV(scope.row.status)}}
                  </el-tag>
                </template>
              </el-table-column>-->
              <el-table-column property="remark" label="备注" width="120px">
                <template slot-scope="scope">
                  <el-tooltip placement="bottom" @mouseenter.native="remarkEnter(scope.row.remark)" :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{scope.row.remark===''?'-':scope.row.remark}}
                    </div>
                    <el-tag type="info" class="showCenter">
                      {{scope.row.remark===''?'-':scope.row.remark}}
                    </el-tag>
                  </el-tooltip>

                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="pageNav" aria-label="分页">

          <div class="pageChoose">
            <el-button type="danger" plain :disabled="page===1" @click="selectPage(1)">首页</el-button>
            <el-button type="danger" icon="el-icon-caret-left" circle
                       :disabled="page===1" @click="selectPage(page-1)"></el-button>
            <el-button class="pageNumber" :class="p===0?'pageNumbers':''" type="danger" plain v-for="p in pages"
                       :key="p"
                       @click="selectPage(p+1)">{{p+1}}
            </el-button>
            <el-button type="danger" icon="el-icon-caret-right" circle
                       :disabled="page===pageTotal" @click="selectPage(page+1)"></el-button>
            <el-button type="danger" plain :disabled="page===pageTotal" @click="selectPage(pageTotal)">尾页</el-button>
            <el-button class="totalCount" type="danger" plain>共{{totalSize}}条</el-button>
            <span>前往</span>
            <el-input class="goPageNum" v-model="input" placeholder="1" @change="inputValue"></el-input>
            <el-button v-model="input" id="goBtn" type="danger" plain @click="selectPage(input)">GO</el-button>
          </div>

        </div>
      </div>

      <div class="addDisposeForm" v-if="showAddDispose">
        <div class="mainForm">
          <el-form :model="disposeUnit" :rules="rules" ref="disposeUnit" label-width="120px"
                   class="demo-disposeUnit store-info-form">
            <el-form-item class="labelTitle" label="处置单位名称" prop="unitName">
              <el-input class="inputLine" v-model="disposeUnit.unitName" placeholder="请输入处置单位名称"
                        maxlength="50"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="联系人" prop="unitPerson">
              <el-input class="inputLine" v-model="disposeUnit.unitPerson" placeholder="请输入联系人"
                        maxlength="20"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="手机号" prop="unitPhone">
              <el-input class="inputLine" v-model="disposeUnit.unitPhone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="备注" prop="remark">
              <el-input id="stockDescription" class="inputCoreDesc" :rows="6"
                        type="textarea" maxlength="500" show-word-limit v-model="disposeUnit.remark"
                        placeholder="请输入备注信息，最多500个字符"></el-input>
            </el-form-item>

            <el-form-item class="storeInfoSub">
              <el-button class="storeInfoBtn" type="danger" @click="save('disposeUnit')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('disposeUnit')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {
      /*checkClientIds: {
        type:Array,
        default:null
      },
      checkClientNames:{
        type:Array,
        default:null
      },*/
    },
    data () {
      let validate_clientPhone = (rule, value, callback) => {
        if (value && !Pattern.validate('mobile', value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        message: '选择处置单位',
        unitName: {
          default: '-'
        },
        disposeUnit: {},
        disposeUnits: [],
        disposeUnitIds: [],
        checkClientIds: 0,
        centerDialogVisible: false,
        searchInput: '',
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        currentRow: null,
        pageShow: true,
        showPageList: true,
        showAddDispose: false,
        //分页
        list: {
          type: Function,
          default: null
        },
        //显示的页码数，共50页，显示5页，其他用...表示
        itemCount: Number,
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        input: null,
        //选中
        checkClients: 0,
        strClients: '',
        type: 1,//type=1商品管理，=2进货
        remarkFlag: false,
        previousCheck: {},//上一次选中
        rules: {
          unitName: [
            {
              required: true,
              message: '请输入处置单位名称',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 50,
              message: '处置单位名称最多不能超过50个字符',
              trigger: 'blur'
            }
          ],
          unitPerson: [
            {
              required: true,
              message: '请输入联系人',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 20,
              message: '联系人最多不能超过20个字符',
              trigger: 'blur'
            }
          ],
          unitPhone: [
            {
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              validator: validate_clientPhone,
              trigger: 'blur'
            }
          ],
        },
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      getClientIds (disposeUnitIds, type) {
        this.disposeUnitIds = []
        this.type=type
        this.disposeUnitIds = disposeUnitIds
        //注意，必须先打开对话框才能获取对话框的ref
        this.centerDialogVisible = true
        this.showAddDispose = false
        this.getSearch(1)
      },

      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        let size = 10 //引用传入组件后，在标签中refs定义的别名

        let keywords = this.searchInput
        let showStop = 0
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastedisposeunit/page?current=' + current + '&size=' + size + '&keywords=' + keywords + '&showStop=' + showStop)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.disposeUnits = resp.data.records
            this.renderPage(page, resp.data.total)
            this.stockOrdering()

            let closeDialog = $('.el-dialog__close')
            if (this.clientIds === undefined) {
              closeDialog[0].style.display = 'none'
            } else {
              closeDialog[0].style.display = 'block'
            }
          })
      },
      reSearch (data) {
        this.getSearch(1, data)
      },
      //进货
      stockOrdering () {
        let _this = this
        let pseudoArr = document.getElementsByClassName('el-checkbox')[0]
        pseudoArr.style.display = 'none'
        pseudoArr.firstChild.style.display = 'none'
        setTimeout(function () {
          _this.toggleSelection()
        }, 10)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      handleChange (value) {
        this.size = value[0]
        this.$emit('pageSizeChange', this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
        this.getSearch(this.input)
      },
      /**
       * 渲染分页组件
       */
      renderPage (page, totalSize) {
        this.page = parseInt(page) //当前页
        this.totalSize = totalSize //总条数 / 每页显示条数size
        this.pageTotal = Math.ceil(totalSize / this.size)
        //默认分页后显示的页码数：10
        this.pages = this.getPageItems(this.pageTotal, page, 10)
        let pageNums = $('.pageNumber')
        if (pageNums.length > 0) {
          if (page === 1) {
            Tool.addClass(pageNums[0], 'pageNumbers')
          }
          let newPageIndex = 0
          if (page !== 1) {
            for (let i = 0; i < pageNums.length; i++) {
              Tool.removeClass(pageNums[i], 'pageNumbers')
              if (pageNums[i].innerText === page) {
                //console.log('')
              }
            }
          }
          for (let j = 0; j < pageNums.length; j++) {
            if (pageNums[j].innerText === page + '') {
              Tool.addClass(pageNums[j], 'pageNumbers')
            }
          }
        }
      },
      //查询每一页
      selectPage (page) {
        let _this = this
        if (page < 1) {
          page = 1
        }
        //如果传入的当前页>总页码
        if (page > _this.pageTotal) {
          page = _this.pageTotal
        }
        if (this.page !== page) {
          _this.page = page
          /*if (_this.list) {
            _this.list(page)
          }*/
        }
        let pageNums = $('.pageNumber')
        for (let i = 0; i < pageNums.length; i++) {
          Tool.removeClass(pageNums[i], 'pageNumbers')
        }
        _this.getSearch(page)
      },
      //当前显示的页码
      getPageItems (totalSize, current, length) {
        let items = []
        if (length >= totalSize) {
          for (let i = 0; i < totalSize; i++) {
            //总条数放入items，遍历页码
            items.push(i)
          }
        } else {
          let base = 0
          //前移:向下取整
          if (current - 0 > Math.floor((length - 1) / 2)) {
            //后移：向上取整
            base = Math.min(totalSize, current - 0 + Math.ceil((length - 1) / 2)) - length
          }
          //条数集合
          for (let i = 1; i <= length; i++) {
            items.push(base + i)
          }
        }
        return items
      },
      //3.添加class
      addClass (node, className) {
        let reg = new RegExp('\\b' + className + '\\b')
        if (!reg.test(node.className)) {
          node.className += (' ' + className)
        }
      },

      //4.移除class
      removeClass (node, className) {
        if (node.className) {
          let reg = new RegExp('\\b' + className + '\\b')
          let classes = node.className
          node.className = classes.replace(reg, '')
          if (/^\s*$/g.test(node.className)) {
            node.removeAttribute('class')
          }
        } else {
          node.removeAttribute('class')
        }
      },

      //多选
      handleCurrentChange (val) {
        this.currentRow = val
      },
      toggleSelection () {
        if (this.disposeUnitIds && this.disposeUnitIds.length > 0) {
          let rows = []
          this.disposeUnits.map((item, index) => {
            this.disposeUnitIds.map(id => {
              if (item.disposeUnitId === id) {
                rows.push(this.disposeUnits[index])
              }
            })
          })
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
      },

      handleSelectionChange (val) {
        let _this = this
        this.multipleSelection = val
        if (this.multipleSelection.length < 1) {
          this.multipleSelection.push(this.previousCheck)
        }
        this.checkClients = this.multipleSelection.length
        let ids = []
        let names = []
        if (this.type === 1) {
          this.checkClientIds = []
          this.checkClientNames = []
          if (this.multipleSelection.length > 0 || this.disposeUnitIds.length > 0) {
            if (this.multipleSelection.length < 1) {
              for (let i = 0; i < this.disposeUnitIds.length; i++) {
                for (let j = 0; j < this.disposeUnits.length; j++) {
                  if (this.disposeUnits[j].disposeUnitId === this.disposeUnitIds[i]) {
                    this.multipleSelection.push(this.disposeUnits[j])
                  }
                }
              }
            }
          }
          for (let i = 0; i < this.multipleSelection.length; i++) {
            ids.push(this.multipleSelection[i].disposeUnitId)
            names.push(this.multipleSelection[i].unitName)
          }
        }

        if (this.type === 2 || this.type === 3) {
          this.checkClientIds = []
          this.checkClientNames = []
          if (this.multipleSelection.length > 0 || this.disposeUnitIds.length > 0) {
            if (this.multipleSelection.length < 1) {
              for (let i = 0; i < this.disposeUnitIds.length; i++) {
                for (let j = 0; j < this.disposeUnits.length; j++) {
                  if (this.disposeUnits[j].disposeUnitId === this.disposeUnitIds[i]) {
                    this.multipleSelection.push(this.disposeUnits[j])
                  }
                }
              }
            }
            this.previousCheck = this.multipleSelection[this.multipleSelection.length - 1]
            ids.push(this.multipleSelection[this.multipleSelection.length - 1].disposeUnitId)
            if (Tool.isNotEmpty(this.multipleSelection[this.multipleSelection.length - 1].unitName)) {
              names.push(this.multipleSelection[this.multipleSelection.length - 1].unitName)
            } else {
              if (Tool.isNotEmpty(this.multipleSelection[this.multipleSelection.length - 1].unitPerson)) {
                names.push(this.multipleSelection[this.multipleSelection.length - 1].unitPerson)
              }
            }

            let checkBox = document.getElementsByClassName('el-checkbox__input')
            for (let i = 0; i < checkBox.length; i++) {
              Tool.removeClass(checkBox[i], 'is-checked')
            }
            this.multipleSelection = this.multipleSelection[this.multipleSelection.length - 1]
          }
        }

        this.checkClientIds = ids
        this.disposeUnitIds=ids
        this.checkClientNames = names
        this.strClients = this.checkClientNames.toString()

        let checkBoxInput = document.getElementsByClassName('el-checkbox__input')
        setTimeout(function () {

          for (let i = 0; i < checkBoxInput.length; i++) {
            if (checkBoxInput[i].className.indexOf('is-focus') !== -1) {
              //Tool.removeClass(checkBoxInput[i],'is-focus')
              Tool.addClass(checkBoxInput[i], 'is-checked')
              let clientNew = checkBoxInput[i].parentNode.parentNode.parentNode.parentNode.childNodes[2].childNodes[0].childNodes[1].innerHTML
              clientNew = parseInt(clientNew)
              for (let i = 0; i < _this.disposeUnits.length; i++) {
                if (_this.disposeUnits[i].disposeUnitId === clientNew) {
                  _this.checkClientIds = []
                  _this.checkClientIds.push(_this.disposeUnits[i].disposeUnitId)
                  _this.clientIds = _this.checkClientIds
                  _this.checkClientNames = []
                  if (Tool.isNotEmpty(_this.disposeUnits[i].unitName)) {
                    _this.checkClientNames.push(_this.disposeUnits[i].unitName)
                  } else {
                    _this.checkClientNames.push(_this.disposeUnits[i].unitPerson)
                  }
                  _this.strClients = _this.checkClientNames.toString()

                }
              }
            }
          }
        }, 50)

        //console.log('选中行：', this.multipleSelection, '当前选中? : ', this.checkClients, '选中id：', this.checkClientIds, '选中name：', this.checkClientNames)
      },
      addClients () {
        this.$emit('getClients', this.checkClientIds, this.checkClientNames)
        this.centerDialogVisible = false
      },
      addClient () {
        this.showPageList = false
        this.showAddDispose = true
        this.disposeUnit = $.extend({}, this.disposeUnit)
      },
      returnClients () {
        this.showPageList = true
        this.showAddDispose = false
        this.disposeUnit={}
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show()
            this.$axios.post(process.env.VUE_APP_SERVER + '/psi/wastedisposeunit/save', this.disposeUnit)
              .then((response) => {
                Loadings.hide()
                if (response.status === 200) {
                  if (response.data.code === 200) {
                    this.$message({
                      type: 'success',
                      message: '新增处置单位成功！',
                      duration: 2000,
                      offset: 110
                    })
                    this.showAddDispose = false
                    this.showPageList = true
                    this.resetForm('disposeUnit')
                    this.getSearch(1)
                  } else {
                    this.$message({
                      type: 'warning',
                      message: response.data.msg,
                      duration: 2000,
                      offset: 110
                    })
                  }
                } else {
                  this.$message({
                    type: 'warning',
                    message: "网络异常",
                    duration: 2000,
                    offset: 110
                  })
                }
              })
          }
        })
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>
  .toolTipWidth {
    max-width: 300px !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
  }
  .showTipLine{
    line-height: 40px;
    height: 40px;
    max-width: 195px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .player-btn {
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .verticalLine {
    position: absolute;
    top: 20px;
    float: left;
    left: 150px;
    width: 3px;
    height: 20px;
    border-radius: 40px;
    background-color: #ffffff;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .navRight {
    float: right;
  }

  /*表格*/
  .orderNav {
    position: relative;
    width: 100%;
    margin-bottom: 50px;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    float: left;
    transition: all .5s;
  }

  .searchBtnSearch {
    margin-right: 10px;
    float: left;
  }

  /*订单表格*/
  .orderTable {
    margin-bottom: 10px;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 480px !important;
  }

  /deep/ .el-table .el-table__cell {
    padding: 6px 0;
  }

  .remark div {
    text-align: left;
  }

  /*分页*/
  .pageNav {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .pageChoose {
    margin-top: 10px;
  }

  .totalCount {
    margin-right: 10px;
  }

  .pageSize {

    margin-top: 5px;
  }

  .sizeNum {
    margin: 0 5px;
    max-width: 150px;
  }

  .goPage {
    margin: 5px 0 5px 5px;
    color: #F56C6C;
  }

  .goPageNum {
    width: 60px;
    margin: 0 10px;

  }

  /*跳转页*/
  .pageNumbers {
    background: #F56C6C !important;
    border-color: #F56C6C !important;
    color: #FFF !important;
  }

  /*新增处置单位*/
  .addDisposeForm {
    margin-top: 60px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }
  .clientIdShow {
    display: none;
  }
</style>
