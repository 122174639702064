<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="请输入客户名称、商品名称"
                  value-format="yyyy-MM-dd"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  prefix-icon="el-icon-search"
                  :clearable="true">
        </el-input>

        <div class="block searchTime">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>

        <div class="checkStop">
          <template>
            <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示已撤销订单</el-checkbox>
          </template>
        </div>

      </div>
      <div class="navRight">
        <!--<div class="searchBtn">
          <span class="titleDesc">￥ {{sumAmount}}</span>
          <span class="titleName">&lt;!&ndash;<li class="el-icon-money"></li>&ndash;&gt;{{(startDate==startDateInit || startDate=='') && (endDate==endDateInit || endDate=='')?'近6个月':''}}销货退货总金额 </span>
        </div>-->
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="getSearch(1)" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="saleOrders"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" ></el-table-column>
        <el-table-column property="clientName" label="客户名称" width="200" sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.clientName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{!scope.row.clientPerson || scope.row.clientPerson===''?'-':scope.row.clientPerson}}
              </div>
              <span class="showTipLine">{{!scope.row.clientPerson || scope.row.clientPerson===''?'-':scope.row.clientPerson}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="createTime" label="开单时间" width="160" ></el-table-column>
        <el-table-column property="goodsName" label="购买商品" sortable>
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthGoods" placement="bottom" @mouseenter.native="remarkEnter(scope.row.goodsName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.goodsName===''?'-':scope.row.goodsName}}
              </div>
              <span class="showTipLine">{{scope.row.goodsName===''?'-':scope.row.goodsName}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="realRefundAmount" label="实收金额" width="150" sortable>
          <template slot-scope="scope">
            <el-tag type="danger" class="showCenter">￥ {{scope.row.realRefundAmount}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{SUPPLIER_ORDER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <!--<el-table-column property="remark" label="备注"  :show-overflow-tooltip="true">
          <template slot-scope="scope" >
            <el-tag type="info" class="showCenter">
              {{scope.row.remark===""?"-":scope.row.remark}}
            </el-tag>
          </template>
        </el-table-column>-->
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="180">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="detail(scope.row)">查看详情
            </el-button>
            <el-button v-if="getAuthorityMenu('300401')"
                       size="mini" :disabled="scope.row.status===1"
                       :type="'warning'"
                       @click="cancelOrder(scope.row)">{{scope.row.status===0?'撤单':'已撤销'}}
            </el-button>
            <!--<el-button v-if="scope.row.show"
              size="mini"
              type="success"
              @click="reOrder(scope.row)">再来一单
            </el-button>-->
            <!--<el-button
              size="mini"
              type="warning"
              @click="del">排序
            </el-button>-->
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-client-order-detail ref="dialogClientOrderDetail" @cancelOrder="cancelOrder"></dialog-client-order-detail>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogClientOrderDetail from '../../../components/DialogClientOrderDetail'

  export default {
    name: 'SaleOrderList',
    components: {
      PageHelper,
      DialogClientOrderDetail
    },
    data () {
      return {
        message: '销货单据列表',
        searchInput: '',
        clientName: '',
        client: {
          clientName: '',
          clientPerson: '',
          clientPhone: '',
          status: 0
        },
        saleOrder: {},
        saleOrders: [],
        checkedStop: false,//默认不显示停用供应商
        currentRow: null,
        SUPPLIER_ORDER_STATUS: SUPPLIER_ORDER_STATUS,
        //弹框
        centerDialogVisible: false,
        //时间选择器
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        value1: '',
        value2: '',
        startDate: '',
        endDate: '',
        startDateInit:'',
        endDateInit:'',
        sumAmount:'',
        remarkFlag: false,
        isHLJ:LocalStorage.get(IS_HLJ),
      }
    },
    mounted () {
      this.client = this.$route.params.item || {}
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1)
      }

      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
    },
    methods: {
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        let size = this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let keywords = this.searchInput
        let showStop = 0
        if (this.checkedStop === false) {
          showStop = 0
        } else {
          showStop = 1
        }

        let startDate=''
        let endDate=''
        let date=new Date
        let newMonth=date.getMonth()-6
        let start=new Date
        start.setMonth(newMonth)
        if(Tool.isEmpty(this.startDate) && Tool.isEmpty(this.endDate)){
          // startDate=Tool.formatDate(start.getTime())
          // endDate=Tool.formatDate(date.getTime())
          // this.startDateInit=startDate
          // this.endDateInit=endDate
        }else{
          startDate=this.startDate
          endDate=this.endDate
        }

        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/salereturnorder/pageForPc?current='
          + current + '&size=' + size + '&clientName=' + keywords + '&status=' + showStop + '&beginTime=' + startDate+'&endTime='+endDate)
          .then((response) => {
              Loadings.hide()
              let resp = response.data
              let orders = resp.data.records
              this.saleOrders=resp.data.orderList;
              this.sumAmount=resp.data.money;
              this.$refs.pageHelper.render(page, resp.data.total)
            }
          )
      },
      reSearch (data) {
        this.getSearch(1, data)
      }
      ,
      //2.已撤销订单
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      }
      ,
      checkedStopClick () {
        this.getSearch(1)
      }
      ,
      //订单详情
      detail (item) {
        let client = {
          clientName: item.clientName,
          clientPerson: item.clientPerson,
          clientPhone: item.clientPhone
        };
        SessionStorage.set(WANGLAIMINGXI_CLIENT, client);
        this.$nextTick(() => {
          let orderId = item.saleReturnOrderId
          let clientName = item.clientPerson && item.clientPerson!==''?item.clientPerson:'-'
          let type=2;
          this.$refs.dialogClientOrderDetail.getOrderId(orderId, clientName,type)
        })
      }
      ,
      //撤销订单
      cancelOrder (item) {
        let _this = this
        let reqUrl='/psi/salereturnorder/revoke'
        if(this.isHLJ){
          reqUrl='/psi/salereturnorder/hlj/revoke'
        }
        if (typeof (item) === 'number') {
          Loadings.show()
          _this.$axios.post(process.env.VUE_APP_SERVER + reqUrl + '?saleReturnOrderId=' + item)
            .then((response) => {
                Loadings.hide()

              if (response.status === 200) {
                if (response.data.code === 200) {
                  _this.$message({ type: 'success', message: '撤单成功！', duration: 2000, offset: 110 });
                  let resp = response.data
                  _this.getSearch(1)
                } else {
                  _this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                }
              } else {
                _this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
              }


              }
            )
        } else {
          this.$toast.showCodeConfirm('撤销当前订单', item.goodsName, '是否撤单', '撤单', function () {
            Loadings.show()
            _this.$axios.post(process.env.VUE_APP_SERVER + reqUrl + '?saleReturnOrderId=' + item.saleReturnOrderId.toString())
              .then((response) => {
                  Loadings.hide()

                if (response.status === 200) {
                  if (response.data.code === 200) {
                    _this.$message({ type: 'success', message: '撤单成功！', duration: 2000, offset: 110 });
                    let resp = response.data
                    let orders = resp.data
                    _this.getSearch(1)
                  } else {
                    _this.$message({ type: 'warning', message: response.data.msg, duration: 2000, offset: 110 });
                  }
                } else {
                  _this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
                }

                }
              )
          })
        }
      }
      ,
      //再来一单

      handleCurrentChange (val) {
        this.currentRow = val
      }
      ,
      //时间选择
      dateChange (e) {
        if (Tool.isNotEmpty(e)) {
          this.startDate = this.$moment(e[0]).format('YYYY-MM-DD')
          this.endDate = this.$moment(e[1]).format('YYYY-MM-DD')
        } else {
          this.startDate = ''
          this.endDate = ''
        }
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .mainPages {
    height: calc(100vh - 120px);
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    margin-right: 10px;
  }

  .supplierName {
    margin-top: -5px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*搜索导航*/
  .topNav {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 250px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*时间搜索*/
  .searchTime {
    float: left;
    margin-right: 10px;
  }

  /deep/ .el-date-editor .el-range-input {
    margin-left: 20px;
  }

  /*表格*/
  .mainForm {

  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 600px;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }
  .toolTipWidth {
    max-width: 200px !important;
    text-align: center !important;
  }
  .toolTipWidthPlugin{
    padding: 0 0 20px;
    float: left;
    text-align: center;
  }
  .showTipLine{
    line-height: 36px;
    height: 36px;
    width: 170px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address{
    max-width: 170px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .showRemarkLine{
    height: 36px !important;
    line-height: 36px !important;
    overflow: hidden;
  }

  .titleName {
    display: inline-block;
    background-color: #e92322;
    color: #ffffff;
    padding: 0 15px;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;
    float: right;
    border-top-left-radius: 10px;
  }

  .titleDesc {
    display: inline-block;
    background-color: #ffffff;
    color: #e92322;
    border: 1px solid #e92322;
    padding: 0 15px;
    line-height: 38px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    float: right;
    border-bottom-right-radius: 10px;
  }

</style>
