<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">店铺信息管理</div>
      </div>

      <div>
        <div class="mainForm">
          <el-form :model="tenant" :rules="rules" ref="store" label-width="120px"
                   class="demo-store store-info-form">
            <el-form-item class="labelTitle" label="店铺名称" prop="tenantName">
              <el-input class="inputLine" v-model="tenant.tenantName" placeholder="请输入店铺名称" :disabled="!canChange"
                        maxlength="50"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="社会信用代码" prop="creditCode">
              <el-input class="inputLine" v-model="tenant.creditCode" placeholder="请输入社会信用代码" :disabled="!canChange"
                        maxlength="20"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="姓名" prop="linkman">
              <el-input class="inputLine" v-model="tenant.linkman" placeholder="请输入姓名" maxlength="20" :disabled="!canChange"></el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="选择区域" prop="addressDetail" @click.native="mapClick">
              <el-input class="inputLine" v-model="tenant.addressDetail" placeholder="请输入详细地址" :disabled="!canChange" readonly>
                <i slot="suffix" :class="canChange===false?'greyTheme':''" class="locationIcon el-icon-location" @click="mapClick"></i>
              </el-input>
            </el-form-item>
            <el-form-item class="labelTitle" label="详细地址" prop="address">
              <el-input class="inputLine" v-model="tenant.address" placeholder="请填写街道门牌信息" :disabled="!canChange">
                <!--<i slot="suffix" :class="canChange===false?'greyTheme':''" class="locationIcon el-icon-location" @click="mapClick"></i>-->
              </el-input>
            </el-form-item>

            <el-form-item class="storeInfoSub">
              <el-button class="storeInfoBtn" type="danger" @click="save('store')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('store')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <!--<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>-->
            <h4 class="modal-title" id="myModalLabel">选择位置</h4>
          </div>
          <div id="mapLocation" class="modal-body mapBody" style="width: 1000px; height: 600px;"></div>
          <!--
          <div class="modal-footer">
            <button type="button" class="locationBtn btn btn-primary" @click="saveLocation">保存</button>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MyStoreManager',
    props: {},
    data () {
      let validate_clientPhone = (rule, value, callback) => {
        if (value && !Pattern.validate('mobile', value)) {
          callback(new Error('请输入正确的手机号'))
        } else {
          callback()
        }
      }
      return {
        message: '店铺信息管理',
        centerDialogVisible: false,
        loginUser:{},
        canChange:true,
        rules: {
          tenantName: [
            {
              required: true,
              message: '请输入店铺名称',
              trigger: 'blur'
            }
          ],
          creditCode: [
            {
              required: true,
              message: '请输入社会统一信用代码',
              trigger: 'blur'
            }
          ],
          linkman: [
            {
              required: true,
              message: '请输入姓名',
              trigger: 'blur'
            }
          ],
          address: [
            {
              required: true,
              message: '请输入详细地址',
              trigger: 'blur'
            }
          ],
          addressDetail: [
            {
              required: true,
              message: '请选择区域',
              trigger: 'blur'
            }
          ]
        },

        tenant: {
          id:'',
          tenantName: '',
          creditCode: '',
          linkman: '',
          address: '',
          addressDetail:''
        },

        province: '',
        city: '',
        district: '',
        township: '',
        addressDetailObj: {
          pro:'',
          city:'',
          district:'',
          township:'',
          code:'',
        }, //选择区域对象
      }
    },
    mounted () {
      if(LocalStorage.get(LOGIN_USER)){
        this.loginUser=LocalStorage.get(LOGIN_USER)
        if(this.loginUser.level){
          if(this.loginUser.level>2){
            this.canChange=false
          }
        }
      }

    },
    methods: {
      //定位图标点击触发
      mapClick() {
        if(this.canChange){
          $('#myModal').modal('show');
          //console.log("myModal: ",$('#myModal')[0].childNodes[0].firstChild)
          Tool.addClass($('#myModal')[0].childNodes[0].firstChild,'mapModal')
          this.getMapLocation();
        }
      },
      getMapLocation: function () {
        let currentAdd=this.tenant.address
        var map = new BMap.Map("mapLocation");    // 创建Map实例
        map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
        // 初始化地图,设置中心点坐标和地图级别
        //map.centerAndZoom(new BMap.Point(116.404, 39.915), 9);
        map.centerAndZoom(new BMap.Point(115.55281540191652, 37.810134993180114), 13);
        var geocoder= new BMap.Geocoder();
        let _this = this;
        map.addEventListener("click",function(e) { //给地图添加点击事件
          geocoder.getLocation(e.point,function(rs) {
            //console.log(rs); //地址描述(string)
            // console.log(rs.addressComponents);//结构化的地址描述(object)
            // console.log(rs.addressComponents.province); //省
            // console.log(rs.addressComponents.city); //城市
            // console.log(rs.addressComponents.district); //区县
            // console.log(rs.addressComponents.street); //街道
            // console.log(rs.addressComponents.streetNumber); //门牌号
            // console.log(rs.surroundingPois); //附近的POI点(array)
            // console.log(rs.business); //商圈字段，代表此点所属的商圈(string)

            _this.tenant.address = rs.addressComponents.province + rs.addressComponents.city + rs.addressComponents.district +
              rs.addressComponents.street + rs.addressComponents.streetNumber+currentAdd;

            _this.province = rs.addressComponents.province;
            _this.city = rs.addressComponents.city;
            _this.district = rs.addressComponents.district;
            _this.township = rs.addressComponents.street;
            _this.tenant.addressDetail=rs.addressComponents.province + rs.addressComponents.city + rs.addressComponents.district +
              rs.addressComponents.street + rs.addressComponents.streetNumber;
            $('#myModal').modal('hide');
          });
        });
      },
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openStore (id) {
        console.log("id: ",id)
        //查找当前用户的原有店铺信息
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/blade-system/tenant/tenantDetail3?id='+id,
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                let data = res.data.data;
                this.tenant.id=data.id
                this.tenant.tenantName = data.tenantName;
                this.tenant.creditCode = data.creditCode;
                this.tenant.linkman = data.linkman;
                this.tenant.address = data.address;
                this.tenant.addressDetail=data.addressPro+data.addressCity+data.addressDistrict+data.addressTownship
                this.addressDetailObj.pro=res.data.data.addressPro
                this.addressDetailObj.city=res.data.data.addressCity
                this.addressDetailObj.district=res.data.data.addressDistrict
                this.addressDetailObj.township=res.data.data.addressTownship
                if(Tool.isEmpty(this.tenant.addressDetail)){
                  this.tenant.addressDetail=this.tenant.address
                }
                //打开店铺信息管理弹窗
                this.centerDialogVisible = true;
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          });
      },
      //重置表单
      resetForm (formName) {
        this.$refs[formName].resetFields();
      },
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Loadings.show();
            this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-user/editTenantInfo?id='+ this.tenant.id +
                '&tenantName=' + this.tenant.tenantName +
                '&creditCode=' + this.tenant.creditCode +
                '&linkman=' + this.tenant.linkman +
                '&address=' + this.tenant.address +
                '&pro=' + this.province +
                '&city=' + this.city +
                '&district=' + this.district +
                '&township=' + this.township,
              method: 'POST'
            })
              .then((res) => {
                Loadings.hide();
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    this.$message({ type: 'success', message: '编辑店铺信息成功！', duration: 2000, offset: 110 });
                    this.$emit('saveStore');
                    //关闭店铺信息管理弹窗
                    this.centerDialogVisible = false;
                  } else {
                    this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
                  }
                } else {
                  this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
                }
              });
          }
        })
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 540px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }
  .remark div {
    text-align: left;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }

  .locationIcon{
    font-size: 20px;
    margin-top: 10px;
    color: #2a92ee;
    cursor: pointer;
  }
  .locationIcon:hover{
    color: #58adf8;
  }
  #myModal{
    z-index: 3000;
    margin-top: 100px;
  }
  .mapModal{
    width: 1002px;
    height: 600px;
    left: -100px;
  }
  .greyTheme{
    color: #999999 !important;
  }
</style>
