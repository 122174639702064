<template>
  <div class="mainBody">
    <div>
      <el-row :gutter="10" class="rowForm">
        <el-col :span="9">
          <div class="leftBody">
            <div class="topNav" @click="handleClick">
              <el-tag class="keyTags" v-if="goodsCart.supplierName===undefined" type="warning">请选择供应商</el-tag>
              <el-tag class="keyTags" id="supplierIds" v-show="goodsCart.supplierName.length>0"
                      :key="index"
                      v-for="(tag,index) in goodsCart.supplierName"
                      :disable-transitions="false"
                      @close="handleClose(tag)">
                <template >
                  <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(tag)" :disabled="!remarkFlag">
                    <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                      {{tag}}
                    </div>
                    <span class="showRemarkLine">{{tag}}</span>
                  </el-tooltip>
                </template>

              </el-tag>
              <el-button class="keyTagsInput" icon="el-icon-circle-plus-outline" type="primary"
                         @click="showChooseDialog">
                选择供应商
              </el-button>
              <div class="tipTag">
                <el-button v-if="showClearBtn" class="clearCart" icon="el-icon-delete" type="danger"
                           @click="showChooseDialog">清空购物车
                </el-button>
              </div>
            </div>
            <div class="toastMessage" @click="handleClick">
              <el-alert title="提示：点击这里，使用扫码枪添加商品" type="success" :closable="false" center show-icon>
              </el-alert>
              <input type="text" id="scanCode" class="scanCode" v-model="qrcode" @input="qrCodeInput"/>
            </div>
            <div class="mainForm" @click="handleClick">
              <el-table
                ref="multipleTable"
                tooltip-effect="dark"
                :data="goodsCart.details"
                height="500px"
                border
                highlight-current-row
                empty-text="未选择任何商品，请在右侧栏选择添加商品"
                class="previous-row"
                :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
              >
                <el-table-column type="index" label="序号" class="indexCenter" width="49" fixed></el-table-column>
                <el-table-column property="goodsName" label="已选商品" width="120"
                                 :show-overflow-tooltip="true" fixed></el-table-column>
                <el-table-column property="packingSpecification" label="包装规格" width="160"
                                 :show-overflow-tooltip="true"></el-table-column>
                <el-table-column property="buyPackageLevel" label="单位" width="49">
                  <template slot-scope="scope">
                    {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                  </template>
                </el-table-column>
                <el-table-column property="packageLevel" label="进货单价" width="105" :show-overflow-tooltip="true"
                                 sortable>
                  <template slot-scope="scope">
                    <el-tag type="danger" class="showCenter">
                      ￥ {{scope.row.goodsPrice}}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column property="packageLevel" label="进货数量" width="105" :show-overflow-tooltip="true"
                                 sortable>
                  <template slot-scope="scope">
                    <el-tag :type="scope.row.buyPackageLevel===1?'primary':'danger'" class="showCenter">
                      {{scope.row.goodsNumber}}
                      {{scope.row.buyPackageLevel===1?scope.row.middleUnitName:scope.row.bigUnitName}}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column property="packageLevel" label="小计" width="135" :show-overflow-tooltip="true" sortable>
                  <template slot-scope="scope">
                    <el-tag type="danger" class="showCenter">
                      ￥ {{scope.row.totalPrice}}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  class="optionList"
                  fixed="right"
                  label="操作"
                  width="145">
                  <!--使用slot-scope传递当前行参数-->
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="editCart(scope.row)">修改
                    </el-button>
                    <el-button
                      size="mini"
                      type="warning"
                      @click="delCart(scope.row)">删除
                    </el-button>
                    <!--<el-button
                      size="mini"
                      type="warning"
                      @click="del">排序
                    </el-button>-->
                  </template>

                </el-table-column>
              </el-table>
            </div>
            <div class="formBottom">
              <el-row>
                <div class="pay">
                  <div class="payLabel">应付金额：</div>
                  <el-input-number class="priceInput" v-model="goodsCart.accountsPayable" :precision="2" :step="0.1"
                                   disabled :min="0"
                                   :max="99999999999.99" placeholder="请输入应收金额"></el-input-number>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="payLabel">实付金额：</div>
                  <el-input-number class="priceInput" v-model="goodsCart.paymentAmount" :precision="2" :step="0.1"
                                   :min="0.00" @blur="paymentAmountChange(goodsCart.paymentAmount)"
                                   :max="99999999999.99" placeholder="请输入整箱销售价"></el-input-number>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="remarkLabel">备注：</div>
                  <el-input id="stockDescription" class="inputCoreDesc" :rows="2"
                            type="textarea" maxlength="500" show-word-limit v-model="goodsCart.remark"
                            placeholder="请输入备注信息，最多500个字符"></el-input>
                </div>
              </el-row>
              <div class="bottomBtn">
                <el-button class="storeInfoBtn" type="danger" icon="el-icon-document-copy" @click="saveCart">去结算
                </el-button>
                <el-badge :value="goodsCart.num" class="item">
                  <el-button :disabled="goodsCart.num>0?false:true" class="resetBtn" type="danger" plain
                             icon="el-icon-delete" @click="clearCart()">清空购物车
                  </el-button>
                </el-badge>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="15">
          <div class="rightBody">
            <el-row>
              <div class="topNav">
                <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                             placeholder="选择商品分类"
                             v-model="category"
                             :options="categorys"
                             :props="{value:'categoryId',label:'categoryName',children:'children'}"
                             separator=" / "
                             @change="categoryChange">
                </el-cascader>
                <el-input class="searchInput"
                          placeholder="请输入商品名称"
                          v-model="searchInput"
                          :blur="searchInputChange(searchInput)"
                          :clearable="true">
                </el-input>
                <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
              </div>
              <div class="navRight">
                <el-button v-show="getAuthorityMenu('200102')" class="searchBtn" type="danger" @click="toNewGood">新增商品</el-button>
                <el-button class="refreshBtn" icon="el-icon-refresh" circle></el-button>
              </div>
            </el-row>
            <div class="supplierGoodsToast">
              <i class="el-icon-warning-outline toastIcon"></i>此列表仅显示当前供应商下商品，如需选择其他商品，请选择对应的其他供应商
            </div>
            <div class="row mainCard" @click="handleClick">
              <el-col :span="4" v-for="(good, index) in goods" :key="index">
                <el-card class="goodCart" :class="good.added?'addGood':''">
                  <!--<el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="cover"></el-image>-->
                  <div class="imgBox">
                    <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="scale-down"></el-image>
                    <div class="innerImg" v-if="good.isGreen==1">
                      <el-image :style="{width:'100%'}" :src="'/static/imgs/green_tag.png'"></el-image>
                    </div>

                  </div>
                  <div v-if="good.added" class="checkedbox">已选</div>
                  <div class="goodDesc">
                    <div class="desc">
                      <div class="descTitle">规格</div>
                      <div class="descContent">{{ good.packingSpecification }}</div>
                    </div>
                    <div class="descTag">
                      <div class="descTitle">进价</div>
                      <div class="descContentTag">
                        <el-tag type="warning" class="inventoryTag">
                          ￥&nbsp;{{good.buyPackageLevel===1?good.smallGoodsPrice:good.bigGoodsPrice}}
                        </el-tag>
                      </div>

                    </div>
                    <div class="descTag">
                      <div class="descTitle">库存</div>
                      <div class="descContentTag">
                        <el-tag :type="good.inventory <= 5 ? 'danger':'primary'" class="inventoryTag"
                                id="status">{{good.inventory}}&nbsp;{{good.middleUnitName}}
                        </el-tag>
                      </div>
                    </div>
                    <div class="desc">
                      <div class="descTitle">商品名</div>
                      <div class="descContent">{{ good.goodsName }}</div>
                    </div>

                    <div class="bottom clearfix">
                      <el-button v-if="!good.added " class="addCart" type="danger" icon="el-icon-shopping-bag-2"
                                 @click="addGood(good)">
                        添加购物车
                      </el-button>
                      <el-button v-if="good.added" disabled class="addCart" type="danger" icon="el-icon-s-goods">
                        商品已添加
                      </el-button>
                      <!--<el-button v-if="good.inventory<1" class="addCart" type="warning" icon="el-icon-sell">
                        去进货
                      </el-button>-->
                    </div>
                  </div>
                </el-card>
              </el-col>
            </div>

            <page-helper class="pageChooseStock" ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch" @rePage="rePage"></page-helper>
          </div>
        </el-col>
      </el-row>
    </div>


    <div class="dialogs">
      <dialog-supplier-choose ref="dialogSupplierChoose" @getSuppliers="getSuppliers"></dialog-supplier-choose>
      <dialog-stock-order ref="dialogStockOrder" @addStockCart="addStockCart" @editStockCart="editStockCart"
                          @cartChange="cartChange"></dialog-stock-order>
      <dialog-stock-order-sure ref="dialogStockOrderSure" @saveFinish="saveFinish"></dialog-stock-order-sure>
    </div>
  </div>
</template>

<script>
  import DialogSupplierChoose from '../../../components/DialogSupplierChoose'
  import PageHelper from '../../../components/PageHelper'
  import DialogStockOrder from '../../../components/DialogStockOrder'
  import DialogStockOrderSure from '../../../components/DialogStockOrderSure'

  export default {
    name: 'StockOrder',
    components: {
      DialogSupplierChoose,
      DialogStockOrder,
      DialogStockOrderSure,
      PageHelper
    },
    data () {
      return {
        message: '进货开单页面',
        //type=1,进货，=2新增商品
        type: 0,
        //供应商
        supplierId: '',
        inputVisible: false,
        //弹框
        centerDialogVisible: false,
        sureDialogVisible: false,
        //购物车
        goodCart: {},
        goodsCart: {
          accountsPayable: 0,
          paymentAmount: 0,
          num: 0,
          details: [],
          supplierId: [],
          supplierName: [],
        },
        good: {},
        goods: [],
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        showClearBtn: false,
        checkedStop: false,//默认不显示停用商品
        base_url: '',
        //分类
        //级联选择
        categorys: [
          {
            'categoryId': 0,
            'categoryName': '全部',
            'categotyOrder': 0,
            'children': [
              {
                'categoryId': 0,
                'categoryName': '全部分类',
                'categotyOrder': 0,
                'children': [],
                'parentCategoryId': 0
              }
            ],
            'parentCategoryId': 0
          }
        ],
        category: {},
        categoryId: '',
        //搜索
        searchInput: '',
        //扫码传参
        qrcode: '',
        timer: -1,
        //分页切换
        currentPage: 1,
        remarkFlag: false,
      }
    },
    mounted () {
      //设置scanCode元素获得焦点
      document.getElementById('scanCode').focus();
      let flag = Tool.getAuthorityRouter(this.$route.path.substr(1));
      if (!flag) {
        this.$emit('noAuth');
      } else {
        this.getCategorys();
      }
      //应付金额
      this.goodsCart.accountsPayable = 0;
      //实付金额
      this.goodsCart.paymentAmount = 0;
      if (Tool.isNotEmpty(SessionStorage.get(STOCK_CART))) {
        this.goodsCart = SessionStorage.get(STOCK_CART);
        this.supplierIds = this.goodsCart.supplierId;
        this.supplierNames = this.goodsCart.supplierName;
        //console.log('获取缓存的goodsCart：', this.goodsCart)
      }

      if (Tool.isNotEmpty(this.$route.params)) {
        this.type = this.$route.params.type
        if (this.type === 4) {
          this.navChange()
          //console.log('进货传入商品：', this.$route.params.item)
          this.goodsCart = this.$route.params.item
          this.goodsCart = $.extend({}, this.goodsCart)
          this.supplierIds = this.goodsCart.supplierId
          this.supplierNames = this.goodsCart.supplierName
          this.goodsCart.num = this.goodsCart.details.length
          this.getSearch(1)
        }
        if (this.type === 5) {
          this.navChange()
          //console.log('进货传入商品：', this.$route.params.item)
          let item=this.$route.params.item;
          item.smallGoodsPrice=0;
          item.bigGoodsPrice=0;
          console.log("item: ",item)
          this.goodsCart.details.push(item)
          this.goodsCart = $.extend({}, this.goodsCart)
          this.supplierIds = this.goodsCart.supplierId
          this.supplierNames = this.goodsCart.supplierName
          this.goodsCart.num = this.goodsCart.details.length
          for (let i = 0; i < this.goodsCart.details.length; i++) {
            this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
          }
          this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        }
        if (this.type === 6) {
          this.navChange()
          //console.log('type=6 ', this.type, '进货退货商品传入：', this.$route.params.item, '类型：', typeof this.$route.params.item, '当前购物车：', this.goodsCart)
          this.goodsCart.details.push(this.$route.params.item)
          this.goodsCart = $.extend({}, this.goodsCart)
          this.supplierIds = this.goodsCart.supplierId
          this.supplierNames = this.goodsCart.supplierName
          this.goodsCart.num = this.goodsCart.details.length
          for (let i = 0; i < this.goodsCart.details.length; i++) {
            this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
          }
          this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        }
        if (this.type === 7) {
          let item = this.$route.params.item
          if (Tool.isEmpty(item.supplierName)) {
            item.supplierName = item.supplierPerson
          }

          this.goodsCart = $.extend({}, this.goodsCart)
          this.goodsCart.supplierId = []
          this.goodsCart.supplierId.push(item.supplierId)
          this.goodsCart.supplierName = []
          this.goodsCart.supplierName.push(item.supplierName)
          this.supplierIds = this.goodsCart.supplierId
          this.supplierNames = this.goodsCart.supplierName
          this.updateCat()
        }
      }
      if (this.goodsCart.supplierName === undefined || this.goodsCart.supplierName.length < 1) {
        this.showChooseDialog()
      } else {
        this.getSearch(1)
      }
      let currentWidth = $(window).width()
      this.changeWidth(currentWidth)

      this.navChange()
      this.checkSuppliers()
      this.base_url=this.BASE_URL;
      //this.base_url = 'https://jxccs.weituodata.com:81'
      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
    },
    methods: {
      handleClick (event) {
        document.getElementById('scanCode').focus()
      },
      qrCodeInput (e) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.qrcode.indexOf('&') !== -1) {
            this.qrcode = this.qrcode.replace(new RegExp('&', 'g'), '%26')
          }
          Loadings.show()
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/decodeQrCode?qrcode=' + this.qrcode)
            .then((response) => {
              Loadings.hide()
              let resp = response.data.data
              let barCodeGood = resp
              barCodeGood.barcode = this.qrcode
              this.getCodeGood(barCodeGood)
            })

        }, 50)
      },
      paymentAmountChange(paymentAmount){
        if(Tool.isEmpty(paymentAmount)){
          this.goodsCart.paymentAmount=0
        }
      },
      getCodeGood (barCodeGood) {
        let _this = this
        let current = 1
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let size = 10
        let showStop = 1
        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        Loadings.show()
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectGoodsPageBySupplier?barCode='
          + barCodeGood.barcode + '&size=' + size + '&status=' + showStop + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&current=' + current + '&supplierId=' + this.supplierIds.toString())
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            let _this = this
            if (resp.data.records.length < 1) {
              if(this.getAuthorityMenu('200102') || this.getAuthorityMenu('500101')){
                this.$toast.showCodeConfirm('供应商无此商品', barCodeGood.goodsName, '是否去新增', '去新增', function () {
                    let type = 4
                    _this.$router.push({
                      name: '/goodsManagerEdit',
                      params: {
                        type,
                        item: barCodeGood,
                        supplierNames: _this.goodsCart.supplierName,
                        supplierIds: _this.goodsCart.supplierId,
                      }
                    })
                  }
                )
              }else{
                this.$toast.showAuth("当前登录用户没有访问权限，请重新登录！","center")
              }


            } else if (resp.data.records[0].belongToSupplier && resp.data.records[0].status === 0) {

              for (let i = 0; i < this.goods.length; i++) {
                if (resp.data.records[0].goodsId === this.goods[i].goodsId) {
                  if (!this.goods[i].added) {
                    //商品存在修改属性
                    this.goods[i].added = true
                    if (this.goods[i].buyPackageLevel === 1) {
                      this.goods[i].goodsPrice = this.goods[i].smallGoodsPrice
                    } else if (this.goods[i].buyPackageLevel === 2) {
                      this.goods[i].goodsPrice = this.goods[i].bigGoodsPrice
                    }
                    this.goods[i].totalPrice = parseFloat(this.goods[i].goodsPrice) * this.goods[i].goodsNumber
                    if (this.goodsCart.num < 1) {
                      this.goodsCart.details.push(this.goods[i])
                    } else {
                      let pushCartFlag = false
                      for (let i = 0; i < this.goodsCart.details.length; i++) {
                        this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
                        if (this.goodsCart.details[i].goodsId !== resp.goodsId) {
                          pushCartFlag = true
                        }
                      }
                      this.goodsCart.details.push(this.goods[i])
                    }
                  }
                }
              }
              this.updateCat()
            } else if (resp.data.records[0].belongToSupplier && resp.data.records[0].status === 1) {
              //console.log('扫码获取后台商品 belongToSupplier=true : ', resp.data.records[0])
              if(this.getAuthorityMenu('500103')){
                this.$toast.showCodeConfirm('该商品已下架：', barCodeGood.goodsName, '是否去上架', '去上架', function () {
                    let relevanceGood = resp.data.records[0]
                    _this.goOpenStock(relevanceGood.goodsId)
                  }
                )
              }else{
                this.$toast.showAuth("当前登录用户没有访问权限，请重新登录！","center")
              }
            } else if (!resp.data.records[0].belongToSupplier && resp.data.records[0].status === 0) {
              //console.log('扫码获取后台商品 !belongToSupplier=false111 : ', resp.data.records[0])
              //不属于当前供应商
              this.$toast.showCodeConfirm('此商品不属于当前供应商', barCodeGood.goodsName, '是否去关联当前供应商', '去关联', function () {
                  let relevanceGood = resp.data.records[0]
                  _this.modifySupplier(relevanceGood)
                }
              )
            } else if (!resp.data.records[0].belongToSupplier && resp.data.records[0].status === 1) {
              //console.log('扫码获取后台商品 !belongToSupplier=false222 : ', resp.data.records[0])
              //不属于当前供应商
              if(this.getAuthorityMenu('500103')){
                this.$toast.showCodeConfirm('此商品已下架，且不属于当前供应商', barCodeGood.goodsName, '是否去上架，关联当前供应商', '去关联并上架', function () {
                    let relevanceGood = resp.data.records[0]
                    _this.modifySupplier(relevanceGood)
                  }
                )
              }else{
                this.$toast.showAuth("当前登录用户没有访问权限，请重新登录！","center")
              }
            }
            this.qrcode = ''
          })
      },
      //上架
      goOpenStock (goodsId) {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/upShelves?goodsIds=' + goodsId + '&supplierId=' + this.goodsCart.supplierId.toString())
          .then((response) => {
            Loadings.hide()
            let resp = response.data.data
            //商品存在修改属性
            resp.added = true
            if (resp.buyPackageLevel === 1) {
              resp.goodsPrice = resp.smallGoodsPrice
            } else if (resp.buyPackageLevel === 2) {
              resp.goodsPrice = resp.bigGoodsPrice
            }
            resp.totalPrice = parseFloat(resp.goodsPrice) * resp.goodsNumber
            let pushCartFlag = false
            if (this.goodsCart.details.length < 1) {
              this.goodsCart.details.push(resp)
            } else {
              for (let i = 0; i < this.goodsCart.details.length; i++) {
                this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
                if (this.goodsCart.details[i].goodsId !== resp.goodsId) {
                  pushCartFlag = true
                }
              }
              this.goodsCart.details.push(resp)
            }
            this.getSearch(1);
            this.updateCat()
          })
      },
      //更换供应商
      modifySupplier (good) {
        this.$axios.post(process.env.VUE_APP_SERVER + '/psi/suppliergoosrel/save', {
          goodsId: good.goodsId,
          supplierId: this.goodsCart.supplierId.toString()
        })
          .then((response) => {
            Loadings.hide()
            let resp = response.data.data
            if(resp.status===1){
              this.goOpenStock(resp.goodsId)
            }else{
              resp.belongToSupplier = true
              if (resp.buyPackageLevel === 1) {
                resp.goodsPrice = resp.smallGoodsPrice
              } else if (resp.buyPackageLevel === 2) {
                resp.goodsPrice = resp.bigGoodsPrice
              }
              resp.totalPrice = parseFloat(resp.goodsPrice) * resp.goodsNumber
              let pushCartFlag = false
              if (this.goodsCart.details.length < 1) {
                this.goodsCart.details.push(resp)
                this.goodsCart.num=1
              } else {
                for (let i = 0; i < this.goodsCart.details.length; i++) {
                  this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
                  if (this.goodsCart.details[i].goodsId !== resp.goodsId) {
                    pushCartFlag = true
                  }
                }
                this.goodsCart.details.push(resp)
                this.goodsCart.num+=1
              }
            }

          })
        console.log("")
        this.updateCat()
      },
      checkSuppliers(){
        let _this=this;
        for(let i=0;i<this.goodsCart.details.length;i++){
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/detail?goodsId=' + this.goodsCart.details[i].goodsId)
            .then((response) => {
              Loadings.hide()
              let resp = response.data.data.supplierIds
              if(resp.indexOf(this.goodsCart.supplierId[0])===-1){
                let returnGoods=this.goodsCart.details[i];
                _this.goodsCart.details.splice(i,1)
                this.$toast.showCodeConfirm('此商品不属于当前供应商', returnGoods.goodsName, '是否去关联当前供应商', '去关联', function () {
                  let relevanceGood = response.data.data;
                    _this.modifySupplier(relevanceGood)
                  }
                )
              }
            })

        }
      },
      //更新购物车更新缓存
      updateCat () {
        this.goodsCart.accountsPayable = 0
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
        }
        this.goodsCart.num = this.goodsCart.details.length
        this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        SessionStorage.set(STOCK_CART, this.goodsCart)
        this.getSearch(1)
      },
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //获取所有分类
      getCategorys () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/category/selectAll')
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            resp.data.map(item=>{
              if(item.categoryName!=='其他'){
                item.children.unshift({categoryId:item.categoryId,categoryName:'全部'+item.categoryName})
              }
            })
            for (let i = 0; i < resp.data.length; i++) {
              this.categorys.push(resp.data[i])
            }
            for (let i = 0; i < this.categorys.length; i++) {
              for (let j = 0; j < this.categorys[i].children.length; j++) {
                if (this.categorys[i].children[j].children) {
                  delete this.categorys[i].children[j].children
                }
              }
            }
          })
      },
      //选中分类搜索
      categoryChange () {
        let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0]
        this.categoryId = checkedNodes.value
        this.getSearch(1)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      rePage (e) {
        this.currentPage = e
        //console.log("子组件传入当前分页：",e,"currentPage: ",this.currentPage);
      },
      getSearch: function (page, pageChange) {
        Loadings.show()
        let current = page
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let resize = 12
        let size = resize
        let keywords = this.searchInput
        let showStop = 0
        if (this.checkedStop === false) {
          showStop = 0
        } else {
          showStop = 1
        }
        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/selectGoodsPageBySupplier?current='
          + current + '&size=' + size + '&status=' + showStop + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput + '&barCode=' + this.qrcode + '&supplierId=' + this.supplierIds.toString())
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            //console.log('resp商品: ', resp)
            this.goods = resp.data.records

            for (let i = 0; i < this.goodsCart.details.length; i++) {
              for (let j = 0; j < this.goods.length; j++) {
                if (this.goodsCart.details[i].goodsId === this.goods[j].goodsId) {
                  this.goods[j].added = true
                }
              }
            }
            this.$refs.pageHelper.render(page, resp.data.total, resize)
          })
      },
      reSearch (data) {
        this.getSearch(1, data)
      },

      //添加购物车
      addGood (good) {
        let flag = false
        good.added = false
        good.goodsNumber = 1
        if (good.buyPackageLevel === 2) {
          good.goodsPrice = parseFloat(good.bigGoodsPrice)
        }
        if (good.buyPackageLevel === 1) {
          good.goodsPrice = parseFloat(good.smallGoodsPrice)
        }
        good.totalPrice = parseFloat(good.goodsNumber * good.goodsPrice).toFixed(2)
        if (this.goodsCart.supplierName === undefined || this.goodsCart.supplierName.length < 1) {
          this.showChooseDialog()
        } else {
          this.$nextTick(() => {
            this.$refs.dialogStockOrder.openStockGood(good, this.supplierNames, flag)
          })
        }

        /*this.$router.push({
          name: "/goodsManagerEdit",
          params: {type}
        });*/
      },
      addStockCart (good) {
        good.added = true
        let _this = this
        _this.goodsCart.details.push(good)
        _this.goodsCart.num = _this.goodsCart.details.length
        _this.goodsCart.accountsPayable = 0
        for (let i = 0; i < _this.goodsCart.details.length; i++) {
          _this.goodsCart.details[i].totalPrice = parseFloat(_this.goodsCart.details[i].totalPrice)
          _this.goodsCart.accountsPayable += _this.goodsCart.details[i].totalPrice
        }
        _this.goodsCart.paymentAmount = _this.goodsCart.accountsPayable
        SessionStorage.set(STOCK_CART, _this.goodsCart)
        this.getSearch(this.currentPage)
      },
      editStockCart (good) {
        this.goodsCart.accountsPayable = 0
        for (let i = 0; i < this.goodsCart.details.length; i++) {
          if (good.goodsId === this.goodsCart.details[i].goodsId) {
            this.goodsCart.details.splice(i, 1, good)
            //this.$forceUpdate();
          }
          this.goodsCart.details[i].totalPrice = parseFloat(this.goodsCart.details[i].totalPrice)
          this.goodsCart.accountsPayable += this.goodsCart.details[i].totalPrice
        }
        this.goodsCart.paymentAmount = this.goodsCart.accountsPayable
        SessionStorage.set(STOCK_CART, this.goodsCart)
      },
      //选择供应商
      handleClose (tag) {
        this.supplierIds.splice(this.supplierIds.indexOf(tag), 1)
      },
      showChooseDialog () {
        //this.inputVisible = true;
        this.$nextTick(() => {
          let supplierIds = this.supplierIds
          let type = 2
          this.$refs.dialogSupplierChoose.getSupplierIds(supplierIds, type)
        })
      },
      getSuppliers (supplierIds, supplierNames) {
        this.supplierNames = supplierNames
        this.supplierIds = supplierIds
        this.goodsCart.supplierId = this.supplierIds
        this.goodsCart.supplierName = this.supplierNames
        this.goodsCart.details = []
        this.goodsCart.num = 0
        this.$forceUpdate()
        SessionStorage.set(STOCK_CART, this.goodsCart)
        this.getSearch(1)
      },
      //购物车
      editCart (item) {
        let flag = true
        item.saleSmallPrice = item.smallGoodsPrice
        item.saleBigPrice = item.bigGoodsPrice
        this.$nextTick(() => {
          this.$refs.dialogStockOrder.openStockGood(item, this.goodsCart.supplierName, flag)
        })
      },

      cartChange (item) {
        //console.log('购物车商品更新的good：', item)
      },
      saveFinish (flag) {
        this.goodsCart.details = []
        this.goodsCart.num = this.goodsCart.details.length
        this.goodsCart.supplierName = []
        this.goodsCart.supplierId = []
        this.goodsCart.paymentAmount = 0.00
        this.goodsCart.accountsPayable = 0.00
        this.getSearch(1)
        this.showChooseDialog()
      },
      delCart (item) {
        let _this = this
        this.$toast.showConfirm(item.goodsName, function () {
            for (let i = 0; i < _this.goodsCart.details.length; i++) {
              if (_this.goodsCart.details[i].goodsId === item.goodsId) {
                _this.goodsCart.details.splice(i, 1)
                _this.goodsCart.num = _this.goodsCart.details.length
                _this.goodsCart.accountsPayable = 0
                for (let i = 0; i < _this.goodsCart.details.length; i++) {
                  _this.goodsCart.accountsPayable += parseInt(_this.goodsCart.details[i].totalPrice)
                }
                _this.goodsCart.paymentAmount = _this.goodsCart.accountsPayable
                SessionStorage.set(STOCK_CART, _this.goodsCart)
              }
            }
            for (let j = 0; j < _this.goods.length; j++) {
              if (item.goodsId === _this.goods[j].goodsId) {
                _this.goods[j].added = false
              }
            }
          }
        )

      },
      clearCart () {
        let _this = this
        this.$toast.showConfirm('清空购物车商品', function () {
            _this.goodsCart.details = []
            _this.goodsCart.num = _this.goodsCart.details.length
            _this.goodsCart.paymentAmount = 0.00
            _this.goodsCart.accountsPayable = 0.00
            SessionStorage.remove(STOCK_CART)
            SessionStorage.set(STOCK_CART, _this.goodsCart)
            _this.getSearch(1)
          }
        )
      },
      saveCart () {
        this.goodsCart.paymentAmount=parseFloat(this.goodsCart.paymentAmount).toFixed(2)
        if (this.goodsCart.supplierName === undefined || this.goodsCart.supplierName.length < 1) {
          this.showChooseDialog()
        } else if (this.goodsCart.num < 1) {
          this.$message({
            message: '您还没有选择任何商品哦~',
            center: true,
            type: 'warning',
            offset: 150
          })
        } else {
          let type = 2
          this.$nextTick(() => {
            this.$refs.dialogStockOrderSure.sureOrders(this.goodsCart, type)
          })
        }
      },
      toNewGood () {
        let type = 4
        if (this.goodsCart.supplierName === undefined || this.goodsCart.supplierName.length < 1) {
          this.showChooseDialog()
        } else {
          this.$router.push({
            name: '/goodsManagerEdit',
            params: {
              type,
              supplierNames: this.goodsCart.supplierName,
              supplierIds: this.goodsCart.supplierId,
            }
          })
        }
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
      //视口
      changeWidth (width) {
        let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
        let clearCart = document.getElementsByClassName('clearCart')[0]
        let cardImage = document.getElementsByClassName('cardImage')
        if (width >= 1619) {
          inputCoreDesc.style.width = '400px'
        }
        if (this.showClearBtn) {
          if (width > 1669) {
            clearCart.style.marginRight = '-100px'
          }
          if (width <= 1669) {
            clearCart.style.marginRight = '0px'
            for (let i = 0; i < cardImage.length; i++) {
              cardImage[i].style.maxHeight = 'unset !important'
              cardImage[i].style.height = '145px !important'
            }
          }
          if (width < 1640) {
            clearCart.style.marginRight = '50px'
          }
          if (width < 1523) {
            clearCart.style.marginRight = '100px'
            for (let i = 0; i < cardImage.length; i++) {
              cardImage[i].style.maxHeight = 'unset !important'
              cardImage[i].style.height = '145px !important'
            }
          }
          if (width < 1370) {
            clearCart.style.marginRight = '160px'
          }

        }

        if (width < 1619) {
          inputCoreDesc.style.width = '280px'
        }
        if (width < 1310) {
          inputCoreDesc.style.width = '250px'
        }
        if (width < 1240) {
          inputCoreDesc.style.width = '200px'
        }
        this.triggerResize()
      },
      triggerResize () {
        $(window).on('resize', function () {
          let width = $(window).width()
          let inputCoreDesc = document.getElementsByClassName('inputCoreDesc')[0]
          let clearCart = document.getElementsByClassName('clearCart')[0]
          let cardImage = document.getElementsByClassName('cardImage')
          if (width >= 1619) {
            inputCoreDesc.style.width = '400px'
          }

          if (this.showClearBtn) {
            if (width > 1669) {
              clearCart.style.marginRight = '-100px'
            }
            if (width <= 1669) {
              clearCart.style.marginRight = '0px'
              for (let i = 0; i < cardImage.length; i++) {
                cardImage[i].style.maxHeight = 'unset !important'
                cardImage[i].style.height = '145px !important'
              }
            }
            if (width < 1640) {
              clearCart.style.marginRight = '50px'
            }
            if (width < 1523) {
              clearCart.style.marginRight = '100px'
              for (let i = 0; i < cardImage.length; i++) {
                cardImage[i].style.maxHeight = 'unset !important'
                cardImage[i].style.height = '145px !important'
              }
            }
            if (width < 1370) {
              clearCart.style.marginRight = '160px'
            }
          }

          if (width < 1619) {
            inputCoreDesc.style.width = '280px'
          }
          if (width < 1310) {
            inputCoreDesc.style.width = '250px'
          }
          if (width < 1240) {
            inputCoreDesc.style.width = '200px'
          }
        }).trigger('resize')
      },
    }
  }
</script>

<style scoped>
  .mainBody {
    padding: 10px;
  }

  .rowForm {
    margin-bottom: 0px;
  }

  .leftBody, .rightBody {
    background: #fafafa;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: dashed 1px #faecd8;
    min-height: 840px;
  }

  .topNav {
    float: left;
    margin-left: 10px;
    max-width: 500px;
  }

  /*供应商*/
  .keyTags {
    float: left;
    margin-right: 8px;
    margin-bottom: 10px;
    line-height: 40px;
    height: 40px;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
  }

  .keyTagsInput {
    float: left;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  .clearCart {
    float: right;
    right: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  /*购物车*/
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  .pay {
    display: inline-block;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
  }

  .pay .payLabel {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }

  .priceInput {
    float: left;
  }

  /*备注*/
  .pay .remarkLabel {
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;

  }

  .inputCoreDesc {
    float: left;
    width: 400px;
    margin-left: 28px;
  }

  .bottomBtn {
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 180px;
    margin-right: 10px;
  }

  .resetBtn {
    width: 180px;
  }

  .tipTag {
    position: relative;
  }

  /*右侧页面*/
  .categoryInput {
    float: left;
    width: 180px;
    margin-right: 10px;
  }

  .searchInput {
    width: 200px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 0;
    float: left;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*商品列表*/
  .el-col-4 {
    width: 16.4%;
  }

  .mainCard {
    margin-left: 5px;
    margin-top: 10px;
    min-height: 670px;
  }

  /deep/ .el-card {

  }

  /deep/ .el-card__body {
    padding: 5px;
    left: 0;
  }

  .goodCart {
    max-width: 230px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .cardImage {
    /*width: 150px;
    height: 150px;*/
    /*max-height: 145px;*/
    height: 145px;
    border-radius: 10px;
  }

  .desc .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .desc .descContent {
    line-height: 30px;
    font-size: 12px;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .descTag .descContentTag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descContentTag {
    padding-bottom: 8px;
  }

  .descTag .descContentTag:nth-of-type(2) {
    padding-top: 0;
  }

  .descTag .descContentTag /deep/ .el-tag {
    line-height: 25px;
    height: 25px;

  }

  .addCart {
    padding: 6px 12px;
    margin-bottom: 5px;
  }

  /*已选*/
  .addGood {
    border: 1px solid #F56C6C;
    position: relative;
  }

  .checkedbox {
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: #F56C6C;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }

  .inventoryTag {
    padding: 0 20px;
  }

  .scanCode {
    color: #fff;
    border: 0px solid #fff;
    position: absolute;
    outline: none;
    width: 300px;
    opacity: 0;
  }
  .pageChooseStock{
    margin-top: -10px !important;
    margin-bottom: -20px !important;
  }
  .supplierGoodsToast{
    margin-top: 12px;
    margin-bottom: 14px;
    color: #E6A23C;/*#c5c9d0*/
  }
  .toastIcon{
    margin-right: 5px;
  }
  .imgBox{
    position: relative;
  }
  .innerImg{
    position: absolute;
    left: 2px;
    bottom: 0;
    width: 97.5%;
  }
</style>
