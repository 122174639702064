<template>
  <div class="order" :id="orderId" >
    <div class="orderNav">
      <div class="navLeft">
        <div class="orderTime">开单时间：{{order.createTime}}</div>
        <!--<div class="orderNum">购买商品：{{order.buyOrderNo}}</div>-->
      </div>
      <div class="navRight">
        <el-button class="cancelOrder" type="danger" @click="cancelOrder" v-if="order.status===0 && (getAuthorityMenu('400201') || getAuthorityMenu('400401'))">撤销订单</el-button>
        <!--<el-button class="printOrder" type="warning" icon="el-icon-s-order">打印单据</el-button>-->
        <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="print" v-if="order.status === 0">打印小票</el-button>
        <el-button class="printOrder" type="warning" icon="el-icon-s-order" @click="printBig" v-if="order.status === 0">打印大票</el-button>
      </div>
    </div>
    <div class="orderTable">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="orderDetail"
        border
        highlight-current-row
        class="previous-row orderTables"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50" fixed></el-table-column>
        <el-table-column property="bigPackageName" label="包装分类" width="120" fixed></el-table-column>
        <el-table-column property="bigPackageId" label="包装规格">
          <template slot-scope="scope">
            <el-tag type="info" class="packageUnitDesc"
                    v-if="scope.row.bigPackageId===1 || scope.row.bigPackageId===2">
              {{scope.row.bigPackageId===1?'农药：':scope.row.bigPackageId===2?'肥料：':scope.row.bigPackageId===3?'农膜：':'其他：'}}
              {{scope.row.vesselPackageName}}+
              {{scope.row.texturePackageName}}+
              {{scope.row.sizePackageName}}
            </el-tag>
            <el-tag type="info" class="packageUnitDesc" v-if="scope.row.bigPackageId===3">
              {{scope.row.bigPackageId===3?'农膜：':''}}
              {{scope.row.vesselPackageName}}
            </el-tag>
            <el-tag type="info" class="packageUnitDesc" v-if="scope.row.bigPackageId===4">
              {{scope.row.bigPackageId===4?'其他：':''}}
              无
            </el-tag>

          </template>
        </el-table-column>
        <el-table-column v-if="type!==3" property="recoveryPrice" label="单价" width="90" sortable>
          <template slot-scope="scope">
            <el-tag type="warning">
              {{scope.row.recoveryPrice}}元 /{{scope.row.pricePackageUnitName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type===3" property="recoveryPrice" label="单价" width="90" sortable>
          <template slot-scope="scope">
            <el-tag type="warning">
              {{scope.row.disposePrice}}元 /{{scope.row.pricePackageUnitName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type!==3" property="recoveryNumber" label="数量" width="80" sortable>
          <template slot-scope="scope">
            <el-tag type="warning">
              {{scope.row.recoveryNumber}} {{scope.row.numberPackageUnitName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type===3" property="recoveryNumber" label="数量" width="80" sortable>
          <template slot-scope="scope">
            <el-tag type="warning">
              {{scope.row.disposeNumber}} {{scope.row.numberPackageUnitName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type!==3" property="recoveryWeight" label="重量" width="80" :show-overflow-tooltip="true" sortable>
          <template slot-scope="scope">
            <el-tag type="info">
              {{scope.row.recoveryWeight}} {{scope.row.weightPackageUnitName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type===3" property="recoveryWeight" label="重量" width="80" :show-overflow-tooltip="true" sortable>
          <template slot-scope="scope">
            <el-tag type="info">
              {{scope.row.disposeWeight}} {{scope.row.weightPackageUnitName}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type!==3" property="packageLevel" label="支付金额" width="110" :show-overflow-tooltip="true" sortable>
          <template slot-scope="scope">
            <el-tag type="danger" class="showCenter">
              ￥ {{scope.row.recoveryMoney}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="type===3" property="packageLevel" label="支付金额" width="110" :show-overflow-tooltip="true" sortable>
          <template slot-scope="scope">
            <el-tag type="danger" class="showCenter">
              ￥ {{scope.row.disposeMoney}}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="sum">
      <div ><div class="sumTitle">回收支付总金额：</div><span class="sumMoney">{{type!==3?order.recoveryMoney:order.disposeMoney}} 元</span></div>
    </div>
    <div class="horizontalLine"></div>
    <div class="remark">
      <div>
        <p>备注：{{order.remark}}</p>
      </div>
    </div>

    <!-- 打印大票 -->
    <iframe style="display: none;" :srcdoc="tmpHtml" id="ifr"></iframe>

  </div>
</template>

<script>
  import { getLodop } from '../../public/static/js/utils/LodopFuncs'

  export default {
    //name: "订单详情",
    //向外暴露属性
    props:{
      orderId:{
        default:"order-Id"
      },
      clientName:{
        default:'clientName'
      }
    },
    data:function () {
      return{
        //播放器实例
        order:{},
        orderDetail:[],
        UNIT_TYPE:UNIT_TYPE,
        VESSEL_TYPE:VESSEL_TYPE,
        type:1,
        tmpHtml:'',
        clientInfo: {}, //供应商
        tenantInfo: LocalStorage.get(TENANT_INFO) || {}, //店铺信息
      }
    },
    methods:{
      getDetailById(orderId,clientName,type){
        this.tenantInfo = LocalStorage.get(TENANT_INFO) || {};
        console.log("type-----",type,'type==3为处置单据',"clientName: ",clientName)
        Loadings.show();
        if(type===3){
          this.type=3;
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastedisposeorder/detail?disposeOrderId=' +orderId)
            .then((response) => {
              Loadings.hide()
              let resp = response.data
              this.clientInfo={
                name:resp.data.disposeUnitName?resp.data.disposeUnitName:'',
                phone:resp.data.disposeUnitMobile?resp.data.disposeUnitMobile:''
              }
              this.order.disposeMoney=resp.data.disposeMoney;
              this.order.buyOrderNo=orderId;
              this.order.createTime=resp.data.createTime;
              this.order.status=resp.data.status;
              this.order.remark=resp.data.remark;
              this.orderDetail=resp.data.disposeDetailList;
              this.printHtml()
            })
        }else{
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/detail?recoveryOrderId=' +orderId)
            .then((response) => {
              Loadings.hide()
              let resp = response.data
              this.clientInfo={
                name:resp.data.clientName?resp.data.clientName:(resp.data.clientPerson?resp.data.clientPerson:''),
                phone:resp.data.clientPhone?resp.data.clientPhone:''
              }
              this.order.recoveryMoney=resp.data.recoveryMoney;
              this.order.buyOrderNo=orderId;
              this.order.createTime=resp.data.createTime;
              this.order.status=resp.data.status;
              this.order.remark=resp.data.remark;
              this.orderDetail=resp.data.wasteRecoveryDetailList;
              this.printHtml()
            })
        }

      },
      cancelOrder(){
        let flag=true;
        this.$emit('orderCancel',flag);
        /*let _this = this
        toast.showConfirm('撤销当前订单：' + item.goodsName, function () {
          Loadings.show()
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/revoke?recoveryOrderId=' + item.recoveryOrderId)
            .then((response) => {
                Loadings.hide()
                let resp = response.data
                let orders = resp.data
                _this.getSearch(1)
                console.log('响应列表：', resp)
              }
            )
        })*/
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },

      printHtml(){
        this.tmpHtml = `<div style="text-align: center;">` +
          `<span style="font-size: 18px;">${this.tenantInfo.tenantName ? this.tenantInfo.tenantName + (this.type==3?'处置单据':'回收单据') : ''}</span>` +
          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:10px; margin-bottom:10px;">` +
          `<div style= "text-align: left;">` +
          /*`<div>供应商：${this.supplier.supplierName}</div>` +*/
          `<div>${this.type==3?'处置单位':'客户'}：${this.clientInfo.name}</div>` +
          `<div>电话：${this.clientInfo.phone}</div>` +
          `</div>` +
          `<div style="text-align: left;">` +
          /*`<div>No：${this.order.buyOrderNo}</div>` +*/
          `<div>日期：${this.order.createTime}</div>` +
          `</div>` +
          `</div>` +
          `<table width="100%" cellspacing="0" border="1" style="margin: auto; text-align: center;">` +
          `<tr>` +
          `<td>包装分类</td>` +
          `<td>包装规格</td>` +
          `<td>${this.type==3?'单价':'单价'}</td>` +
          `<td>数量</td>` +
          `<td>重量</td>` +
          `<td>${this.type==3?'支付金额':'支付金额'}</td>` +
          `</tr>`;

        let sumTotalPrice = 0;
        for (let i = 0; i < this.orderDetail.length; i++) {
          let item = this.orderDetail[i];
          if (item.buyPackageLevel == 1) {
            item.unit = item.middleUnitName;
          } else if (item.buyPackageLevel == 2) {
            item.unit = item.bigUnitName;
          }
          let packageDesc=''
          if(item.bigPackageId===1 || item.bigPackageId===2){
            packageDesc=item.bigPackageId===1?'农药：':'肥料：'+item.vesselPackageName+item.texturePackageName+item.sizePackageName
          }else if(item.bigPackageId===3){
            packageDesc='农膜：'+item.vesselPackageName
          }else if(item.bigPackageId===4){
            packageDesc='其他：'+'无'
          }
          this.tmpHtml +=
            `<tr style="font-size: 12px;">` +
            `<td>${item.bigPackageName}</td>` +
            `<td>${packageDesc}</td>` +
            `<td>${(this.type==3?item.disposePrice:item.recoveryPrice) + '元/' + item.pricePackageUnitName}</td>` +
            `<td>${(this.type==3?item.disposeNumber:item.recoveryNumber) + item.numberPackageUnitName}</td>` +
            `<td>${(this.type==3?item.disposeWeight:item.recoveryWeight) + item.weightPackageUnitName}</td>` +
            `<td>${this.type==3?item.disposeMoney:item.recoveryMoney + '元' }</td>` +
            `</tr>`;

          if(this.type==3){
            sumTotalPrice += item.disposeMoney;
          }else{
            sumTotalPrice += item.recoveryMoney;
          }


          if (i == (this.orderDetail.length - 1)) {
            this.tmpHtml +=
              `<tr style="font-size: 12px;">` +
              `<td>合计：</td>` +
              `<td></td>` +
              `<td></td>` +
              `<td></td>` +
              `<td></td>` +
              `<td>￥${sumTotalPrice}</td>` +
              `</tr>`;
          }
        }

        this.tmpHtml += `</table>` +
          `<div style="width: 100%; display:flex; justify-content:space-between; margin-top:100px; margin-bottom:10px;">` +
          `<div style= "text-align: left; width:200px;">` +
          `<div>${this.type===3?'处置总金额':'回收总金额'}：￥${this.type==3?this.order.disposeMoney:this.order.recoveryMoney} 元</div>` +
          `<div>制单人：${this.tenantInfo.userName}</div>` +
          `</div>` +
          `<div style= "text-align: left;">` +
          `<div>单位：${this.tenantInfo.tenantName}</div>` +
          `</div>` +
          `<div style="text-align: left;">` +
          `<div>电话：${this.tenantInfo.account}</div>` +
          `<div>备注：${this.order.remark}</div>` +
          `</div>` +
          `</div>` +
          /*`<div>注意事项：所有农药产品，请按产品标签说明使用</div>`+*/
          `</div>`;
        // console.log("this.tmpHtml: ",this.tmpHtml)
      },

      //打印票据
      print() {

        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
          return;
        }

        let LODOP = getLodop();

        if (LODOP === 'Error:DriveNotInstalled') {
          //console.log('打印插件未安装！');
          this.$message({ type: 'error', message: '打印插件未安装！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        } else if (LODOP === 'Error:DriverNeedsToBeUpgraded') {
          //console.log('打印插件需升级！');
          this.$message({ type: 'error', message: '打印插件需升级！请点击右下角悬浮图标，进行下载安装。', duration: 2000, offset: 110 });
          return;
        }

        let topDistance = 5; //距离顶部距离
        LODOP.NEWPAGEA();
        LODOP.ADD_PRINT_TEXT(topDistance, 20, 580, 40, '*慧台账进销存*');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",15);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 40;
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '开单时间：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 1;
        LODOP.ADD_PRINT_TEXT(topDistance,55,125,40,this.order.createTime);

        /*topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 5, 580, 40, '订单编号：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",8);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");*/

        // topDistance += 1;
        // LODOP.ADD_PRINT_TEXT(topDistance,55,130,60,this.order.buyOrderNo);
        // topDistance += 40;
        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 460, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【'+(this.type==3?'处置单据':'回收单据')+'信息】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let height_supplierName = 0;
        if (this.clientInfo.name.length > 9) {
          height_supplierName += (Math.ceil(this.clientInfo.name.length / 9) - 1) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_supplierName,this.clientInfo.name);

        if (this.clientInfo.name.length > 9) {
          topDistance += Math.ceil(this.clientInfo.name.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系人：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let height_supplierPerson = 0;
        if (this.clientInfo.name.length > 9) {
          height_supplierPerson += Math.ceil(this.clientInfo.name.length / 9) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_supplierPerson,this.clientInfo.name);

        if (this.clientInfo.name.length > 9) {
          topDistance += Math.ceil(this.clientInfo.name.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,this.clientInfo.phone);

        topDistance += 30;

        LODOP.ADD_PRINT_TEXT(topDistance, 0, 370, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【订单详情】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        let sumTotalPrice = 0;
        this.orderDetail.map((item, index) => {
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品名称：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_goodsName = 0;

          let packageDesc=''
          if(item.bigPackageId===1 || item.bigPackageId===2){
            packageDesc=item.bigPackageId===1?'农药：':'肥料：'+item.vesselPackageName+item.texturePackageName+item.sizePackageName
          }else if(item.bigPackageId===3){
            packageDesc='农膜：'+item.vesselPackageName
          }else if(item.bigPackageId===4){
            packageDesc='其他：'+'无'
          }

          if (item.bigPackageName.length > 9) {
            height_goodsName += Math.ceil(item.bigPackageName.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_goodsName,item.bigPackageName);

          if (item.bigPackageName.length > 9) {
            topDistance += Math.ceil(item.bigPackageName.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'包装规格：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          let height_packingSpecification = 0;
          if (packageDesc.length > 9) {
            height_packingSpecification += Math.ceil(packageDesc.length / 9) * 17;
          }

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_packingSpecification,packageDesc);

          if (packageDesc.length > 9) {
            topDistance += Math.ceil(packageDesc.length / 9) * 17;
          } else {
            topDistance += 20;
          }

          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品单价'+'：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + (this.type==3?item.disposePrice:item.recoveryPrice));

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品数量：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,(this.type==3?item.disposeNumber:item.recoveryNumber) + item.numberPackageUnitName);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'商品重量：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,(this.type==3?item.disposeWeight:item.recoveryWeight) + item.weightPackageUnitName);

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance, 5, 172, 40, '————————');

          topDistance += 20;
          LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'小计：');
          LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
          LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

          topDistance += 3;
          LODOP.ADD_PRINT_TEXT(topDistance,65,120,45,'￥' + (this.type==3?item.disposeMoney:item.recoveryMoney + '元'));

          if(this.type==3){
            sumTotalPrice += item.disposeMoney;
          }else{
            sumTotalPrice += item.recoveryMoney;
          }

          if (index != this.orderDetail.length - 1) {
            topDistance += 20;
            LODOP.ADD_PRINT_TEXT(topDistance, 15, 172, 40, '*************************');
            topDistance += 20;
          }

        });

        topDistance += 25;
        LODOP.ADD_PRINT_TEXT(topDistance, 55, 172, 40, '=========================');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance,85,580,40,this.type==3?'总计':'总计'+'：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,120,120,45,'￥' + sumTotalPrice);

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 0, 360, 54, '---------------------------------------------');

        topDistance += 20;
        LODOP.ADD_PRINT_TEXT(topDistance, 42, 580, 40, '【店铺信息】');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",12);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 30;
        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'店铺名称：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        let tenantInfo = this.tenantInfo;

        let height_tenantName = 0;
        if (tenantInfo.tenantName.length > 9) {
          height_tenantName += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
        }

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,height_tenantName,tenantInfo.tenantName);

        if (tenantInfo.tenantName.length > 9) {
          topDistance += Math.ceil(tenantInfo.tenantName.length / 9) * 17;
        } else {
          topDistance += 20;
        }

        LODOP.ADD_PRINT_TEXT(topDistance,5,580,40,'联系方式：');
        LODOP.SET_PRINT_STYLEA(0,"FontSize",10);
        LODOP.SET_PRINT_STYLEA(0,"FontName","微软雅黑");

        topDistance += 3;
        LODOP.ADD_PRINT_TEXT(topDistance,65,120,40,tenantInfo.account);

        //设定打印页面大小
        LODOP.SET_PRINT_PAGESIZE(3,580,24,"客户往来明细单据打印");
        LODOP.PREVIEW();
      },
      //打印大票
      printBig() {
        //this.$refs.printBarcode.showDialog();
        /*
        //根据div标签ID拿到div中的局部内容
        let backHtml = window.document.body.innerHTML;
        //把获取的 局部div内容赋给body标签, 相当于重置了 body里的内容
        window.document.body.innerHTML= document.getElementById("printcontent").innerHTML;
        //调用打印功能
        window.print();
        window.document.body.innerHTML = backHtml;
        */

        /*
        let bdhtml=window.document.body.innerHTML;
        let sprnstr="<!--startprint-->"; //开始打印标识字符串有17个字符
        let eprnstr="<!--endprint-->"; //结束打印标识字符串
        let prnhtml=bdhtml.substr(bdhtml.indexOf(sprnstr)+17); //从开始打印标识之后的内容
        prnhtml=prnhtml.substring(0,prnhtml.indexOf(eprnstr)); //截取开始标识和结束标识之间的内容
        window.document.body.innerHTML=prnhtml; //把需要打印的指定内容赋给body.innerHTML
        window.print(); //调用浏览器的打印功能打印指定区域
        window.document.body.innerHTML=bdhtml;//重新给页面内容赋值
        */
        //this.pagesetup_null();

        if (!(this.tenantInfo && this.tenantInfo.printEnable == 'Y')) {
          this.$Swal.fire({
            icon: 'error',
            position: 'center',
            title: PRINT_MSG,
            confirmButtonText: `知道了`,
            confirmButtonColor: '#e92322',
          });

          setTimeout(function () {
            let sweet = document.getElementsByClassName('swal2-container')[0]
            sweet.style.zIndex = '3000'
          }, 100)
          return;
        }

        //打印
        document.getElementById('ifr').contentWindow.print();
      },

    }
  }
</script>

<style scoped>

  .orderNav{
    position: relative;
    width: 100%;
  }
  .navLeft{
  }
  .orderTime{
    float: left;
    margin-right: 10px;
  }
  .orderNum{
    float: left;

  }
  .navRight{

  }
  .printOrder{
    float: right;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .cancelOrder{
    float: right;
    margin-left: 10px;
    padding: 10px 15px;
  }
  /*订单表格*/
  .orderTable{
    margin-bottom: 10px;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 10px !important;
  }
  /*总计*/
  .sum{
    margin-bottom: 60px;
  }
  .sumTitle{
    float: left;

  }
  .sumMoney{
    float: left;
    margin-right: 10px;
  }
  .sumTitle{
    text-align: right;
    vertical-align: middle;
    float: left;
    font-weight: 700;
    font-size: 16px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .sumMoney{
    float: left;
    font-size: 18px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }
  .sumMoney:after{
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 6px;
  }
  .realMoney{
    float: left;
    margin-right: 10px;
  }
  .debtMoney{
    float: left;
  }
  .horizontalLine{
    width: 100%;
    height: 3px;
    border: 3px solid #e9eef3;
    border-radius: 20px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .remark{
    margin-bottom: 50px;
  }
  .remark div{
    text-align: left;
  }
</style>
