<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">用户缴费记录：
          <i class="el-icon-user-solid"></i>
          <span>{{user.name}}</span>
        </div>
      </div>

      <div class="dialogBody">

        <div class="mainForm">
          <el-table
            ref="multipleTable"
            tooltip-effect="dark"
            :data="userPayDetail"
            border
            height="540px"
            highlight-current-row
            class="previous-row"
            :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
          >
            <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>

            <el-table-column property="realName" label="用户姓名" width="100" :show-overflow-tooltip="true">
            </el-table-column>

            <el-table-column property="payAmount" label="缴费金额" width="100">
              <template slot-scope="scope">
                <el-tag type="danger" class="showCenter">
                  ￥ {{ scope.row.payAmount}}
                </el-tag>
              </template>
            </el-table-column>
            <!--<el-table-column property="useTerm" label="使用期限" width="100">
              <template slot-scope="scope">
                {{USE_STATUS | optionObjectKV(scope.row.useTerm)}}
              </template>
            </el-table-column>-->
            <el-table-column property="payTime" label="支付时间">
              <template slot-scope="scope">
                <el-tag type="primary" class="showCenter">
                  {{ scope.row.payTime}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column property="deadline" label="到期时间" >
              <template slot-scope="scope">
                <el-tag type="warning"
                        id="status">{{scope.row.deadline}}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'UserPwd',
    props: {},
    data () {
      return {
        message: '用户密码修改',
        user: {},
        userPayDetail: [],
        centerDialogVisible: false,
        USE_STATUS:USE_STATUS,
      }
    },
    mounted () {

    },
    methods: {
      dialogClose () {

      },
      openUserPayDetail (user) {
        let _this = this
        _this.centerDialogVisible = true
        _this.user = $.extend({}, _this.user)
        _this.user = user
        this.getDetail()
      },

      getDetail () {
        let _this = this
        Loadings.show()
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/psi/paylog/list' +
            '?tenantId=' + this.user.tenantId,
          method: 'GET'
        })
          .then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.userPayDetail=res.data.data
                for(let i=0;i<this.userPayDetail.length;i++){
                  this.userPayDetail[i].realName=this.user.realName
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })

      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 800px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }
  /deep/ .el-table--enable-row-transition .el-table__body td.el-table__cell{
    text-align: center;
  }
  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .title i {
    margin-left: 5px;
    margin-right: 5px;
    color: #f6e483;
  }

  .header-title .title span {
    display: block;
    float: right;
    margin-top: 19px;
    color: #f6e483;
    cursor: pointer;
    max-width: 400px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .remark div {
    text-align: left;
  }

</style>
