<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">联系我们</div>
      </div>

      <div class="contactBody">
        <div class="mainLogo">
          <el-image class="logo"
            :src="contact.url"
            :fit="fits[0]"></el-image>
          <div class="nameDesc">
            <span>河北威拓信息技术有限公司</span>
            <div class="bottomLine"></div>
            <div class="eng">WEI TUO INFORMATION TECHNOLOGY</div>
          </div>

        </div>
        <div class="contactItems">
          <div class="bodyItem">
            <div class="contactTitle"><li class="el-icon-phone"></li>座机号</div>
            <div class="contactDesc">{{contact.phone}}</div>
          </div>
          <div class="bodyItem">
            <div class="contactTitle"><li class="el-icon-mobile"></li>手机号</div>
            <div class="contactDesc"><span class="mobile">{{contact.mobile}}</span><span >（<img class="weixin" src="/static/imgs/my/weiixin_1.png">微信同步）</span></div>
          </div>
          <div class="bodyItem">
            <div class="contactTitle"><li class="showLi">QQ</li>号码</div>
            <div class="contactDesc">{{contact.qqNum}}</div>
          </div>
          <div class="bodyItem">
            <div class="contactTitle"><li class="el-icon-location"></li>总部地址</div>
            <div class="contactDesc">{{contact.address}}</div>
          </div>
        </div>
        <div id="wrap">
          <div class="cubic-box">
            <div style="color: rgba(20,117,181,.3)">1</div>
            <div style="color: #7ec3f1">0</div>
            <div>0</div>


          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MyContact',
    props: {},
    data () {
      return {
        message: '联系我们',
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        contact: {
          companyName:'河北威拓信息技术有限公司',
          url:'/static/imgs/weituo-logo2.png',
          phone:'03182111122',
          mobile:'19933197321',
          qqNum:'2404419627',
          address:'衡水市滨湖新区红旗大街2373号'
        },
        centerDialogVisible:false,
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openMyContact () {
        this.centerDialogVisible=true;
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }
  /*body*/
  .contactBody{
    overflow: hidden;
  }
  .mainLogo{
    position: relative;
    left: -120px;
    float: left;
    margin-left: 35%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .mainLogo /deep/ .el-image img{
    width: 90px;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 50%;
    position: relative;
  }
  .mainLogo /deep/ .el-image:after{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #4eabda;
    position: absolute;
    left: 10px;
    top: 8px;
  }
  .mainLogo .nameDesc{
    position: relative;
    min-width: 300px;
    top: -11px;
  }
  .mainLogo span{
    position: absolute;
    min-width: 300px;
    top: -90px;
    left: 180px;
    margin-left: 30px;
    font-size: 24px;
    font-weight: 500;
    line-height: 80px;
  }

  .mainLogo .eng{
    position: absolute;
    width: 100%;
    top: -25px;
    right: -210px;
    color: #1475b5;
  }
  .mainLogo .bottomLine{
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #4eabda;
    top: -30px;
    right: -210px;
  }

  .contactItems{
    display: block;
  }

  .bodyItem{
    margin-left: 20%;
    position: relative;
  }
  .contactTitle{
    font-size: 18px;
    width: 100px;
    line-height: 30px;
    text-align: left;
    font-weight: 700;
    color: #606266;
  }
  .contactTitle .showLi{
    list-style: none;
    float: left;
  }
  .contactTitle li{
    color: rgba(20,117,181);
  }
  .contactTitle li{
    margin-right: 10px;
  }
  .contactDesc{
    display: inline-block;
    min-width: 300px;
    float: left;
    margin-top: -40px;
    margin-left: 120px;
    line-height: 40px;
    font-size: 18px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid #dddddd;
    margin-bottom: 25px;
    padding: 5px 20px;
    cursor: pointer;
  }
  .mobile{
    color: rgb(20,117,181);
  }
  .weixin{
    width: 40px;
  }
  .iconfont{

  }

  #wrap {
    width: 800px;
    height: 300px;
    border-radius: 50%;
    margin-left: 600px;
    margin-top: -200px;
    perspective: 500px;
  }
  #wrap #logo {
    position: absolute;
    font: 40px "微软雅黑";
    color: skyblue;
    font-weight: bold;
    margin-left: 40%;
    margin-top: 80%;
    text-shadow: 2px 2px 0px white;
  }
  #wrap:hover .cubic-box {
    transform: rotateX(180deg) rotateY(320deg) rotateZ(45deg);
  }
  #wrap .cubic-box {
    position: absolute;
    width: 50px;
    height: 150px;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -150px;
    transition: 2s;
    transform-style: preserve-3d;
    transform-origin: center center -150px;

  }
  #wrap .cubic-box div {
    position: absolute;
    width: 1px;
    height: 15px;
    font: 30px/300px "微软雅黑";
    text-align: center;
    color: rgba(20,117,181,.4);
    background: skyblue;
  }
  #wrap .cubic-box div:nth-child(1) {
    z-index: 1;
  }
  #wrap .cubic-box div:nth-child(2) {
    transform: translateZ(-304px) rotateX(180deg);
  }
  #wrap .cubic-box div:nth-child(3) {
    left: -104px;
    transform-origin: right;
    transform: rotateY(-90deg);
  }
  #wrap .cubic-box div:nth-child(4) {
    right: -104px;
    transform-origin: left;
    transform: rotateY(90deg);
  }
  #wrap .cubic-box div:nth-child(5) {
    top: -104px;
    transform-origin: bottom;
    transform: rotateX(90deg);
  }
  #wrap .cubic-box div:nth-child(6) {
    bottom: -104px;
    transform-origin: top;
    transform: rotateX(-90deg);
  }

</style>
