<template>
  <div class="main">
    <el-dialog width="85%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">防治对象</div>
      </div>
      <div class="orderNav">
        <div class="mytabsbox">
          <div class="toptabs">
            <div class="tabone" :class="{'mytabactive':fztabindex==index}" @click="changemytab(index,item)" v-for="(item,index) in fzlist" :key="item.crop_id">{{item.cropName}}<span class="chooseflag" v-if="item.check==1"></span></div>
          </div>
          <div class="tabcontent" >
            
              <div v-for="(item,index) in fzCurrCheackList" :key="index">
                <div class="twoclass">{{item.preventionCategory}}</div>
                <div class="line"></div>
                <el-checkbox-group v-model="checkList">
                 <el-checkbox :label="it.preventionId" v-for="(it) in item.preventions" :key="it.preventionId" @change="choosefzdx($event,it.preventionId)">{{it.preventionName}}</el-checkbox>
                </el-checkbox-group>
              </div>
            
          </div>
        </div>
        <div class="bottomBtn">
          <el-button  class="storeInfoBtn" icon="el-icon-s-goods" type="danger"
                      @click="saveFzdx" >确定
          </el-button>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogPlayer',
    props: {
        good:{
            type:Object,
            default:() => ({})
        },
        isUpdate:{
          type:Boolean,
          default:false
        },
        crops:{
          type:String,
          default:''
        },
        preventionId:{
           type:Array,
           default:() => ([])
        },
        checkListfz:{
          type:String,
          default:''
        }
    },
    data () {
      return {
        message: '处方管理选择防治对象',
        fzlist:[],//防治对象数组
        checkList:[],
       // fzclassForeList:[],//当前4个防治对象
        fztabindex:0,//当前选中防治对象页面
        fzCurrCheackList:[],//当前页签所有checkbox
        isToLeft:true,//是否可以点击防治对象的左箭头
        isToRight:false,//是否可以点击防治对象的右箭头
        zwnum:1,//当前是第几组4个数据
        classone:0,//默认类选择第一个
        fzcategoryId:'',//当前一级分类id
        yxChooseFzdx:[],//已经选择的防治对象
        centerDialogVisible: false,
        base_url: '',
      }
    },
    mounted () {
      this.base_url=this.BASE_URL;
    },
    methods: {
      //获取用药方法
      getffData(){
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/prevention/queryPreventionByCropId?crops='+this.crops.slice(0,this.crops.length-1))
          .then((response) => {
            Loadings.hide()
            response.data.data.map(item=>{
              item.check=0
            })
            this.fzlist=response.data.data;
            this.fzlist.map(item=>{
              this.yxChooseFzdx.map(it=>{
                if(item.crop_id==it.one && it.values!=undefined && it.values.length!=0){
                  item.check=1
                }
              })
            })
            this.fzCurrCheackList=this.fzlist[0].preventionChild
            this.fzcategoryId=this.fzlist[0].crop_id

          })
      },
       //页签
      changemytab(index,item){
        this.fztabindex=index;
        this.fzCurrCheackList=item.preventionChild
        this.fzcategoryId=item.crop_id
      },
      //点击选择防治对象
      choosefzdx(e,id){
        //e是是否选中 
        if(e){//选中
          let farr=this.yxChooseFzdx.filter(item=>{
            return item.one==this.fzcategoryId
          })
          if(farr.length==0){
            let vals=[]
            vals.push(id)
            this.yxChooseFzdx.push({one:this.fzcategoryId,values:vals})
          }else{
            farr[0].values.push(id)
          }
          this.fzlist[this.fztabindex].check=1
        }else{
           //console.log(this.checkList);
           let farr=this.yxChooseFzdx.filter(item=>{
            return item.one==this.fzcategoryId
          })
          farr[0].values.map((it,index)=>{
            if(it==id){
              farr[0].values.splice(index,1)
            }
          })
          //取消选择要查看当前一级分类中是否还有选中项
          if(!farr[0].values.length){
            this.fzlist[this.fztabindex].check=0
          }
          //此处是编辑的时候处理已经渲染的复选框
          if(this.checkListfz){
             this.checkListfz.split(",").map((item,index)=>{
               if(item==id){
                  this.checkListfz.split(",").splice(index,1)
               }
             })
             this.checkList.map((it,index)=>{
               if(it==id){
                  this.checkList.splice(index,1)
               }
             })
          }
        }
      },
      dialogClose () {
        this.$nextTick(() => {
          this.centerDialogVisible = false;
        })
      },
      open() {
        if(this.isUpdate){
          this.checkList=[]
          this.yxChooseFzdx=[]
        }else{
          if(this.checkListfz){
            this.checkListfz.split(",").map(it=>{
              this.checkList.push(Number(it))
            })
          }
          this.yxChooseFzdx=this.preventionId
        }
        this.centerDialogVisible = true
        this.getffData()
      },
      saveFzdx(){
        this.centerDialogVisible = false;
        this.fztabindex=0
        this.$emit("saveFzdx",this.yxChooseFzdx,this.checkList)
      }
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 1000px !important;
  }
   /* 防治对象样式 */
  .mytabsbox{
    background-color: #fff;
    border-radius: 5px;
    width: 99%;
    position: relative;
    min-height: 200px;
  }
  .mytabsbox .arrow{
    background-color: #409ffe;
    color: #fff;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .leftarrow{
    position: absolute;
    left: 0;
    top:0;
  }
  .rightarrow{
    position: absolute;
    right:0;
    top: 0;
  }
  .toptabs{
    display: flex;
    position: relative;
    padding: 0 28px;
  }
  .tabone{
    min-width: 45px;
    padding:10px;
    padding-bottom: 10px;
    position: relative;
  }
  .mytabactive{
    border-bottom: 2px solid #F56C6C;
    color: #F56C6C;
  }
  .tabcontent{
    text-align: left;
    margin-top: 15px;
    overflow-y: auto;
    max-height: 330px;
    padding: 5px 35px;
  }
  .mytabsbox .isToLeft,.mytabsbox .isToRight{
    background-color: #eee;
  }
  .chooseflag{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #F56C6C;
    position: absolute;
    top: 5px;
    right: 0;
  }
.line{
  width: 100%;
  border-bottom: #F56C6C 1px solid;
  margin-top: 3px;
  margin-bottom: 10px;
}
.twoclass{
  /* color: #409ffe; */
  font-weight: 700;
  font-size: 16px;
  margin-top: 5px;
}

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__content {

  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .orderNav {
    position: relative;
    margin-bottom: 40px;
  }

  .inputLine {
    max-width: 250px;
    float: left;
  }

  .bottomBtn {
    display: inline-block;
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }

  /*包装*/
  .checkGroup {
    float: left;
  }

  .inputGroup {
    float: left;
    margin-right: 5px;
  }

  .packageUnit {
  }

  .unitLine {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 5px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  .unitName {
    float: left;
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;
  }

  .unitOneName {
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;

  }

  .priceUnit {
    float: left;
    display: inline-block;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #DCDFE6;
  }

  .inventoryLine {
    float: left;
    max-width: 180px;
    margin-right: 10px;
  }

  /*小计*/
  .totalMoney {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
  }

  .totalPrice {
    float: left;
    font-size: 20px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 0;
  }

  .imageBlock {
    max-width: 270px;
    padding: 20px;
  }

  .uploadImage {
    max-height: 210px;
  }

  .imageDesc {
    display: block;
    margin-top: 15px;
  }

  .stockSale {
    color: #f6e483;
    float: left;
  }

  .stockSale li {
    margin-right: 5px;
  }

  .yytime{
      margin-left: -24px;
  }
  .mmclass{
      margin-top: 20px;
      display: inline-block;
      margin-left: -75px;
  }
  .cftype{
      margin-left: -125px;
      width: 250px;
  }
</style>
