<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link to="/admin/recipelManager">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回处方管理
          </el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">处方详情</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>

    <div class="mainForm">
       <div class="line">
           <div class="desc">处方名称：</div>
           <div>{{detail.name}}</div>
       </div>
       <div class="line">
           <div class="desc">处方类型：</div>
           <div>{{detail.type | mytype}}</div>
       </div>
       <div class="line">
           <div class="desc">作物：</div>
           <div style="margin-left:28px">{{detail.cropCategoryName}}</div>
       </div>
       <div class="line">
           <div class="desc">防治对象：</div>
           <div>{{detail.preventionName}}</div>
       </div>
       <div class="line">
           <div class="desc">发生程度：</div>
           <div>{{detail.happenLevel | happenLevel}}</div>
       </div>
       <div class="line">
           <div class="desc">用药组合：</div>
           <div class="yybox">
               <div class="yydesc" v-for="item in detail.recipeGoodsList" :key="item.goodsId">
                    <div>{{item.goodsName}}</div>
                    <div>规格：{{item.smallCount}}{{item.smallUnitName}}/{{item.middleUnitName}}</div>
                    <div>使用时间：{{item.useTime}}</div>
                    <div>用法用量：{{item.usageName}}，{{item.dosage}}{{item.smallUnitName}}/亩</div>
                </div>
           </div>
       </div>
       <div class="line">
           <div class="desc">注意事项：</div>
           <div>{{detail.matters}}</div>
       </div>
       <div class="line">
           <div class="desc">备注：</div>
           <div>{{detail.remark}}</div>
       </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        message: '处方详情',
        detail:''
        
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }
      this.getData()
      this.navChange()
    },
    filters:{
        mytype(e){
            if(e==1){
                return '专家处方'
            }else if(e==2){
                return '门店处方'
            }else if(e==0){
                return '常规处方'
            }
        },
        happenLevel(e){
            if(e==0){
                return '轻发生'
            }else if(e==1){
                return '中等偏轻发'
            }else if(e==2){
                return '中等发生'
            }else if(e==3){
                return '中等偏重'
            }else if(e==4){
                return '大发生'
            }
        }
    },
    methods: {
       getData(){
             this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/recipe/detail?recipeId=' + this.$route.params.recipeId +"&recipeType="+this.$route.params.type,
            method: 'GET',
          })
            .then((res) => {
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.detail = res.data.data
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
       },
        navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
    }
  }
</script>

<style scoped>
  
  .mainPages {
    min-height: 850px;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
    text-align: left;
    padding: 5px 20px;
  }
  .line{
      display: flex;
      margin-bottom: 20px;
  }
  .desc{
      margin-right: 10px;
  }
  .yybox{
      display: flex;
      flex-direction: column;
  }
  .yydesc{
      margin-bottom: 20px;
      
  }
</style>
