<template>
  <div class="mainBody">
    <div>
      <el-row>
      <div class="pageNav">
        <router-link to="/admin/recipelManager" v-show="!isPesticide">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回处方管理
          </el-button>
        </router-link>
        <router-link to="/admin/saleOrders" v-show="isPesticide">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">返回销货开单
          </el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="topdescTitle" type="primary" :underline="false">{{this.recipeId?'编辑处方':'新增处方'}}</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>
      <el-row :gutter="10" class="rowForm">
        <el-col :span="9">
          <div class="leftBody">
             <div class="toastMessage">
              <el-alert title="提示：请使用扫码枪 或 在右侧选择需要加入处方的商品" type="success" :closable="false" center show-icon>
              </el-alert>
              <input type="text" id="scanCode" class="scanCode" v-model="qrcode" @input="qrCodeInput"/>
            </div>


            <div class="formBottom">
              <el-row>
                <div class="pay">
                  <div class="payLabel">处方类型：<span class="requireclass">*</span></div>
                  <el-select v-model="value" class="cftype">
                    <el-option
                    v-for="item in cftypeoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="payLabel">处方名称：<span class="requireclass">*</span></div>
                  <el-input  class="inputLine" v-model="recipelname" maxlength="50" @blur="nameblur"></el-input>
                </div>
              </el-row>
              <el-row>
                <div class="pay zw">
                  <div class="payLabel">作物：<span class="requireclass">*</span></div>
                  <div class="mytabsbox">
                    <div class="toptabs">
                      <div class="arrow leftarrow" :class="{'isToLeft':isToLeft}" @click="leftarrow">&lt;</div>
                      <div class="arrow rightarrow" :class="{'isToRight':isToRight}" @click="rightarrow">&gt;</div>
                      <div class="tabone" :class="{'mytabactive':zwtabindex==index}" @click="changemytab(index,item)" v-for="(item,index) in zwclassForeList" :key="item.categoryId">{{item.categoryName}}<span class="chooseflag" v-if="item.check==1"></span></div>
                    </div>
                    <div class="tabcontent" >
                      <el-checkbox-group v-model="checkList">
                        <el-checkbox :label="item.categoryName" v-for="(item) in zwCurrCheackList" :key="item.categoryId" @change="choosezw($event,item.categoryId)"></el-checkbox>

                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="payLabel">防治对象：<span class="requireclass">*</span></div>
                  <div class="choosefzdx" @click="chooseFzdx">{{fzdxdesc.indexOf(",")=='-1'?fzdxdesc:fzdxdesc.substr(0,fzdxdesc.length-1)}}</div>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="payLabel">发生程度：<span class="requireclass">*</span></div>
                  <el-select v-model="fsvalue" class="cftype">
                    <el-option
                    v-for="item in fsoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="payLabel">用药组合：<span class="requireclass">*</span></div>
                  <div class="yyzhbox" v-if="yyzhList.length==0">暂无药品</div>
                  <div class="yypay" v-else>
                     <div class="yyzhbox" v-for="(item,index) in yyzhList" :key="item.good.goodIds">
                    <div class="yyline">商品名称：{{item.good.goodsName}}</div>
                    <div class="yyline">规格：{{item.good.smallCount}}{{item.good.smallUnitName}}/{{item.good.middleUnitName}}</div>
                    <div class="yyline">使用时间：{{item.yytime}}</div>
                    <div class="yyline">用法用量：{{item.syfadesc}},{{item.yl}}{{item.good.smallUnitName}}/亩</div>
                    <div class="yyeditbox">
                      <div class="yyiede" @click="edityyzh(index)">编辑</div>
                      <div class="yyiede" @click="delyyzh(item,index)">删除</div>
                    </div>
                  </div>
                  </div>
                </div>
              </el-row>
              <el-row>
                <div class="Qbox">
                  <div class="addProQiang" @click="handleClick">使用扫码枪添加药品</div>
                </div>
              </el-row>
              <el-row>
                <div class="pay">
                  <div class="payLabel">注意事项：</div>
                  <el-input id="stockDescription" class="zysxDesc" :rows="5"
                            type="textarea" maxlength="500" show-word-limit v-model="matters"
                            placeholder="请输入注意事项，最多500个字符"></el-input>
                </div>
              </el-row>

              <el-row>
                <div class="pay">
                  <div class="payLabel">备注：</div>
                  <el-input id="stockDescriptions" class="zysxDesc" :rows="2"
                            type="textarea" maxlength="500" show-word-limit v-model="remark"
                            placeholder="请输入备注信息，最多500个字符"></el-input>
                </div>
              </el-row>
              <div class="bottomBtn">
                <el-button class="storeInfoBtn" type="danger" icon="el-icon-document-copy" @click="saveRecipel">保存
                </el-button>

              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="15">
          <div class="rightBody">
            <el-row>
              <div class="topNav">
                <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                             placeholder="选择商品分类"
                             v-model="category"
                             :options="categorys"
                             :props="{value:'categoryId',label:'categoryName',children:'children'}"
                             separator=" / "
                             @change="categoryChange">
                </el-cascader>
                <el-input class="searchInput"
                          placeholder="请输入商品名称"
                          v-model="searchInput"
                          :clearable="true">
                </el-input>
                <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
              </div>
              <div class="navRight">
                <el-button v-show="getAuthorityMenu('200102')" class="searchBtn" type="danger" @click="toNewGood">新增商品</el-button>
                <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
              </div>
            </el-row>

            <div class="row mainCard" @click="handleClick">
              <el-col :span="4" v-for="(good, index) in goods" :key="index">
                <el-card class="goodCart" :class="good.added?'addGood':''">
                  <el-image :src="base_url+'/goods'+good.goodsImg" class="cardImage" fit="cover"></el-image>
                  <div v-if="good.added" class="checkedbox">已选</div>
                  <div class="goodDesc">
                    <div class="desc">
                      <div class="descTitle">规格</div>
                      <div class="descContent">{{ good.packageUnit }}</div>
                    </div>

                    <div class="descTag">
                      <div class="descTitle">库存</div>
                      <div class="descContentTag">
                        <el-tag :type="good.inventory <= 5 ? 'danger':'primary'" class="inventoryTag"
                                id="status">{{good.inventory}}&nbsp;{{good.middleUnitName}}
                        </el-tag>
                      </div>
                    </div>
                    <div class="desc">
                      <div class="descTitle">商品名</div>
                      <div class="descContent">{{ good.goodsName }}</div>
                    </div>

                    <div class="bottom clearfix">
                      <el-button v-if="!good.added " class="addCart" type="danger" icon="el-icon-shopping-bag-2"
                                 @click="addGood(good)">
                        加入处方
                      </el-button>
                      <el-button v-if="good.added" disabled class="addCart" type="danger" icon="el-icon-s-goods">
                        商品已加入
                      </el-button>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </div>

            <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch" @rePage="rePage"></page-helper>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="dialogs">
      <recipel-yfyl ref="dialogYfyl" @addPro="addPro" :good="recipelgood" :editgood="yfylidet"></recipel-yfyl>
      <recipel-fzdx ref="dialogFzdx" :crops="crops" @saveFzdx="saveFzdx" :isUpdate="isUpdate" :preventionId="alchooseFzdx" :checkListfz="checkListfz"></recipel-fzdx>
    </div>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import RecipelYfyl from '../../../components/recipel/RecipelYfyl'
  import RecipelFzdx from '../../../components/recipel/RecipelFzdx'

  export default {
    name: 'StockOrder',
    components: {
      RecipelYfyl,
      RecipelFzdx,
      PageHelper
    },
    data () {
      return {
        message: '新增编辑处方页面',
        recipeId:'',//处方id
        recipelType:'',//编辑时从列表页面传过来的id
        //type=1编辑处方，=0新增处方
       // type: 0,
        recipelgood:{},//当前需要加入处方的商品
        //处方类型
        cftypeoptions:[
            {
                value:'2',
                label:'门店处方'
            },
            {
                value:'1',
                label:'专家处方'
            }
        ],
        value:'2',
        recipelname:'',//处方名称
        iscfname:false,//处方名称是否重复
        checkList:[],
        zwClassList:[],//作物数据
        zwclassForeList:[],//当前4个作物
        zwtabindex:0,//当前选中作物页面
        zwCurrCheackList:[],//当前页签所有checkbox
        isToLeft:true,//是否可以点击作物的左箭头
        isToRight:false,//是否可以点击作物的右箭头
        zwnum:1,//当前是第几组4个数据
        classone:0,//默认类选择第一个
        zwcategoryId:'',//当前一级分类id
        yxChooseZw:[],//已经选择的作物
        crops:'',//选择的作物ids
        fzdxdesc:'点击选择防治对象',
        alchooseFzdx:[],//已经选择的防治对象
        isUpdate:false,//是否是需要重新选择防治对象，如果修改了作物就要从新选择
        checkListfz:"",//编辑时存放选择的防治对象id
        //发生程度
        fsoptions:[
            {
                value:'0',
                label:'轻发生'
            },
            {
                value:'1',
                label:'中等偏轻发生'
            },
            {
                value:'2',
                label:'中等发生'
            },
            {
                value:'3',
                label:'中等偏重'
            },
            {
                value:'4',
                label:'大发生'
            }
        ],
        fsvalue:'0',
        yyzhList:[],//用药组合数组
        yfylidet:{},//编辑当前用药组合
        matters:'1. 使用本品应采取相应的安全防护措施，穿戴防护服和防护手套、口罩等，避免皮肤接触和口鼻吸入。使用中不可吸烟、饮水及吃东西，使用后及时清洗手、脸等暴露部位皮肤并更换衣物，2. 严格按照推荐剂量量取药剂，不得任意增减用量，严禁用手搅拌药液。3. 避免孕妇及哺乳期妇女接触。',//注意事项
        remark:'',//备注



        good: {},
        goods: [],
        //showClearBtn: false,
        //checkedStop: false,//默认不显示停用商品
        base_url: '',
        //分类
        //级联选择
        categorys: [
          {
            'categoryId': 0,
            'categoryName': '全部',
            'categotyOrder': 0,
            'parentCategoryId': 0
          }
        ],
        category: {},
        categoryId: '',
        //搜索
        searchInput: '',
        //扫码传参
        qrcode: '',
        timer: -1,
        //分页切换
        currentPage: 1,
        //销货新增处方传入
        isPesticide:'',
      }
    },
    mounted () {
      //document.getElementById('scanCode').focus()
      this.qrcode = ''
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getCategorys()
      }
      if(this.$route.query.recipeId ){
        this.recipeId=this.$route.query.recipeId
        this.recipelType=this.$route.query.type
        this.getDetail()
      }
      //销货新增处方传入
      if(this.$route.params.isPesticide){
        this.isPesticide=this.$route.params.isPesticide
      }
      this.getSearch(1)
      //let currentWidth = $(window).width()
     // this.changeWidth(currentWidth)

      //this.checkSuppliers()
      this.base_url=this.BASE_URL;
      //获取作物数据
      this.getzwData()
      if(this.$route.params.good){
        //console.log(this.$route.params.good);
        this.$route.params.good.packageUnit=this.$route.params.good.packingSpecification
        this.addGood(this.$route.params.good)
      }
      this.navChange()
    },
    methods: {
      //作物页签
      changemytab(index,item){
        this.zwtabindex=index;
        this.zwCurrCheackList=item.childrenNode
        this.zwcategoryId=item.categoryId
      },
      //作物左
      leftarrow(){
       if(!this.isToLeft){
         this.zwclassForeList=this.zwClassList.slice((this.zwnum-1)*4-4,(this.zwnum-1)*4)
         this.zwCurrCheackList=this.zwclassForeList[0].childrenNode
         this.zwnum-=1;
         this.isToRight=false;
         this.zwtabindex=0
         this.zwcategoryId=this.zwclassForeList[0].categoryId
       }
       //如果点击到1了说明到左边头了
       if(this.zwnum==1){
         this.isToLeft=true;
       }
      },
      //作物右
      rightarrow(){
        if(!this.isToRight){
          this.zwclassForeList=this.zwClassList.slice(this.zwnum*4,this.zwnum*4+4)
          this.zwCurrCheackList=this.zwclassForeList[0].childrenNode
          this.zwnum+=1;
          this.isToLeft=false;
          this.zwtabindex=0
          this.zwcategoryId=this.zwclassForeList[0].categoryId
        }
        if(this.zwnum>=this.zwClassList.length/4){
          this.isToRight=true;
        }
      },
       //获取作物数据
      getzwData(){
         this.$axios.get(this.base_url + '/psi/cropCategory/queryCropCategoryTree')
        .then((response) => {
          Loadings.hide()
          if(response.data.code==200){
             response.data.data.map((item)=>{
                item.check=0
             })
            this.zwClassList=response.data.data;
            setTimeout(() => {
              this.zwClassList.map(it=>{
                this.yxChooseZw.map(ite=>{
                  if(it.categoryId==ite.one){
                    it.check=1
                  }
                })

              })
            }, 1000);
            this.zwcategoryId=this.zwClassList[0].categoryId
            this.zwclassForeList=this.zwClassList.slice(0,4)
            this.zwCurrCheackList=this.zwClassList[0].childrenNode
          }else{
             this.$message({
              message: '网络异常~',
              center: true,
              type: 'warning',
              offset: 150
            })
          }

        })
      },
      //点击选择作物
      choosezw(e,id){
        this.fzdxdesc='点击选择防治对象'
        this.alchooseFzdx=[]
        this.isUpdate=true;
        if(e){
          this.yxChooseZw.push({one:this.zwcategoryId,name:this.checkList[this.checkList.length-1],id:id})
          this.zwclassForeList[this.zwtabindex].check=1
        }else{
          this.yxChooseZw.map((item,index)=>{
            if(item.id==id){
              this.yxChooseZw.splice(index,1)
            }
          })
          //取消选择要查看当前一级分类中是否还有选中项
          let f=this.yxChooseZw.filter(item=>{
            return item.one==this.zwcategoryId
          })
          if(!f.length){
            this.zwclassForeList[this.zwtabindex].check=0
          }
        }

       // console.log(this.yxChooseZw);
      },
      //点击选择防治对象
      chooseFzdx(){
        if(this.yxChooseZw.length!=0){
          this.crops=""
          this.yxChooseZw.map(item=>{
            this.crops=this.crops+item.id+","
          })
          setTimeout(()=>{
            this.$refs.dialogFzdx.open()
          },500)
        }else{
          this.$message({
              message: '请先选择作物',
              center: true,
              type: 'warning',
              offset: 150
            })
        }
      },
      //选择防治对象
      saveFzdx(e,list){
        this.alchooseFzdx=e;
        this.checkListfz=list.join(",")
        this.fzdxdesc=''
        let flag=10
        if(list.length<10){
            flag=list.length
        }
        for(let i=0;i<=flag-1;i++){
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/prevention/queryPrevention?preventionId='+list[i])
          .then((response) => {
            Loadings.hide()
           if(response.data.code==200){
             this.fzdxdesc=this.fzdxdesc+response.data.data.preventionName+","
           }

          })
        }
        this.isUpdate=false;
      },
      //添加用药组合
      addPro(list,type){
        console.log(list);
        console.log(type);
        if(type==1){
          this.goods.map(item=>{
            if(item.goodsId==list.good.goodsId){
              item.added=true
            }
          })
          this.yyzhList.push(list)
        }else{
          this.yyzhList.map((item,index)=>{
            if(item.good.goodsId==list.good.goodsId){
              this.yyzhList.splice(index,1,list)
            }
          })
        }
        this.handleClick()
      },
       //添加用药组合商品
      addGood (good) {
        this.yfylidet={}
        this.recipelgood=good
        this.$nextTick(() => {
            this.$refs.dialogYfyl.clearyl()
            this.$refs.dialogYfyl.open()
        })
      },
      //编辑用药组合
      edityyzh(index){
        this.recipelgood=this.yyzhList[index].good
        this.yfylidet=this.yyzhList[index]
        this.$nextTick(() => {
            this.$refs.dialogYfyl.clearyl()
            this.$refs.dialogYfyl.open()
        })
      },
      //删除用药组合
       delyyzh(item,index){
         this.$confirm('确定要删除药品吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.yyzhList.splice(index,1)
            this.goods.map(it=>{
              if(it.goodsId==item.good.goodsId){
                it.added=false;
              }
            })
          }).catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });

      },
      //详情数据
      getDetail(){
         this.$axios.get(process.env.VUE_APP_SERVER + '/psi/recipe/detail?recipeId='+this.recipeId+"&recipeType="+this.recipelType)
          .then((response) => {
            Loadings.hide()
           if(response.data.code==200){
             let data=response.data.data
             this.value=data.type+''
             this.recipelname=data.name
             this.checkList=data.cropCategoryName.split(",")
             this.yxChooseZw=data.cropCategoryList
             this.crops=data.cropCategoryId
             this.alchooseFzdx=data.preventionList
             this.checkListfz=data.preventionId
             this.fzdxdesc=data.preventionName
             let yyzharr=[];
             data.recipeGoodsList.map(it=>{
               let obj={}
               obj.good=it.good
               obj.yytime=it.useTime
               obj.yl=it.dosage
               obj.syfaid=it.usageId
               obj.syfadesc=it.usageName
               yyzharr.push(obj)
             })
             this.yyzhList=yyzharr
             this.matters=data.matters
             this.remark=data.remark
           }

          })
      },
      handleClick (event) {
        document.getElementById('scanCode').focus()
      },
      qrCodeInput (e) {
        let that=this;
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          if (this.qrcode.indexOf('&') !== -1) {
            this.qrcode = this.qrcode.replace(new RegExp('&', 'g'), '%26')
          }
          Loadings.show()
          this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/queryPestBarCodeGoods?barCode='
          + this.qrcode + '&tenantId=' + JSON.parse(window.localStorage.getItem("tenant_info")).tenantId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            if (response.data.code === 200) {
               let arr=this.yyzhList.filter(item=>{
                // console.log(item.good.goodsId);
                 return item.good.goodsId==resp.data.goodsId
               })
               //console.log(arr);
               if(arr.length!=0){
                  this.$message({
                    message: '此药品已选择~',
                    center: true,
                    type: 'warning',
                    offset: 150
                  })
                  return false
                }
                if(JSON.stringify(response.data.data)!='{}'){
                   this.addGood(response.data.data)
                }else{
                  this.$toast.showCodeConfirm('暂无商品', '', '是否去新增', '去新增', function () {
                      that.toNewGood()
                    }
                  )
                }
            } else {
              this.$message({
                type: 'warning',
                message: response.data.msg,
                duration: 2000,
                offset: 110
              })
            }
            this.qrcode = ''
          })

        }, 50)
      },
      navChange () {
        let flag = 0
        if (this.recipeId) {
          flag = 1
        }
        this.$emit('navChange', flag)
        SessionStorage.set(EDIT_FLAG, flag)
      },
      //获取所有分类
      getCategorys () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/category/queryPestCategory')
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            for (let i = 0; i < resp.data.length; i++) {
              this.categorys.push(resp.data[i])
            }
          })
      },
      //选中分类搜索
      categoryChange () {
        let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0]
        this.categoryId = checkedNodes.value
        this.getSearch(1)
      },
      rePage (e) {
        this.currentPage = e
        //console.log("子组件传入当前分页：",e,"currentPage: ",this.currentPage);
      },
      getSearch: function (page, pageChange) {
        Loadings.show()
        let current = page
        //let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let resize = 12
        let size = resize

        if (this.categoryId === 0) {
          this.categoryId = ''
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/goods/queryPestGoods?current='
          + current + '&size=' + size + '&tenantId=' + JSON.parse(localStorage.getItem("tenant_info")).tenantId + '&categoryId=' + this.categoryId + '&goodsName=' + this.searchInput )
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            resp.data.records.map(item=>{
              item.added=false;
            })
            this.goods = resp.data.records
            setTimeout(() => {
              this.goods.map((item)=>{
                this.yyzhList.map(it=>{
                  if(it.good.goodsId==item.goodsId){
                    item.added=true;
                  }
                })
              })
            }, 500);

            this.$refs.pageHelper.render(page, resp.data.total, resize)
          })
      },
      reSearch (data) {
        this.getSearch(1, data)
      },
      //处方是否重名
      nameblur(){
        this.$axios.get(this.base_url + '/psi/recipe/isReuse?recipeId='+this.recipeId+"&recipeName="+this.recipelname+"&type="+this.value)
        .then((response) => {
          Loadings.hide()
          if(response.data.code==200){
            if(!response.data.data){
              this.iscfname=true
              this.$message({
                message: '处方名称重复',
                center: true,
                type: 'warning',
                offset: 150
              })
            }else {
              this.iscfname=false
            }
          }else{
            this.$message({
              message: '网络异常~',
              center: true,
              type: 'warning',
              offset: 150
            })
          }

        })
      },
      //保存和编辑
      saveRecipel () {
        if(!this.recipelname){
          this.$message({
            message: '请填写处方名称',
            center: true,
            type: 'warning',
            offset: 150
          })
          return false;
        }
        if(this.yxChooseZw.length==0){
          this.$message({
            message: '请选择作物',
            center: true,
            type: 'warning',
            offset: 150
          })
          return false;
        }
        if(this.alchooseFzdx.length==0 || !this.fzdxdesc){
          this.$message({
            message: '请选择防治对象',
            center: true,
            type: 'warning',
            offset: 150
          })
          return false;
        }
        if(this.yyzhList.length==0){
          this.$message({
            message: '请添加药品',
            center: true,
            type: 'warning',
            offset: 150
          })
          return false;
        }
         if(this.iscfname){
          this.$message({
            message: '处方名称重复',
            center: true,
            type: 'warning',
            offset: 150
          })
          return false;
        }
        let recipelobj={}
        recipelobj.name=this.recipelname
        recipelobj.type=this.value
        let cropCategoryId=""
        this.yxChooseZw.map(it=>{
          cropCategoryId+=it.id+","
        })
        recipelobj.cropCategoryId=cropCategoryId.slice(0,cropCategoryId.length-1)
        recipelobj.preventionId=this.checkListfz
        recipelobj.happenLevel=this.fsvalue
        recipelobj.matters=this.matters
        recipelobj.remark=this.remark
        let recipeGoodsDTOList=[]
        this.yyzhList.map(it=>{
          let obj={}
          obj.goodsId=it.good.goodsId
          obj.useCount=''
          obj.useTime=it.yytime
          obj.usageId=it.syfaid
          obj.dosage=it.yl
          recipeGoodsDTOList.push(obj)
        })
        recipelobj.recipeGoodsDTOList=recipeGoodsDTOList
        if(this.recipeId){
          recipelobj.recipeId=this.recipeId
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/recipe/update', recipelobj)
          .then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$message({
                  type: 'success',
                  message: '编辑处方成功！',
                  duration: 2000,
                  offset: 110
                })
                setTimeout(() => {
                  this.$router.push({
                    name: '/recipelManager',
                    params: {
                    }
                  })
                }, 2000)
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
        }else{
          this.$axios.post(process.env.VUE_APP_SERVER + '/psi/recipe/save', recipelobj)
          .then((res) => {
            Loadings.hide()
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.$message({
                  type: 'success',
                  message: '新增处方成功！',
                  duration: 2000,
                  offset: 110
                })
                if(!this.isPesticide){
                  setTimeout(() => {
                    this.$router.push({
                      name: '/recipelManager',
                      params: {
                      }
                    })
                  }, 2000)
                }else if(this.isPesticide){
                  setTimeout(() => {
                    this.$router.push({
                      name: '/saleOrders',
                      params: {
                        type:7,//新增处方传入
                      }
                    })
                  }, 2000)
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
        }

      },
      //新增商品
      toNewGood () {
        let type = 6
        this.$router.push({
            name: '/goodsManagerEdit',
            params: {
              type,
            }
        })
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
      //视口
      // changeWidth (width) {
      //   let cardImage = document.getElementsByClassName('cardImage')
      //   if (this.showClearBtn) {
      //     if (width <= 1669) {
      //       for (let i = 0; i < cardImage.length; i++) {
      //         cardImage[i].style.maxHeight = 'unset !important'
      //         cardImage[i].style.height = '145px !important'
      //       }
      //     }
      //     if (width < 1523) {
      //       for (let i = 0; i < cardImage.length; i++) {
      //         cardImage[i].style.maxHeight = 'unset !important'
      //         cardImage[i].style.height = '145px !important'
      //       }
      //     }

      //   }

      //   this.triggerResize()
      // },
      // triggerResize () {
      //   $(window).on('resize', function () {
      //     let width = $(window).width()
      //     let cardImage = document.getElementsByClassName('cardImage')

      //     if (this.showClearBtn) {
      //       if (width <= 1669) {
      //         for (let i = 0; i < cardImage.length; i++) {
      //           cardImage[i].style.maxHeight = 'unset !important'
      //           cardImage[i].style.height = '145px !important'
      //         }
      //       }
      //       if (width < 1523) {
      //         for (let i = 0; i < cardImage.length; i++) {
      //           cardImage[i].style.maxHeight = 'unset !important'
      //           cardImage[i].style.height = '145px !important'
      //         }
      //       }
      //     }

      //   }).trigger('resize')
      // },
    }
  }
</script>

<style scoped>
  .mainBody {
    padding: 10px;
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .rowForm {
    margin-bottom: 0px;
  }

  .leftBody, .rightBody {
    background: #fafafa;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: dashed 1px #faecd8;
    min-height: 840px;
  }

  .topNav {
    float: left;
    margin-left: 10px;
    max-width: 500px;
  }


  .clearCart {
    float: right;
    right: 0;
    line-height: 40px;
    height: 40px;
    padding: 0 10px 10px 10px;
  }

  /*购物车*/
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  .pay {
    display: flex;
    margin-left: 20px;
    margin-top: 10px;
  }
  .inputLine{
    width: 300px;
  }
  .requireclass{
    color: #F56C6C;;
  }
  .zysxDesc{
    width: 80%;

  }
  .pay .payLabel {
    /* text-align: right; */
    vertical-align: middle;
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
  }
  .payLabel{
    width: 88px;
  }
  .priceInput {
    float: left;
  }

  /*备注*/
  .pay .remarkLabel {
    float: left;
    font-weight: 700;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;

  }
  .remarkLabel{
    margin-left: 5px;
  }

  .bottomBtn {
    margin-top: 30px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 16%;
    margin-right: -70px;
  }

  .resetBtn {
    width: 180px;
  }

  .tipTag {
    position: relative;
  }

  /*右侧页面*/
  .categoryInput {
    float: left;
    width: 180px;
    margin-right: 10px;
  }

  .searchInput {
    width: 200px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 0;
    float: left;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*商品列表*/
  .el-col-4 {
    width: 16.4%;
  }

  .mainCard {
    margin-left: 5px;
    margin-top: 10px;
    min-height: 670px;
  }

  .pay /deep/ .inputLine {
    width: 80%;
  }

  /deep/ .el-card__body {
    padding: 5px;
    left: 0;
  }

  .goodCart {
    max-width: 230px;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .cardImage {
    /*width: 150px;
    height: 150px;*/
    /*max-height: 145px;*/
    height: 145px;
    border-radius: 10px;
  }

  .desc .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .desc .descContent {
    line-height: 30px;
    font-size: 12px;
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descTitle {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 12px;
    font-weight: 700;
    color: #606266;
    box-sizing: border-box;
    line-height: 30px;
  }

  .descTag .descContentTag {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .descTag .descContentTag {
    padding-bottom: 8px;
  }

  .descTag .descContentTag:nth-of-type(2) {
    padding-top: 0;
  }

  .descTag .descContentTag /deep/ .el-tag {
    line-height: 25px;
    height: 25px;

  }

  .addCart {
    padding: 6px 12px;
    margin-bottom: 5px;
  }

  /*已选*/
  .addGood {
    border: 1px solid #F56C6C;
    position: relative;
  }

  .checkedbox {
    width: 60px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    background-color: #F56C6C;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
  }

  .inventoryTag {
    padding: 0 20px;
  }

  .scanCode {
    color: #fff;
    border: 0px solid #fff;
    position: absolute;
    outline: none;
    width: 300px;
    opacity: 0;
  }

  /* 作物样式 */
  .mytabsbox{
    background-color: #fff;
    box-shadow: 2px 2px 3px 1px #f1f1f1;
    padding: 15px;
    border-radius: 5px;
    width: 80%;
    position: relative;
    min-height: 200px;
  }
  .mytabsbox .arrow{
    background-color: #409ffe;
    color: #fff;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .leftarrow{
    position: absolute;
    left: 0;
    top:0;
  }
  .rightarrow{
    position: absolute;
    right:0;
    top: 0;
  }
  .toptabs{
    display: flex;
    position: relative;
    padding: 0 28px;
  }
  .tabone{
    width: 90px;
    padding-bottom: 10px;
    position: relative;
  }
  .mytabactive{
    border-bottom: 2px solid #409ffe;
    color: #409ffe;
  }
  .tabcontent{
    text-align: left;
    margin-top: 15px;
    overflow-y: auto;
    max-height: 330px;
  }
  .mytabsbox .isToLeft,.mytabsbox .isToRight{
    background-color: #eee;
  }
  .chooseflag{
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #F56C6C;
    position: absolute;
    top: 0;
    right: 0;
  }
  .choosefzdx{
    border-radius: 5px;
    border:1px solid #eee;
    width: 80%;
    height: 40px;
    line-height: 30px;
    background-color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
  }
  .yyzhbox{

    text-align: left;
    padding: 10px 12px;
    border-radius: 5px;
  }
  .yypay{
    background-color: #fff;
    width: 80%;
    display: flex;
    flex-direction: column;
  }
  .yyline{
    margin-bottom: 5px;
  }
  .yyeditbox{
    display: flex;
    justify-content: space-between;
    color: #e92322;
  }
  .yyiede{
    cursor: pointer;
  }
   /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }
  .returnPrevious {
    float: left;
  }
  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }
   .topdescTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }
  .addProQiang{
    text-align: center;
    width: 77%;
    background-color: #e92322;
    color: #fff;
    padding: 10px 0;
    border-radius: 5px;
    margin-left: 78px;
    margin-top: 15px;
    cursor: pointer;
  }
  .Qbox{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
</style>
