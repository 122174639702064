<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="输入用户姓名"
                  v-model="realNameInput"
                  :blur="realNameInputChange(realNameInput)"
                  :clearable="true">
        </el-input>
        <el-input class="searchInput"
                  placeholder="输入用户手机号"
                  v-model="phoneInput"
                  :blur="phoneInputChange(phoneInput)"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
        <div class="checkStop">
          <template>
            <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示停用用户</el-checkbox>
          </template>
        </div>

      </div>
      <div class="navRight">
        <el-button class="searchBtn" type="danger" @click="addUser" v-if="getAuthorityMenu('600301')">新增用户</el-button>
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="users"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
        <!--<el-table-column property="name" label="用户姓名" width="150px" sortable :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.name===''?'-':scope.row.name}}
          </template>

        </el-table-column>-->
        <el-table-column property="realName" label="员工姓名" width="150px" sortable :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.realName===''?'-':scope.row.realName}}
          </template>

        </el-table-column>
        <el-table-column property="sex" label="性别" width="120" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.sex === 1 ? 'primary':'warning'" class="showCenter"
                    id="sex">{{SEX_STATUS | optionObjectKV(scope.row.sex)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="phone" label="手机号" width="200px" sortable>
          <template slot-scope="scope">
            {{scope.row.phone===''?'-':scope.row.phone}}
          </template>
        </el-table-column>

        <el-table-column property="email" label="邮箱" width="210">
          <template slot-scope="scope">
            <el-tag type="primary" class="showCenter"
                    id="email">{{scope.row.email===''?'-':scope.row.email}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="email" label="生日" width="200">
          <template slot-scope="scope">
            <el-tag type="info" class="showCenter"
                    id="birthday">{{scope.row.birthday===''?'-':scope.row.birthday}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="createTime" label="创建时间" width="210">
          <template slot-scope="scope">
            <el-tag :type="'success'" class="showCenter"
                    id="deadline">{{scope.row.createTime===''?'-':scope.row.createTime}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="openStatus">{{SUPPLIER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="270"
        >
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button v-if="getAuthorityMenu('600301')"
              size="mini"
              type="primary"
              @click="edit(scope.row)">编辑赋权
            </el-button>
            <!--<el-button
              size="mini"
              type="warning"
              @click="editPwd(scope.row)">修改密码
            </el-button>-->

            <el-button v-if="getAuthorityMenu('600302')"
              size="mini"
              :type="scope.row.status===0?'danger':'primary'"
              @click="openStop(scope.row)">{{scope.row.status===0?'停用':'启用'}}
            </el-button>

          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>

    <user-privilege ref="userPrivilege" @getSearch="getSearch"></user-privilege>
    <user-pay-detail ref="userPayDetail"></user-pay-detail>
    <user-pwd ref="userPwd"></user-pwd>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import UserPrivilege from '../../../components/UserPrivilege'
  import UserPayDetail from '../../../components/UserPayDetail'
  import UserPwd from '../../../components/UserPwd'

  export default {
    name: 'UserRolesManager',
    components: {
      PageHelper,
      UserPrivilege,
      UserPayDetail,
      UserPwd
    },
    data () {
      return {
        message: '用户管理',
        user: {},
        users: [],
        loginUser: {},
        realNameInput: '',
        phoneInput: '',
        checkedStop: false,//默认不显示停用供应商
        SUPPLIER_STATUS: SUPPLIER_STATUS,
        SEX_STATUS: SEX_STATUS,
        userStatus: '',
        currentRow: null,
        remarkFlag: false,
        currentTime: new Date(),
      }
    },
    mounted () {

      setTimeout(function () {
        if (document.getElementsByClassName('swal2-container')[0]) {
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display = 'none'
        }
      }, 50)
      this.loginUser = LocalStorage.get(LOGIN_USER)
      // console.log('当期loginUser： ', this.loginUser)
      this.navChange()
      this.currentTime = this.$moment(this.currentTime).format('YYYY-MM-DD HH:mm:ss')

      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1)
      }

    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      realNameInputChange (searchInput) {
        //console.log('姓名搜索内容: ', searchInput);
      },
      phoneInputChange (searchInput) {
        //console.log('电话搜索内容: ', searchInput);
      },
      statusChange (e) {
        //console.log("当前选中：",e)
        this.userStatus = e
        this.getSearch(1)
      },
      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        let size = this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let showStop = 0
        if (this.checkedStop === false) {
          showStop = 0
        } else {
          showStop = 1
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/blade-user/queryuUserPage?current=' + current + '&size=' + size
          + '&name=' + this.realNameInput + '&phone=' + this.phoneInput + '&status=' + showStop
          + '&createUser=' + this.loginUser.userId).then((response) => {
          Loadings.hide()
          let resp = response.data
          this.users = resp.data.records
          this.$refs.pageHelper.render(page, resp.data.total, size)
        })
      },
      reSearch (data) {
        this.$router.go(0)
      },
      openStop (item) {
        let _this = this
        let optionStatus = 0
        if (item.status === 0) {
          optionStatus = 1
        }
        console.log('item: ', item)
        _this.$axios.get(process.env.VUE_APP_SERVER + '/blade-user/modifyUserStatus?id=' + item.id+"&status="+optionStatus)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                if(optionStatus===1){
                  this.$message({
                    type: 'success',
                    message: '停用成功！',
                    duration: 2000,
                    offset: 110
                  })
                }else{
                  this.$message({
                    type: 'success',
                    message: '启用成功！',
                    duration: 2000,
                    offset: 110
                  })
                }
                _this.getSearch(_this.$refs.pageHelper._data.page)
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //2.显示停用用户
      checkedStopClick () {
        //console.log('当前的checkedStop: ', this.checkedStop)
        this.getSearch(1)
      },
      //3.新增供应商
      addUser () {
        let type = 0
        this.$router.push({
          name: '/userRolesManagerEdit',
          params: { type }
        })
        console.log("type==>",type)
        console.log("当前user111：",this.user)
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      edit (item) {
        let type = 1
        this.$router.push({
          name: '/userRolesManagerEdit',
          params: {
            item,
            type
          }
        })
      },

      //编辑权限
      editPrivilege (item) {
        let _this = this
        let user = this.nameChange(item)
        _this.$refs.userPrivilege.openUserPrivilege(user)
      },
      //修改密码
      /*editPwd(item){
        this.$refs.userPwd.openMyPwd(item);
      },*/
      nameChange (item) {
        let name = item.realName
        if (Tool.isEmpty(item.realName)) {
          name = item.clientPerson
        }
        if (Tool.isEmpty(name)) {
          name = '-'
        }
        item.name = name
        return item
      },
      //5.往来明细
      payDetail (item) {
        let _this = this
        let user = this.nameChange(item)
        _this.$refs.userPayDetail.openUserPayDetail(user)
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      remarkEnters (remark) {
        if (remark.length > 15) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 170px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkArea {
    float: left;
    margin-right: 10px;
  }

  .checkStop {
    float: left;
    margin-right: 5px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 5px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  .mainForm {

  }

  /deep/ .el-table .el-table__cell {
    text-align: center;
  }

  /deep/ .el-table .el-table__cell:first-of-type {
    line-height: 30px !important;
  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 630px;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }

  .toolTipWidth {
    max-width: 300px !important;
    text-align: center !important;
  }

  .toolTipWidthPlugin {
    padding: 0 0 20px;
    float: left;
    /*text-align: center;*/
  }

  .showTipLine {
    line-height: 36px;
    height: 36px;
    width: 150px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showTipLines {
    line-height: 36px;
    height: 36px;
    width: 160px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showTipLinePlugin {
    line-height: 36px;
    height: 36px;
    width: 200px !important;
    text-align: center;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /deep/ .el-radio {
    margin-right: 10px;
  }

  .optionList {
  }
</style>
