<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-select v-model="value" placeholder="请选择" class="cftype">
            <el-option
            v-for="item in cftypeoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        <el-cascader class="categoryInput inputOptionLine" ref="categoryInput"
                     placeholder="选择作物"
                     v-model="category"
                     :options="categorys"
                     :props="{value:'categoryId',label:'categoryName',children:'childrenNode'}"
                     separator=" / "
                     @change="categoryChange">
        </el-cascader>
        <el-input class="searchInput"
                  placeholder="处方名称、防治对象、商品名称"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  :clearable="true">
        </el-input>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>
      </div>
      <div class="navRight">
        <el-button v-if="getAuthorityMenu('500401')" class="searchBtn" type="danger" @click="addRecipel">新增处方</el-button>
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="goods"
        height="700px"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="80" fixed></el-table-column>
        <el-table-column property="name" label="处方名称" width="240" fixed></el-table-column>
        <el-table-column property="type" label="处方类型" width="120" :formatter="cftypeFormat"></el-table-column>
        <el-table-column property="cropName" label="使用作物" ></el-table-column>
        <el-table-column property="happenLevel" label="发生程度" width="160" :formatter="cdFormat"></el-table-column>
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="240">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button v-if="scope.row.isUpdate==1 && getAuthorityMenu('500402')"
              size="mini"
              type="primary"
              @click="edit(scope.row)">编辑
            </el-button>
            <el-button v-if="scope.row.isUpdate==1 && getAuthorityMenu('500404')"
              size="mini"
              type="danger"
              @click="del(scope.row)">删除
            </el-button>
            <el-button v-if="scope.row.isUpdate==0 && getAuthorityMenu('500403')"
              size="mini"
              type="primary"
              @click="detail(scope.row)">查看
            </el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
    <!-- <dialog-goods-stock-check ref="dialogGoodsStockCheck" @stockChange="stockChange"></dialog-goods-stock-check> -->
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'

  export default {
    name: 'RecipelrManager',
    components:{PageHelper},
    data () {
      return {
        message: '商品管理',
        good: {},
        goods: [],
        base_url:'',
        searchInput: '',
        currentRow: null,

        //级联选择
        categorys: [
          {
            "categoryId":0,
            "categoryName":"全部",
            "categotyOrder":0,
            "childrenNode":[
              {
                "categoryId":0,
                "categoryName":"全部分类",
                "categotyOrder":0,
                "childrenNode":[],
                "parentCategoryId":0
              }
            ],
            "parentCategoryId":0
          }
        ],
        category: {},
        categoryId:'',

        //处方类型查询下拉框
        cftypeoptions:[
            {
                value:'',
                label:'全部'
            },{
                value:'0',
                label:'常规处方'
            },{
                value:'1',
                label:'专家处方'
            },{
                value:'2',
                label:'门店处方'
            },
        ],
        value:''
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1);
        this.getCategorys();
      }
      this.base_url=this.BASE_URL;
      this.navChange();
    },
    methods: {
      //格式化处方类别
      cftypeFormat(row){
        if(row.type==1){
          return '专家处方'
        }else if(row.type==0){
          return '常规处方'
        }else if(row.type==2){
          return '门店处方'
        }else{
          return ''
        }
      },
      //格式化发生程度
      cdFormat(row){
        if(row.happenLevel==0){
          return '轻发生'
        }else if(row.happenLevel==1){
          return '中等偏轻发生'
        }else if(row.happenLevel==2){
          return '中等发生'
        }else if(row.happenLevel==3){
          return '中等偏重'
        }else if(row.happenLevel==4){
          return '大发生'
        }else{
          return ''
        }
      },
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //获取作物分类
      getCategorys(){
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/cropCategory/queryCropCategoryTree')
          .then((response) => {
            Loadings.hide()
            let resp = response.data;
            for (let i = 0; i < resp.data.length; i++) {
              this.categorys.push(resp.data[i]);
            }
            for (let i = 0; i < this.categorys.length; i++) {
              for (let j = 0; j < this.categorys[i].childrenNode.length; j++) {
                if (this.categorys[i].childrenNode[j].childrenNode.length < 1) {
                  delete this.categorys[i].childrenNode[j].childrenNode;
                }
              }
            }
          })
      },
      //选中分类搜索
      categoryChange(value) {
        // let checkedNodes = this.$refs.categoryInput.getCheckedNodes()[0];
        // this.categoryId = checkedNodes.value;

        this.categoryId = value[1];
        this.getSearch(1);
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      getSearch (page,pageChange) {
        let current=page
        let size= this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        if(this.categoryId===0){
          this.categoryId='';
        }
        Loadings.show();
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/recipe/queryRecipePage?current='
          +current+"&size="+size+"&cropId="+this.categoryId+"&searchText="+this.searchInput+"&type="+this.value+"&tenantId="+JSON.parse(localStorage.getItem("tenant_info")).tenantId)
          .then((response) => {
            Loadings.hide()
            let resp = response.data
            this.goods = resp.data.records;
            this.$refs.pageHelper.render(page, resp.data.total)
          })
      },
      reSearch (data) {
        this.getSearch(1,data);
      },
      //新增处方
      addRecipel () {
        //let type = 0;
        this.$router.push({
          name: "/recipelManagerEdit",
          query: {}
        });
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      edit(item){
        let recipeId=item.recipeId;
        this.$router.push({
          name: "/recipelManagerEdit",
          query: {recipeId,type:item.type}
        });
      },
      //详情
      detail(item){
        let recipeId=item.recipeId;
        this.$router.push({
          name: "/recipelManagerDetail",
          params: {recipeId,type:item.type}
        });
      },
      //删除
      del(item){
        this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/psi/recipe/delete?recipeId=' + item.recipeId ,
            method: 'post',
          })
            .then((res) => {
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: '删除成功',
                    duration: 2000,
                    offset: 110
                  })
                  this.reSearch()
                } else {
                  this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: '网络异常',
                  duration: 2000,
                  offset: 110
                })
              }
            })
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 300px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }
  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }
  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }
  /deep/ .el-table__body-wrapper{
    min-height: 630px;
  }
  .categoryInput{
    float: left;
    margin-right: 10px;
  }
  /* 处方样式 */
  .cftype{
      float: left;
      margin-right: 10px;
  }
</style>
