<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">用户角色附权
          <i class="el-icon-user-solid"></i>
          <span>{{user.name}}</span>
        </div>
      </div>

      <div class="addStoreForm" >
        <div class="mainForm">
          <el-form :model="userRole" :rules="rules" ref="userRole" label-width="120px"
                   class="demo-user user-info-form">
            <el-form-item class="labelTitle" label="当前角色" prop="roleIds">
                <el-checkbox-group v-for="(item,index) in roles" :key="index" class="roleBox" v-model="userRole.roleIds" @change="roleChange">
                  <el-checkbox :label="item.id" border >{{item.roleName}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item class="labelTitle">
              <div class="showStopInfo">
                <div class="stopInfo">注意：新用户默认为店主级别角色。</div>
              </div>
            </el-form-item>
            <el-form-item class="storeInfoSub">
              <el-button class="userInfoBtn" type="danger" @click="save('userRole')">保存</el-button>
              <el-button class="resetBtn" @click="resetForm('userRole')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'UserRolePrivilege',
    props: {},
    data () {
      return {
        message: '用户角色附权',
        user:{},
        roleIds:[],
        userRole: {
          roleIds: [],
          roleName:'',
          id:'',
        },
        roles:[],
        checked1: true,
        checked2: false,
        centerDialogVisible: false,
        rules: {
          roleIds: [
            {
              required: true,
              message: '请选择用户角色',
              trigger: 'blur'
            }
          ],
        },
      }
    },
    mounted () {

    },
    methods: {
      dialogClose () {

      },
      openUserRP (userRole) {
        this.centerDialogVisible=true;
        this.user=userRole;
        this.userRole.id=userRole
        //_this.userRole.roleIds=_this.user.roleIds
        this.userRole=$.extend({},this.userRole)
        this.getSearch();
      },
      resetForm (formName) {
        this.$refs[formName].resetFields();
      },
      roleChange(item){
        console.log("选中的item：",item)
        this.userRole.roleIds=item
      },
      getSearch () {
        Loadings.show()
        this.$axios.get(process.env.VUE_APP_SERVER + '/blade-system/role/list').then((response) => {
          Loadings.hide()
          let resp = response.data;
          this.roles = resp.data;
        })
      },
      save (formName) {
        let _this = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log("当前userRole：",this.userRole)
            /*Loadings.show();
            this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-user/editPassword' +
                '?password=' + this.userRole.newPwd +
                '&rpassword=' + this.userRole.newPwdAgin,
              method: 'POST'
            })
              .then((res) => {
                Loadings.hide();
                if (res.status === 200) {
                  if (res.data.code === 200) {
                    this.$message({ type: 'success', message: '保存密码成功！', duration: 2000, offset: 110 });
                    this.centerDialogVisible = false;
                  } else {
                    this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
                  }
                } else {
                  this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
                }
              })*/
          }
        })
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
    max-width: 620px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }
  .header-title .title i{
    margin-left: 5px;
    margin-right: 5px;
    color: #f6e483;
  }
  .header-title .title span{
    display: block;
    float: right;
    margin-top: 19px;
    color: #f6e483;
    cursor: pointer;
    max-width: 400px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  /*搜索*/
  .showCheck {
    float: left;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    height: 40px;
    max-width: 585px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }
  .remark div {
    text-align: left;
  }

  /*店铺管理*/
  .addStoreForm {
  }

  .userInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -150px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 370px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 500px;
    float: left;
  }
  .showStopInfo{
    text-align: left;
    border: 1px solid #DCDFE6;
    border-radius: 5px;
    max-width: 370px;
    line-height: 20px;
    padding: 10px;
    margin-bottom: 10px;
    color: #9c9c9c;
    background-color: #eeeeee;
    cursor: pointer;
  }
  .roleBox{
    float: left;
    margin-right: 10px;
  }
  /deep/ .el-checkbox{
    margin-right: unset;
    margin-bottom: 10px;
  }
</style>
