<template>
  <div class="pageNav" aria-label="分页">

    <div class="pageChoose">
      <el-button type="primary" plain :disabled="page===1" @click="selectPage(1)">首页</el-button>
      <el-button type="primary" icon="el-icon-caret-left" circle
                 :disabled="page===1" @click="selectPage(page-1)"></el-button>
      <el-button class="pageNumber" :class="p===0?'pageNumbers':''" type="primary" plain v-for="p in pages" :key="p" @click="selectPage(p+1)">{{p+1}}
      </el-button>
      <el-button type="primary" icon="el-icon-caret-right" circle
                 :disabled="page===pageTotal" @click="selectPage(page+1)"></el-button>
      <el-button type="primary" plain :disabled="page===pageTotal" @click="selectPage(pageTotal)">尾页</el-button>
      <el-cascader v-if="showGo" class="sizeNum" placeholder="每页显示5行" :options="options" :show-all-levels="false"
                   filterable v-model="size" @change="handleChange"></el-cascader>
      <el-button class="totalCount" type="primary" plain>共{{totalSize}}条</el-button>
      <span>前往</span>
      <el-input class="goPageNum" v-model="input" placeholder="1" @change="inputValue"></el-input>
      <el-button v-model="input" id="goBtn" type="primary" plain @click="selectPage(input)">GO</el-button>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'PageHelper',
    //父组件传递的参数list函数
    props: {
      list: {
        type: Function,
        default: null
      },
      //显示的页码数，共50页，显示5页，其他用...表示
      itemCount: Number,
    },
    data () {
      return {
        message: '欢迎使用cevent分页',
        /*变量名与PageDto一致，和父组件传入的参数一直，否则造成NaN等解析异常
            当前页码:currentPage -->父组件page
            每页条数:size
            总条数(pageInfo处理的total属性为long)：totalSize
            查询记录放入list：listPageData
            自定义总页码:pageTotal
            自定义分页数组：pages
         */
        page: 0,
        size: 10,
        totalSize: 0,
        pageTotal: 0,
        pages: [],
        options: [{
          value: 5,
          label: '每页显示5行',
        }, {
          value: 10,
          label: '每页显示10行',
        }, {
          value: 20,
          label: '每页显示20行',
        }, {
          value: 50,
          label: '每页显示50行',
        }, {
          value: 100,
          label: '每页显示100行',
        }],
        input: null,
        showGo:true
      }
    }
    ,
    mounted () {
      //console.log(this.message)
    }
    ,
    methods: {
      handleChange (value) {
        this.size = value[0]
        this.$emit('pageSizeChange',this.size)
      },
      inputValue () {
        let goBtn = document.getElementById('goBtn')
      },
      /**
       * 渲染分页组件
       */
      render (page, totalSize,resize) {
        if(resize){
          this.size=resize;
          this.showGo=false;
        }
        this.page = parseInt(page) //当前页
        this.totalSize = totalSize //总条数 / 每页显示条数size
        this.pageTotal = Math.ceil(totalSize / this.size)
        let pageNums = $('.pageNumber')
        if (pageNums.length > 0) {
          let newPageIndex = 0

            for (let i = 0; i < pageNums.length; i++) {
              Tool.removeClass(pageNums[i], 'pageNumbers')
              if (pageNums[i].innerText === page) {
                //console.log('')
              }
            }

          for (let j = 0; j < pageNums.length; j++) {
            if (pageNums[j].innerText === page + '') {
              Tool.addClass(pageNums[j], 'pageNumbers')
            }
          }
        }
        //默认分页后显示的页码数：10
        this.pages = this.getPageItems(this.pageTotal, page, this.itemCount || 10)
      },
      //查询每一页
      selectPage (page) {
        let _this = this
        if (page < 1) {
          page = 1
        }
        //如果传入的当前页>总页码
        if (page > _this.pageTotal) {
          page = _this.pageTotal
        }
        if (this.page !== page) {
          _this.page = page
          if (_this.list) {
            _this.list(page)
          }
        }
        this.$emit("rePage",page);
        let pageNums = $('.pageNumber')
        for (let i = 0; i < pageNums.length; i++) {
          Tool.removeClass(pageNums[i], 'pageNumbers')
          if (pageNums[i].innerText === page + '') {
            Tool.addClass(pageNums[i], 'pageNumbers')
          }
        }
      },
      //当前显示的页码
      getPageItems (totalSize, current, length) {
        let items = []
        if (length >= totalSize) {
          for (let i = 0; i < totalSize; i++) {
            //总条数放入items，遍历页码
            items.push(i)
          }
        } else {
          let base = 0
          //前移:向下取整
          if (current - 0 > Math.floor((length - 1) / 2)) {
            //后移：向上取整
            base = Math.min(totalSize, current - 0 + Math.ceil((length - 1) / 2)) - length
          }
          //条数集合
          for (let i = 0; i < length; i++) {
            items.push(base + i)
          }
        }
        return items
      },
      //3.添加class
      addClass (node, className) {
        let reg = new RegExp('\\b' + className + '\\b')
        if (!reg.test(node.className)) {
          node.className += (' ' + className)
        }
      },

      //4.移除class
      removeClass (node, className) {
        if (node.className) {
          let reg = new RegExp('\\b' + className + '\\b')
          let classes = node.className
          node.className = classes.replace(reg, '')
          if (/^\s*$/g.test(node.className)) {
            node.removeAttribute('class')
          }
        } else {
          node.removeAttribute('class')
        }
      }
    }
  }
</script>

<style scoped>
  .pageNav {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .pageChoose {
    margin-top: 20px;
  }

  .totalCount {
    margin-right: 10px;
  }

  .pageSize {

    margin-top: 5px;
  }

  .sizeNum {
    margin: 0 5px;
    max-width: 150px;
  }

  .goPage {
    margin: 5px 0 5px 5px;
    color: #409EFF;
  }

  .goPageNum {
    width: 60px;
    margin: 0 10px;

  }

  /*跳转页*/
  .pageNumbers {
    background: #409EFF !important;
    border-color: #409EFF !important;
    color: #FFF !important;
  }
</style>
