import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login'
import MdjLogin from '../views/login/mdjLogin'
import Admin from '../views/admin/Admin'
import Index from '../views/admin/index/Index'
//用户协议
import Yonghuxieyi from '../views/register/Yonghuxieyi'
//进货管理
import StockOrder from '../views/admin/stock/StockOrder'
import StockOrderList from '../views/admin/stock/StockOrderList'
import StockOrderReturn from '../views/admin/stock/StockOrderReturn'
import StockOrderReturnList from '../views/admin/stock/StockOrderReturnList'
//销货管理
import SaleOrder from '../views/admin/sale/SaleOrder'
import SaleOrderList from '../views/admin/sale/SaleOrderList'
import SaleOrderReturn from '../views/admin/sale/SaleOrderReturn'
import SaleOrderReturnList from '../views/admin/sale/SaleOrderReturnList'
//包装回收管理
import RecycleOrder from '../views/admin/recycle/RecycleOrder'
import RecycleOrderList from '../views/admin/recycle/RecycleOrderList'
import RecycleOrderReturn from '../views/admin/recycle/RecycleOrderReturn'
import RecycleOrderReturnList from '../views/admin/recycle/RecycleOrderReturnList'
//基础管理
import GoodsManager from '../views/admin/manager/GoodsManager'
import GoodsManagerEdit from '../views/admin/manager/GoodsManagerEdit'
import ClientManager from '../views/admin/manager/ClientManager'
import ClientManagerEdit from '../views/admin/manager/ClientManagerEdit'
import ClientManagerSaleDetail from '../views/admin/manager/ClientManagerSaleDetail'
import SupplierManager from '../views/admin/manager/SupplierManager'
import SupplierManagerEdit from '../views/admin/manager/SupplierManagerEdit'
import SupplierManagerSaleDetail from '../views/admin/manager/SupplierManagerSaleDetail'
import RecipelManager from '../views/admin/manager/RecipelManager'
import RecipelManagerEdit from '../views/admin/manager/RecipelManagerEdit'
import RecipelManagerDetail from '../views/admin/manager/RecipelManagerDetail'
//系统管理
import UserManager from '../views/admin/system/UserManager'
import PrivilegeManager from '../views/admin/system/PrivilegeManager'
import PrivilegeManagerEdit from '../views/admin/system/PrivilegeManagerEdit'
import UserRolesManagerEdit from '../views/admin/system/UserRolesManagerEdit'
import UserRolesManager from '../views/admin/system/UserRolesManager'
import RoleManager from '../views/admin/system/RoleManager'
import RoleManagerEdit from '../views/admin/system/RoleManagerEdit'
import ResourceManager from '../views/admin/system/ResourceManager'

//实名认证
import IdentityScan from '../views/admin/identity/IdentityScan'
import IdentityAuthentication from '../views/admin/identity/IdentityAuthentication'

//上报
import StockReport from '../views/admin/report/StockReport'
import SaleReport from '../views/admin/report/SaleReport'
import InventoryReport from '../views/admin/report/InventoryReport'
import AutoGenerate from '../views/admin/report/AutoGenerate'
//导入
import ImporttData from '../views/admin/import/importData'
import importUpdate from '../views/admin/import/importUpdate'
Vue.use(VueRouter)

//解决重复点击router刷新问题
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/mudanjianglogin',
    name: 'mdjlogin',
    component: MdjLogin
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register/yonghuxieyi',
    name: 'yonghuxieyi',
    component: Yonghuxieyi
  },
  {
    path: '/identityScan',
    name: 'identityScan',
    meta:{title:'认证中心'},
    component: IdentityScan
  },
  {
    path: '/identityAuthentication',
    name: 'identityAuthentication',
    meta:{title:'认证中心'},
    component: IdentityAuthentication
  },
  {
    path: '/',
    name: 'admin',
    component: Admin,
    children:[
      {
        path:'admin/index',
        name:'/index',
        component: Index,
      },

      //进货
      {
        path:'admin/stockOrders',
        name:'/stockOrders',
        component: StockOrder,
      },
      {
        path:'admin/stockOrderList',
        name:'/stockOrderList',
        component: StockOrderList,
      },
      {
        path:'admin/stockOrderReturns',
        name:'/stockOrderReturns',
        component: StockOrderReturn,
      },
      {
        path:'admin/stockOrderReturnList',
        name:'/stockOrderReturnList',
        component: StockOrderReturnList,
      },
      //销货
      {
        path:'admin/saleOrders',
        name:'/saleOrders',
        component: SaleOrder,
      },
      {
        path:'admin/saleOrderList',
        name:'/saleOrderList',
        component: SaleOrderList,
      },
      {
        path:'admin/saleOrderReturns',
        name:'/saleOrderReturns',
        component: SaleOrderReturn,
      },
      {
        path:'admin/saleOrderReturnList',
        name:'/saleOrderReturnList',
        component: SaleOrderReturnList,
      },
      //包装回收
      {
        path:'admin/recycleOrders',
        name:'/recycleOrders',
        component: RecycleOrder,
      },
      {
        path:'admin/recycleOrderList',
        name:'/recycleOrderList',
        component: RecycleOrderList,
      },
      {
        path:'admin/recycleOrderReturns',
        name:'/recycleOrderReturns',
        component: RecycleOrderReturn,
      },
      {
        path:'admin/recycleOrderReturnList',
        name:'/recycleOrderReturnList',
        component: RecycleOrderReturnList,
      },
      //基础管理
      {
        path:'admin/goodsManager',
        name:'/goodsManager',
        component: GoodsManager,
      },
      {
        path:'admin/goodsManagerEdit',
        name:'/goodsManagerEdit',
        component: GoodsManagerEdit,
      },
      {
        path:'admin/clientManager',
        name:'/clientManager',
        component: ClientManager,
      },
      {
        path:'admin/clientManagerEdit',
        name:'/clientManagerEdit',
        component: ClientManagerEdit,
      },
      {
        path:'admin/clientManagerSaleDetail',
        name:'/clientManagerSaleDetail',
        component: ClientManagerSaleDetail,
      },
      {
        path:'admin/supplierManager',
        name:'/supplierManager',
        component: SupplierManager,
      },
      {
        path:'admin/supplierManagerEdit',
        name:'/supplierManagerEdit',
        component: SupplierManagerEdit,
      },
      {
        path:'admin/supplierManagerSaleDetail',
        name:'/supplierManagerSaleDetail',
        component: SupplierManagerSaleDetail,
      },
      {
        path:'admin/recipelManager',
        name:'/recipelManager',
        component: RecipelManager,
      },
      {
        path:'admin/recipelManagerEdit',
        name:'/recipelManagerEdit',
        component: RecipelManagerEdit,
      },
      {
        path:'admin/recipelManagerDetail',
        name:'/recipelManagerDetail',
        component: RecipelManagerDetail,
      },
      //系统管理
      {
        path:'admin/userManager',
        name:'/userManager',
        component: UserManager,
      },
      {
        path:'admin/privilegeManager',
        name:'/privilegeManager',
        component: PrivilegeManager,
      },
      {
        path:'admin/privilegeManagerEdit',
        name:'/privilegeManagerEdit',
        component: PrivilegeManagerEdit,
      },
      {
        path:'admin/userRolesManagerEdit',
        name:'/userRolesManagerEdit',
        component: UserRolesManagerEdit,
      },
      {
        path:'admin/userRolesManager',
        name:'/userRolesManager',
        component: UserRolesManager,
      },
      {
        path:'admin/roleManager',
        name:'/roleManager',
        component: RoleManager,
      },
      {
        path:'admin/roleManagerEdit',
        name:'/roleManagerEdit',
        component: RoleManagerEdit,
      },
      {
        path:'admin/resourceManager',
        name:'/resourceManager',
        component: ResourceManager,
      },
      //上报
      {
        path:'admin/stockReport',
        name:'/stockReport',
        component: StockReport,
      },
      {
        path:'admin/saleReport',
        name:'/saleReport',
        component: SaleReport,
      },
      {
        path:'admin/inventoryReport',
        name:'/inventoryReport',
        component: InventoryReport,
      },
      {
        path:'admin/autoGenerate',
        name:'/autoGenerate',
        component: AutoGenerate,
      },
      //导入
      {
        path:'admin/importData',
        name:'/importData',
        component: ImporttData,
      },
      {
        path:'admin/importEdit',
        name:'/importEdit',
        component: importUpdate,
      }
    ]
  },
  {
    path: '*',
    redirect: "/login"
  },
]

const router = new VueRouter({
  mode:'history',
  //vue-router内置变量路径，指向public目录
  base:process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  if(process.env.VUE_APP_SERVER=='https://nongyao.zftdata.com' || process.env.VUE_APP_SERVER=='https://jxccs.weituodata.com:81' || document.title=='牡丹江市慧台账电脑版'){
  //if(process.env.VUE_APP_SERVER=='https://nongyao.zftdata.com' || document.title=='牡丹江市慧台账电脑版'){
    if(to.path.indexOf('identityScan')!==-1 || to.path.indexOf('identityAuthentication')!==-1){
      document.title=to.meta.title
    }else if(document.title!='牡丹江市慧台账电脑版'){
      document.title='慧台账电脑版'
    }
    next()
  }else if(to.path.indexOf('mudanjianglogin')!=-1 || from.path.indexOf('mudanjianglogin')!=-1){
    next()
  }else{
    document.title='牡丹江市慧台账电脑版'
    next('/mudanjianglogin')
  }


})
export default router
