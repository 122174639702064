<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="输入用户姓名"
                  v-model="realNameInput"
                  :blur="realNameInputChange(realNameInput)"
                  :clearable="true">
        </el-input>
        <el-input class="searchInput"
                  placeholder="输入用户手机号"
                  v-model="phoneInput"
                  :blur="phoneInputChange(phoneInput)"
                  :clearable="true">
        </el-input>
        <el-input class="searchInput"
                  placeholder="输入店铺名称"
                  v-model="tenantNameInput"
                  :blur="tenantNameInputChange(tenantNameInput)"
                  :clearable="true">
        </el-input>
        <el-cascader
          ref="regionCascader"
          class="checkArea"
          size="large"
          :clearable="true"
          v-model="parentRegionCode"
          :props="props"
          @change="handlerChange">
        </el-cascader>
        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>

        <div class="checkStop" >
          <template>
            <el-radio-group v-model="userStatus" @change="statusChange">
              <el-radio :label="USER_STATUS.OPEN.key">显示{{USER_STATUS.OPEN.value}}</el-radio>
              <el-radio :label="USER_STATUS.CLOSE.key">显示{{USER_STATUS.CLOSE.value}}</el-radio>
              <el-radio :label="USER_STATUS.EXPIRE.key">显示{{USER_STATUS.EXPIRE.value}}</el-radio>
            </el-radio-group>
          </template>

        </div>
        <!--<el-radio class="showDel" :label="1" v-model="isDel" @change="showDel">显示已删除</el-radio>-->
      </div>
      <div class="navRight">
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="reSearch" circle></el-button>
        <el-button class="exportBtn" type="primary" @click="exportData()">导出</el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="users"
        border
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
        <el-table-column property="realName" label="用户姓名" width="110px" sortable :show-overflow-tooltip="true">
          <!--<template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom"
                        @mouseenter.native="remarkEnter(scope.row.realName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.realName===''?'-':scope.row.realName}}
              </div>
              <span class="showTipLines">{{scope.row.realName===''?'-':scope.row.realName}}</span>
            </el-tooltip>
            <span class="clientIdShow">{{scope.row.userId}}</span>
          </template>-->

        </el-table-column>
        <el-table-column property="tenantName" label="店铺名称" width="280px"  sortable :show-overflow-tooltip="true">
          <!--<template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom"
                        @mouseenter.native="remarkEnter(scope.row.tenantName)" :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.tenantName===''?'-':scope.row.tenantName}}
              </div>
              <span class="showTipLines">{{scope.row.tenantName===''?'-':scope.row.tenantName}}</span>
            </el-tooltip>
            <span class="clientIdShow">{{scope.row.userId}}</span>
          </template>-->

        </el-table-column>
        <el-table-column property="creditCode" label="统一社会信用代码" width="180px" sortable>
          <template slot-scope="scope">
            {{scope.row.creditCode===''?'-':scope.row.creditCode}}
          </template>
        </el-table-column>
        <el-table-column property="phone" label="手机号" width="150px" sortable>
          <template slot-scope="scope">
            {{scope.row.phone===''?'-':scope.row.phone}}
          </template>
        </el-table-column>
        <el-table-column property="regionName" label="区域名称" width="240">
          <template slot-scope="scope">
            <el-tooltip class="toolTipWidthPlugin" placement="bottom" @mouseenter.native="remarkEnter(scope.row.regionName)"
                        :disabled="!remarkFlag">
              <div class="toolTipWidth" slot="content" v-show="remarkFlag">
                {{scope.row.regionName===''?'-':scope.row.regionName}}
              </div>
              <el-tag type="info" class="showTipLinePlugin">{{scope.row.regionName===''?'-':scope.row.regionName}}</el-tag>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column property="createTime" label="注册时间" width="200">
          <template slot-scope="scope">
            <el-tag type="primary" class="showCenter"
                    id="createTime">{{scope.row.createTime===''?'-':scope.row.createTime}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="deadline" label="到期时间" width="200">
          <template slot-scope="scope">
            <el-tag :type="scope.row.deadline === '' ? 'warning':scope.row.deadline<currentTime?'danger':'success'" class="showCenter"
                    id="deadline">{{scope.row.deadline===''?'-':scope.row.deadline}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="openStatus" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.openStatus === 2 ? 'success':'danger'" class="showCenter"
                    id="openStatus">{{USER_STATUS | optionObjectKV(scope.row.openStatus)}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="printEnable" label="是否允许打印" width="130" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.printEnable === 'Y' ? 'success':'danger'" class="showCenter" id="printEnable">
              {{ PRINT_ENABLE | optionObjectKV(scope.row.printEnable) }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="540">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <!--
            <el-button
              size="mini"
              type="warning"
              @click="editPwd(scope.row)">修改密码
            </el-button>-->

            <el-button
              size="mini"
              type="info"
              @click="editShop(scope.row)">店鋪信息
            </el-button>

            <!--开通状态（1 未开通 2 已开通 3 已到期）-->
            <el-button v-if="getAuthorityMenu('600101')"
              size="mini"
              :type="scope.row.openStatus===2?'success':scope.row.openStatus===1?'warning':'danger'"
              @click="editPrivilege(scope.row)">{{scope.row.openStatus===1?'开通权限':scope.row.openStatus===2?'续费':'去缴费'}}
            </el-button>

            <el-button v-if="scope.row.printEnable == 'Y'" size="mini" type="danger" @click="closePrint(scope.row)">
              关闭打印
            </el-button>
            <el-button v-else size="mini" type="success" @click="printEnable(scope.row)">
              开启打印
            </el-button>

            <el-button v-if="getAuthorityMenu('600102')"
              size="mini"
              type="primary"
              @click="payDetail(scope.row)">缴费记录
            </el-button>
            <el-button v-if="getAuthorityMenu('600103')"
              size="mini"
              type="danger"
              @click="del(scope.row)">删除店铺
            </el-button>
            <el-button
              size="mini"
              type="info" plain
              @click="addRemark(scope.row)">备注
            </el-button>
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>

    <user-role-privilege ref="userRolePrivilege"></user-role-privilege>
    <user-privilege ref="userPrivilege" @getSearch="getSearch"></user-privilege>
    <user-pay-detail ref="userPayDetail"></user-pay-detail>
    <user-manager-remark ref="userManagerRemark" @getSearch="getSearch"></user-manager-remark>

    <store-manager ref="storeManager" @saveStore="saveStore"></store-manager>

  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import UserRolePrivilege from '../../../components/UserRolePrivilege'
  import UserPrivilege from '../../../components/UserPrivilege'
  import UserPayDetail from '../../../components/UserPayDetail'
  import UserManagerRemark from '../../../components/UserManagerRemark'

  import StoreManager from '../../../components/StoreManager'

  export default {
    name: 'UserManager',
    components: {
      PageHelper,
      UserRolePrivilege,
      UserPrivilege,
      UserPayDetail,
      UserManagerRemark,
      StoreManager
    },
    data () {
      return {
        message: '店铺权限管理',
        user: {},
        users: [],
        realNameInput: '',
        phoneInput:'',
        tenantNameInput:'',
        regionCode:'',
        checkStatus: false,//默认不显示停用供应商
        USER_STATUS: USER_STATUS,
        PRINT_ENABLE: PRINT_ENABLE,
        userStatus:"",
        currentRow: null,
        remarkFlag: false,
        parentRegionCode: '',
        props: {
          lazy: true,
          lazyLoad: this.lazyLoad,
          value: 'code',
          label: 'name',
          checkStrictly: true,
          expandTrigger: 'hover', //鼠标移上触发加载下一级
        },
        isDel:0,
        currentTime:new Date(),
        currentPage:1,
        currentSize:10,
      }
    },
    mounted () {
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1)
      }

      setTimeout(function () {
        if (document.getElementsByClassName('swal2-container')[0]) {
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display = 'none'
        }
      }, 50)
      this.navChange()
      this.currentTime=this.$moment(this.currentTime).format('YYYY-MM-DD HH:mm:ss')
    },
    methods: {
      navChange () {
        let flag = true
        this.$emit('navChange', flag)
      },
      //1.搜索
      realNameInputChange (searchInput) {
        //console.log('姓名搜索内容: ', searchInput);
      },
      phoneInputChange (searchInput) {
        //console.log('电话搜索内容: ', searchInput);
      },
      tenantNameInputChange (searchInput) {
        //console.log('店铺名称搜索内容: ', searchInput);
      },
      statusChange(e){
        //console.log("当前选中：",e)
        this.userStatus=e;
        this.getSearch(1)
      },
      getSearch (page, pageChange) {
        //Loadings.show()
        let current = page
        let size = this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        this.currentPage=page
        this.currentSize=this.$refs.pageHelper.size

        let showStop = ""
        if (this.userStatus === 2) {
          showStop = 2
        } else if(this.userStatus === 3){
          showStop = 3
        }else if(this.userStatus === 1){
          showStop = 1
        }
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/paylog/queryTenantPayInfo?current=' + current + '&size=' + size
          + '&realName=' + this.realNameInput + '&phone=' + this.phoneInput + '&tenantName=' + this.tenantNameInput
          + '&openStatus=' + showStop + '&regionCode=' + this.regionCode).then((response) => {
          //Loadings.hide()

          let resp = response.data
          this.users = resp.data.records
          this.$refs.pageHelper.render(page, resp.data.total)
        })
      },
      exportData(){
        let showStop = ""
        if (this.userStatus === 2) {
          showStop = 2
        } else if(this.userStatus === 3){
          showStop = 3
        }else if(this.userStatus === 1){
          showStop = 1
        }
        // window.location.href = process.env.VUE_APP_SERVER + "/psi/paylog/export?current=" + this.currentPage + '&size=' + this.currentSize
        //   + '&realName=' + this.realNameInput + '&phone=' + this.phoneInput + '&tenantName=' + this.tenantNameInput
        //   + '&openStatus=' + showStop + '&regionCode=' + this.regionCode

        let consturl=process.env.VUE_APP_SERVER + "/psi/paylog/export?current=" + this.currentPage + '&size=' + this.currentSize
          + '&realName=' + this.realNameInput + '&phone=' + this.phoneInput + '&tenantName=' + this.tenantNameInput
          + '&openStatus=' + showStop + '&regionCode=' + this.regionCode
        var xhr = new XMLHttpRequest();
        xhr.open("get", consturl, true) // get、post都可
        xhr.responseType = "blob"
        xhr.setRequestHeader('blade-auth', 'bearer ' + LocalStorage.get(TOKEN)) //加请求头
        xhr.onload = function() {
          if(xhr.status==200){
            let blob = new Blob([this.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            var a = document.createElement("a")
            var url = window.URL.createObjectURL(blob)
            a.href = url
            var farmname='店铺缴费.xlsx'
            a.download = farmname  // 文件名
          }
          a.click()
          window.URL.revokeObjectURL(url)
        }
        xhr.send();

      },
      reSearch (data) {
        this.isDel=0
        this.userStatus=""
        this.getSearch(1, data)
      },
      //2.显示停用客户
      checkStatusClick () {
        //console.log('当前的checkStatus: ', this.checkStatus)
        this.getSearch(1)
      },
      //3.新增供应商
      addUser () {
        let type = 0
        this.$router.push({
          name: '/userManagerEdit',
          params: { type }
        })
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //4.编辑
      del (item) {
        let _this = this
        let user = this.nameChange(item)

        this.$toast.showConfirm(item.tenantName, function () {
          Loadings.show()
          _this.$axios.request({
            url: process.env.VUE_APP_SERVER + '/blade-system/tenant/remove?ids='+item.id,
            method: 'post'
          }).then((res) => {
              Loadings.hide()
              if (res.status === 200) {
                if (res.data.code != 200) {
                  _this.$message({
                    type: 'warning',
                    message: res.data.msg,
                    duration: 2000,
                    offset: 110
                  })
                } else {
                  if (res.status === 200) {
                    if (res.data.code === 200) {
                      _this.$message({
                        type: 'success',
                        message: '删除店铺成功！',
                        duration: 2000,
                        offset: 110
                      })
                      //跳转到客户列表页面
                      _this.getSearch(1)
                    }
                  }
                }
              } else {
                _this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            }
          )
        })

      },
      editShop(item){
        console.log("编辑店铺信息：",item)
        this.$refs.storeManager.openStore(item.id)
      },
      saveStore(){
        console.log("保存店铺信息: ")
        this.isDel=0
        this.userStatus=""
        this.getSearch(1)
      },
      //编辑权限
      editPrivilege (item) {
        console.log("编辑权限")
        let _this = this
        let user = this.nameChange(item)
        _this.$refs.userPrivilege.openUserPrivilege(user)
      },
      //允许打印
      printEnable(row) {
        let _this = this;
        _this.$Swal.fire({
          title: '确定要开启 &nbsp;<span style="color: #e92322;display: inline-block;margin-top: 10px">' + row.tenantName + '</span>&nbsp; 的打印功能吗? ',
          text: '请确认是否开启打印功能！',
          showDenyButton: true,
          confirmButtonText: `确定`,
          confirmButtonColor: '#e92322',
          denyButtonText: `取消`,
          denyButtonColor: '#3085d6',
        }).then((result) => {
          if (result.isConfirmed) {
            _this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-system/tenant/printEnable?id=' + row.id + '&printEnable=Y',
              method: 'POST'
            }).then((res) => {

              _this.getSearch(_this.$refs.pageHelper.page);

              if (res.data.code == 200 && res.data.data) {
                _this.$Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title:confirmParams,
                  confirmButtonText: `操作完成`,
                  confirmButtonColor: '#67C23A',
                  timer: 1500
                });
              }
            });
          }
        });
      },
      //关闭打印
      closePrint(row) {
        let _this = this;
        _this.$Swal.fire({
          title: '确定要关闭 &nbsp;<span style="color: #e92322;display: inline-block;margin-top: 10px">' + row.tenantName + '</span>&nbsp; 的打印功能吗? ',
          text: '请确认是否要关闭打印功能！',
          showDenyButton: true,
          confirmButtonText: `确定`,
          confirmButtonColor: '#e92322',
          denyButtonText: `取消`,
          denyButtonColor: '#3085d6',
        }).then((result) => {
          if (result.isConfirmed) {
            _this.$axios.request({
              url: process.env.VUE_APP_SERVER + '/blade-system/tenant/printEnable?id=' + row.id + '&printEnable=N',
              method: 'POST'
            }).then((res) => {

              _this.getSearch(_this.$refs.pageHelper.page);

              if (res.data.code == 200 && res.data.data) {
                _this.$Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title:confirmParams,
                  confirmButtonText: `操作完成`,
                  confirmButtonColor: '#67C23A',
                  timer: 1500
                });
              }
            });
          }
        });
      },
      nameChange (item) {
        let name = item.realName
        if (Tool.isEmpty(item.realName)) {
          name = item.clientPerson
        }
        if (Tool.isEmpty(name)) {
          name = '-'
        }
        item.name = name
        return item
      },
      //5.往来明细
      payDetail (item) {
        let _this = this
        let user = this.nameChange(item)
        _this.$refs.userPayDetail.openUserPayDetail(user)
      },
      showDel(e){
        console.log("显示已删除：",e)
      },
      remarkEnter (remark) {
        if (remark.length > 9) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      remarkEnters (remark) {
        if (remark.length > 15) {
          this.remarkFlag = true
        } else {
          this.remarkFlag = false
        }
      },
      handlerChange (value) {
        let provCode, cityCode, distCode, townCode, villageCode
        for (let i = 0; i < value.length; i++) {
          if (i === 0) {
            provCode = value[i]
          } else if (i === 1) {
            cityCode = value[i]
          } else if (i === 2) {
            distCode = value[i]
          } else if (i === 3) {
            townCode = value[i]
          } else if (i === 4) {
            villageCode = value[i]
          }
        }
        if (provCode) {
          if (cityCode) {
            let store = this.$refs.regionCascader.$refs.panel.store
            //console.log("store：", store);
            //根据provCode获取指定列表项对象
            let provNode = store.nodes.find(item => item.value === provCode)

            //调用panel的方法，模拟点击省级列表项的操作
            this.$refs.regionCascader.$refs.panel.handleExpand(provNode)

            let cityNode = provNode.children.find(item => item.value === cityCode)

            if (distCode) {
              //调用panel的方法，模拟点击市级列表项的操作
              this.$refs.regionCascader.$refs.panel.handleExpand(cityNode)
              let distNode = cityNode.children.find(item => item.value === distCode)
              if (townCode) {
                //调用panel的方法，模拟点击区县级列表项的操作
                this.$refs.regionCascader.$refs.panel.handleExpand(distNode)

                let townNode = distNode.children.find(item => item.value === townCode)

                if (villageCode) {
                  //调用panel的方法，模拟点击街道级列表项的操作
                  this.$refs.regionCascader.$refs.panel.handleExpand(townNode)

                }
              }
            }
          }

          //调用组件的方法，将文字回显出来（省/市/区）
          this.$refs.regionCascader.computePresentText()
        }

        //选中后隐藏下拉面板
        this.$refs.regionCascader.dropDownVisible = false

        if (value.length > 0) {
          this.regionCode = value[value.length - 1]
        } else {
          this.regionCode = ''
        }
      },
      lazyLoad (node, resolve) {
        let _this = this
        let parent_code = '00'
        if (node && node.data) {
          parent_code = node.data.code
        }
        _this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/blade-system/region/select?code=' + parent_code,
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                if (res.data.data && res.data.data.length > 0) {
                  const nodes = res.data.data.map(item => ({
                    name: item.name,
                    code: item.code,
                    leaf: item.level === 3 ? true : false //是否是最后一级
                  }))
                  resolve(nodes)
                } else {
                  resolve([])
                }
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      addRemark(item){
        console.log("修改备注：",item)
        this.$refs.userManagerRemark.openStorePrivilege(item)
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
    }
  }
</script>

<style scoped>

  .topNav {
    width: 100%;
    display: inline-block;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 170px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .checkArea {
    float: left;
    margin-right: 10px;
  }

  .checkStop {
    float: left;
    margin-right: 5px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 5px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 20px;
  }
  .exportBtn{
    margin-right: 20px;
  }
  .mainForm {

  }

  /deep/ .el-table .el-table__cell:first-of-type {
    line-height: 30px !important;
  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 630px;
  }

  /*提示*/
  .clientIdShow {
    display: none;
  }

  .toolTipWidth {
    max-width: 300px !important;
    text-align: center !important;
  }

  .toolTipWidthPlugin {
    padding: 0 0 20px;
    float: left;
    /*text-align: center;*/
  }

  .showTipLine {
    line-height: 36px;
    height: 36px;
    width: 150px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showTipLines {
    line-height: 36px;
    height: 36px;
    width: 160px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .showTipLinePlugin {
    line-height: 36px;
    height: 36px;
    width: 200px !important;
    text-align: center;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  /deep/ .el-radio{
    margin-right: 10px;
  }
  .showDel{
    margin-left: 10px;
    line-height: 35px;
  }
</style>
