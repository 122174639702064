<template>
  <div>
    <el-dialog width="65%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">更新购物车商品</div>
        <el-link class="supplierName" type="info" :underline="false" icon="el-icon-d-arrow-right">客户（{{clientName}}）
        </el-link>
      </div>
      <div class="orderNav">
        <el-form :model="good" :rules="rules" ref="good" label-width="120px" class="demo-good store-info-form">
          <el-row :gutter="20" class="rowForm">
            <el-col :span="12">
              <el-form-item class="labelTitle" label="包装分类" prop="bigPackageId">
                <div class="showPackage">{{RECYCLE_CATEGORY | optionObjectKV(good.bigPackageId)}}</div>
              </el-form-item>
              <el-form-item class="labelTitle" label="包装规格" prop="packingSpecification">
                <div class="showPackageDesc" v-if="good.bigPackageId===1 || good.bigPackageId===2">
                  {{good.bigPackageId===1?'农药：':good.bigPackageId===2?'肥料：':good.bigPackageId===3?'农膜：':'其他：'}}
                  {{VESSEL_TYPE | optionObjectKV(good.vesselPackageId)}}{{good.vesselPackageId===1?'':'+'}}
                  {{VESSEL_TYPE | optionObjectKV(good.texturePackageId)}}{{good.texturePackageId===1?'':'+'}}
                  {{VESSEL_TYPE | optionObjectKV(good.sizePackageId)}}
                </div>
                <div class="showPackageDesc" v-if="good.bigPackageId===3 ">
                  {{good.bigPackageId===3?'农膜：':''}}
                  {{VESSEL_TYPE | optionObjectKV(good.vesselPackageId)}}
                </div>
                <div class="showPackageDesc" v-if="good.bigPackageId===4 ">
                  {{good.bigPackageId===4?'其他：':''}}
                  无
                </div>
              </el-form-item>

              <el-form-item v-if="type===1" class="labelTitle" label="回收单价" prop="recoveryPrice">
                <div class="unitLine">
                  <el-input-number @change="priceChange" :precision="2" :step="0.1" :min="0.00" :max="999999.99"
                                   class="packageUnitLine" v-model="good.recoveryPrice"
                                   placeholder="请输入回收单价"></el-input-number>
                  <div class="money">元</div>
                  <div class="slash">/</div>
                  <el-select class="unitSelectRight" v-model="good.pricePackageUnitId" placeholder="请选择"
                             @change="priceUnitChange">
                    <el-option
                      v-for="item in moneyUnits"
                      :key="item.packageUnitId"
                      :label="item.packageUnitName"
                      :value="item.packageUnitId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item v-if="type===2" class="labelTitle" label="处置单价" prop="disposePrice">
                <div class="unitLine">
                  <el-input-number @change="priceChange" :precision="2" :step="0.1" :min="0.00" :max="999999.99"
                                   class="packageUnitLine" v-model="good.disposePrice"
                                   placeholder="请输入回收单价"></el-input-number>
                  <div class="money">元</div>
                  <div class="slash">/</div>
                  <el-select class="unitSelectRight" v-model="good.pricePackageUnitId" placeholder="请选择"
                             @change="priceUnitChange">
                    <el-option
                      v-for="item in moneyUnits"
                      :key="item.packageUnitId"
                      :label="item.packageUnitName"
                      :value="item.packageUnitId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item v-if="type===1" class="labelTitle numberError" label="回收数量" prop="recoveryNumber">
                <div class="unitLine">
                  <el-input-number @change="amountChange" :min="1" :max="9999" class="packageUnitLine"
                                   v-model="good.recoveryNumber" placeholder="请输入回收数量"></el-input-number>
                  <el-select class="unitSelectRight" v-model="good.numberPackageUnitId" placeholder="请选择"
                             @change="numberUnitChange">
                    <el-option
                      v-for="item in amountUnits"
                      :key="item.packageUnitId"
                      :label="item.packageUnitName"
                      :value="item.packageUnitId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item v-if="type===2" class="labelTitle numberError" label="处置数量" prop="disposeNumber">
                <div class="unitLine">
                  <el-input-number @change="amountChange" :min="1" :max="9999" class="packageUnitLine"
                                   v-model="good.disposeNumber" placeholder="请输入回收数量"></el-input-number>
                  <el-select class="unitSelectRight" v-model="good.numberPackageUnitId" placeholder="请选择"
                             @change="numberUnitChange">
                    <el-option
                      v-for="item in amountUnits"
                      :key="item.packageUnitId"
                      :label="item.packageUnitName"
                      :value="item.packageUnitId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item v-if="type===1" class="labelTitle weightError" label="回收重量" prop="recoveryWeight">
                <div class="unitLine">
                  <el-input-number @change="weightChange" :precision="2" :step="0.1" :min="0.00" :max="9999.99"
                                   class="packageUnitLine"
                                   v-model="good.recoveryWeight" placeholder="请输入回收重量"></el-input-number>
                  <el-select class="unitSelectRight" v-model="good.weightPackageUnitId" placeholder="请选择"
                             @change="weightUnitChange">
                    <el-option
                      v-for="item in weightUnits"
                      :key="item.packageUnitId"
                      :label="item.packageUnitName"
                      :value="item.packageUnitId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>

              <el-form-item v-if="type===2" class="labelTitle weightError" label="处置重量" prop="disposeWeight">
                <div class="unitLine">
                  <el-input-number @change="weightChange" :precision="2" :step="0.1" :min="0.0" :max="9999.99"
                                   class="packageUnitLine"
                                   v-model="good.disposeWeight" placeholder="请输入回收重量"></el-input-number>
                  <el-select class="unitSelectRight" v-model="good.weightPackageUnitId" placeholder="请选择"
                             @change="weightUnitChange">
                    <el-option
                      v-for="item in weightUnits"
                      :key="item.packageUnitId"
                      :label="item.packageUnitName"
                      :value="item.packageUnitId">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>

              <el-form-item v-if="type===1" class="labelTitle" label="回收支付金额" prop="recoveryMoney">
                <div class="unitLine">
                  <el-input-number @change="payChange" :precision="2" :step="0.1" :min="0.00" :max="9999999999.99"
                                   class="packageUnitLine" v-model="good.recoveryMoney"
                                   placeholder="请输入支付金额"></el-input-number>
                  <div class="money">元</div>
                </div>
              </el-form-item>
              <el-form-item v-if="type===2" class="labelTitle" label="处置支付金额" prop="disposeMoney">
                <div class="unitLine">
                  <el-input-number @change="payChange" :precision="2" :step="0.1" :min="0.00" :max="9999999999.99"
                                   class="packageUnitLine" v-model="good.disposeMoney"
                                   placeholder="请输入支付金额"></el-input-number>
                  <div class="money">元</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item class="labelTitle" label="包装分类" prop="goodPhone" v-if="!this.showOther">
                <el-collapse v-model="activeNames" @change="unitChange">
                  <el-collapse-item class="collapseItem" name="1">
                    <template slot="title">
                      <i class="header-icon"></i>容器
                    </template>
                    <div v-for="(item,index) in vessels" :key="index">
                      <el-button class="unitBtnModel" @click="selectVessel(item)">{{item.packageName}}
                        <li class="el-icon-check"></li>
                      </el-button>
                    </div>

                  </el-collapse-item>
                  <el-collapse-item class="collapseItem " name="2" v-if="!this.showFilm">
                    <template slot="title">
                      <i class="header-icon"></i>材质
                    </template>
                    <div v-for="(item,index) in textures" :key="index">
                      <el-button class="textureBtn" @click="selectTexture(item)">{{item.packageName}}
                        <li class="el-icon-check"></li>
                      </el-button>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item class="collapseItem" name="3" v-if="!this.showFilm">
                    <template slot="title">
                      <i class="header-icon"></i>大小
                    </template>
                    <div v-for="(item,index) in sizes" :key="index">
                      <el-button class="sizeBtn" @click="selectSize(item)">{{item.packageName}}
                        <li class="el-icon-check"></li>
                      </el-button>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-form-item>

            </el-col>
          </el-row>

          <div class="bottomBtn">
            <el-button class="storeInfoBtn" type="danger" @click="cartChange('good')">保存</el-button>
          </div>
        </el-form>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'DialogRecycleOrder',
    props: {},
    data () {
      let priceValidate = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('请输入回收单价'))
        } else {
          callback()
        }
      }
      let numberValidate = (rule, value, callback) => {
        if (Tool.isEmpty(value) && Tool.isEmpty(this.good.recoveryWeight)) {
          callback(new Error('回收数量和回收重量至少填写一个！'))
        } else {
          callback()
        }
      }
      let weightValidate = (rule, value, callback) => {
        if (Tool.isEmpty(value) && Tool.isEmpty(this.good.recoveryNumber)) {
          callback(new Error('回收数量和回收重量至少填写一个！'))
        } else {
          callback()
        }
      }
      return {
        message: '回收开单修改购物车商品',
        clientName: '',
        good: {},
        centerDialogVisible: false,
        rules: {
          recoveryPrice: [
            {
              validator: priceValidate,
              trigger: 'blur'
            }
          ],
          recoveryNumber: [
            {
              validator: numberValidate,
              trigger: 'blur'
            }
          ],
          recoveryWeight: [
            {
              validator: weightValidate,
              trigger: 'blur'
            }
          ],
          disposePrice: [
            {
              validator: priceValidate,
              trigger: 'blur'
            }
          ],
          disposeNumber: [
            {
              validator: numberValidate,
              trigger: 'blur'
            }
          ],
          disposeWeight: [
            {
              validator: weightValidate,
              trigger: 'blur'
            }
          ]
        },
        //动态下拉
        activeNames: ['1', '2', '3'],
        //包装单位
        parentId: 0,
        moneyUnits: [],
        amountUnits: [],
        weightUnits: [],
        vessels: [],
        textures: [],
        sizes: [],
        //是否显示texture和size
        showFilm: false,
        //是否包装分类
        showOther: false,
        PACKAGE_LEVEL: PACKAGE_LEVEL,
        RECYCLE_CATEGORY: RECYCLE_CATEGORY,
        VESSEL_TYPE: VESSEL_TYPE,
        UNIT_TYPE: UNIT_TYPE,
        base_url: '',
        uncheck: false,
        type: 1,
        previousGood: {},
        isPrevious:true
      }
    },
    mounted () {
      //this.base_url=this.BASE_URL;
      //this.base_url="D:\\DEV_CODE\\Intelligy_idead_code\\upload-file\\temp";
      this.base_url = 'https://jxccs.weituodata.com:81'
      this.getPackageUnits()
    },
    methods: {
      dialogClose () {
        let _this = this
        if(this.isPrevious){
          _this.good = _this.previousGood
          _this.$emit('cartChange', _this.good)
          _this.checkPackage()
          _this.centerDialogVisible=true
          _this.centerDialogVisible=false
        }

      },
      openSaleGood (good, clientName, type) {
        let _this=this
        //注意，必须先打开对话框才能获取对话框的ref
        _this.centerDialogVisible = true
        _this.good = good
        _this.previousGood = good
        _this.good = $.extend({}, _this.good)
        _this.clientName = clientName.toString()
        _this.parentId = _this.good.bigPackageId
        _this.showOther = false
        if (type) {
          _this.type = type
        }
        _this.showFilm = _this.parentId === 3
        if (_this.parentId === 4) {
          _this.showOther = true
        } else {
          _this.getVesselCategory()
        }
        _this.activeNames.push('3')
      },

      cartChange (good) {
        let _this = this
        _this.good = $.extend({}, _this.good)
        if (_this.good.vesselPackageId === 1 && _this.good.bigPackageId !== 4) {
          _this.$notify({
            title: '请选择包装分类',
            message: '容器不能为空',
            position: 'bottom-right',
            type: 'warning'
          })

        } else if (_this.good.texturePackageId === 1 && _this.good.bigPackageId !== 4 && _this.good.bigPackageId !== 3) {
          _this.$notify({
            title: '请选择包装分类',
            message: '材质不能为空',
            position: 'bottom-right',
            type: 'warning'
          })

        } else if (_this.good.sizePackageId === 1 && _this.good.bigPackageId !== 4 && _this.good.bigPackageId !== 3) {
          _this.$notify({
            title: '请选择包装分类',
            message: '大小不能为空',
            position: 'middle-right',
            type: 'warning'
          })

        } else {
          _this.$refs[good].validate((valid) => {
              if (valid) {
                if(_this.good.recoveryMoney===undefined || _this.good.disposeMoney===undefined){
                  _this.good.recoveryMoney=0.00
                  _this.good.disposeMoney=0.00
                }
                _this.$emit('cartChange', _this.good)
                _this.centerDialogVisible = false
                _this.isPrevious=false;
              }
            }
          )
        }

      },

      //获取回收单位
      getPackageUnits () {
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/packageunit/selectAll')
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                resp.data.map((item) => {
                  if (item.packageUnitType === 1) {
                    this.moneyUnits.push(item)
                  }
                  if (item.packageUnitType === 2) {
                    this.amountUnits.push(item)
                  }
                  if (item.packageUnitType === 3) {
                    this.weightUnits.push(item)
                  }
                })
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      //获取包装分类
      getVesselCategory () {
        let _this = this
        Loadings.show()
        _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + _this.parentId)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 200) {
                Loadings.hide()
                let resp = response.data
                _this.vessels = resp.data
                setTimeout(function () {
                  for (let i = 0; i < _this.vessels.length; i++) {
                    if (_this.vessels[i].packageId === _this.good.vesselPackageId) {
                      //console.log('_this.vessels[i]: ', _this.vessels[i], '_this.good.vesselPackageId: ', _this.good.vesselPackageId)
                      _this.good.vesselPackageName = _this.vessels[i].packageName
                    }
                  }
                  let unitBtnModel = $('.unitBtnModel')
                  for (let i = 0; i < unitBtnModel.length; i++) {
                    Tool.removeClass(unitBtnModel[i], 'activeBtn')
                    unitBtnModel[i].childNodes[2].childNodes[1].style.display = 'none'
                    if (unitBtnModel[i].innerText.trim() === _this.good.vesselPackageName.trim()) {
                      Tool.addClass(unitBtnModel[i], 'activeBtn')
                      unitBtnModel[i].childNodes[2].childNodes[1].style.display = 'block'
                    }
                  }
                }, 50)

                _this.parentId = _this.good.vesselPackageId
                _this.getTextureCategory()
              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      getTextureCategory () {
        let _this = this
        _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + _this.parentId)
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                _this.textures = resp.data
                if (!this.uncheck) {
                  setTimeout(function () {
                    for (let i = 0; i < _this.textures.length; i++) {
                      if (_this.textures[i].packageId === _this.good.texturePackageId) {
                        _this.good.texturePackageName = _this.textures[i].packageName
                      }
                    }
                    let textureBtn = $('.textureBtn')
                    for (let i = 0; i < textureBtn.length; i++) {
                      Tool.removeClass(textureBtn[i], 'activeBtn')
                      textureBtn[i].childNodes[2].childNodes[1].style.display = 'none'
                      if (textureBtn[i].innerText.trim() === _this.good.texturePackageName.trim()) {
                        Tool.addClass(textureBtn[i], 'activeBtn')
                        textureBtn[i].childNodes[2].childNodes[1].style.display = 'block'
                      }
                    }
                  }, 50)
                  if (_this.good.bigPackageId !== 3) {
                    _this.parentId = _this.good.texturePackageId
                    _this.getSizeCategory()
                  }
                }

              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      getSizeCategory (selects) {
        let _this = this
        _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + _this.parentId)
          .then((response) => {
            Loadings.hide()
            if (response.status === 200) {
              if (response.data.code === 200) {
                let resp = response.data
                _this.sizes = resp.data
                if (!this.uncheck) {
                  if (!selects) {
                    setTimeout(function () {
                      for (let i = 0; i < _this.sizes.length; i++) {
                        if (_this.sizes[i].packageId === _this.good.sizePackageId) {
                          _this.good.sizePackageName = _this.sizes[i].packageName
                        }
                      }
                      let sizeBtn = $('.sizeBtn')
                      for (let i = 0; i < sizeBtn.length; i++) {
                        Tool.removeClass(sizeBtn[i], 'activeBtn')
                        sizeBtn[i].childNodes[2].childNodes[1].style.display = 'none'
                        if (sizeBtn[i].innerText.trim() === _this.good.sizePackageName.trim()) {
                          Tool.addClass(sizeBtn[i], 'activeBtn')
                          sizeBtn[i].childNodes[2].childNodes[1].style.display = 'block'
                        }
                      }
                    }, 50)
                  }
                }

              } else {
                this.$message({
                  type: 'warning',
                  message: response.data.msg,
                  duration: 2000,
                  offset: 110
                })
              }
            } else {
              this.$message({
                type: 'warning',
                message: '网络异常',
                duration: 2000,
                offset: 110
              })
            }
          })
      },
      checkPackage () {
        let _this = this
        setTimeout(function () {
          for (let i = 0; i < _this.vessels.length; i++) {
            if (_this.vessels[i].packageId === _this.good.vesselPackageId) {
              //console.log('_this.vessels[i]: ', _this.vessels[i], '_this.good.vesselPackageId: ', _this.good.vesselPackageId)
              _this.good.vesselPackageName = _this.vessels[i].packageName
            }
          }
          let unitBtnModel = $('.unitBtnModel')
          for (let i = 0; i < unitBtnModel.length; i++) {
            Tool.removeClass(unitBtnModel[i], 'activeBtn')
            unitBtnModel[i].childNodes[2].childNodes[1].style.display = 'none'
            if (unitBtnModel[i].innerText.trim() === _this.good.vesselPackageName.trim()) {
              Tool.addClass(unitBtnModel[i], 'activeBtn')
              unitBtnModel[i].childNodes[2].childNodes[1].style.display = 'block'
            }
          }

          _this.parentId = _this.good.vesselPackageId
          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + _this.parentId)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                let resp = response.data
                _this.textures = resp.data
                setTimeout(function () {
                  for (let i = 0; i < _this.textures.length; i++) {
                    if (_this.textures[i].packageId === _this.good.texturePackageId) {
                      _this.good.texturePackageName = _this.textures[i].packageName
                    }
                  }
                  let textureBtn = $('.textureBtn')
                  for (let i = 0; i < textureBtn.length; i++) {
                    Tool.removeClass(textureBtn[i], 'activeBtn')
                    textureBtn[i].childNodes[2].childNodes[1].style.display = 'none'
                    if (textureBtn[i].innerText.trim() === _this.previousGood.texturePackageName.trim()) {
                      //console.log("材质获取：",_this.good.texturePackageName,"当前选中：",textureBtn[i].innerText.trim());
                      Tool.addClass(textureBtn[i], 'activeBtn')
                      textureBtn[i].childNodes[2].childNodes[1].style.display = 'block'
                    }
                  }
                }, 50)
                if (_this.good.bigPackageId !== 3) {
                  _this.parentId = _this.good.texturePackageId
                  _this.getSizeCategory()
                }
              }
            })

          _this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wastepackage/selectByParent?parentId=' + _this.parentId)
            .then((response) => {
              Loadings.hide()
              if (response.status === 200) {
                if (response.data.code === 200) {
                  let resp = response.data
                  _this.sizes = resp.data
                  setTimeout(function () {
                    for (let i = 0; i < _this.sizes.length; i++) {
                      if (_this.sizes[i].packageId === _this.good.sizePackageId) {
                        _this.good.sizePackageName = _this.sizes[i].packageName
                      }
                    }
                    let sizeBtn = $('.sizeBtn')
                    for (let i = 0; i < sizeBtn.length; i++) {
                      Tool.removeClass(sizeBtn[i], 'activeBtn')
                      sizeBtn[i].childNodes[2].childNodes[1].style.display = 'none'
                      if (sizeBtn[i].innerText.trim() === _this.previousGood.sizePackageName.trim()) {
                        Tool.addClass(sizeBtn[i], 'activeBtn')
                        sizeBtn[i].childNodes[2].childNodes[1].style.display = 'block'
                      }
                    }
                  }, 50)
                }
              }
            })
        }, 160)
      },
      //输入变动
      priceChange (e) {
        this.recoveryMoneyHandler();
      },
      amountChange (e) {
        if (Tool.isEmpty(e)) {
          let numberError = document.getElementsByClassName('numberError')[1]
          let weightError = document.getElementsByClassName('weightError')[1]
          setTimeout(function () {
            let item = document.getElementsByClassName('el-form-item')
            for (let i = 0; i < item.length; i++) {
              if (item[i].className.indexOf('is-error') !== -1) {
                if (weightError.className.indexOf('is-success') !== -1) {
                  Tool.removeClass(weightError, 'is-success')
                  Tool.addClass(weightError, 'is-error')
                }
              }
            }
          })
        } else {
          setTimeout(function () {
            let numberError = document.getElementsByClassName('numberError')[1]
            let weightError = document.getElementsByClassName('weightError')[1]
            if (weightError.className.indexOf('is-error') !== -1) {
              Tool.removeClass(weightError, 'is-error')
              Tool.addClass(weightError, 'is-success')
              Tool.removeClass(numberError, 'is-error')
              Tool.addClass(numberError, 'is-success')
              if(weightError.childNodes[1].childNodes[1]!==undefined){
                //weightError.childNodes[1].removeChild(weightError.childNodes[1].childNodes[1])
                weightError.childNodes[1].childNodes[1].style.display="none"
              }
            }
          })

        }

        this.recoveryMoneyHandler();
      },
      weightChange (e) {
        if (Tool.isEmpty(e)) {
          let numberError = document.getElementsByClassName('numberError')[1]
          let weightError = document.getElementsByClassName('weightError')[1]
          setTimeout(function () {
            let item = document.getElementsByClassName('el-form-item')
            for (let i = 0; i < item.length; i++) {
              if (item[i].className.indexOf('is-error') !== -1) {
                if (numberError.className.indexOf('is-success') !== -1) {
                  Tool.removeClass(numberError, 'is-success')
                  Tool.addClass(numberError, 'is-error')
                }
              }
            }
          })
        } else {
          setTimeout(function () {
            let numberError = document.getElementsByClassName('numberError')[1]
            let weightError = document.getElementsByClassName('weightError')[1]
            if (weightError.className.indexOf('is-error') !== -1 || numberError.className.indexOf('is-error') !== -1) {
              Tool.removeClass(weightError, 'is-error')
              Tool.addClass(weightError, 'is-success')
              Tool.removeClass(numberError, 'is-error')
              Tool.addClass(numberError, 'is-success')
              if(numberError.childNodes[1].childNodes[1]!==undefined){
                //numberError.childNodes[1].removeChild(numberError.childNodes[1].childNodes[1])
                numberError.childNodes[1].childNodes[1].style.display="none"
              }
            }
          })
        }

        this.recoveryMoneyHandler();
      },
      payChange (e) {
        //console.log('支付金额变动：', e)
      },

      recoveryMoneyHandler(){
        let priceUnit=JSON.parse(JSON.stringify(this.moneyUnits));
        let numberUnit=JSON.parse(JSON.stringify(this.amountUnits));
        let weightUnit=JSON.parse(JSON.stringify(this.weightUnits));
        let priceName='';
        let numberName='';
        let weightName='';
        for(let i=0;i<priceUnit.length;i++){
          let unit= JSON.parse(JSON.stringify(priceUnit[i]))
          if(this.good.pricePackageUnitId===unit.packageUnitId){
            priceName=unit.packageUnitName;
          }
        }
        //数量单位判断
        for(let i=0;i<numberUnit.length;i++){
          let unit= JSON.parse(JSON.stringify(numberUnit[i]))
          if(this.good.numberPackageUnitId===unit.packageUnitId){
            numberName=unit.packageUnitName;
          }
        }
        //重量单位判断
        for(let i=0;i<weightUnit.length;i++){
          let unit= JSON.parse(JSON.stringify(weightUnit[i]))
          if(this.good.weightPackageUnitId===unit.packageUnitId){
            weightName=unit.packageUnitName;
          }
        }
        //console.log("this.good.recoveryMoney: ",this.good.recoveryMoney,"this.good.recoveryPrice: ",this.good.recoveryPrice,"this.good.recoveryNumber： ",this.good.recoveryNumber)
        if(priceName.trim()===numberName.trim()){
          this.good.recoveryMoney = this.good.recoveryPrice * this.good.recoveryNumber
          this.good.disposeMoney = this.good.disposePrice * this.good.disposeNumber
        }
        if(priceName.trim()===weightName.trim()){
          this.good.recoveryMoney = this.good.recoveryPrice * this.good.recoveryWeight
          this.good.disposeMoney = this.good.disposePrice * this.good.disposeWeight
        }
      },

      unitChange (val) {
        //console.log('改变的unit：', val)
      },
      //单位监听
      priceUnitChange (e) {
        this.recoveryMoneyHandler()
      },
      numberUnitChange (e) {
        this.recoveryMoneyHandler()
      },
      weightUnitChange (e) {
        this.recoveryMoneyHandler()
      },

      //选择包装分类
      selectVessel (item) {
        this.parentId = item.packageId
        this.good.vesselPackageId = item.packageId
        this.removeCats()

        for (let i = 0; i < this.vessels.length; i++) {
          if (this.vessels[i].packageId === this.good.vesselPackageId) {
            this.good.vesselPackageName = this.vessels[i].packageName
          }
        }

        let unitBtnModel = $('.unitBtnModel')
        for (let i = 0; i < unitBtnModel.length; i++) {
          Tool.removeClass(unitBtnModel[i], 'activeBtn')
          unitBtnModel[i].childNodes[2].childNodes[1].style.display = 'none'
          if (unitBtnModel[i].innerText.trim() === item.packageName.trim()) {
            Tool.addClass(unitBtnModel[i], 'activeBtn')
            unitBtnModel[i].childNodes[2].childNodes[1].style.display = 'block'
          }
        }

        this.good.texturePackageId = 1
        this.good.texturePackageName = ''
        this.good.sizePackageId = 1
        this.good.sizePackageName = ''
        this.activeNames = ['1', '2']
        this.uncheck = true
        this.getTextureCategory()
      },
      selectTexture (item) {
        let _this = this
        _this.parentId = item.packageId
        _this.good.texturePackageId = item.packageId
        let textureBtn = $('.textureBtn')

        for (let i = 0; i < _this.textures.length; i++) {
          if (_this.textures[i].packageId === _this.good.texturePackageId) {
            _this.good.texturePackageName = _this.textures[i].packageName
          }
        }

        for (let i = 0; i < textureBtn.length; i++) {
          Tool.removeClass(textureBtn[i], 'activeBtn')
          textureBtn[i].childNodes[2].childNodes[1].style.display = 'none'
          if (textureBtn[i].innerText.trim() === item.packageName.trim()) {
            Tool.addClass(textureBtn[i], 'activeBtn')
            textureBtn[i].childNodes[2].childNodes[1].style.display = 'block'
          }
        }
        _this.activeNames.push('3')
        let selects = 1
        this.uncheck = true
        _this.getSizeCategory(selects)
      },
      selectSize (item) {
        this.parentId = item.packageId
        this.good.sizePackageId = item.packageId

        for (let i = 0; i < this.sizes.length; i++) {
          if (this.sizes[i].packageId === this.good.sizePackageId) {
            this.good.sizePackageName = this.sizes[i].packageName
          }
        }
        let sizeBtn = $('.sizeBtn')
        for (let i = 0; i < sizeBtn.length; i++) {
          Tool.removeClass(sizeBtn[i], 'activeBtn')
          sizeBtn[i].childNodes[2].childNodes[1].style.display = 'none'
          if (sizeBtn[i].innerText.trim() === item.packageName.trim()) {
            Tool.addClass(sizeBtn[i], 'activeBtn')
            sizeBtn[i].childNodes[2].childNodes[1].style.display = 'block'
          }
        }
      },
      removeBtn () {
        let catBtn = $('.addCart')
        for (let i = 0; i < catBtn.length; i++) {
          Tool.removeClass(catBtn[i], 'checkCat')
        }
      },
      removeCats () {
        let unitBtnModel = $('.unitBtnModel')
        let textureBtn = $('.textureBtn')
        let sizeBtn = $('.sizeBtn')
        let elIconCheck = $('.el-icon-check')
        for (let i = 0; i < unitBtnModel.length; i++) {
          Tool.removeClass(unitBtnModel[i], 'activeBtn')
        }
        for (let i = 0; i < textureBtn.length; i++) {
          Tool.removeClass(textureBtn[i], 'activeBtn')
        }
        for (let i = 0; i < sizeBtn.length; i++) {
          Tool.removeClass(sizeBtn[i], 'activeBtn')
        }
        for (let i = 0; i < elIconCheck.length; i++) {
          elIconCheck[i].style.display = 'none'
        }
      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    font-size: 18px;
    margin-left: 10px;
    color: #ffffff;
    height: 40px;
    margin-top: 15px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-form-item__content {

  }

  .showCheck span {
    color: rgba(255, 102, 0, 0.9);
  }

  .orderNav {
    position: relative;
    margin-bottom: 50px;
  }

  .inputLine {
    max-width: 230px;
    float: left;
  }

  .bottomBtn {
    display: inline-block;
    /*position: absolute;
    bottom: 80px;
    left: 45%;
    margin: 0 auto;*/
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
  }

  /*包装*/
  .checkGroup {
    float: left;
  }

  .inputGroup {
    float: left;
    margin-right: 5px;
  }

  .packageUnit {
  }

  .unitLine {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 5px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  .unitName {
    float: left;
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;
  }

  .unitOneName {
    font-size: 15px;
    margin-right: 5px;
    line-height: 40px;

  }

  .priceUnit {
    float: left;
    display: inline-block;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 10px;
    border: 1px solid #DCDFE6;
  }

  .inventoryLine {
    float: left;
    max-width: 180px;
    margin-right: 10px;
  }

  /*小计*/
  .totalMoney {
    display: inline-block;
    width: 100%;
    margin-top: -5px;
  }

  .totalPrice {
    float: left;
    font-size: 20px;
    font-weight: 700;
    color: #e92322;
    line-height: 40px;
    padding: 0px 10px;
    position: relative;
  }

  .totalPrice:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background: #e92322;
    left: 0;
    bottom: 0;
  }

  .imageBlock {
    max-width: 270px;
    margin-left: 30px;
    padding: 20px;
  }

  .uploadImage {
    max-height: 210px;
  }

  .imageDesc {
    display: block;
    margin-top: 15px;
  }

  /*包装*/
  .showPackage {
    /*border: 1px solid #DCDFE6;*/
    border: 1px solid #d9ecff;
    border-radius: 4px;
    /*color: #606266;*/
    color: #409EFF;
    background-color: #ecf5ff;
    font-size: inherit;
    line-height: 40px;
    height: 40px;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    cursor: pointer;
  }

  .showPackageDesc {
    /*border: 1px solid #DCDFE6;*/
    border: 1px solid #e9e9eb;
    border-radius: 4px;
    color: #909399;
    /*color: #409EFF;*/
    background-color: #f4f4f5;
    font-size: inherit;
    line-height: 40px;
    height: 40px;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    cursor: pointer;
  }

  /*单位*/
  .unitLine {
    display: inline-block;
    width: 100%;
  }

  /deep/ .el-form-item__content {
    line-height: 0 !important;
  }

  .packageUnitLine {
    margin-right: 10px;
    float: left;
    display: block;
  }

  .unitSelect {
    width: 80px;
    display: inline-block;
    float: left;
    margin-right: 5px;
  }

  .unitSelectRight {
    width: 80px;
    display: inline-block;
    margin-right: 5px;
    float: left;
  }

  .money {
    float: left;
    font-size: 18px;
    line-height: 40px;
    margin-right: 5px;
  }

  .slash {
    float: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 5px;
  }

  /*包装容器*/
  /deep/ .el-collapse {
    max-width: 800px;
    border-radius: 10px;
    border-bottom: 10px;
    margin-bottom: 5px;
    border-top: unset;
    margin-right: 20px;
  }

  /deep/ .el-collapse-item__wrap {
    background-color: unset;
    border-bottom: unset;
  }

  /deep/ .el-collapse-item__header {
    border: 1px solid #EBEEF5;
    border-radius: 10px;
    font-size: 16px;
    height: 40px;
    margin-bottom: -3px;
  }

  /deep/ .el-collapse-item__header.is-active {
    border-bottom: 1px solid #EBEEF5;
  }

  /deep/ .el-collapse-item__content {
    /*padding-bottom: 5px;*/
  }

  /deep/ .el-collapse-item {

  }

  /deep/ .el-collapse-item__arrow {
    font-weight: 700;
    float: left;
    margin: 0 10px;
  }

  .header-icon {
    background: #409EFF;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 20px;
    margin-right: 20px;
  }

  .collapseItem {
    margin-bottom: 10px;
  }

  .unitBtnModel {
    margin-top: 15px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 80px;
    padding: 10px;
  }

  .textureBtn {
    margin-top: 15px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 80px;
    padding: 10px;
  }

  .sizeBtn {
    margin-top: 15px;
    float: left;
    margin-left: 20px;
    position: relative;
    width: 80px;
    padding: 10px;
  }

  .el-icon-check {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #409EFF;
    color: #409EFF;
    border-radius: 50%;
    padding: 3px;
    left: 65px;
    top: -5px;
    display: none;
  }

  .activeBtn {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }
  /*错误提示*/
  /deep/ .el-form-item__error {
    margin-top: 1px !important;
    float: left;
  }
</style>
