<template>
  <div class="mainPages">
    <el-row>
      <div class="pageNav">
        <router-link :to="'/admin/roleManager'">
          <el-button class="returnPrevious" type="primary" icon="el-icon-d-arrow-left">{{'返回角色管理'}}
          </el-button>
        </router-link>
        <div class="verticalLine"></div>
        <el-link class="descTitle" type="primary" :underline="false">{{this.type===1?'编辑角色':'新增角色'}}</el-link>
      </div>
      <div class="horizontalLine"></div>
    </el-row>
    <el-row>
      <el-col :span="10">
        <el-card class="cardBox">
          <div class="mainForm">
            <el-form :model="role" :rules="rules" ref="role" label-width="100px"
                     class="demo-role store-info-form">

              <el-form-item class="labelTitle" label="角色名称" prop="roleName">
                <el-input class="inputLine"
                          v-model="role.roleName"
                          placeholder="请输入角色名称"
                          maxlength="50"
                >
                </el-input>
              </el-form-item>

              <el-form-item class="labelTitle" label="角色描述" prop="roleDesc">
                <el-input id="stockDescription" class="inputCoreDesc" :rows="12"
                          type="textarea" maxlength="500" show-word-limit v-model="role.roleDesc"
                          placeholder="请输入角色描述，最多500个字符"></el-input>
              </el-form-item>
              <el-form-item class="labelTitle" label="角色等级" prop="level" v-if="loginUser.level===0">
                <el-radio-group class="roleChoose" v-model="role.level" @change="levelChange">
                  <el-radio :label="0"><i class="levelIcon el-icon-user-solid"></i>管理员级别</el-radio>
                  <el-radio :label="1" disabled><i class="levelIcon el-icon-user-solid"></i>店主级别</el-radio>
                  <el-radio :label="2"><i class="levelIcon el-icon-user-solid"></i>员工级别</el-radio>
                  <el-radio :label="3"><i class="levelIcon el-icon-user-solid"></i>自定义角色</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-button class="storeInfoBtn" type="danger" @click="save('role')">保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>

      </el-col>
      <el-col :span="14" style="height: 750px;margin-top:10px;overflow-y: scroll">
        <div class="resourceRow">
          <div class="switchDrag">
            <div class="switchDragBtn">
              <i class="relationShip glyphicon glyphicon-link"></i>关联资源
            </div>
          </div>
          <div class="checkMenus">
            <el-alert v-if="showError"
              title="请为角色选择相应权限！"
              type="warning"
              show-icon>
            </el-alert>
          </div>
          <div class="categoryTree">
            <el-tree
              :data="resourcess"
              :props="defaultProps"
              :expand-on-click-node="false"
              node-key="id"
              show-checkbox
              :default-expanded-keys="checkedKeys"
              :default-checked-keys="checkedKeys"
              ref="resourcesTree">
                                    <span class="tree-node" slot-scope="{node}">
                                        <span class="nodeIcon glyphicon glyphicon-folder-open"></span>
                                        <span class="labelName">{{node.label}}</span>
                                    </span>
            </el-tree>
          </div>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: 'roleManager',
    inject: ['reload'],
    data () {
      let validate_roleName = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (!value) {
          callback(new Error('请填写角色名称'))
        } else if (getLength(value) > 50) {
          callback(new Error('供应商名称最多不能超过50个字符'))
        } else {
          callback()
        }
      }
      let validate_remark = (rule, value, callback) => {
        function getLength (str) {
          //如果为中文，替换为ccc，1个中文字符计算长度=1
          return str.replace(/[^x00-xff]/g, 'c').length
        }

        if (value && getLength(value) > 500) {
          callback(new Error('备注最多不能超过500个字符'))
        } else {
          callback()
        }
      }

      return {
        message: '供应商管理',
        type: 0,
        loginUser: {},
        role: {
          roleName: '',
          roleDesc: '',
          menuIds: [],
        },
        userLevel: '',
        resourcess: [],
        resources: {},
        resourcesId: [],
        expendedKeys: [], //tree默认展开的数组
        defaultProps: {
          children: 'children', //子菜单的属性名
          label: 'name' //显示的名称
        },
        checkedKeys: [], //默认选中
        checkedKeysParent: [], //回显时，如果不存在children，则将父级的uniId去除，这里存储parentId
        showError:false,
        rules: {
          roleName: [
            {
              required: true,
              validator: validate_roleName,
              trgger: 'blur'
            }
          ],
          roleDesc: [
            {
              validator: validate_remark,
              trgger: 'blur'
            }
          ],
          level: [
            {
              required: true,
              message: '请选择角色等级',
              trigger: 'blur'
            }
          ]
        },
      }
    },
    created () {

    },
    mounted () {
      let roleManagerInfo = {}
      let flag = Tool.getAuthorityRouter(this.$route.path.substr(1))
      if (!flag) {
        this.$emit('noAuth')
      }
      this.loginUser = LocalStorage.get(LOGIN_USER)
      this.role.tenantId = this.loginUser.tenantId
      this.role.userId = this.loginUser.userId
      this.navChange()
      if (Tool.isEmpty(this.$route.params.type) && this.$route.params.type !== 0) {
        roleManagerInfo = SessionStorage.get(ROLE_MANAGER_INFO)
        console.log('this.$route.params.type:', this.$route.params.type, 'roleManagerInfo: ', roleManagerInfo)
        this.type = roleManagerInfo.type
        this.resourcess = roleManagerInfo.resourcess
        this.role = roleManagerInfo.role
      } else {
        this.type = this.$route.params.type
        this.resourcess = this.$route.params.resourcess
        console.log('当前resourcess：', this.resourcess)
        //编辑：type=1 新增：type=0
        if (this.type === 1) {
          this.role = this.$route.params.item || {}
          this.role.tenantId = this.loginUser.tenantId
          this.role.userId = this.loginUser.userId
          if (this.role.status === 1) {
            this.showStopInfo = true
          }
        } else if (this.type === 0) {
          //新增默认是启用状态
          this.role.status = 0
        }
        roleManagerInfo = {
          type: this.type,
          resourcess: this.resourcess,
          role: this.role
        }
        SessionStorage.set(ROLE_MANAGER_INFO, roleManagerInfo)
      }
      //this.supplier = SessionStorage.get(SUPPLIER_INFO).supplier
      //this.type=SessionStorage.get(SUPPLIER_INFO).type
      this.checkedLeaf()
    },
    methods: {
      levelChange (e) {
        console.log('等级更新：', e, 'role: ', this.role)
      },
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },
      resetForm (formName) {
        this.$refs[formName].resetFields()
      },
      //多选的方法
      /*toggleSelection (rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row)
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
      },
      handleSelectionChange (val) {
        this.multipleSelection = val
      },*/

      checkedLeaf () {
        let _this = this
        let uniIds = _this.role.menuIds
        for (let i = 0; i < uniIds.length; i++) {
          _this.checkedKeys.push(uniIds[i])
        }
        let resourcessId = []

        for (let i = 0; i < _this.resourcess.length; i++) {
          resourcessId.push(_this.resourcess[i].id)
          if (Tool.isNotEmpty(_this.resourcess[i].children)) {
            for (let j = 0; j < _this.resourcess[i].children.length; j++) {
              resourcessId.push(_this.resourcess[i].children[j].id)
              if (Tool.isNotEmpty(_this.resourcess[i].children[j].children)) {
                for (let r = 0; r < _this.resourcess[i].children[j].children.length; r++) {
                  if (_this.checkedKeys.indexOf(_this.resourcess[i].children[j].children[r].id) === -1) {
                    console.log("进入了：",_this.checkedKeys.indexOf(_this.resourcess[i].children[j].children[r]))
                    _this.checkedKeysParent.push(_this.resourcess[i].children[j].children[r].parentId)
                    _this.checkedKeysParent.push(_this.resourcess[i].children[j].parentId)
                    resourcessId.push(_this.resourcess[i].children[j].children[r].id)
                  }
                  //更新4级halfLeaf，如果没有，注掉以下循环
                  if (Tool.isNotEmpty(_this.resourcess[i].children[j].children[r].children)) {
                    for (let f = 0; f < _this.resourcess[i].children[j].children[r].children.length; f++) {
                      if (_this.checkedKeys.indexOf(_this.resourcess[i].children[j].children[r].children[f].id) === -1) {
                        _this.checkedKeysParent.push(_this.resourcess[i].children[j].children[r].children[f].parentId)
                        _this.checkedKeysParent.push(_this.resourcess[i].children[j].children[r].parentId)
                        _this.checkedKeysParent.push(_this.resourcess[i].children[j].parentId)
                        resourcessId.push(_this.resourcess[i].children[j].children[r].children[f].id)
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for (let i = 0; i < _this.checkedKeysParent.length; i++) {
          for (let j = 0; j < _this.checkedKeys.length; j++) {
            if (_this.checkedKeys[j] === _this.checkedKeysParent[i]) {
              _this.checkedKeys.splice(_this.checkedKeys.indexOf(_this.checkedKeys[j]), 1)
            }
          }
        }
        console.log('当前选中的checkKeys：', _this.checkedKeys)
        //_this.reload()

      }
      ,
      save (formName) {
        console.log('当前选中的checkKeys：', this.checkedKeys)
        let _this = this
        this.$refs[formName].validate((valid) => {
            if (valid) {
              this.resourcesId = []
              //getCheckednodes：(leafOnly, includeHalfChecked) 接收两个 boolean 类型的参数，
              // 1. 是否只是叶子节点，默认值为 false 2. 是否包含半选节点，默认值为 false ，这里我们保存连同半叶
              let leafNodes = this.$refs.resourcesTree.getCheckedNodes(false, true)
              for (let i = 0; i < leafNodes.length; i++) {
                this.resourcesId.push(leafNodes[i].id)
              }
              this.role.menuIds = this.resourcesId
              if(this.role.menuIds.length<1){
                this.showError=true
              }else{
                Loadings.show()
                //校验供应商名称是否重复
                this.$axios.request({
                  url: process.env.VUE_APP_SERVER + '/blade-system/role/saveRoleMenu',
                  method: 'post',
                  data: this.role
                }).then((res) => {
                    Loadings.hide()
                    if (res.status === 200) {
                      if (res.data.code != 200) {
                        this.$message({
                          type: 'warning',
                          message: res.data.msg,
                          duration: 2000,
                          offset: 110
                        })
                      } else {
                        if (res.status === 200) {
                          if (res.data.code === 200) {
                            this.$message({
                              type: 'success',
                              message: '保存资源角色成功！',
                              duration: 2000,
                              offset: 110
                            })
                            //跳转到客户列表页面
                            _this.$router.push('/admin/roleManager')
                          }
                        }
                      }
                    } else {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 110
                      })
                    }
                  }
                )
              }


            } else {
              return false
            }
          }
        )
      }
      ,

      navChange () {
        let flag = 0
        if (this.type === 1) {
          flag = 1
        }
        //console.log("子组件发射flag：",flag)
        this.$emit('navChange', flag)
        SessionStorage.set(EDIT_FLAG, flag)
      }
      ,
    }
  }
</script>

<style scoped>
  /deep/ .el-form-item__label {
    font-size: 15px;
  }

  /deep/ .el-form-item__content {
    padding-right: 20px;
  }

  /deep/ .el-form-item__label:before {
    margin-left: 4px;
    float: right;
  }

  .mainPages {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 150px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 25px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*表单*/
  .mainForm {
    width: 100%;
    margin-top: 20px;
  }

  .storeInfoBtn {
    background: #e92322;
    width: 150px;
    margin-left: -100px;
    margin-top: 50px;
  }

  .resetBtn {
    width: 150px;
  }

  .inputLine {
    max-width: 350px;
    float: left;
  }

  /*备注*/
  .inputCoreDesc {
    max-width: 350px;
    float: left;
  }

  /deep/ .el-textarea .el-input__count {
    background: unset;
    bottom: -10px;
    right: 10px;
  }

  /*权限*/
  .resourceTree {
    margin-top: 20px;
  }

  .cardBox {
    margin-top: 20px;
  }

  /deep/ .el-card__body {
    padding: 0;
  }

  /*树型控件*/
  /deep/ .el-tree-node__content {
    height: 30px;
  }

  .switchDrag {
    right: 40px;
    top: 20px;
    position: absolute;
    background: #ecf5ff;
    border: 1px dashed #d9ecff;
    color: #409eff;
    padding: 5px 20px;
    border-radius: 5px;
  }

  .switchDragBtn {

  }

  .categoryTree {
    margin-top: 20px;
    float: left;
    margin-left: 20px;
  }

  .nodeIcon {
    margin-right: 15px;
    color: #2a92ee;
    font-size: 15px;
  }

  .labelName {
    margin-right: 30px;
    font-size: 15px;
  }

  .relationShip {
    margin-right: 5px;
  }

  .roleResource {
    position: relative;
  }

  .roleResource .roleSpan {
    margin-top: 20px;
    line-height: 40px;
    font-size: 18px;
    color: #2a92ee;
  }

  .roleChoose {
    float: left;
    line-height: 50px;
  }

  .roleChoose .el-radio {
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    padding: 10px 20px;
    float: left;
  }

  .levelIcon {
    color: #f58500;
    margin-right: 5px;
  }

  .checkMenus{
    width: 50%;
    margin-top: 10px;
    margin-left: 40px;
  }
</style>
