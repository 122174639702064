<template>
  <div>
    <el-dialog width="55%" @close="dialogClose"
               :visible.sync="centerDialogVisible"
               class="dialogDetail"
               :close-on-click-modal="false">
      <div slot="title" class="header-title">
        <div class="title">农药经营许可信息</div>
      </div>

      <div class="contactBody">
        <el-descriptions class="margin-top" title="" :column="1"  :size="size" border v-if="licenseInfo.id">
          <el-descriptions-item labelClassName="labelClassName">
            <template slot="label">
              <!--<i class="el-icon-user"></i>-->
              经营许可信息
            </template>
            {{licenseInfo.licenceCode || ''}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-mobile-phone"></i>-->
              经营者名称
            </template>
            {{licenseInfo.enterpriseName || ''}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-location-outline"></i>-->
              统一社会信用代码
            </template>
            {{licenseInfo.creditCode || ''}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-tickets"></i>-->
              法定代表人（负责人）
            </template>
            {{ licenseInfo.legalPerson || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              住所
            </template>
            {{ licenseInfo.abode || '-' }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              营业场所
            </template>
            {{ licenseInfo.businessPlace || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              仓储场所
            </template>
            {{ licenseInfo.storePlace || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              经营范围
            </template>
            {{ licenseInfo.businessScope || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              有效期开始日期
            </template>
            {{ licenseInfo.expiryDateStartString || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              有效期截止日期
            </template>
            {{ licenseInfo.expiryDateString || '-' }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              发证机关
            </template>
            {{ licenseInfo.issuer || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              发证日期
            </template>
            {{ licenseInfo.approvalDateString || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <!--<i class="el-icon-office-building"></i>-->
              状态
            </template>
            {{ licenseInfo.licenceStatus || '-' }}
          </el-descriptions-item>

        </el-descriptions>
        <div class="tipText" v-if="licenseInfo.id">
          注：数据更新存在延迟，请以实际情况为准。
        </div>
        <div class="emptyBody" v-if="!licenseInfo.id">
          <el-empty description="暂无信息"></el-empty>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'PesticideLicense',
    props: {},
    data () {
      return {
        message: '农药经营许可信息',
        fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
        size:'',
        licenseInfo: {},
        centerDialogVisible:false,
      }
    },
    mounted () {
      //console.log('进来了：', this.message)
    },
    methods: {
      dialogClose () {
        this.$nextTick(() => {
          /*this.$refs.ossPlayer.pause()*/
        })
      },
      openDialog () {
        this.centerDialogVisible=true;
        this.getLicenseInfo()
      },
      getLicenseInfo(){
        Loadings.show()
        //查找当前用户的原有店铺信息
        this.$axios.request({
          url: process.env.VUE_APP_SERVER + '/blade-system/tenant/pesticideBusi',
          method: 'GET'
        })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                console.log("许可信息：",res)
                this.licenseInfo=res.data.data
                Loadings.hide()
              } else {
                this.$message({ type: 'warning', message: res.data.msg, duration: 2000, offset: 110 });
              }
            } else {
              this.$message({ type: 'warning', message: '网络异常', duration: 2000, offset: 110 });
            }
          });

      },
    }
  }
</script>

<style scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-button.is-round {
    padding: 8px;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    background: #d2d3d04d;
  }

  /deep/ .el-dialog__body {
    padding: 10px;
  }

  /deep/ .el-dialog__headerbtn .el-dialog__close {
    color: white;
    font-size: 24px;
  }

  .header-title {
    background-color: #e52a33;
    color: white;
    font-size: 20px;
    display: inline-block;
    width: 100%;
    line-height: 60px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .header-title .title {
    float: left;
    cursor: pointer;
    margin-left: 30px;
  }

  .header-title .titleDesc {
    float: left;
    margin-left: 30px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;
  }

  .header-title .supplierName {
    float: left;
    bottom: 5px;
    margin-left: 10px;
    color: #eeeeee;
    height: 40px;
    text-decoration: none;
    cursor: pointer;
  }

  .header-title .supplierName:hover, .header-title .supplierName:focus {
    color: #f6e483;
  }

  /deep/ .el-dialog__close:before {
    color: #ffffff;
  }
  /*body*/
  .contactBody{
    width: 100%;
    height: 100%;
    padding: 10px 20px 0 20px;
  }
  .tipText{
    margin-top: 10px;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    /*font-weight: 700;*/
    color: #909399;
  }
  /deep/ .el-descriptions-item__label{
    text-align: right !important;
    width: 25%;
  }


  .mainLogo /deep/ .el-image img{
    width: 90px;
    border: 1px solid #dddddd;
    padding: 10px;
    border-radius: 50%;
    position: relative;
  }
  .mainLogo /deep/ .el-image:after{
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #4eabda;
    position: absolute;
    left: 10px;
    top: 8px;
  }


  .mainLogo .eng{
    position: absolute;
    width: 100%;
    top: -25px;
    right: -210px;
    color: #1475b5;
  }

  .contactTitle{
    font-size: 18px;
    width: 100px;
    line-height: 30px;
    text-align: left;
    font-weight: 700;
    color: #606266;
  }

  .contactDesc{
    display: inline-block;
    min-width: 300px;
    float: left;
    margin-top: -40px;
    margin-left: 120px;
    line-height: 40px;
    font-size: 18px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid #dddddd;
    margin-bottom: 25px;
    padding: 5px 20px;
    cursor: pointer;
  }

  #wrap .cubic-box div {
    position: absolute;
    width: 1px;
    height: 15px;
    font: 30px/300px "微软雅黑";
    text-align: center;
    color: rgba(20,117,181,.4);
    background: skyblue;
  }

</style>
