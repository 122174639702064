<template>
  <div class="mainPages">
    <div class="topNav">
      <div class="navLeft">
        <el-input class="searchInput"
                  placeholder="请输入客户名称"
                  value-format="yyyy-MM-dd"
                  v-model="searchInput"
                  :blur="searchInputChange(searchInput)"
                  prefix-icon="el-icon-search"
                  :clearable="true">
        </el-input>

        <div class="block searchTime">
          <el-date-picker
            v-model="value2"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>

        <el-button class="searchBtn" type="danger" @click="getSearch(1)">搜索</el-button>

        <div class="checkStop">
          <template>
            <el-checkbox v-model="checkedStop" @change="checkedStopClick">显示已撤销订单</el-checkbox>
          </template>
        </div>

        <el-button class="searchBtn" type="danger" @click="exportExcel()">导出</el-button>

      </div>
      <div class="navRight">
        <!--<div class="searchBtn">
          <span class="titleDesc">￥ {{recycleOrders.money}}</span>
          <span class="titleName">&lt;!&ndash;<li class="el-icon-money"></li>&ndash;&gt;{{(startDate==startDateInit || startDate=='') && (endDate==endDateInit || endDate=='')?'近6个月':''}}包装回收总金额 </span>
        </div>-->
        <el-button class="refreshBtn" icon="el-icon-refresh" @click="getSearch(1)" circle></el-button>
      </div>
    </div>

    <div class="mainForm">
      <el-table
        ref="multipleTable"
        tooltip-effect="dark"
        :data="orderList"
        border
        height="650px"
        highlight-current-row
        class="previous-row"
        @current-change="handleCurrentChange"
        :header-cell-style="{backgroundImage:'linear-gradient(#f9f9f9,#f9f9f9)',color:'#909399',textAlign:'center'}"
      >
        <el-table-column type="index" label="序号" class="indexCenter" width="50"></el-table-column>
        <el-table-column property="createTime" label="开单时间" width="160" sortable></el-table-column>
        <el-table-column property="clientPerson" label="客户名称" width="180" sortable></el-table-column>
        <el-table-column property="goodsName" label="购买商品" sortable></el-table-column>
        <el-table-column property="recoveryMoney" label="回收支付金额" width="150" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.recoveryMoney > 0 ? 'danger':'warning'" class="showCenter"
                    id="money">￥ {{scope.row.recoveryMoney}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column property="status" label="状态" width="100" sortable>
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 0 ? 'success':'danger'" class="showCenter"
                    id="status">{{SUPPLIER_ORDER_STATUS | optionObjectKV(scope.row.status)}}
            </el-tag>
          </template>
        </el-table-column>
        <!--<el-table-column property="remark" label="备注"  :show-overflow-tooltip="true">
          <template slot-scope="scope" >
            <el-tag type="info" class="showCenter">
              {{scope.row.remark===""?"-":scope.row.remark}}
            </el-tag>
          </template>
        </el-table-column>-->
        <el-table-column
          class="optionList"
          fixed="right"
          label="操作"
          width="180">
          <!--使用slot-scope传递当前行参数-->
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="detail(scope.row)">查看详情
            </el-button>
            <el-button v-if="scope.row.status===0 && getAuthorityMenu('400201')"
                       size="mini"
                       :type="scope.row.status===0?'warning':'danger'"
                       @click="cancelOrder(scope.row)">{{scope.row.status===0?'撤单':'已撤销'}}
            </el-button>
            <!--<el-button
              size="mini"
              type="success"
              @click="reOrder(scope.row)">再来一单
            </el-button>-->
            <!--<el-button
              size="mini"
              type="warning"
              @click="del">排序
            </el-button>-->
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="pageHelper">
      <page-helper ref="pageHelper" :list="getSearch" @pageSizeChange="reSearch"></page-helper>
    </div>
    <dialog-recycle-order-detail ref="dialogRecycleOrderDetail" @cancelOrder="cancelOrder"></dialog-recycle-order-detail>
  </div>
</template>

<script>
  import PageHelper from '../../../components/PageHelper'
  import DialogRecycleOrderDetail from '../../../components/DialogRecycleOrderDetail'

  export default {
    name: 'RecycleOrderList',
    components: {
      PageHelper,
      DialogRecycleOrderDetail
    },
    data () {
      return {
        message: '回收单据列表',
        searchInput: '',
        clientName: '',
        client: {
          clientName: '',
          clientPerson: '',
          clientPhone: '',
          status: 0
        },
        recycleOrder: {},
        recycleOrders: [],
        orderList: [],
        checkedStop: false,//默认不显示停用供应商
        currentRow: null,
        SUPPLIER_ORDER_STATUS: SUPPLIER_ORDER_STATUS,
        //弹框
        centerDialogVisible: false,
        //时间选择器
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
        value1: '',
        value2: '',
        startDate: '',
        endDate: '',
        startDateInit:'',
        endDateInit:''
      }
    },
    mounted () {
      this.client = this.$route.params.item || {}
      let flag= Tool.getAuthorityRouter(this.$route.path.substr(1))
      if(!flag){
        this.$emit('noAuth')
      }else{
        this.getSearch(1)
      }

      setTimeout(function () {
        if(document.getElementsByClassName('swal2-container')[0]){
          let sweet = document.getElementsByClassName('swal2-container')[0]
          sweet.style.display='none'
        }
      }, 50)
    },
    methods: {
      //1.搜索
      searchInputChange (searchInput) {
        //console.log('搜索内容: ', searchInput)
      },

      getSearch (page, pageChange) {
        Loadings.show()
        let current = page
        let size = this.$refs.pageHelper.size //引用传入组件后，在标签中refs定义的别名
        let keywords = this.searchInput
        let showStop = 0
        if (this.checkedStop === false) {
          showStop = 0
        } else {
          showStop = 1
        }
        let startDate=''
        let endDate=''
        let date=new Date
        let newMonth=date.getMonth()-6
        let start=new Date
        start.setMonth(newMonth)
        if(Tool.isEmpty(this.startDate) && Tool.isEmpty(this.endDate)){
          // startDate=Tool.formatDate(start.getTime())
          // endDate=Tool.formatDate(date.getTime())
          // this.startDateInit=startDate
          // this.endDateInit=endDate
        }else{
          startDate=this.startDate
          endDate=this.endDate
        }
        this.orderList = []
        this.$axios.get(process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/page?current='
          + current + '&size=' + size + '&clientName=' + keywords + '&status=' + showStop + '&beginTime=' + startDate + '&endTime=' + endDate)
          .then((response) => {
              Loadings.hide()
              let resp = response.data
              let orders = resp.data
              this.recycleOrders = orders
              for (let i = 0; i < orders.orderList.length; i++) {
                for (let j = 0; j < orders.orderList[i].orderList.length; j++) {
                  this.orderList.push(orders.orderList[i].orderList[j])
                }
              }
              this.$refs.pageHelper.render(page, resp.data.total)
            }
          )
      },
      reSearch (data) {
        this.getSearch(1, data)
      },
      //2.已撤销订单
      radioChange (e) {
        if (e === 1) {
          this.showStopInfo = true
        } else {
          this.showStopInfo = false
        }
        //key === this.client.status ? this.client.status = key:this.client.status=0;
      },
      checkedStopClick () {
        this.getSearch(1)
      },
      //订单详情
      detail (item) {
        // console.log("item---",item)
        // let supplier = {
        //   supplierName: item.supplierName,
        //   supplierPerson: item.supplierPerson,
        //   supplierPhone: item.supplierPhone
        // };

        this.$nextTick(() => {
          let orderId = item.recoveryOrderId
          let clientName = item.clientPerson && item.clientPerson!==''?item.clientPerson:'-'
          this.$refs.dialogRecycleOrderDetail.getOrderId(orderId, clientName)
        })
      },
      //撤销订单
      cancelOrder (item) {
        let _this = this
        if(typeof(item)==='number'){
          Loadings.show()
          _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/revoke?recoveryOrderId=' + item)
            .then((response) => {
                Loadings.hide()
                let resp = response.data
                let orders = resp.data
                _this.getSearch(1)
              }
            )
        }else{
          this.$toast.showCodeConfirm('撤销当前订单', item.goodsName, '是否撤单', '撤单', function () {
            Loadings.show()
            _this.$axios.post(process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/revoke?recoveryOrderId=' + item.recoveryOrderId)
              .then((response) => {
                  Loadings.hide()
                  let resp = response.data
                  let orders = resp.data
                  _this.getSearch(1)
                }
              )
            }
          )
        }
      },
      //再来一单
      reOrder (item) {
        //console.log('跳转进货开单页面：', item)
      },
      handleCurrentChange (val) {
        this.currentRow = val
      },
      //时间选择
      dateChange (e) {
        this.startDate = this.$moment(e[0]).format('YYYY-MM-DD')
        this.endDate = this.$moment(e[1]).format('YYYY-MM-DD')
      },
      getAuthorityMenu(id){
        return Tool.authorityUserResources(id)
      },
      exportExcel () {
        //console.log('导出excel')
        let token = LocalStorage.get(TOKEN)
        let startDate=''
        let endDate=''
        let date=new Date
        let newMonth=date.getMonth()-6
        let start=new Date
        start.setMonth(newMonth)
        if(Tool.isEmpty(this.startDate) && Tool.isEmpty(this.endDate)){
          // startDate=Tool.formatDate(start.getTime())
          // endDate=Tool.formatDate(date.getTime())
          // this.startDateInit=startDate
          // this.endDateInit=endDate
        }else{
          startDate=this.startDate
          endDate=this.endDate
        }
        axios({
          method: 'get',
          url: process.env.VUE_APP_SERVER + '/psi/wasterecoveryorder/exportRecoveryOrder?clientName='
            + this.searchInput + '&beginTime=' + startDate + '&endTime=' + endDate + '&status=' + (this.checkedStop ? 1 : 0),
          responseType: 'blob',
          headers: {
            'blade-auth': 'bearer ' + LocalStorage.get(TOKEN)
          }
        })
          .then((res) => {
            const link = document.createElement('a')
            let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
            let temp = res.headers["content-disposition"].split(";")[2].split("filename*=")[1];
            let fileTemp=temp.substr(7,temp.length)
            //let temp = res.data;
            let fileName = decodeURIComponent(fileTemp);
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }).catch(error => {
          console.log(error)
        })

      },
    }
  }
</script>

<style scoped>

  .mainPages {
    height: calc(100vh - 120px);
  }

  /*顶部导航*/
  .pageNav {
    display: inline-block;
    float: left;
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .horizontalLine {
    width: 100%;
    height: 5px;
    border: 5px solid #e9eef3;
    border-radius: 20px;
    margin-top: 60px;
  }

  .returnPrevious {
    float: left;
  }

  .verticalLine {
    position: absolute;
    top: 5px;
    float: left;
    left: 170px;
    width: 5px;
    height: 30px;
    border-radius: 40px;
    background-color: #409EFF;
  }

  .descTitle {
    margin-left: 35px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
    margin-right: 10px;
  }

  .supplierName {
    margin-top: -5px;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;
  }

  /*搜索导航*/
  .topNav {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }

  .navLeft {
    float: left;
  }

  .searchInput {
    width: 250px;
    float: left;
    margin-right: 10px;
  }

  .searchBtn {
    margin-right: 10px;
    float: left;
  }

  .titleName {
    display: inline-block;
    background-color: #e92322;
    color: #ffffff;
    padding: 0 15px;
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;
    float: right;
    border-top-left-radius: 10px;
  }

  .titleDesc {
    display: inline-block;
    background-color: #ffffff;
    color: #e92322;
    border: 1px solid #e92322;
    padding: 0 15px;
    line-height: 38px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    float: right;
    border-bottom-right-radius: 10px;
  }

  .checkStop {
    float: left;
    margin-right: 10px;
    line-height: 40px;
  }

  .el-checkbox:last-of-type {
    margin-right: 10px;
  }

  .navRight {
    float: right;
  }

  .refreshBtn {
    color: #e92322;
    font-weight: bold;
    margin-right: 50px;
  }

  /*时间搜索*/
  .searchTime {
    float: left;
    margin-right: 10px;
  }

  /deep/ .el-date-editor .el-range-input {
    margin-left: 20px;
  }

  /*表格*/
  .mainForm {

  }

  /deep/ .el-table .el-table__cell:first-child {
    text-align: center;
  }

  /deep/ .el-table__body-wrapper {
    min-height: 560px;
  }


</style>
